import React, { useState, useEffect, useMemo, useRef } from 'react';
import { View, StyleSheet, FlatList, useWindowDimensions } from 'react-native';
import { Button, Input, Text, Layout, Icon, useTheme, Modal, Divider, Select, SelectItem, Spinner } from '@ui-kitten/components';
import { InventoryItem, InventoryStock, InventoryLocation } from '../../models/Inventory.model';
import * as InventoryController from '../../functions/Inventory.controller';
import Toast from 'react-native-toast-message';
import * as StorageController from '../../functions/storageController';
import AddLocationModal from './AddLocationModal.component';
import DMModal from '../common/Modal';
import { LocationManager } from './LocationManager.component';

interface StockManagerProps {
    category_id: string;
    items: InventoryItem[];
    onClose: () => void;
}

export const StockManager: React.FC<StockManagerProps> = ({ category_id, items, onClose }) => {
    const [stockByItem, setStockByItem] = useState<{ [key: string]: InventoryStock[] }>({});
    const stockByItemRef = useRef<{ [key: string]: InventoryStock[] }>({});
    const [locations, setLocations] = useState<InventoryLocation[]>([]);
    const [loading, setLoading] = useState(true);
    const [transferModalVisible, setTransferModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState<InventoryItem | null>(null);
    const [transferDetails, setTransferDetails] = useState({
        fromLocation: null as InventoryLocation | null,
        toLocation: null as InventoryLocation | null,
        quantity: 0
    });
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [originalStockData, setOriginalStockData] = useState<{ [key: string]: InventoryStock[] }>({});
    const [locationModalVisible, setLocationModalVisible] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const { width } = useWindowDimensions();
    const theme = useTheme();

    useEffect(() => {
        fetchInitialData();
    }, [items]);

    const fetchInitialData = async () => {
        setLoading(true);
        await Promise.all([
            fetchLocations(),
            fetchAllStockData()
        ]);
        setLoading(false);
    };

    const fetchLocations = async () => {
        const company = StorageController.getCurrentCompany();
        const fetchedLocations = await InventoryController.getAllInventoryLocationsByCompanyId(company._id);
        if (fetchedLocations) {
            setLocations(fetchedLocations);
        }
    };

    const onSetStockByItem = (stock: { [key: string]: InventoryStock[] }) => {
        // setStockByItem(stock);
        stockByItemRef.current = stock;
    }

    const fetchAllStockData = async () => {
        const stockData: { [key: string]: InventoryStock[] } = {};
        if (category_id) {
            const stock = await InventoryController.getInventoryStockByCategoryId(category_id);
            if (stock) {
                stock.forEach((s) => {
                    const item = new InventoryItem(s.inventory_item_id as Partial<InventoryItem>);
                    if (!stockData[item._id!]) {
                        stockData[item._id!] = [];
                    }
                    stockData[item._id!].push(s);
                });
            }
        } else {
            for (const item of items) {
                if (item._id) {
                    const stock = await InventoryController.getInventoryStockByInventoryItemId(item._id);
                    stockData[item._id] = stock || [];
                }
            }
        }

        onSetStockByItem(stockData);
        setOriginalStockData(JSON.parse(JSON.stringify(stockData)));
        setUnsavedChanges(false);
    };

    const updateStock = (itemId: string, locationId: string, quantity: number) => {
        const updatedStockByItem = { ...stockByItemRef.current };
        const existingStock = updatedStockByItem[itemId]?.find(s => s.location_id === locationId);

        if (existingStock) {
            existingStock.quantity = quantity;
        } else {
            const newStock = new InventoryStock({
                inventory_item_id: itemId,
                location_id: locationId,
                quantity: quantity,
                company_id: items.find(i => i._id === itemId)?.company_id
            });
            if (!updatedStockByItem[itemId]) {
                updatedStockByItem[itemId] = [];
            }
            updatedStockByItem[itemId].push(newStock);
        }

        onSetStockByItem(updatedStockByItem);
        setUnsavedChanges(true);
    };

    const handleTransfer = async () => {
        if (!selectedItem?._id || !transferDetails.fromLocation?._id || !transferDetails.toLocation?._id) return;

        const fromStock = stockByItemRef.current[selectedItem._id]?.find(
            s => s.location_id === transferDetails.fromLocation?._id
        );
        const toStock = stockByItemRef.current[selectedItem._id]?.find(
            s => s.location_id === transferDetails.toLocation?._id
        );

        if (!fromStock || fromStock.quantity! < transferDetails.quantity) {
            Toast.show({
                type: 'error',
                text1: 'Insufficient stock for transfer'
            });
            return;
        }

        await updateStock(
            selectedItem._id,
            transferDetails.fromLocation._id,
            fromStock.quantity! - transferDetails.quantity
        );

        await updateStock(
            selectedItem._id,
            transferDetails.toLocation._id,
            (toStock?.quantity || 0) + transferDetails.quantity
        );

        setTransferModalVisible(false);
        setTransferDetails({ fromLocation: null, toLocation: null, quantity: 0 });
        Toast.show({
            type: 'success',
            text1: 'Stock transferred successfully'
        });
    };

    const saveChanges = async () => {
        setLoading(true);
        try {
            for (const itemId in stockByItemRef.current) {
                const originalStocks = originalStockData[itemId] || [];
                const currentStocks = stockByItemRef.current[itemId];

                for (const stock of currentStocks) {
                    const originalStock = originalStocks.find(s => s.location_id === stock.location_id);

                    if (originalStock) {
                        if (originalStock.quantity !== stock.quantity) {
                            await InventoryController.updateInventoryStock(originalStock._id!, stock);
                        }
                    } else {
                        await InventoryController.createInventoryStock(stock);
                    }
                }
            }

            await fetchAllStockData();
            Toast.show({
                type: 'success',
                text1: 'Stock updates saved successfully'
            });
        } catch (error) {
            console.error('Error saving changes:', error);
            Toast.show({
                type: 'error',
                text1: 'Failed to save changes'
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAddLocation = async (location: InventoryLocation) => {
        try {
            const newLocation = await InventoryController.createInventoryLocation(location);
            if (newLocation) {
                setLocations([...locations, newLocation]);
                setLocationModalVisible(false);
                Toast.show({
                    type: 'success',
                    text1: 'Location added successfully'
                });
            }
        } catch (error) {
            console.error('Error adding location:', error);
            Toast.show({
                type: 'error',
                text1: 'Failed to add location'
            });
        }
    };

    const memoTransferModal = useMemo(() => {
        return <RenderTransferModal
            selectedItem={selectedItem || new InventoryItem()}
            transferDetails={transferDetails as any}
            setTransferDetails={setTransferDetails}
            setTransferModalVisible={setTransferModalVisible}
            locations={locations}
            transferModalVisible={transferModalVisible}
            handleTransfer={handleTransfer}
        />
    }, [selectedItem, transferDetails, setTransferDetails, setTransferModalVisible, locations, transferModalVisible, handleTransfer]);

    const memoLocationManager = useMemo(() => {
        return <RenderLocationManager
            onClose={onClose}
            locationModalVisible={locationModalVisible}
            setLocationModalVisible={setLocationModalVisible}
            fetchLocations={fetchLocations}
        />
    }, [locationModalVisible, setLocationModalVisible, fetchLocations]);

    const calculateTotalStock = (itemId: string) => {
        const stocks = stockByItemRef.current[itemId] || [];
        return stocks.reduce((total, stock) => total + (stock.quantity || 0), 0);
    };

    return (
        <View style={[styles.container, { flex: 1 }]}>
            <View style={styles.headerContainer}>
                <View style={styles.headerLeft}>
                    <Text category="h5" style={styles.header}>Stock Management</Text>
                    {unsavedChanges && (
                        <Text status="warning" category="s1" style={styles.unsavedLabel}>
                            Unsaved Changes
                        </Text>
                    )}
                </View>
                <Button
                    size="small"
                    status="primary"
                    onPress={() => setLocationModalVisible(true)}
                >
                    Manage Locations
                </Button>
            </View>

            <RenderItemSearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

            <View style={{ flex: 1 }}>
                <View style={styles.headerRow}>
                    <View style={styles.itemColumn}>
                        <Text>Item</Text>
                    </View>
                    {locations.map(location => (
                        <View key={location._id} style={styles.locationColumn}>
                            <Text>{location.name}</Text>
                        </View>
                    ))}
                </View>

                <FlatList
                    data={items.filter(item =>
                        searchQuery === '' ||
                        item.name.toLowerCase().includes(searchQuery.toLowerCase())
                    )}
                    renderItem={({ item }) => (
                        <View style={styles.itemRow}>
                            <View style={styles.itemColumn}>
                                <View style={styles.itemNameContainer}>
                                    <Text style={styles.totalStock}>
                                        {calculateTotalStock(item._id!)}
                                    </Text>
                                    <Text>{item.name}</Text>
                                </View>
                            </View>
                            {locations.map(location => {
                                const stockAtLocation = stockByItemRef.current[item._id!]?.find(
                                    s => s.location_id === location._id
                                );
                                return (
                                    <View key={location._id} style={styles.locationColumn}>
                                        <Input
                                            placeholder="0"
                                            defaultValue={stockAtLocation?.quantity?.toString() || ''}
                                            onChangeText={(text) => {
                                                const quantity = parseInt(text) || 0;
                                                updateStock(item._id!, location._id!, quantity);
                                            }}
                                            keyboardType="numeric"
                                            style={styles.quantityInput}
                                        />
                                    </View>
                                );
                            })}
                        </View>
                    )}
                    keyExtractor={item => item._id!}
                    ItemSeparatorComponent={() => <View style={styles.separator} />}
                    contentContainerStyle={{ flexGrow: 1 }}
                    ListEmptyComponent={() => (
                        <View style={styles.emptyContainer}>
                            <Text>No items found</Text>
                        </View>
                    )}
                />
            </View>



            <View style={styles.footer}>
                <View style={styles.saveButtonContainer}>
                    <Button
                        status="primary"
                        onPress={saveChanges}
                        disabled={!unsavedChanges || loading}
                        style={styles.saveButton}
                    >
                        Save Changes
                    </Button>
                    {loading && <Spinner size='small' status='primary' />}
                </View>
                <Button appearance="ghost" status="danger" onPress={onClose}>
                    Close
                </Button>
            </View>

            {memoTransferModal}
            {memoLocationManager}


        </View>
    );
};



interface RenderLocationManagerProps {
    onClose: () => void;
    locationModalVisible: boolean;
    setLocationModalVisible: (visible: boolean) => void;
    fetchLocations: () => void;
}

const RenderLocationManager = ({ locationModalVisible, setLocationModalVisible, fetchLocations }: RenderLocationManagerProps) => {
    return (<DMModal
        visible={locationModalVisible}
        onClose={() => setLocationModalVisible(false)}
        style={{ height: '80%', width: '80%' }}
    >
        <LocationManager
            onClose={() => {
                setLocationModalVisible(false);
                fetchLocations(); // Refresh locations after changes
            }}
        />
    </DMModal>
    )
}


interface RenderItemSearchInputProps {
    searchQuery: string;
    setSearchQuery: (text: string) => void;
}

const RenderItemSearchInput = ({ searchQuery, setSearchQuery }: RenderItemSearchInputProps) => {
    return (
        <View style={styles.searchContainer}>
            <Input
                placeholder='Search items...'
                defaultValue={searchQuery}
                onChangeText={setSearchQuery}
                style={styles.searchInput}
            />
        </View>
    )
}


interface RenderTransferModalProps {
    selectedItem: InventoryItem;
    transferDetails: { fromLocation: InventoryLocation, toLocation: InventoryLocation, quantity: number };
    setTransferDetails: (details: { fromLocation: InventoryLocation, toLocation: InventoryLocation, quantity: number }) => void;
    setTransferModalVisible: (visible: boolean) => void;
    locations: InventoryLocation[];
    transferModalVisible: boolean;
    handleTransfer: () => void;
}

const RenderTransferModal = ({
    selectedItem,
    transferDetails,
    setTransferDetails,
    setTransferModalVisible,
    locations,
    transferModalVisible,
    handleTransfer
}: RenderTransferModalProps) => {
    return (
        <DMModal
            visible={transferModalVisible}
            onClose={() => setTransferModalVisible(false)}
        >
            <Layout style={styles.modalContainer}>
                <Text category="h6">Transfer Stock</Text>
                <Text category="s1">{selectedItem?.name}</Text>

                <Select
                    label="From Location"
                    // @ts-ignore
                    selectedIndex={locations.findIndex(loc => loc._id === transferDetails.fromLocation?._id)}
                    onSelect={index => setTransferDetails({
                        ...transferDetails,
                        // @ts-ignore
                        fromLocation: locations[index.row]
                    })}
                    value={transferDetails.fromLocation?.name || 'Select location'}
                    style={styles.modalInput}
                >
                    {locations.map(location => (
                        <SelectItem key={location._id} title={location.name} />
                    ))}
                </Select>

                <Select
                    label="To Location"
                    // @ts-ignore
                    selectedIndex={locations.findIndex(loc => loc._id === transferDetails.toLocation?._id)}
                    onSelect={index => setTransferDetails({
                        ...transferDetails,
                        // @ts-ignore
                        toLocation: locations[index.row]
                    })}
                    value={transferDetails.toLocation?.name || 'Select location'}
                    style={styles.modalInput}
                >
                    {locations.map(location => (
                        <SelectItem key={location._id} title={location.name} />
                    ))}
                </Select>

                <Input
                    label="Quantity"
                    value={transferDetails.quantity.toString()}
                    onChangeText={value => setTransferDetails({
                        ...transferDetails,
                        quantity: parseInt(value) || 0
                    })}
                    keyboardType="numeric"
                    style={styles.modalInput}
                />

                <View style={styles.modalButtons}>
                    <Button
                        status="primary"
                        onPress={handleTransfer}
                        disabled={!transferDetails.fromLocation || !transferDetails.toLocation || transferDetails.quantity <= 0}
                    >
                        Transfer
                    </Button>
                    <Button appearance="ghost" status="basic" onPress={() => setTransferModalVisible(false)}>
                        Cancel
                    </Button>
                </View>
            </Layout>
        </DMModal>
    )
}

const styles = StyleSheet.create({
    container: {
        padding: 16,
        height: '80%',
    },
    header: {
        marginBottom: 16,
    },
    headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#ccc',
        paddingBottom: 8,
    },
    itemRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#eee',
        paddingVertical: 8,
    },
    itemColumn: {
        maxWidth: 300,
        justifyContent: 'center',
        flex: 1,
    },
    locationColumn: {
        maxWidth: 120,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    quantityInput: {
        width: 80,
    },
    itemList: {
        maxHeight: 500,
    },
    footer: {
        marginTop: 16,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 8,
    },
    modalContainer: {
        padding: 20,
        borderRadius: 8,
        width: 300,
    },
    modalInput: {
        marginVertical: 8,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
    },
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    headerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 16,
    },
    unsavedLabel: {
        backgroundColor: '#fff3cd',
        padding: 4,
        borderRadius: 4,
        color: '#856404',
    },
    saveButton: {
        marginRight: 0,
    },
    searchContainer: {
        marginBottom: 16,
        flexDirection: 'row',
        gap: 8,
    },
    searchInput: {
        flex: 1,
    },
    separator: {
        height: 1,
        backgroundColor: '#eee',
    },
    emptyContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    saveButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    itemNameContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    totalStock: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 4,
        minWidth: 100,
        textAlign: 'left',
    },
}); 