import React from 'react';
import { Input } from "@ui-kitten/components";

interface InputTextFieldProps {
    field: string,
    value: string,
    onChange: Function,
    label: string,
    disabled: boolean
}

export const InputTextField = ({
    field,
    value,
    onChange,
    label,
    disabled
}: InputTextFieldProps) => {
    return (
        <Input
            label={label}
            value={value}
            disabled={disabled}
            onChangeText={(val: string) => onChange(field, val)}
        />
    )
} 