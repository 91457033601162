import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { View, StyleSheet, ScrollView, useWindowDimensions, Animated, Easing } from 'react-native';
import { Button, Text, Layout, Input, Card, Select, SelectItem, Spinner, Icon } from '@ui-kitten/components';
import { Order, OrderDetails, OrderItem, OrderStatus } from '../../models/Order.model';
import { Company, CompanySavedAddress } from '../../models/Company.model';
import { InventoryItem } from '../../models/Inventory.model';
import { JobAddress, JobCustomerDetails, JobLocation, JobVehicleDetails } from '../../models/Job.model';
import { LINE_ITEM_BILLED_TO } from '../../models/LineItem.model';
import { LineItem } from '../../models/LineItem.model';
import { AddressDetailsCard } from '../common/address/AddressComponents';
import * as ClientController from '../../functions/client.controller';
import { Client } from '../../models/Client.model';
import { createOrder } from '../../functions/order.controller';
import * as StorageController from '../../functions/storageController';
import { InventoryDetailsCard } from '../job/details/inventory/inventoryDetailsCard.component';
import { VendorProfile } from '../../models/VendorProfile.model';
import { VendorStore } from '../PartsNetwork/VendorStore';
import { CartItem } from './PartsNetworkContainerScreen';
import * as CompanyController from '../../functions/company.controller';
import { useAppStateContext } from '../../contexts/AppStateContext';
import { LineItemsList } from '../job/details/inventory/LineItemsList.component';

interface CreateOrderComponentProps {
    onOrderCreated: (order: Order) => void;
    onClose: () => void;
}

export const CreateOrderComponent: React.FC<CreateOrderComponentProps> = ({
    onOrderCreated,
    onClose,
}) => {
    const currentCompany = StorageController.getCurrentCompany();
    const [isLoading, setIsLoading] = useState(false);
    const [clients, setClients] = useState<Client[]>([]);
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [selectedItems, setSelectedItems] = useState<LineItem[]>([]);

    const [clientCompany, setClientCompany] = useState<Company | null>(null);
    const [vendorCompany, setVendorCompany] = useState<Company | null>(null);

    const [cart, setCart] = useState<CartItem[]>([]);

    const {
        getCurrentCompany,
    } = useAppStateContext();

    const [orderDetails, setOrderDetails] = useState<OrderDetails>({
        dispatch_comments: '',
        vehicle_details: new JobVehicleDetails({}),
        customer_details: new JobCustomerDetails({}),
        address: new JobAddress({}),
        location: new JobLocation({}),
        delivery_fee: 0,
        scheduled_date: null,
        client_company_name: '',
        vendor_company_name: currentCompany?.name ?? '',
    });

    const { width, height } = useWindowDimensions();

    const IS_SMALL_SCREEN = width < 768;

    const [showCart, setShowCart] = useState(false);
    const slideAnim = useRef(new Animated.Value(0)).current;

    const toggleCart = () => {
        setShowCart(prev => {
            if (!prev) {
                Animated.timing(slideAnim, {
                    toValue: 1,
                    duration: 300,
                    easing: Easing.out(Easing.quad),
                    useNativeDriver: true,
                }).start();
            } else {
                Animated.timing(slideAnim, {
                    toValue: 0,
                    duration: 200,
                    easing: Easing.in(Easing.quad),
                    useNativeDriver: true,
                }).start();
            }
            return !prev;
        });
    };

    const cartTranslateX = slideAnim.interpolate({
        inputRange: [0, 1],
        outputRange: [400, 0],
    });

    useEffect(() => {
        loadClients();
    }, []);

    // update the selected items from the current cart
    useEffect(() => {
        setSelectedItems(cartItemsToLineItems(cart));
    }, [cart]);

    const loadClients = async () => {
        setIsLoading(true);
        try {
            const fetchedClients = await ClientController.getClientsByCompanyId(currentCompany?._id);
            setClients(fetchedClients.filter((client: Client) => !client.deleted));
            if (fetchedClients.length > 0) {
                // fetch the company for the first client
                const clientCompany = await CompanyController.getCompanyById(fetchedClients[0].linkedClientAccountId);
                if (clientCompany) {
                    setClientCompany(clientCompany);
                }
            }
        } catch (error) {
            console.error('Error loading clients:', error);
        }
        setIsLoading(false);
    };

    const handleAddToCart = (item: InventoryItem, quantity: number, isGSTInclusive: boolean) => {
        console.time('handleAddToCart');

        // Check if item already exists in cart
        const existingItemIndex = cart.findIndex(cartItem => cartItem._id === item._id);

        if (existingItemIndex !== -1) {
            // Update existing item quantity
            const newCart = [...cart];
            newCart[existingItemIndex] = {
                ...newCart[existingItemIndex],
                quantity: quantity
            };
            setCart(newCart);
        } else {
            // Create new inventory item first to ensure we have all the methods
            const inventoryItem = new InventoryItem(item);
            // Create new cart item
            const cartItem = {
                ...inventoryItem,
                quantity,
                vendorId: currentCompany?._id || '',
                vendorName: currentCompany?.name || '',
                isGSTInclusive,
                billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
                paid: false,
                toLineItem: (details?: Partial<LineItem>) => inventoryItem.toLineItem(details)
            } as CartItem;
            setCart(prev => [...prev, cartItem]);
        }

        console.timeEnd('handleAddToCart');
    };

    const handleClientSelect = async (clientId: string) => {
        const client = clients.find(c => c._id === clientId);
        if (client) {
            setSelectedClient(client);
            setOrderDetails(prev => ({
                ...prev,
                customer_details: new JobCustomerDetails({
                    name: client.name,
                    phone: client.contact_info.phone,
                    email: client.contact_info.email,
                }),
                address: new JobAddress(client.contact_info.address),
                location: new JobLocation(client.contact_info.location),
                client_company_name: client.name,
            }));
            if (client.linkedClientAccountId) {
                const clientCompany = await CompanyController.getCompanyById(client.linkedClientAccountId);
                if (clientCompany) {
                    setClientCompany(clientCompany);
                }
            } else {
                setClientCompany(null);
            }
        }
    };

    const calculateTotal = () => {
        return selectedItems.reduce((sum, item) => sum + (item.cost * item.quantity), 0);
    };

    const handleCreateOrder = useCallback(async () => {
        if (!selectedClient) return;

        setIsLoading(true);
        try {
            // Convert LineItems to OrderItems
            const orderItems = selectedItems.map(item => {
                return new OrderItem({
                    inventory_item_id: item.inventoryItemId,
                    quantity: item.quantity,
                    price: item.cost,
                    billed_to: item.billed_to,
                    // is_gst_inclusive: item.is_gst_inclusive
                })
            });

            const order = new Order({
                company_id: currentCompany?._id,
                client_company_id: selectedClient.linkedClientAccountId || null,
                vendor_company_id: currentCompany?._id,
                client_profile_id: selectedClient._id,
                // vendor_profile_id: selectedVendor?._id,
                items: orderItems as OrderItem[],
                total_amount: calculateTotal(),
                status: OrderStatus.REQUEST,
                details: orderDetails,
            });

            const createdOrder = await createOrder(order);
            if (createdOrder) {
                console.log("🚀============== ~ file: CreateOrderComponent.tsx:114 ~ handleCreateOrder ~ createdOrder🚀==============", createdOrder)
                onOrderCreated(createdOrder);
            }
        } catch (error) {
            console.error('Error creating order:', error);
        }
        setIsLoading(false);
    }, [selectedClient, orderDetails, selectedItems]);

    const renderClientSelect = () => (
        <Card
            status='basic'
            style={styles.card}>
            <View style={{ flexDirection: 'column', margin: -15 }}>

                <Text category="h6">Select Client</Text>
                <Select
                    label="Client"
                    size='small'
                    value={selectedClient?.name}
                    // @ts-ignore
                    onSelect={index => handleClientSelect(clients[index.row]._id as string)}
                >
                    {clients.map(client =>
                        <SelectItem key={client._id} title={client.name} />
                    )}
                </Select>
            </View>
        </Card>
    );

    const renderCustomerDetails = () => (
        <Card
            status='basic'
            style={styles.card}>
            <View style={{ flexDirection: 'column', margin: -15 }}>

                <Text category="h6">Customer Details</Text>
                <Input
                    label="Name"
                    size='small'
                    value={orderDetails.customer_details.name}
                    onChangeText={value =>
                        setOrderDetails(prev => ({
                            ...prev,
                            customer_details: { ...prev.customer_details, name: value }
                        }))
                    }
                />
                <Input
                    label="Phone"
                    size='small'
                    value={orderDetails.customer_details.phone}
                    onChangeText={value =>
                        setOrderDetails(prev => ({
                            ...prev,
                            customer_details: { ...prev.customer_details, phone: value }
                        }))
                    }
                />
                <Input
                    label="Email"
                    size='small'
                    value={orderDetails.customer_details.email}
                    onChangeText={value =>
                        setOrderDetails(prev => ({
                            ...prev,
                            customer_details: { ...prev.customer_details, email: value }
                        }))
                    }
                />
            </View>
        </Card>
    );

    const memoizedVendorStore = useMemo(() => {
        return <VendorStore
            vendor={currentCompany}
            onBack={() => { }}
            addToCart={handleAddToCart}
            cart={cart}
            clientCompany={clientCompany || getCurrentCompany()}
            selectedClientProfile={selectedClient || null}
        />
    }, [currentCompany, cart, clientCompany, selectedClient]);

    // First, let's add a helper function to convert CartItems to LineItems
    const cartItemsToLineItems = (cartItems: CartItem[]): LineItem[] => {
        return cartItems.map(item => new LineItem({
            name: item.name,
            description: item.description || '',
            quantity: item.quantity,
            cost: item.default_price,
            billed_to: item.billed_to || LINE_ITEM_BILLED_TO.BILL_ALL_BACK, // Default to bill back
            inventoryItemId: item._id,
            is_gst_inclusive: item.isGSTInclusive,
            paid: item.paid || false,
        }));
    };



    // Calculate totals for each billing type
    const customerCostItems = useMemo(() => {
        return cartItemsToLineItems(cart).filter(item =>
            item.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST
        );
    }, [cart]);

    const billbackItems = useMemo(() => {
        return cartItemsToLineItems(cart).filter(item =>
            item.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK
        );
    }, [cart]);

    // Calculate totals
    const customerCostTotal = useMemo(() => {
        return customerCostItems.reduce((total, item) =>
            total + (item.cost * item.quantity), 0
        ).toFixed(2);
    }, [customerCostItems]);

    const billbackTotal = useMemo(() => {
        return billbackItems.reduce((total, item) =>
            total + (item.cost * item.quantity), 0
        ).toFixed(2);
    }, [billbackItems]);

    // Handler for updating cart items when line items are edited
    const handleLineItemUpdate = (updatedLineItem: LineItem) => {
        setCart(prevCart => prevCart.map((cartItem: CartItem) => {
            if (cartItem._id === updatedLineItem.inventoryItemId) {
                return {
                    ...cartItem,
                    quantity: updatedLineItem.quantity,
                    default_price: updatedLineItem.cost,
                    isGSTInclusive: updatedLineItem.is_gst_inclusive,
                    billed_to: updatedLineItem.billed_to,
                    paid: updatedLineItem.paid,
                } as CartItem;
            }
            return cartItem;
        }));
    };

    // Handler for removing items
    const handleLineItemRemove = (lineItem: LineItem) => {
        setCart(prevCart => prevCart.filter(cartItem =>
            cartItem._id !== lineItem.inventoryItemId
        ));
    };


    const getItemsInCartCount = () => {
        return cart.reduce((total, item) => total + item.quantity, 0);
    }

    if (isLoading) {
        return (
            <Layout style={styles.loadingContainer}>
                <Spinner size="large" />
            </Layout>
        );
    }

    return (
        <Layout style={[styles.container, { width: width * 0.9, height: height * 0.9 }]}>
            <View style={styles.header}>
                <Text category="h6">Create New Order</Text>
                <View style={{ flexDirection: 'row', gap: 8 }}>
                    <View style={{ position: 'relative' }}>
                        <Button
                            appearance="ghost"
                            status="success"
                            onPress={toggleCart}
                            accessoryLeft={(props) => <Icon {...props} name="shopping-cart-outline" />}
                        />
                        {cart.length > 0 && (
                            <View style={styles.badge}>
                                <Text style={styles.badgeText}>{getItemsInCartCount()}</Text>
                            </View>
                        )}
                    </View>
                    <Button
                        appearance="ghost"
                        status="danger"
                        onPress={onClose}
                        accessoryLeft={(props) => <Icon {...props} name="close-outline" />}
                    />
                </View>
            </View>

            <View style={styles.columns}>
                <View style={[styles.leftColumn]}>
                    {renderClientSelect()}
                    {renderCustomerDetails()}
                    <AddressDetailsCard
                        address={orderDetails.address}
                        onSetAddress={address => setOrderDetails(prev => ({ ...prev, address }))}
                        onSetLocation={location => setOrderDetails(prev => ({ ...prev, location }))}
                        onSetAddressLocation={(address, location) => {
                            let _orderDetails = { ...orderDetails }
                            _orderDetails.address = new JobAddress(address)
                            _orderDetails.location = new JobLocation(location)
                            setOrderDetails(_orderDetails)
                        }}
                        onSetSavedAddress={(savedAddress: CompanySavedAddress) => {
                            let _orderDetails = { ...orderDetails }
                            _orderDetails.address = new JobAddress(savedAddress.address)
                            _orderDetails.location = new JobLocation(savedAddress.location)
                            setOrderDetails(_orderDetails)
                        }}
                        addressDisplayValue={orderDetails.address.formatted_address}
                        allowEdit={true}
                    />
                </View>
                <View style={[styles.rightColumn]}>
                    <ScrollView >
                        {selectedClient &&
                            memoizedVendorStore
                        }
                    </ScrollView>
                </View>
            </View>

            {showCart && (
                <Animated.View
                    style={[
                        styles.cartContainer,
                        { transform: [{ translateX: cartTranslateX }] }
                    ]}
                >
                    <Layout style={styles.cartContent}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category="h6">Cart</Text>
                            <Button
                                appearance="ghost"
                                status="danger"
                                onPress={toggleCart}
                                accessoryLeft={(props) => <Icon {...props} name="close-outline" />}
                            />
                        </View>
                        <LineItemsList
                            title="Customer Cost Items"
                            items={customerCostItems}
                            total={customerCostTotal}
                            allowEdit={true}
                            onRemove={handleLineItemRemove}
                            onUpdate={handleLineItemUpdate}
                        />
                        <LineItemsList
                            title="Billback Items"
                            items={billbackItems}
                            total={billbackTotal}
                            allowEdit={true}
                            onRemove={handleLineItemRemove}
                            onUpdate={handleLineItemUpdate}
                        />
                        {/* show a total of the cart */}
                        <Text category="h6">Total: ${calculateTotal().toFixed(2)}</Text>
                        <Button
                            onPress={handleCreateOrder}
                            disabled={!selectedClient || selectedItems.length === 0}
                            style={styles.createButton}
                        >
                            Create Order
                        </Button>
                    </Layout>
                </Animated.View>
            )}
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        // flex: 1,
        padding: 16,
        borderWidth: 1,
        borderColor: 'red',
        borderRadius: 10,
    },
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 4,
        borderBottomWidth: 1,
        borderColor: 'gray',
    },
    content: {
        flex: 1,
    },
    columns: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 3,
    },
    leftColumn: {
        // flex: 25,
        flexBasis: '29%',
        flexWrap: 'wrap',
        minWidth: 300,
    },
    rightColumn: {
        // flex: 60,
        flexBasis: '70%',
        flexWrap: 'wrap',
        minWidth: 300,
    },
    cartContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        width: "40%",
        backgroundColor: 'white',
        borderLeftWidth: 1,
        borderColor: '#ddd',
        shadowColor: '#000',
        shadowOffset: { width: -2, height: 0 },
        shadowOpacity: 0.1,
        shadowRadius: 3,
        elevation: 5,
    },
    cartContent: {
        flex: 1,
        padding: 16,
    },
    card: {
        marginBottom: 16,
        padding: 16,
    },
    createButton: {
        marginTop: 16,
    },
    badge: {
        position: 'absolute',
        right: 5,
        top: 5,
        backgroundColor: 'red',
        borderRadius: 10,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    badgeText: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
    },
}); 