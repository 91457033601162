import React, { useState, useEffect, useReducer, useCallback, useRef, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, AppState, TouchableOpacity, ScrollView } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, Spinner, RangeDatepicker, CheckBox, List, OverflowMenu, MenuItem, useTheme } from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import debounce from 'lodash.debounce';
import JobsList from '../job/jobsList.component';
import { JobDetailContainer } from '../job/details/jobDetailContainer.component'
// import { JobDetailContainer } from './details/_JobDetailsContainer.component'
import { HoldingList } from '../job/holdingList.component';
import { CaseList } from '../job/casesList.component';
import DriversList from '../Drivers/DriversList.component';
import AssignJobModal from '../job/modals/assignJobModal.component';
import Map from '../map/map.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as ServiceController from '../../functions/service.controller'
import * as HoldingController from '../../functions/holding.controller'
import * as MqttService from '../../services/mqtt.service'
import * as CaseController from '../../functions/case.controller'
import * as RouteController from '../../functions/route.controller'
import * as CompanyController from '../../functions/company.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _log from '../../utils/log'
import CreateJobModal from '../job/modals/createJobModal.component';
import { CreateRouteContainer } from '../routes/createRouteContainer.component';
import { CreateHoldingContainer } from '../job/holding/createHolding.component';
import { HoldingDetailsModalContainer } from '../job/holding/holdingDetailModal';
import { HoldingDetailsContainer } from '../job/holding/holdingDetailsContainer.component';
import { Job, Service, JOB_STATUS } from '../../models/Job.model';
import { Company } from '../../models/Company.model';
import { Member } from '../../models/Member.model';
import { Case } from '../../models/Case.model';
import { HOLDING_STATUS, Holding } from '../../models/Holding.model';
import { Client } from '../../models/Client.model';
import { Route, ROUTE_STATUS } from '../../models/Route.model';
import { JobReportModal, FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { CreateJobFromHoldingModal } from '../job/holding/createJobFromHoldingModal';
import { CalendarContainer } from '../schedule/calendarContainer.components';
import { CreateCaseContainer } from '../cases/createCaseContainer.component';
import { CaseDetailsContainer } from '../cases/caseDetailsContainer.component';
import Toast from 'react-native-toast-message';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
import { RoutesList } from '../routes/routesList.component';
import { JobStatusFilterButtons } from '../job/components/JobStatusFilterButtons';
import { FilterPanel } from '../job/FilterPanel';
import { SearchJobsHoldingsCases, SEARCH_TYPE } from '../job/SearchJobsHoldingsCases';
import { CounterBadge } from '../job/components/CounterBadge'
import { CaseStatusFilterButtons, CASE_STATUS_FILTER } from '../job/CaseStatusFilterButtons'
import { HoldingStatusFilterButtons } from '../job/HoldingStatusFilterButtons'
import { ViewModeButtons } from '../job/ViewModeButtons';
import { LIST_TABS, ListPanelTabs } from '../job/ListPanelTabs';
import DMModal from '../common/Modal';
import { VendorProfile } from '../../models/VendorProfile.model';
import { OverviewActionBar } from './actionBar/actionBar.component';

export enum VIEW_MODE {
    LIST = "LIST",
    MAP = "MAP",
    CALENDAR = "CALENDAR"
}

export enum FILTER_TYPE {
    JOB = "job",
    HOLDING = "holding",
    CASE = "case",
    ROUTE = "route"
}


export enum JOB_STATUS_FILTER {
    // ALL = "all",
    INCOMPLETE = "incomplete", //all
    REQUEST = "request",
    QUOTE = "quote",
    UNASSIGNED = "unassigned",
    ASSIGNED = "assigned",
    PENDING = "pending",
    COMPLETE = "complete",
    CANCELLED = "cancelled"
}


export const OverviewRender = () => {
    const theme = useTheme()
    const styles = getStyles(theme)
    const { width, height } = useWindowDimensions()

    const [filterType, setFilterType] = useState<FILTER_TYPE>(FILTER_TYPE.JOB)
    const [viewMode, setViewMode] = useState<VIEW_MODE>(VIEW_MODE.MAP)
    const [enableRoutes, setEnableRoutes] = useState<boolean>(true)
    const [enableHoldings, setEnableHoldings] = useState<boolean>(true)
    const [enableCases, setEnableCases] = useState<boolean>(true)


    const [searchResults, setSearchResults] = useState<Job[] | Holding[] | Case[]>([])
    const [showCreateRouteContainer, setShowCreateRouteContainer] = useState<boolean>(false)
    const [showCreateHoldingContainer, setShowCreateHoldingContainer] = useState<boolean>(false)
    const [showCreateCaseContainer, setShowCreateCaseContainer] = useState<boolean>(false)
    const [showCreateJobModal, setShowCreateJobModal] = useState<boolean>(false)

    const [allJobs, setAllJobs] = useState<Job[]>([])
    const [memoizedDrivers, setMemoizedDrivers] = useState<Member[]>([])
    const [selectedCompany, setSelectedCompany] = useState<Company>(StorageController.getAppState().selectedCompany as Company)
    const [routesToShow, setRoutesToShow] = useState<Route[]>([])
    const [selectedRoute, setSelectedRoute] = useState<Route | null>(null)
    const [showDriverDirections, setShowDriverDirections] = useState<boolean>(false)
    const [driverToJobDirections, setDriverToJobDirections] = useState<{ driver: Member, job: Job }[]>([])
    const [zoomToJob, setZoomToJob] = useState<Job | null>(null)


    const [allHoldings, setAllHoldings] = useState<Holding[]>([])
    const [allCases, setAllCases] = useState<Case[]>([])

    const [holdingFilterApplied, setHoldingFilterApplied] = useState<HOLDING_STATUS>(HOLDING_STATUS.HELD)
    const [holdingSearchResults, setHoldingSearchResults] = useState<Holding[]>([])
    const [caseFilterApplied, setCaseFilterApplied] = useState<CASE_STATUS_FILTER>(CASE_STATUS_FILTER.ONGOING)

    const [showCompactJobList, setShowCompactJobList] = useState<boolean>(false)

    const [selectedJob, setSelectedJob] = useState<Job | null>(null)
    const [selectedCase, setSelectedCase] = useState<Case | null>(null)

    const [memoizedUnscheduledJobs, setMemoizedUnscheduledJobs] = useState<Job[]>([])
    const [memoizedScheduledJobs, setMemoizedScheduledJobs] = useState<Job[]>([])
    const [memoizedFilteredJobs, setMemoizedFilteredJobs] = useState<Job[]>([])
    const [selectedHolding, setSelectedHolding] = useState<Holding | null>(null)
    const [memoizedClients, setMemoizedClients] = useState<Client[]>([])
    const [vendorProfiles, setVendorProfiles] = useState<VendorProfile[]>([])
    const [routes, setRoutes] = useState<Route[]>([])





    const onSetSelectedJob = (job: Job) => {
        setZoomToJob(job)
    }

    const clearDirections = () => {

    }

    const onSelectRoute = (route: Route) => {
        setSelectedRoute(route)
    }


    const IS_SMALL_SCREEN = width <= 800

    const resetSearch = () => {
        setSearchResults([])
    }

    const onSearchResultsReturned = (results: Job[] | Holding[] | Case[]) => {
        setSearchResults(results)
    }

    const openCreateRouteContainer = () => {
        setShowCreateRouteContainer(true)
    }

    const openCreateHoldingContainer = () => {
        setShowCreateHoldingContainer(true)
    }

    const openCreateCaseContainer = () => {
        setShowCreateCaseContainer(true)
    }

    const openNewJobModal = () => {
        setShowCreateJobModal(true)
    }

    const onSelectHoldingItem = (holding: Holding) => {
        setSelectedHolding(holding)
    }

    const onSelectCaseItem = (c: Case) => {
        setSelectedCase(c)
    }

    const onSelectRouteItem = (route: Route) => {
        setSelectedRoute(route)
    }

    const onSelectJobId = (job_id: string) => {

    }

    const onZoomToJobMarker = (job: Job) => {
        setZoomToJob(job)
    }

    const onSetDriverToJobDirections = (driver: Member, job: Job) => {

    }

    const onOpenAssign = (job: Job) => {

    }

    const onEndReached = () => {

    }

    const onUpdateRouteStatus = () => {

    }

    const onRoutesEndReached = () => {

    }

    const onUpdateRoute = () => {

    }

    const vendorAcceptJob = () => {

    }

    const openNewJobModalWithCase = () => {

    }

    const createHoldingWithCase = () => {

    }

    const onSetSelectedCase = (c: Case) => {
        setSelectedCase(c)
    }

    const onSetSelectedHolding = (holding: Holding) => {
        setSelectedHolding(holding)
    }

    const onClearSearchResults = () => {
        setSearchResults([])
    }


    const getDriversJobs = () => {
        return []
    }


    const [listPanelIndex, setListPanelIndex] = useState<LIST_TABS>(LIST_TABS.JOBS)

    const onListPanelIndexChange = (index: LIST_TABS) => {
        setListPanelIndex(index)
        if (index === LIST_TABS.JOBS) {
            setFilterType(FILTER_TYPE.JOB)
        }
        if (index === LIST_TABS.SCHEDULED) {
            setFilterType(FILTER_TYPE.JOB)
        }
        if (index === LIST_TABS.DRIVERS) {
            setFilterType(FILTER_TYPE.JOB)
        }
        if (index === LIST_TABS.HOLDINGS) {
            setFilterType(FILTER_TYPE.HOLDING)
        }
        if (index === LIST_TABS.CASES) {
            setFilterType(FILTER_TYPE.CASE)
        }
        if (index === LIST_TABS.ROUTES) {
            setFilterType(FILTER_TYPE.ROUTE)
        }
    }

    const onRoutesTabSelected = () => {
        setListPanelIndex(LIST_TABS.ROUTES)
    }

    const scheduledJobsCount = 0
    const onlineDriversCount = 0
    const holdingsCount = 0
    const ongoingCasesCount = 0

    const [filterApplied, setFilterApplied] = useState<JOB_STATUS_FILTER>(JOB_STATUS_FILTER.INCOMPLETE)
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const fetchedJobsRef = useRef<Job[]>([])

    const onSetFilterApplied = (filter: JOB_STATUS_FILTER) => {
        setFilterApplied(filter)
    }

    const RenderMemoizedJobList = useMemo(() => {
        const viewedBy = StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"
        return (
            <View style={{ flex: 1 }}>
                <View style={{ position: 'absolute', top: -20, left: 0, zIndex: 10 }}>
                    <Button
                        size="tiny"
                        appearance="ghost"
                        status="primary"
                        onPress={() => setShowCompactJobList(!showCompactJobList)}>
                        {showCompactJobList ? 'Show Large' : 'Show Compact'}
                    </Button>
                </View>
                <JobsList
                    // jobs={memoizedFilteredJobs}
                    showCompact={showCompactJobList}
                    selectedJobId={selectedJob && selectedJob._id}
                    jobs={memoizedUnscheduledJobs}
                    clients={selectedCompany.clients}
                    drivers={selectedCompany.members}
                    var_vendorProfiles={vendorProfiles}
                    fn_onEndReached={onEndReached}
                    fn_zoomToMarker={onZoomToJobMarker}
                    fn_selectJob={(job: Job) => { onSetSelectedJob(job) }}
                    var_viewedBy={viewedBy}
                    fn_setDirections={onSetDriverToJobDirections}
                    fn_openAssign={onOpenAssign}
                    fn_vendorAcceptJob={vendorAcceptJob}
                />
            </View>
        )
    }, [memoizedUnscheduledJobs, memoizedFilteredJobs, memoizedClients, memoizedDrivers, selectedCompany, viewMode, showCompactJobList])//selectedJob


    const RenderMemoizedScheduledJobList = useMemo(() => {
        const viewedBy = StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"
        return (
            <View style={{ flex: 1 }}>
                <View style={{ position: 'absolute', top: -20, left: 0, zIndex: 10 }}>
                    <Button
                        size="tiny"
                        appearance="ghost"
                        status="primary"
                        onPress={() => setShowCompactJobList(!showCompactJobList)}>
                        {showCompactJobList ? 'Show Large' : 'Show Compact'}
                    </Button>
                </View>
                <JobsList
                    // jobs={memoizedFilteredJobs}
                    showCompact={showCompactJobList}
                    selectedJobId={selectedJob && selectedJob._id}
                    jobs={memoizedScheduledJobs}
                    clients={selectedCompany.clients}
                    drivers={selectedCompany.members}
                    var_vendorProfiles={vendorProfiles}
                    fn_onEndReached={onEndReached}
                    fn_zoomToMarker={onZoomToJobMarker}
                    fn_selectJob={(job: Job) => { onSetSelectedJob(job) }}
                    var_viewedBy={viewedBy}
                    fn_setDirections={onSetDriverToJobDirections}
                    fn_openAssign={onOpenAssign}
                    fn_vendorAcceptJob={vendorAcceptJob}
                />
            </View>
        )
    }, [memoizedUnscheduledJobs, memoizedFilteredJobs, memoizedClients, memoizedDrivers, selectedCompany, viewMode, showCompactJobList])//selectedJob


    // drivers list
    const RenderMemoizedDriversList = useMemo(() => {
        const viewedBy = StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"
        return (
            <DriversList
                var_clients={memoizedClients}
                var_drivers={memoizedDrivers}
                fn_selectDriver={() => { }}
                fn_zoomToMarker={onZoomToJobMarker}
                fn_selectJob={onSetSelectedJob}
                fn_setDirections={onSetDriverToJobDirections}
                var_viewedBy={viewedBy}
            />
        )
    }, [memoizedDrivers])

    const RenderMemoizedHoldingsList = useMemo(() => {
        return (
            <HoldingList
                holdingFilter={holdingFilterApplied}
                selectedCompany={selectedCompany}
                preloadedHoldingItems={holdingSearchResults}
                onSelectHolding={onSelectHoldingItem}
                allowShowHoldingDetails={false}
            />
        )
    }, [selectedCompany, holdingFilterApplied, listPanelIndex, holdingSearchResults])

    const RenderMemoizedCasesList = useMemo(() => {
        return (
            <CaseList
                var_viewedBy={StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"}
                selectedCompany={selectedCompany}
                caseFilter={caseFilterApplied}
                var_clients={memoizedClients}
                var_drivers={memoizedDrivers}
                fn_selectJob={onSetSelectedJob}
                fn_openAssign={onOpenAssign}
                fn_zoomToMarker={onZoomToJobMarker}
                fn_setDirections={onSetDriverToJobDirections}
                fn_vendorAcceptJob={vendorAcceptJob}
                fn_onCreateNewCaseJob={openNewJobModalWithCase}
                fn_onCreateNewHolding={createHoldingWithCase}
                var_vendorProfiles={undefined}
                fn_openJobReport={undefined}
                fn_openCaseDetails={onSetSelectedCase}
            />
        )
    }, [selectedCompany, caseFilterApplied, listPanelIndex])


    const RenderMemoizedRoutesList = useMemo(() => {
        return (
            <RoutesList
                routes={routes}
                selectedRoute={selectedRoute}
                onSelectRoute={onSelectRoute}
                onSelectJobId={onSelectJobId}
                onUpdateRouteStatus={onUpdateRouteStatus}
                onEndReached={onRoutesEndReached}
                onUpdateRoute={onUpdateRoute}
            />
        )
    }, [routes])

    const RenderMemoizedMap = useMemo(() => {
        // if tabs are on jobs, then show jobs on map
        const jobs = listPanelIndex == LIST_TABS.JOBS ? memoizedFilteredJobs : []
        const scheduledJobs = listPanelIndex == LIST_TABS.SCHEDULED ? memoizedScheduledJobs : []
        const driverJobs = listPanelIndex == LIST_TABS.DRIVERS ? getDriversJobs() : []
        const allJobs = [...jobs, ...scheduledJobs, ...driverJobs]

        const routesToShow = listPanelIndex == LIST_TABS.ROUTES ? routes : []
        // console.log("allJobs", allJobs)
        return (
            <Map
                jobs={allJobs}
                // jobs={memoizedMapDisplayJobs}
                drivers={memoizedDrivers}
                onSelectJob={onSetSelectedJob}
                company={selectedCompany}
                onClearDirections={clearDirections}
                showDirections={showDriverDirections}
                driverToJobDirections={driverToJobDirections}
                zoomToJob={zoomToJob}
                zoomToDriver={null}
                routes={routesToShow}
                selectedRoute={selectedRoute}
                onSelectRoute={onSelectRoute}
            />
        )
    }, [memoizedFilteredJobs, memoizedDrivers, zoomToJob, showDriverDirections, routes, selectedRoute, listPanelIndex])

    return (
        <View style={{ flex: 1 }}>
            <View style={{ flex: 1 }}>
                <Map
                    jobs={allJobs}
                    // jobs={memoizedMapDisplayJobs}
                    drivers={memoizedDrivers}
                    onSelectJob={onSetSelectedJob}
                    company={selectedCompany}
                    onClearDirections={clearDirections}
                    showDirections={showDriverDirections}
                    driverToJobDirections={driverToJobDirections}
                    zoomToJob={zoomToJob}
                    zoomToDriver={null}
                    routes={routesToShow}
                    selectedRoute={selectedRoute}
                    onSelectRoute={onSelectRoute}
                />
            </View>
            <View style={{ position: 'absolute', top: 10, left: 0, zIndex: 10 }}>
                <OverviewActionBar
                    filterType={filterType}
                    viewMode={viewMode}
                    enableRoutes={enableRoutes}
                    enableHoldings={enableHoldings}
                    enableCases={enableCases}
                    setViewMode={setViewMode}
                    resetSearch={resetSearch}
                    onSearchResultsReturned={onSearchResultsReturned}
                    openCreateRouteContainer={openCreateRouteContainer}
                    openCreateHoldingContainer={openCreateHoldingContainer}
                    openCreateCaseContainer={openCreateCaseContainer}
                    openNewJobModal={openNewJobModal}
                    onSetFilterApplied={onSetFilterApplied}
                    filterApplied={filterApplied}
                    setShowFilters={setShowFilters}
                    fetchedJobsRef={fetchedJobsRef}
                    holdingFilterApplied={holdingFilterApplied}
                    setHoldingFilterApplied={setHoldingFilterApplied}
                    onClearSearchResults={onClearSearchResults}
                    holdingSearchResults={holdingSearchResults}
                    caseFilterApplied={caseFilterApplied}
                    setCaseFilterApplied={setCaseFilterApplied}
                />
            </View>
            <View style={{ position: 'absolute', top: 100, left: 10, zIndex: 10, maxHeight: height * 0.75, width: width * 0.4 }}>

                <View style={{ flexDirection: 'row', flex: 1 }}>
                    <View style={{ flexDirection: 'column', flex: 1 }}>
                        <ListPanelTabs
                            enableRoutes={enableRoutes}
                            enableHoldings={enableHoldings}
                            enableCases={enableCases}
                            listPanelIndex={listPanelIndex}
                            setListPanelIndex={onListPanelIndexChange}
                            onRoutesTabSelected={onRoutesTabSelected}
                            scheduledJobsCount={scheduledJobsCount}
                            onlineDriversCount={onlineDriversCount}
                            holdingsCount={holdingsCount}
                            ongoingCasesCount={ongoingCasesCount}
                        />
                        <View style={{ flex: 1 }}>
                            {listPanelIndex == LIST_TABS.JOBS && RenderMemoizedJobList}
                            {listPanelIndex == LIST_TABS.SCHEDULED && RenderMemoizedScheduledJobList}
                            {listPanelIndex == LIST_TABS.DRIVERS && RenderMemoizedDriversList}
                            {listPanelIndex == LIST_TABS.HOLDINGS && enableHoldings && RenderMemoizedHoldingsList}
                            {listPanelIndex == LIST_TABS.CASES && enableCases && RenderMemoizedCasesList}
                            {listPanelIndex == LIST_TABS.ROUTES && enableRoutes && RenderMemoizedRoutesList}
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

const getStyles = (theme: any) => StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme['background-basic-color-1'],
    },
})