import React, { useState, useEffect, useRef, useMemo } from 'react';
import { StyleSheet, View, ScrollView, Linking } from 'react-native';
import { Text, Button, Layout, Divider, Input, Icon, useTheme, TopNavigation, TopNavigationAction, Modal, Card, Spinner } from '@ui-kitten/components';
import { Order, OrderItem, OrderStatus } from '../../models/Order.model';
import { updateOrder, processPayment, createJobFromOrder, cancelOrder, CancelOrderResult } from '../../functions/order.controller';
import { Company, CompanySavedAddress } from '../../models/Company.model';
import { InventoryCategory, InventoryItem, PricingTier } from '../../models/Inventory.model';
import * as InventoryController from '../../functions/Inventory.controller';
import * as ClientController from '../../functions/client.controller';
import { Client } from '../../models/Client.model';
import * as CompanyController from '../../functions/company.controller';
import * as OrderController from '../../functions/order.controller';
import { OrderDetailsResponse } from '../../functions/order.controller';
import { AddItemModal } from './AddItemModal';
import { CartItem } from './PartsNetworkContainerScreen';
import { PaymentDetails } from './PaymentDetails';
import DMModal from '../common/Modal';
import { TextDetailPill } from '../common/TextDetailPill';
import { JobReportModal } from '../dashboardComponents/jobReportModal.component';
import { Job, LINE_ITEM_BILLED_TO } from '../../models/Job.model';
import * as JobController from '../../functions/job.controller';
import * as StorageController from '../../functions/storageController'
import { JobAddress, JobLocation } from '../../models/Job.model';
import { AddressDetailsCard } from '../job/details/addressDetailsCard.component';
import { IAction, useAppStateChange } from '../../hooks/appStateChange.hook';


interface OrderDetailProps {
    order: Order;
    currentCompany: Company;
    onOrderUpdated: () => void;
    onBack: () => void;
    activeTab: string;
}

export const OrderDetail: React.FC<OrderDetailProps> = ({ order, currentCompany, onOrderUpdated, onBack, activeTab }) => {
    const [editedOrder, setEditedOrder] = useState<Order | null>(new Order(order));
    const [isEditing, setIsEditing] = useState(false);
    const [clientProfile, setClientProfile] = useState<Client | null>(null);
    const [isAddItemModalVisible, setIsAddItemModalVisible] = useState(false);
    const [orderItems, setOrderItems] = useState<OrderDetailsResponse['items']>({});
    const [statusToConfirm, setStatusToConfirm] = useState<OrderStatus | null>(null);
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState<string | null>(null);
    const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const theme = useTheme();
    const styles = getStyles(theme);
    const [isCreatingJob, setIsCreatingJob] = useState(false);
    const [jobCreatedSuccessfully, setJobCreatedSuccessfully] = useState(false);
    const [createdJob, setCreatedJob] = useState<Job | null>(null);
    const [showJobReportModal, setShowJobReportModal] = useState(false);
    const [relatedJob, setRelatedJob] = useState<Job | null>(null);
    const [isLoadingJob, setIsLoadingJob] = useState(false);
    const [isCancellingOrder, setIsCancellingOrder] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [vendorCompany, setVendorCompany] = useState<Company | null>(null);
    // const isVendor = activeTab === 'vendorOrders';
    const isLocalClient = StorageController.getAppState().selectedMembership?.is_client
    const isVendor = (currentCompany._id == order.vendor_company_id)
    const [showEditContactModal, setShowEditContactModal] = useState(false);
    const [showGSTWarningModal, setShowGSTWarningModal] = useState(false);
    const [pendingJobCreation, setPendingJobCreation] = useState(false);

    useEffect(() => {
        const company = StorageController.getCurrentCompany()
        if (!company) return
        if (isVendor && order.vendor_company_id == company._id) {
            console.log("🚀============== ~ file: OrderDetail.tsx:58 ~ useEffect ~ isVendor🚀==============", isVendor)
            onLoad();
        }
        else if (!isVendor && order.client_company_id == company._id) {
            console.log("🚀============== ~ file: OrderDetail.tsx:61 ~ useEffect ~ isVendor🚀==============", isVendor)
            onLoad()
        }
        else {
            // onBack()
            console.log("🚀============== ~ file: OrderDetail.tsx:61 ~ useEffect ~ order🚀==============", order)
        }

    }, [order, isVendor]);

    const getStatusColor = (status: OrderStatus): string => {
        switch (status) {
            case OrderStatus.REQUEST:
                return theme['color-request-500'];
            case OrderStatus.AWAITING_PAYMENT:
                return theme['color-complete-500'];
            case OrderStatus.PROCESSING:
                return theme['color-unassigned-500'];
            case OrderStatus.READY:
                return theme['color-assigned-300'];
            case OrderStatus.SHIPPED:
                return theme['color-assigned-500'];
            case OrderStatus.DELIVERED:
                return theme['color-pending-500'];
            case OrderStatus.COMPLETED:
                return theme['color-complete-500'];
            case OrderStatus.CANCELLED:
                return theme['color-cancelled-500'];
            default:
                return theme['color-basic-600'];
        }
    };

    const getStatusText = (status: OrderStatus): string => {
        // capitalize the first letter of the status and remove the underscores
        return status.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    }

    const onLoad = async () => {
        // onSetEditedOrder(null)
        console.log("🚀============== ~ file: OrderDetail.tsx:85 ~ onLoad ~ onLoad🚀==============")
        setIsLoading(true);
        await fetchOrderDetails();
        await fetchClientProfile();
        await fetchVendorCompany();
        setIsLoading(false);
        onSetEditedOrder(order)
    }

    useEffect(() => {
        return () => {
            if (pollIntervalRef.current) {
                clearInterval(pollIntervalRef.current);
            }
        };
    }, []);

    const fetchOrderDetails = async () => {
        if (!order._id) return;
        const details = await OrderController.getOrderById(order._id);
        console.log("🚀============== ~ file: OrderDetail.tsx:56 ~ fetchOrderDetails ~ details🚀==============", details)
        if (details) {
            onSetEditedOrder(details.order);
            setOrderItems(details.items);
        }
    };

    const editedOrderRef = useRef<Order | null>(editedOrder);

    const onSetEditedOrder = (order: Order | null) => {
        console.log("🚀============== ~ file: OrderDetail.tsx:612 ~ onSetEditedOrder ~ order🚀==============", order)
        editedOrderRef.current = order ? new Order(order) : null;
        setEditedOrder(order ? new Order(order) : null);
    };

    const fetchClientProfile = async () => {
        if (!isVendor && order.vendor_company_id && order.client_company_id) {
            const profile = await ClientController.getClientByCompanyAndClientLinkingId(order.vendor_company_id, order.client_company_id);
            console.log("🚀============== ~ file: OrderDetail.tsx:117 ~ fetchClientProfile ~ profile🚀==============", profile)
            setClientProfile(profile);
        }
    };

    const fetchVendorCompany = async () => {
        if (!order.vendor_company_id) return;
        const vendorCompany = await CompanyController.getCompanyById(order.vendor_company_id);
        console.log("🚀============== ~ file: OrderDetail.tsx:123 ~ fetchVendorCompany ~ vendorCompany🚀==============", vendorCompany)
        if (vendorCompany) {
            console.log("🚀============== ~ file: OrderDetail.tsx:124 ~ fetchVendorCompany ~ vendorCompany🚀==============", vendorCompany)
            setVendorCompany(vendorCompany);
        }
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region EVENT HANDLERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handleUpdateOrderEvent = async (action: IAction) => {
        console.log("🚀============== ~ file: OrderDetail.tsx:145 ~ handleUpdateOrderEvent ~ action🚀==============", action)
        if (action.data.order._id == editedOrderRef.current?._id) {
            fetchOrderDetails();
        }
    }

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedOrders: handleUpdateOrderEvent
    });



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region Update Order
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handleUpdateOrder = async () => {
        console.log("🚀============== ~ file: OrderDetail.tsx:146 ~ handleUpdateOrder ~ editedOrderRef.current🚀==============", editedOrderRef.current)
        if (editedOrderRef.current && editedOrderRef.current._id) {
            const updatedOrder = await updateOrder(editedOrderRef.current._id, editedOrderRef.current);
            if (updatedOrder) {
                onOrderUpdated();
                setIsEditing(false);
                fetchOrderDetails(); // Reload the order to ensure we have the latest data
                // onSetEditedOrder(updatedOrder);
            }
            // setTimeout(async () => {
            //     await onLoad();
            // }, 100);
        }
    };

    const handleStatusChange = (newStatus: Order['status']) => {
        setStatusToConfirm(newStatus);
    };

    const confirmStatusChange = async () => {
        if (statusToConfirm && editedOrderRef.current) {
            const updatedOrder = new Order({ ...editedOrderRef.current, status: statusToConfirm });
            onSetEditedOrder(updatedOrder);
            setStatusToConfirm(null);

            // Save/update the order
            if (updatedOrder._id) {
                const savedOrder = await updateOrder(updatedOrder._id, updatedOrder);
                if (savedOrder) {
                    onOrderUpdated();
                    fetchOrderDetails(); // Reload the order to ensure we have the latest data
                }
            }
        }
    };

    const cancelStatusChange = () => {
        setStatusToConfirm(null);
    };

    const handleQuantityChange = (itemId: string | null, newQuantity: number) => {
        if (!editedOrderRef.current) return
        const updatedItems = editedOrderRef.current.items.map(item =>
            item.inventory_item_id === itemId ? new OrderItem({ ...item, quantity: newQuantity }) : item
        );
        const newTotalAmount = calculateTotalCost(updatedItems as OrderItem[]);
        onSetEditedOrder(new Order({ ...editedOrderRef.current, items: updatedItems as OrderItem[], total_amount: newTotalAmount }));
    };

    const handlePriceChange = (itemId: string | null, newPrice: number) => {
        if (!editedOrderRef.current) return
        const updatedItems = editedOrderRef.current.items.map(item =>
            item.inventory_item_id === itemId ? new OrderItem({ ...item, price: newPrice }) : item
        );
        const newTotalAmount = calculateTotalCost(updatedItems as OrderItem[]);
        onSetEditedOrder(new Order({ ...editedOrderRef.current, items: updatedItems as OrderItem[], total_amount: newTotalAmount }));
    };

    const handleAddItems = (item: InventoryItem, quantity: number, isGSTInclusive: boolean) => {
        if (!editedOrderRef.current) return
        const updatedItems = [
            ...editedOrderRef.current.items,
            {
                inventory_item_id: item._id,
                quantity: quantity,
                price: item.default_price,
                billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
                // is_gst_inclusive: isGSTInclusive,
            }
        ] as Order['items'];
        const newTotalAmount = calculateTotalCost(updatedItems as OrderItem[]);
        onSetEditedOrder(new Order({ ...editedOrderRef.current, items: updatedItems as Order['items'], total_amount: newTotalAmount }));
        // also add the items to the orderdetails items
        setOrderItems({
            ...orderItems,
            [item._id as string]: {
                item: item,
                category: item.inventory_category_id,
                tierPrice: item.default_price,
                quantity: quantity,
            }
        });
    };

    const handleRemoveItem = (itemId: string | null) => {
        if (!editedOrderRef.current) return
        const updatedItems = editedOrderRef.current.items.filter(item => item.inventory_item_id !== itemId);
        const newTotalAmount = calculateTotalCost(updatedItems as OrderItem[]);
        onSetEditedOrder(new Order({ ...editedOrderRef.current, items: updatedItems as OrderItem[], total_amount: newTotalAmount }));
    };

    const renderStatusButtons = () => {
        if (!editedOrderRef.current) return
        const statuses: OrderStatus[] = [OrderStatus.REQUEST, OrderStatus.AWAITING_PAYMENT, OrderStatus.PROCESSING, OrderStatus.READY, OrderStatus.SHIPPED, OrderStatus.DELIVERED, OrderStatus.COMPLETED, OrderStatus.CANCELLED];
        return (
            <View style={styles.statusButtonContainer}>
                {statuses.map((status) => (
                    <Button
                        key={status}
                        size="tiny"
                        appearance={editedOrderRef.current && editedOrderRef.current.status === status ? 'filled' : 'outline'}
                        onPress={() => handleStatusChange(status)}
                        style={[styles.statusButton, { backgroundColor: editedOrderRef.current && editedOrderRef.current.status === status ? getStatusColor(status) : 'transparent' }]}

                    >
                        {getStatusText(status)}
                    </Button>
                ))}
            </View>
        );
    };

    const handleBilledToChange = (itemId: string | null, billedTo: LINE_ITEM_BILLED_TO) => {
        if (!editedOrderRef.current) return
        const updatedItems = editedOrderRef.current.items.map(item =>
            item.inventory_item_id === itemId ? new OrderItem({ ...item, billed_to: billedTo }) : item
        );
        onSetEditedOrder(new Order({ ...editedOrderRef.current, items: updatedItems as OrderItem[] }));
    };

    //#region Render Item Details

    const renderItemDetails = (orderItem: OrderItem, index: number) => {
        console.log("🚀============== ~ file: OrderDetail.tsx:306 ~ renderItemDetails ~ orderItem🚀==============", orderItem)
        const itemDetails = orderItems[orderItem.inventory_item_id as string];
        if (!itemDetails) return null;

        const { item: inventoryItem, category, tierPrice } = itemDetails;
        const gstRegistered = StorageController.getCurrentCompany().settings?.business_info?.gst_registered;
        return (
            <View key={index} style={styles.itemRow}>
                {/* {gstRegistered &&
                    <View style={{ position: 'absolute', left: 0, top: 0 }}>
                        <Text style={{ fontSize: 10 }}>{orderItem.is_gst_inclusive ? '(GST INC)' : '(GST EXC)'}</Text>
                    </View>
                } */}
                <Text style={styles.itemName}>{inventoryItem.name || 'Unknown Item'}</Text>
                {!isLocalClient && isVendor && isEditing ? (
                    <>
                        <Input
                            style={styles.itemQuantityInput}
                            size="small"
                            value={orderItem.quantity.toString()}
                            onChangeText={(text) => handleQuantityChange(orderItem.inventory_item_id, parseInt(text) || 0)}
                            keyboardType="numeric"
                        />
                        <Input
                            style={styles.itemPriceInput}
                            size="small"
                            value={orderItem.price.toFixed(2)}
                            onChangeText={(text) => handlePriceChange(orderItem.inventory_item_id, parseFloat(text) || 0)}
                            keyboardType="numeric"
                        />
                        <View style={{ flexDirection: 'row' }}>
                            <Button
                                size="tiny"
                                appearance="ghost"
                                status={orderItem.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK ? 'primary' : 'basic'}
                                onPress={() => handleBilledToChange(orderItem.inventory_item_id, LINE_ITEM_BILLED_TO.BILL_ALL_BACK)}
                            >
                                Bill Back
                            </Button>
                            <Button
                                size="tiny"
                                appearance="ghost"
                                status={orderItem.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST ? 'primary' : 'basic'}
                                onPress={() => handleBilledToChange(orderItem.inventory_item_id, LINE_ITEM_BILLED_TO.CUSTOMER_COST)}
                            >
                                Customer Cost
                            </Button>
                        </View>
                    </>
                ) : (
                    <>
                        <Text style={styles.itemQuantity}>{orderItem.quantity}</Text>
                        <Text style={styles.itemPrice}>${orderItem.price.toFixed(2)}</Text>
                        {/* {!isEditing && gstRegistered && <Text style={styles.itemPrice}>${orderItem.getGSTOnlyAmount().toFixed(2)}</Text>} */}
                    </>
                )}
                <Text style={styles.itemTotal}>${(orderItem.quantity * orderItem.price).toFixed(2)}</Text>
                {!isLocalClient && isVendor && isEditing && (
                    <View style={styles.itemActions}>
                        <Button
                            size="tiny"
                            status="danger"
                            onPress={() => handleRemoveItem(orderItem.inventory_item_id)}
                            accessoryLeft={(props) => <Icon {...props} name="trash-2-outline" />}
                        />
                    </View>
                )}
            </View>
        );
    };


    //#region Render Items List

    const renderItemsList = useMemo(() => {
        if (!editedOrderRef.current) return
        return editedOrderRef.current.items.map((item, index) => renderItemDetails(new OrderItem(item), index));
    }, [editedOrder, orderItems, isEditing]);

    const BackIcon = (props: any) => (
        <Icon {...props} name='arrow-back' />
    );

    const EditIcon = (props: any) => (
        <Icon {...props} name='edit-2-outline' />
    );

    const renderBackAction = () => (
        <TopNavigationAction icon={BackIcon} onPress={onBack} />
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Render Edit Action
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderEditAction = () => (
        isVendor && (
            <TopNavigationAction
                icon={EditIcon}
                onPress={isEditing ? handleUpdateOrder : () => setIsEditing(true)}
            />
        )
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region Edit Buttons
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const RenderVendorEditButtons = () => {
        // Only show edit buttons if the order is in "request" status
        if (!editedOrderRef.current) return
        if (editedOrderRef.current.status !== OrderStatus.REQUEST) {
            return null;
        }

        return (
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                {isEditing ? (
                    <>
                        <Button
                            onPress={handleUpdateOrder}
                            accessoryLeft={(props) => <Icon {...props} name="checkmark-outline" />}
                            appearance="filled"
                            status="success"
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </Button>
                        <Button
                            onPress={() => {
                                setIsEditing(false);
                                fetchOrderDetails(); // This will reload the original order
                            }}
                            accessoryLeft={(props) => <Icon {...props} name="close-outline" />}
                            appearance="ghost"
                            status="danger"
                        >
                            Cancel
                        </Button>
                    </>
                ) : (
                    <Button
                        onPress={() => setIsEditing(true)}
                        accessoryLeft={(props) => <Icon {...props} name="edit-2-outline" />}
                        appearance="ghost"
                        status="warning"
                    >
                        Edit
                    </Button>
                )}
            </View>
        );
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Job Creation
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handleCreateJob = async () => {
        if (!editedOrderRef.current) return
        if (editedOrderRef.current._id) {
            const isGSTRegistered = StorageController.getCurrentCompany().settings?.business_info?.gst_registered;
            const hasGSTExclusiveItems = editedOrderRef.current.items.some(item => !item.is_gst_inclusive);

            if (isGSTRegistered && hasGSTExclusiveItems) {
                setPendingJobCreation(true);
                setShowGSTWarningModal(true);
                return;
            }

            setIsCreatingJob(true);
            const result = await createJobFromOrder(editedOrderRef.current._id);
            setIsCreatingJob(false);
            if (result.success && result.order && result.job) {
                onSetEditedOrder(result.order);
                setCreatedJob(result.job);
                setJobCreatedSuccessfully(true);
            } else {
                // Show an error message
                alert('Failed to create job: ' + (result.message || 'Unknown error'));
            }
        }
    };



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Job Created Success Modal
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const JobCreatedSuccessModal = () => (
        <DMModal visible={jobCreatedSuccessfully} onClose={() => setJobCreatedSuccessfully(false)}>
            <Card disabled>
                <Text category="h6" style={styles.modalTitle}>Job Created Successfully</Text>
                <Text>The job has been created from this order.</Text>
                <View style={styles.modalButtonContainer}>
                    <Button onPress={() => setJobCreatedSuccessfully(false)} status="basic" style={styles.modalButton}>
                        Close
                    </Button>
                    <Button onPress={() => {
                        setJobCreatedSuccessfully(false);
                        setShowJobReportModal(true);
                    }} status="primary" style={styles.modalButton}>
                        View Job Details
                    </Button>
                </View>
            </Card>
        </DMModal>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Cancel Order
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handleCancelOrder = async () => {
        if (!editedOrderRef.current) return
        if (editedOrderRef.current._id) {
            setIsCancellingOrder(true);
            const result = await cancelOrder(editedOrderRef.current._id);
            setIsCancellingOrder(false);
            if (result.success) {
                onSetEditedOrder(result.order as Order);
                onOrderUpdated();
                alert('Order cancelled successfully');
            } else {
                alert('Failed to cancel order: ' + result.message);
            }
        }
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Order Actions
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderOrderActions = () => (
        <View style={styles.actionsContainer}>
            {!isLocalClient && isVendor ? (
                <>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                        <Text category="s1" style={styles.actionTitle}>Update Status</Text>
                        {editedOrder && [OrderStatus.REQUEST, OrderStatus.AWAITING_PAYMENT, OrderStatus.PROCESSING].includes(editedOrder.status) && (
                            <Button
                                status="danger"
                                size="small"
                                onPress={handleCancelOrder}
                                accessoryLeft={(props) =>
                                    isCancellingOrder ?
                                        <Spinner size="small" /> :
                                        <Icon {...props} name="close-circle-outline" />
                                }
                                style={styles.actionButton}
                                disabled={isCancellingOrder}
                            >
                                {isCancellingOrder ? 'Cancelling...' : 'Cancel Order'}
                            </Button>
                        )}
                    </View>
                    {renderStatusButtons()}
                    {editedOrder && !editedOrder.related_job_id &&
                        ![OrderStatus.REQUEST, OrderStatus.AWAITING_PAYMENT].includes(editedOrder.status) && (
                            <Button
                                status="info"
                                onPress={handleCreateJob}
                                accessoryLeft={(props) =>
                                    isCreatingJob ?
                                        <Spinner size="small" /> :
                                        <Icon {...props} name="file-text-outline" />
                                }
                                style={styles.actionButton}
                                disabled={isCreatingJob}
                            >
                                {isCreatingJob ? 'Creating Job...' : 'Create Job'}
                            </Button>
                        )}
                </>
            ) : (
                <>
                    {editedOrder && editedOrder.status === 'awaiting_payment' && (
                        <Button
                            status="success"
                            onPress={handlePaymentProcess}
                            accessoryLeft={(props) => <Icon {...props} name="credit-card-outline" />}
                            style={styles.actionButton}
                        >
                            Process Payment
                        </Button>
                    )}
                </>
            )}

        </View>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Order Info
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderOrderInfo = () => (
        <View style={{ flexDirection: 'column', gap: 10 }}>

            <View style={styles.orderInfoContainer}>
                <View style={styles.orderInfoItem}>
                    <Icon name="hash-outline" style={styles.infoIcon} fill={theme['text-hint-color']} />
                    <View>
                        <Text style={styles.infoLabel}>Order ID</Text>
                        <Text style={styles.infoValue}>{editedOrder && (editedOrder.friendly_id || editedOrder._id) || 'N/A'}</Text>
                    </View>
                </View>
                <View style={styles.orderInfoItem}>
                    <Icon name="activity-outline" style={styles.infoIcon} fill={theme['text-hint-color']} />
                    <View>
                        <Text style={styles.infoLabel}>Status</Text>
                        <Text style={styles.infoValue}>{editedOrder && editedOrder.status || 'N/A'}</Text>
                    </View>
                </View>
                <View style={styles.orderInfoItem}>
                    <Icon name="credit-card-outline" style={styles.infoIcon} fill={theme['text-hint-color']} />
                    <View>
                        <Text style={styles.infoLabel}>Total Amount</Text>
                        <Text style={styles.infoValue}>${editedOrder && editedOrder.total_amount.toFixed(2) || 'N/A'}</Text>
                    </View>
                </View>
                {editedOrder && editedOrder.related_job_id && (
                    <View style={styles.orderInfoItem}>
                        <Icon name="file-text-outline" style={styles.infoIcon} fill={theme['text-hint-color']} />
                        <View>
                            <Text style={styles.infoLabel}>Related Job ID</Text>
                            <Text style={styles.infoValue}>{editedOrder && editedOrder.related_job_friendly_id || 'N/A'}</Text>
                        </View>
                        <Button
                            size="small"
                            status="info"
                            appearance="ghost"
                            accessoryLeft={(props) => <Icon {...props} name="eye-outline" />}
                            onPress={handleOpenJobDetails}
                            disabled={isLoadingJob || !relatedJob}
                            style={styles.viewJobButton}
                        >
                            {isLoadingJob ? 'Loading...' : 'View Job'}
                        </Button>
                    </View>
                )}
            </View>
        </View>
    );


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region Contact Info Update
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const handleContactInfoUpdate = () => {
        if (!editedOrderRef.current) return;
        handleUpdateOrder();
        setShowEditContactModal(false);
    };

    const handleAddressUpdate = (address: JobAddress) => {
        if (!editedOrderRef.current) return;
        const updatedDetails = {
            ...editedOrderRef.current.details,
            address: address
        };
        onSetEditedOrder(new Order({ ...editedOrderRef.current, details: updatedDetails }));
    };

    const handleLocationUpdate = (location: any) => {
        if (!editedOrderRef.current) return;
        const updatedDetails = {
            ...editedOrderRef.current.details,
            location: location
        };
        onSetEditedOrder(new Order({ ...editedOrderRef.current, details: updatedDetails }));
    };

    const handleSavedAddressUpdate = (savedAddress: CompanySavedAddress) => {
        if (!editedOrderRef.current) return;
        const updatedDetails = {
            ...editedOrderRef.current.details,
            address: new JobAddress(savedAddress.address),
            location: new JobLocation(savedAddress.location)
        };
        onSetEditedOrder(new Order({ ...editedOrderRef.current, details: updatedDetails }));
    };

    const renderOrderContactInfo = () => (
        <View style={{ flexDirection: 'column', gap: 10 }}>
            <View style={[styles.orderInfoContainer, { gap: 10 }]}>
                <Card
                    disabled
                    header={
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Text category="s1">Client Info - {editedOrder?.details.client_company_name || ""}</Text>
                                <Button
                                    size="small"
                                    status="warning"
                                    appearance="ghost"
                                    accessoryLeft={(props) => <Icon {...props} name="edit-2-outline" />}
                                    onPress={() => setShowEditContactModal(true)}
                                />
                            </View>
                        </View>
                    }
                    style={{ flex: 1 }}>
                    <View style={{ flexDirection: 'column' }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="s1">Contact</Text>
                            <Text category="s2">{`${editedOrder?.details?.customer_details?.name}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="s1">Email</Text>
                            <Text category="s2">{`${editedOrder?.details?.customer_details?.email}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="s1">Phone</Text>
                            <Text category="s2">{`${editedOrder?.details?.customer_details?.phone}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category="s1">Address</Text>
                            <Text category="s2">{`${editedOrder?.details?.address?.formatted_address}`}</Text>
                        </View>
                    </View>
                </Card>

                <Card
                    style={{ flex: 1 }}
                    disabled
                    header={<Text category="s1">Vendor Info - {editedOrder?.details?.vendor_company_name || ""}</Text>}>
                    <View style={{ flexDirection: 'column' }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="s1">Email:</Text>
                            <Text category="s2">{`${vendorCompany?.settings?.contact_info?.email || ""}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="s1">Phone:</Text>
                            <Text category="s2">{`${vendorCompany?.settings?.contact_info?.phone || ""}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="s1">Address:</Text>
                            <Text category="s2">{`${vendorCompany?.settings?.location?.address?.formatted_address || ""}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="s1">Contact:</Text>
                            <Text category="s2">{`${vendorCompany?.settings?.contact_info?.name || ""}`}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="s1">ABN:</Text>
                            <Text category="s2">{`${vendorCompany?.settings?.business_info?.abn || ""}`}</Text>
                        </View>


                    </View>
                </Card>
            </View>

            <DMModal
                visible={showEditContactModal}
                onClose={() => setShowEditContactModal(false)}
            >
                <Card disabled style={{ width: 600, maxHeight: '90vh' }}>
                    <View style={styles.modalHeader}>
                        <Text category="h6">Edit Contact Information</Text>
                        <Button
                            size="small"
                            status="basic"
                            appearance="ghost"
                            accessoryLeft={(props) => <Icon {...props} name="close" />}
                            onPress={() => setShowEditContactModal(false)}
                        />
                    </View>

                    <ScrollView style={styles.modalContent}>
                        <View style={styles.contactInputs}>
                            <Input
                                label="Name"
                                defaultValue={editedOrder?.details?.customer_details?.name}
                                onChangeText={(value) => {
                                    if (!editedOrderRef.current) return;
                                    const updatedDetails = {
                                        ...editedOrderRef.current.details,
                                        customer_details: {
                                            ...editedOrderRef.current.details.customer_details,
                                            name: value
                                        }
                                    };
                                    onSetEditedOrder(new Order({ ...editedOrderRef.current, details: updatedDetails }));
                                }}
                                style={styles.input}
                            />
                            <Input
                                label="Email"
                                defaultValue={editedOrder?.details?.customer_details?.email}
                                onChangeText={(value) => {
                                    if (!editedOrderRef.current) return;
                                    const updatedDetails = {
                                        ...editedOrderRef.current.details,
                                        customer_details: {
                                            ...editedOrderRef.current.details.customer_details,
                                            email: value
                                        }
                                    };
                                    onSetEditedOrder(new Order({ ...editedOrderRef.current, details: updatedDetails }));
                                }}
                                style={styles.input}
                            />
                            <Input
                                label="Phone"
                                defaultValue={editedOrder?.details?.customer_details?.phone}
                                onChangeText={(value) => {
                                    if (!editedOrderRef.current) return;
                                    const updatedDetails = {
                                        ...editedOrderRef.current.details,
                                        customer_details: {
                                            ...editedOrderRef.current.details.customer_details,
                                            phone: value
                                        }
                                    };
                                    onSetEditedOrder(new Order({ ...editedOrderRef.current, details: updatedDetails }));
                                }}
                                style={styles.input}
                            />
                        </View>

                        <AddressDetailsCard
                            address={editedOrderRef.current?.details?.address || new JobAddress({})}
                            addressDisplayValue={editedOrderRef.current?.details?.address?.formatted_address || ''}
                            onSetAddress={handleAddressUpdate}
                            onSetLocation={handleLocationUpdate}
                            onSetSavedAddress={handleSavedAddressUpdate}
                            allowEdit={true}
                        />

                        <View style={styles.modalActions}>
                            <Button
                                status="primary"
                                onPress={handleContactInfoUpdate}
                                style={styles.saveButton}
                            >
                                Save Changes
                            </Button>
                        </View>
                    </ScrollView>
                </Card>
            </DMModal>
        </View>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Calculate Total Cost
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const calculateTotalCost = (items: Order['items']) => {
        return items.reduce((total, item) => total + (item.quantity * item.price), 0);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Status Confirmation Modal
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const StatusConfirmationModal = ({ visible, onConfirm, onCancel, newStatus }: {
        visible: boolean;
        onConfirm: () => void;
        onCancel: () => void;
        newStatus: Order['status'];
    }) => {
        return (
            <DMModal visible={visible} onClose={onCancel}>
                <Card disabled>
                    <Text category="h6" style={styles.modalTitle}>Confirm Status Update</Text>
                    <Text>Are you sure you want to update the order status to "{newStatus}"?</Text>
                    <View style={styles.modalButtonContainer}>
                        <Button onPress={onCancel} status="basic" style={styles.modalButton}>
                            Cancel
                        </Button>
                        <Button onPress={onConfirm} status="primary" style={styles.modalButton}>
                            Confirm
                        </Button>
                    </View>
                </Card>
            </DMModal>
        );
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Payment Process
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handlePaymentProcess = async () => {
        setIsProcessingPayment(true);
        setIsPaymentModalVisible(true);
        if (!editedOrderRef.current) return
        const result = await processPayment(editedOrderRef.current._id as string, currentCompany);
        if (result.success && result.checkoutSession) {
            setPaymentUrl(result.checkoutSession.url);
            Linking.openURL(result.checkoutSession.url);
            startPolling();
        } else {
            console.error('Payment initiation failed:', result.message);
            // Show error message to user
        }
        setIsProcessingPayment(false);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Payment Confirmation Modal
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const startPolling = () => {
        if (pollIntervalRef.current) {
            clearInterval(pollIntervalRef.current);
        }
        pollIntervalRef.current = setInterval(checkOrderStatus, 10000); // Poll every 10 seconds
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Check Order Status
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const checkOrderStatus = async () => {
        if (!editedOrderRef.current) return
        const updatedOrder = await OrderController.pollOrderStatus(editedOrderRef.current._id as string);
        if (updatedOrder && updatedOrder.status !== OrderStatus.AWAITING_PAYMENT) {
            if (pollIntervalRef.current) {
                clearInterval(pollIntervalRef.current);
            }
            onSetEditedOrder(updatedOrder);
            setIsPaymentModalVisible(false);
            onOrderUpdated();
        }
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Payment Confirmation Modal
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const PaymentConfirmationModal = ({ visible, onCancel }: {
        visible: boolean;
        onCancel: () => void;
    }) => {
        return (
            <DMModal visible={visible} onClose={onCancel}>
                <Card disabled>
                    <Text category="h6" style={styles.modalTitle}>Payment Processing</Text>
                    {isProcessingPayment ? (
                        <View style={styles.spinnerContainer}>
                            <Spinner size="large" />
                            <Text style={styles.processingText}>Processing payment...</Text>
                        </View>
                    ) : paymentUrl ? (
                        <>
                            <Text>Please complete the payment in the opened browser window.</Text>
                            <Button onPress={() => Linking.openURL(paymentUrl)} style={styles.modalButton}>
                                Reopen Payment Window
                            </Button>
                            <Button onPress={checkOrderStatus} style={styles.modalButton}>
                                Check Payment Status
                            </Button>
                        </>
                    ) : (
                        <Text>Ready to process payment</Text>
                    )}
                    <Button onPress={onCancel} status="basic" style={styles.modalButton}>
                        Cancel
                    </Button>
                </Card>
            </DMModal>
        );
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Payment Details
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderPaymentDetails = () => (
        <View style={styles.paymentDetailsContainer}>
            {editedOrder && <PaymentDetails paymentDetails={editedOrder.payment_details} />}
        </View>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Order Items
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const calculateItemsTotalWithGST = () => {
        return editedOrder?.getTotalAmountWithGST();
    }

    const calculateItemsTotalWithoutGST = () => {
        return editedOrder?.getTotalAmountWithoutGST();
    }

    const calculateGSTOnlyAmount = () => {
        return editedOrder?.getGSTOnlyAmount();
    }


    const renderOrderItems = () => {

        return (
            <View style={{ flexDirection: 'column', flex: 1 }}>
                <Text category="h6" style={styles.sectionTitle}>Items</Text>
                {StorageController.getCurrentCompany().settings?.business_info?.gst_registered ? (
                    <View style={styles.itemsHeader}>
                        <Text style={styles.itemName}>Item</Text>
                        <Text style={styles.itemQuantity}>Qty</Text>
                        <Text style={styles.itemPrice}>Price</Text>
                        {/* {isEditing && (
                            <Text style={styles.itemPrice}>GST</Text>
                        )} */}
                        <Text style={styles.itemTotal}>Total</Text>
                    </View>
                ) : (
                    <View style={styles.itemsHeader}>
                        <Text style={styles.itemName}>Item</Text>
                        <Text style={styles.itemQuantity}>Qty</Text>
                        <Text style={styles.itemPrice}>Price</Text>
                    </View>
                )}
                {renderItemsList}
                <Divider style={styles.divider} />
                {/* order total */}
                {StorageController.getCurrentCompany().settings?.business_info?.gst_registered ? (
                    <View style={{ flexDirection: 'column' }}>

                        <TextDetailPill label="GST Amount" value={`$${calculateGSTOnlyAmount()?.toFixed(2)}`} />
                        <TextDetailPill label="Order Total" value={`$${calculateItemsTotalWithGST()?.toFixed(2)}`} />
                    </View>
                ) : (
                    <TextDetailPill label="Order Total" value={`$${calculateItemsTotalWithoutGST()?.toFixed(2)}`} />
                )}
            </View>
        );
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Open Job Details
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handleOpenJobDetails = () => {
        if (relatedJob) {
            setCreatedJob(relatedJob);
            setShowJobReportModal(true);
        }
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Fetch Related Job
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    useEffect(() => {
        const fetchRelatedJob = async () => {
            if (editedOrderRef.current && editedOrderRef.current.related_job_id) {
                setIsLoadingJob(true);
                try {
                    const job = await JobController.getJobById(editedOrderRef.current.related_job_id);
                    setRelatedJob(job);
                } catch (error) {
                    console.error('Error fetching related job:', error);
                }
                setIsLoadingJob(false);
            }
        };

        fetchRelatedJob();
    }, [editedOrderRef.current?.related_job_id]);

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Render Loading Spinner
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const RenderLoadingSpinner = () => (
        <View style={styles.spinnerContainer}>
            <Spinner size="large" />
        </View>
    );

    const GSTWarningModal = () => (
        <DMModal visible={showGSTWarningModal} onClose={() => setShowGSTWarningModal(false)}>
            <Card disabled>
                <Text category="h6" style={styles.modalTitle}>GST Warning</Text>
                <Text>Some items in this order exclude GST. When these items are added to the job, GST will be automatically added to their cost.</Text>
                <View style={styles.modalButtonContainer}>
                    <Button
                        onPress={() => {
                            setShowGSTWarningModal(false);
                            setPendingJobCreation(false);
                        }}
                        status="basic"
                        style={styles.modalButton}
                    >
                        Cancel
                    </Button>
                    <Button
                        onPress={async () => {
                            setShowGSTWarningModal(false);
                            if (pendingJobCreation) {
                                setIsCreatingJob(true);
                                const result = await createJobFromOrder(editedOrderRef.current?._id as string);
                                setIsCreatingJob(false);
                                if (result.success && result.order && result.job) {
                                    onSetEditedOrder(result.order);
                                    setCreatedJob(result.job);
                                    setJobCreatedSuccessfully(true);
                                } else {
                                    alert('Failed to create job: ' + (result.message || 'Unknown error'));
                                }
                                setPendingJobCreation(false);
                            }
                        }}
                        status="primary"
                        style={styles.modalButton}
                    >
                        Continue
                    </Button>
                </View>
            </Card>
        </DMModal>
    );

    if (!editedOrder) return <></>

    /*
            //////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////////////
            // #region Render Order Details
            //////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////////////
            */
    return (
        <Layout style={styles.container}>
            <View style={{ position: 'absolute', top: 0, left: 5 }}>
                <TextDetailPill
                    label=""
                    value={getStatusText(editedOrder.status)}
                    valueThemeColor={getStatusColor(editedOrder.status)}
                    size="large"
                />
            </View>
            <View style={styles.header}>
                <Text category="h6" style={styles.title}>Order Details</Text>
                <Button
                    onPress={onBack}
                    accessoryLeft={(props) => <Icon {...props} name="close-outline" />}
                    appearance="ghost"
                    status="danger"
                >
                </Button>
            </View>
            <ScrollView>
                {isLoading ? <RenderLoadingSpinner /> : (
                    <>
                        {renderOrderInfo()}
                        {renderOrderActions()}
                        {renderOrderContactInfo()}
                        {!isLocalClient && isVendor && RenderVendorEditButtons()}
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 2 }}>
                                {renderOrderItems()}
                            </View>
                            <View style={{ flex: 1 }}>
                                {renderPaymentDetails()}
                            </View>
                        </View>

                        {isVendor && isEditing && (
                            <Button
                                appearance="ghost"
                                status="info"
                                accessoryLeft={(props) => <Icon {...props} name="plus-outline" />}
                                onPress={() => setIsAddItemModalVisible(true)}
                                style={styles.addItemButton}
                            >
                                Add Item
                            </Button>
                        )}
                    </>
                )}
            </ScrollView>

            <AddItemModal
                visible={isAddItemModalVisible}
                onClose={() => setIsAddItemModalVisible(false)}
                onAddItems={handleAddItems}
                vendorCompany={currentCompany}
                clientCompanyId={editedOrder && editedOrder.client_company_id || ''}
                //@ts-ignore
                cart={editedOrder.items}
            />
            <StatusConfirmationModal
                visible={statusToConfirm !== null}
                onConfirm={confirmStatusChange}
                onCancel={cancelStatusChange}
                newStatus={statusToConfirm || '' as Order['status']}
            />
            <PaymentConfirmationModal
                visible={isPaymentModalVisible}
                onCancel={() => {
                    setIsPaymentModalVisible(false);
                    if (pollIntervalRef.current) {
                        clearInterval(pollIntervalRef.current);
                    }
                }}
            />
            <JobCreatedSuccessModal />
            {(createdJob || relatedJob) && (
                <JobReportModal
                    showReportDetailsModal={showJobReportModal}
                    setShowReportDetailsModal={setShowJobReportModal}
                    job={createdJob || relatedJob as Job}
                />
            )}
            <GSTWarningModal />
        </Layout>
    );
};

const getStyles = (theme: any) => StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 8,
    },
    container: {
        flex: 1,
        padding: 16,
    },
    title: {
        marginBottom: 16,
    },
    orderInfoContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 16,
        backgroundColor: theme['background-basic-color-2'],
    },
    orderInfoItem: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoIcon: {
        width: 24,
        height: 24,
        marginRight: 8,
    },
    infoLabel: {
        fontSize: 12,
        color: theme['text-hint-color'],
    },
    infoValue: {
        fontSize: 16,
        fontWeight: 'bold',
        color: theme['text-basic-color'],
    },
    divider: {
        marginVertical: 16,
    },
    sectionTitle: {
        marginBottom: 8,
        paddingHorizontal: 16,
    },
    itemsHeader: {
        flexDirection: 'row',
        paddingBottom: 8,
        borderBottomWidth: 1,
        borderBottomColor: theme['border-basic-color-3'],
        paddingHorizontal: 16,
    },
    itemRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomColor: theme['border-basic-color-3'],
        paddingHorizontal: 16,
    },
    itemName: {
        flex: 3,
    },
    itemQuantity: {
        flex: 1,
        textAlign: 'center',
    },
    itemPrice: {
        flex: 1,
        textAlign: 'right',
    },
    itemTotal: {
        flex: 1,
        textAlign: 'right',
    },
    itemQuantityInput: {
        flex: 1,
        marginHorizontal: 4,
    },
    itemPriceInput: {
        flex: 1,
        marginHorizontal: 4,
    },
    itemActions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginLeft: 8,
    },
    addItemButton: {
        alignSelf: 'flex-start',
        marginTop: 8,
        marginLeft: 16,
    },
    actionsContainer: {
        marginTop: 16,
        marginBottom: 16,
        paddingHorizontal: 16,
        backgroundColor: theme['background-basic-color-2'],
        borderRadius: 8,
        padding: 16,
    },
    actionTitle: {
        marginBottom: 8,
        fontWeight: 'bold',
    },
    actionButton: {
        marginBottom: 8,
    },
    statusButtonContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 8,
    },
    statusButton: {
        margin: 4,
        borderRadius: 16,
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalTitle: {
        marginBottom: 10,
    },
    modalButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    modalButton: {
        marginLeft: 10,
    },
    spinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    processingText: {
        marginTop: 10,
    },
    paymentDetailsContainer: {
        marginTop: 16,
        paddingHorizontal: 16,
    },
    viewJobButton: {
        marginLeft: 8,
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    modalContent: {
        maxHeight: '80vh',
    },
    contactInputs: {
        gap: 10,
        marginBottom: 20,
    },
    input: {
        marginBottom: 10,
    },
    modalActions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    saveButton: {
        minWidth: 120,
    },
});
