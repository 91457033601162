import { JobAddress, JobClientRate, JobCustomerDetails, JobTowingDetails, JobVehicleDetails, JobLocation, JobEquipmentDetails, JobNote } from './Job.model';
import { LINE_ITEM_BILLED_TO } from './LineItem.model';
import { Service } from './Service';
import { LineItem } from './LineItem.model';
import * as HoldingController from '../functions/holding.controller';
import { Image } from './Image.model';
import { JobOptions } from './Company.model';
import { Member } from './Member.model';

export enum HOLDING_STATUS {
    HELD = "held",
    RELEASED = "released",
}

export class Holding {
    _id?: string;
    company_id: string;
    name: string;
    friendly_id: string;
    case_id?: string | null;
    holding_job_id?: string;
    client_id?: string;
    client_company_id?: string;
    vendor_company_id?: string;
    details: HoldingDetails;
    holding_time: number;
    reason: string;
    holding_type: string;
    status: string;
    fees: number;
    line_items: LineItem[];
    released_job_id?: string;
    released_time: number;
    createdAt: string;
    updatedAt: string;

    constructor(holding: any = {}) {
        if (holding._id) this._id = holding._id;
        if (holding.released_job_id) this.released_job_id = holding.released_job_id;
        if (holding.holding_job_id) this.holding_job_id = holding.holding_job_id;
        if (holding.client_company_id) this.client_company_id = holding.client_company_id;
        if (holding.vendor_company_id) this.vendor_company_id = holding.vendor_company_id;
        if (holding.client_id) this.client_id = holding.client_id;
        if (holding.case_id) this.case_id = holding.case_id;
        this.company_id = holding.company_id;
        this.friendly_id = holding.friendly_id;
        this.name = holding.name;
        this.details = holding.details ? new HoldingDetails(holding.details) : new HoldingDetails({});
        this.holding_time = holding.holding_time;
        this.reason = holding.reason;
        this.holding_type = holding.holding_type;
        this.status = holding.status;
        this.fees = holding.fees;
        this.line_items = holding.line_items ? holding.line_items.map((item: LineItem) => new LineItem(item)) : [] as LineItem[];
        this.released_time = holding.released_time ? holding.released_time : null;
        this.createdAt = holding.createdAt;
        this.updatedAt = holding.updatedAt;
    }

    /**
     * Save holding
     */
    async save() {
        try {
            const response = await HoldingController.update(this._id as string, this);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Set Released status
     */
    async setReleased() {
        try {
            const response = await HoldingController.setHoldingReleased(this._id as string, "none");
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Get Total cost bill back items
     */
    getTotalBillBackCost() {
        let total = 0;
        // get job itmes
        this.line_items.forEach(item => {
            if (item.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK) {
                total += Number(item.cost) * Number(item.quantity);
            }
        });
        return total;
    }
    getTotalCostBilledToCompany = this.getTotalBillBackCost;

    /**
     * Get Total cost customer items
     */
    getTotalCustomerCost() {
        let total = 0;
        let items = this.getTotalCustomerCostItems();
        items.forEach(item => {
            total += Number(item.cost) * Number(item.quantity);
        })
        return total;
    }
    getTotalCostBilledToCustomer = this.getTotalCustomerCost;

    /**
 * Get total amount outstanding from customer
 * @returns {number} total amount outstanding
 */
    getTotalAmountOutstanding() {
        let total = 0
        let itemsTotalCost = this.getTotalCustomerCost();
        let itemsTotalPaid = this.getTotalCustomerPaidAmount();
        total = itemsTotalCost - itemsTotalPaid;
        return total
    }

    /**
 * Get total customer paid amount
 * @returns {number} total customer paid amount
 */
    getTotalCustomerPaidAmount() {
        let total = 0;
        let items = this.getTotalCustomerCostItems();
        items.forEach(item => {
            if (item.paid) {
                total += Number(item.cost) * Number(item.quantity);
            }
        })
        return total;
    }

    /**
 * Get all items billed to customer
 * @returns {Array<LineItem>}
 */
    getTotalCustomerCostItems() {
        return this.line_items.filter(item => item.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST);
    }

    /**
     * Get total cost of all items
     */
    getTotalCost() {
        let total = 0;
        // get job itmes
        this.line_items.forEach(item => {
            total += Number(item.cost) * Number(item.quantity);
        });
        return total;
    }


    /**
     * Get holding total bill back items
     */
    getTotalBillBackItems() {
        let lineItems = [] as LineItem[];
        // get job itmes
        this.line_items.forEach(item => {
            if (item.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK) {
                lineItems.push(item);
            }
        });
        return lineItems;
    }

    /**
     * Get all items
     */
    getLineItems() {
        let lineItems = [] as LineItem[];
        // get job itmes
        this.line_items.forEach(item => {
            lineItems.push(item);
        });
        return lineItems;
    }

    getHoldingTime() {
        // if (!this.holding_time) return '';
        const holdingTime = Intl.DateTimeFormat(
            'en-AU',
            {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            }
        ).format(new Date(this.holding_time || this.createdAt))
        return holdingTime
    }

    getReleasedTime() {
        const releasedTime = Intl.DateTimeFormat(
            'en-AU',
            {
                dateStyle: 'full',
                timeStyle: 'long'
            }
        ).format(new Date(this.released_time))
        return releasedTime
    }

    getItemNames = () => {
        let items = [] as string[];
        this.line_items.forEach(item => {
            items.push(item.name);
        });
        return items
    }



    /**
     * Calculate time elapsed
     */
    getTimeElapsed() {
        const holdingTime = new Date(this.holding_time)
        // get the difference in milliseconds
        let holding_time_elapsed = Date.now() - holdingTime.getTime();
        if (this.released_time && this.released_time > 0) {
            holding_time_elapsed = new Date(this.released_time).getTime() - holdingTime.getTime();
        }
        // Calculate days, hours, and minutes separately
        const days = Math.floor(holding_time_elapsed / (1000 * 60 * 60 * 24));
        const hours = Math.floor((holding_time_elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((holding_time_elapsed % (1000 * 60 * 60)) / (1000 * 60));

        return {
            days: days,
            hours: hours,
            minutes: minutes,
            readable: `${days} days ${hours} hours ${minutes} minutes`
        }
    }

    getServicesNames() {
        return this.details.selected_services.map(service => service.name).join(", ");
    }

}

class HoldingDetails {
    towing_details: JobTowingDetails;
    address: JobAddress;
    location: JobLocation;
    images = [] as Image[];
    client_rate: JobClientRate;
    selected_services: Service[];
    customer_details: JobCustomerDetails;
    vehicle_details: JobVehicleDetails;
    equipment_details: JobEquipmentDetails;
    options: JobOptions;
    client_reference_id: string;
    notes: JobNote[];
    invoice_details: HoldingInvoiceDetails;
    constructor(details: any = {}) {
        this.towing_details = details.towing_details ? new JobTowingDetails(details.towing_details) : new JobTowingDetails({});
        this.images = details.images ? details.images.map((image: Image) => new Image(image)) : [] as Image[];
        this.client_rate = details.client_rate ? new JobClientRate(details.client_rate) : new JobClientRate({});
        this.selected_services = details.selected_services ? details.selected_services.map((service: Service) => new Service(service)) : [] as Service[];
        this.customer_details = details.customer_details ? new JobCustomerDetails(details.customer_details) : new JobCustomerDetails({});
        this.vehicle_details = details.vehicle_details ? new JobVehicleDetails(details.vehicle_details) : new JobVehicleDetails({});
        this.equipment_details = details.equipment_details ? new JobEquipmentDetails(details.equipment_details) : new JobEquipmentDetails({});
        this.options = details.options ? new JobOptions(details.options) : new JobOptions({});
        this.client_reference_id = details.client_reference_id
        this.address = details.address ? new JobAddress(details.address) : new JobAddress({});
        this.location = details.location ? new JobLocation(details.location) : new JobLocation({});
        this.notes = details.notes ? details.notes.map((note: JobNote) => new JobNote(note)) : [] as JobNote[];
        this.invoice_details = details.invoice_details ? new HoldingInvoiceDetails(details.invoice_details) : new HoldingInvoiceDetails({});
    }

    addNote(note: JobNote) {
        this.notes.push(note);
    }

    deleteNote(note: JobNote) {
        this.notes = this.notes.filter(n => n._id !== note._id);
    }

    updateNote(note: JobNote) {
        this.notes = this.notes.map(n => {
            if (n._id === note._id) {
                return note;
            }
            return n;
        });
    }
}

export class HoldingInvoiceDetails {
    invoice_id: string;
    invoice_number: string;
    invoice_date: number;
    invoiced_by: Member;
    total: number;
    paid: number;
    status: string;
    submitted: boolean;
    constructor(invoiceDetails: any = {}) {
        this.invoice_id = invoiceDetails.invoice_id;
        this.invoice_number = invoiceDetails.invoice_number;
        this.invoice_date = invoiceDetails.invoice_date;
        this.invoiced_by = invoiceDetails.invoiced_by;
        this.total = invoiceDetails.total;
        this.paid = invoiceDetails.paid;
        this.status = invoiceDetails.status;
        this.submitted = invoiceDetails.submitted || false;
    }
}
