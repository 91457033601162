import React, { useState } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Button, Card, Text, useTheme, Icon, Input } from '@ui-kitten/components';
import { Route } from '../../models/Route.model';

interface RoutesListProps {
    routes: Route[];
    selectedRouteIndex: number | null;
    onSelectRoute: (route: Route, index: number) => void;
    onCreateRoutes: () => void;
    isRouteCreated: boolean;
    onUpdateRouteName: (index: number, newName: string) => void;
}

export const RoutesList: React.FC<RoutesListProps> = ({
    routes,
    selectedRouteIndex,
    onSelectRoute,
    onCreateRoutes,
    isRouteCreated,
    onUpdateRouteName
}) => {
    const theme = useTheme();
    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const [editedName, setEditedName] = useState<string>('');

    const styles = StyleSheet.create({
        container: {
            gap: 20,
            padding: 10,
            flexDirection: 'row',
        },
        routeCard: {
            marginBottom: 10,
            borderWidth: 2,
            borderColor: 'transparent',
        },
        selectedRouteCard: {
            borderColor: theme['color-primary-500'],
        },
        routeInfo: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 5,
            alignItems: 'center',
            gap: 5,
        },
        detailTextPill: {
            borderWidth: 1,
            borderRadius: 8,
            paddingHorizontal: 8,
            paddingVertical: 4,
        },
        statusBadge: {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
        },
        routeNameContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
        },
        routeNameInput: {
            flex: 1,
            borderBottomWidth: 1,
            borderBottomColor: theme['color-primary-500'],
            marginRight: 10,
        },
    });

    const getStops = (route: Route): string => {
        if (!route.details.locations) return 'N/A';
        return `${route.details.locations.length}`;
    };

    const getTotalDistance = (route: Route): string => {
        if (!route.details.legs) return 'N/A';
        return `${route.details.distance_kms?.toFixed(2)} km`;
        // return `${route.details.legs.reduce((sum, leg) => sum + leg.actual_distance_kms, 0).toFixed(2)} km`;
    };

    const getTotalDuration = (route: Route): string => {
        if (!route.details.duration_seconds) return 'N/A';
        return `${(route.details.duration_seconds / 60).toFixed(2)} minutes`;
        // return `${Math.round(route.details.legs.reduce((sum, leg) => sum + leg.actual_duration_seconds, 0) / 60)} min`;
    };

    const handleEditName = (index: number, currentName: string) => {
        setEditingIndex(index);
        setEditedName(currentName);
    };

    const handleSaveName = (index: number) => {
        onUpdateRouteName(index, editedName);
        setEditingIndex(null);
    };

    return (
        <ScrollView style={{ maxHeight: 150 }}>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', padding: 5 }}>
                {routes.map((route, index) => (
                    <Card
                        key={index}
                        style={{
                            flex: 1,
                            minWidth: 250,
                            maxWidth: 250,
                            margin: 5,
                            borderWidth: 2,
                            borderColor: selectedRouteIndex === index ? theme['color-primary-500'] : 'transparent',
                        }}
                        onPress={() => onSelectRoute(route, index)}
                    >
                        <View style={{flex:1, margin: -10}}>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5 }}>
                            {editingIndex === index ? (
                                <Input
                                value={editedName}
                                onChangeText={setEditedName}
                                    size="small"
                                    style={{ flex: 1, marginRight: 5 }}
                                    autoFocus
                                    />
                                ) : (
                                    <Text category="s1" numberOfLines={1} style={{ flex: 1 }}>{route.name || `Route ${index + 1}`}</Text>
                                )}
                            <Button
                                size="tiny"
                                appearance="ghost"
                                accessoryLeft={<Icon name={editingIndex === index ? "checkmark-outline" : "edit-2-outline"} />}
                                onPress={() => editingIndex === index ? handleSaveName(index) : handleEditName(index, route.name || `Route ${index + 1}`)}
                                />
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                            <Text category="c1">Dist:</Text>
                            <Text category="c1" style={{ backgroundColor: theme['color-success-100'], borderRadius: 4, paddingHorizontal: 4 }}>
                                {getTotalDistance(route)}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                            <Text category="c1">Time:</Text>
                            <Text category="c1" style={{ backgroundColor: theme['color-warning-100'], borderRadius: 4, paddingHorizontal: 4 }}>
                                {getTotalDuration(route)}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Text category="c1">Stops:</Text>
                            <Text category="c1" style={{ backgroundColor: theme['color-info-100'], borderRadius: 4, paddingHorizontal: 4 }}>
                                {getStops(route)}
                            </Text>
                        </View>
                        <View style={{ position: 'absolute', top: -10, left: -10 }}>
                            <Text 
                                category="c2"
                                style={{
                                    backgroundColor: route._id ? theme['color-success-100'] : theme['color-warning-100'],
                                    borderRadius: 4,
                                    paddingHorizontal: 4,
                                }}
                                >
                                {route._id ? 'Created' : 'Not Created'}
                            </Text>
                        </View>
                                </View>
                    </Card>
                ))}
            </View>
        </ScrollView>
    );
};
