import React, { useState, useEffect, useRef } from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle, Input, Button, Layout } from '@ui-kitten/components';
import { Company, CompanyTheme } from '../../models/Company.model';
//@ts-ignore
import { SketchPicker, CirclePicker, SwatchesPicker, SliderPicker, ChromePicker } from 'react-color'
import * as UpdateController from '../../functions/update.controller';

interface CompanyThemePickerProps {
    company: Company;
    onSetCompany: (company: Company) => void;
    style?: any;
}

export const CompanyThemePicker: React.FC<CompanyThemePickerProps> = ({ company, onSetCompany, style = {} }) => {
    const [selectedTheme, setSelectedTheme] = useState(company.settings?.theme as CompanyTheme);
    const [currentTheme, setCurrentTheme] = useState(company.settings?.theme);
    const [urlError, setUrlError] = useState('');
    const [colourPickerType, setColourPickerType] = useState('swatches');
    const brandUriInputState = useRef(null as any);

    useEffect(() => {
        setSelectedTheme(company.settings?.theme as CompanyTheme);
        setCurrentTheme(company.settings?.theme);
        if (brandUriInputState.current) {
            brandUriInputState.current.value = company.settings?.theme.brand_logo;
        }
    }, [company]);

    const onSetTheme = (theme: CompanyTheme) => {
        setSelectedTheme(theme);
        testUpdatedTheme(theme);
        onSaveCompanyTheme(theme);
    };

    const onSaveCompanyTheme = (theme: CompanyTheme) => {
        onSetCompany({
            ...company,
            settings: {
                ...company.settings,
                theme: theme
            }
        } as Company);
    };

    const onResetTheme = () => {
        const resetTheme = new CompanyTheme({});
        onSetTheme(resetTheme);
    };

    const testUpdatedTheme = (newTheme: CompanyTheme) => {
        UpdateController.dispatchEventStateChange({
            type: UpdateController.STATE_ACTIONS.UPDATED_COMPANY_THEME,
            data: newTheme
        });
    };

    const setIsLightTheme = (lightTheme: boolean) => {
        const theme = lightTheme ? 'light' : 'dark';
        onSetTheme(new CompanyTheme({ ...selectedTheme, light_dark: theme }));
    };

    const setBrandLogo = (logoUrl: string) => {
        if (!validateImageUrl(logoUrl)) {
            setUrlError('Invalid Image URL');
            return;
        }
        setUrlError('');
        onSetTheme(new CompanyTheme({ ...selectedTheme, brand_logo: logoUrl }));
    };

    const validateImageUrl = (url: string) => {
        return url.includes('http');
    };

    const RenderColourPickerType = (themeProperty: string, themeValue: string) => {
        if (colourPickerType === 'swatches') {
            return <SwatchesPicker
                color={themeValue}
                onChange={(color: any) => onSetTheme(new CompanyTheme({ ...selectedTheme, [themeProperty]: color.hex }))}
            />;
        } else {
            return <ChromePicker
                color={themeValue}
                onChange={(color: any) => onSetTheme(new CompanyTheme({ ...selectedTheme, [themeProperty]: color.hex }))}
            />;
        }
    };

    return (
        <Card
            style={style}
            key={company._id}
            disabled
            header={() => <Text category='h6'>Theme</Text>}
            status="basic"
        >
            <Layout style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Card style={{ flex: 1 }} disabled>
                        <Text category='s1'>Dark/Light Theme</Text>
                        <Toggle
                            checked={selectedTheme.light_dark === 'light'}
                            onChange={(nextChecked: boolean) => setIsLightTheme(nextChecked)}
                        />
                    </Card>
                    <Card style={{ flex: 3 }} disabled>
                        <Text category='s1'>Brand Logo</Text>
                        <Input
                            ref={brandUriInputState}
                            label="Brand Logo"
                            placeholder="Brand Logo"
                            defaultValue={selectedTheme.brand_logo}
                            onChangeText={(nextValue: string) => setBrandLogo(nextValue)}
                        />
                        <Text style={{ color: 'red' }}>{urlError}</Text>
                    </Card>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1'>Colour Picker Type</Text>
                        <Toggle
                            checked={colourPickerType === 'swatches'}
                            onChange={(nextChecked: boolean) => setColourPickerType(nextChecked ? 'swatches' : 'chrome')}
                        />
                    </View>
                </View>

                <Card
                    disabled
                    header={() => (
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text category='h6'>Colours</Text>
                            <Button onPress={() => onResetTheme()} status="danger">Reset</Button>
                        </View>
                    )}
                >
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <Text category='s1'>Primary</Text>
                            {RenderColourPickerType('primary', selectedTheme.primary)}
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text category='s1'>Success</Text>
                            {RenderColourPickerType('success', selectedTheme.success)}
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <Text category='s1'>Warning</Text>
                            {RenderColourPickerType('warning', selectedTheme.warning)}
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text category='s1'>Danger</Text>
                            {RenderColourPickerType('danger', selectedTheme.danger)}
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}>
                            <Text category='s1'>Basic</Text>
                            {RenderColourPickerType('basic', selectedTheme.basic)}
                        </View>
                    </View>
                </Card>
            </Layout>
        </Card>
    );
};
