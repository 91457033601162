import React, { useState, useEffect, useCallback, useRef } from 'react';
//@ts-ignore
import { View, StyleSheet } from "react-native";
import { Button, Icon, Input, OverflowMenu, MenuItem, Text, Card, useTheme } from "@ui-kitten/components";
import { getForwardGeocoding, getReverseGeocoding } from '../../../functions/osrm.controller';
import * as GoogleController from '../../../functions/google.controller';
import { JobAddress, JobLocation } from '../../../models/Job.model';

interface AddressSearchProps {
    // onSetAddress: (address: JobAddress) => void;
    // onSetLocation: (location: JobLocation) => void;
    onSetAddressLocation: (address: JobAddress, location: JobLocation) => void;
    displayValue: string;
    allowEdit: boolean;
    showCard?: boolean;
}

const AddressSearch = ({ onSetAddressLocation, displayValue, allowEdit, showCard = true }: AddressSearchProps) => {
    //@ts-ignore
    const theme = useTheme() as any;
    const [showPredictions, setShowPredictions] = useState(false);
    const [search, setSearch] = useState({ term: '', fetchPredictions: false });
    const [predictions, setPredictions] = useState<any[]>([]);
    const [location, setLocation] = useState({ lat: 0, lng: 0 });
    const searchDebounceTimeout = useRef<NodeJS.Timeout | null>(null);


    useEffect(() => {
        if (displayValue && displayValue !== "") {
            setSearch({ term: displayValue, fetchPredictions: true });
        }
    }, [displayValue]);

    const debounceSearch = (value: string) => {
        if (searchDebounceTimeout.current) {
            clearTimeout(searchDebounceTimeout.current);
        }
        setSearch({ term: value, fetchPredictions: true });
        searchDebounceTimeout.current = setTimeout(() => {
            searchText(value);
        }, 1000);
    };



    const searchText = async (term: string) => {
        const res = await GoogleController.getpredictionsAutocomplete(term) as any;
        setPredictions(res.predictions);
        setShowPredictions(true);
    };


    const clickedPrediction = async (event: any) => {
        setSearch({ term: event.description, fetchPredictions: false });
        const placeId = event.place_id;
        setShowPredictions(false);
        const googleAddressResult = await GoogleController.getPredictionsPlace(event.place_id);
        console.log("🚀============== ~ file: addressSearch.component.tsx:100 ~ googleAddressResult🚀==============", googleAddressResult)
        setLocation({ lat: googleAddressResult.result.geometry.location.lat, lng: googleAddressResult.result.geometry.location.lng });
        const _location = { lat: googleAddressResult.result.geometry.location.lat, lng: googleAddressResult.result.geometry.location.lng };
        const sortedAddress = GoogleController.sortAddress(googleAddressResult.result.address_components);
        sortedAddress.description = googleAddressResult.result.name;
        sortedAddress.formatted_address = googleAddressResult.result.formatted_address;
        const jobAddress = new JobAddress({
            description: sortedAddress.description,
            formatted_address: sortedAddress.formatted_address,
            location: new JobLocation(_location),
            address_components: sortedAddress.address_components,
            place_id: placeId,
            ...sortedAddress,
        });
        displayValue = sortedAddress.formatted_address;
        // onSetAddress?.(jobAddress);
        // onSetLocation?.(new JobLocation(_location));
        onSetAddressLocation?.(jobAddress, new JobLocation(_location));
    };



    return (
        showCard ?
            <Card
                header={() => <Text>Address Search</Text>}
                disabled={true}
                style={{}}
                status='success'
            >
                <RenderAddressSearch
                    showCard={showCard}
                    allowEdit={allowEdit}
                    onSetAddressLocation={onSetAddressLocation}
                    displayValue={displayValue}
                    setSearch={setSearch}
                    search={search}
                    predictions={predictions}
                    showPredictions={showPredictions}
                    debounceSearch={debounceSearch}
                    searchText={searchText}
                    clickedPrediction={clickedPrediction}
                    setShowPredictions={setShowPredictions}
                />
            </Card>
            :
            <View>
                <RenderAddressSearch
                    showCard={showCard}
                    allowEdit={allowEdit}
                    onSetAddressLocation={onSetAddressLocation}
                    displayValue={displayValue}
                    setSearch={setSearch}
                    search={search}
                    predictions={predictions}
                    showPredictions={showPredictions}
                    debounceSearch={debounceSearch}
                    searchText={searchText}
                    clickedPrediction={clickedPrediction}
                    setShowPredictions={setShowPredictions}
                />
            </View>
    );
};


const RenderAddressSearch = ({
    showCard = true,
    allowEdit = true,
    onSetAddressLocation = () => { },
    displayValue = "",
    setSearch = () => { },
    search = { term: '', fetchPredictions: false },
    predictions = [],
    showPredictions = false,
    debounceSearch = (text: string) => { },
    searchText = (term: string) => { },
    clickedPrediction = (item: any) => { },
    setShowPredictions = (showPredictions: boolean) => { },
}: {
    showCard?: boolean;
    allowEdit?: boolean;
    onSetAddressLocation?: (address: JobAddress, location: JobLocation) => void;
    displayValue?: string;
    setSearch?: (search: { term: string, fetchPredictions: boolean }) => void;
    search?: { term: string, fetchPredictions: boolean };
    predictions?: any[];
    showPredictions?: boolean;
    debounceSearch?: (text: string) => void;
    searchText?: (term: string) => void;
    clickedPrediction?: (item: any) => void;
    setShowPredictions?: (showPredictions: boolean) => void;
}) => {
    return (
        <View style={[{ flexDirection: "row", margin: showCard ? -15 : 0 }]}>
            <View style={{ flex: 5 }}>
                <OverflowMenu
                    visible={showPredictions}
                    anchor={() => {
                        return (
                            <Input
                                status='success'
                                placeholder='Search Address'
                                size='small'
                                style={{ margin: 5 }}
                                disabled={!allowEdit}
                                value={search.term}
                                onChangeText={(text: string) => {
                                    setSearch({ term: text, fetchPredictions: true }); // Update state immediately
                                    debounceSearch(text); // Pass the new text to debounceSearch
                                }}
                            />
                        )
                    }}
                    onBackdropPress={() => setShowPredictions(false)}
                    style={{ width: "100%" }}
                    fullWidth={true}
                >
                    {showPredictions ? predictions.map((item) => {
                        return (
                            <MenuItem title={item.description || ""} key={item.place_id} onPress={() => { clickedPrediction(item) }} />
                        )
                    }) : <MenuItem title="No predictions" />}
                </OverflowMenu>
            </View>
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                <Button
                    size="small"
                    appearance='ghost'
                    status="primary"
                    disabled={!allowEdit}
                    style={{ margin: 5 }}
                    accessoryLeft={(props: any) => <Icon {...props} name="search-outline" />}
                    onPress={() => {
                        searchText(search.term)
                    }}
                >
                    {/* <Icon name='search-outline' width={24} height={24} fill={theme['text-basic-color']} /> */}
                </Button>
            </View>
        </View>
    )
}

export default AddressSearch;


const styles = StyleSheet.create({
    groupContainer: {
        margin: 10,
        flex: 1,
    },
    groupTitle: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    inputField: {
        paddingLeft: 24,
        paddingRight: 24,
    },
    horizontalLayout: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    verticalLayout: {
        flexDirection: 'column',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 8,
    },
    modalCard: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
        padding: 10,
        margin: 10,
        maxHeight: "80%",
        minHeight: "50%",
        width: "90%",
        maxWidth: "90%",
        minWidth: "90%",
        alignSelf: "center"
    },
    container: {
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden"
    },
    contentContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        overflow: "hidden"
    },
    listcontainer: {
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    tinyLogo: {
        width: "100%",
        height: 100,
    },
    item: {
        marginVertical: 4,
    },
    predictionsContainer: {
        backgroundColor: '#cfcfcf',
        padding: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10
    },
    predictionRow: {
        paddingBottom: 15,
        marginBottom: 15,
        borderBottomColor: 'black',
        borderBottomWidth: 1,
    },
    greenContainer: {
        backgroundColor: "rgba(0, 224, 150, 0.08)",
        borderColor: "rgb(0, 224, 150)",
        borderRadius: 4,
        borderWidth: 1,
    },
});