import React, { useState, useEffect, useCallback } from 'react';
import { StyleSheet, View, Linking, ScrollView } from 'react-native';
import { Layout, Text, Toggle, Input, Button, Select, SelectItem, IndexPath, Card } from '@ui-kitten/components';
import { Company, CompanyPartsNetworkSettings } from '../../models/Company.model';
import * as CompanyController from '../../functions/company.controller';
import * as PartsNetworkController from '../../functions/PartsNetwork.controller';
import { StripeConnectComplete } from './StripeConnectComplete';
import { CheckStripeConnectStatusResponse } from '../../functions/PartsNetwork.controller';
import { StripeConnectStatus } from './StripeConnectStatus';
import { useNotification } from '../../context/notifications.context';
import { PartsNetworkSetupModal } from './PartsNetworkSetupModal';

export const PartsNetworkSettings = ({ company }: { company: Company | null }) => {
    const [settings, setSettings] = useState<CompanyPartsNetworkSettings | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [stripeConnectModalVisible, setStripeConnectModalVisible] = useState(false);
    const [stripeConnectStatus, setStripeConnectStatus] = useState<CheckStripeConnectStatusResponse | null>(null);
    const [isDirty, setIsDirty] = useState(false);
    const { showNotification } = useNotification();
    const [setupModalVisible, setSetupModalVisible] = useState(false);

    useEffect(() => {
        if (company) {
            initLoad();
        }
    }, [company]);

    useEffect(() => {
        if (settings) {
            if (JSON.stringify(settings) !== JSON.stringify(company?.settings.parts_network)) {
                setIsDirty(true);
            } else {
                setIsDirty(false);
            }
        }
    }, [settings]);

    const initLoad = async () => {
        const coy = await CompanyController.getCompanyById(company?._id || '');
        if (coy) {
            setSettings(new CompanyPartsNetworkSettings(coy.settings.parts_network));
        }
    }

    const updateSettings = async () => {
        if (company && settings) {
            setIsLoading(true);
            try {
                company.settings.parts_network = settings;
                const updatedCompany = await company.save();
                console.log("🚀============== ~ file: PartsNetworkSettings.tsx:40 ~ updateSettings ~ updatedCompany🚀==============", updatedCompany)
                // Optionally, you can fetch the updated company data here
                showNotification("Settings Saved", "Your settings have been saved successfully.", () => { });
            } catch (error) {
                console.error("Error updating settings:", error);
                showNotification("Error", "There was an error saving your settings.", () => { });
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onCancel = () => {
        if (company && company.settings.parts_network) {
            setSettings(new CompanyPartsNetworkSettings(company.settings.parts_network));
        }
    }

    const initiateStripeConnect = async () => {
        if (company) {
            setIsLoading(true);
            try {
                const url = await PartsNetworkController.initiateStripeConnectOnboarding(company._id);
                Linking.openURL(url);
                setStripeConnectModalVisible(true);
            } catch (error) {
                console.error("Error initiating Stripe Connect:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const getStripeConnectStatus = async () => {
        if (company) {
            try {
                const status = await PartsNetworkController.checkStripeConnectStatus(company._id);
                setStripeConnectStatus(status);
            } catch (error) {
                console.error("Error checking Stripe Connect status:", error);
            }
        }
    }

    useEffect(() => {
        getStripeConnectStatus();
    }, [company]);

    const handleSetupClick = () => {
        setSetupModalVisible(false);
        initiateStripeConnect();
    };

    if (!settings) return <Text>Loading settings...</Text>;


    const isFullySetup = () => {
        let setupComplete = stripeConnectStatus?.isSetup;
        let stripeAccountId = company?.settings.payment_settings.stripe.stripe_connect_account_id;
        return setupComplete && stripeAccountId;
    }

    const isPartiallySetup = () => {
        let setupComplete = stripeConnectStatus?.isSetup;
        let stripeAccountId = company?.settings.payment_settings.stripe.stripe_connect_account_id;
        return !setupComplete && stripeAccountId;
    }

    const isNotSetup = () => {
        let setupComplete = stripeConnectStatus?.isSetup;
        let stripeAccountId = company?.settings.payment_settings.stripe.stripe_connect_account_id;
        return !setupComplete && !stripeAccountId;
    }


    const RenderVendorSettingsToggles = () => {
        return (
            <Card
                disabled
                status='basic'
                header={() => <Text category='h6'>Vendor Settings</Text>}
            >
                <View style={{ flexDirection: 'row' }}>
                    <Toggle
                        style={styles.toggle}
                        checked={settings.is_vendor}
                        onChange={(checked) => setSettings({ ...settings, is_vendor: checked })}
                    >
                        Act as Vendor (Allow customers to purchase parts from you)
                    </Toggle>
                </View>
                {settings.is_vendor && (
                    <View style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <Toggle
                                style={styles.toggle}
                                checked={settings.custom_pricing_enabled}
                                onChange={(checked) => setSettings({ ...settings, custom_pricing_enabled: checked })}
                            >
                                Enable Custom Pricing (Allow you to set different prices for different customers)
                            </Toggle>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                            <Toggle
                                style={styles.toggle}
                                checked={settings.stock_visibility}
                                onChange={(checked) => setSettings({ ...settings, stock_visibility: checked })}
                            >
                                Show Stock Levels to Customers
                            </Toggle>
                        </View>
                    </View>
                )}
            </Card>
        )
    }


    return (
        <ScrollView style={styles.container}>
            <Layout style={styles.content}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category="h5" style={styles.heading}>PARTS NETWORK SETTINGS</Text>
                    <Layout style={[styles.buttonContainer, { gap: 10 }]}>
                        <Button onPress={updateSettings} disabled={isLoading || !isDirty} status="success">
                            SAVE
                        </Button>
                        <Button appearance="outline" status="basic" onPress={onCancel}>
                            Cancel
                        </Button>
                    </Layout>
                </View>

                <Layout style={styles.togglesContainer}>
                    <Card style={styles.mainCard} status='basic' disabled={true}>
                        <Text category="h6" style={styles.cardTitle}>Vendor Settings {isFullySetup() ? '(Connected)' : isPartiallySetup() ? '(Partially Setup)' : '(Not Setup)'}</Text>
                        {isNotSetup() && (
                            <View style={styles.setupSection}>
                                <Text style={styles.setupText}>
                                    To become a vendor on the Parts Network, you'll need to set up your payment processing first.
                                </Text>
                                <Button
                                    style={styles.setupButton}
                                    status="primary"
                                    onPress={() => setSetupModalVisible(true)}
                                >
                                    Setup Vendor Account
                                </Button>
                            </View>
                        )}
                        {isFullySetup() && (
                            <View style={styles.statusSection}>
                                {stripeConnectStatus ? (
                                    <StripeConnectStatus status={stripeConnectStatus} />
                                ) : (
                                    <Text>Loading account status...</Text>
                                )}
                                <RenderVendorSettingsToggles />
                            </View>
                        )}
                        {isPartiallySetup() && (
                            <View style={styles.statusSection}>
                                {stripeConnectStatus ? (
                                    <StripeConnectStatus status={stripeConnectStatus} />
                                ) : (
                                    <Text>Loading account status...</Text>
                                )}
                            </View>
                        )}

                    </Card>
                </Layout>

                <StripeConnectComplete
                    visible={stripeConnectModalVisible}
                    onClose={() => setStripeConnectModalVisible(false)}
                    companyId={company?._id || ''}
                />

                <PartsNetworkSetupModal
                    visible={setupModalVisible}
                    onClose={() => setSetupModalVisible(false)}
                    onSetupClick={handleSetupClick}
                />
            </Layout>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        padding: 16,
    },
    heading: {
        marginBottom: 16,
    },
    togglesContainer: {
        marginBottom: 16,
    },
    mainCard: {
        padding: 16,
    },
    cardTitle: {
        marginBottom: 24,
    },
    setupSection: {
        alignItems: 'center',
        padding: 24,
    },
    setupText: {
        textAlign: 'center',
        marginBottom: 16,
    },
    setupButton: {
        minWidth: 200,
    },
    statusSection: {
        marginBottom: 24,
    },
    toggleSection: {
        gap: 16,
    },
    toggle: {
        marginBottom: 8,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
});
