var config = require('../config/config.js')
const axios = require('axios');
import { Case } from '../models/Case.model';
import { Job, LINE_ITEM_BILLED_TO } from '../models/Job.model';
import { Holding } from '../models/Holding.model';
import * as StorageController from './storageController'

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

//==============================================================
//==============================================================
// CASES
//==============================================================
//==============================================================
axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);
const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

//create case
export async function createCase(caseObj: Case) {
    try {
        const response = await axios.post(getApi() + "/case", caseObj);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

//update caseObj
export async function updateCase(caseObj: Case) {
    try {
        const response = await axios.put(getApi() + "/case/" + caseObj._id, caseObj);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

// get case by id
export async function getCaseById(caseId: string) {
    try {
        const response = await axios.get(getApi() + "/case/" + caseId);
        let caseObj = new Case(response.data);
        return caseObj
    } catch (error) {
        console.error(error);
    }
}

//delete case
export async function deleteCase(caseId: string) {
    try {
        const response = await axios.delete(getApi() + "/case/" + caseId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}


//findByJobId
export async function getCaseByJobId(jobId: string) {
    try {
        const response = await axios.get(getApi() + "/case/job/" + jobId);
        return new Case(response.data)
    } catch (error) {
        console.error(error);
    }
}

//addJob
export async function addJobToCase(caseId: string, jobId: string) {
    try {
        const response = await axios.put(getApi() + "/case/" + caseId + "/job/" + jobId);
        return new Case(response.data)
    } catch (error) {
        console.error(error);
    }
}

//removeJob
export async function removeJobFromCase(caseId: string, jobId: string) {
    try {
        const response = await axios.delete(getApi() + "/case/" + caseId + "/job/" + jobId);
        return response.data
    } catch (error) {
        console.error(error);
    }
}

//findByCompanyId
export async function getCasesByCompanyId(companyId: string) {
    try {
        const response = await axios.get(getApi() + "/case/company/" + companyId);
        const cases = response.data?.map((caseObj: any) => {
            return new Case(caseObj);
        })
        return cases
    } catch (error) {
        console.error(error);
    }
}


//findAllByCompanyIdAndDateRange
export async function getCasesByCompanyIdAndDateRange(companyId: string, startDate: any, endDate: any) {
    //body
    var body = {
        company_id: companyId,
        start_date: startDate,
        end_date: endDate,
    }
    try {
        const response = await axios.post(getApi() + "/case/company/dates", body);
        const cases = response.data?.map((caseObj: any) => {
            return new Case(caseObj);
        })
        return cases
    } catch (error) {
        console.error(error);
    }
}

//findByCompanyIdAndStatus
export async function getCasesByCompanyIdAndStatus(companyId: string, status: string, limit = 1000, offset = 0) {
    const body = {
        company_id: companyId,
        status: status,
        limit: limit,
        offset: offset
    }
    try {
        const response = await axios.post(getApi() + "/case/company/status", body);
        const cases = response.data.map((caseObj: any) => {
            return new Case(caseObj);
        })
        return cases
    } catch (error) {
        console.error(error);
    }
}

/**
 * 
 * @param {*} status 
 * @returns <Promise> [ {company: {}}
 */
export async function findByAllCompaniesAndStatus(status: string) {
    try {
        const companies = StorageController.getAppState().companies || [];
        const casePromises = companies.map(async (company) => {
            if (company.settings?.company_roles?.showCases) {
                const cases = await getCasesByCompanyIdAndStatus(company._id, status);
                company.cases = cases;
            } else {
                company.cases = [];
            }
            return company; // This will be a company with holdings populated.
        });
        const companiesWithCases = (await Promise.allSettled(casePromises))
            .filter((company: any) => company.status === 'fulfilled')
            .map((company: any) => company.value);
        return companiesWithCases;
    } catch (error) {
        console.log("🚀 ~ findByAllCompaniesAndStatus ~ error:", error)
    }
}

//setCaseStatus
export async function setCaseStatus(caseId: string, status: string) {
    const body = {
        case_id: caseId,
        status: status,
    }
    try {
        const response = await axios.put(getApi() + `/case/status/${status}`, body);
        console.log("🚀============== ~ file: case.controller:177 ~ setCaseStatus ~ response🚀==============", response)
        return new Case(response.data)
    } catch (error) {
        console.error(error);
    }
}

/**
 * Set all job and holding customer cost items to paid or unpaid. (true/false)
 * @param {Case} caseObj - The case id.
 * @param {boolean} paid - The new paid status.
 * @returns {Promise<Case>} - A promise that resolves to the updated Case instance.
 */
export async function setCaseCustomerCostPaid(caseObj: Case, paid: boolean) {
    try {
        let result = [] as any
        let jobs = caseObj.job_ids.map(job => {
            job.details?.report?.items?.map(item => {
                if (item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST) {
                    item.paid = paid;
                    return item;
                } else {
                    return item;
                }
            })
            return job;
        })

        let holdings = caseObj.holding_ids.map(holding => {
            holding.line_items?.map(item => {
                if (item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST) {
                    item.paid = paid;
                    return item;
                } else {
                    return item
                }
            })
            return holding;
        })

        jobs.forEach(async (job) => {
            const r = await job.save();
            result.push(r);
        })

        holdings.forEach(async (holding) => {
            const r = await holding.save();
            result.push(r);
        })
        return result
    } catch (error) {
        console.error(error);
    }
}