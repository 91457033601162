import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
//@ts-ignore
import { StyleSheet, TouchableOpacity, View, Platform, ScrollView, Dimensions, useWindowDimensions } from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    useTheme,
    Select,
    SelectItem,
    Toggle,
    OverflowMenu,
    MenuItem,
    Modal,
    //@ts-ignore
    Spinner,
    Radio,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import AssignJobList from '../assignJobList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import { ServicesContainer } from '../services/servicesContainer.component';
import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
import * as HoldingController from '../../../functions/holding.controller'
//@ts-ignore
import debounce from 'lodash.debounce';
import ErrorBoundary from '../../ErrorBoundary.component';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import { Job, JobCustomerDetails, JobTowingDetails, Service, TowingLocation, JobVehicleDetails, JobLocation, JobAddress, TOWING_TYPE } from '../../../models/Job.model';
import { LineItem } from '../../../models/LineItem.model';
import { Member } from '../../../models/Member.model';
import { Company } from '../../../models/Company.model';
import { Client } from '../../../models/Client.model';
import { ReportDetailsCard } from '../details/reportDetailsCard.component';

interface CopyJobComponentProps {
    job: Job;
    onCopy: (job: Job) => void;
    onClose: () => void;
    canEdit?: boolean;
    showModalDirect?: boolean;
}

export const CopyJobComponent = ({
    job,
    onCopy,
    canEdit = true,
    onClose,
    showModalDirect = false,
}: CopyJobComponentProps) => {

    const windowCollapseWidth = 768

    //@ts-ignore
    const theme = useTheme() as any;
    const [showCopyModal, setShowCopyModal] = useState(false);
    const [copiedJob, setCopiedJob] = useState<Job | null>(new Job());
    const copiedJobRef = useRef<Job | null>(null);
    const [toggles, setToggles] = useState({
        customer_details: true,
        vehicle_details: true,
        address: true,
        location: true,
        comments: true,
        notes: true,
        dispatchable: true,
        selected_services: true,
        client: true,
    });

    const { width, height } = useWindowDimensions()
    const [showJobReportSmallScreen, setShowJobReportSmallScreen] = useState(false)

    const [towingToggles, setTowingToggles] = useState({
        towing_details: true, //Toggle to show them all
        towing_locations: true,
        towing_vehicle: true,
    });
    const towingTogglesRef = useRef(towingToggles);

    const togglesRef = useRef(toggles);

    useEffect(() => {
        onSetCopiedJob(job);
    }, [job]);

    const onSetCopiedJob = (job: Job) => {
        let j = new Job({
            client_id: togglesRef.current.client ? job.client_id : null,
            client_company_id: togglesRef.current.client ? job.client_company_id : null,
            vendor_profile_id: job.vendor_profile_id,
            vendor_company_id: job.vendor_company_id,
            dispatchable: job.dispatchable,
            company_id: job.company_id,
            details: {
                customer_details: togglesRef.current.customer_details ? job.details.customer_details : null,
                vehicle_details: togglesRef.current.vehicle_details ? job.details.vehicle_details : null,
                address: togglesRef.current.address ? job.details.address : null,
                location: togglesRef.current.location ? job.details.location : null,
                comments: togglesRef.current.comments ? job.details.comments : null,
                notes: togglesRef.current.notes ? job.details.notes : null,
                selected_services: togglesRef.current.selected_services ? job.details.selected_services : null,

                client_rate: togglesRef.current.client ? job.details.client_rate : null,
                options: job.details.options,
            },
            created_by: StorageController.getAppState().selectedMembership?._id,
            start_time: new Date().getTime(),
            dispachable: false,
            log: [
                {
                    time: new Date().getTime(),
                    type: "info",
                    message: "Job created (Copied) by " + StorageController.getAppState().selectedMembership?.name + " on " + new Date().toLocaleDateString()
                }
            ]
        });

        if (towingTogglesRef.current.towing_details) {
            // If towing locations set the locations and the legs
            let jtowingDetails = new JobTowingDetails({
                towing_locations: towingTogglesRef.current.towing_locations ? job.details.towing_details?.towing_locations : null,
                towing_legs: towingTogglesRef.current.towing_locations ? job.details.towing_details?.towing_legs : null,
                direction_polylines: towingTogglesRef.current.towing_locations ? job.details.towing_details?.direction_polylines : null,
                directions_polyline: towingTogglesRef.current.towing_locations ? job.details.towing_details?.directions_polyline : null,

                vehicle_details: towingTogglesRef.current.towing_vehicle ? job.details.towing_details?.vehicle_details : null,
                equipment_details: towingTogglesRef.current.towing_vehicle ? job.details.towing_details?.equipment_details : null,
                towing_type: towingTogglesRef.current.towing_vehicle ? job.details.towing_details?.towing_type : TOWING_TYPE.VEHICLE,

            });
            j.details.towing_details = jtowingDetails;
            // for each location set the time to null
            if (j.details.towing_details?.towing_locations) {
                j.details.towing_details.towing_locations.forEach((location: TowingLocation) => {
                    location.time = null;
                });
            }
        }
        copiedJobRef.current = j;
        setCopiedJob(j);
    }

    const handleCopyJob = () => {
        setShowCopyModal(true);
    }

    const handleCloseCopyModal = () => {
        setShowCopyModal(false);
        onClose();
    }

    const handleToggle = (key: keyof typeof toggles) => (checked: boolean) => {
        setToggles(prev => ({ ...prev, [key]: checked }));
        togglesRef.current[key] = checked;
        onSetCopiedJob(job as Job);
    }

    const handleTowingToggle = (key: keyof typeof towingToggles) => (checked: boolean) => {
        setTowingToggles(prev => ({ ...prev, [key]: checked }));
        towingTogglesRef.current[key] = checked;
        onSetCopiedJob(job as Job);
    }


    const handleConfirmCopy = async () => {
        if (!copiedJob) return;
        // create a new job with the copied job details
        const newJob = await JobsController.createJob(copiedJobRef.current as Job)
        UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.CREATED_JOB, data: newJob })
        console.log("🚀============== ~ file: copyJob.component.tsx:133 ~ handleConfirmCopy ~ copiedJob🚀==============", copiedJobRef.current, newJob)
        setShowCopyModal(false);
        // onCopy(copiedJob);
    }


    // render button to ask to copy job, then render modal with job details
    const RenderCopyJobButton = () => {
        return (
            <Button
                appearance="outline"
                onPress={handleCopyJob}
                accessoryLeft={() => (<Icon name="copy" fill={theme['text-basic-color']} width={20} height={20} />)}
            >
                Copy Job
            </Button>
        )
    }



    const RenderModal = () => {
        return (
            <Modal
                visible={showCopyModal || showModalDirect}
                backdropStyle={styles.backdrop}
                onBackdropPress={handleCloseCopyModal}
                style={[styles.modal, { maxWidth: width * 0.9, height: height * 0.9 }]}
            >
                <View style={{ height: 5, backgroundColor: theme['color-success-default'] }}></View>
                <Layout style={{ flexDirection: 'row', flex: 1 }}>
                    {/* @ts-ignore */}
                    <Layout style={{ flex: 1, padding: 10 }} level="2">
                        <Text category="h6" style={styles.modalTitle}>Copy Job Details</Text>
                        <View style={{ flexDirection: 'row', height: 1, backgroundColor: 'gray' }}></View>
                        <Text category="p1">Copy the details of the job to a new job.</Text>
                        {width <= windowCollapseWidth &&
                            <Button onPress={() => setShowJobReportSmallScreen(!showJobReportSmallScreen)}>
                                {showJobReportSmallScreen ? "Hide Job Report" : "Show Job Report"}
                            </Button>
                        }
                        <ScrollView style={styles.scrollView}>
                            <View style={{ flexDirection: 'column', gap: 5, marginTop: 10 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Toggle
                                        checked={toggles.client}
                                        onChange={handleToggle('client')}
                                    >
                                        Client
                                    </Toggle>
                                </View>
                                <View style={{ flexDirection: 'row' }}>

                                    <Toggle
                                        checked={toggles.customer_details}
                                        onChange={handleToggle('customer_details')}
                                    >
                                        Customer Details
                                    </Toggle>
                                </View>
                                <View style={{ flexDirection: 'row' }}>

                                    <Toggle
                                        checked={toggles.vehicle_details}
                                        onChange={handleToggle('vehicle_details')}
                                    >
                                        Vehicle Details
                                    </Toggle>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    {job.details.options.towing_job_options &&
                                        <View style={{ flexDirection: 'column', gap: 5 }}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <Toggle
                                                    checked={towingToggles.towing_details}
                                                    onChange={handleTowingToggle('towing_details')}
                                                >
                                                    Towing Details
                                                </Toggle>
                                            </View>
                                            {towingToggles.towing_details &&
                                                <Card
                                                    disabled={true}
                                                    header={() => <Text>Towing Details</Text>}
                                                    status='basic'
                                                >

                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Toggle
                                                            checked={towingToggles.towing_locations}
                                                            onChange={handleTowingToggle('towing_locations')}
                                                        >
                                                            Towing Locations
                                                        </Toggle>
                                                    </View>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Toggle
                                                            checked={towingToggles.towing_vehicle}
                                                            onChange={handleTowingToggle('towing_vehicle')}
                                                        >
                                                            Towing Vehicle/Equipment
                                                        </Toggle>
                                                    </View>
                                                </Card>
                                            }
                                        </View>
                                    }
                                </View>
                                <View style={{ flexDirection: 'row' }}>

                                    <Toggle
                                        checked={toggles.address}
                                        onChange={handleToggle('address')}
                                    >
                                        Address
                                    </Toggle>
                                </View>

                                <View style={{ flexDirection: 'row' }}>

                                    <Toggle
                                        checked={toggles.location}
                                        onChange={handleToggle('location')}
                                    >
                                        Location
                                    </Toggle>
                                </View>
                                <View style={{ flexDirection: 'row' }}>

                                    <Toggle
                                        checked={toggles.comments}
                                        onChange={handleToggle('comments')}
                                    >
                                        Comments
                                    </Toggle>

                                </View>

                                <View style={{ flexDirection: 'row' }}>

                                    <Toggle
                                        checked={toggles.notes}
                                        onChange={handleToggle('notes')}
                                    >
                                        Notes
                                    </Toggle>
                                </View>

                                <View style={{ flexDirection: 'row' }}>

                                </View>

                                <View style={{ flexDirection: 'row' }}>
                                    <Toggle
                                        checked={toggles.selected_services}
                                        onChange={handleToggle('selected_services')}
                                    >
                                        Selected Services
                                    </Toggle>
                                </View>
                            </View>
                        </ScrollView>
                    </Layout>
                    {width >= windowCollapseWidth &&
                        //@ts-ignore
                        <Layout level="2" style={{ padding: 10, flex: 1 }}>
                            <ScrollView style={styles.scrollView}>
                                <ReportDetailsCard job={copiedJob as Job} />
                            </ScrollView>
                        </Layout>
                    }
                </Layout>

                {width <= windowCollapseWidth && showJobReportSmallScreen &&
                    // @ts-ignore
                    <Layout level="2" style={{ padding: 10, flex: 1 }}>
                        <ScrollView style={styles.scrollView}>
                            <ReportDetailsCard job={copiedJob as Job} />
                        </ScrollView>
                    </Layout>
                }

                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button onPress={handleCloseCopyModal} appearance="outline" style={styles.button} status="warning">Cancel</Button>
                    <Button onPress={handleConfirmCopy} style={styles.button} status="success">Confirm Copy</Button>
                </Layout>
            </Modal>
        );
    }

    if (!job) {
        return null;
    }

    return (
        <>
            {!showModalDirect && RenderCopyJobButton()}
            {RenderModal()}
        </>
    );
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    button: {
        margin: 10,
        flex: 1,
    },
    modal: {
        // width: '90%',
        height: '90%',
    },
    modalTitle: {
        textAlign: 'center',
    },
    scrollView: {
        marginBottom: 20,
    },
});