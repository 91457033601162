import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card,
    useTheme
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as HoldingController from "../../functions/holding.controller"
import * as StorageController from "../../functions/storageController"
import * as JobController from "../../functions/job.controller"
import * as UpdateController from "../../functions/update.controller"
import { ReportDetailsCard } from './details/reportDetailsCard.component';
import { CreateJobFromHolding } from './modals/createJobFromHoldingModal.component';
import { HoldingDetailsContainer } from './holding/holdingDetailsContainer.component';
import { HoldingDetailsModalContainer } from './holding/holdingDetailModal';
import Toast from 'react-native-toast-message';
import { Job, JobTowingDetails, TOWING_TYPE } from '../../models/Job.model';
import { Holding, HOLDING_STATUS } from '../../models/Holding.model';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';
const { width, height } = Dimensions.get('window');



interface HoldingListProps {
    preloadedHoldingItems: any
    selectedCompany: any
    holdingFilter: HOLDING_STATUS
    allowShowHoldingDetails: boolean, // to show holding details modal from clicking this item
    onSelectHolding: (holdingItem: Holding) => void
}


export const HoldingList = ({
    preloadedHoldingItems,
    selectedCompany,
    holdingFilter,
    allowShowHoldingDetails = true,
    onSelectHolding
}: HoldingListProps) => {

    const [selectedHoldingItem, setSelectedHoldingItem] = useState<Holding | null>(null)
    const [holdings, setHoldings] = useState<Holding[]>([])
    const [showJobDetails, setShowJobDetails] = useState(false)
    const [jobLoadedForReport, setJobLoadedForReport] = useState<Job | null>(null)
    const [showCreateJobFromHoldingModal, setShowCreateJobFromHoldingModal] = useState(false)
    const [releasedHoldingItems, setReleasedHoldingItems] = useState<Holding[]>([])
    const releasedHoldingsRef = useRef([])
    const { width, height } = Dimensions.get('window');

    const holdingItemIdRef = useRef(null as string | null)
    const holdingJobIdRef = useRef(null as string | null)
    const selectedHoldingItemRef = useRef(null as Holding | null)
    //@ts-ignore
    const theme = useTheme() as any

    useEffect(() => {
        // on selected company
        if (selectedCompany && (!preloadedHoldingItems || preloadedHoldingItems?.length == 0)) {
            loadCompanyHoldings()
        }
    }, [selectedCompany, preloadedHoldingItems])

    useEffect(() => {
        // if holding items have been passed in
        if (holdingFilter == HOLDING_STATUS.RELEASED) {
            onShowReleasedList()
            return
        }
        if (preloadedHoldingItems && preloadedHoldingItems.length > 0) {
            onSetHoldings(preloadedHoldingItems)
        }
        if ((!preloadedHoldingItems || preloadedHoldingItems?.length == 0)) {
            onShowReleasedList()
        } else if ((!preloadedHoldingItems || preloadedHoldingItems?.length == 0) && holdingFilter == HOLDING_STATUS.HELD) {
            loadCompanyHoldings()
        }
    }, [holdingFilter, preloadedHoldingItems])

    const holdingsRef = useRef(holdings)
    const onSetHoldings = (holdings: Holding[]) => {
        setHoldings(holdings)
        holdingsRef.current = holdings
    }


    
    const { dispatchEventStateChange } = useAppStateChange({
        onHoldingsUpdate : (action: IAction) => {
            const updatedHolding = action.data as Holding
                const company = StorageController.getCurrentCompany()
                const companyHoldings = company.holdings
            onSetHoldings(companyHoldings.filter((holding) => holding.status == "held"))
        }
    })


    /////////////////////////////////////////////
    ////////////////////////////////////////////
    // RELEASED HOLDINGS LIST
    ////////////////////////////////////////////
    ////////////////////////////////////////////

    const loadReleasedHoldings = async () => {
        const holdings = await HoldingController.findByCompanyIdAndStatus(selectedCompany._id, "released", 10, 0)
        releasedHoldingsRef.current = holdings || []
        setReleasedHoldingItems(holdings)
    }

    const onShowReleasedList = async () => {
        await loadReleasedHoldings()
    }

    const pagenateReleasedHoldings = async () => {
        const holdings = await HoldingController.findByCompanyIdAndStatus(selectedCompany._id, "released", 10, releasedHoldingsRef.current.length)
        setReleasedHoldingItems([...releasedHoldingsRef.current, ...holdings])
    }

    /////////////////////////////////////////////
    ////////////////////////////////////////////
    // HELD HOLDINGS LIST
    ////////////////////////////////////////////
    ////////////////////////////////////////////

    const onSelectedHoldingItem = async (holding: Holding) => {
        setSelectedHoldingItem(holding)
    }

    const onOpenJobReport = async (job_id: string) => {
        const job = await JobController.getJobById(job_id) as Job

        setJobLoadedForReport(job)
        setShowJobDetails(true)
    }

    const loadCompanyHoldings = async () => {
        const holdings = await HoldingController.findByCompanyIdAndStatus(selectedCompany._id, "held")
        onSetHoldings(holdings)
    }

    const RenderJobDetailModal = () => {
        return (
            <Modal
                visible={showJobDetails}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowJobDetails(false)}
            >
                <View style={{ height: height * 0.95 }}>
                    <ScrollView style={{ flex: 1 }}>
                        <ReportDetailsCard
                            job={jobLoadedForReport as Job}
                        />
                    </ScrollView>
                </View>
            </Modal>
        )
    }

    const [showRemoveFromHoldingOptionsModal, setShowRemoveFromHoldingOptionsModal] = useState(false)
    //Shows options to either create a job from the holding or just remove it from the holding with no job
    const RenderRemoveFromHoldingOptionsModal = () => {
        return (
            <Modal
                visible={showRemoveFromHoldingOptionsModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowRemoveFromHoldingOptionsModal(false)}
            >
                <Card
                    disabled={true}
                    style={{ margin: 5, minHeight: 200 }}
                    status="warning"
                    header={() => <Text category='s1'>Remove From Holding</Text>}
                    footer={() =>
                        <View style={{ flexDirection: 'row', gap: 3, justifyContent: 'flex-end' }}>
                            <Button size="small" status="danger" appearance="outline" onPress={() => { setShowRemoveFromHoldingOptionsModal(false) }}>Cancel</Button>
                        </View>
                    }
                >
                    <View style={{ margin: -15, flexDirection: "row", gap: 15, flex: 1 }}>
                        <Card
                            disabled={true}
                            style={{ height: "100%", margin: 5 }}
                            status="success"
                            header={() => <Text category='s1'>Remove and Create Job</Text>}
                            footer={() =>
                                <View style={{ justifyContent: 'flex-end' }}>
                                    <Button
                                        size='small'
                                        appearance='outline'
                                        status='success'
                                        onPress={() => {
                                            setShowRemoveFromHoldingOptionsModal(false)
                                            setShowCreateJobFromHoldingModal(true)
                                        }}>Create Job</Button>
                                </View>
                            }
                        >
                            <View style={{ height: "100%", flexDirection: 'column', gap: 5, justifyContent: 'space-evenly' }}>
                                <Text category='p1'>This will remove the item from the holding and create a job for it.</Text>

                            </View>
                        </Card>
                        <Card
                            disabled={true}
                            style={{ margin: 5, flex: 1 }}
                            status="info"
                            header={() => <Text category='s1'>Remove Only</Text>}
                            footer={() =>
                                <View style={{ justifyContent: 'flex-end' }}>

                                    <Button
                                        size='small'
                                        appearance='outline'
                                        status='purple'
                                        onPress={() => {
                                            selectedHoldingItemRef.current?.setReleased()
                                            Toast.show({
                                                type: 'success',
                                                position: 'top',
                                                text1: 'Holding Released',
                                                text2: 'Item has been released from holding',
                                                visibilityTime: 3000,
                                                autoHide: true,
                                                topOffset: 30,
                                                bottomOffset: 40,
                                            });
                                            setShowRemoveFromHoldingOptionsModal(false)
                                        }}>Remove From Holding Only</Button>
                                </View>
                            }
                        >
                            <View style={{ flex: 1, flexDirection: 'column', gap: 5, justifyContent: 'space-evenly' }}>
                                <Text category='p1'>This will remove the item from the holding without creating a job.</Text>
                            </View>
                        </Card>
                    </View>
                </Card>
            </Modal>
        )
    }



    const onReleaseAndCreateJob = (item: Holding) => {
        setSelectedHoldingItem(item)
        selectedHoldingItemRef.current = item
        holdingItemIdRef.current = item?._id as string
        if (item?.holding_job_id) {
            holdingJobIdRef.current = item?.holding_job_id
        }
        setShowRemoveFromHoldingOptionsModal(true)
        setShowCreateJobFromHoldingModal(true)
    }






    const onCreateJob = async (createdJob: Job) => {
        try {

            // update holding
            const updatedHolding = await HoldingController.updateHoldingSetReleased(holdingItemIdRef.current as string, createdJob._id)

            setShowCreateJobFromHoldingModal(false)
            loadCompanyHoldings()
        } catch (err) {
            console.log("🚀 ~ file: holdingList.component.js:241 ~ onCreateJob ~ err:", err)
        }
    }

    const [showHoldingDetailsModal, setShowHoldingDetailsModal] = useState(false)
    const onOpenHoldingDetails = (holdingItem: Holding) => {
        setSelectedHoldingItem(holdingItem)
        onSelectHolding?.(holdingItem)
        setSelectedHoldingItem(holdingItem)
        setShowHoldingDetailsModal(true)
    }

    return (
        <View style={{ flex: 1 }}>
            {selectedHoldingItem && allowShowHoldingDetails &&
                <>
                    <HoldingDetailsModalContainer
                        visible={showHoldingDetailsModal}
                        holdingItem={selectedHoldingItem}
                        // visible={showHoldingDetailsModal}
                        fn_onReleaseAndCreateJobHolding={(holdingItem: Holding) => {
                            setShowHoldingDetailsModal(false);
                            onReleaseAndCreateJob(holdingItem);
                        }}
                        fn_onReleaseOnlyHolding={(holdingItem: Holding) => {
                            setShowHoldingDetailsModal(false);
                            // probably update lists or something idk
                        }}
                        fn_onClose={() => setShowHoldingDetailsModal(false)}
                        disabled={false} />
                    <RenderRemoveFromHoldingOptionsModal />
                    <Modal
                        visible={showCreateJobFromHoldingModal}
                        backdropStyle={styles.backdrop}
                        style={styles.modal}
                        onBackdropPress={() => setShowCreateJobFromHoldingModal(false)}
                    >
                        <CreateJobFromHolding
                            visible={showCreateJobFromHoldingModal}
                            holdingItemId={holdingItemIdRef.current as string}
                            holdingJobId={holdingJobIdRef.current as string}
                            onCloseModal={() => setShowCreateJobFromHoldingModal(false)}
                            onCreateJob={onCreateJob}
                        />
                    </Modal>
                </>
            }
            <RenderJobDetailModal />
            {preloadedHoldingItems && preloadedHoldingItems.length > 0 ?
                <List
                    data={preloadedHoldingItems}
                    ItemSeparatorComponent={Divider}
                    renderItem={({ item, index }: { item: Holding, index: number }) => (
                        <HoldingListItem
                            isSelected={selectedHoldingItem?._id == item._id}
                            item={item}
                            index={index}
                            onOpenJobReport={onOpenJobReport}
                            onRemoveFromHolding={onReleaseAndCreateJob}
                            onOpenHoldingDetails={onOpenHoldingDetails}
                        />
                    )}
                />
                :
                <>
                    {holdingFilter == HOLDING_STATUS.RELEASED &&
                        <List
                            data={releasedHoldingItems}
                            ItemSeparatorComponent={Divider}
                            renderItem={({ item, index }: { item: Holding, index: number }) => (
                                <HoldingListItem
                                    isSelected={selectedHoldingItem?._id == item._id}
                                    item={item}
                                    index={index}
                                    onOpenJobReport={onOpenJobReport}
                                    onRemoveFromHolding={onReleaseAndCreateJob}
                                    onOpenHoldingDetails={onOpenHoldingDetails}
                                />
                            )}
                            onEndReached={pagenateReleasedHoldings}
                        />
                    }
                    {holdingFilter == HOLDING_STATUS.HELD &&
                        <List
                            data={holdings}
                            ItemSeparatorComponent={Divider}
                            renderItem={({ item, index }: { item: Holding, index: number }) => (
                                <HoldingListItem
                                    isSelected={selectedHoldingItem?._id == item._id}
                                    item={item}
                                    index={index}
                                    onOpenJobReport={onOpenJobReport}
                                    onRemoveFromHolding={onReleaseAndCreateJob}
                                    onOpenHoldingDetails={onOpenHoldingDetails}
                                />
                            )}
                        />
                    }
                </>
            }
        </View>
    );
}


interface HoldingListItemProps {
    isSelected: boolean
    item: Holding
    index: number
    onOpenJobReport: (job_id: string) => void
    onRemoveFromHolding: (item: Holding) => void
    onOpenHoldingDetails: (item: Holding) => void
}


export const HoldingListItem = ({ item, index, isSelected, onOpenJobReport, onRemoveFromHolding, onOpenHoldingDetails }: HoldingListItemProps) => {

    //@ts-ignore
    const theme = useTheme() as any
    const timestampToReadable = (timestamp: number) => {
        const date = new Date(timestamp);
        const dateFormatter = new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
        return dateFormatter.format(date);
    };
    // make readable date from timestamp on item.holding_time
    const holding_time = new Date(item.holding_time)
    // DD/MM/YYYY HH:MM
    const holding_time_readable =
        holding_time.getDate() + "/" +
        (holding_time.getMonth() + 1) + "/" +
        holding_time.getFullYear() + " " +
        holding_time.getHours() + ":" +
        holding_time.getMinutes()


    // time elapsed since holding_time
    let holding_time_elapsed = 0
    if (item.status == "released" && item.released_time) {
        holding_time_elapsed = item.released_time - holding_time.getTime();
    } else {
        holding_time_elapsed = Date.now() - holding_time.getTime();
    }

    // Calculate days, hours, and minutes separately
    const days = Math.floor(holding_time_elapsed / (1000 * 60 * 60 * 24));
    const hours = Math.floor((holding_time_elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((holding_time_elapsed % (1000 * 60 * 60)) / (1000 * 60));

    // Format the elapsed time into a readable string
    const holding_time_elapsed_readable = `${days} days ${hours} hours ${minutes} minutes`;

    let itemType = "Equipment"
    let itemDescription = ""
    let itemSerialOrRego = ""
    let equipmentType = ""

    const towingDetails = new JobTowingDetails(item.details?.towing_details)

    itemType = towingDetails.towing_type == TOWING_TYPE.EQUIPMENT ? "Equipment" : "Vehicle"


    const buildDesctipion = () => {
        let description = ""
        if (item.details.vehicle_details) {
            description += item.details.vehicle_details.make || ""
            description += " "
            description += item.details.vehicle_details.model || ""
        }
        if (item.details.equipment_details) {
            description += item.details.equipment_details.equipment || ""
        }
        return description
    }

    const buildSerialOrRego = () => {
        let serialOrRego = ""
        if (item.details.vehicle_details) {
            serialOrRego += item.details.vehicle_details.rego || ""
        }
        if (item.details.equipment_details) {
            serialOrRego += item.details.equipment_details.serial || ""
        }
        return serialOrRego
    }

    // itemDescription = towingDetails?.equipment_details?.equipment || ""
    // itemSerialOrRego += towingDetails?.equipment_details?.serial || ""
    // itemDescription += towingDetails.vehicle_details?.make ?? "" + " " + towingDetails.vehicle_details?.model ?? ""
    // itemSerialOrRego += towingDetails?.vehicle_details?.rego || ""

    const RenderHoldingStatusBadge = () => (
        <View style={{
            backgroundColor: item.status == "held" ? "orange" : "green",
            borderRadius: 5,
            padding: 5,
            justifyContent: 'center',
        }}>
            {/* capitalise item.status */}
            <Text category='s1' style={{ color: 'white', textAlign: 'center' }}>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</Text>
        </View>
    )

    return (
        <Card

            onPress={() => onOpenHoldingDetails(item)}
            // disabled={true}
            style={[{ backgroundColor: isSelected ? theme['background-basic-color-3'] : theme['background-basic-color-1'] }]}
            status={item.status == "held" ? "warning" : "success"}
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: 'row', gap: 3 }}>
                        <Text category='s1'>Time Elapsed:</Text>
                        <Text category='p1'>{holding_time_elapsed_readable}</Text>
                    </View>
                </View>
            }
            footer={() =>
                <View style={{ flexDirection: 'row', gap: 3, justifyContent: 'space-between' }}>
                    <RenderHoldingStatusBadge />
                    {item.status == "released" &&
                        <View style={{ flexDirection: 'row', gap: 3 }}>
                            <Text category='s1'>Released Job:</Text>
                            <Text category='p1'>{item.released_job_id}</Text>
                        </View>
                    }
                    <View style={{ flexDirection: 'row', gap: 3 }}>
                        {item.status == "held" &&
                            <Button size="small" status="success" appearance="outline" onPress={() => { onOpenHoldingDetails(item) }}>Open Details</Button>
                        }
                    </View>
                </View>
            }
        >
            <View style={{ flexDirection: 'row', margin: -15, marginBottom: 0, paddingBottom: 5, justifyContent: 'space-between' }}>
                <View style={[styles.cardColumn, styles.debug]}>
                    <View style={{ flex: 1 }}>
                        <View style={{ flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
                            <Text category='s1' style={{ alignSelf: 'flex-end' }}>Type:</Text>
                            <Text category='c1' style={{ alignSelf: 'flex-end' }}>{itemType}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
                            <Text category='s1' style={{ alignSelf: 'flex-end' }}>Description:</Text>
                            <Text category='c1' style={{ alignSelf: 'flex-end' }}>{buildDesctipion()}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
                            <Text category='s1' style={{ alignSelf: 'flex-end' }}>Serial/Rego:</Text>
                            <Text category='c1' style={{ alignSelf: 'flex-end' }}>{buildSerialOrRego()}</Text>
                        </View>
                    </View>

                </View>
                <View style={[styles.cardColumn, styles.debug]}>
                    {/* Times */}
                    <View style={{ flex: 1 }}>
                        {/* Start Time */}
                        <View style={{ flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
                            <Text category='s1' style={{ alignSelf: 'flex-end' }}>Holding Time:</Text>
                            <Text category='c1' style={{ alignSelf: 'flex-end' }}>{holding_time_readable}</Text>
                        </View>
                        {/* Time elapsed */}
                        <View style={{ flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
                            <Text category='s1' style={{ alignSelf: 'flex-end' }}>Time Held:</Text>
                            <Text category='c1' style={{ alignSelf: 'flex-end' }}>{holding_time_elapsed_readable}</Text>
                        </View>
                        {item.status == "released" &&
                            <View style={{ flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
                                <Text category='s1' style={{ alignSelf: 'flex-end' }}>Released Time:</Text>
                                <Text category='c1' style={{ alignSelf: 'flex-end' }}>{timestampToReadable(item.released_time)}</Text>
                            </View>
                        }
                        <View style={{ flex: 1, flexDirection: 'row', gap: 3, flexWrap: 'wrap' }}>
                            <Text category='s1' style={{ alignSelf: 'flex-end' }}>Reason:</Text>
                            <Text category='c1' style={{ alignSelf: 'flex-end' }}>{item.reason}</Text>
                        </View>
                    </View>
                </View>
                <View style={[styles.cardColumn, styles.orangeDebug, { flex: 1 }]}>
                    {item.holding_job_id &&
                        <Button size="small" status="basic" appearance="outline" onPress={() => onOpenJobReport(item.holding_job_id as string)}>Holding Report</Button>
                    }
                    {
                        item.released_job_id && item.status == "released" &&
                        <Button size="small" status="basic" appearance="outline" onPress={() => onOpenJobReport(item.released_job_id as string)}>Released Report</Button>
                    }
                </View>
            </View>
        </Card>
    )
}
const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modal: {
        width: width * 0.9,
        height: height * 0.9,
        // backgroundColor: 'white',
    },
    cardColumn: {
        flexDirection: 'column',
        // justifyContent: 'space-between',
        flex: 2,
    },
    debug: {
        // borderWidth: 1,
        // borderColor: 'red',
        // borderStyle: 'solid',
    },
    orangeDebug: {
        // borderWidth: 1,
        // borderColor: 'orange',
        // borderStyle: 'solid',
    }
});