import React from 'react';
import { Text } from '@ui-kitten/components';
import { Job, JobReport, JobVehicleDetails, JobTowingDetails, JobCustomerDetails, Service, LineItem } from '../../models/Job.model';
import { Holding } from '../../models/Holding.model';
import { JobInvoiceRow, HoldingInvoiceRow } from './invoicingTypes';
import * as Utils from './invoiceUtils';
import * as Formatters from './invoiceFormatters';
import { createVehicleColumns, createTowingColumns, JobsColumnsRenderer, HoldingColumnsRenderer, itemSubrowColumns } from './InvoiceColumnsConfigJobs';
import { Column } from 'react-data-grid';

const buildDescription = (details: any, fields: string[]): string => {
    if (!details) return "";
    return fields
        .map((field: string) => details[field] || "")
        .filter((value: string) => value !== "")
        .join(" | ");
};


interface AddAllFieldsToJobRowsProps {
    rows: Job[];
    servicesCheckedRef: React.MutableRefObject<Service[]>;
    onItemsExpanded: (args: any) => void;
    onOpenJobDetailsPanel: (args: any) => void;
    getClientName: (clientId: string) => string;
    getClientAccountCode: (clientId: string) => string;
    getMemberName: (memberId: string) => string;
}

export const addAllFieldsToJobRows = ({
    rows,
    servicesCheckedRef,
    onItemsExpanded,
    onOpenJobDetailsPanel,
    getClientName,
    getClientAccountCode,
    getMemberName
}: AddAllFieldsToJobRowsProps): { rows: JobInvoiceRow[], columns: any[] } => {
    let allServices = new Set<string>();
    let serviceColumns: any[] = [];
    let columnKeys = new Set<string>();
    const addedServices = new Set<string>();

    rows?.forEach((job) => {
        const hasRoadsideOptions = job.details?.options?.roadside_job_options ?? true;
        const hasTowingOptions = job.details?.options?.towing_job_options ?? false;

        if (hasRoadsideOptions) {
            const vehicleColumns = createVehicleColumns(job);
            vehicleColumns.forEach((column: any) => {
                if (!columnKeys.has(column.key)) {
                    columnKeys.add(column.key);
                    serviceColumns.push(column);
                }
            });
        }

        if (hasTowingOptions) {
            const towingColumns = createTowingColumns(job);
            towingColumns.forEach((column: any) => {
                if (!columnKeys.has(column.key)) {
                    columnKeys.add(column.key);
                    serviceColumns.push(column);
                }
            });
        }

        if (servicesCheckedRef.current?.length === 0) {
            job.details.selected_services?.forEach((service) => {
                allServices.add(service.name);
            });
        } else {
            job.details.selected_services?.forEach((service) => {
                if (servicesCheckedRef.current.some((s: Service) => s._id === service._id) && !addedServices.has(service.name)) {
                    allServices.add(service.name);
                    addedServices.add(service.name);
                }
            });
        }

        allServices.forEach((serviceName) => {
            const serviceItem = job.details?.selected_services?.find((item) => item.name === serviceName);
            if (serviceItem) {
                Object.entries(serviceItem.fields).forEach(([fieldKey, fieldName]: [string, any]) => {
                    const isInvoiced = serviceItem.invoiced[fieldKey] === true;
                    const fieldValue = serviceItem.values[fieldKey];
                    if (isInvoiced) {
                        const columnKey = `details.selected_services.${serviceName}.${fieldKey}`;
                        if (!columnKeys.has(columnKey)) {
                            columnKeys.add(columnKey);
                            const isCost = serviceItem.types[fieldKey] === 'cost';
                            serviceColumns.push({
                                key: columnKey,
                                name: `${serviceName} | ${fieldName}`,
                                formatter: ({ row }: { row: JobInvoiceRow }) => {
                                    if (!row.details?.selected_services) return null;
                                    const service = row.details.selected_services.find((s) => s.name === serviceName);
                                    if (isCost) {
                                        return (<Text style={{ backgroundColor: "rgba(0,0,255,0.2)" }}>{service ? Formatters.paymentFormatter(fieldValue) : ''}</Text>);
                                    } else {
                                        return <Text>{service ? fieldValue?.toString() : ''}</Text>;
                                    }
                                },
                            });
                        }
                    }
                });
            }
        });
    });

    allServices.forEach((serviceName: string) => {
        serviceColumns.push({
            key: `details.selected_services.${serviceName}`,
            name: serviceName,
            formatter: ({ row }: { row: JobInvoiceRow }) => {
                if (!row.details?.selected_services) return null;
                const service = row.details?.selected_services.find(
                    (s) => s.name === serviceName
                );
                return <Text>{service ? service.name : ''}</Text>;
            },
        });
    });

    const updatedRows = rows?.map((job, index) => {
        const updatedJob = new JobInvoiceRow(job);
        serviceColumns.forEach((column) => {
            //@ts-ignore
            updatedJob[column.key] = column.formatter({ row: job });
        });
        updatedJob.id = index;
        // updatedJob.description = createJobDescription(job, getClientName, getMemberName);
        return updatedJob;
    });
    const defaultColumns = JobsColumnsRenderer({
        onItemsExpanded: onItemsExpanded as any,
        itemsExpanded: true,
        handleGridSort: () => { },
        onOpenJobDetailsPanel: onOpenJobDetailsPanel,
        getMemberName,
        getClientName,
        getClientAccountCode
    });
    return { rows: updatedRows, columns: [...defaultColumns, ...serviceColumns] };
};


interface AddAllFieldsToHoldingRowsProps {
    rows: Holding[];
    servicesCheckedRef: React.MutableRefObject<Service[]>;
    getClientName: (clientId: string) => string;
    getMemberName: (memberId: string) => string;
    onItemsExpanded: (args: any) => void;
    getClientAccountCode: (clientId: string) => string;
    onOpenHoldingDetailsPanel: (args: any) => void;
}

export const addAllFieldsToHoldingRows = ({
    rows,
    servicesCheckedRef,
    getClientName,
    getMemberName,
    onItemsExpanded,
    getClientAccountCode,
    onOpenHoldingDetailsPanel,
    // defaultColumns: any[]
}: AddAllFieldsToHoldingRowsProps): { rows: HoldingInvoiceRow[], columns: any[] } => {
    let allServices = new Set<string>();
    let serviceColumns: any[] = [];
    let columnKeys = new Set<string>();
    const addedServices = new Set<string>();

    rows?.forEach((holding) => {
        if (servicesCheckedRef.current?.length === 0) {
            holding.details.selected_services?.forEach((service) => {
                allServices.add(service.name);
            });
        } else {
            holding.details.selected_services?.forEach((service) => {
                if (servicesCheckedRef.current.some((s: Service) => s._id === service._id) && !addedServices.has(service.name)) {
                    allServices.add(service.name);
                    addedServices.add(service.name);
                }
            });
        }

        allServices.forEach((serviceName) => {
            const serviceItem = holding.details?.selected_services?.find((item) => item.name === serviceName);
            if (serviceItem) {
                Object.entries(serviceItem.fields).forEach(([fieldKey, fieldName]: [string, any]) => {
                    const isInvoiced = serviceItem.invoiced[fieldKey] === true;
                    const fieldValue = serviceItem.values[fieldKey];
                    if (isInvoiced) {
                        const columnKey = `details.selected_services.${serviceName}.${fieldKey}`;
                        if (!columnKeys.has(columnKey)) {
                            columnKeys.add(columnKey);
                            const isCost = serviceItem.types[fieldKey] === 'cost';
                            serviceColumns.push({
                                key: columnKey,
                                name: `${serviceName} | ${fieldName}`,
                                formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                                    if (!row.details?.selected_services) return null;
                                    const service = row.details.selected_services.find((s) => s.name === serviceName);
                                    if (isCost) {
                                        return <Text style={{ backgroundColor: "rgba(0,0,255,0.2)" }}>{service ? Formatters.paymentFormatter(fieldValue) : ''}</Text>;
                                    } else {
                                        return <Text>{service ? fieldValue?.toString() : ''}</Text>;
                                    }
                                },
                            });
                        }
                    }
                });
            }
        });
    });

    allServices.forEach((serviceName: string) => {
        serviceColumns.push({
            key: `details.selected_services.${serviceName}`,
            name: serviceName,
            formatter: ({ row }: { row: HoldingInvoiceRow }) => {
                if (!row.details?.selected_services) return null;
                const service = row.details?.selected_services.find(
                    (s) => s.name === serviceName
                );
                return <Text>{service ? service.name : ''}</Text>;
            },
        });
    });

    const updatedRows = rows?.map((holding, index) => {
        const updatedHolding = new HoldingInvoiceRow(holding);
        serviceColumns.forEach((column) => {
            //@ts-ignore
            updatedHolding[column.key as keyof HoldingInvoiceRow] = column.formatter({ row: holding });
        });
        updatedHolding.id = index;
        // updatedHolding.description = createHoldingDescription(holding, getClientName);
        return updatedHolding;
    });
    const defaultColumns = HoldingColumnsRenderer({
        onItemsExpanded: onItemsExpanded as any,
        itemsExpanded: true,
        handleGridSort: () => { },
        onOpenHoldingDetailsPanel: onOpenHoldingDetailsPanel,
        getMemberName,
        getClientName,
        getClientAccountCode
    });
    return { rows: updatedRows, columns: [...defaultColumns, ...serviceColumns] };
};


const createJobDescription = ({
    job,
    getMemberName,
    getClientName,
    descriptionOptions
}: {
    job: JobInvoiceRow,
    getMemberName: Function,
    getClientName: Function,
    descriptionOptions: DescriptionOptions
}): string => {
    let description = "";
    const driverName = getMemberName(job.member_id || "");
    const vehicleDetails = buildDescription(job.details.vehicle_details, ["make", "model", "year", "colour", "rego"]);
    const customerDetails = buildDescription(job.details.customer_details, ["name", "phone", "email"]);
    const equipmentDetails = buildDescription(job.details.towing_details?.equipment_details, ["equipment", "type", "weight", "size", "serial"]);
    const towingDetails = buildDescription(job.details.towing_details, ["towing_type", "distance_kms", "duration_seconds", "cost_per_km", "covered_distance_kms", "total_cost"]);
    const addressDetails = job.details.address?.addressToString() || "";
    const comments = `${job.details.comments || ""} ${job.details?.report?.comments || ""}`;
    const serviceNames = job.details?.selected_services?.map((service: Service) => service.name || "").join(" | ") || "";

    if (job.status === "cancelled") {
        description += `CANCELLED -- |`;
    } else if (job.status === "transferred_out") {
        description += `TRANSFERRED OUT -- |`;
    }

    description += `RefID: ${job.client_reference_id || ""} |`;
    if (descriptionOptions.includeDriverName) {
        description += `Driver: ${driverName} |`;
    }
    if (descriptionOptions.includeServiceNames) {
        description += `Service: ${serviceNames} |`;
    }
    if (descriptionOptions.includeTowingDetails && job.details.towing_details) {
        description += `Towing: ${towingDetails} |`;
    }
    if (descriptionOptions.includeEquipmentDetails && job.details.towing_details?.equipment_details) {
        description += `Equipment: ${equipmentDetails} |`;
    }
    if (descriptionOptions.includeAddressDetails && job.details.address) {
        description += `Address: ${addressDetails} |`;
    }
    if (descriptionOptions.includeVehicleDetails) {
        description += `Vehicle: ${vehicleDetails} |`;
    }
    if (descriptionOptions.includeCustomerDetails) {
        description += `Customer: ${customerDetails} |`;
    }
    if (descriptionOptions.includeComments) {
        // description += `Comments: ${comments.replace(",", " ").replace("\"", " ")}`;
        description += `Comments: "${comments} |`;
    }

    if (job.details?.client_rate) {
        description += ` Client Rate: ${job.details.client_rate.name} at ${job.details.client_rate.cost}`;
    }

    description = description.replace(/[^a-zA-Z0-9 .$%&|:()]/g, "")
    return description;
};

const createHoldingDescription = (holding: Holding, getClientName: (clientId: string) => string): string => {

    let description = "";
    try {
        const vehicleDetails = buildDescription(holding.details.vehicle_details, ["make", "model", "year", "colour", "rego"]);
        const customerDetails = buildDescription(holding.details.customer_details, ["name", "phone", "email"]);
        const serviceNames = holding.details?.selected_services?.map((service: Service) => service.name || "").join(" | ") || "";
        const addressDetails = holding.details?.address?.addressToString() || "";

        description += `RefID: ${holding.details.client_reference_id || ""} |`;
        description += `Service: ${serviceNames} |`;
        description += `Address: ${addressDetails} |`;
        description += `Vehicle: ${vehicleDetails} |`;
        description += `Customer: ${customerDetails} |`;
    } catch (error) {
        console.log("🚀============== ~ file: rowCreationUtils.tsx:290 ~ createHoldingDescription ~ error🚀==============", error)
    }
    return description;
};

const defaultDescriptionOptions = {
    includeServiceNames: true,
    includeVehicleDetails: true,
    includeCustomerDetails: true,
    includeDriverName: true,
    includeComments: true,
    includeClientRate: true,
    includeAddressDetails: true,
    includeTowingDetails: true,
    includeEquipmentDetails: true,
}

export interface DescriptionOptions {
    includeServiceNames: boolean;
    includeVehicleDetails: boolean;
    includeCustomerDetails: boolean;
    includeDriverName: boolean;
    includeComments: boolean;
    includeClientRate: boolean;
    includeAddressDetails: boolean;
    includeTowingDetails: boolean;
    includeEquipmentDetails: boolean;
}

export const transformJobDataToCSV = (
    sortedData: JobInvoiceRow[],
    getClientName: (clientId: string) => string,
    getClientAccountCode: (clientId: string) => string,
    getMemberName: (memberId: string) => string,
    getDueDate: () => Date,
    getTaxType: () => string,
    exportLineItemsRef: React.MutableRefObject<boolean>,
    exportBillBackItemsRef: React.MutableRefObject<boolean>,
    exportCustomerCostItemsRef: React.MutableRefObject<boolean>,
    exportServicesRef: React.MutableRefObject<boolean>,
    descriptionOptions: DescriptionOptions = defaultDescriptionOptions
): any[] => {
    let csvData: any[] = [];

    sortedData?.forEach((item: JobInvoiceRow) => {
        if (item.isSubRow) return;

        const invoiceData = createInvoiceData_XERO({
            item,
            getClientName,
            getClientAccountCode,
            getDueDate,
            getTaxType,
            getMemberName,
            descriptionOptions
        });
        csvData.push(invoiceData);

        if (exportLineItemsRef.current) {
            const lineItems = createLineItems(
                item,
                getClientName,
                getClientAccountCode,
                getDueDate,
                getTaxType,
                exportBillBackItemsRef,
                exportCustomerCostItemsRef
            );
            csvData.push(...lineItems);
        }

        if (exportServicesRef.current && item.details?.selected_services) {
            const serviceItems = createServiceItems(item, getClientName, getClientAccountCode, getDueDate, getTaxType);
            csvData.push(...serviceItems);
        }
    });

    // console.log("🚀============== ~ file: rowCreationUtils.tsx:397 ~ csvData🚀==============", csvData)
    return csvData;
};

const createInvoiceData_XERO = ({
    item,
    getClientName,
    getClientAccountCode,
    getDueDate,
    getTaxType,
    getMemberName,
    descriptionOptions
}: {
    item: JobInvoiceRow,
    getClientName: Function,
    getClientAccountCode: Function,
    getDueDate: Function,
    getTaxType: Function,
    getMemberName: Function,
    descriptionOptions: DescriptionOptions
}): any => {
    let invoiceTime = item.createdAt as string | number
    if (item.pending_time && item.pending_time > 0) {
        invoiceTime = item.pending_time as number
    } else {
        invoiceTime = item.createdAt as string
    }

    const invoiceDate = new Date(invoiceTime).toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    const dueDate = new Date(getDueDate()).toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    const clientName = getClientName(item.client_id);
    const clientAccountCode = getClientAccountCode(item.client_id);
    const taxType = getTaxType();
    const invoiceNumber = Utils.generateInvoiceNumber(item._id, item.createdAt as string, item.job_count);
    const description = createJobDescription(
        {
            job: item,
            getMemberName: getMemberName,
            getClientName: getClientName,
            descriptionOptions
        }
    );

    return {
        "*ContactName": clientName,
        "*AccountCode": clientAccountCode,
        "*TaxType": taxType,
        "*InvoiceNumber": invoiceNumber,
        "*InvoiceDate": invoiceDate,
        "*DueDate": dueDate,
        "*Quantity": 1,
        "*UnitAmount": item.details?.client_rate?.cost || 0,
        "Reference": item.client_reference_id || "",
        "Description": description,
    };
};

// *InvoiceNo	*Customer	*InvoiceDate	*DueDate	Terms	Location	Memo	*Item(Product/Service)	ItemDescription	ItemQuantity	ItemRate	*ItemAmount
const createInvoiceData_QUICKBOOKS = ({
    item,
    getClientName,
    getClientAccountCode,
    getDueDate,
    getTaxType,
    getMemberName,
    descriptionOptions
}: {
    item: JobInvoiceRow,
    getClientName: Function,
    getClientAccountCode: Function,
    getDueDate: Function,
    getTaxType: Function,
    getMemberName: Function,
    descriptionOptions: DescriptionOptions
}): any => {
    let invoiceTime = item.createdAt as string | number
    if (item.pending_time && item.pending_time > 0) {
        invoiceTime = item.pending_time as number
    } else {
        invoiceTime = item.createdAt as string
    }

    const invoiceDate = new Date(invoiceTime).toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    const dueDate = new Date(getDueDate()).toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    const clientName = getClientName(item.client_id);
    const clientAccountCode = getClientAccountCode(item.client_id);
    const taxType = getTaxType();
    const invoiceNumber = Utils.generateInvoiceNumber(item._id as string, item.createdAt as string, item.job_count as number);
    const description = createJobDescription(
        {
            job: item,
            getMemberName: getMemberName,
            getClientName: getClientName,
            descriptionOptions
        }
    );

    return {
        "*InvoiceNo": invoiceNumber,
        "*Customer": clientName,
        "*InvoiceDate": invoiceDate,
        "*DueDate": dueDate,
        "Terms": "",
        "Location": "",
        "Memo": description,
        "*Item(Product/Service)": "Service",
        "ItemDescription": description,
        "*ItemQuantity": 1,
        "*ItemRate": item.details?.client_rate?.cost || 0,
        "*ItemAmount": item.details?.client_rate?.cost || 0,
    };

};

const createLineItems = (item: JobInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function, exportBillBackItemsRef: React.MutableRefObject<boolean>, exportCustomerCostItemsRef: React.MutableRefObject<boolean>): any[] => {
    const lineItems: any[] = [];
    const report = new JobReport(item.details?.report);
    let items: LineItem[] = [];

    if (exportBillBackItemsRef.current) {
        items = items.concat(report.getTotalBillBackItems());
    }
    if (exportCustomerCostItemsRef.current) {
        items = items.concat(report.getTotalCustomerCostItems());
    }

    items.forEach((lineItem) => {
        if (isNaN(lineItem.quantity)) {
            lineItem.quantity = 1;
        }
        if (isNaN(lineItem.cost)) {
            lineItem.cost = 0;
        }
        lineItems.push({
            "*ContactName": getClientName(item.client_id),
            "*AccountCode": getClientAccountCode(item.client_id),
            "*TaxType": "GST on Income",
            "*InvoiceNumber": Utils.generateInvoiceNumber(item._id as string, item.createdAt as string, item.job_count as number),
            "*InvoiceDate": new Date(item.pending_time as number || item.createdAt as string).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*DueDate": new Date(getDueDate()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*Quantity": lineItem.quantity,
            "*UnitAmount": lineItem.cost,
            "Description": `${lineItem.billAllBack ? 'Bill All Back' : lineItem.customerCost ? 'Customer Cost' : ''} - ${lineItem.description || lineItem.name || ""}`,
        });
    });

    return lineItems;
};

const createServiceItems = (item: JobInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function): any[] => {
    const serviceItems: any[] = [];

    item.details?.selected_services?.forEach((service: Service) => {
        if (!service?.fields || Object.keys(service?.fields).length === 0) return;

        const serviceData: any = {
            "*ContactName": getClientName(item.client_id),
            "*AccountCode": getClientAccountCode(item.client_id),
            "*TaxType": getTaxType(),
            "*InvoiceNumber": Utils.generateInvoiceNumber(item._id as string, item.createdAt as string, item.job_count as number),
            "*InvoiceDate": new Date(item.pending_time as number || item.createdAt as string).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*DueDate": new Date(getDueDate()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
        };

        Object.keys(service?.fields)?.forEach((field: string) => {
            if (service.invoiced[field]) {
                if (service.types[field] == "cost") {
                    serviceData["*UnitAmount"] = service.values[field];
                } else if (service.types[field] == "quantity") {
                    serviceData["*Quantity"] = service.values[field];
                } else {
                    serviceData[`${service.name}-${field}`] = service.values[field];
                }
            }
        });

        serviceItems.push(serviceData);
    });

    return serviceItems;
};

export const transformHoldingDataToCSV = (
    sortedData: HoldingInvoiceRow[],
    getClientName: (clientId: string) => string,
    getClientAccountCode: (clientId: string) => string,
    getDueDate: () => Date,
    getTaxType: () => string,
    exportLineItemsRef: React.MutableRefObject<boolean>,
    exportBillBackItemsRef: React.MutableRefObject<boolean>,
    exportCustomerCostItemsRef: React.MutableRefObject<boolean>,
    exportServicesRef: React.MutableRefObject<boolean>
): any[] => {
    let csvData: any[] = [];

    sortedData?.forEach((item: HoldingInvoiceRow) => {
        if (item.isSubRow) return;

        const invoiceData = createHoldingInvoiceData(item, getClientName, getClientAccountCode, getDueDate, getTaxType);
        csvData.push(invoiceData);

        if (exportLineItemsRef.current) {
            const lineItems = createHoldingLineItems(item, getClientName, getClientAccountCode, getDueDate, getTaxType, exportBillBackItemsRef, exportCustomerCostItemsRef);
            csvData.push(...lineItems);
        }

        if (exportServicesRef.current && item.details?.selected_services) {
            const serviceItems = createHoldingServiceItems(item, getClientName, getClientAccountCode, getDueDate, getTaxType);
            csvData.push(...serviceItems);
        }
    });

    return csvData;
};

const createHoldingInvoiceData = (item: HoldingInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function): any => {
    const invoiceDate = new Date(item.released_time || Date.now()).toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    const dueDate = new Date(getDueDate()).toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    const clientName = getClientName(item.client_id);
    const clientAccountCode = getClientAccountCode(item.client_id);
    const taxType = getTaxType();
    const invoiceNumber = item.name;
    const description = createHoldingDescription(item, getClientName as any);

    return {
        "*ContactName": clientName,
        "*AccountCode": clientAccountCode,
        "*TaxType": taxType,
        "*InvoiceNumber": invoiceNumber,
        "*InvoiceDate": invoiceDate,
        "*DueDate": dueDate,
        "*Quantity": 1,
        "*UnitAmount": item.details?.client_rate?.cost || 0,
        "Reference": item.details?.client_reference_id || "",
        "Description": description,
    };
};

const createHoldingLineItems = (item: HoldingInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function, exportBillBackItemsRef: React.MutableRefObject<boolean>, exportCustomerCostItemsRef: React.MutableRefObject<boolean>): any[] => {
    const lineItems: any[] = [];
    let items: LineItem[] = [];

    if (exportBillBackItemsRef.current) {
        items = items.concat(item.getTotalBillBackItems());
    }
    if (exportCustomerCostItemsRef.current) {
        items = items.concat(item.getTotalCustomerCostItems());
    }

    

    items.forEach((lineItem) => {
        if (isNaN(lineItem.quantity)) {
            lineItem.quantity = 1;
        }
        if (isNaN(lineItem.cost)) {
            lineItem.cost = 0;
        }
        lineItems.push({
            "*ContactName": getClientName(item.client_id),
            "*AccountCode": getClientAccountCode(item.client_id),
            "*TaxType": "GST on Income",
            "*InvoiceNumber": item.name,
            "*InvoiceDate": new Date(item.released_time || Date.now()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*DueDate": new Date(getDueDate()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*Quantity": lineItem.quantity,
            "*UnitAmount": lineItem.cost,
            "Description": `${lineItem.billAllBack ? 'Bill All Back' : lineItem.customerCost ? 'Customer Cost' : ''} - ${lineItem.description || lineItem.name || ""}`,
        });
    });

    return lineItems;
};

const createHoldingServiceItems = (item: HoldingInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function): any[] => {
    const serviceItems: any[] = [];

    item.details?.selected_services?.forEach((service: Service) => {
        if (!service?.fields || Object.keys(service?.fields).length === 0) return;

        const serviceData: any = {
            "*ContactName": getClientName(item.client_id),
            "*AccountCode": getClientAccountCode(item.client_id),
            "*TaxType": getTaxType(),
            "*InvoiceNumber": item.name,
            "*InvoiceDate": new Date(item.released_time || Date.now()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*DueDate": new Date(getDueDate()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
        };

        Object.keys(service?.fields)?.forEach((field: string) => {
            if (service.invoiced[field]) {
                if (service.types[field] == "cost") {
                    serviceData["*UnitAmount"] = service.values[field];
                } else if (service.types[field] == "quantity") {
                    serviceData["*Quantity"] = service.values[field];
                } else {
                    serviceData[`${service.name}-${field}`] = service.values[field];
                }
            }
        });

        serviceItems.push(serviceData);
    });

    return serviceItems;
};

export const transformJobDataToQuickBooksCSV = (
    sortedData: JobInvoiceRow[],
    getClientName: (clientId: string) => string,
    getClientAccountCode: (clientId: string) => string,
    getMemberName: (memberId: string) => string,
    getDueDate: () => Date,
    getTaxType: () => string,
    exportLineItemsRef: React.MutableRefObject<boolean>,
    exportBillBackItemsRef: React.MutableRefObject<boolean>,
    exportCustomerCostItemsRef: React.MutableRefObject<boolean>,
    exportServicesRef: React.MutableRefObject<boolean>,
    descriptionOptions: DescriptionOptions
): any[] => {
    let csvData: any[] = [];

    sortedData?.forEach((item: JobInvoiceRow) => {
        if (item.isSubRow) return;

        const invoiceData = createInvoiceData_QUICKBOOKS({
            item,
            getClientName,
            getClientAccountCode,
            getDueDate,
            getTaxType,
            getMemberName,
            descriptionOptions
        });
        csvData.push(invoiceData);

        if (exportLineItemsRef.current) {
            const lineItems = createQuickBooksLineItems(
                item,
                getClientName,
                getClientAccountCode,
                getDueDate,
                getTaxType,
                exportBillBackItemsRef,
                exportCustomerCostItemsRef
            );
            csvData.push(...lineItems);
        }

        if (exportServicesRef.current && item.details?.selected_services) {
            const serviceItems = createQuickBooksServiceItems(item, getClientName, getClientAccountCode, getDueDate, getTaxType);
            csvData.push(...serviceItems);
        }
    });

    // console.log("🚀============== ~ file: rowCreationUtils.tsx:809 ~ csvData🚀==============", csvData)
    return csvData;
};

const createQuickBooksLineItems = (item: JobInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function, exportBillBackItemsRef: React.MutableRefObject<boolean>, exportCustomerCostItemsRef: React.MutableRefObject<boolean>): any[] => {
    const lineItems: any[] = [];
    const report = new JobReport(item.details?.report);
    let items: LineItem[] = [];

    if (exportBillBackItemsRef.current) {
        items = items.concat(report.getTotalBillBackItems());
    }
    if (exportCustomerCostItemsRef.current) {
        items = items.concat(report.getTotalCustomerCostItems());
    }

    items.forEach((lineItem) => {
        // console.log("🚀============== ~ file: rowCreationUtils.tsx:824 ~ items.forEach ~ lineItem🚀==============", lineItem)
        if (isNaN(lineItem.quantity)) {
            lineItem.quantity = 1;
        }
        if (isNaN(lineItem.cost)) {
            lineItem.cost = 0;
        }
        lineItems.push({
            "*InvoiceNo": Utils.generateInvoiceNumber(item._id as string, item.createdAt as string, item.job_count as number),
            "*Customer": getClientName(item.client_id),
            "*InvoiceDate": new Date(item.pending_time as number || item.createdAt as string).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*DueDate": new Date(getDueDate()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "Terms": "",
            "Location": "",
            // "Memo": `${lineItem.billAllBack ? 'Bill All Back' : lineItem.customerCost ? 'Customer Cost' : ''} - ${lineItem.description || lineItem.name || ""}`,
            "Memo" : "",
            "*Item(Product/Service)": lineItem.name || "Service",
            "ItemDescription": lineItem.description || "",
            "*ItemQuantity": lineItem.quantity,
            "*ItemRate": lineItem.cost,
            "*ItemAmount": (lineItem.quantity * lineItem.cost).toFixed(2),
        });
    });

    return lineItems;
};

const createQuickBooksServiceItems = (item: JobInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function): any[] => {
    const serviceItems: any[] = [];

    item.details?.selected_services?.forEach((service: Service) => {
        if (!service?.fields || Object.keys(service?.fields).length === 0) return;

        const serviceData: any = {
            "*InvoiceNo": Utils.generateInvoiceNumber(item._id as string, item.createdAt as string, item.job_count as number),
            "*Customer": getClientName(item.client_id),
            "*InvoiceDate": new Date(item.pending_time as number || item.createdAt as string).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*DueDate": new Date(getDueDate()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "Terms": "",
            "Location": "",
            "Memo": "",
            "*Item(Product/Service)": service.name,
            "ItemDescription": "",
            "*ItemQuantity": 1,
            "*ItemRate": 0,
            "*ItemAmount": 0,
        };

        Object.keys(service?.fields)?.forEach((field: string) => {
            if (service.invoiced[field]) {
                if (service.types[field] == "cost") {
                    serviceData["*ItemRate"] = service.values[field];
                    serviceData["*ItemAmount"] = service.values[field];
                } else if (service.types[field] == "quantity") {
                    serviceData["*ItemQuantity"] = service.values[field];
                } else {
                    serviceData["ItemDescription"] += `${field}: ${service.values[field]}, `;
                }
            }
        });

        serviceData["ItemDescription"] = serviceData["ItemDescription"].slice(0, -2); // Remove trailing comma and space
        serviceItems.push(serviceData);
    });

    return serviceItems;
};


export const transformHoldingDataToQuickBooksCSV = (
    sortedData: HoldingInvoiceRow[],
    getClientName: (clientId: string) => string,
    getClientAccountCode: (clientId: string) => string,
    getDueDate: () => Date,
    getTaxType: () => string,
    exportLineItemsRef: React.MutableRefObject<boolean>,
    exportBillBackItemsRef: React.MutableRefObject<boolean>,
    exportCustomerCostItemsRef: React.MutableRefObject<boolean>,
    exportServicesRef: React.MutableRefObject<boolean>
): any[] => {
    let csvData: any[] = [];

    sortedData?.forEach((item: HoldingInvoiceRow) => {
        if (item.isSubRow) return;

        const invoiceData = createHoldingInvoiceDataForQuickBooks(item, getClientName, getClientAccountCode, getDueDate, getTaxType);
        csvData.push(invoiceData);

        if (exportLineItemsRef.current) {
            const lineItems = createHoldingLineItemsForQuickBooks(
                item,
                getClientName,
                getClientAccountCode,
                getDueDate,
                getTaxType,
                exportBillBackItemsRef,
                exportCustomerCostItemsRef
            );
            csvData.push(...lineItems);
        }

        if (exportServicesRef.current && item.details?.selected_services) {
            const serviceItems = createHoldingServiceItemsForQuickBooks(item, getClientName, getClientAccountCode, getDueDate, getTaxType);
            csvData.push(...serviceItems);
        }
    });

    return csvData;
};

const createHoldingInvoiceDataForQuickBooks = (item: HoldingInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function): any => {
    const invoiceDate = new Date(item.released_time || Date.now()).toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    const dueDate = new Date(getDueDate()).toLocaleDateString("en-AU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
    const clientName = getClientName(item.client_id);
    const description = createHoldingDescription(item, getClientName as any);

    return {
        "*InvoiceNo": item.name,
        "*Customer": clientName,
        "*InvoiceDate": invoiceDate,
        "*DueDate": dueDate,
        "Terms": "",
        "Location": "",
        "Memo": description,
        "*Item(Product/Service)": "Holding Service",
        "ItemDescription": description,
        "*ItemQuantity": 1,
        "*ItemRate": item.details?.client_rate?.cost || 0,
        "*ItemAmount": item.details?.client_rate?.cost || 0,
    };
};

const createHoldingLineItemsForQuickBooks = (item: HoldingInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function, exportBillBackItemsRef: React.MutableRefObject<boolean>, exportCustomerCostItemsRef: React.MutableRefObject<boolean>): any[] => {
    const lineItems: any[] = [];
    let items: LineItem[] = [];

    if (exportBillBackItemsRef.current) {
        items = items.concat(item.getTotalBillBackItems());
    }
    if (exportCustomerCostItemsRef.current) {
        items = items.concat(item.getTotalCustomerCostItems());
    }

    items.forEach((lineItem) => {
        if (isNaN(lineItem.quantity)) {
            lineItem.quantity = 1;
        }
        if (isNaN(lineItem.cost)) {
            lineItem.cost = 0;
        }
        lineItems.push({
            "*InvoiceNo": item.name,
            "*Customer": getClientName(item.client_id),
            "*InvoiceDate": new Date(item.released_time || Date.now()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*DueDate": new Date(getDueDate()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "Terms": "",
            "Location": "",
            "Memo": "",
            "*Item(Product/Service)": lineItem.name || "Additional Service",
            "ItemDescription": `${lineItem.billAllBack ? 'Bill All Back' : lineItem.customerCost ? 'Customer Cost' : ''} - ${lineItem.description || ""}`,
            "*ItemQuantity": lineItem.quantity,
            "*ItemRate": lineItem.cost,
            "*ItemAmount": (lineItem.quantity * lineItem.cost).toFixed(2),
        });
    });

    return lineItems;
};

const createHoldingServiceItemsForQuickBooks = (item: HoldingInvoiceRow, getClientName: Function, getClientAccountCode: Function, getDueDate: Function, getTaxType: Function): any[] => {
    const serviceItems: any[] = [];

    item.details?.selected_services?.forEach((service: Service) => {
        if (!service?.fields || Object.keys(service?.fields).length === 0) return;

        const serviceData: any = {
            "*InvoiceNo": item.name,
            "*Customer": getClientName(item.client_id),
            "*InvoiceDate": new Date(item.released_time || Date.now()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "*DueDate": new Date(getDueDate()).toLocaleDateString("en-AU", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            }),
            "Terms": "",
            "Location": "",
            "Memo": "",
            "*Item(Product/Service)": service.name,
            "ItemDescription": "",
            "*ItemQuantity": 1,
            "*ItemRate": 0,
            "*ItemAmount": 0,
        };

        Object.keys(service?.fields)?.forEach((field: string) => {
            if (service.invoiced[field]) {
                if (service.types[field] == "cost") {
                    serviceData["*ItemRate"] = service.values[field];
                    serviceData["*ItemAmount"] = service.values[field];
                } else if (service.types[field] == "quantity") {
                    serviceData["*ItemQuantity"] = service.values[field];
                } else {
                    serviceData["ItemDescription"] += `${field}: ${service.values[field]}, `;
                }
            }
        });

        serviceData["ItemDescription"] = serviceData["ItemDescription"].slice(0, -2); // Remove trailing comma and space
        serviceItems.push(serviceData);
    });

    return serviceItems;
};