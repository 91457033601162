import React, { useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    View,
    //@ts-ignore
} from "react-native";
import {
    Card,
    Icon,
    Button,
    Text,
    List,
    ListItem
} from "@ui-kitten/components";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import * as StorageController from '../../../functions/storageController';
import * as GoogleController from '../../../functions/google.controller';
import { JobLocation, JobAddress } from '../../../models/Job.model';



const mapLibs = ["places", "visualization"]


interface NewJobMapSelectLocationProps {
    lat_lng: JobLocation;
    onSetLocation: (location: JobLocation) => void;
    onSetAddress: (address: JobAddress) => void;
    onGetFormattedAddress: (address: string) => void;
    allowEdit: boolean;
    style?: any;
}

export const JobDetailMap = ({
    lat_lng,
    onSetLocation,
    onSetAddress,
    onGetFormattedAddress,
    allowEdit,
    style
}: NewJobMapSelectLocationProps) => {


    const mapRef = useRef<GoogleMap>(null);
    const companyLocation = StorageController.getCurrentCompany().settings?.location?.coords || { lat: 0, lng: 0, lon: 0 };
    const [center, setCenter] = useState(companyLocation);
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [isSelectingPoint, setIsSelectingPoint] = useState(false);
    const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 });
    const [showMarker, setShowMarker] = useState(false);
    const [formattedAddress, setFormattedAddress] = useState('');
    const [showLocationSelectInfoWindow, setShowLocationSelectInfoWindow] = useState(false);
    const [InfoWindowListSelectedIndex, setInfoWindowListSelectedIndex] = useState(0);
    const [locationSearchResults, setLocationSearchResults] = useState<google.maps.GeocoderResult[]>([]);






    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs  // Required for heatmap
    })

    useEffect(() => {
        if (lat_lng && lat_lng.lat && lat_lng.lng) {
            let position = { lat: lat_lng.lat, lng: lat_lng.hasOwnProperty('lon') ? lat_lng.lon : lat_lng.lng };
            setCenter(new JobLocation(position));
            setMarkerPosition(position);
            setShowMarker(true);
        }
    }, [lat_lng]);


    const onClickLocation = async (event: google.maps.MapMouseEvent) => {
        if (!allowEdit) {
            return
        }
        if (!event.latLng) {
            return
        }
        try {
            setMarkerPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() })
            onSetLocation(new JobLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() }))
            const address = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${event.latLng.lat()},${event.latLng.lng()}&key=${GoogleController.apiKey}`)
            const addressJson = await address.json()
            setFormattedAddress(addressJson.results[0]?.formatted_address || "")
            const addressComponents = addressJson.results[0]?.address_components
            let addressObject = await GoogleController.sortAddress(addressComponents)
            addressObject.description = addressJson.results[0]?.name
            onSetAddress(addressObject)
            onGetFormattedAddress(addressJson.results[0].formatted_address)
        } catch (e) {
            console.log(e)
        }
    }


    const onDragMarker = async (event: google.maps.MapMouseEvent) => {
        const location = { lat: event.latLng!.lat(), lng: event.latLng!.lng() };
        const locations = await GoogleController.getAddressFromLatLngAllResults(location.lat, location.lng);
        setLocationSearchResults(locations);
        setShowLocationSelectInfoWindow(true);
    };

    const RenderSelectPlaceResultsSideBar = ({ locations, style }: { locations: google.maps.GeocoderResult[], style: any }) => {
        return (
            <View
                style={style}
            >
                <Card
                    status="info"
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Icon name='pin-outline' width={24} height={24} fill="white" />
                            <Text style={{ alignSelf: 'center' }}>Select a place</Text>
                            <Button
                                size="tiny"
                                appearance="filled"
                                status="danger"
                                onPress={() => {
                                    setInfoWindowListSelectedIndex(0)
                                    setShowLocationSelectInfoWindow(false)
                                }}>
                                <Icon
                                    name='close-outline'
                                    width={24}
                                    height={24}
                                    fill="white"
                                />
                            </Button>
                        </View>
                    }
                    disabled={true}
                    style={{ flex: 1 }}
                >
                    <View style={{ margin: -15, height: style.height }}>
                        <List
                            data={locations}
                            renderItem={({ item, index }: any) => {
                                const description = `${item.geometry.location_type} - ${item.types.join(", ")}`
                                return (
                                    <ListItem
                                        style={{ backgroundColor: InfoWindowListSelectedIndex == index ? "#222" : "transparent" }}
                                        title={item.formatted_address}
                                        description={description}
                                        // accessoryLeft={() => <Icon name='pin-outline' width={24} height={24} fill="white" />}
                                        onPress={() => {
                                            setInfoWindowListSelectedIndex(index)
                                            const location = item.geometry.location
                                            onSetLocation(location)
                                            setMarkerPosition(location)
                                            let address = GoogleController.sortAddress(item.address_components)
                                            address.formatted_address = item.formatted_address
                                            address.description = description
                                            setFormattedAddress(address.formatted_address || "")
                                            onSetAddress(address)
                                            onGetFormattedAddress(item.formatted_address)
                                        }}
                                    />
                                )
                            }}
                        />
                    </View>
                </Card>
            </View>
        )
    }


    const mapPanelWidth = 200
    const mapHeight = 400

    const mapContainerStyle = {
        width: "100%",
        height: `${mapHeight}px`
    }


    if (!isLoaded) {
        return null
    }

    return (
        <View style={style}>
            <View style={{ flexDirection: 'row' }}>
                {showLocationSelectInfoWindow ?
                    <RenderSelectPlaceResultsSideBar
                        locations={locationSearchResults}
                        style={{
                            width: mapPanelWidth,
                            height: mapHeight,
                        }}
                    />
                    :
                    <></>
                }
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={10}
                    onClick={onClickLocation}
                    // map options to disable place selection
                    options={{
                        disableDefaultUI: true,
                        zoomControl: true,
                        fullscreenControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        scrollwheel: true,
                        clickableIcons: false,
                    }}
                >
                    {
                        showMarker &&
                        <Marker
                            position={markerPosition}
                            draggable={allowEdit}
                            onDragEnd={(event) => {
                                onDragMarker(event)
                            }}
                        />
                    }
                </GoogleMap>
            </View>
        </View>
    )

}


export default JobDetailMap;