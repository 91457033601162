import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Autocomplete,
    useTheme,
    AutocompleteItem
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as UpdateController from '../../functions/update.controller';
import DriversListItem from "./DriversListItem.component";
import { Member } from '../../models/Member.model';
import { Job, JOB_STATUS } from '../../models/Job.model';
import { useAppStateContext } from '../../contexts/AppStateContext';
interface DriversListProps {
    var_drivers: any;
    fn_selectDriver: (driver: any, jobs: any) => void;
    fn_selectJob: (job: any) => void;
    fn_zoomToMarker: (job: any) => void;
    var_clients: any;
    fn_setDirections: (driver: Member, job: Job) => void;
    var_viewedBy: string;
}


const DriversList = ({
    var_drivers,
    fn_selectDriver,
    fn_selectJob,
    fn_zoomToMarker,
    var_clients,
    fn_setDirections,
    var_viewedBy
}: DriversListProps) => {
    const [drivers, setDrivers] = useState<Member[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredDrivers, setFilteredDrivers] = useState<Member[]>([]);
    const [activeFilter, setActiveFilter] = useState('all');
    const theme = useTheme();
    const { currentJobs, getCurrentJobs } = useAppStateContext();
    useEffect(() => {
        // order drivers by online status
        let _drivers = var_drivers.sort((a: Member, b: Member) => (a.online < b.online) ? 1 : -1)
        // now sort by last updated at

        _drivers = _drivers.sort((a: Member, b: Member) => (new Date(a.updatedAt) < new Date(b.updatedAt)) ? 1 : -1)
        // remove deleted and locked drivers
        _drivers = _drivers.filter((driver: Member) => {
            return !driver.deleted && !driver.locked
        })
        setDrivers(_drivers)
        setFilteredDrivers(_drivers); // Initialize filtered drivers
    }, [var_drivers])

    const filterDrivers = useCallback((filter: string, driversList: Member[]) => {
        try {

            let filtered = [...driversList];

            switch (filter) {
                case 'occupied':
                    filtered = driversList.filter((driver: Member) =>
                        getCurrentJobs()?.some((job: Job) => job.member_id === driver._id)
                    );
                    break;
                case 'available':
                    filtered = driversList.filter((driver: Member) =>
                        driver.online && !getCurrentJobs()?.some((job: Job) => job.member_id === driver._id)
                    );
                    break;
                case 'recent':
                    filtered = [...driversList].sort((a: Member, b: Member) => {
                        const aJobs = getCurrentJobs()?.filter((job: Job) => job.member_id === a._id) || [];
                        const bJobs = getCurrentJobs()?.filter((job: Job) => job.member_id === b._id) || [];

                        const aLatestJob = aJobs.length > 0
                            ? Math.max(...aJobs.map(job => new Date(job.updatedAt || 0).getTime()))
                            : 0;
                        const bLatestJob = bJobs.length > 0
                            ? Math.max(...bJobs.map(job => new Date(job.updatedAt || 0).getTime()))
                            : 0;


                        return bLatestJob - aLatestJob;
                    });
                    break;
                default: // 'all'
                    break;
            }
            return filtered;
        } catch (error) {
            console.error(error);
            return driversList;
        }
    },
        [getCurrentJobs]
    );


    useEffect(() => {
        let filtered = drivers as Member[];

        // Apply active filter
        filtered = filterDrivers(activeFilter, filtered);

        // Apply search query
        if (searchQuery) {
            filtered = filtered.filter((driver: Member) =>
                driver.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        setFilteredDrivers(filtered);
    }, [searchQuery, drivers, activeFilter, filterDrivers]);

    const onSelect = (index: number) => {
        setSearchQuery(filteredDrivers[index].name);
    };

    const onChangeText = (query: string) => {
        setSearchQuery(query);
    };

    const renderOption = (item: Member, index: number) => (
        <AutocompleteItem
            accessoryLeft={(props) => <Icon {...props} name="person-outline" fill={item.online ? 'green' : 'red'} />}
            style={{ borderBottomWidth: 1, borderBottomColor: 'lightgray', backgroundColor: theme['color-basic-1000'] }}
            key={index}
            title={item.name}
        />
    );

    const renderItem = useCallback(({ item, index }: { item: Member, index: number }) => {
        const ignoreStatuses = [JOB_STATUS.COMPLETE, JOB_STATUS.CANCELLED, JOB_STATUS.TRANSFERRED_OUT]
        const jobs = getCurrentJobs()?.filter((job: Job) => job.member_id == item._id && !ignoreStatuses.includes(job.status))
        return (
            <DriversListItem



                driver={item}
                jobs={jobs}
                index={index}
                fn_selectDriver={fn_selectDriver}
                fn_selectJob={fn_selectJob}
                fn_zoomToMarker={fn_zoomToMarker}
                fn_setDirections={fn_setDirections}
                var_viewedBy={var_viewedBy}
            ></DriversListItem>
        )
    }, [fn_selectDriver, fn_selectJob, fn_zoomToMarker, fn_setDirections, var_viewedBy, currentJobs])

    return (
        <Layout style={{ flex: 1, width: '100%', height: '100%' }}>
            <View style={styles.filterContainer}>
                <Button
                    size='small'
                    status={activeFilter === 'all' ? 'primary' : 'basic'}
                    onPress={() => setActiveFilter('all')}
                    style={styles.filterButton}
                    appearance={activeFilter === 'all' ? 'filled' : 'outline'}
                    accessoryLeft={(props) => (
                        <Icon {...props} name={activeFilter === 'all' ? 'grid' : 'grid-outline'} />
                    )}
                >
                    All
                </Button>
                <Button
                    size='small'
                    status={activeFilter === 'occupied' ? 'primary' : 'basic'}
                    onPress={() => setActiveFilter('occupied')}
                    style={styles.filterButton}
                    appearance={activeFilter === 'occupied' ? 'filled' : 'outline'}
                    accessoryLeft={(props) => (
                        <Icon {...props} name={activeFilter === 'occupied' ? 'car' : 'car-outline'} />
                    )}

                >
                    Occupied
                </Button>
                <Button
                    size='small'
                    status={activeFilter === 'available' ? 'primary' : 'basic'}
                    onPress={() => setActiveFilter('available')}
                    style={styles.filterButton}
                    appearance={activeFilter === 'available' ? 'filled' : 'outline'}
                    accessoryLeft={(props) => (
                        <Icon {...props} name={activeFilter === 'available' ? 'checkmark-circle-2' : 'checkmark-circle-2-outline'} />
                    )}

                >
                    Available
                </Button>
                <Button
                    size='small'
                    status={activeFilter === 'recent' ? 'primary' : 'basic'}
                    onPress={() => setActiveFilter('recent')}
                    style={styles.filterButton}
                    appearance={activeFilter === 'recent' ? 'filled' : 'outline'}
                    accessoryLeft={(props) => (
                        <Icon {...props} name={activeFilter === 'recent' ? 'clock' : 'clock-outline'} />
                    )}
                >
                    Recent

                </Button>
            </View>

            <Autocomplete
                size='small'
                placeholder="Search drivers..."
                value={searchQuery}
                onChangeText={onChangeText}
                onSelect={onSelect}
                style={{ width: '100%', padding: 2 }}
                accessoryLeft={(props) => <Icon {...props} name="search-outline" />}
                accessoryRight={(props) => <Button {...props} appearance='ghost' size='tiny' status='warning' onPress={() => setSearchQuery('')} accessoryLeft={(props) => <Icon {...props} name="close-outline" />} />}
            >
                {filteredDrivers.map(renderOption)}
            </Autocomplete>
            <List

                style={{ width: '100%' }}
                data={filteredDrivers}
                renderItem={renderItem}
            />
        </Layout>
    );
}

const styles = StyleSheet.create({
    filterContainer: {
        flexDirection: 'row',
        padding: 2,
        gap: 4,
    },
    filterButton: {
        flex: 1,
    },
});


export default DriversList;
