import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Input, Text, Layout } from '@ui-kitten/components';
import { InventoryLocation } from '../../models/Inventory.model';
import { JobAddress, JobLocation } from '../../models/Job.model';
import * as StorageController from '../../functions/storageController';
import * as InventoryController from '../../functions/Inventory.controller';
import { AddressDetailsCard } from '../common/address/AddressComponents';
import { CompanySavedAddress } from '../../models/Company.model';
import Toast from 'react-native-toast-message';
import DMModal from '../common/Modal';

interface AddLocationModalProps {
    visible: boolean;
    onClose: () => void;
    onSuccess: () => void;
    locationToEdit?: InventoryLocation | null;
}

const AddLocationModal: React.FC<AddLocationModalProps> = ({
    visible,
    onClose,
    onSuccess,
    locationToEdit
}) => {
    const [locationForm, setLocationForm] = useState({
        name: '',
        description: '',
        address: new JobAddress(),
        location: new JobLocation(),
        is_active: true
    });

    useEffect(() => {
        if (locationToEdit) {
            setLocationForm({
                name: locationToEdit.name || '',
                description: locationToEdit.description || '',
                address: new JobAddress(locationToEdit.address || {}),
                location: new JobLocation(locationToEdit.location || {}),
                is_active: true
            });
        } else {
            resetForm();
        }
    }, [locationToEdit]);

    const resetForm = () => {
        setLocationForm({
            name: '',
            description: '',
            address: new JobAddress(),
            location: new JobLocation(),
            is_active: true
        });
    };

    const handleSubmit = async () => {
        const company = StorageController.getCurrentCompany();

        if (locationToEdit?._id) {
            // Handle Edit
            const updatedLocation = new InventoryLocation({
                ...locationToEdit,
                name: locationForm.name,
                description: locationForm.description,
                address: locationForm.address,
                location: locationForm.location
            });

            const result = await InventoryController.updateInventoryLocation(locationToEdit._id, updatedLocation);
            if (result) {
                Toast.show({
                    type: 'success',
                    text1: 'Location updated successfully'
                });
                onSuccess();
                onClose();
                resetForm();
            } else {
                Toast.show({
                    type: 'error',
                    text1: 'Failed to update location'
                });
            }
        } else {
            // Handle Add
            const newLocation = new InventoryLocation({
                name: locationForm.name,
                description: locationForm.description,
                address: locationForm.address,
                location: locationForm.location,
                company_id: company._id
            });

            const result = await InventoryController.createInventoryLocation(newLocation);
            if (result) {
                Toast.show({
                    type: 'success',
                    text1: 'Location added successfully'
                });
                onSuccess();
                onClose();
                resetForm();
            } else {
                Toast.show({
                    type: 'error',
                    text1: 'Failed to add location'
                });
            }
        }
    };

    const renderLocationForm = () => {
        return (
            <View style={{ flexDirection: 'column' }}>
                <Input
                    label="Location Name"
                    placeholder="Enter location name"
                    value={locationForm.name}
                    onChangeText={(text) => setLocationForm({ ...locationForm, name: text })}
                    style={styles.input}
                />
                <Input
                    label="Description"
                    placeholder="Enter description"
                    value={locationForm.description}
                    onChangeText={(text) => setLocationForm({ ...locationForm, description: text })}
                    style={styles.input}
                    multiline
                />
                <AddressDetailsCard
                    key={locationForm.address.formatted_address}
                    address={locationForm.address}
                    addressDisplayValue={locationForm.address.formatted_address || ''}
                    onSetAddress={(address: JobAddress) => {
                        setLocationForm(prev => {
                            return { ...prev, address };
                        });
                    }}
                    onSetLocation={(location: JobLocation) => {
                        setLocationForm(prev => ({ ...prev, location: new JobLocation(location) }));
                    }}
                    onSetAddressLocation={(address, location) => {
                        setLocationForm(prev => ({
                            ...prev,
                            address: new JobAddress(address),
                            location: new JobLocation(location)
                        }));
                    }}
                    onSetSavedAddress={(savedAddress: CompanySavedAddress) => {
                        setLocationForm(prev => ({
                            ...prev,
                            address: new JobAddress(savedAddress.address),
                            location: new JobLocation(savedAddress.location)
                        }));
                    }}
                    style={{ marginVertical: 10 }}
                    addressName="Location Address"
                />
            </View>
        );
    };

    return (
        <DMModal visible={visible} onClose={onClose}>
            <Layout style={styles.modalContainer}>
                <Text category="h6" style={styles.modalHeader}>
                    {locationToEdit ? 'Edit Location' : 'Add New Location'}
                </Text>
                {renderLocationForm()}
                <View style={styles.modalButtons}>
                    <Button status="primary" onPress={handleSubmit}>
                        {locationToEdit ? 'Save Changes' : 'Add Location'}
                    </Button>
                    <Button
                        appearance="ghost"
                        status="basic"
                        onPress={() => {
                            onClose();
                            resetForm();
                        }}
                    >
                        Cancel
                    </Button>
                </View>
            </Layout>
        </DMModal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        padding: 20,
        borderRadius: 8,
        minWidth: 400,
        maxWidth: '80%',
    },
    modalHeader: {
        marginBottom: 16,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
    },
    input: {
        marginBottom: 8,
    },
});

export default AddLocationModal; 