import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { Input, Button, Text, Modal, Select, IndexPath, SelectItem, Layout } from '@ui-kitten/components';
import { Client } from '../../models/Client.model';
import { getClientsByCompanyId } from '../../functions/client.controller';
import { CustomPricing } from '../../models/Inventory.model';

interface EditCustomPricingModalProps {
    visible: boolean;
    onClose: () => void;
    onUpdatePricing: (pricing: CustomPricing) => void;
    pricing: CustomPricing | null;
    clientName: string;
}

const EditCustomPricingModal = ({ visible, onClose, onUpdatePricing, pricing, clientName }: EditCustomPricingModalProps) => {

    const [price, setPrice] = useState<number | null>(null);
    const [selectedClientIndex, setSelectedClientIndex] = useState<IndexPath | IndexPath[] | undefined>(undefined);

    useEffect(() => {
        if (pricing) {
            setPrice(pricing.price);
            // Assuming you have a way to set the selected client based on pricing.client_id
        }
    }, [pricing]);


    const handleUpdate = useCallback(() => {
        if (price !== null && pricing) {
            onUpdatePricing({
                ...pricing,
                price: price,
            });
        }
    }, [price, pricing, onUpdatePricing]);


    return (
        <Modal visible={visible} onBackdropPress={onClose} backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <Layout style={styles.container}>
                <Text category='h6'>Edit Custom Pricing</Text>
                <Text category='p1'>{clientName}</Text>
                <Input
                    placeholder="Price"
                    keyboardType="numeric"
                    value={price !== null ? price.toString() : ''}
                    onChangeText={(text) => setPrice(Number(text))}
                />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button onPress={handleUpdate}>Update Pricing</Button>
                    <Button  status='danger' appearance='ghost' onPress={onClose}>Cancel</Button>
                </View>
            </Layout>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        padding: 20,
        gap:30
    },
});

export default EditCustomPricingModal;