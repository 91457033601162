var config = require('../config/config.js')
const axios = require('axios');
import Toast from 'react-native-toast-message';
import * as StorageController from './storageController'
import { Job, JOB_TYPE } from '../models/Job.model';
import { Company } from '../models/Company.model';

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}


axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}


//Assign Job
export async function assignJob(job_id: string, member_id: string, company_id: string): Promise<Job> {
    try {
        // const response = await axios.put(getApi() + `/job/${job_id}/assign/${member_id}`, {company_id: company_id, member_id: member_id});
        const response = await axios.put(getApi() + `/job/status/assign/${job_id}/${member_id}`, { company_id: company_id, member_id: member_id });
        // console.log(response);
        // console.log("🚀============== ~ file: job.controller.ts:38 ~ assignJob ~ response🚀==============", response)
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        throw new Error(`HTTP error! status: ${error}`);
    }
}


// assign multiple jobs to a member
export async function assignMultipleJobsToMember(job_ids: string[], member_id: string, company_id: string): Promise<Job[]> {
    try {
        // router.put("/job/status/assign/multiple", JobController.assignMemberToMultipleJobs);
        const result = await axios.put(getApi() + `/job/status/assign_multiple`, { job_ids: job_ids, member_id: member_id, company_id: company_id });
        console.log("🚀============== ~ file: job.controller.ts:61 ~ assignMultipleJobsToMember ~ result🚀==============", result)
        if (result.status == 200) {
            return result.data?.updated_jobs?.map((j: any) => new Job(j))
        } else {
            throw new Error(`HTTP error! status: ${result.status}`);
        }
    } catch (error) {
        console.error(error);
        return [] as Job[]
    }
}


// set the route_id for a job
export async function setRouteIdForJob(job_id: string, route_id: string) {
    try {
        let job = await getJobById(job_id);
        if (!job) {
            throw new Error(`Job not found`);
        }
        job.route_id = route_id;
        const response = await updateJob(job);
        return response;
    } catch (error) {
        console.error(error);
        return null;
    }
}

// set the route_id for multiple jobs
export async function setRouteIdForJobs(job_ids: string[], route_id: string) {
    try {
        const jobs = await Promise.all(job_ids.map(job_id => getJobById(job_id)));
        console.log("🚀============== ~ file: job.controller.ts:93 ~ setRouteIdForJobs ~ jobs🚀==============", jobs)
        const jobsToUpdate = jobs.filter(job => job !== null) as Job[];
        console.log("🚀============== ~ file: job.controller.ts:95 ~ setRouteIdForJobs ~ jobsToUpdate🚀==============", jobsToUpdate)
        const updatedJobs = await Promise.all(jobsToUpdate.map(job => setRouteIdForJob(job._id, route_id)));
        return updatedJobs;
    } catch (error) {
        console.error(error);
        return null;
    }
}



export async function reassignJob(job_id: string, member_id: string, company_id: string) {
    try {
        const response = await axios.put(getApi() + `/job/status/assign/${job_id}/${member_id}`, { company_id: company_id });
        // console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//Read job by if from server
export async function getJobById(jobId: string): Promise<Job | null> {
    try {
        const response = await axios.get(`${await getApi()}/job/${jobId}`);
        return new Job(response.data);
    } catch (error) {
        console.error('Error fetching job by ID:', error);
        return null;
    }
}

//Get jobs by selected company from storageController
export async function getLocalJobsBySelectedCompany(): Promise<Job[]> {
    let jobs = [] as Job[]
    let companies = StorageController.getAppState().companies || []
    try {
        for (var c of companies) {
            // console.log(c)
            if (c._id == StorageController.getAppState().selectedCompany?._id) {
                jobs = c.jobs.map(j => new Job(j))
                // jobs = c.jobs
                break
            }
        }
        return jobs
    } catch (error) {
        console.error(error);
        return jobs
        // throw new Error(`Could not find selected company`);
    }
}


//Read jobs from server
export async function getIncompleteJobsByCompanyId(company_id: string, limit = 30) {
    try {
        const data = {
            company_id: company_id,
            limit: limit
        }
        const response = await axios.post(getApi() + `/job/company/incomplete`, data);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function getIncompleteJobsByAllCompanyMemberships(limit = 3000): Promise<Company[]> {
    const companies = StorageController.getAppState().companies || [] as Company[];
    const jobsPromises = companies.map(async (company) => {
        const jobs = await getIncompleteJobsByCompanyId(company._id, limit);

        company.jobs = jobs.map((j: any) => new Job(j));
        return company; // This will be a company with jobs populated.
    });

    // Promise.all will resolve when all promises in the array have resolved.
    const companiesWithJobs = (await Promise.allSettled(jobsPromises))
        .filter((result) => result.status === 'fulfilled')
        .map((result) => (result as PromiseFulfilledResult<Company>).value);
    return companiesWithJobs;
}


//Read jobs from server
export async function getAllJobsByCompanyId(companyId = StorageController.getAppState().selectedCompany?._id, limit = 10) {
    try {
        const response = await axios.get(getApi() + `/job/company/${companyId}/${limit}`);
        // console.log(response);
        return response.data.map((j: any) => new Job(j))
        // return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// get all jobs by all company memberships
export async function getAllJobsByAllCompanyMemberships(limit = 30) {
    let companies = StorageController.getAppState().companies || []
    for (let i = 0; i < companies.length; i++) {
        let company = companies[i]
        let jobs = await getAllJobsByCompanyId(company._id, limit)
        company.jobs = jobs.map((j: any) => new Job(j))
        // company.jobs = jobs
    }
    return companies
}

//TODO: unused?
export async function getCompanyJobsByDatesAndStatus(companyId: string, startDate: any, endDate: any, status: string, limit = 10000, offset = 0) {
    try {
        const data = {
            company_id: companyId,
            status: status,
            start_date: startDate,
            end_date: endDate,
            limit: limit,
            offset: offset
        }
        const response = await axios.post(getApi() + `/job/find/company/dates/status`, data);
        // console.log(response);
        return response.data.map((j: any) => new Job(j))
        // return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}



const shouldUpdateJobIfClient = (job: Job) => {
    try {

        // get user memberships
        const memberships = StorageController.getAppState().memberships;
        const companies = StorageController.getAppState().companies;

        if (!memberships || !companies) return false
        // find company job belongs to

        const company = companies.find(c => c._id == job.company_id || c._id == job.client_company_id);
        if (!company) return false
        // get membership for company
        const member = memberships.find(m => m.company_id == company._id);
        if (!member) return false

        // console.log("🚀 ~ file: job.controller:165 ~ shouldUpdateJobIfClient ~ job:", job)
        let perms = false
        if (member.is_admin) {
            perms = true
        }
        if (member.is_operator) {
            perms = true
        }
        if (member.is_client) {
            if (job.client_id == member.client_id) {
                perms = true
            } else {
                perms = false
            }
        }
        return perms
    } catch (err) {
        console.log("🚀 ~ file: job.controller:199 ~ shouldUpdateJobIfClient ~ err:", err)
        return false
    }
}


// on job update, if the job is not in the selected company, find the company and add the job to it, or update the job if it already exists
export async function updateJobInCompany(job: Job) {
    try {
        let companies = StorageController.getAppState().companies || []
        if (!shouldUpdateJobIfClient(job)) return companies
        let company = companies.find(c => c._id == job.company_id)
        if (company && company.jobs && company.jobs.length > 0) {
            let jobIndex = company.jobs.findIndex(j => j._id == job._id)
            if (jobIndex > -1) {
                // console.log('====== === == updating job in company = = = === ==', job)
                company.jobs[jobIndex] = job
            } else {
                // console.log("🚀============== ~ file: job.controller:226 ~ updateJobInCompany ~ ADDING NEW JOB job🚀==============", job)
                company.jobs.unshift(job)
            }
        }
        return companies
    } catch (err) {
        console.log("🚀 ~ file: job.controller:225 ~ updateJobInCompany ~ err", err)
        return StorageController.getAppState().companies || []
    }
}




// get jobs by company and status
export async function getJobsByCompanyAndStatus(companyId: string, status: string, limit = 30, offset = 0) {
    try {
        const data = {
            company_id: companyId,
            status: status,
            limit: limit,
            offset: offset
        }
        const response = await axios.post(getApi() + `/job/company/status/`, data);
        // console.log(response);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
        Toast.show({
            type: 'error',
            position: 'bottom',
            text1: 'Error',
            text2: `Could not get Jobs`,
            visibilityTime: 4000,
            autoHide: true,
            topOffset: 30,
            bottomOffset: 40,
        });
    }
}



//get images from job
export async function getImagesByJobId(jobId: string) {
    try {
        const response = await axios.get(getApi() + "/image/job/" + jobId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function uploadImages(images: any) {
    const company = StorageController.getCurrentCompany()
    const membership = StorageController.getAppState().selectedMembership
    let imageArr = []
    try {
        for (var i = 0; i < images.length; i++) {
            var image = images[i]
            let b64Data = image.uri.split(';base64,').pop();
            let imageType = image.uri.split('data:')[1].split(';')[0].split('/')[1];
            var requestObj = {
                company_id: company?._id,
                job_id: StorageController.getAppState().selectedJob?._id,
                member_id: membership?._id,
                details: "todo",
                status: "todo",
                name: "image name",
                desc: "todo",
                data: image.uri,
                contentType: `data:image/${imageType}`,
                fileType: imageType
            }
            const response = await axios.post(getApi() + "/image", requestObj);
            // console.log(response);
            imageArr.push(response.data._id)
        }
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
    return imageArr
}

function createLogObject(details: any, type: any) {
    const company = StorageController.getCurrentCompany()
    const membership = StorageController.getAppState().selectedMembership
    let logEntry = {
        company_id: company?._id,
        member_id: membership?._id,
        member_name: membership?.name,
        details: details,
        type: type,
        timestamp: new Date().toISOString()
    }
    return logEntry
}

// create job
export async function createJob(job: Job) {
    try {
        let logEntry = createLogObject("Job created", "info")
        if (!job.log) job.log = []
        job.log.push(logEntry)
        const response = await axios.post(getApi() + "/job", job);
        // console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// Update job
export async function updateJob(job: Job) {
    try {
        if (!job.log) {
            job.log = []
        }
        let logEntry = createLogObject("Job updated", "info")
        job.log.push(logEntry)
        const response = await axios.put(getApi() + "/job/" + job._id, job)
        // console.log(response)
        return new Job(response.data)
    } catch (error) {
        console.log(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function silentUpdateJob(job: Job) {
    try {
        if (!job.log) {
            job.log = []
        }
        let logEntry = createLogObject("Job updated", "info")
        let data = {
            job: job,
            log: logEntry
        }
        job.log.push(logEntry)
        const response = await axios.put(getApi() + "/job_silent/" + job._id, data)
        console.log(response)
        return new Job(response.data)
    } catch (error) {
        console.log(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


export async function deleteImageById(imageId: string) {
    try {
        const response = await axios.delete(getApi() + "/image/" + imageId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// delete job and then remove each image attached - This is currently unused
export async function deleteJob(jobId: string) {
    //get job by id
    // let job = await getJobById(jobId)
    //get images by job id
    let images = await getImagesByJobId(jobId)
    //delete job
    let response = await axios.delete(getApi() + "/job/" + jobId)
    //delete images
    for (let i = 0; i < images.length; i++) {
        // delete image from database
        await deleteImageById(images[i]._id)
    }
    return response.data
}


// submit invoices
export async function submitInvoices(job_ids: string[]) {
    const company = StorageController.getCurrentCompany()
    const membership = StorageController.getAppState().selectedMembership
    try {
        let data = {
            member_id: membership?._id,
            company_id: company._id,
            job_ids: job_ids,
            member_name: membership?.name,
        }
        const response = await axios.post(getApi() + "/job/invoice/submit", data);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// cancel job role either client or vendor
export async function cancelJob(jobId: string, role = "vendor") {
    const company = StorageController.getCurrentCompany()
    const membership = StorageController.getAppState().selectedMembership
    try {
        let data = {
            member_id: membership?._id,
            company_id: company._id,
            job_id: jobId,
            member_name: membership?.name,
        }
        const response = await axios.put(getApi() + `/job/status/${role}/cancel/` + jobId, data);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function setJobCancelledMultiple(jobs: Job[]) {
    try {
        const response = await Promise.all(jobs.map(async (job) => {
            const response = await cancelJob(job._id)
            return response
        }))
        return response
    } catch (error) {
        console.error(error);
    }
}


// set job complete
export async function setJobComplete(job: Job) {
    try {
        if (!job.log) {
            job.log = []
        }
        let logEntry = createLogObject("Job complete", "info")
        job.log.push(logEntry)
        const response = await axios.put(getApi() + "/job/status/complete/" + job._id, job);
        // console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// set multiple jobs to complete
export async function setJobsCompleteMultiple(jobs: Job[]) {
    try {
        const response = await Promise.all(jobs.map(async (job) => {
            const response = await setJobComplete(job)
            return response
        }))
        return response
    } catch (error) {
        console.error(error);
    }
}

// set job pending
export async function setJobPending(job: Job) {
    try {
        if (!job.log) {
            job.log = []
        }
        let logEntry = createLogObject("Job pending", "info")
        job.log.push(logEntry)
        const response = await axios.put(getApi() + "/job/status/pending/" + job._id, job);
        // console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// set multiple jobs to pending
export async function setJobsPendingMultiple(jobs: Job[]) {
    try {
        const response = await Promise.all(jobs.map(async (job) => {
            const response = await setJobPending(job)
            return response
        }))
        return response
    } catch (error) {
        console.error(error);
    }
}

// set job assigned back to driver
export async function setJobReassignedBack(job: Job) {
    try {
        if (!job.log) {
            job.log = []
        }
        let logEntry = createLogObject("Job assigned", "info")
        job.log.push(logEntry)
        const response = await axios.put(getApi() + "/job/status/assignback/" + job._id, job);
        // console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


//set job unassigned
export async function setJobUnassigned(job: Job) {
    try {
        if (!job.log) {
            job.log = []
        }
        let logEntry = createLogObject("Job unassigned", "info")
        job.log.push(logEntry)
        const response = await axios.put(getApi() + "/job/status/unassign/" + job._id, job);
        // console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function setJobsUnassignedMultiple(jobs: Job[]) {
    try {
        if (!jobs) {
            return []
        }
        const response = await Promise.all(jobs.map(async (job) => {
            const response = await setJobUnassigned(job)
            return response
        }))
        return response
    } catch (error) {
        console.error(error);
    }
}


// mongo find all jobs by member_id and company_id between dates
export async function getJobsByMemberIdAndCompanyIdBetweenDates(member_id: string, company_id: string, startDate: any, endDate: any) {
    try {
        const response = await axios.get(getApi() + "/job/member/" + member_id + "/company/" + company_id + "/start/" + startDate + "/end/" + endDate);
        // console.log(response);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function getIncompleteJobsByMemberIdAndCompanyId(member_id: string, company_id: string) {
    try {
        const data = {
            member_id: member_id,
            company_id: company_id
        }

        const response = await axios.post(getApi() + "/job/company/incomplete/member", data);
        // console.log(response);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function getFinalisedJobsByCompanyIdBetweenDates(company_id = StorageController.getCurrentCompany()._id, startDate: any, endDate: any): Promise<Job[]> {
    try {
        const body = {
            company_id: company_id,
            start: startDate,
            end: endDate,
            status: "all"
        }
        const response = await axios.post(getApi() + "/job/find/dates", body);
        // console.log(response, body);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        throw new Error(`HTTP error! status: ${error}`);
    }
}

export async function getJobsByCompanyIdAndStatusBetweenDates(company_id = StorageController.getCurrentCompany()._id, startDate: any, endDate: any, statusArray: any): Promise<Job[]> {
    try {
        const body = {
            company_id: company_id,
            start: startDate,
            end: endDate,
            status_array: statusArray || ["complete", "cancelled", "transferred_out"]
        }
        const response = await axios.post(getApi() + "/job/find/dates", body);
        // console.log(response, body);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        throw new Error(`HTTP error! status: ${error}`);
    }
}

// search jobs by name, make, model and rego
export async function searchJobs(term: string, limit = 30) {
    let data = {
        term: term,
        company_id: StorageController.getCurrentCompany()._id,
        limit: limit
    }
    try {
        const response = await axios.post(getApi() + "/job/search", data);
        if (Array.isArray(response.data)) {
            return response.data.map((j: any) => new Job(j));
        } else {
            return [];
        }
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//resend job created email
export async function resendJobCreatedEmail(jobId: string) {
    try {
        const response = await axios.get(getApi() + "/job/email/new/" + jobId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// resend completed job email
export async function resendJobPendingEmail(jobId: string) {
    try {
        const response = await axios.get(getApi() + "/job/email/pending/" + jobId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// get job logs by job id
export async function getJobLogsByJobId(jobId: string) {
    try {
        const response = await axios.get(getApi() + "/jobLog/job/" + jobId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// vendor accept job
export async function vendorAcceptJob(jobId: string, vendor_eta: any) {
    try {
        let body = {
            vendor_eta: vendor_eta
        }
        const response = await axios.put(getApi() + "/job/status/vendor/accept/" + jobId, body);
        // console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// vendor decline job
export async function vendorDeclineJob(jobId: string) {
    try {
        const response = await axios.put(getApi() + "/job/status/vendor/decline/" + jobId);
        // console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


//=====================================
// CLIENT COMPANY FUNCTIONS
//=====================================
// routes on api
// router.get("/job/client_company/:client_company_id", JobController.findAllByClientCompany);
// router.get("/job/client_company/:client_company_id/:limit", JobController.findAllByClientCompany);
// router.get("/job/client_company/:client_company_id/:limit/:offset", JobController.findAllByClientCompany);
// router.post("/job/client_company/incomplete", JobController.findAllIncompleteByClientCompany);
// router.post("/job/find/client_company", JobController.findAllByClientCompanyAndSearchTerm);
// router.post("/job/find/client_company/dates", JobController.findAllByClientCompanyBetweenDates);
// router.post("/job/client_company/status", JobController.findAllJobsByStatusAndClientCompanyId);


// get all jobs by client company id
export async function getAllJobsByClientCompanyId(client_company_id: string, limit = 30, offset = 0) {
    try {
        const response = await axios.get(getApi() + "/job/client_company/" + client_company_id + "/" + limit + "/" + offset);
        //        console.log(response);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${error}`);
    }
}

// get all incomplete jobs by client company id
export async function getIncompleteJobsByClientCompanyId(client_company_id: string) {
    try {
        const response = await axios.post(getApi() + "/job/client_company/incomplete", { client_company_id: client_company_id });
        //console.log(response);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${error}`);
    }
}

// get all jobs by client company id and search term
export async function getAllJobsByClientCompanyIdAndSearchTerm(client_company_id: string, term: string) {
    try {
        const response = await axios.post(getApi() + "/job/find/client_company", { client_company_id: client_company_id, term: term });
        //console.log(response);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${error}`);
    }
}

// get all jobs by client company id between dates
export async function getAllJobsByClientCompanyIdBetweenDates(client_company_id: string, start: any, end: any) {
    try {
        const response = await axios.post(getApi() + "/job/find/client_company/dates", { client_company_id: client_company_id, start: start, end: end });
        //console.log(response);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${error}`);
    }
}

// get all jobs by client company id between dates
export async function getAllJobsByStatusAndClientCompanyId(client_company_id: string, status: string) {
    try {
        const response = await axios.post(getApi() + "/job/client_company/status", { client_company_id: client_company_id, status: status });
        //console.log(response);
        return response.data.map((j: any) => new Job(j))
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${error}`);
    }
}

// Get all pdfs for a job
export async function getPdfsByJobId(jobId: string) {
    try {
        const response = await axios.get(getApi() + "/job/pdf/" + jobId);
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// Get signed url for pdf by key
export async function getSignedUrlForPdf(key: string) {
    try {
        const response = await axios.post(getApi() + "/job/pdf/signed_url", { key: key });
        // console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


interface IEmailSendResponse {
    success: boolean;
    message: string;
}

/**
 * Send complete email to custom email address
 * @param jobId 
 * @param email 
 * @returns IEmailSendResponse
 */
export async function sendCompleteEmailToCustomEmailAddress(jobId: string, email: string): Promise<IEmailSendResponse> {
    try {
        const response = await axios.post(getApi() + "/job/email/custom/complete", { job_id: jobId, email: email });
        // console.log(response);
        return { success: response.data.success, message: response.data.message }
    } catch (error: any) {
        console.error(error);
        return { success: false, message: error.message }
    }
}


/**
 * Send new job email to custom email address
 * @param jobId 
 * @param email 
 * @returns IEmailSendResponse
 */
export async function sendNewJobEmailToCustomEmailAddress(jobId: string, email: string): Promise<IEmailSendResponse> {
    try {
        const response = await axios.post(getApi() + "/job/email/custom/new", { job_id: jobId, email: email });
        return { success: response.data.success, message: response.data.message }
    } catch (error: any) {
        console.error(error);
        return { success: false, message: error.message }
    }
}

interface ISendCustomerInvoiceRequest {
    job_id: string;
    customer_email: string;
}

/**
 * Send complete email to customer
 * @param jobId 
 * @returns IEmailSendResponse
 */
export async function sendCompleteInvoiceEmailToCustomer(jobId: string, customerEmail: string): Promise<IEmailSendResponse> {
    try {
        // /invoice/email/customer
        const request: ISendCustomerInvoiceRequest = {
            job_id: jobId,
            customer_email: customerEmail
        }
        const response = await axios.post(getApi() + "/invoice/email/customer", request);
        return { success: response.data.success, message: response.data.message }
    } catch (error: any) {
        console.error(error);
        return { success: false, message: error.message }
    }
}

/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// TRANSFER TO ANOTHER COMPANY
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

/**
 * Transfer job to another company
 * @param job_id
 * @param from_company_id
 * @param to_company_id
 */
export async function transferJob(job_id: string, from_company_id: string, to_company_id: string) {
    try {
        const response = await axios.put(getApi() + `/job/transfer/${job_id}/${from_company_id}/${to_company_id}`);
        console.log(response);
        return new Job(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}



/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region OPEN AI
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
interface ProcessJobTextRequest {
    jobText: string;
    jobType: JOB_TYPE; // (roadside, towing)
}

interface ProcessJobTextResponse {
    success: boolean;
    job?: Job;
    error?: string;
}

export async function getOpenAiProcessJobText(request: ProcessJobTextRequest): Promise<ProcessJobTextResponse> {
    try {
        const response = await axios.post(getApi() + "/openai/process_job_text", request);
        return { success: response.data.success, job: new Job(response.data.job), error: response.data.error }
    } catch (error: any) {
        console.error(error);
        return { success: false, error: error.message }
    }
}
