import React from 'react';
import { StyleSheet, View, Linking } from 'react-native';
import { Card, Text, Button, Layout, Icon, Divider } from '@ui-kitten/components';
import { CheckStripeConnectStatusResponse } from '../../functions/PartsNetwork.controller';

interface StripeConnectStatusProps {
    status: CheckStripeConnectStatusResponse;
}

export const StripeConnectStatus: React.FC<StripeConnectStatusProps> = ({ status }) => {
    const CheckIcon = (props: any) => <Icon {...props} name='checkmark-circle-2-outline' />;
    const AlertIcon = (props: any) => <Icon {...props} name='alert-circle-outline' />;
    const ExternalLinkIcon = (props: any) => <Icon {...props} name='external-link' />;

    return (
        <Card style={styles.card} disabled={true} status='basic'>
            <View style={styles.header}>
                <Text category='h6' style={styles.title}>STRIPE CONNECT ACCOUNT</Text>
                <Button
                    size='small'
                    status={status.isSetup ? 'success' : 'warning'}
                    accessoryLeft={status.isSetup ? CheckIcon : AlertIcon}
                >
                    {status.isSetup ? 'ACTIVE' : 'NEEDS ATTENTION'}
                </Button>
                <Layout style={styles.buttonContainer}>
                    {!status.isSetup && status.accountLink && (
                        <Button
                            onPress={() => Linking.openURL(status.accountLink)}
                            status='info'
                        >
                            Complete Account Setup
                        </Button>
                    )}
                    {status.isSetup && status.loginLink && (
                        <Button
                            onPress={() => Linking.openURL(status.loginLink.url)}
                            status='primary'
                            accessoryRight={ExternalLinkIcon}
                        >
                            View Stripe Dashboard
                        </Button>
                    )}
                </Layout>
            </View>
            <Divider style={styles.divider} />

            <Layout style={styles.infoContainer} level='2'>
                <Card
                    style={styles.infoCard}
                    disabled={true}
                    header={(props) => (
                        <Text category='s1' {...props}>Business Profile</Text>
                    )}
                >
                    <Text>Name: {status.account.business_profile.name || 'Not set'}</Text>
                    <Text>MCC: {status.account.business_profile.mcc || 'Not set'}</Text>
                    <Text>URL: {status.account.business_profile.url || 'Not set'}</Text>
                </Card>

                <Card
                    style={styles.infoCard}
                    disabled={true}
                    header={(props) => (
                        <Text category='s1' {...props}>Account Details</Text>
                    )}
                >
                    <Text>Type: {status.account.type}</Text>
                    <Text>Country: {status.account.country}</Text>
                    <Text>Default Currency: {status.account.default_currency}</Text>
                </Card>

                <Card
                    style={styles.infoCard}
                    disabled={true}
                    header={(props) => (
                        <Text category='s1' {...props}>Capabilities</Text>
                    )}
                >
                    <Text>Card Payments: {status.account.capabilities.card_payments}</Text>
                    <Text>Transfers: {status.account.capabilities.transfers}</Text>
                </Card>
            </Layout>

            <Divider style={styles.divider} />

            <Text style={styles.message}>{status.message}</Text>

        </Card>
    );
};

const styles = StyleSheet.create({
    card: {
        marginVertical: 10,
        borderRadius: 8,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
    },
    title: {
        fontWeight: 'bold',
    },
    infoContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    infoCard: {
        marginBottom: 0,
    },
    cardTitle: {
        marginBottom: 8,
    },
    infoSection: {
        width: '48%',
        marginBottom: 8,
    },
    sectionTitle: {
        fontWeight: 'bold',
        marginBottom: 5,
    },
    divider: {
        marginVertical: 3,
    },
    message: {
        marginBottom: 3,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
});
