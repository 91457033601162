import React from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle, Input } from '@ui-kitten/components';
import { EmailSettings } from '../../models/Company.model';

interface EmailSettingsProps {
    emailSettings: EmailSettings;
    onUpdateEmailSettings: (updatedSettings: EmailSettings) => void;
    style?: any;
}

export const EmailSettingsComponent: React.FC<EmailSettingsProps> = ({
    emailSettings,
    onUpdateEmailSettings,
    style = {}
}) => {
    const updateEmailSetting = (key: keyof EmailSettings, value: boolean | string) => {
        const updatedSettings = new EmailSettings({
            ...emailSettings,
            [key]: value
        });
        onUpdateEmailSettings(updatedSettings);
    };

    const RenderToggle = (label: string, key: keyof EmailSettings) => (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
            <Text>{label}</Text>
            <Toggle
                checked={emailSettings[key] as boolean}
                onChange={(nextChecked) => updateEmailSetting(key, nextChecked)}
            />
        </View>
    );

    return (
        <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Email Settings</Text>} status="basic">
            <View style={{ flexDirection: 'column' }}>
                <Text category='h6' style={{ marginBottom: 10 }}>Customer Emails</Text>
                {RenderToggle('Send Created Email', 'customer_sendCreatedEmail')}
                {RenderToggle('Send Complete Email', 'customer_sendCompleteEmail')}

                <Text category='h6' style={{ marginTop: 20, marginBottom: 10 }}>Client Emails</Text>
                {RenderToggle('Send Created Email', 'client_sendCreatedEmail')}
                {RenderToggle('Send Completed Email', 'client_sendCompletedEmail')}

                <Text category='h6' style={{ marginTop: 20, marginBottom: 10 }}>General Settings</Text>
                {RenderToggle('Include Job Status Link in Email', 'include_job_status')}

                <Text category='h6' style={{ marginTop: 20, marginBottom: 10 }}>Admin Settings</Text>
                {RenderToggle('Enable Admin Notify Job Request', 'enable_admin_notify_job_request')}

                {emailSettings.enable_admin_notify_job_request && (
                    <Input
                        label="Admin Notification Email"
                        placeholder="Enter admin email"
                        value={emailSettings.admin_notify_job_request_email}
                        onChangeText={(nextValue) => updateEmailSetting('admin_notify_job_request_email', nextValue)}
                        style={{ marginTop: 10 }}
                    />
                )}
            </View>
        </Card>
    );
};
