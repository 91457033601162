import React, { useState, useEffect } from 'react';
import { View, StyleSheet, ScrollView, Modal, TouchableOpacity, useWindowDimensions } from 'react-native';
import { Button, Input, Text, Card, List, ListItem, Layout, RadioGroup, Radio } from '@ui-kitten/components';
import { PricingTier, InventoryCategory, InventoryItem } from '../../models/Inventory.model';
import * as StorageController from '../../functions/storageController';

interface PricingTierManagerProps {
    category: InventoryCategory;
    items: InventoryItem[];
    onSave: (updatedCategory: InventoryCategory) => void;
    onClose: () => void;
}

export const PricingTierManager: React.FC<PricingTierManagerProps> = ({ category, items, onSave, onClose }) => {
    const [pricingTiers, setPricingTiers] = useState<PricingTier[]>(
        category.pricing_tiers?.map(tier => ({
            ...tier,
            client_ids: tier.client_ids || []
        })) || []
    );
    const [newTierName, setNewTierName] = useState('');
    const [editingTierIndex, setEditingTierIndex] = useState<number | null>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const { width, height } = useWindowDimensions();
    const [clients, setClients] = useState<any[]>([]); // Store all clients
    const [viewMode, setViewMode] = useState<'items' | 'clients'>('items');
    const [clientSearchQuery, setClientSearchQuery] = useState('');
    const [filteredClients, setFilteredClients] = useState<any[]>([]);

    useEffect(() => {
        // Fetch all clients
        const company = StorageController.getCurrentCompany();
        if (company && company.clients) {
            setClients(company.clients);
            setFilteredClients(company.clients);
        }
    }, []);

    useEffect(() => {
        const lowercasedQuery = clientSearchQuery.toLowerCase();
        const filtered = clients.filter(client => 
            client.name.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredClients(filtered);
    }, [clientSearchQuery, clients]);

    const addTier = () => {
        if (newTierName.trim()) {
            setPricingTiers([...pricingTiers, new PricingTier({ 
                name: newTierName, 
                item_prices: {},
                client_ids: [] // Initialize client_ids as an empty array
            })]);
            setNewTierName('');
        }
    };

    const removeTier = (index: number) => {
        setPricingTiers(pricingTiers.filter((_, i) => i !== index));
    };

    const editTier = (index: number) => {
        setEditingTierIndex(index);
        setModalVisible(true);
    };

    const updateItemPrice = (tierIndex: number, itemId: string, price: number) => {
        const updatedTiers = [...pricingTiers];
        if (!updatedTiers[tierIndex].item_prices) {
            updatedTiers[tierIndex].item_prices = {};
        }
        updatedTiers[tierIndex].item_prices[itemId] = price;
        setPricingTiers(updatedTiers);
    };

    const addClientToTier = (tierIndex: number, clientId: string) => {
        const updatedTiers = [...pricingTiers];
        if (!updatedTiers[tierIndex].client_ids) {
            updatedTiers[tierIndex].client_ids = []; // Initialize if it doesn't exist
        }
        if (!updatedTiers[tierIndex].client_ids.includes(clientId)) {
            // Remove client from other tiers in this category
            updatedTiers.forEach((tier, index) => {
                if (index !== tierIndex && tier.client_ids) {
                    tier.client_ids = tier.client_ids.filter(id => id !== clientId);
                }
            });
            // Add client to the selected tier
            updatedTiers[tierIndex].client_ids.push(clientId);
            setPricingTiers(updatedTiers);
        }
    };

    const removeClientFromTier = (tierIndex: number, clientId: string) => {
        const updatedTiers = [...pricingTiers];
        if (updatedTiers[tierIndex].client_ids) {
            updatedTiers[tierIndex].client_ids = updatedTiers[tierIndex].client_ids.filter(id => id !== clientId);
            setPricingTiers(updatedTiers);
        }
    };

    const handleSave = () => {
        const updatedCategory = new InventoryCategory({
            ...category,
            pricing_tiers: pricingTiers
        });
        onSave(updatedCategory);
    };

    const renderItemsView = () => (
        <ScrollView style={{ flex: 1 }}>
            {items.map((item) => (
                <View key={item._id} style={styles.itemRow}>
                    <View style={styles.itemColumn}><Text>{item.name}</Text></View>
                    <View style={styles.basePriceColumn}>
                        <Text>${item.default_price.toFixed(2)}</Text>
                    </View>
                    {pricingTiers.map((tier, tierIndex) => (
                        <View key={tierIndex} style={styles.tierColumn}>
                            <Input
                                placeholder="Price"
                                value={tier.item_prices && item._id ? tier.item_prices[item._id]?.toString() || '' : ''}
                                onChangeText={(text) => updateItemPrice(tierIndex, item._id!, parseFloat(text) || 0)}
                                keyboardType="numeric"
                                style={styles.priceInput}
                            />
                        </View>
                    ))}
                    <View style={styles.addTierColumn} />
                </View>
            ))}
        </ScrollView>
    );

    const renderClientsView = () => (
        <View style={{ flex: 1 }}>
            <Input
                placeholder="Search clients..."
                value={clientSearchQuery}
                onChangeText={setClientSearchQuery}
                style={styles.searchInput}
            />
            <ScrollView>
                {filteredClients.map((client) => (
                    <View key={client._id} style={styles.itemRow}>
                        <View style={styles.itemColumn}><Text>{client.name}</Text></View>
                        {pricingTiers.map((tier, tierIndex) => (
                            <View key={tierIndex} style={styles.tierColumn}>
                                <RadioGroup
                                    selectedIndex={tier.client_ids.includes(client._id) ? 0 : -1}
                                    onChange={(index) => {
                                        if (index === 0) {
                                            addClientToTier(tierIndex, client._id);
                                        } else {
                                            removeClientFromTier(tierIndex, client._id);
                                        }
                                    }}
                                >
                                    <Radio />
                                </RadioGroup>
                            </View>
                        ))}
                        <View style={styles.addTierColumn} />
                    </View>
                ))}
            </ScrollView>
        </View>
    );

    return (
        <View style={[styles.container, { height: height * 0.8 }]}>
            <Text category="h5" style={styles.header}>Pricing Tiers for {category.name}</Text>
            <View style={styles.toggleContainer}>
                <Button
                    appearance={viewMode === 'items' ? 'filled' : 'outline'}
                    onPress={() => setViewMode('items')}
                >
                    Items
                </Button>
                <Button
                    appearance={viewMode === 'clients' ? 'filled' : 'outline'}
                    onPress={() => setViewMode('clients')}
                >
                    Clients
                </Button>
            </View>
            <ScrollView horizontal style={{ flex: 1 }} stickyHeaderIndices={[0]}>
                <View style={{ flex: 1 }}>
                    <View style={styles.headerRow}>
                        <View style={styles.itemColumn}>
                            <Text>{viewMode === 'items' ? 'Item' : 'Client'}</Text>
                        </View>
                        {viewMode === 'items' && (
                            <View style={styles.basePriceColumn}><Text>Base Price</Text></View>
                        )}
                        {pricingTiers.map((tier, index) => (
                            <View key={index} style={styles.tierColumn}>
                                <Text>{tier.name}</Text>
                                <Button size="tiny" onPress={() => editTier(index)}>Edit</Button>
                                <Button size="tiny" status="danger" onPress={() => removeTier(index)}>Remove</Button>
                            </View>
                        ))}
                        <View style={styles.addTierColumn}>
                            <Input
                                placeholder="New tier name"
                                value={newTierName}
                                onChangeText={setNewTierName}
                                style={styles.input}
                            />
                            <Button size="tiny" onPress={addTier}>Add Tier</Button>
                        </View>
                    </View>
                    {viewMode === 'items' ? renderItemsView() : renderClientsView()}
                </View>
            </ScrollView>
            <View style={styles.buttonContainer}>
                <Button onPress={handleSave}>Save Changes</Button>
                <Button appearance="ghost" status="basic" onPress={onClose}>Cancel</Button>
            </View>

            <Modal
                visible={modalVisible}
                transparent={true}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={[styles.modalContainer]}>
                    <Layout style={{ padding: 16, borderRadius: 16 }}>
                        <PricingTierEditor
                            tier={editingTierIndex !== null ? pricingTiers[editingTierIndex] : new PricingTier()}
                            clients={clients}
                            onSave={(updatedTier) => {
                                if (editingTierIndex !== null) {
                                    const updatedTiers = [...pricingTiers];
                                    updatedTiers[editingTierIndex] = updatedTier;
                                    setPricingTiers(updatedTiers);
                                }
                                setModalVisible(false);
                            }}
                            onCancel={() => setModalVisible(false)}
                            addClientToTier={(clientId) => addClientToTier(editingTierIndex!, clientId)}
                            removeClientFromTier={(clientId) => removeClientFromTier(editingTierIndex!, clientId)}
                        />
                    </Layout>
                </View>
            </Modal>
        </View>
    );
};

interface PricingTierEditorProps {
    tier: PricingTier;
    clients: any[];
    onSave: (updatedTier: PricingTier) => void;
    onCancel: () => void;
    addClientToTier: (clientId: string) => void;
    removeClientFromTier: (clientId: string) => void;
}

const PricingTierEditor: React.FC<PricingTierEditorProps> = ({ 
    tier, 
    clients, 
    onSave, 
    onCancel, 
    addClientToTier, 
    removeClientFromTier 
}) => {
    const [editedTier, setEditedTier] = useState(new PricingTier({
        ...tier,
        client_ids: tier.client_ids || [] // Ensure client_ids is initialized
    }));
    const [availableClients, setAvailableClients] = useState<any[]>([]);
    const { width, height } = useWindowDimensions();

    useEffect(() => {
        // Filter out clients that are already in the tier
        setAvailableClients(clients.filter(client => !editedTier.client_ids.includes(client._id)));
    }, [clients, editedTier.client_ids]);

    const handleSave = () => {
        onSave(editedTier);
    };

    const handleAddClient = (clientId: string) => {
        addClientToTier(clientId);
        setEditedTier(prevTier => ({
            ...prevTier,
            client_ids: [...(prevTier.client_ids || []), clientId]
        }));
    };

    const handleRemoveClient = (clientId: string) => {
        removeClientFromTier(clientId);
        setEditedTier(prevTier => ({
            ...prevTier,
            client_ids: (prevTier.client_ids || []).filter(id => id !== clientId)
        }));
    };

    return (
        <ScrollView style={{ flex: 1}}>
            <Input
                label="Tier Name"
                value={editedTier.name}
                onChangeText={(text) => setEditedTier({ ...editedTier, name: text })}
            />
            <Input
                label="Description"
                value={editedTier.description}
                onChangeText={(text) => setEditedTier({ ...editedTier, description: text })}
            />
            {/* <Input
                label="Multiplier"
                value={editedTier.multiplier.toString()}
                onChangeText={(text) => setEditedTier({ ...editedTier, multiplier: parseFloat(text) || 0 })}
                keyboardType="numeric"
            />
            <Input
                label="Fixed Price"
                value={editedTier.fixed_price.toString()}
                onChangeText={(text) => setEditedTier({ ...editedTier, fixed_price: parseFloat(text) || 0 })}
                keyboardType="numeric"
            /> */}
            <Button onPress={handleSave}>Save</Button>
            <Button appearance="ghost" status="basic" onPress={onCancel}>Cancel</Button>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16,
    },
    header: {
        marginBottom: 16,
    },
    headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#ccc',
        paddingBottom: 8,
    },
    itemRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#eee',
        paddingVertical: 8,
    },
    itemColumn: {
        width: 150,
        justifyContent: 'center',
    },
    basePriceColumn: {
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    tierColumn: {
        width: 120,
        alignItems: 'center',
    },
    addTierColumn: {
        width: 150,
        alignItems: 'center',
    },
    input: {
        width: '100%',
    },
    priceInput: {
        width: 80,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    toggleContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 16,
    },
    searchInput: {
        marginBottom: 10,
    },
});
