import React, { useState } from 'react';
import { View } from 'react-native';
import {
    Button,
    Card,
    Icon,
    Layout,
    Text,
    useTheme,
    Spinner,
} from "@ui-kitten/components";
import { Job } from '../../../models/Job.model';
import DMModal from '../../common/Modal';
import { Toast } from 'react-native-toast-message/lib/src/Toast';

interface ConvertJobComponentProps {
    job: Job;
    onConvert?: (job: Job) => void;
    canEdit?: boolean;
}

export const ConvertJobComponent = ({
    job,
    onConvert = () => { },
    canEdit = true
}: ConvertJobComponentProps) => {
    const theme = useTheme();
    const [showConvertModal, setShowConvertModal] = useState(false);
    const [isConverting, setIsConverting] = useState(false);

    const jobTypes = [
        {
            text: 'Roadside',
            value: 'roadside',
            icon: 'car-outline'
        },
        {
            text: 'Towing',
            value: 'towing',
            icon: 'car-outline' // or 'activity-outline'
        },
        {
            text: 'Transport',
            value: 'transport',
            icon: 'car-outline'
        }
    ];

    const onChangeJobType = async (newType: string) => {
        try {
            setIsConverting(true);
            let updatedJob = new Job(job);
            updatedJob.details.options = {
                roadside_job_options: newType === 'roadside',
                towing_job_options: newType === 'towing',
                transport_job_options: newType === 'transport'
            };
            await updatedJob.save();
            onConvert(updatedJob);

            Toast.show({
                text1: 'Job Type Changed',
                text2: `Job type changed to ${newType}`,
                type: 'success',
                position: 'bottom'
            });

        } catch (err) {
            console.log(err);
            Toast.show({
                text1: 'Error',
                text2: 'Failed to change job type',
                type: 'error',
                position: 'bottom'
            });
        } finally {
            setIsConverting(false);
            setShowConvertModal(false);
        }
    };

    const getCurrentJobType = () => {
        const options = job.details?.options || {};
        if (options.roadside_job_options) return 'roadside';
        if (options.towing_job_options) return 'towing';
        if (options.transport_job_options) return 'transport';
        return null;
    };

    const currentType = getCurrentJobType();

    return (
        <>
            <Button
                status="warning"
                appearance="outline"
                size='tiny'
                disabled={!canEdit}
                onPress={() => setShowConvertModal(true)}
                accessoryLeft={() => (
                    <Icon
                        name='swap-outline'
                        width={20}
                        height={20}
                        fill={theme['text-basic-color']}
                    />
                )}
            >
                Convert Job Type
            </Button>

            <DMModal
                visible={showConvertModal}
                onClose={() => !isConverting && setShowConvertModal(false)}
            >
                <Card disabled={true}>
                    <Text category='h6' style={{ marginBottom: 15 }}>Convert Job Type</Text>

                    {isConverting ? (
                        <Layout style={{ alignItems: 'center', padding: 20 }}>
                            <Spinner size='large' status='warning' />
                            <Text category='s1' style={{ marginTop: 10 }}>Converting job type...</Text>
                        </Layout>
                    ) : (
                        <>
                            <Layout style={{ gap: 10 }}>
                                {jobTypes.map((type) => (
                                    <Button
                                        key={type.value}
                                        status={currentType === type.value ? "primary" : "warning"}
                                        appearance={currentType === type.value ? "filled" : "outline"}
                                        disabled={currentType === type.value}
                                        onPress={() => onChangeJobType(type.value)}
                                        accessoryLeft={(props) => (
                                            <Icon
                                                {...props}
                                                name={type.icon}
                                            />
                                        )}
                                        style={{ marginBottom: 5 }}
                                    >
                                        {type.text}
                                    </Button>
                                ))}
                            </Layout>

                            <Button
                                status="basic"
                                appearance="ghost"
                                onPress={() => setShowConvertModal(false)}
                                style={{ marginTop: 20 }}
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                </Card>
            </DMModal>
        </>
    );
}; 