import React from 'react';
import { View } from 'react-native';
import { Button, Text } from '@ui-kitten/components';
import { JOB_TOWING_START_END_OPTION } from '../../../models/Job.model';

interface RouteTypeSelectorProps {
    startEndOption: JOB_TOWING_START_END_OPTION;
    onRouteTypeChange: (option: JOB_TOWING_START_END_OPTION) => void;
}

export const RouteTypeSelector = ({
    startEndOption,
    onRouteTypeChange
}: RouteTypeSelectorProps) => {
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
            <Text category="label" style={{ marginRight: 10 }}>Route S/E type:</Text>
            <Button
                size="small"
                appearance={startEndOption === JOB_TOWING_START_END_OPTION.NONE ? 'filled' : 'outline'}
                status="basic"
                onPress={() => onRouteTypeChange(JOB_TOWING_START_END_OPTION.NONE)}
            >
                Default/None
            </Button>
            <Button
                size="small"
                appearance={startEndOption === JOB_TOWING_START_END_OPTION.ROUND_TRIP ? 'filled' : 'outline'}
                status="basic"
                onPress={() => onRouteTypeChange(JOB_TOWING_START_END_OPTION.ROUND_TRIP)}
            >
                Round Trip
            </Button>
            <Button
                size="small"
                appearance={startEndOption === JOB_TOWING_START_END_OPTION.START_ONLY ? 'filled' : 'outline'}
                status="basic"
                onPress={() => onRouteTypeChange(JOB_TOWING_START_END_OPTION.START_ONLY)}
            >
                From Start
            </Button>
            <Button
                size="small"
                appearance={startEndOption === JOB_TOWING_START_END_OPTION.END_ONLY ? 'filled' : 'outline'}
                status="basic"
                onPress={() => onRouteTypeChange(JOB_TOWING_START_END_OPTION.END_ONLY)}
            >
                To End
            </Button>
            <Button
                size="small"
                appearance={startEndOption === JOB_TOWING_START_END_OPTION.START_END ? 'filled' : 'outline'}
                status="basic"
                onPress={() => onRouteTypeChange(JOB_TOWING_START_END_OPTION.START_END)}
            >
                Start to End
            </Button>
        </View>
    );
}; 