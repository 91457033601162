var config = require('../config/config.js')
const axios = require('axios');
import * as StorageController from './storageController'
import * as MqttService from '../services/mqtt.service'
import * as UserController from './user.controller'
import * as UpdateController from './update.controller'
import * as MembershipController from './membership.controller'
import { Message } from '../models/Message.model';
import { batch } from 'react-redux';

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

// subscribe to all channels
export async function subscribeToAllChannels() {
    const channels = await updateMessageChannelsFromApi()
    //console.log('subscribeToAllChannels', channels);
    for (var i = 0; i < channels.length; i++) {
        subscribeToChannel(channels[i]._id)
    }
}

// update message channels
export async function updateMessageChannelsFromApi() {
    //console.log('updateMessageChannels');
    var chArr = []
    var channelIds = await getMessageChannelsFromMemberStorage()
    chArr = await getMessageChannelsFromApi()
    StorageController.appState.messageChannels = chArr
    StorageController.saveStateToDisk()
    return chArr
}


export async function getMessageChannelsFromApi() {
    //console.log('getMessageChannels from api');
    var channelArr = await getMessageChannelsFromMemberStorage()
    try {
        const response = await axios.post(getApi() + `/messagechannel/ids`, channelArr);
        //console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// get channel from api
export async function getMessageChannelFromApi(channelId: string) {
    //console.log('getMessageChannels from api');
    try {
        const response = await axios.get(getApi() + `/messagechannel/${channelId}`);
        //console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
        return
    }
}


// set selected channel in localStorage
export function setSelectedChannel(channelObj: any) {
    //console.log('setSelectedChannel', channelObj);
    StorageController.appState.selectedChannel = channelObj
    StorageController.saveStateToDisk()
}

// get message channels from member in storageController
export async function getMessageChannelsFromMemberStorage() {
    const channels = await StorageController.appState.selectedMembership?.message_channels
    return channels
}

// get messages from channel by id from api
export async function getMessagesFromChannel(channelId: string) {
    //console.log('getMessagesFromChannel', channelId);
    try {
        const response = await axios.get(getApi() + `/message/channel/${channelId}`);
        return response.data

    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// send chat message via mqtt
export async function sendChatMessageToChannel(message: string) {
    // create payload object
    var payload = {
        //@ts-ignore
        channel_id: StorageController.appState.selectedChannel?._id || null,
        sender_id: StorageController.appState.selectedMembership?._id,
        message: message,
        type: 'chat',
        read: false
    } as any
    // send message via mqtt
    //console.log('sendChatMessage', payload);
    MqttService.publishChatChannelMessage(payload)
    payload.createdAt = new Date().toLocaleDateString()
    return payload
}

// create new channel
export async function createChannel(channelName: string, otherMembership: string) {
    // create payload object
    var payload = {
        company_id: StorageController.appState.selectedMembership?.company_id,
        creator_id: StorageController.appState.selectedMembership?._id,
        members: [StorageController.appState.selectedMembership?._id, otherMembership],
        subject: channelName,
        type: 'chat'
    } as any
    const response = await axios.post(getApi() + `/messagechannel`, payload);
    return response.data
}

// subscribe to channel via mqtt
export async function subscribeToChannel(channelId: string) {
    const response = await MqttService.subscribeToChannel(channelId)
    return response
}

// update channels in member
export async function updateMemberMessageChannels() {
    //@ts-ignore
    const response = await UserController.getMembershipById(StorageController.appState.selectedMembership?._id)
    //@ts-ignore
    StorageController.appState.selectedMembership.message_channels = response.message_channels
    StorageController.saveStateToDisk()
    return response.message_channels
}

// delete channel
export async function deleteChannel(channel: any) {
    const response = await axios.delete(getApi() + `/messagechannel/${channel._id}`);
    return response.data
}



// get unread messages
export async function getUnreadMessages() {
    try {
        if (!StorageController.appState.selectedMembership) return
        const response = await axios.get(getApi() + `/message/unread/${StorageController.appState.selectedMembership._id}`);
        // if there are unread messages, update the unread messages in the storage controller
        // console.log("🚀============== ~ file: message.controller:175 ~ getUnreadMessages ~ response🚀==============", response)
        if (response.data.length > 0) {
            // setUnreadMessagesToStorageController(response.data)
        }
        return response.data
    } catch (error) {
        console.log(error);
    }
}

// get unread messages for all memberships
export async function getUnreadMessagesForAllMemberships() {
    const membershipIds = StorageController.getAppState().memberships;
    let unreadMessages = StorageController.getAppState().allMembershipUnreadMessages || [];
    for (var i = 0; i < membershipIds.length; i++) {
        let id = membershipIds[i]._id
        const messages = await axios.get(getApi() + `/message/unread/${id}`);
        StorageController.appState.allMembershipUnreadMessages.push(messages)
        // console.log("🚀============== ~ file: message.controller:193 ~ getUnreadMessagesForAllMemberships ~ messages🚀==============", messages)
        unreadMessages.push(messages)
    }
    // console.log("🚀 ~ file: message.controller:175 ~ getUnreadMessagesForAllMemberships ~ unreadMessages:", unreadMessages)
    UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.UPDATED_UNREAD_MESSAGES, data: unreadMessages })
    StorageController.saveStateToDisk()
}


// Set the unread messages to stoargae controller by unique member id
export async function setUnreadMessagesToStorageController(unreadMessages: any) {
    try {

        if (!unreadMessages || unreadMessages.length === 0) return
        let unreadMessageSenderIds = unreadMessages?.map((message: any) => message.sender._id);
        unreadMessageSenderIds = [...new Set(unreadMessageSenderIds)];

        StorageController.appState.unreadMessageSenderIds = unreadMessageSenderIds;
        // console.log("🚀 ~ file: message.controller:192 ~ setUnreadMessagesToStorageController ~ unreadMessageSenderIds:", unreadMessageSenderIds)
        StorageController.saveStateToDisk();
        UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.UPDATED_UNREAD_MESSAGES, data: unreadMessageSenderIds })
    } catch (err) {
        console.log("🚀============== ~ file: message.controller:205 ~ setUnreadMessagesToStorageController ~ err🚀==============", err)

    }
}

// set messages to read
export async function setMessageToRead(message: Message) {
    message.read = true
    // console.log("🚀 ~ file: message.controller:199 ~ setMessageToRead ~ message:", message)
    const response = await axios.put(getApi() + `/message/${message._id}`, message);
    return response.data
}

// set all messages to read that selected_membership id is the recipient
export async function setAllMessagesToReadForSelectedMembership(messages: Message[]) {
    const selectedMembership_id = StorageController.getAppState().selectedMembership?._id || "";
    const senderId = StorageController.getAppState().selectedChatRecipient?._id || "";
    if (!senderId || !selectedMembership_id) return
    try {
        if (!messages || messages.length === 0) return
        if (!Array.isArray(messages)) messages = [messages]
        const myUnreadMessages = messages.filter((message: Message) => {
            if (typeof message.recipient === 'string') {
                return (message.recipient === selectedMembership_id) && (message.read === false);
            } else {
                // recipient is a full membership object 
                //@ts-ignore
                const recipientId = message.recipient._id as string;
                return (recipientId === selectedMembership_id) && (message.read === false);
            }
        });
        if (myUnreadMessages.length > 0) {
            console.log("🚀============== ~ file: message.controller.ts:253 ~ setAllMessagesToReadForSelectedMembership ~ messages🚀==============", myUnreadMessages)
            await setAllMessagesToRead(senderId, selectedMembership_id)
        }
        // filter out the sender id from the unread messages in storage controller
        let unreadMessages = StorageController.getAppState().unreadMessageSenderIds;
        unreadMessages = unreadMessages.filter(id => id !== senderId);
        StorageController.appState.unreadMessageSenderIds = unreadMessages;

        // Use setTimeout to delay the dispatch
        setTimeout(async () => {
            UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.UPDATED_UNREAD_MESSAGES, data: unreadMessages })
            await StorageController.saveStateToDisk();
        }, 0);

    } catch (e) {
        console.log(e)
    }
}


// set all current channel messages to read
export async function setAllMessagesToRead(sender_id: string, recipient_id: string) {
    const data = {
        sender_id: sender_id,
        recipient_id: recipient_id
    }
    const response = await axios.post(getApi() + `/message/read/`, data);
    // filter out the sender id from the unread messages in storage controller
    // console.log("🚀 ~ file: message.controller:239 ~ setAllMessagesToRead ~ response:", response)
    // let unreadMessages = StorageController.getAppState().unreadMessageSenderIds;
    // unreadMessages = unreadMessages.filter(id => id !== sender_id);
    // StorageController.appState.unreadMessageSenderIds = unreadMessages;
    // StorageController.saveStateToDisk();
    // UpdateController.dispatchEventStateChange({ action: UpdateController.STATE_ACTIONS.UPDATED_UNREAD_MESSAGES, data: unreadMessages })
    return response.data
}


// get all messages between members
export async function getMessagesBetweenMembers(member1: string, member2: string) {
    const limit = 50;
    const offset = 0;
    const data = {
        member1: member1,
        member2: member2,
        limit: limit,
        offset: offset
    }
    const response = await axios.post(getApi() + `/message/chat/${member1}/${member2}`, data);
    return response.data
}

// send message
export async function sendMessage(recipient: string, message: string) {

    // create payload object
    var payload = {
        company_id: StorageController.appState.selectedMembership?.company_id,
        sender: StorageController.getAppState().selectedMembership?._id,
        recipient: recipient,
        text: message,
        type: 'chat',
        read: false,
        recipientType: "member",
        user: {
            _id: StorageController.getAppState().selectedMembership?._id,
            name: StorageController.getAppState().selectedMembership?.name,
        },
        senderName: StorageController.getAppState().selectedMembership?.name,
    }
    // console.log("🚀 ~ file: message.controller:282 ~ sendMessage ~ payload:", payload)
    const response = await axios.post(getApi() + `/message`, payload);
    return response.data
}