import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    Pressable,
    Dimensions,
    useWindowDimensions,
    ScrollView,
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Modal,
    Input,
    //@ts-ignore
    Tooltip,
    useTheme
} from "@ui-kitten/components";
import * as UpdateController from "../../functions/update.controller"
import * as StorageController from '../../functions/storageController';
import * as JobsController from '../../functions/job.controller';
import * as RouteController from '../../functions/route.controller';
import ErrorBoundary from '../ErrorBoundary.component';
import * as GoogleController from '../../functions/google.controller';
import { Job, TowingLocation, JobTowingDetails, JobDetails, JOB_STATUS, TOWING_TYPE, JOB_LOCATION_TYPE } from '../../models/Job.model';
import { Member } from '../../models/Member.model';
import { Route } from '../../models/Route.model';
import { formatIsoDateTime, formatTimestamp } from '../../functions/utils';
import { Client } from '../../models/Client.model';
import { VendorAcceptModal } from './VendorAcceptModal';
import { IAction, useAppStateChange } from '../../hooks/appStateChange.hook';
import { RouteSummary } from '../routes/RouteSummary';
import DMModal from '../common/Modal';

interface JobListItemProps {
    selectedJobId: string;
    job: Job;
    driver: Member;
    client: Client;
    // var_drivers: Member[];
    // var_clients: Client[];
    var_viewedBy: any;
    fn_selectJob: (job: Job) => void;
    fn_setDirections: (driver: Member, job: Job) => void;
    fn_openAssign: (job: Job) => void;
    fn_vendorAcceptJob: (job_id: string, eta: number) => void;
    fn_zoomToMarker: (job: Job) => void;
}


const JobListItem = ({
    selectedJobId,
    job = new Job(),
    driver = new Member({}),
    client = new Client({}),
    // var_drivers = [],
    // var_clients = [],
    var_viewedBy,
    fn_selectJob = () => { },
    fn_setDirections = () => { },
    fn_openAssign = () => { },
    fn_vendorAcceptJob = () => { },
    fn_zoomToMarker = (j: any) => { },
}: JobListItemProps) => {

    //@ts-ignore
    const theme = useTheme() as any

    const [showRoadsideJobOptions, setShowRoadsideJobOptions] = React.useState(false)
    const [showTowingJobOptions, setShowTowingJobOptions] = React.useState(false)
    const [showTransportJobOptions, setShowTransportJobOptions] = React.useState(false)
    const [showRouteSummary, setShowRouteSummary] = React.useState(false)

    const [showVendorAcceptModal, setShowVendorAcceptModal] = React.useState(false)


    const [jobState, setJobState] = useState(job)

    const [isJobSelected, setIsJobSelected] = useState(false)

    const { dispatchEventStateChange } = useAppStateChange({
        onSetSelectedJob: (action: IAction) => {
            const isSelected = action.data._id == jobState._id
            if (isJobSelected != isSelected) {
                setIsJobSelected(isSelected)
            }
        }
    })

    useEffect(() => {
        setJobState(job)
    }, [job])

    useEffect(() => {
        // get job options
        // setJobState(new Job(var_job))
        if (jobState.details.options) {
            let options = jobState.details.options
            setShowRoadsideJobOptions(options.roadside_job_options)
            setShowTowingJobOptions(options.towing_job_options)
            setShowTransportJobOptions(options.transport_job_options)
        } else {
            setShowRoadsideJobOptions(true)
            setShowTowingJobOptions(false)
            setShowTransportJobOptions(false)
        }
    }, [jobState])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// JOB ACTIONS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */


    const selectJob = () => {
        UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.SET_SELECTED_JOB, data: jobState })
        fn_selectJob(jobState)
    }

    const promoteQuoteToJob = async () => {
        if (StorageController.getAppState().selectedMembership?.is_client) {
            // client
            jobState.status = JOB_STATUS.REQUEST
            const _j = await JobsController.updateJob(jobState)
            // console.log("promoteQuoteToJob", _j)
        }
        else {
            // vendor
            jobState.status = JOB_STATUS.UNASSIGNED
            const _j = await JobsController.updateJob(jobState)
            // console.log("promoteQuoteToJob", _j)
        }
    }

    const cancelJob = async () => {
        // client
        jobState.status = JOB_STATUS.CANCELLED
        const _j = await JobsController.updateJob(jobState)
        // console.log("cancelJob", _j)
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// ICONS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const ForwardIcon = () => (
        <Icon fill={theme['text-basic-color']} name='arrow-ios-forward' width={15} height={25} />
    );
    const TruckIcon = () => (
        <Icon style={{ width: "10px" }} fill="#FFFFFF" name='car-outline' />
    );



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CARD RENDERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    // console.log("JobListItem", props);
    const renderStatusColour = (t: string) => {
        if (t == JOB_STATUS.ASSIGNED) {
            return "orange"
        }
        if (t == JOB_STATUS.PENDING) {
            return "blue"
        }
        if (t == JOB_STATUS.COMPLETE) {
            return "green"
        }
        if (t == JOB_STATUS.CANCELLED) {
            return "purple"
        }
        if (t == JOB_STATUS.QUOTE) {
            return "grey"
        }
        if (t == JOB_STATUS.REQUEST) {
            return "#ff34fb"
        }
        if (t == JOB_STATUS.TRANSFERRED_OUT) {
            return "cyan"
        }
        else {
            return "red"
        }
    }


    // render custom card header
    const RenderCardHeaderAccent = () => {
        // get status colour
        let statusColour = renderStatusColour(jobState.status)
        return (
            <View style={{ height: 5, backgroundColor: statusColour, borderTopLeftRadius: 5, borderTopRightRadius: 5 }}>
            </View>
        )
    }

    const RenderPriority = ({ style }: any) => {
        // job.details.priority = {name: String, value: Number}
        const priority = jobState.details?.priority || { name: "Normal", value: 0 }
        const priorityName = priority.name
        const priorityValue = priority.value
        let priorityColour = "green"
        let priorityColours = ["green", "red", "blue"]
        // kind of like a small badge
        return (
            <View style={[{ backgroundColor: priorityColours[priorityValue] }, style]}>
                <Text style={{ fontSize: 10 }}>Priority: </Text>
                <Text style={{ fontSize: 10, backgroundColor: priorityColours[priorityValue], color: "white" }}>{priorityName}</Text>
            </View>
        )
    }



    const RenderButtons = ({ job }: { job: Job }) => {

        if (job.status === JOB_STATUS.QUOTE) {
            return null
        }
        else if (job.status === JOB_STATUS.REQUEST) {
            return null
        }
        else if (job.status === JOB_STATUS.COMPLETE) {
            return null
        }
        else if (job.status === JOB_STATUS.PENDING) {
            return null
        }
        else if (job.status === JOB_STATUS.CANCELLED) {
            return null
        }
        else if (job.status === JOB_STATUS.TRANSFERRED_OUT) {
            return null
        }

        else if (job.status === JOB_STATUS.ASSIGNED) {
            return (<Button
                style={{}}
                size='tiny'
                appearance='outline'
                status='warning'
                onPress={(e: any) => fn_openAssign(job)}
                disabled={StorageController.getAppState().selectedMembership?.is_client ? true : false}
            >
                Re-assign
            </Button>
            )
        }
        //Unassigned
        else if (job.status === JOB_STATUS.UNASSIGNED) {
            return (
                <Button
                    style={{ zIndex: 10 }}
                    appearance='outline'
                    status='warning'
                    size='tiny'
                    onPress={() => fn_openAssign(job)}
                    disabled={StorageController.getAppState().selectedMembership?.is_client ? true : false}
                >
                    Assign
                </Button>
            )
        }
        else {
            return null
        }
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// ANIMATION
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const pulseValue = useRef(new Animated.Value(1)).current;
    useEffect(() => {
        // Start the pulse animation when the component mounts
        if (jobState.status == JOB_STATUS.REQUEST) {
            Animated.loop(
                Animated.sequence([
                    Animated.timing(pulseValue, {
                        toValue: 1.5,
                        duration: 500,
                        useNativeDriver: true,
                    }),
                    Animated.timing(pulseValue, {
                        toValue: 1,
                        duration: 500,
                        useNativeDriver: true,
                    }),
                ])
            ).start();
        }
        return () => {
            pulseValue.stopAnimation();
        };
    }, [jobState]);


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// OTHER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const getVendorName = (job: Job) => {
        try {
            if (job.vendor_profile_id) {

                // get the vendor profiles from storage controller
                const vendorProfiles = StorageController.getAppState().vendorProfiles
                if (!vendorProfiles) return "N/A"
                if (!(vendorProfiles?.length > 0)) return
                // find the vendor profile, matching the vendor_profile_id or linkedVendorAccountId to the job.vendor_company_id
                let vendorProfile: any = vendorProfiles.find((vendorProfile: any) => {
                    if (vendorProfile._id == job.vendor_profile_id) return true
                    if (vendorProfile.linkedVendorAccountId == job.vendor_company_id) return true
                    return false
                })
                if (vendorProfile) {
                    return vendorProfile.name
                }
            }
            return "N/A"
        } catch (e) {
        }
    }



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// TIMES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const RenderTimeStatus = ({ label, time, isConditionMet, conditionMetText, conditionNotMetText }: any) => {
        const date = new Date(time).getTime();
        const localDate = (time !== 0 && !!time) && !isNaN(date) ? formatTimestamp(date) : "N/A";
        const backgroundColor = isConditionMet ? "green" : "red";

        return (
            <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
                <Text style={{ fontSize: 12 }}>{label}:</Text>
                <Text style={{ fontSize: 12, backgroundColor }}>{localDate}</Text>
            </View>
        );
    };
    interface RenderTimeStatusProps {
        item: Job
    }

    const RenderCreatedTime = ({ item }: RenderTimeStatusProps) => {
        const date = new Date(item.start_time || new Date().getTime());
        const localDate = formatTimestamp(date);
        return (
            <Text style={{ fontSize: 12 }}>Time: <Text style={{ fontSize: 12 }}>{localDate}</Text></Text>
        );
    };

    const RenderAccepted = ({ item }: RenderTimeStatusProps) => (
        <RenderTimeStatus
            label="Accepted"
            time={item.accepted_time}
            isConditionMet={item.driver_accepted}
            conditionMetText={formatTimestamp(new Date(item.accepted_time || 0))}
            conditionNotMetText="N/A"
        />
    );

    const RenderOnRoute = ({ item }: RenderTimeStatusProps) => (
        <RenderTimeStatus
            label="En route"
            time={item.driver_on_route_time}
            isConditionMet={item.driver_on_route}
            conditionMetText={formatTimestamp(new Date(item.driver_on_route_time || 0))}
            conditionNotMetText="N/A"
        />
    );

    const RenderArrived = ({ item }: RenderTimeStatusProps) => {
        let date = new Date(item.arrived_time || 0);
        let localDate: any = formatTimestamp(date) as string;

        if (localDate.includes("NaN")) {
            date = new Date(item.pending_time || 0);
            localDate = formatTimestamp(date);
        }

        return (
            <RenderTimeStatus
                label="Arrived"
                time={item.arrived_time}
                isConditionMet={item.driver_arrived}
                conditionMetText={localDate}
                conditionNotMetText="N/A"
            />
        );
    };

    const RenderPendingTime = ({ item }: RenderTimeStatusProps) => {
        if (item.pending_time || item.pending_time !== 0) {
            return (
                <RenderTimeStatus
                    label="Pending"
                    time={item.pending_time}
                    isConditionMet={item.pending_time || 0 > 0}
                    conditionMetText={formatTimestamp(new Date(item.pending_time || 0))}
                    conditionNotMetText="N/A"
                />
            );
        }
        return null;
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// STATUS RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const RenderStatus = ({ item }: { item: Job }) => {
        const statusStyles = {
            [JOB_STATUS.COMPLETE]: { backgroundColor: 'green', text: 'Completed' },
            [JOB_STATUS.PENDING]: { backgroundColor: 'blue', text: 'Pending Review' },
            [JOB_STATUS.ASSIGNED]: { backgroundColor: 'orange', text: 'Assigned' },
            [JOB_STATUS.CANCELLED]: { backgroundColor: 'purple', text: 'Cancelled' },
            [JOB_STATUS.QUOTE]: { backgroundColor: 'grey', text: 'Quote' },
            [JOB_STATUS.TRANSFERRED_OUT]: { backgroundColor: 'cyan', text: 'Transferred', textColor: 'black' },
            [JOB_STATUS.REQUEST]: { backgroundColor: '#ff34fb', text: 'Job Request', animated: true },
            default: { backgroundColor: 'red', text: 'Unassigned' }
        };

        //@ts-ignore
        const { backgroundColor, text, textColor = 'white', animated = false } = statusStyles[item.status] || statusStyles.default;

        const content = (
            <Layout style={{ backgroundColor, width: "105px", textAlign: "center", position: "absolute", left: 0, top: 0 }}>
                <Text numberOfLines={1} style={{ paddingHorizontal: 0, paddingVertical: 5, color: textColor }}>{text}</Text>
            </Layout>
        );

        // if (animated) {
        //     return (
        //         <Animated.View style={{ transform: [{ scale: pulseValue }], position: "absolute", left: 0, top: 0 }}>
        //             {content}
        //         </Animated.View>
        //     );
        // }

        return content;
    };

    const RenderRequestButton = () => {
        let showBtn = true
        if (StorageController.getAppState().selectedMembership?.is_client) {
            showBtn = false
        }
        if (jobState.is_client_created && StorageController.getCurrentCompany()._id != jobState.company_id) {
            showBtn = false
        }
        if (showBtn) {
            return (
                <Button
                    size='tiny'
                    status="request"
                    appearance="outline"
                    onPress={() => setShowVendorAcceptModal(true)}>
                    Accept/Reject
                </Button>
            )
        } else {
            return <Text>Awaiting Vendor Accept...</Text>
        }
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// ROADSIDE JOB INFO
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const RenderRoadsideAddress = () => {
        return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Icon name="pin-outline" width={14} height={14} fill="grey" />
                <Text numberOfLines={1} style={{ fontSize: 12 }}>
                    {jobState.details?.address?.suburb && (
                        <>
                            <Text style={{ fontWeight: 'bold' }}>{jobState.details.address.suburb}</Text>
                            <Text> - </Text>
                        </>
                    )}
                    {typeof jobState.details.address === "string"
                        ? jobState.details.address
                        : GoogleController.addressBuilder(jobState.details.address)
                    }
                </Text>
            </View>

        )
    }

    const RenderRoadsideInfo = () => {
        // if roadside is selected, show roadside details
        return (
            <Text numberOfLines={1} style={{ fontSize: 12 }}>
                Vehicle: {jobState.details.vehicle_details?.make} {jobState.details.vehicle_details?.model} - {jobState.details.vehicle_details?.rego}
            </Text>
        )
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// TOWING JOB INFO
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const RenderMemoizedTowingLocations = useMemo(() => {
        return (
            <RenderTowingLocations
                job={jobState}
            />
        )
    }, [jobState])

    const RenderMemoizedTowingDetails = useMemo(() => {
        return (
            <RenderTowingDetails
                job={jobState}
            />
        )
    }, [jobState.details?.towing_details])






    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// DRIVER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const RenderDriver = ({ item }: any) => {
        // const driver: Member | any = var_drivers.find(driver => driver._id === item.member_id) || {};
        const driver_name = driver.name || "";

        const commonTextStyle = {
            fontSize: 12,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        } as any;

        const renderDriverText = () => (
            <Text style={commonTextStyle}>
                <TruckIcon />
                {driver_name}
            </Text>
        );

        if (item.status === JOB_STATUS.COMPLETE || item.status === JOB_STATUS.PENDING) {
            return renderDriverText();
        }

        if (item.status === JOB_STATUS.ASSIGNED) {
            return (
                <TouchableOpacity onPress={() => fn_setDirections(driver, item)}>
                    {/* @ts-ignore */}
                    <Button
                        appearance='outline'
                        status='basic'
                        style={{ marginLeft: 10, zIndex: 10 }}
                        size='tiny'
                        onPress={() => fn_setDirections(driver, item)}
                    >
                        <TruckIcon />
                        {driver_name || "N/A"}
                    </Button>
                </TouchableOpacity>
            );
        }

        // Unassigned or other statuses
        return <Text></Text>;
    };


    const RenderClientOrVendorInfo = () => {
        const company = StorageController.getCurrentCompany()
        const is_own_job = jobState.company_id == company._id
        const weAreClient = jobState.client_company_id == company._id
        const weAreVendor = jobState.vendor_company_id == company._id
        // if we are the client, and the vendor is not us, show the vendor
        if (weAreClient && !weAreVendor) {
            return <Text category='s1' style={{ fontWeight: 'bold' }}>{getVendorName(jobState)}</Text>
        }
        // if we are the vendor, and the client is not us, show the client
        if (weAreVendor && !weAreClient) {
            return <Text category='s1' style={{ fontWeight: 'bold' }}>{client.name}</Text>
        }
        return <Text category='s1' style={{ fontWeight: 'bold' }}>{client.name}</Text>
    }



    return (
        <View style={{ flexDirection: 'column' }}>
            <RenderRouteSummaryModal
                job={jobState}
                visible={showRouteSummary}
                setVisible={setShowRouteSummary}
            />
            <View style={{ flexDirection: 'row' }}>
                <Card
                    style={[styles.card, { backgroundColor: isJobSelected ? theme['background-basic-color-3'] : theme['background-basic-color-1'] }]}
                    accent={RenderCardHeaderAccent}
                    onPress={() => {
                        selectJob()
                    }}
                    header={() =>
                        <View style={{ flexDirection: 'row', height: 30, zIndex: 10, gap: 3 }}>
                            <View style={{ flex: 1 }}>
                                <RenderStatus item={jobState} />
                            </View>
                            <View style={styles.headerText}>
                                <Text
                                    numberOfLines={1}
                                    category="s1">
                                    {!jobState.details.selected_services || jobState.details.selected_services.length === 0
                                        ? "N/A"
                                        : jobState.details.selected_services.map(service => service.name).join(" ")}
                                </Text>
                            </View>
                            <View style={{ flex: 1, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                {
                                    jobState.status === 'request' &&
                                    <RenderRequestButton />
                                }
                                {
                                    jobState.vendor_eta ?

                                        <Text numberOfLines={1} adjustsFontSizeToFit style={{ fontSize: 9 }}>Vendor ETA: {jobState.vendor_eta} mins</Text>

                                        :
                                        <></> //not having this produces a random '0'
                                }

                            </View>
                            <RenderPriority style={styles.priority} />
                            <View style={styles.headerButtons}>
                                {jobState.route_id &&
                                    <Button
                                        size='tiny'
                                        status='info'
                                        onPress={() => setShowRouteSummary(true)}
                                    >
                                        Open Route
                                    </Button>
                                }
                                <RenderButtons job={jobState} />
                            </View>
                        </View>
                    }>
                    <View style={styles.containerRow}>
                        <View style={[styles.statusLine, { backgroundColor: renderStatusColour(jobState.status) }]}>
                        </View>
                        <VendorAcceptModal
                            showModal={showVendorAcceptModal}
                            job={jobState}
                            onAccept={fn_vendorAcceptJob}
                            onClose={() => setShowVendorAcceptModal(false)}
                        />
                        {/* STATUS | MAIN INFO | BUTTON */}
                        <View style={styles.mainContainer}>

                            <View style={styles.mainInfoArea}>
                                <View style={styles.basicInfoArea}>
                                    {/* NON DISPATCHABLE */}
                                    {!jobState.dispatchable &&
                                        <View style={{ flex: 1, width: "50%" }}>
                                            <View style={{ flex: 2, backgroundColor: "red", height: 15, zIndex: 11, borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                                                <Text numberOfLines={1} adjustsFontSizeToFit style={{ fontSize: 9, textAlign: 'center' }}>    Non Dispatchable</Text>
                                            </View>
                                        </View>
                                    }
                                    {/* SCHEDULED */}
                                    {jobState.is_scheduled &&
                                        <View style={{ flex: 1, width: "50%" }}>
                                            <RenderIsScheduled item={jobState} />
                                        </View>
                                    }
                                    <View style={{ flexDirection: 'row' }}>
                                        {var_viewedBy == "client" ?
                                            <Text category='s1' style={{ fontWeight: 'bold' }}>{getVendorName(jobState)}</Text>
                                            :
                                            // <RenderClientName item={job} clients={var_clients} />
                                            <>
                                                <RenderClientOrVendorInfo />
                                                {/* <Text category='s1' style={{ fontWeight: 'bold' }}>{client.name}</Text> */}
                                            </>
                                        }
                                        {jobState.client_reference_id && jobState.client_reference_id != "" &&
                                            <RenderClientReferenceId item={jobState} />
                                        }
                                    </View>
                                    <RenderCreatedTime item={jobState} />
                                    {(showRoadsideJobOptions || showTransportJobOptions) &&
                                        <>
                                            <RenderRoadsideInfo />
                                            <RenderRoadsideAddress />
                                        </>
                                    }
                                    {(showTowingJobOptions || jobState.details?.towing_details) &&
                                        <>
                                            {RenderMemoizedTowingDetails}
                                        </>
                                    }




                                </View>
                                {
                                    jobState.status == "quote" &&
                                    <View style={styles.scheduleInfo}>
                                        <View style={{ flex: 1, height: 50 }}>
                                            <Button
                                                appearance='outline'
                                                size='tiny'
                                                status='success'
                                                style={{ margin: 5, flex: 1 }}
                                                onPress={() => {
                                                    // send to server
                                                    promoteQuoteToJob()
                                                }
                                                }>
                                                Promote to Job
                                            </Button>
                                            <Button
                                                appearance='outline'
                                                size='tiny'
                                                status='danger'
                                                style={{ margin: 5, flex: 1 }}
                                                onPress={() => {
                                                    cancelJob()
                                                }
                                                }>
                                                Cancel
                                            </Button>
                                        </View>
                                    </View>
                                }

                                <View style={styles.timesInfo}>
                                    {driver ?
                                        <RenderDriver item={jobState} />
                                        : null
                                    }
                                    {
                                        jobState.status != "quote" && jobState.status != "request" ?
                                            <View style={{ flexDirection: 'column' }}>
                                                <RenderAccepted item={jobState} />
                                                <RenderOnRoute item={jobState} />
                                                <RenderArrived item={jobState} />
                                                <RenderPendingTime item={jobState} />
                                            </View>
                                            : null
                                    }
                                </View>
                            </View>
                        </View>
                    </View>
                </Card>
                <Button
                    style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 5,
                        borderTopRightRadius: 5,
                        height: "93%",
                    }}
                    appearance='outline'
                    status='info'
                    onPress={() => fn_zoomToMarker(jobState)}
                >
                    <ForwardIcon />
                </Button>
            </View>
            <View style={styles.multiDestination}>
                {(showTowingJobOptions || jobState.details?.towing_details) &&
                    <>
                        {RenderMemoizedTowingLocations}
                    </>
                }
            </View>
        </View>
    )
}


const RenderIsScheduled = ({ item }: any) => {
    if (item.is_scheduled) {
        let localDateTime = new Date(item.start_time).toLocaleString()
        let date = new Date(item.start_time)
        let localDate: any = formatTimestamp(date);
        return (
            <Text numberOfLines={1} style={{ fontSize: 12, backgroundColor: "green", textAlign: 'center', borderRadius: 30 }}>
                Scheduled : <span style={{ fontSize: 12 }}>{localDate}</span>
            </Text>
        )
    } else {
        return (
            null
        )
    }
}

const RenderRouteSummaryModal = ({ job, visible, setVisible }: { job: Job, visible: boolean, setVisible: (visible: boolean) => void }) => {

    const [route, setRoute] = useState<Route | null>(null)
    const { width, height } = useWindowDimensions()
    useEffect(() => {
        loadRoute()
    }, [job, visible])

    const loadRoute = async () => {
        if (!job.route_id) return
        const route = await RouteController.getRouteById(job.route_id) as Route
        if (!route) return
        setRoute(route)
    }

    if (!route) return null

    return (
        <DMModal
            visible={visible}
            onClose={() => setVisible(false)}
            style={{ maxWidth: width * 0.9, maxHeight: height * 0.9 }}
        >
            <ScrollView>
                <RouteSummary
                    route={route as Route}
                    routeReadyForCreation={false}
                    onCreateRoute={() => { }}
                    onAssignDriver={() => { }}
                    onUpdatePlannedStartTime={() => { }}
                    onSelectRoute={() => { }}
                    onSelectJobId={() => { }}
                    onUpdateRouteStatus={() => { }}
                    onUpdateRoute={() => { }}
                    selectedRouteIndex={0}
                />
            </ScrollView>
        </DMModal>
    )
}




const RenderTowingDetails = ({
    job = new Job() as Job
}) => {
    const towing_details = job?.details?.towing_details
    if (!towing_details) return null

    // get if vehicle or equipment
    const buildDescription = (details: any, fields: any) => {
        if (!details) return "";
        return fields
            .map((field: any) => details[field] || "")
            .filter((value: any) => value !== "")
            .join(" | ");
    };
    let description = ""
    if (towing_details?.towing_type?.toLowerCase() === "equipment" || towing_details?.towing_type == TOWING_TYPE.EQUIPMENT) {
        description = buildDescription(towing_details?.equipment_details, ["equipment", "serial", "size", "type", "weight"]);
    }

    if (towing_details?.towing_type?.toLowerCase() === "vehicle" || towing_details?.towing_type == TOWING_TYPE.VEHICLE) {
        description = buildDescription(towing_details?.vehicle_details, ["make", "model", "rego", "type", "year", "colour"]);
    }

    return (
        <View style={{ flexDirection: 'column' }}>
            <View style={{ flexDirection: 'row' }}>
                <Text category="c1" style={{ flex: 1 }}>Towing Type: </Text>
                <Text category="c1" style={{ flex: 1 }}>{towing_details.towing_type}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <Text category="c1" style={{ flex: 1 }}>Description: </Text>
                <Text category="c1" style={{ flex: 1 }}>{description}</Text>
            </View>
        </View>
    )
}


const RenderTowingLocations = ({ job }: { job: Job }) => {
    const towing_details = new JobTowingDetails(job?.details?.towing_details)
    if (!towing_details) return null
    const distance = (towing_details.getTotalDistance() / 1000).toFixed(2)
    const duration = (towing_details.getTotalDuration() / 60).toFixed(2)


    return (
        <Layout style={{ flexDirection: "row" }}>
            <Layout style={{ flex: 1, flexDirection: "column" }}>
                <View style={{
                    borderBottomRightRadius: 20,
                    borderBottomLeftRadius: 20,
                    borderWidth: 1,
                    borderTopWidth: 0,
                    borderColor: 'black',
                    boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.75)"
                }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text style={{ fontSize: 12 }} category='s1'>Towing</Text>
                        <Text style={{ fontSize: 12 }} category='s1'>Distance - {distance}km</Text>
                        <Text style={{ fontSize: 12 }} category='s1'>Duration - {duration}min</Text>
                        <Text style={{ fontSize: 12 }} category='s1'></Text>
                        <Text style={{ fontSize: 12 }} category='s1'></Text>
                    </View>
                    <View style={{ flex: 1, flexDirection: "column", marginTop: 5 }}>
                        {(towing_details.towing_locations && towing_details.towing_locations.length > 0) &&
                            towing_details.towing_locations.map((towing_location, index) => {
                                return (
                                    <RenderTowingLocation key={index} towing_location={towing_location} />
                                )
                            })
                        }
                    </View>
                </View>
            </Layout>
        </Layout>
    )
}

const RenderTowingLocation = ({ towing_location }: { towing_location: TowingLocation }) => {
    if (!towing_location) return null
    towing_location = new TowingLocation(towing_location)
    let address = towing_location?.address?.addressToString()

    const getLocationTypeDetail = () => {
        let result = ""
        if (towing_location.location_type == JOB_LOCATION_TYPE.ADDRESS) {
            result += `Address: ${towing_location?.address_type}`
        }
        else if (towing_location.location_type == JOB_LOCATION_TYPE.HOLDING) {
            result += `Holding: ${towing_location?.holding_reason}`
        }
        return result
    }

    return (
        <View style={{
            flex: 1,
            flexDirection: "row",
            marginLeft: 5,
            borderTopWidth: 1,
            borderColor: 'black',
        }}>
            <Text style={{ fontSize: 12, flex: 1 }} category='s1'>{towing_location.name}</Text>
            <RenderValidTime style={{ fontSize: 12, flex: 2 }} time={towing_location.time} />
            <Text style={{ fontSize: 12, flex: 2, borderColor: 'black', borderLeftWidth: 1 }}>{getLocationTypeDetail()}</Text>
            <Text numberOfLines={1} style={{ fontSize: 12, flex: 4, borderColor: 'black', borderLeftWidth: 1, borderBottomRightRadius: 22 }}>
                <Icon name="pin-outline" width={14} height={14} fill="grey" />
                {address}
            </Text>
        </View>
    )
}


const RenderValidTime = ({ time, style }: any) => {
    if (time && time > 0) {
        let fTime = formatTimestamp(new Date(time))
        return (
            <Text style={[style, { backgroundColor: 'green', marginLeft: 10 }]}>{fTime}</Text>
        )
    } else {
        return (
            <Text style={[style, { backgroundColor: 'transparent' }]}>N/A</Text>
        )
    }
}


const RenderClientReferenceId = ({ item }: any) => {
    if (item.client_reference_id) {
        return (
            <Text category='s1' style={{ fontWeight: 'bold' }}> - Ref:{item.client_reference_id}</Text>
        )
    } else {
        return null
    }
}




export default React.memo(JobListItem)

const styles = StyleSheet.create({
    card: {
        flex: 1,
        boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.75)",
        marginBottom: 10,
    },
    priority: {
        flexDirection: 'row',
        borderRadius: 20,
        borderWidth: 1,
        borderColor: 'black',
        paddingRight: 5,
        paddingLeft: 5,
        marginTop: 5,
        marginBottom: 5
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    mainContainer: {
        flexDirection: "column",
        flex: 1,
        // marginRight: -10,
        // marginLeft: -10,
        // boxShadow: "0px 5px 5px 0px rgba(0,0,0,0.75)",
    },
    containerRow: {
        flexDirection: "row",
        width: "100%",
        flex: 1,
        marginLeft: -25,
        marginRight: -25,
        marginTop: -15
    },
    dispatchableRow: {
        flexDirection: "row",
        justifyContent: 'space-evenly',
        backgroundColor: '#222b45',
        // marginTop: -24,
        marginRight: -24,
    },
    headerText: {
        flex: 1
    },
    dispatchableInfo: {
        flex: 1,
        padding: 5,
        paddingTop: 0,
        borderRadius: 5,
    },
    headerButtons: {
        flex: 1,
        flexDirection: "row",
        justifyContent: 'flex-end',
        padding: 5,
        paddingTop: 0,
        borderRadius: 5,
        gap: 3
    },
    actionButton: {
        width: "5%",
        marginBottom: 10,
        // backgroundColor: "#3366FF32",
        // borderColor: "#3366FF",
        borderWidth: 1,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    statusLine: {
        width: "2%",
        // marginRight:-10,
        // width: 3,
        // backgroundColor: renderStatusColour(Job.status),
        borderBottomLeftRadius: 5,
        borderTopLeftRadius: 5,
    },
    mainInfoArea: {
        flexDirection: "row",
    },
    basicInfoArea: {
        flex: 2
    },
    scheduleInfo: {
        flex: 1
    },
    timesInfo: {
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        paddingLeft: 5,
        flex: 1,

    },
    actionArea: {
        width: "5%",
        // backgroundColor: "#3366FF",
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    multiDestination: {
        flex: 1,
        marginTop: -10,
        marginBottom: 10,
        width: "100%",
        // flexDirection: "row",
    }
});

