import React, { useState, useEffect, useMemo, useRef } from 'react';
import { View, StyleSheet, FlatList, ScrollView, useWindowDimensions } from 'react-native';
import { Button, Input, Text, Card, List, ListItem, Layout, RadioGroup, Radio, Icon, useTheme, Spinner, Toggle } from '@ui-kitten/components';
import { PricingTier, InventoryCategory, InventoryItem } from '../../models/Inventory.model';
import * as StorageController from '../../functions/storageController';
import DMModal from '../common/Modal';
import Toast from 'react-native-toast-message';
import { TextDetailPill } from '../common/TextDetailPill';

interface PricingTierManagerProps {
    category: InventoryCategory;
    items: InventoryItem[];
    onSave: (updatedCategory: InventoryCategory) => void;
    onClose: () => void;
}

export const PricingTierManager: React.FC<PricingTierManagerProps> = ({ category, items, onSave, onClose }) => {
    const [pricingTiers, onSetPricingTiers] = useState<PricingTier[]>(
        category.pricing_tiers?.map(tier => ({
            ...tier,
            client_ids: tier.client_ids || []
        })) || []
    );
    // const [pricingTiers, setPricingTiers] = useState<PricingTier[]>([]);
    const pricingTiersRef = useRef(pricingTiers);
    const [newTierName, setNewTierName] = useState('');
    const [editingTierIndex, setEditingTierIndex] = useState<number | null>(null);
    const [modalVisible, setModalVisible] = useState(false);
    const { width, height } = useWindowDimensions();
    const [clients, setClients] = useState<any[]>([]); // Store all clients
    const [viewMode, setViewMode] = useState<'items' | 'clients'>('items');
    const [clientSearchQuery, setClientSearchQuery] = useState('');
    const theme = useTheme();
    const [filteredClients, setFilteredClients] = useState<any[]>([]);
    const [itemSearchQuery, setItemSearchQuery] = useState('');
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        // Fetch all clients
        const company = StorageController.getCurrentCompany();
        if (company && company.clients) {
            // remove clients that are not active
            const activeClients = company.clients.filter(client => !client.deleted);
            setClients(activeClients);
            setFilteredClients(activeClients);
        }
    }, []);

    useEffect(() => {
        const lowercasedQuery = clientSearchQuery.toLowerCase();
        const filtered = clients.filter(client =>
            client.name.toLowerCase().includes(lowercasedQuery)
        );
        setFilteredClients(filtered);
    }, [clientSearchQuery, clients]);


    useEffect(() => {
        pricingTiersRef.current = pricingTiers;
    }, [pricingTiers]);

    // const onSetPricingTiers = (tiers: PricingTier[]) => {
    //     const t = tiers.map(tier => ({
    //         ...tier,
    //         client_ids: tier.client_ids || []
    //     })) || [];
    //     setPricingTiers(t);
    //     pricingTiersRef.current = t;
    // }

    const addTier = () => {
        if (newTierName.trim()) {
            onSetPricingTiers([...pricingTiers, new PricingTier({
                name: newTierName,
                item_prices: {},
                client_ids: [] // Initialize client_ids as an empty array
            })]);
            setNewTierName('');
            setUnsavedChanges(true);
        }
    };

    const removeTier = (index: number) => {
        onSetPricingTiers(pricingTiersRef.current.filter((_, i) => i !== index));
        setUnsavedChanges(true);
    };

    const editTier = (index: number) => {
        setEditingTierIndex(index);
        setModalVisible(true);
    };

    const updateItemPrice = (tierIndex: number, itemId: string, price: number) => {
        const updatedTiers = [...pricingTiersRef.current];
        if (!updatedTiers[tierIndex].item_prices) {
            updatedTiers[tierIndex].item_prices = {};
        }
        updatedTiers[tierIndex].item_prices[itemId] = price;
        onSetPricingTiers(updatedTiers);
        setUnsavedChanges(true);
    };

    const addClientToTier = (tierIndex: number, clientId: string) => {
        const updatedTiers = [...pricingTiersRef.current];
        if (!updatedTiers[tierIndex].client_ids) {
            updatedTiers[tierIndex].client_ids = []; // Initialize if it doesn't exist
        }
        if (!updatedTiers[tierIndex].client_ids.includes(clientId)) {
            // Remove client from other tiers in this category
            updatedTiers.forEach((tier, index) => {
                if (index !== tierIndex && tier.client_ids) {
                    tier.client_ids = tier.client_ids.filter(id => id !== clientId);
                }
            });
            // Add client to the selected tier
            updatedTiers[tierIndex].client_ids.push(clientId);
            onSetPricingTiers(updatedTiers);
            setUnsavedChanges(true);
        }
    };

    const removeClientFromTier = (tierIndex: number, clientId: string) => {
        const updatedTiers = [...pricingTiersRef.current];
        if (updatedTiers[tierIndex].client_ids) {
            updatedTiers[tierIndex].client_ids = updatedTiers[tierIndex].client_ids.filter(id => id !== clientId);
            onSetPricingTiers(updatedTiers);
            setUnsavedChanges(true);
        }
    };

    const handleSave = async () => {
        setSaving(true);
        try {
            const updatedCategory = new InventoryCategory({
                ...category,
                pricing_tiers: pricingTiersRef.current
            });
            await onSave(updatedCategory);
            setUnsavedChanges(false);
            Toast.show({
                type: 'success',
                text1: 'Pricing tiers saved successfully'
            });
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: 'Failed to save pricing tiers'
            });
        } finally {
            setSaving(false);
        }
    };

    const getFilteredItems = () => {
        return items.filter(item =>
            itemSearchQuery === '' ||
            item.name.toLowerCase().includes(itemSearchQuery.toLowerCase())
        );
    };

    const renderItemsView = useMemo(() => (
        <View style={{ flex: 1 }}>
            <View style={styles.searchContainer}>
                <Input
                    placeholder='Search items...'
                    value={itemSearchQuery}
                    onChangeText={setItemSearchQuery}
                    style={styles.searchInput}
                />
            </View>
            <FlatList
                data={getFilteredItems()}
                renderItem={({ item }) => (
                    <View style={styles.itemRow}>
                        <View style={styles.itemColumn}><Text>{item.name}</Text></View>
                        <View style={styles.basePriceColumn}>
                            <Text>${item.default_price.toFixed(2)}</Text>
                        </View>
                        {pricingTiers.map((tier, tierIndex) => (
                            <View key={tierIndex} style={styles.tierColumn}>
                                <Input
                                    placeholder="Price"
                                    defaultValue={tier.item_prices && item._id ? tier.item_prices[item._id]?.toString() || '' : ''}
                                    onChangeText={(text) => updateItemPrice(tierIndex, item._id!, parseFloat(text) || 0)}
                                    keyboardType="numeric"
                                    style={styles.priceInput}
                                />
                            </View>
                        ))}
                        <View style={styles.addTierColumn} />
                    </View>
                )}
                keyExtractor={item => item._id!}
                ItemSeparatorComponent={() => <View style={styles.separator} />}
                ListEmptyComponent={() => (
                    <View style={styles.emptyContainer}>
                        <Text>No items found</Text>
                    </View>
                )}
            />
        </View>
    ), [itemSearchQuery, items, addTier]);

    const renderClientsView = useMemo(() => (
        <View style={{ flex: 1 }}>
            <View style={styles.searchContainer}>
                <Input
                    placeholder="Search clients..."
                    value={clientSearchQuery}
                    onChangeText={setClientSearchQuery}
                    style={styles.searchInput}
                />
            </View>
            <FlatList
                data={filteredClients}
                renderItem={({ item: client }) => (
                    <View style={styles.itemRow}>
                        <View style={styles.itemColumn}><Text>{client.name}</Text></View>
                        {pricingTiers.map((tier, tierIndex) => (
                            <View key={tierIndex} style={styles.tierColumn}>
                                <RadioGroup
                                    selectedIndex={tier.client_ids.includes(client._id) ? 0 : -1}
                                    onChange={(index) => {
                                        if (index === 0) {
                                            addClientToTier(tierIndex, client._id);
                                        } else {
                                            removeClientFromTier(tierIndex, client._id);
                                        }
                                    }}
                                >
                                    <Radio />
                                </RadioGroup>
                            </View>
                        ))}
                        <View style={styles.addTierColumn} />
                    </View>
                )}
                keyExtractor={client => client._id}
                ItemSeparatorComponent={() => <View style={styles.separator} />}
                ListEmptyComponent={() => (
                    <View style={styles.emptyContainer}>
                        <Text>No clients found</Text>
                    </View>
                )}
            />
        </View>
    ), [clientSearchQuery, filteredClients, addTier]);

    return (
        <View style={[{ height: height * 0.8 }]}>
            <View style={styles.headerContainer}>
                <View style={styles.headerLeft}>
                    <Text category="h5" style={styles.header}>Pricing Tiers for {category.name}</Text>
                    {unsavedChanges && (
                        <Text status="warning" category="s1" style={styles.unsavedLabel}>
                            Unsaved Changes
                        </Text>
                    )}
                </View>
                <View style={styles.headerRight}>
                    <Button
                        appearance="ghost"
                        status="danger"
                        onPress={onClose}
                        accessoryLeft={<Icon name='close-outline' />}
                    >
                    </Button>
                </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: -1 }}>
                    <Button
                        appearance={viewMode === 'items' ? 'filled' : 'outline'}
                        onPress={() => setViewMode('items')}
                        status={'success'}
                    >
                        Items
                    </Button>
                    <Button
                        appearance={viewMode === 'clients' ? 'filled' : 'outline'}
                        onPress={() => setViewMode('clients')}
                        status={'warning'}
                    >
                        Clients
                    </Button>
                </View>
            </View>
            <View style={{ flex: 1, borderWidth: 1, borderColor: viewMode === 'items' ? theme['color-success-500'] : theme['color-warning-500'], borderRadius: 10, padding: 10 }}>
                <ScrollView horizontal style={{ flex: 1 }} stickyHeaderIndices={[0]}>
                    <View style={{ flex: 1 }}>
                        <View style={styles.headerRow}>
                            <View style={styles.itemColumn}>
                                <Text>{viewMode === 'items' ? 'Item' : 'Client'}</Text>
                            </View>
                            {viewMode === 'items' && (
                                <View style={styles.basePriceColumn}><Text>Base Price</Text></View>
                            )}
                            {pricingTiers.map((tier, index) => (
                                <View key={index} style={styles.tierColumn}>
                                    <Text>{tier.name}</Text>
                                    {tier.is_gst_inclusive && <TextDetailPill
                                        value='Inclusive'
                                        label='GST'
                                        // valueStyle={{ color: theme['color-success-500'] }}
                                        valueThemeColor={theme['color-success-500']}
                                    />}
                                    {!tier.is_gst_inclusive &&
                                        <TextDetailPill
                                            value='Exclusive'
                                            label='GST'
                                            // valueStyle={{ color: theme['color-warning-500'] }}
                                            valueThemeColor={theme['color-warning-500']}
                                        />
                                    }
                                    <View style={{ flexDirection: 'row', gap: 5 }}>
                                        <Button
                                            size="tiny"
                                            onPress={() => editTier(index)}
                                            accessoryLeft={<Icon name='edit-outline' />}
                                        />
                                        <Button
                                            size="tiny"
                                            status="danger"
                                            onPress={() => removeTier(index)}
                                            accessoryLeft={<Icon name='trash-outline' />}
                                        />
                                    </View>
                                </View>
                            ))}
                            <View style={styles.addTierColumn}>
                                <Input
                                    size="small"
                                    placeholder="New tier name"
                                    value={newTierName}
                                    onChangeText={setNewTierName}
                                    style={styles.input}
                                />
                                <Button size="tiny" onPress={addTier}>Add Tier</Button>
                            </View>
                        </View>
                        {viewMode === 'items' ? renderItemsView : renderClientsView}
                    </View>
                </ScrollView>
            </View>
            <View style={[styles.buttonContainer, { gap: 10 }]}>
                <View style={{ flexDirection: 'row' }}>

                </View>
                <View style={{ flexDirection: 'row', gap: 5 }}>

                    <View style={styles.saveButtonContainer}>
                        <Button
                            status='success'
                            onPress={handleSave}
                            accessoryLeft={<Icon name='save-outline' />}
                            disabled={saving || !unsavedChanges}
                        >
                            Save Changes
                        </Button>
                        {saving && <Spinner size='small' status='success' />}
                    </View>
                    <Button
                        appearance="ghost"
                        status="danger"
                        onPress={onClose}
                    >
                        close
                    </Button>
                </View>
            </View>

            <DMModal
                visible={modalVisible}
                onClose={() => setModalVisible(false)}
            >
                <View style={[styles.modalContainer]}>
                    <Layout style={{ padding: 16, borderRadius: 16 }}>
                        <PricingTierEditor
                            tier={editingTierIndex !== null ? pricingTiers[editingTierIndex] : new PricingTier()}
                            clients={clients}
                            onSave={(updatedTier: PricingTier) => {
                                console.log("🚀============== ~ file: PricingTierManager.component.tsx:372 ~ updatedTier🚀==============", updatedTier)
                                if (editingTierIndex !== null) {
                                    const updatedTiers = [...pricingTiers];
                                    updatedTiers[editingTierIndex] = new PricingTier({
                                        ...updatedTier,
                                        item_prices: updatedTier.item_prices || {},
                                        client_ids: updatedTier.client_ids || [],
                                        is_gst_inclusive: updatedTier.is_gst_inclusive
                                    });
                                    onSetPricingTiers(updatedTiers);
                                    setUnsavedChanges(true);
                                }
                                setModalVisible(false);
                            }}
                            onCancel={() => setModalVisible(false)}
                            addClientToTier={(clientId) => addClientToTier(editingTierIndex!, clientId)}
                            removeClientFromTier={(clientId) => removeClientFromTier(editingTierIndex!, clientId)}
                        />
                    </Layout>
                </View>
            </DMModal>
        </View>
    );
};

interface PricingTierEditorProps {
    tier: PricingTier;
    clients: any[];
    onSave: (updatedTier: PricingTier) => void;
    onCancel: () => void;
    addClientToTier: (clientId: string) => void;
    removeClientFromTier: (clientId: string) => void;
}

const PricingTierEditor: React.FC<PricingTierEditorProps> = ({
    tier,
    clients,
    onSave,
    onCancel,
    addClientToTier,
    removeClientFromTier
}) => {
    const [editedTier, setEditedTier] = useState(new PricingTier({
        ...tier,
        client_ids: tier.client_ids || [],
        is_gst_inclusive: tier.is_gst_inclusive ?? true,
        item_prices: tier.item_prices || {},
        name: tier.name || '',
        description: tier.description || ''
    }));
    const [availableClients, setAvailableClients] = useState<any[]>([]);
    const { width, height } = useWindowDimensions();

    const editedTierRef = useRef(editedTier);

    const onSetEditedTier = (updatedTier: PricingTier) => {
        console.log("🚀============== ~ file: PricingTierManager.component.tsx:428 ~ onSetEditedTier ~ updatedTier🚀==============", updatedTier)
        setEditedTier(new PricingTier(updatedTier));
        editedTierRef.current = new PricingTier(updatedTier);
    }

    useEffect(() => {
        setAvailableClients(clients.filter(client => !editedTier.client_ids.includes(client._id)));
    }, [clients, editedTier.client_ids]);

    const handleSave = () => {
        const updatedTier = new PricingTier({
            ...editedTierRef.current,
            client_ids: editedTierRef.current.client_ids,
            is_gst_inclusive: editedTierRef.current.is_gst_inclusive,
            item_prices: editedTierRef.current.item_prices,
            name: editedTierRef.current.name,
            description: editedTierRef.current.description
        });
        onSave(updatedTier);
    };

    const handleAddClient = (clientId: string) => {
        addClientToTier(clientId);
        setEditedTier(prevTier => ({
            ...prevTier,
            client_ids: [...(prevTier.client_ids || []), clientId]
        }));
    };

    const handleRemoveClient = (clientId: string) => {
        removeClientFromTier(clientId);
        setEditedTier(prevTier => ({
            ...prevTier,
            client_ids: (prevTier.client_ids || []).filter(id => id !== clientId)
        }));
    };

    return (
        <ScrollView style={{ flex: 1 }}>
            <Input
                label="Tier Name"
                value={editedTier.name}
                onChangeText={(text) => onSetEditedTier({ ...editedTier, name: text })}
            />
            <Input
                label="Description"
                value={editedTier.description}
                onChangeText={(text) => onSetEditedTier({ ...editedTier, description: text })}
            />
            <View style={{ flexDirection: 'row', alignItems: 'center', marginVertical: 10 }}>
                <Text category="s1" style={{ flex: 1 }}>GST Inclusive Pricing</Text>
                <Toggle
                    checked={editedTier.is_gst_inclusive}
                    onChange={(checked) => {
                        console.log("🚀============== ~ file: PricingTierManager.component.tsx:481 ~ checked🚀==============", checked)
                        onSetEditedTier({ ...editedTier, is_gst_inclusive: checked })
                    }}
                />
            </View>
            <Button onPress={handleSave}>Save</Button>
            <Button appearance="ghost" status="basic" onPress={onCancel}>Cancel</Button>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16,
    },
    header: {
        marginBottom: 16,
    },
    headerRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#ccc',
        paddingBottom: 8,
    },
    itemRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor: '#eee',
        paddingVertical: 8,
    },
    itemColumn: {
        width: 300,
        justifyContent: 'center',
    },
    basePriceColumn: {
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
    },
    tierColumn: {
        width: 120,
        alignItems: 'center',
    },
    addTierColumn: {
        width: 150,
        alignItems: 'center',
    },
    input: {
        width: '100%',
    },
    priceInput: {
        width: 80,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 16,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    toggleContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 16,
    },
    searchContainer: {
        marginBottom: 16,
        flexDirection: 'row',
        gap: 8,
    },
    searchInput: {
        flex: 1,
    },
    separator: {
        height: 1,
        backgroundColor: '#eee',
    },
    emptyContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    headerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 16,
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 16,
    },
    unsavedLabel: {
        backgroundColor: '#fff3cd',
        padding: 4,
        borderRadius: 4,
        color: '#856404',
    },
    saveButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
});
