import React from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle } from '@ui-kitten/components';
import { JobOptions } from '../../models/Company.model';

interface JobOptionsProps {
    jobOptions: JobOptions;
    onUpdateJobOptions: (updatedOptions: JobOptions) => void;
    style?: any;
}

export const JobOptionsComponent: React.FC<JobOptionsProps> = ({
    jobOptions,
    onUpdateJobOptions,
    style = {}
}) => {
    const updateJobOption = (key: keyof JobOptions, value: boolean) => {
        const updatedOptions = new JobOptions({
            ...jobOptions,
            [key]: value
        });
        onUpdateJobOptions(updatedOptions);
    };

    const RenderToggle = (label: string, key: keyof JobOptions) => (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
            <Text category='s1'>{label}</Text>
            <Toggle
                checked={jobOptions[key]}
                onChange={(nextChecked) => updateJobOption(key, nextChecked)}
            />
        </View>
    );

    return (
        <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Job Options</Text>} status="basic">
            <View style={{ flexDirection: 'column' }}>
                {RenderToggle('Show Roadside Job Options', 'showRoadsideJobOptions')}
                {RenderToggle('Show Transport Job Options', 'showTransportJobOptions')}
                {RenderToggle('Show Towing Job Options', 'showTowingJobOptions')}
            </View>
        </Card>
    );
};
