import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as HoldingController from "../../../functions/holding.controller"
import * as StorageController from "../../../functions/storageController"
import * as JobController from "../../../functions/job.controller"
import { Holding } from "../../../models/Holding.model";
import { Job, JobAddress, JobLocation, LineItem } from "../../../models/Job.model";
import { ReportDetailsCard } from '../details/reportDetailsCard.component';
import { CreateJobFromHolding } from '../modals/createJobFromHoldingModal.component';
import { InventoryDetailsCard } from '../details/inventory/inventoryDetailsCard.component';
import { InvoicedTotalCard } from '../details/jobInvoicedTotalDetailsCard.component';
import * as ClientController from '../../../functions/client.controller'
import { LocationDetailsCard } from '../details/locationDetailsCard.component';
import { formatTimestamp } from '../../../functions/utils';
import { Client } from '../../../models/Client.model';

export const HoldingReport = ({ holding, onClose }: any) => {

    const [holdingState, setHoldingState] = useState(new Holding(holding) || null);
    const [holdingClient, setHoldingClient] = useState(null as Client | null);
    const [holdingJob, setHoldingJob] = useState(null as Job | null);
    const [releasedJob, setReleasedJob] = useState(null as Job | null);

    useEffect(() => {
        setHoldingState(new Holding(holding));
        getClientDetails();
        getJobs();
    }, [holding])

    const getClientDetails = async () => {
        if (holdingState?.client_id) {
            const client = await ClientController.getClientById(holdingState?.client_id)
            setHoldingClient(client)
            console.log("🚀============== ~ file: holdingDetailsContainer.component.js:130 ~ getClientDetails ~ client🚀==============", client)
        }
    }

    const getJobs = async () => {
        if (holdingState.holding_job_id) {
            const job = await JobController.getJobById(holdingState.holding_job_id) as Job
            setHoldingJob(job)
        }
        if (holdingState.released_job_id) {
            const job = await JobController.getJobById(holdingState.released_job_id) as Job
            setReleasedJob(job)
        }
    }


    const RenderClientDetailsCard = ({ style }: any) => {
        return (
            <Card
                disabled={true}
                style={[style]}
                status='primary'
                header={() => <Text category='s1'>Client Details</Text>}
            >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>{holdingClient?.name}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Email: </Text>
                    <Text>{holdingClient?.contact_info?.email}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Phone: </Text>
                    <Text>{holdingClient?.contact_info?.phone}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Address</Text>
                    <Text>{holdingClient?.contact_info?.address?.formatted_address || ""}</Text>
                </View>
            </Card>
        )
    }

    const RenderTimeElapsedCard = ({ style }: any) => {
        return (
            <Card
                disabled={true}
                style={[style]}
                status='primary'
                header={() => <Text category='s1'>Time In Holding</Text>}
            >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>{holdingState.getTimeElapsed().readable}</Text>
                </View>
            </Card>
        )
    }

    const RenderJobDetailsCard = ({ style, job, title }: any) => {
        if (!job) return null
        return (
            <Card
                disabled={true}
                style={[style]}
                status='primary'
                header={() => <Text category='s1'>{title}</Text>}
            >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Job ID: {job.friendly_id}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Service: {job?.getServiceNames}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Status: {job?.status}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Start: </Text>
                    <Text category='s1'>{formatTimestamp(job?.start_time)}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Complete: </Text>
                    <Text category='s1'>{formatTimestamp(job?.pending_time)}</Text>
                </View>
            </Card>
        )
    }

    if (!holdingState) return null;
    return (
        <Card
            disabled={true}
            status='primary'
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='h6'>Holding Report</Text>
                    <Text category='s1' style={{ marginLeft: 10 }}>{holdingState?.friendly_id}</Text>
                    <Button
                        status="danger"
                        onPress={() => { onClose() }}
                    >
                        <Icon name='close-outline' fill='white' width={24} height={24} />
                    </Button>
                </View>
            }
            style={{ flex: 1 }}>
            <ScrollView>
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    <RenderClientDetailsCard style={{ marginBottom: 10, flex: 1 }} />
                    <RenderTimeElapsedCard style={{ marginBottom: 10, flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    <LocationDetailsCard
                        style={{ flex: 1 }}
                        cardTitle={"Holding Location"}
                        address={holdingState.details.towing_details.dropoff_towing_location.address}
                        location={holdingState.details.towing_details.dropoff_towing_location.location}
                        allowEdit={false} onSetAddress={function (address: JobAddress): void {
                        }}
                        onSetLocation={function (location: JobLocation): void {
                        }}
                        searchDisplayValue={''} />
                    <InvoicedTotalCard
                        canEdit={true}
                        //@ts-ignore
                        holding={holdingState}
                        style={{ flex: 1 }}
                    />
                </View>
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    {holdingState.holding_job_id && <RenderJobDetailsCard title={"Holding Job Details"} style={{ flex: 1 }} job={holdingJob} />}
                    {holdingState.released_job_id && <RenderJobDetailsCard title={"Released Job Details"} style={{ flex: 1 }} job={releasedJob} />}
                </View>
            </ScrollView>
        </Card>
    )
}