import React, { useState, useEffect } from 'react';
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import { Modal, Card, Text, Button, useTheme, Layout, Icon } from '@ui-kitten/components';
import { Company } from '../../models/Company.model';
import { InventoryItem } from '../../models/Inventory.model';
import { CartItem } from './PartsNetworkContainerScreen';
import * as StorageController from '../../functions/storageController';
import * as ClientController from '../../functions/client.controller';
import { VendorStore } from './VendorStore';

interface AddItemModalProps {
    visible: boolean;
    onClose: () => void;
    onAddItems: (item: InventoryItem, quantity: number) => void;
    vendorCompany: Company;
    clientCompanyId: string;
    cart: CartItem[];
}

export const AddItemModal: React.FC<AddItemModalProps> = ({
    visible,
    onClose,
    onAddItems,
    vendorCompany,
    clientCompanyId,
    cart
}) => {
    const theme = useTheme();
    const { width, height } = useWindowDimensions();
    const styles = getStyles(theme, width, height);
    const [company, setCompany] = useState<Company | null>(null);
    const [clientCompany, setClientCompany] = useState<Company | null>(null);

    useEffect(() => {
        const company = StorageController.getCurrentCompany();
        setCompany(company);
    }, []);

    //   useEffect(() => {
    //     const fetchClientCompany = async () => {
    //       const clientCompany = await ClientController.getClientByCompanyAndClientLinkingId(vendorCompany._id, clientId);
    //       console.log("🚀============== ~ file: AddItemModal.tsx:42 ~ fetchClientCompany ~ vendorCompany._id, clientId🚀==============", vendorCompany._id, clientId)
    //       console.log("🚀============== ~ file: AddItemModal.tsx:42 ~ fetchClientCompany ~ clientCompany🚀==============", clientCompany)
    //       setClientCompany(clientCompany);
    //     };
    //     fetchClientCompany();
    //   }, [clientId]);


    const handleAddToCart = (item: InventoryItem, quantity: number) => {
        onAddItems(item, quantity);
        onClose();
    };

    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={onClose}
        >
            <Layout style={styles.card}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category="s1">Add Items to Order</Text>
                    <Button
                        onPress={onClose}
                        accessoryLeft={(props) => <Icon {...props} name="close-outline" />}
                        appearance="ghost"
                        status="danger"
                    >
                        Close
                    </Button>
                </View>
                <VendorStore
                    vendor={vendorCompany}
                    clientCompany={clientCompany || { _id: clientCompanyId } as Company}
                    onBack={onClose}
                    addToCart={handleAddToCart}
                    cart={cart}
                />
            </Layout>
        </Modal>
    );
};

const getStyles = (theme: any, width: number, height: number) => StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    card: {
        // flex: 1,
        margin: 2,
        borderRadius: 10,
        padding: 10,
        // maxHeight: '90%',
        width: width * 0.8,
        height: height * 0.8,
    },
    title: {
        marginBottom: 15,
    },
});
