import React, { useState, useEffect, useRef, useReducer, useMemo } from 'react';
class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo)
  }


  render() {
    // @ts-ignore
    if (this.state.hasError ) {
      // You can render any custom fallback UI
      return (<div>
        <h1>Something went wrong.</h1>;
        {/* @ts-ignore */}
        <p>{this.state.error}</p>
        {/* @ts-ignore */}
        <p>{this.state.errorInfo}</p>
      </div>)
    }

    return this.props.children || null;
  }
}
export default ErrorBoundary;