import React from 'react';
import { View } from 'react-native';
import { JobStatusFilterButtons } from '../../job/components/JobStatusFilterButtons';
import { HoldingStatusFilterButtons } from '../../job/HoldingStatusFilterButtons';
import { CaseStatusFilterButtons } from '../../job/CaseStatusFilterButtons';
import { FILTER_TYPE, JOB_STATUS_FILTER } from '../overviewRender.component';
import { HOLDING_STATUS } from '../../../models/Holding.model';
import { CASE_STATUS_FILTER } from '../../job/CaseStatusFilterButtons';
import { Job } from '../../../models/Job.model';
import { Holding } from '../../../models/Holding.model';

interface OverviewFiltersProps {
    filterType: FILTER_TYPE,
    onSetFilterApplied: (filter: JOB_STATUS_FILTER) => void,
    filterApplied: JOB_STATUS_FILTER,
    setShowFilters: (show: boolean) => void,
    fetchedJobsRef: React.MutableRefObject<Job[]>,
    holdingFilterApplied: HOLDING_STATUS,
    setHoldingFilterApplied: (filter: HOLDING_STATUS) => void,
    onClearSearchResults: () => void,
    holdingSearchResults: Holding[],
    caseFilterApplied: CASE_STATUS_FILTER,
    setCaseFilterApplied: (filter: CASE_STATUS_FILTER) => void
}

export const OverviewFilters = ({
    filterType,
    onSetFilterApplied,
    filterApplied,
    setShowFilters,
    fetchedJobsRef,
    holdingFilterApplied,
    setHoldingFilterApplied,
    onClearSearchResults,
    holdingSearchResults,
    caseFilterApplied,
    setCaseFilterApplied
}: OverviewFiltersProps) => {
    return (
        <View style={{ flexDirection: 'row' }}>
            {filterType === FILTER_TYPE.JOB &&
                <JobStatusFilterButtons
                    style={{ flex: 1 }}
                    onSetFilterApplied={onSetFilterApplied}
                    filterApplied={filterApplied}
                    setShowFilters={setShowFilters}
                    fetchedJobsRef={fetchedJobsRef}
                />
            }
            {filterType === FILTER_TYPE.HOLDING &&
                <HoldingStatusFilterButtons
                    holdingFilterApplied={holdingFilterApplied}
                    setHoldingFilterApplied={setHoldingFilterApplied}
                    onClearSearchResults={onClearSearchResults}
                    holdingSearchResults={holdingSearchResults}
                />
            }
            {filterType === FILTER_TYPE.CASE &&
                <CaseStatusFilterButtons
                    caseFilterApplied={caseFilterApplied}
                    setCaseFilterApplied={setCaseFilterApplied}
                />
            }
            {filterType === FILTER_TYPE.ROUTE &&
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }} />
            }
        </View>
    );
}; 