var config = require('../config/config.js');
const axios = require('axios');
import * as StorageController from './storageController';
import { Order, OrderSearchParams, OrderStatus } from '../models/Order.model';
import { Company } from '../models/Company.model';
import { InventoryItem } from '../models/Inventory.model';
import { Job } from '../models/Job.model';

var api = config.api;
var testApi = config.test_api;

async function getApi() {
    if (await StorageController.getUseTestApi()) {
        return testApi;
    }
    return api;
}

axios.interceptors.request.use(
    async (config: any) => {
        let token = await getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
};


// // Order routes
// router.post("/order", OrderController.createOrder);
// router.get("/order", OrderController.getAllOrders);
// router.get("/order/:id", OrderController.getOrderById);
// router.put("/order/:id", OrderController.updateOrder);
// router.delete("/order/:id", OrderController.deleteOrder);
// router.post("/order/search", OrderController.searchOrders);
// router.get("/order/company/:type/:company_id", OrderController.getOrdersByCompany);
// router.get("/order/stats/:type/:company_id", OrderController.getOrderStats);

// Order functions

export async function createOrder(order: Order): Promise<Order | null> {
    try {
        const response = await axios.post(`${await getApi()}/order`, order);
        return new Order(response.data);
    } catch (error) {
        console.error('Error creating order:', error);
        return null;
    }
}

export async function getAllOrders(): Promise<Order[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/order`);
        return response.data.map((order: any) => new Order(order));
    } catch (error) {
        console.error('Error fetching all orders:', error);
        return null;
    }
}

// export interface OrderDetailsResponse {
//     order: any; // Using 'any' for simplicity, but you should define a proper Order interface
//     items: {
//       [inventory_item_id: string]: {
//         item: any; // Define InventoryItem interface
//         category: any; // Define InventoryCategory interface
//         tierPrice?: number;
//       };
//     };
//     clientCompany?: any; // Define Client interface
//   }  

  export interface OrderDetailsResponse {
    order: Order;
    items: {
      [inventory_item_id: string]: OrderItem;
    };
    clientCompany?: any; // Define Company interface
  }
  
  export interface OrderItem {
    item: InventoryItem;
    category: any;
    tierPrice: number;
    quantity: number;
  }
  

export async function getOrderById(id: string): Promise<OrderDetailsResponse | null> {
    try {
        console.log("🚀============== ~ file: order.controller.ts:82 ~ getOrderById ~ id🚀==============", id)
        const response = await axios.get(`${await getApi()}/order/${id}`);
        return {
            order: new Order(response.data.order),
            items: response.data.items,
            clientCompany: response.data.clientCompany
        };
    } catch (error) {
        console.error('Error fetching order by ID:', error);
        return null;
    }
}

export async function updateOrder(id: string, order: Order): Promise<Order | null> {
    try {
        const response = await axios.put(`${await getApi()}/order/${id}`, order);
        return new Order(response.data);
    } catch (error) {
        console.error('Error updating order:', error);
        return null;
    }
}

export async function deleteOrder(id: string): Promise<boolean> {
    try {
        await axios.delete(`${await getApi()}/order/${id}`);
        return true;
    } catch (error) {
        console.error('Error deleting order:', error);
        return false;
    }
}

export async function searchOrders(params: OrderSearchParams): Promise<Order[] | null> {
    try {
        const response = await axios.post(`${await getApi()}/order/search`, params);
        return response.data.map((order: any) => new Order(order));
    } catch (error) {
        console.error('Error searching orders:', error);
        return null;
    }
}

export interface IGetOrdersByCompanyResponse {
    orders: Order[],
    total: number,
    limit: number,
    offset: number
  }

export async function getOrdersByCompany(type: string, company_id: string): Promise<IGetOrdersByCompanyResponse | null> {
    try {
        const response = await axios.get(`${await getApi()}/order/company/${type}/${company_id}`);
        const result =  {
            orders: response.data.orders.map((order: any) => new Order(order)),
            total: response.data.total,
            limit: response.data.limit,
            offset: response.data.offset
        };
        console.log("🚀============== ~ file: order.controller.ts:142 ~ getOrdersByCompany ~ result🚀==============", result)
        return result;
    } catch (error) {
        console.error('Error fetching orders by company:', error);
        return null;
    }
}

export async function getOrderStats(type: string, company_id: string): Promise<any> {
    try {
        const response = await axios.get(`${await getApi()}/order/stats/${type}/${company_id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching order stats:', error);
        return null;
    }
}
interface CreateMarketplacePaymentRequest {
    order_id: string;
    currency: string;
}

export async function processPayment(orderId: string, clientCompany: Company): Promise<any> {
  try {
    const orderDetails = await getOrderById(orderId);
    if (!orderDetails || !orderDetails.order) {
        return {
            success: false,
            message: 'Order not found'
        };
    }

    const order = orderDetails.order;
    const paymentRequest: CreateMarketplacePaymentRequest = {
      order_id: orderId,
      currency: 'aud',
    };

    const response = await axios.post(
      `${await getApi()}/parts_network/stripe/create_marketplace_payment`,
      paymentRequest
    );

    const result = {
        success: true,
        data: response.data,
        checkoutSession: response.data.checkoutSession
    };
    console.log("🚀============== ~ file: order.controller.ts:206 ~ processPayment ~ result🚀==============", result)
    return result;
  } catch (error: any) {
    console.error('Error processing payment:', error);
    return {
      success: false,
      message: error.message || 'An error occurred while processing the payment',
    };
  }
}

// Add this new function to poll for order status
export async function pollOrderStatus(orderId: string): Promise<Order | null> {
  try {
    const orderDetails = await getOrderById(orderId);
    return orderDetails ? orderDetails.order : null;
  } catch (error) {
    console.error('Error polling order status:', error);
    return null;
  }
}


interface CreateJobFromOrderResult {
    success: boolean;
    message?: string;
    job?: Job | null;
    order?: Order | null;
  }

// router.post("/order/job/create/:order_id", OrderController.createJobFromOrder);
export async function createJobFromOrder(orderId: string): Promise<CreateJobFromOrderResult> {
    try {
        const response = await axios.post(`${await getApi()}/order/job/create/${orderId}`);
        return {
            success: true,
            job: new Job(response.data.job),
            order: new Order(response.data.order)
        };
    } catch (error: any) {
        console.error('Error creating job from order:', error);
        return {
            success: false,
            message: error.message || 'An error occurred while creating the job',
        };
    }
}

// Add this interface
export interface CancelOrderResult {
  success: boolean;
  message: string;
  order?: Order | null;
  job?: Job | null;
  error?: any;
}

// Add this new function
export async function cancelOrder(orderId: string): Promise<CancelOrderResult> {
  try {
    const response = await axios.post(`${await getApi()}/order/status/cancel/${orderId}`);
    return {
      success: true,
      message: 'Order cancelled successfully',
      order: new Order(response.data.order),
      job: response.data.job ? new Job(response.data.job) : null
    };
  } catch (error: any) {
    console.error('Error cancelling order:', error);
    return {
      success: false,
      message: error.response?.data?.message || 'An error occurred while cancelling the order',
      error: error
    };
  }
}
