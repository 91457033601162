var config = require('../config/config.js')
const axios = require('axios');
import * as StorageController from './storageController'
import { Job, LineItem, Service } from '../models/Job.model';
import { Holding, HoldingInvoiceDetails } from '../models/Holding.model';
import * as HoldingController from './holding.controller'
import { Invoice, InvoiceItem } from '../models/Invoice.model';
import { Client } from '../models/Client.model';
import { Member } from '../models/Member.model';
var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}


// get jobs by date range
export async function getJobsByDateRange(startDate: any, endDate: any) {
    //get company_id from storage controller
    var companyId = StorageController.getAppState().selectedCompany?._id
    //body
    var body = {
        company_id: companyId,
        start: startDate,
        end: endDate,
    }
    try {
        // const response = await axios.post(getApi() + "/invoice/find/", body);
        const response = await axios.post(getApi() + "/job/find/dates", body);
        const result = response.data.map((job: any) => new Job(job))
        return result as Job[]
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// get holdings by date range
export async function getHoldingsByDateRange(startDate: any, endDate: any) {
    //get company_id from storage controller
    var companyId = StorageController.getAppState().selectedCompany?._id as string
    try {
        // const response = await axios.post(getApi() + "/invoice/find/", body);
        const response = await HoldingController.getHoldingsByDateRange(companyId, startDate, endDate);
        return response as Holding[]
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// get company jobs by member by date range
export async function getJobCompanyMemberByDateRange(memberId: string, startDate: any, endDate: any) {
    //get company_id from storage controller
    var companyId = StorageController.getAppState().selectedCompany?._id
    //body
    var body = {
        company_id: companyId,
        member_id: memberId,
        start: startDate,
        end: endDate,
    }
    try {
        // const response = await axios.post(getApi() + "/invoice/company/member", body);
        const response = await axios.post(getApi() + "/job/find/company/member", body);
        console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

// get company jobs by client by date range
export async function getJobCompanyClientByDateRange(clientId: string, startDate: any, endDate: any) {
    //get company_id from storage controller
    var companyId = StorageController.getAppState().selectedCompany?._id
    //body
    var body = {
        company_id: companyId,
        client_id: clientId,
        start: startDate,
        end: endDate,
    }
    try {
        // const response = await axios.post(getApi() + "/invoice/company/client", body);
        const response = await axios.post(getApi() + "/job/find/company/client", body);
        console.log(response);
        return response.data
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


const addTimezone = (date: Date) => {
    var timezone = date.getTimezoneOffset() * -1
    var newDate = new Date(date.getTime() + (timezone * 60000))
    return newDate
}

export const create_XERO_CSV = (filteredJobs: any[], dueDate: any, clients: Client[], services: Service[], members: Member[]) => {
    let _dueDate = addTimezone(dueDate) // add timezone to due date
    var csv = "";
    var header =
        "*ContactName," +
        "*AccountCode," +
        "*TaxType," +
        "*InvoiceNumber," +
        "*InvoiceDate," +
        "*DueDate," +
        "*Quantity," +
        "*UnitAmount," +
        "Description\n"
    csv += header
    filteredJobs.forEach(job => {
        if (job.details.report?.items) {
            job.details.report.items.forEach((item: any) => {
                job.total = parseFloat(item.cost) * parseFloat(item.quantity)
                var line =
                    (clients.find(client => client._id == job.client_id)?.name || "") + "," + // contact name
                    (clients.find(client => client._id == job.client_id)?.account_code || "") + "," +
                    "GST on Income" + "," +
                    "RCTI (job number)" + "," +
                    job.createdAt.substring(0, 10) + "," +
                    _dueDate.toISOString().substring(0, 10) + "," +
                    item.quantity + "," +
                    job.total + "," +
                    job.details.make + " " + job.details.model + " " + job.details.rego
                csv += line
                csv += "\n"
            })
        } else {
            job.total = job.details?.report?.items?.map(({ cost }: any) => parseFloat(cost)).reduce((acc: any, curr: any) => acc + curr, 0)
            var line =
                (clients.find(client => client._id == job.client_id)?.name || "") + "," + // contact name
                (clients.find(client => client._id == job.client_id)?.account_code || "") + "," +
                "GST on Income" + "," +
                "RCTI (job number)" + "," +
                job.createdAt.substring(0, 10) + "," +
                _dueDate.toISOString().substring(0, 10) + "," +
                "1" + "," +
                0 + "," +
                job.details.make + " " + job.details.model + " " + job.details.rego
            csv += line
            csv += "\n"
        }
    })
    console.log(csv)
    return csv
}

// *InvoiceNo	*Customer	*InvoiceDate	*DueDate	Terms	Location	Memo	*Item(Product/Service)	ItemDescription	ItemQuantity	ItemRate	*ItemAmount

export const create_QUICKBOOKS_CSV = (filteredJobs: any[], dueDate: any, clients: Client[], services: Service[], members: Member[]) => {
    let _dueDate = addTimezone(dueDate) // add timezone to due date
    var csv = "";
    var header =
        "*InvoiceNo," +
        "*Customer," +
        "*InvoiceDate," +
        "*DueDate," +
        "Terms," +
        "Location," +
        "Memo," +
        "*Item(Product/Service)," +
        "ItemDescription," +
        "*ItemQuantity," +
        "*ItemRate," +
        "*ItemAmount\n"
    csv += header

    filteredJobs.forEach(job => {
        const client = clients.find(client => client._id == job.client_id);
        const invoiceDate = new Date(job.createdAt).toISOString().substring(0, 10);
        const dueDate = _dueDate.toISOString().substring(0, 10);
        const invoiceNo = `RCTI-${job._id}`; // Assuming we use job ID as invoice number
        const customer = client?.name || "";
        const terms = ""; // Add terms if available
        const location = ""; // Add location if available
        const memo = `${job.details.make} ${job.details.model} ${job.details.rego}`; // Using vehicle details as memo

        if (job.details.report?.items && job.details.report.items.length > 0) {
            job.details.report.items.forEach((item: any) => {
                const itemAmount = parseFloat(item.cost) * parseFloat(item.quantity);
                const line =
                    `${invoiceNo},` +
                    `${customer},` +
                    `${invoiceDate},` +
                    `${dueDate},` +
                    `${terms},` +
                    `${location},` +
                    `${memo},` +
                    `${item.name || "Service"},` + // Assuming item name is available, otherwise use "Service"
                    `${item.description || ""},` +
                    `${item.quantity},` +
                    `${item.cost},` +
                    `${itemAmount.toFixed(2)}\n`;
                csv += line;
            });
        } else {
            // If no items, create a single line item for the job
            const totalAmount = job.total || 0;
            const line =
                `${invoiceNo},` +
                `${customer},` +
                `${invoiceDate},` +
                `${dueDate},` +
                `${terms},` +
                `${location},` +
                `${memo},` +
                `Service,` +
                `${memo},` +
                `1,` +
                `${totalAmount.toFixed(2)},` +
                `${totalAmount.toFixed(2)}\n`;
            csv += line;
        }
    });

    console.log(csv);
    return csv;
}



export const generateInvoiceHTML = (
    invoiceName = "",
    companyDetails = {} as any,
    customerCostLineItems = [] as LineItem[],
    billBackLineItems = [] as LineItem[],
    lineItems = [] as LineItem[],
    total = 0,
    paidAmount = 0,
    outstandingAmount = 0,
    showPaid = false
) => {
    const isPaid = paidAmount >= total;
    const isCustomerCost = customerCostLineItems.length > 0;
    const isBillBack = billBackLineItems.length > 0;
    const isLineItems = lineItems.length > 0;


    const date = new Date().toLocaleDateString('en-AU', { year: 'numeric', month: 'short', day: 'numeric' });
    // Convert line items to HTML rows
    const pageWidth = 420;
    const font1 = `24px`;
    const font2 = `18px`;
    const font3 = `12px`;
    const font4 = `10px`;

    const inlineTableStyle = `style="width: 100%; border-collapse: collapse;"`;
    const inlineTdStyle = `style="font-size: ${font4}; border: 1px solid black; padding: 8px; text-align: left;"`;
    const inlineTrEvenStyle = `style="background-color: #f2f2f2;"`;
    const inlineTrOddStyle = `style="background-color: #ffffff;"`;

    const containerDivStyle = `style="width: ${pageWidth}px; font-family: Arial, sans-serif; display: flex; flex-direction:column;"`;
    const headerStyle = `style="display: flex; justify-content: space-between; flex-direction:column; border: 1px solid black;"`;
    const headerH1Style = `style="margin: 0; padding: 0; font-size: ${font1}; font-weight: bold;"`;
    const headerH2Style = `style="margin: 0; padding: 0; font-size: ${font2}; font-weight: bold; color: #666;"`;
    const headerH3Style = `style="margin: 0; padding: 0; font-size: ${font3}; font-weight: bold; color: #666;"`;
    const companyDetailsDivStyle = `style="display: flex; flex-direction: column;"`;
    const companyDetailsP1Style = `style="font-size: ${font3}; margin: 0; padding: 0;"`;
    const companyDetailsP2Style = `style="font-size: ${font4}; margin: 0; padding: 0;"`;
    const lineItemsDivStyle = `style="display: flex; flex-direction: column;"`;
    const statusTdStyle1 = `style="font-size: ${font3}; text-align: right; font-weight: bold;"`;
    const statusTdStyle2 = `style="font-size: ${font3}; text-align: right; font-weight: bold; color: ${isPaid ? '#4CAF50' : '#F44336'};"`;
    const statusTdStyle3 = `style="font-size: ${font3}; text-align: right; font-weight: bold; color: #4CAF50;"`;

    const renderDescription = (lineItem: any) => {
        const isCustomerCost = lineItem.customerCost
        const isPaid = lineItem.paid
        const description = lineItem.description
        const result = isCustomerCost && isPaid ?
            `<p>${description} - <span style="font-size: 10px; color: #064; font-weight:bold;">PAID</span></p>
         `
            : description
        return result
    }


    const lineItemsHTML = isLineItems ? lineItems?.map((item: any, index) => `
      <tr ${index % 2 === 0 ? inlineTrEvenStyle : inlineTrOddStyle}>
        <td ${inlineTdStyle}>${renderDescription(item)}</td>
        <td ${inlineTdStyle}>${item?.quantity}</td>
        <td ${inlineTdStyle}>$${Number(item?.cost)?.toFixed(2)}</td>
        <td ${inlineTdStyle}>$${(Number(item.quantity) * Number(item.cost))?.toFixed(2)}</td>
      </tr>
    `).join('') : '';

    const customerCostLineItemsHTML = customerCostLineItems?.map((item: any, index) => {
        return (`
        <tr ${index % 2 === 0 ? inlineTrEvenStyle : inlineTrOddStyle}>
            <td ${inlineTdStyle}>${renderDescription(item)}</td>
            <td ${inlineTdStyle}>${item.quantity}</td>
            <td ${inlineTdStyle}>$${Number(item.cost).toFixed(2)}</td>
            <td ${inlineTdStyle}>$${(Number(item?.quantity) * Number(item?.cost))?.toFixed(2)}</td>
        </tr>
    `)
    }).join('');

    const billBackLineItemsHTML = billBackLineItems?.map((item: any, index) => `
        <tr ${index % 2 === 0 ? inlineTrEvenStyle : inlineTrOddStyle}>
            <td ${inlineTdStyle}>${renderDescription(item)}</td>
            <td ${inlineTdStyle}>${item.quantity}</td>
            <td ${inlineTdStyle}>$${Number(item.cost)?.toFixed(2)}</td>
            <td ${inlineTdStyle}>$${(Number(item?.quantity) * Number(item?.cost))?.toFixed(2)}</td>
        </tr>
    `).join('');

    const customerCostItemTable = isCustomerCost ? `
        <div ${lineItemsDivStyle}>
            <p ${headerH3Style}>Customer Cost</p>
            <table ${inlineTableStyle}>
                <thead>
                    <tr ${inlineTrEvenStyle}>
                        <th ${inlineTdStyle}>Description</th>
                        <th ${inlineTdStyle}>Quantity</th>
                        <th ${inlineTdStyle}>Cost</th>
                        <th ${inlineTdStyle}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    ${customerCostLineItemsHTML}
                </tbody>
            </table>
        </div>
    ` : '';

    const billBackItemTable = isBillBack ? `
        <div ${lineItemsDivStyle}>
            <p ${headerH3Style}>Bill Back</p>
            <table ${inlineTableStyle}>
                <thead>
                    <tr ${inlineTrEvenStyle}>
                        <th ${inlineTdStyle}>Description</th>
                        <th ${inlineTdStyle}>Quantity</th>
                        <th ${inlineTdStyle}>Cost</th>
                        <th ${inlineTdStyle}>Total</th>
                    </tr>
                </thead>
                <tbody>
                    ${billBackLineItemsHTML}
                </tbody>
            </table>
        </div>
    ` : '';





    // Determine payment status
    const paymentStatus = isPaid ? "Paid" : "Unpaid";

    const statusRow = () => {
        if (showPaid) {
            return `
            <tr>
                <td colspan="3" ${statusTdStyle1}>Status:</td>
                <td ${statusTdStyle2}>${paymentStatus}</td>
            </tr>
            `
        } else {
            return ``
        }
    }

    const paidRows = () => {
        if (showPaid) {
            return `
            <tr>
                <td colspan="3" ${statusTdStyle1}>Paid:</td>
                <td ${statusTdStyle3}>$${paidAmount.toFixed(2)}</td>
            </tr>
            <tr>
                <td colspan="3" ${statusTdStyle1}>Outstanding:</td>
                <td ${statusTdStyle2}>$${outstandingAmount.toFixed(2)}</td>
            </tr>
            `
        } else {
            return ``
        }
    }

    let htmlContent = `

                <div ${containerDivStyle}>
                    <div ${headerStyle}>
                        <p ${headerH1Style}>Invoice - ${invoiceName}</p>
                        <p ${headerH2Style}>${date}</p>
                    </div>
                    <br/>
                    <div ${companyDetailsDivStyle}>
                        <p ${companyDetailsP1Style}>${companyDetails.name || ""}</p>
                        <p ${companyDetailsP2Style}>${companyDetails.address || ""}</p>
                        <p ${companyDetailsP2Style}>${companyDetails.phone || ""}</p>
                    </div>
                    <div ${lineItemsDivStyle}>
                        ${customerCostItemTable}
                        ${billBackItemTable}
                        ${lineItemsHTML}
                        <table ${inlineTableStyle}>
                            <tfoot>
                                <tr>
                                    <td colspan="3" style="text-align: right; font-weight: bold;">
                                    Total:
                                    </td>
                                    <td ${statusTdStyle1}>$${total.toFixed(2)}</td>
                                </tr>
                                ${statusRow()}
                                ${paidRows()}
                            </tfoot >
                        </table >
                    </div >
                    </div >

    `;
    return htmlContent;
};

// 622a12af6925f7e172ebfff5