import * as StorageController from './storageController'
import * as UserController from './user.controller'
import * as MembershipController from './membership.controller'
import * as JobController from './job.controller'
import * as ClientController from './client.controller'
import * as ServiceController from './service.controller'
import * as CompanyController from './company.controller'
import * as VendorProfileController from './vendorProfile.controller'
import * as HoldingController from './holding.controller'
import * as CaseController from './case.controller'
import * as RouteController from './route.controller'
import * as MqttService from '../services/mqtt.service'
import * as CompanySubscriptionController from './CompanySubscription.controller'
import { jwtDecode, JwtPayload } from 'jwt-decode'
//@ts-ignore
import _ from 'lodash'
var config = require('../config/config.js')
const axios = require('axios');
//@ts-ignore
import { Dispatcher } from 'flux';
import { Company } from '../models/Company.model'
import { Member } from '../models/Member.model'
import { Job } from '../models/Job.model'
import { Holding } from '../models/Holding.model'
import { Route } from '../models/Route.model'


export interface IAction {
    type?: string;
    data?: any;
    job?: Job;
}

var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}
axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}
setTimeout(() => {
    getSystemInfo()
}, 1000)

export async function getSystemInfo() {
    try {
        const api = getApi()
        console.log("🚀============== ~ file: update.controller.ts:67 ~ getSystemInfo ~ api🚀==============", api)
        let response = await axios.get(getApi() + '/system_info')
        // console.log("🚀============== ~ file: update.controller:18 ~ getSystemInfo ~ response🚀==============", response.data)
        StorageController.appState.systemInfo = response.data
        return response.data
    } catch (e) {
        console.log("getSystemInfo error", e)
        return {}
    }
}

// live update logic
var _hasUpdate = false
var _hasMessageUpdate = false

var _messageChannelsUpdated = [] as any

export const hasUpdate = () => {
    return _hasUpdate
}

export const readyUpdate = () => {
    // dispatchEventStateChange({ type: "DEFAULT" })
    _hasUpdate = true
}



export const hasMessageUpdate = () => {
    return _hasMessageUpdate
}

export const getMessageChannelsUpdated = () => {
    // return _messageChannelsUpdated
    return StorageController.getAppState().unreadChannels
}

export const updateFromMqtt = async (type: string, data: any) => {
    try {
        // console.log("UPDATE FROM MQTT", type, data)
        switch (type) {
            case "DEFAULT" || "ALL":
                console.log("update.controller All MQTT", data)
                updateMembership()
                // updateAllCompanyJobs()
                updateClients()
                updateVendorProfiles()
                updateMembers('MQTT Default TOP')
                updateDrivers('MQTT Default TOP')
                break;
            case "MEMBERSHIP":
                updateMembership()
                break;
            case "JOBS":
                // console.log("UPDATE - JOBS MQTT", data)
                //if member.is_client
                let canUpdate = false
                // console.log("🚀============== ~ file: update.controller.ts:122 ~ updateFromMqtt ~ data🚀==============", data)
                if (data.job) {
                    const member = StorageController.getAppState().selectedMembership
                    if (member && member.is_client) {
                        if (data.job.client_id == member.client_id) {
                            canUpdate = true
                        }
                    } else {
                        canUpdate = true
                    }
                } else {
                    canUpdate = true
                }
                if (canUpdate) {
                    dispatchEventStateChange({ type: STATE_ACTIONS.UPDATED_JOBS, data: data })
                    // dispatchEventJobsUpdated({ type: STATE_ACTIONS.UPDATED_JOBS, data: data, job: data.job })
                }
                break;
            case "HOLDINGS":
                const updatedHolding = new Holding(data.data)
                await HoldingController.updateHoldingInCompany(data.data) // ensuring the company has the updated holding
                dispatchEventStateChange({ type: STATE_ACTIONS.HOLDINGS_UPDATE, data: updatedHolding })
                break;
            case "ROUTE":
                const updatedRoute = new Route(data.route)
                console.log("🚀============== ~ file: update.controller.ts:150 ~ updateFromMqtt ~ updatedRoute🚀==============", updatedRoute)
                await RouteController.updateRouteInCompany(updatedRoute) // ensuring the company has the updated route
                dispatchEventStateChange({ type: STATE_ACTIONS.ROUTES_UPDATE, data: updatedRoute })
                break;
            case "ORDERS":
                dispatchEventStateChange({ type: STATE_ACTIONS.UPDATED_ORDERS, data: data })
                break;
            case "CLIENTS":
                updateClients()
                break;
            case "MEMBERS":
                updateMembers('MQTT')
                break;
            case "MESSAGES":
                dispatchEventStateChange({ type: STATE_ACTIONS.MESSAGE_UPDATE, data: data })
                break;
            case "DRIVERS":
                updateDrivers('MQTT')
                break;
            case "DRIVER_LOCATION":
                if (data.member) {
                    dispatchEventDriverLocationChange({ type: STATE_ACTIONS.UPDATED_DRIVER_LOCATION, data: data })
                }
                if (!data.member_id) break;
                break;
            case "DRIVER_JOB_LOCATION":
                if (data.job) {
                    dispatchEventJobDriverLocationChanged({ type: STATE_ACTIONS.UPDATED_DRIVER_JOB_LOCATION, data: data })
                }
                break;
            default:
                console.log("🚀============== ~ file: update.controller:130 ~ updateFromMqtt ~", "DEFAULT MQTT")
                updateMembership()
                // updateAllCompanyJobs()
                updateClients()
                updateVendorProfiles()
                updateMembers('MQTT Default')
                updateDrivers('MQTT Default')
                break;
        }
    } catch (error) {
        console.error("Error updating from MQTT:", error);
    }
    readyUpdate()
}


/* FLUX STUFF */
// dispatch event when appState is updated

// App state changes
export const eventAppStateChange = new Dispatcher();
export const eventDriverLocationChanged = new Dispatcher();
export const eventJobDriverLocationChanged = new Dispatcher();



// // UNUSED EVENTS BELOW
// export const eventSelectedJobsChanged = new Dispatcher(); // 
// export const eventSelectedDriversChanged = new Dispatcher(); // 
// export const eventJobsUpdated = new Dispatcher();

// //MQTT event
export const eventMqttEvent = new Dispatcher();

// //Job state changes
// export const eventSelectedJobChanged = new Dispatcher();


// //Client state changes
// export const eventSelectedClientChanged = new Dispatcher();

// //Member state changes
// export const eventSelectedMemberChanged = new Dispatcher();
// export const eventSelectedDriverChanged = new Dispatcher();


// //Membership state changes
// export const eventSelectedCompanyChanged = new Dispatcher();
// export const eventSelectedMembershipChanged = new Dispatcher();

// //Message state changes
// export const eventSelectedMessageChanged = new Dispatcher();
// export const eventSelectedMessageChannelChanged = new Dispatcher();
// export const eventMessageReceived = new Dispatcher();

// //User state changes
// export const eventUserChanged = new Dispatcher();




/* Dispatch events */
export const dispatchEventJobDriverLocationChanged = (action: IAction) => {
    eventJobDriverLocationChanged.dispatch(action);
}


export const dispatchEventDriverLocationChange = (action: any) => {
    eventDriverLocationChanged.dispatch(action);
}

// export const dispatchEventSelectedJobChanged = (action: IAction) => {
//     eventSelectedJobChanged.dispatch(action);
// }

// export const dispatchEventSelectedClientChanged = (action: IAction) => {
//     eventSelectedClientChanged.dispatch(action);
// }

// export const dispatchEventSelectedMemberChanged = (action: IAction) => {
//     eventSelectedMemberChanged.dispatch(action);
// }

// export const dispatchEventSelectedDriverChanged = (action: IAction) => {
//     eventSelectedDriverChanged.dispatch(action);
// }

// export const dispatchEventSelectedCompanyChanged = (action: IAction) => {
//     eventSelectedCompanyChanged.dispatch(action);
// }

// export const dispatchEventSelectedMembershipChanged = (action: IAction) => {
//     eventSelectedMembershipChanged.dispatch(action);
// }

// export const dispatchEventSelectedMessageChanged = (action: IAction) => {
//     eventSelectedMessageChanged.dispatch(action);
// }

// export const dispatchEventSelectedMessageChannelChanged = (action: IAction) => {
//     eventSelectedMessageChannelChanged.dispatch(action);
// }

// export const dispatchEventMessageReceived = (action: IAction) => {
//     eventMessageReceived.dispatch(action);
// }

// export const dispatchEventUserChanged = (action: IAction) => {
//     eventUserChanged.dispatch(action);
// }

// export const dispatchEventSelectedJobsChanged = (action: IAction) => {
//     eventSelectedJobsChanged.dispatch(action);
// }

// export const dispatchEventSelectedDriversChanged = (action: IAction) => {
//     eventSelectedDriversChanged.dispatch(action);
// }

// export const dispatchEventJobsUpdated = (action: IAction) => {
//     eventJobsUpdated.dispatch(action);
// }

export const dispatchEventMqtt = (action: IAction) => {
    eventMqttEvent.dispatch(action);
}

eventMqttEvent.register((action: IAction) => {
    // console.log("🚀 ~ file: update.controller:207 ~ action:", action)
    switch (action.type) {
        case MQTT_ACTIONS.CONNECTED:
            // console.log("MQTT CONNECTED")
            break;
        case MQTT_ACTIONS.DISCONNECTED:
            // console.log("MQTT DISCONNECTED")
            break;
        case MQTT_ACTIONS.RECONNECTING:
            // console.log("MQTT RECONNECTING")
            break;
        case MQTT_ACTIONS.ERROR:
            console.log("MQTT ERROR")
            break;
        case STATE_ACTIONS.MQTT_ON_MEMBER_CHAT_READ:
            // console.log("MQTT_ON_MEMBER_CHAT_READ")
            break;
        case STATE_ACTIONS.MEMBER_CHAT_IS_TYPING:
            console.log("MEMBER_CHAT_IS_TYPING")
            break;
        case STATE_ACTIONS.MEMBER_CHAT_UPDATE:
            // console.log("MEMBER_CHAT_UPDATE")
            break;
        default:
            console.log("DEFAULT MQTT EVENT", action)
            break;
    }
})






export const dispatchEventStateChange = (action: IAction) => {
    try {
        try {
            eventAppStateChange.dispatch(action);
        } catch (e) {
            console.log("🚀 ~ file: update.controller:254 ~ dispatchEventStateChange ~ e:", e, action)
            setTimeout(() => {
                eventAppStateChange.dispatch(action);
            }, 0);
        }
    } catch (e) {
        console.log("🚀 ~ file: update.controller:254 ~ dispatchEventStateChange ~ e:", e, action)
    }
}

eventDriverLocationChanged.register((action: IAction) => {
    // console.log("eventDriverLocationChanged", action)
    switch (action.type) {
        case STATE_ACTIONS.UPDATED_DRIVER_LOCATION:
            updateDriverLocation(action.data, 'eventDriverLocationChanged')
            break;
        default:
            // console.log("DEFAULT ACTION", action)
            break;
    }
})




// listen for dispatch events
eventAppStateChange.register((action: IAction) => {
    switch (action.type) {
        case STATE_ACTIONS.SET_APP_STATE:
            // console.log("SET_APP_STATE")
            break;
        case STATE_ACTIONS.DEFAULT:
        // console.log("DEFAULT ACTION", action)
        // console.trace()
        case STATE_ACTIONS.SET_USER:
            // appState.user = action.user;
            break;
        case STATE_ACTIONS.SET_LOGGED_IN:
            // appState.loggedIn = action.loggedIn;
            break;
        case STATE_ACTIONS.SET_SELECTED_MEMBERSHIP:
            // appState.selectedMembership = action.selectedMembership;
            break;
        case STATE_ACTIONS.SET_SELECTED_COMPANY:
            // appState.selectedCompany = action.selectedCompany;
            break;
        case STATE_ACTIONS.SET_SELECTED_JOB:
            // appState.selectedJob = action.selectedJob;
            break;
        case STATE_ACTIONS.SET_SELECTED_MEMBER_TO_EDIT:
            // appState.selectedMemberToEdit = action.selectedMemberToEdit;
            break;
        case STATE_ACTIONS.SET_MEMBER_LIST:
            // appState.memberList = action.memberList;
            break;
        case STATE_ACTIONS.SET_SELECTED_CLIENT_TO_EDIT:
            // appState.selectedClientToEdit = action.selectedClientToEdit;
            break;
        case STATE_ACTIONS.SET_MESSAGE_CHANNELS:
            // console.log("SET_MESSAGE_CHANNELS", action)
            break;
        case STATE_ACTIONS.SET_SELECTED_CHANNEL:
            // console.log('SET_SELECTED_CHANNEL')
            break;
        case STATE_ACTIONS.SET_UNREAD_CHANNELS:
            // console.log("SET_UNREAD_CHANNELS", action)
            break;
        case STATE_ACTIONS.UPDATED_SELECTED_COMPANY:
            // console.log("UPDATED_SELECTED_COMPANY")
            updateCompanySettings()

            // const services = ServiceController.getServicesByCompanyId(StorageController.getAppState().selectedCompany._id)
            // StorageController.appState.services = services
            break;
        case STATE_ACTIONS.UPDATED_SELECTED_MEMBERSHIP:
            // console.log('UPDATED_SELECTED_MEMBERSHIP')
            break;
        case STATE_ACTIONS.UPDATED_SELECTED_JOB:
            // console.log('UPDATED_SELECTED_JOB')
            break;
        case STATE_ACTIONS.UPDATED_SELECTED_JOBS:
            // console.log('UPDATED_SELECTED_JOBS')
            break;
        case STATE_ACTIONS.UPDATED_SELECTED_CHANNEL:
            // console.log('UPDATED_SELECTED_CHANNEL')
            break;
        case STATE_ACTIONS.UPDATED_CHANNELS:
            // console.log('UPDATED_CHANNELS')
            break;
        case STATE_ACTIONS.CREATED_JOB:
            // console.log('CREATED_JOB')
            break;
        case STATE_ACTIONS.UPDATED_JOBS:
            // console.log("UPDATED_JOBS", action)
            try {

                if (action.data?.job) {
                    JobController.updateJobInCompany(action.data.job)
                }
            } catch (e) {
                console.log("UPDATED_JOBS error", e)
            }
            // JobController.getIncompleteJobsByAllCompanyMemberships() // update all companies

            break;
        case STATE_ACTIONS.ROUTES_UPDATE:
            // console.log("ROUTES_UPDATE")
            break;
        case STATE_ACTIONS.UPDATED_MAP_DIRECTIONS:
            // console.log("MAP_DIRECTIONS_UPDATED")
            break;
        case STATE_ACTIONS.UPDATED_MAP_SHOW_SERVICE_AREA:
            // console.log("UPDATED_MAP_SHOW_SERVICE_AREA")
            break;

        case STATE_ACTIONS.UPDATED_DRIVER_LOCATION:
            // console.log("UPDATED_DRIVER_LOCATION")
            // updateDriverLocation(action.data, 'eventAppStateChange')
            break;
        case STATE_ACTIONS.SET_JOBS_LIST:
            // console.log("SET_JOBS_LIST", action)
            break;
        case STATE_ACTIONS.MESSAGE_UPDATE:
            // console.log("MESSAGE_UPDATE")
            break;
        case STATE_ACTIONS.COMPANY_UPDATE:
            // console.log("COMPANY_UPDATE")
            break;
        case STATE_ACTIONS.MEMBERS_UPDATE:
            // console.log("MEMBERS_UPDATE")
            break;
        case STATE_ACTIONS.DRIVERS_UPDATE:
            // console.log("DRIVERS_UPDATE")
            break;
        case STATE_ACTIONS.CLIENTS_UPDATE:
            // console.log("CLIENTS_UPDATE")
            break;
        case STATE_ACTIONS.LOGGED_IN:
            // console.log("LOGGED_IN")
            break;
        case STATE_ACTIONS.LOGGED_OUT:
            // console.log("LOGGED_OUT")
            break;
        case STATE_ACTIONS.MQTT_EVENT:
            // console.log("MQTT_EVENT")
            break;
        case STATE_ACTIONS.NAVIGATED:
            // console.log("NAVIGATED")
            break;
        case STATE_ACTIONS.UPDATED_UNREAD_MESSAGES:
            // console.log("UPDATED_UNREAD_MESSAGES")
            break;
        case STATE_ACTIONS.HOLDINGS_UPDATE:
            // console.log("HOLDINGS_UPDATE")
            break;
        case STATE_ACTIONS.UPDATED_ORDERS:
            // console.log("UPDATED_ORDERS")
            break;
        default:
            // console.log('Unknown action type: ', action);
            break;
    }
});

export const MQTT_ACTIONS = {
    CONNECTED: 'connected',
    DISCONNECTED: 'disconnected',
    RECONNECTING: 'reconnecting',
    ERROR: 'error',
    MESSAGE: 'message',
    DEFAULT: 'DEFAULT'
}

export const STATE_ACTIONS = {
    SET_APP_STATE: 'SET_APP_STATE',
    SET_USER: 'SET_USER',
    SET_LOGGED_IN: 'SET_LOGGED_IN',
    SET_LOGGED_OUT: 'SET_LOGGED_OUT',
    SET_SELECTED_MEMBERSHIP: 'SET_SELECTED_MEMBERSHIP',
    SET_SELECTED_COMPANY: 'SET_SELECTED_COMPANY',
    SET_SELECTED_JOB: 'SET_SELECTED_JOB',
    SET_SELECTED_MEMBER_TO_EDIT: 'SET_SELECTED_MEMBER_TO_EDIT',
    SET_MEMBER_LIST: 'SET_MEMBER_LIST',
    SET_SELECTED_CLIENT_TO_EDIT: 'SET_SELECTED_CLIENT_TO_EDIT',
    SET_MESSAGE_CHANNELS: 'SET_MESSAGE_CHANNELS',
    SET_SELECTED_CHANNEL: 'SET_SELECTED_CHANNEL',
    SET_UNREAD_CHANNELS: 'SET_UNREAD_CHANNELS',
    SET_SELECTED_CHAT: 'SET_SELECTED_CHAT',
    SET_JOBS_LIST: 'SET_JOBS_LIST',

    UPDATED_SELECTED_COMPANY: 'UPDATED_SELECTED_COMPANY',
    UPDATED_SELECTED_MEMBERSHIP: 'UPDATED_SELECTED_MEMBERSHIP',
    UPDATED_SELECTED_JOB: 'UPDATED_SELECTED_JOB',
    UPDATED_SELECTED_JOBS: 'UPDATED_SELECTED_JOBS',
    UPDATED_SELECTED_DRIVER: 'UPDATED_SELECTED_DRIVER',
    UPDATED_SELECTED_DRIVERS: 'UPDATED_SELECTED_DRIVERS',
    UPDATED_SELECTED_CHANNEL: 'UPDATED_SELECTED_CHANNEL',
    UPDATED_CHANNELS: 'UPDATED_CHANNELS',


    CREATED_JOB: 'CREATED_JOB',
    UPDATED_JOBS: 'UPDATED_JOBS',
    UPDATED_COMPANIES: 'UPDATED_COMPANIES',
    UPDATED_CLIENTS: 'UPDATED_CLIENTS',
    UPDATED_MESSAGE_CHANNELS: 'UPDATED_MESSAGE_CHANNELS',
    UPDATED_DRIVER_LOCATION: 'UPDATED_DRIVER_LOCATION',
    UPDATED_MAP_DIRECTIONS: 'UPDATED_MAP_DIRECTIONS',
    UPDATED_MAP_SHOW_SERVICE_AREA: 'UPDATED_MAP_SHOW_SERVICE_AREA',
    UPDATED_DRIVER_JOB_LOCATION: 'UPDATED_DRIVER_JOB_LOCATION',
    UPDATED_ORDERS: 'UPDATED_ORDERS',

    UPDATED_UNREAD_MESSAGES: 'UPDATED_UNREAD_MESSAGES',

    MQTT_ON_MEMBER_CHAT_READ: 'MQTT_ON_MEMBER_CHAT_READ',
    MEMBER_CHAT_IS_TYPING: 'MEMBER_CHAT_IS_TYPING',
    MEMBER_CHAT_UPDATE: 'MEMBER_CHAT_UPDATE',

    MESSAGE_UPDATE: 'MESSAGE_UPDATE',
    COMPANY_UPDATE: 'COMPANY_UPDATE',
    MEMBERS_UPDATE: 'MEMBERS_UPDATE',
    DRIVERS_UPDATE: 'DRIVERS_UPDATE',
    HOLDINGS_UPDATE: 'HOLDINGS_UPDATE',
    USER_UPDATE: 'USER_UPDATE',
    JOBS_UPDATE: 'JOBS_UPDATE',
    CLIENTS_UPDATE: 'CLIENTS_UPDATE',
    ROUTES_UPDATE: 'ROUTES_UPDATE',
    DEFAULT: 'DEFAULT',

    UPDATED_OTHER_COMPANY: 'UPDATED_OTHER_COMPANY',

    MQTT_EVENT: 'MQTT_EVENT',
    NAVIGATED: 'NAVIGATED',
    UPDATED_COMPANY_THEME: 'UPDATED_COMPANY_THEME',

    LOGGED_OUT: 'LOGGED_OUT',
    LOGGED_IN: 'LOGGED_IN',

    UPDATED_ALL_DATA: 'UPDATED_ALL_DATA',
    VENDOR_PROFILES_UPDATE: 'VENDOR_PROFILES_UPDATE',
};


/* END FLUX STUFF */

const updateCompanySettings = async () => {
    try {
        const company_id = StorageController.getAppState().selectedCompany?._id || null
        if (!company_id) return
        let company = await CompanyController.getCompanyById(company_id) as Company
        //@ts-ignore
        StorageController.appState.selectedCompany.settings = company.settings
    } catch (e) {
        console.log("🚀 ~ file: update.controller:384 ~ updateCompanySettings ~ e", e)
    }
}

export const readyMessageUpdate = (msg: any) => {
    let channel_id = msg.channel_id;
    // if channel_id is not in _messageChannelsUpdated
    // console.log("_messageChannelsUpdated", _messageChannelsUpdated, channel_id);
    if (!_messageChannelsUpdated.includes(channel_id)) {
        if (channel_id != undefined) {
            _messageChannelsUpdated.push(channel_id)
        }
        StorageController.getAppState().unreadChannels = _messageChannelsUpdated
        StorageController.saveStateToDisk()
        // console.log("readyMessageUpdate", _messageChannelsUpdated)
    }
    dispatchEventStateChange({ type: "MESSAGE_UPDATE", data: msg })
    _hasMessageUpdate = true
}

export const messageChannelUpdatedRead = (channel_id: string) => {
    if (_messageChannelsUpdated.indexOf(channel_id) != -1) {
        _messageChannelsUpdated.splice(_messageChannelsUpdated.indexOf(channel_id), 1)
    }
    StorageController.getAppState().unreadChannels = _messageChannelsUpdated
    StorageController.saveStateToDisk()
    // console.log("readyMessageUpdate", _messageChannelsUpdated)
}

export var checkForMessageUpdate = function () {
    if (hasMessageUpdate()) {
        // console.log("Message UPDATE!")
        _hasMessageUpdate = false
        return true
    }
    return false
}


//check for update then set to false
export var checkForUpdate = function () {
    if (_hasUpdate) {
        _hasUpdate = false
        return true
    } else {
        return false
    }
}

//TODO: unused too?
export async function updateIncompleteCompanyJobs() {
    // console.log("updateCompanyJobs")
    var company_id = StorageController.getCurrentCompany()._id
    // var company_id = "622a12af6925f7e172ebfff5"
    var jobs = await JobController.getIncompleteJobsByCompanyId(company_id)
    StorageController.appState.jobs = jobs
    if (StorageController.appState.selectedCompany) {
        StorageController.appState.selectedCompany.jobs = jobs
    }
    for (var company in StorageController.appState.companies) {
        //@ts-ignore
        if (company._id == company_id) {
            //@ts-ignore
            company.jobs = jobs
        }
    }
    StorageController.saveStateToDisk();
    dispatchEventStateChange({ type: STATE_ACTIONS.UPDATED_JOBS, data: jobs })
    return jobs
}

// //TODO:Unused - remove probably??
// export async function updateAllCompanyJobs(limit = 30) {
//     // console.log("updateCompanyJobs")
//     var company_id = StorageController.getAppState().selectedCompany?._id
//     // var company_id = "622a12af6925f7e172ebfff5"
//     var jobs = await JobController.getAllJobsByCompanyId(company_id, limit)
//     console.log("🚀============== ~ file: update.controller:562 ~ updateAllCompanyJobs ~ jobs🚀==============", jobs)
//     StorageController.appState.jobs = jobs
//     for (var company in StorageController.appState.companies) {
//         //@ts-ignore
//         if (company._id == company_id) {
//             //@ts-ignore
//             company.jobs = jobs
//         }
//     }
//     if (StorageController.appState.selectedCompany) {
//         StorageController.appState.selectedCompany.jobs = jobs
//     }
//     StorageController.saveStateToDisk();
//     dispatchEventStateChange({ type: STATE_ACTIONS.UPDATED_JOBS, data: {} })
//     return jobs
// }


// Debounce method for updateDrivers, it should run immediately, but then max every 100ms
export const updateDrivers = _.debounce(_updateDrivers, 300, { leading: true, trailing: true })

export async function _updateDrivers(caller: string = 'unknown') {
    console.log("🚀============== ~ file: update.controller:593 ~ _updateDrivers ~ updateDrivers🚀==============", caller)
    try {
        var company_id = StorageController.getAppState().selectedCompany?._id
        if (!company_id) return
        var drivers = await MembershipController.getDriversByCompanyId(company_id)
        StorageController.appState.drivers = drivers
        StorageController.saveStateToDisk();
        dispatchEventStateChange({ type: STATE_ACTIONS.DRIVERS_UPDATE, data: drivers })
        return drivers
    } catch (e) {
        console.log("updateDrivers error", e)
        return []
    }
}

// update all user memberships, add them to companies, and update companies
export async function updateMembershipsAndCompanies() {
    // console.log("updateMembershipsAndCompanies")
    try {

        var userId = StorageController.getAppState()?.user?._id
        if (!userId) return
        var memberships = await UserController.getMemberships(userId)
        // get companies from StorageController
        var companies = StorageController.getAppState().companies
        // for each company, set membership where id matches
        if (!companies) return
        for (var i = 0; i < companies.length; i++) {
            var company = companies[i]
            var membership = memberships.find((m: Member) => m.company_id == company?._id)
            company.membership = membership
        }
        // for each membership, if the id matches, set that membership
        var membership = memberships.find((m: Member) => m?._id == StorageController.getAppState().selectedMembership?._id)
        StorageController.appState.selectedMembership = membership
        StorageController.saveStateToDisk();
    } catch (e) {
        console.log("updateMembershipsAndCompanies error", e)
    }
}


export async function updateMembership() {
    // console.log("updateMembership")
    try {
        const userId = StorageController.getAppState().user?._id
        if (!userId) return
        var memberships = await UserController.getMemberships(userId)
        // StorageController.appState.user.memberships = memberships
        StorageController.appState.memberships = memberships
        StorageController.saveStateToDisk();
        dispatchEventStateChange({ type: "MEMBERS_UPDATE", data: memberships })
        return memberships
    } catch (e) {
        console.log("updateMembership error", e)
    }
}

export async function updateCompanies() {
    // console.log("updateCompanies")
    var userId = StorageController.getAppState().user._id
    var memberships = await UserController.getMemberships(userId)
    // filter memberships where not deleted or locked
    memberships = memberships?.filter((membership: Member) => {
        return !membership.deleted && !membership.locked
    }) || []
    StorageController.appState.memberships = memberships
    var companies = await CompanyController.getCompaniesFromMemberships(memberships)
    // StorageController.appState.user.companies = companies
    StorageController.saveStateToDisk();
    dispatchEventStateChange({ type: "COMPANY_UPDATE", data: companies })
    return companies
}


// updateVendor profiles
export async function updateVendorProfiles() {
    // console.log("updateVendorProfiles")
    var company_id = StorageController.getAppState().selectedCompany?._id
    if (!company_id) return
    var vendorProfiles = await VendorProfileController.getVendorProfilesByCompanyId(company_id)
    StorageController.appState.vendorProfiles = vendorProfiles
    var companies = StorageController.appState.companies
    if (!companies || companies.length === 0) return
    if (!StorageController.appState.companies) return
    for (var i = 0; i < companies.length; i++) {
        if (StorageController.appState.companies[i]._id == company_id) {
            StorageController.appState.companies[i].vendorProfiles = vendorProfiles
            break
        }
    }
    StorageController.saveStateToDisk();
    dispatchEventStateChange({ type: STATE_ACTIONS.VENDOR_PROFILES_UPDATE, data: vendorProfiles })
    return vendorProfiles
}

//update clients
export async function updateClients() {
    // console.log("updateClients")
    var company_id = StorageController.getAppState().selectedCompany?._id
    if (!company_id) return
    var clients = await ClientController.getClientsByCompanyId(company_id)
    StorageController.appState.clients = clients
    StorageController.saveStateToDisk();
    dispatchEventStateChange({ type: STATE_ACTIONS.CLIENTS_UPDATE })
    return clients
}


// Debounce method for updateMembers, it should run immediately, but then max every 300ms
export const updateMembers = _.debounce(_updateMembers, 300, { leading: true, trailing: true })
// let lastCalled = new Date().getTime()
//update members
export async function _updateMembers(caller: string = 'unknown') {
    try {
        console.log("🚀============== ~ file: update.controller.ts:783 ~ _updateMembers ~ _updateMembers🚀==============", caller)
        var company_id = StorageController.getAppState().selectedCompany?._id
        if (!company_id) return
        var members = await MembershipController.getMembersByCompanyId(company_id)
        if (!StorageController.appState.companies) return
        for (var i = 0; i < StorageController.appState.companies.length; i++) {
            if (StorageController.appState.companies[i]._id == company_id) {
                StorageController.appState.companies[i].members = members
                break
            }
        }
        StorageController.appState.members = members
        StorageController.saveStateToDisk();
        dispatchEventStateChange({ type: STATE_ACTIONS.MEMBERS_UPDATE, data: members })
        return members
    } catch (e) {
        console.log("updateMembers error", e)
    }
}

// update driver location in local storage
export async function updateDriverLocation(data: any, caller: string = 'unknown') {
    try {
        if (data.member.company_id != StorageController.getAppState().selectedCompany?._id) return
        if (!StorageController.getAppState().drivers) return
        if (!StorageController.getAppState().loggedIn) return
        // console.log("updateDriverLocation", data)
        let driver_id = data.member_id
        let location = data.location

        // update driver location in local storage in comapny
        var company_id = StorageController.getAppState().selectedCompany?._id
        if (!company_id) return
        var company = StorageController.getAppState().companies?.find((c) => c._id == company_id)
        if (!company?.members)
            return
        for (var i = 0; i < company.members.length; i++) {
            if (company.members[i]._id == driver_id) {
                // console.log("===driver found ===")
                if (!company.members[i].details) company.members[i].details = {}
                company.members[i].details.location = location
                break
            }
        }
    } catch (err) {
        console.log("🚀============== ~ file: update.controller:768 ~ updateDriverLocation ~ err🚀==============", err)
    }
}

export async function updateDriverLocation_old(data: any) {
    if (!StorageController.getAppState().drivers) return
    if (!StorageController.getAppState().loggedIn) return
    // console.log("updateDriverLocation", data)
    let driver_id = data.member_id
    let location = data.location
    var drivers = StorageController.getAppState().drivers
    // find driver in drivers
    for (var i = 0; i < drivers.length; i++) {
        if (drivers[i]._id == driver_id) {
            console.log("===driver found ===")
            StorageController.getAppState().drivers[i].details.location = location
            break
        }
    }
}


// get selected company from companies
export async function getSelectedCompany() {
    // console.log("getSelectedCompany")
    var company_id = StorageController.getAppState().selectedCompany?._id
    if (!company_id) return
    var company = StorageController.getAppState().companies?.find((c) => c._id == company_id)
    return company
}


// update services
export async function updateServices() {
    // console.log("updateServices")
    var company_id = StorageController.getAppState().selectedCompany?._id
    if (!company_id) return
    const services = await ServiceController.getServicesByCompanyId(company_id)
    StorageController.appState.services = services
    StorageController.saveStateToDisk();
    dispatchEventStateChange({ type: "SERVICES_UPDATE" })
}


export interface IdecodedToken extends JwtPayload {
    user_id: string
}

// Get all user login data for dashboard
export async function getAllCompanyDashboardData() {
    // Load memberships
    if (!StorageController.getAppState().user) return;
    try {
        const access_token = await StorageController.getAccessToken()
        if (!access_token) return
        const decodedToken = jwtDecode(access_token) as IdecodedToken
        const memberships = await UserController.getMemberships(decodedToken.user_id)
        const user = await UserController.getUser(decodedToken.user_id)
        StorageController.appState.memberships = memberships
        StorageController.appState.user = user
        const newDashboardApiCallData = await axios.get(getApi() + "/user/data/dashboard_overview_data")
        if (Object.keys(newDashboardApiCallData.data).length === 0) return
        StorageController.appState.companies = newDashboardApiCallData.data;
        StorageController.saveStateToDisk();
        // create a 1ms timeout
        await new Promise(resolve => setTimeout(resolve, 1));
        dispatchEventStateChange({ type: STATE_ACTIONS.UPDATED_ALL_DATA, data: newDashboardApiCallData.data })
        // retryMqttCreateACLSubscriptions()

        return newDashboardApiCallData.data
    } catch (e) {
        console.log("🚀============== ~ file: update.controller:812 ~ getAllCompanyDashboardData ~ e🚀==============", e)
        return
    }
}

let _isMqttSubscriptionsCreated = false
let retries = 0
const MAX_RETRIES = 15
const debounceRetry = _.debounce(retryMqttCreateACLSubscriptions, 1000, { leading: true, trailing: true })
// Create retry Mqtt create ACL subscriptions. this should be done only once Client is not null
function retryMqttCreateACLSubscriptions() {
    try {
        if (retries >= MAX_RETRIES) {
            MqttService.connect()
            retries = 0
            debounceRetry()
            return
        }
        const mqttClient = MqttService.getClient()
        if (!mqttClient?.connected) {
            retries++
            // enough time to make sure the MQTT client is created
            debounceRetry()
        } else {
            MqttService.createACLSubscriptions()
            _isMqttSubscriptionsCreated = true
            retries = 0
        }
    } catch (e) {
        console.log("🚀============== ~ file: update.controller.ts:838 ~ retryMqttCreateACLSubscriptions ~ e retrying ...🚀==============", e)
        retries++
        debounceRetry()
    }
}