var config = require('../config/config.js');
const axios = require('axios');
import * as StorageController from './storageController';
import { InventoryCategory, InventoryItem, InventoryStock, CustomPricing, ExtendedInventoryItem, ExtendedInventoryCategory, InventoryLocation } from '../models/Inventory.model';

var api = config.api;
var testApi = config.test_api;

async function getApi() {
    if (await StorageController.getUseTestApi()) {
        return testApi;
    }
    return api;
}

axios.interceptors.request.use(
    async (config: any) => {
        let token = await getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
};

// =========================
// Inventory Category
// =========================

export async function createInventoryCategory(category: InventoryCategory): Promise<InventoryCategory | null> {
    try {
        const response = await axios.post(`${await getApi()}/inventory/category`, category);
        return new InventoryCategory(response.data);
    } catch (error) {
        console.error('Error creating inventory category:', error);
        return null;
    }
}

export async function getInventoryCategory(id: string): Promise<InventoryCategory | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/category/${id}`);
        return new InventoryCategory(response.data);
    } catch (error) {
        console.error('Error fetching inventory category:', error);
        return null;
    }
}

export async function updateInventoryCategory(category: InventoryCategory): Promise<InventoryCategory | null> {
    try {
        const response = await axios.put(`${await getApi()}/inventory/category/${category._id}`, category);
        return new InventoryCategory(response.data);
    } catch (error) {
        console.error('Error updating inventory category:', error);
        return null;
    }
}

export async function deleteInventoryCategory(id: string): Promise<boolean> {
    try {
        await axios.delete(`${await getApi()}/inventory/category/${id}`);
        return true;
    } catch (error) {
        console.error('Error deleting inventory category:', error);
        return false;
    }
}

export async function getInventoryCategoriesByCompanyId(company_id: string): Promise<InventoryCategory[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/category/company/${company_id}`);
        console.log("🚀============== ~ file: Inventory.controller.ts:53 ~ getInventoryCategories ~ response.data🚀==============", response.data)
        return response.data.map((category: any) => new InventoryCategory(category));
    } catch (error) {
        console.error('Error fetching inventory categories:', error);
        return null;
    }
}

// Get all categories and items for a company


export async function getInventoryCategoriesAndItemsByCompanyId(company_id: string): Promise<ExtendedInventoryCategory[] | null> {
    try {
        const categoriesData = await getInventoryCategoriesByCompanyId(company_id);
        if (!categoriesData) {
            return [];
        }
        // Assuming the API returns categories with their respective items nested
        const categories: ExtendedInventoryCategory[] = categoriesData.map(categoryData => {
            return new ExtendedInventoryCategory({
                ...categoryData,
                inventoryItems: []
            });
        });

        // Use Promise.all to fetch items for all categories concurrently
        const itemPromises = categories.map(category =>
            category._id ? getInventoryItemsByCategory(category._id) : Promise.resolve([])
        );

        const itemResults = await Promise.all(itemPromises);

        // Assign the results to each category
        categories.forEach((category, index) => {
            category.inventoryItems = itemResults[index] || [];
        });

        console.log("🚀============== ~ file: Inventory.controller.ts:121 ~ getInventoryCategoriesAndItemsByCompanyId ~ categories🚀==============", categories)
        return categories;
    } catch (error) {
        console.error('Error fetching inventory categories and items:', error);
        return null;
    }
}



// =========================
// Inventory Item
// =========================

export async function createInventoryItem(item: InventoryItem): Promise<InventoryItem | null> {
    try {
        const response = await axios.post(`${await getApi()}/inventory/item`, item);
        return new InventoryItem(response.data);
    } catch (error) {
        console.error('Error creating inventory item:', error);
        return null;
    }
}

export async function getInventoryItem(id: string): Promise<InventoryItem | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/item/${id}`);
        return new InventoryItem(response.data);
    } catch (error) {
        console.error('Error fetching inventory item:', error);
        return null;
    }
}

export async function updateInventoryItem(item: InventoryItem): Promise<InventoryItem | null> {
    try {
        const response = await axios.put(`${await getApi()}/inventory/item/${item._id}`, item);
        return new InventoryItem(response.data);
    } catch (error) {
        console.error('Error updating inventory item:', error);
        return null;
    }
}

export async function deleteInventoryItem(id: string): Promise<boolean> {
    try {
        await axios.delete(`${await getApi()}/inventory/item/${id}`);
        return true;
    } catch (error) {
        console.error('Error deleting inventory item:', error);
        return false;
    }
}

export async function getInventoryItemsByCategory(category_id: string): Promise<InventoryItem[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/item/category/${category_id}`);
        return response.data.map((item: any) => new InventoryItem(item));
    } catch (error) {
        console.error('Error fetching inventory items:', error);
        return null;
    }
}

// =========================
// Inventory Stock
// =========================

export async function createInventoryStock(stock: InventoryStock): Promise<InventoryStock | null> {
    try {
        const response = await axios.post(`${await getApi()}/inventory/stock`, stock);
        return new InventoryStock(response.data);
    } catch (error) {
        console.error('Error creating inventory stock:', error);
        return null;
    }
}

export async function getInventoryStockById(id: string): Promise<InventoryStock | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/stock/${id}`);
        return new InventoryStock(response.data);
    } catch (error) {
        console.error('Error fetching inventory stock:', error);
        return null;
    }
}

export async function updateInventoryStock(id: string, stock: InventoryStock): Promise<InventoryStock | null> {
    try {
        const response = await axios.put(`${await getApi()}/inventory/stock/${id}`, stock);
        return new InventoryStock(response.data);
    } catch (error) {
        console.error('Error updating inventory stock:', error);
        return null;
    }
}

export async function deleteInventoryStock(id: string): Promise<boolean> {
    try {
        await axios.delete(`${await getApi()}/inventory/stock/${id}`);
        return true;
    } catch (error) {
        console.error('Error deleting inventory stock:', error);
        return false;
    }
}

export async function getInventoryStocks(company_id: string): Promise<InventoryStock[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/stock/company/${company_id}`);
        return response.data.map((stock: any) => new InventoryStock(stock));
    } catch (error) {
        console.error('Error fetching inventory stocks:', error);
        return null;
    }
}

// router.get("/inventory/stock/item/:inventory_item_id", InventoryController.getInventoryStockByInventoryItemId);
// router.get("/inventory/stock/category/:category_id", InventoryController.getInventoryStockByCategoryId);

export async function getInventoryStockByInventoryItemId(inventory_item_id: string): Promise<InventoryStock[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/stock/item/${inventory_item_id}`);
        return response?.data?.map((stock: any) => new InventoryStock(stock)) || [];
    } catch (error) {
        console.error('Error fetching inventory stock by inventory item id:', error);
        return null;
    }
}

export async function getInventoryStockByCategoryId(category_id: string): Promise<InventoryStock[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/stock/category/${category_id}`);
        return response?.data?.map((stock: any) => new InventoryStock(stock)) || [];
    } catch (error) {
        console.error('Error fetching inventory stock by category id:', error);
        return null;
    }
}



// =========================
// Custom Pricing
// =========================

export async function createCustomPricing(pricing: CustomPricing): Promise<CustomPricing | null> {
    try {
        const response = await axios.post(`${await getApi()}/inventory/custom_pricing`, pricing);
        return new CustomPricing(response.data);
    } catch (error) {
        console.error('Error creating custom pricing:', error);
        return null;
    }
}

export async function getCustomPricing(id: string): Promise<CustomPricing | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/custom_pricing/${id}`);
        return new CustomPricing(response.data);
    } catch (error) {
        console.error('Error fetching custom pricing:', error);
        return null;
    }
}

export async function updateCustomPricing(id: string, pricing: CustomPricing): Promise<CustomPricing | null> {
    try {
        const response = await axios.put(`${await getApi()}/inventory/custom_pricing/${id}`, pricing);
        console.log("🚀============== ~ file: Inventory.controller.ts:252 ~ updateCustomPricing ~ response🚀==============", response)
        return new CustomPricing(response.data);
    } catch (error) {
        console.error('Error updating custom pricing:', error);
        return null;
    }
}

export async function deleteCustomPricing(id: string): Promise<boolean> {
    try {
        await axios.delete(`${await getApi()}/inventory/custom_pricing/${id}`);
        return true;
    } catch (error) {
        console.error('Error deleting custom pricing:', error);
        return false;
    }
}

export async function getCustomPricings(company_id: string): Promise<CustomPricing[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/custom_pricing/company/${company_id}`);
        return response.data?.map((pricing: any) => new CustomPricing(pricing)) || [];
    } catch (error) {
        console.error('Error fetching custom pricings:', error);
        return null;
    }
}

export async function getAllCustomPricesByClient(company_id: string, client_id: string): Promise<CustomPricing[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/custom_pricing/client/${company_id}/${client_id}`);
        console.log("🚀============== ~ file: Inventory.controller.ts:256 ~ getAllCustomPricesByClient ~ response.data🚀==============", response.data)
        return response.data?.map((pricing: any) => new CustomPricing(pricing)) || [];
    } catch (error) {
        console.error('Error fetching custom pricings by client:', error);
        return null;
    }
}

export async function getAllCustomPricesByItem(company_id: string, inventory_item_id: string): Promise<CustomPricing[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/custom_pricing/item/${company_id}/${inventory_item_id}`);
        console.log("🚀============== ~ file: Inventory.controller.ts:267 ~ getAllCustomPricesByItem ~ response.data🚀==============", response.data)
        return response.data?.map((pricing: any) => new CustomPricing(pricing)) || [];
    } catch (error) {
        console.error('Error fetching custom pricings by item:', error);
        return null;
    }
}




/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region INVENORY LOCATION
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

/**
 * router.get("/inventory/stock/location/:location_id", InventoryController.getStockByLocation);
router.post("/inventory/location", InventoryController.createInventoryLocation);
router.get("/inventory/location/:id", InventoryController.getInventoryLocationById);
router.put("/inventory/location/:id", InventoryController.updateInventoryLocation);
router.delete("/inventory/location/:id", InventoryController.deleteInventoryLocation);
router.get("/inventory/location/company/:company_id", InventoryController.getAllInventoryLocationsByCompanyId);
 */

// impolement the above routes

export async function createInventoryLocation(location: InventoryLocation): Promise<InventoryLocation | null> {
    try {
        const response = await axios.post(`${await getApi()}/inventory/location`, location);
        return new InventoryLocation(response.data);
    } catch (error) {
        console.error('Error creating inventory location:', error);
        return null;
    }
}

export async function getInventoryLocationById(id: string): Promise<InventoryLocation | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/location/${id}`);
        return new InventoryLocation(response.data);
    } catch (error) {
        console.error('Error fetching inventory location by id:', error);
        return null;
    }
}

export async function updateInventoryLocation(id: string, location: InventoryLocation): Promise<InventoryLocation | null> {
    try {
        const response = await axios.put(`${await getApi()}/inventory/location/${id}`, location);
        return new InventoryLocation(response.data);
    } catch (error) {
        console.error('Error updating inventory location:', error);
        return null;
    }
}

export async function deleteInventoryLocation(id: string): Promise<boolean> {
    try {
        await axios.delete(`${await getApi()}/inventory/location/${id}`);
        return true;
    } catch (error) {
        console.error('Error deleting inventory location:', error);
        return false;
    }
}

export async function getAllInventoryLocationsByCompanyId(company_id: string): Promise<InventoryLocation[] | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/location/company/${company_id}`);
        return response.data?.map((location: any) => new InventoryLocation(location)) || [];
    } catch (error) {
        console.error('Error fetching inventory locations by company id:', error);
        return null;
    }
}

export interface IInventoryStockResponse {
    success: boolean;
    stocks: InventoryStock[];
    items: InventoryItem[];
    message?: string;
    error?: Error;
}

export async function getStockByLocation(location_id: string): Promise<IInventoryStockResponse | null> {
    try {
        const response = await axios.get(`${await getApi()}/inventory/stock/location/${location_id}`);
        return {
            success: true,
            stocks: response.data.stocks.map((stock: any) => new InventoryStock(stock)),
            items: response.data.items.map((item: any) => new InventoryItem(item))
        } as IInventoryStockResponse;
    } catch (error) {
        console.error('Error fetching stock by location:', error);
        return null;
    }
}

