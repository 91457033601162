import React from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle, Input } from '@ui-kitten/components';
import { CompanyBusinessInfo } from '../../models/Company.model';

interface CompanyBusinessSettingsProps {
    businessInfo: CompanyBusinessInfo;
    onUpdateBusinessInfo: (updatedInfo: CompanyBusinessInfo) => void;
    style?: any;
}

export const CompanyBusinessSettings: React.FC<CompanyBusinessSettingsProps> = ({
    businessInfo,
    onUpdateBusinessInfo,
    style = {}
}) => {
    const updateBusinessInfo = (key: keyof CompanyBusinessInfo, value: string | boolean) => {
        const updatedInfo = new CompanyBusinessInfo({
            ...businessInfo,
            [key]: value
        });
        onUpdateBusinessInfo(updatedInfo);
    };

    return (
        <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Business Settings</Text>} status="basic">
            <View style={{ flexDirection: 'column', gap: 10 }}>
                <Toggle
                    checked={businessInfo.gst_registered}
                    onChange={(nextChecked) => updateBusinessInfo('gst_registered', nextChecked)}
                >
                    GST Registered
                </Toggle>
                {businessInfo.gst_registered && (
                    <Input
                        label="GST Number"
                        placeholder="Enter GST number"
                        value={businessInfo.gst_number}
                        onChangeText={(nextValue) => updateBusinessInfo('gst_number', nextValue)}
                    />
                )}
                <Input
                    label="ABN"
                    placeholder="Enter ABN"
                    value={businessInfo.abn}
                    onChangeText={(nextValue) => updateBusinessInfo('abn', nextValue)}
                />
                <Input
                    label="ACN"
                    placeholder="Enter ACN"
                    value={businessInfo.acn}
                    onChangeText={(nextValue) => updateBusinessInfo('acn', nextValue)}
                />
            </View>
        </Card>
    );
};
