import { LineItem, LINE_ITEM_BILLED_TO } from "./Job.model";

export class InventoryCategory {
    _id!: string | null;
    company_id!: string | null;
    name!: string;
    description!: string | null;
    details!: any;
    is_public!: boolean;
    pricing_tiers: PricingTier[] = [];
    deleted: boolean = false;
    createdAt!: string;
    updatedAt!: string;

    constructor(category: any = {}) {
        this._id = category._id || null;
        this.company_id = category.company_id || null;
        this.name = category.name || '';
        this.description = category.description || null;
        this.details = category.details || {};
        this.is_public = category.is_public || false;
        this.pricing_tiers = (category.pricing_tiers || []).map((tier: any) => new PricingTier(tier));
        this.deleted = category.deleted ?? false;
        this.createdAt = category.createdAt || '';
        this.updatedAt = category.updatedAt || '';
    }
}

export class PricingTier {
    name: string;
    description: string;
    multiplier: number;
    fixed_price: number;
    item_prices: { [key: string]: number };
    client_ids: string[]; // New property to store client IDs

    constructor(tier: any = {}) {
        this.name = tier.name || '';
        this.description = tier.description || '';
        this.multiplier = tier.multiplier || 0;
        this.fixed_price = tier.fixed_price || 0;
        this.item_prices = tier.item_prices || {};
        this.client_ids = tier.client_ids || []; // Initialize client_ids
    }
}



export class InventoryItem {
    _id?: string | null;
    company_id!: string | null;
    inventory_category_id!: string | null;
    name!: string;
    description!: string | null;
    default_price!: number;
    sku!: string | null;
    details!: any;
    purchase_price: number = 0;
    default_sale_price: number = 0;
    is_public: boolean = false;
    surcharge: InventoryItemSurchargeProperties = new InventoryItemSurchargeProperties();
    createdAt?: string;
    updatedAt?: string;

    constructor(item: Partial<InventoryItem> = {}) {
        this._id = item._id
        this.company_id = item.company_id || null;
        this.inventory_category_id = item.inventory_category_id || null;
        this.name = item.name || '';
        this.description = item.description || null;
        this.default_price = item.default_price || 0;
        this.sku = item.sku || null;
        this.details = item.details || {};
        this.purchase_price = item.purchase_price || 0;
        this.default_sale_price = item.default_sale_price || 0;
        this.is_public = item.is_public || false;
        this.surcharge = item.surcharge || new InventoryItemSurchargeProperties();
        this.createdAt = item.createdAt || '';
        this.updatedAt = item.updatedAt || '';
    }

    toLineItem(): LineItem {
        return new LineItem({
            name: this.name,
            description: this.description,
            cost: this.default_price,
            billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
            inventoryItemId: this._id,
            quantity: 1,
        })
    }


}

enum InventoryItemSurchargeType {
    FIXED = "fixed",
    PERCENTAGE = "percentage",
    DEFAULT = "default"
}

export class InventoryItemSurchargeProperties {
    surcharge_type: InventoryItemSurchargeType; // "fixed", "percentage"
    surcharge_value: number;

    constructor(surcharge: Partial<InventoryItemSurchargeProperties> = {}) {
        this.surcharge_type = surcharge.surcharge_type || InventoryItemSurchargeType.DEFAULT;
        this.surcharge_value = surcharge.surcharge_value || 0;
    }
}



export class InventoryStock {
    _id!: string;
    company_id!: string;
    inventory_item_id!: string;
    quantity!: number;
    location!: string | null;
    last_updated!: Date;
    details!: any;
    createdAt!: string;
    updatedAt!: string;

    // populated by mongo
    inventory_item?: InventoryItem | null;

    constructor(stock: any = {}) {
        this._id = stock._id;
        this.company_id = stock.company_id;
        this.inventory_item_id = stock.inventory_item_id;
        this.quantity = stock.quantity;
        this.location = stock.location;
        this.last_updated = stock.last_updated ? new Date(stock.last_updated) : new Date();
        this.details = stock.details || {};
        this.createdAt = stock.createdAt;
        this.updatedAt = stock.updatedAt;

        this.inventory_item = stock.inventory_item ? new InventoryItem(stock.inventory_item) : null;
    }

}


export class CustomPricing {
    _id?: string | null;
    company_id!: string | null;
    client_id?: string | null;
    inventory_item_id!: string | null;
    price!: number;
    start_date?: Date;
    end_date?: Date | null;
    details!: any;
    pricing_tier: string = 'default';
    createdAt?: string;
    updatedAt?: string;

    constructor(pricing: any = {}) {
        this._id = pricing._id
        this.company_id = pricing.company_id || null
        this.client_id = pricing.client_id || null;
        this.inventory_item_id = pricing.inventory_item_id || null;
        this.price = pricing.price || 0;
        this.start_date = pricing.start_date;
        this.end_date = pricing.end_date;
        this.pricing_tier = pricing.pricing_tier || 'default';
        this.details = pricing.details || {};
        this.createdAt = pricing.createdAt || '';
        this.updatedAt = pricing.updatedAt || '';
    }

    toLineItem(name: string, description: string): LineItem {
        return new LineItem({
            name: name,
            description: description,
            cost: this.price,
            billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
            inventoryItemId: this.inventory_item_id,
            quantity: 1,
        })
    }
}



/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// EXTENDED MODELS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/


export class ExtendedInventoryCategory extends InventoryCategory {
    inventoryItems: ExtendedInventoryItem[] = [];

    constructor(category: any = {}) {
        super(category);

        // Initialize inventoryItems if provided
        if (Array.isArray(category.inventoryItems)) {
            this.inventoryItems = category.inventoryItems.map((item: any) => new InventoryItem(item));
        }
    }
}


export class ExtendedInventoryItem extends InventoryItem {
    custom_pricing?: CustomPricing[] = [];
    inventoryStock?: InventoryStock[] = [];

    constructor(item: any = {}) {
        super(item);

        // Initialize custom_pricing if provided
        if (Array.isArray(item.custom_pricing)) {
            this.custom_pricing = item.custom_pricing.map((pricing: any) => new CustomPricing(pricing));
        }

        // Initialize inventoryStock if provided
        if (Array.isArray(item.inventoryStock)) {
            this.inventoryStock = item.inventoryStock.map((stock: any) => new InventoryStock(stock));
        }
    }
}
