import React, { useState, useEffect, useReducer, useCallback, useRef, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, AppState, TouchableOpacity, ScrollView } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, Spinner, RangeDatepicker, CheckBox, List, OverflowMenu, MenuItem, useTheme } from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import debounce from 'lodash.debounce';
import JobsList from '../job/jobsList.component';
import { JobDetailContainer } from '../job/details/jobDetailContainer.component'
// import { JobDetailContainer } from './details/_JobDetailsContainer.component'
import { HoldingList } from '../job/holdingList.component';
import { CaseList } from '../job/casesList.component';
import DriversList from '../Drivers/DriversList.component';
import AssignJobModal from '../job/modals/assignJobModal.component';
import Map from '../map/map.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as ServiceController from '../../functions/service.controller'
import * as HoldingController from '../../functions/holding.controller'
import * as MqttService from '../../services/mqtt.service'
import * as CaseController from '../../functions/case.controller'
import * as RouteController from '../../functions/route.controller'
import * as CompanyController from '../../functions/company.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _log from '../../utils/log'
import CreateJobModal from '../job/modals/createJobModal.component';
import { CreateRouteContainer } from '../routes/createRouteContainer.component';
import { CreateHoldingContainer } from '../job/holding/createHolding.component';
import { HoldingDetailsModalContainer } from '../job/holding/holdingDetailModal';
import { HoldingDetailsContainer } from '../job/holding/holdingDetailsContainer.component';
import { Job, Service, JOB_STATUS } from '../../models/Job.model';
import { Company } from '../../models/Company.model';
import { Member } from '../../models/Member.model';
import { Case } from '../../models/Case.model';
import { HOLDING_STATUS, Holding } from '../../models/Holding.model';
import { Client } from '../../models/Client.model';
import { Route, ROUTE_STATUS } from '../../models/Route.model';
import { JobReportModal, FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { CreateJobFromHoldingModal } from '../job/holding/createJobFromHoldingModal';
import { CalendarContainer } from '../schedule/calendarContainer.components';
import { CreateCaseContainer } from '../cases/createCaseContainer.component';
import { CaseDetailsContainer } from '../cases/caseDetailsContainer.component';
import Toast from 'react-native-toast-message';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
import { RoutesList } from '../routes/routesList.component';
import { JobStatusFilterButtons } from '../job/components/JobStatusFilterButtons';
import { FilterPanel } from '../job/FilterPanel';
import { SearchJobsHoldingsCases, SEARCH_TYPE } from '../job/SearchJobsHoldingsCases';
import { CounterBadge } from '../job/components/CounterBadge'
import { CaseStatusFilterButtons, CASE_STATUS_FILTER } from '../job/CaseStatusFilterButtons'
import { HoldingStatusFilterButtons } from '../job/HoldingStatusFilterButtons'
import { ViewModeButtons } from '../job/ViewModeButtons';
import { LIST_TABS, ListPanelTabs } from '../job/ListPanelTabs';
import DMModal from '../common/Modal';
import { VendorProfile } from '../../models/VendorProfile.model';
import { OverviewActionBar } from './actionBar/actionBar.component';
import { OverviewRender } from './overviewRender.component';


export const OverviewScreen = () => {
    return (
        <OverviewRender />
    )
}