import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Card, Text, Toggle, Input, Modal, Button } from '@ui-kitten/components';
import { CompanyBusinessInfo } from '../../models/Company.model';
import DMModal from '../common/Modal';

interface CompanyBusinessSettingsProps {
    businessInfo: CompanyBusinessInfo;
    onUpdateBusinessInfo: (updatedInfo: CompanyBusinessInfo) => void;
    style?: any;
}

export const CompanyBusinessSettings: React.FC<CompanyBusinessSettingsProps> = ({
    businessInfo,
    onUpdateBusinessInfo,
    style = {}
}) => {
    const [isGSTWarningVisible, setIsGSTWarningVisible] = useState(false);
    const [pendingGSTValue, setPendingGSTValue] = useState<boolean | null>(null);

    const updateBusinessInfo = (key: keyof CompanyBusinessInfo, value: string | boolean) => {
        const updatedInfo = new CompanyBusinessInfo({
            ...businessInfo,
            [key]: value
        });
        onUpdateBusinessInfo(updatedInfo);
    };

    const handleGSTToggle = (nextChecked: boolean) => {
        setPendingGSTValue(nextChecked);
        setIsGSTWarningVisible(true);
    };

    const handleConfirmGSTChange = () => {
        if (pendingGSTValue !== null) {
            updateBusinessInfo('gst_registered', pendingGSTValue);
        }
        setIsGSTWarningVisible(false);
    };

    const handleCancelGSTChange = () => {
        setIsGSTWarningVisible(false);
        setPendingGSTValue(null);
    };

    const getWarningMessages = (isEnabling: boolean) => {
        if (isEnabling) {
            return [
                'Turning this on will not change the default behaviour of any items as is. They will be assumed to have GST included unless explicitly set otherwise in pricing tiers',
                'GST calculations only happen for internal inventory pricing tiers that are marked Excluding GST',
                'GST figures will now appear in the job reports and line items',
                'Once the item is added to a Job, Case, Holding, Order...etc, It is assumed GST is now included',
                'Therefore any items that appear outside DispatchMe Inventory Tiers will always be assumed you\'ve added the correct taxes for the final number',
                'Finally, you are responsible for ensuring you have the correct tax rates set for your items and ensure your accounting system is configured correctly to handle GST as all prices will be assumed you\'ve added the correct taxes for the final number'
            ];
        } else {
            return [
                'Any items marked GST excluded in pricing tiers may still be calculated with GST in the pricing, but the GST calculation will no longer appear in the report',
                'GST figures will be hidden from job reports and line items',
                'All prices displayed will be assumed you\'ve added the correct taxes for the final number',
                'Finally, you are responsible for ensuring you have the correct tax rates set for your items and ensure your accounting system is configured correctly to handle appropriate taxes as all prices will be assumed you\'ve added the correct taxes for the final number'
            ];
        }
    };

    return (
        <>
            <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Business Settings</Text>} status="basic">
                <View style={{ flexDirection: 'column', gap: 10 }}>
                    <Toggle
                        checked={businessInfo.gst_registered}
                        onChange={handleGSTToggle}
                    >
                        GST Registered
                    </Toggle>
                    {businessInfo.gst_registered && (
                        <Input
                            label="GST Number"
                            placeholder="Enter GST number"
                            value={businessInfo.gst_number}
                            onChangeText={(nextValue) => updateBusinessInfo('gst_number', nextValue)}
                        />
                    )}
                    <Input
                        label="ABN"
                        placeholder="Enter ABN"
                        value={businessInfo.abn}
                        onChangeText={(nextValue) => updateBusinessInfo('abn', nextValue)}
                    />
                    <Input
                        label="ACN"
                        placeholder="Enter ACN"
                        value={businessInfo.acn}
                        onChangeText={(nextValue) => updateBusinessInfo('acn', nextValue)}
                    />
                </View>
            </Card>

            <DMModal
                visible={isGSTWarningVisible}
                onClose={handleCancelGSTChange}
            >
                <Card disabled style={styles.modalCard}>
                    <Text category='h6' style={styles.modalTitle}>
                        {pendingGSTValue ? 'Important GST Information' : 'Disabling GST'}
                    </Text>
                    <View style={styles.warningContainer}>
                        {getWarningMessages(pendingGSTValue ?? false).map((warning, index) => (
                            <View key={index} style={styles.warningItem}>
                                <Text style={styles.bullet}>•</Text>
                                <Text style={styles.warningText}>{warning}</Text>
                            </View>
                        ))}
                    </View>
                    <View style={styles.buttonContainer}>
                        <Button
                            onPress={handleCancelGSTChange}
                            appearance='outline'
                            style={styles.button}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={handleConfirmGSTChange}
                            style={styles.button}
                        >
                            I Understand
                        </Button>
                    </View>
                </Card>
            </DMModal>
        </>
    );
};

const styles = StyleSheet.create({
    modalCard: {
        width: '90%',
        maxWidth: 500,
        margin: 2,
    },
    modalTitle: {
        marginBottom: 15,
        textAlign: 'center',
    },
    warningContainer: {
        marginBottom: 15,
    },
    warningItem: {
        flexDirection: 'row',
        marginBottom: 10,
        alignItems: 'flex-start',
    },
    bullet: {
        marginRight: 8,
        fontSize: 16,
    },
    warningText: {
        flex: 1,
        fontSize: 14,
        lineHeight: 20,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 10,
        marginTop: 10,
    },
    button: {
        minWidth: 120,
    },
});
