import React, { useRef, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Button, Card, Icon, List, ListItem, Text } from '@ui-kitten/components';
import { GoogleMap, Marker, Polyline } from '@react-google-maps/api';
import { JobAddress, JobLocation, JobTowingDetails, TowingLocation } from '../../../models/Job.model';
import * as GoogleController from '../../../functions/google.controller';

const mapHeight = 500;
const mapPanelWidth = 200;

const mapContainerStyle = {
    width: "100%",
    height: `${mapHeight}px`
};

// #region Constants
const POLYLINE_COLORS = {
    START: "#00FF00", // Green for start leg
    MAIN: "#0000FF", // Blue for main route segments
    END: "#FF0000",  // Red for end leg
    VARIANTS: ["#4B0082", "#9400D3", "#FF7F00"], // Additional colors for multiple segments
};
// #endregion

interface TowingMapProps {
    jobTowingDetails: JobTowingDetails;
    isLoaded: boolean;
    center: any;
    directionsPolylines: any[];
    directionsPolyline: any;
    onMapClick: (event: any) => void;
    onDragMarker: (location: JobLocation, index: number) => void;
    validateTowingLegs: () => void;
    showLocationSelectInfoWindow: boolean;
    locationSearchResults: JobAddress[] | null;
    onLocationSelect: (location: JobAddress) => void;
    InfoWindowListSelectedIndex: number;
    setInfoWindowListSelectedIndex: (index: number) => void;
    setShowLocationSelectInfoWindow: (show: boolean) => void;
}



export const TowingMap = ({
    jobTowingDetails,
    isLoaded,
    center,
    directionsPolylines,
    directionsPolyline,
    onMapClick,
    onDragMarker,
    validateTowingLegs,
    showLocationSelectInfoWindow,
    locationSearchResults,
    onLocationSelect,
    InfoWindowListSelectedIndex,
    setInfoWindowListSelectedIndex,
    setShowLocationSelectInfoWindow
}: TowingMapProps) => {
    const mapRef = useRef(null);

    const polylineStrokeColourSet = (index: number) => {
        return POLYLINE_COLORS.VARIANTS[index % POLYLINE_COLORS.VARIANTS.length];
    };

    return (
        <Card
            status="info"
            header={() => <Text style={{ alignSelf: 'center' }}>{"Map"}</Text>}
            disabled={true}
            style={{ flex: 1, minWidth: 350 }}
        >
            <View style={{ flexDirection: 'column', margin: -15, marginBottom: 0, gap: 20 }}>
                <View style={{ flexDirection: 'row' }}>
                    {showLocationSelectInfoWindow && (
                        <RenderSelectPlaceResultsSideBar
                            onLocationSelect={(location: JobAddress) => onLocationSelect(location)}
                            locations={locationSearchResults}
                            style={{
                                width: mapPanelWidth,
                                height: mapHeight,
                            }}
                            InfoWindowListSelectedIndex={InfoWindowListSelectedIndex}
                            setInfoWindowListSelectedIndex={setInfoWindowListSelectedIndex}
                            onClose={() => {
                                setInfoWindowListSelectedIndex(0);
                                setShowLocationSelectInfoWindow(false);
                            }}
                        />
                    )}
                    {isLoaded && (
                        <GoogleMap
                            options={{
                                disableDefaultUI: true,
                                zoomControl: true,
                                fullscreenControl: false,
                                streetViewControl: false,
                                mapTypeControl: true,
                                rotateControl: false,
                            }}
                            mapContainerStyle={mapContainerStyle}
                            center={center}
                            zoom={10}
                            onLoad={(map: any) => { mapRef.current = map; }}
                            onUnmount={() => { mapRef.current = null; }}
                            onClick={onMapClick}
                        >
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={{ flex: 1 }}></View>
                                <Button
                                    status='success'
                                    accessoryLeft={(props: any) => <Icon {...props} name='refresh-outline' width={24} height={24} />}
                                    onPress={() => {
                                        setTimeout(() => {
                                            validateTowingLegs();
                                        }, 100);
                                    }}
                                >Re-Calculate Route</Button>
                            </View>

                            {jobTowingDetails.start_location && (
                                <Marker
                                    position={jobTowingDetails.start_location.location}
                                    label="START"
                                    icon={{
                                        url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                                        scaledSize: new window.google.maps.Size(30, 30),
                                    }}
                                />
                            )}

                            {jobTowingDetails.end_location && (
                                <Marker
                                    position={jobTowingDetails.end_location.location}
                                    label="END"
                                    icon={{
                                        url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                                        scaledSize: new window.google.maps.Size(30, 30),
                                    }}
                                />
                            )}

                            {jobTowingDetails.towing_locations.map((towingLocation, index) => (
                                towingLocation.location && (
                                    <Marker
                                        key={index}
                                        position={towingLocation.location}
                                        label={index == 0 ? "Pickup" : index == jobTowingDetails.towing_locations.length - 1 ? "Dropoff" : `Location ${index}`}
                                        draggable={true}
                                        onDragEnd={(event: any) => {
                                            const location = event?.latLng.toJSON();
                                            onDragMarker(new JobLocation(location), index);
                                        }}
                                    />
                                )
                            ))}

                            {/* Start Leg Polyline */}
                            {jobTowingDetails.start_leg?.directions?.polyline && (
                                <Polyline
                                    path={GoogleController.decodePolyline(jobTowingDetails.start_leg.directions.polyline)}
                                    options={{
                                        strokeColor: POLYLINE_COLORS.START,
                                        strokeOpacity: 0.8,
                                        strokeWeight: 4,
                                        icons: [{
                                            offset: "0",
                                            repeat: "10px",
                                        }],
                                    }}
                                />
                            )}

                            {/* Main Route Polylines */}
                            {(directionsPolylines && directionsPolylines.length > 0) && (
                                directionsPolylines.map((polyline, index) => (
                                    <Polyline
                                        key={index}
                                        path={polyline}
                                        options={{
                                            strokeColor: polylineStrokeColourSet(index),
                                            strokeOpacity: 0.8,
                                            strokeWeight: 4,
                                            icons: [{
                                                offset: "0",
                                                repeat: "10px",
                                            }],
                                        }}
                                    />
                                ))
                            )}

                            {/* End Leg Polyline */}
                            {jobTowingDetails.end_leg?.directions?.polyline && (
                                <Polyline
                                    path={GoogleController.decodePolyline(jobTowingDetails.end_leg.directions.polyline)}
                                    options={{
                                        strokeColor: POLYLINE_COLORS.END,
                                        strokeOpacity: 0.8,
                                        strokeWeight: 4,
                                        icons: [{
                                            offset: "0",
                                            repeat: "10px",
                                        }],
                                    }}
                                />
                            )}

                            {directionsPolyline && (
                                <Polyline
                                    path={GoogleController.decodePolyline(directionsPolyline)}
                                    options={{
                                        strokeColor: "#252e56",
                                        strokeOpacity: 0.8,
                                        strokeWeight: 4,
                                        icons: [{
                                            offset: "0",
                                            repeat: "10px",
                                        }],
                                    }}
                                />
                            )}
                        </GoogleMap>
                    )}
                </View>
            </View>
        </Card>
    );
};




interface RenderSelectPlaceResultsSideBarProps {
    locations: JobAddress[] | null;
    style: any;
    onLocationSelect: (location: JobAddress) => void;
    onClose: () => void;
    InfoWindowListSelectedIndex: number;
    setInfoWindowListSelectedIndex: (index: number) => void;
}

const RenderSelectPlaceResultsSideBar = ({
    locations,
    style,
    onLocationSelect,
    onClose,
    InfoWindowListSelectedIndex,
    setInfoWindowListSelectedIndex
}: RenderSelectPlaceResultsSideBarProps) => {
    return (
        <View style={style}>
            <Card
                status="info"
                header={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Icon name='pin-outline' width={24} height={24} fill="white" />
                        <Text style={{ alignSelf: 'center' }}>Select a place</Text>
                        <Button
                            size="tiny"
                            appearance="filled"
                            status="danger"
                            onPress={onClose}>
                            <Icon name='close-outline' width={24} height={24} fill="white" />
                        </Button>
                    </View>
                }
                disabled={true}
                style={{ flex: 1 }}
            >
                <List
                    style={{ margin: -15, height: style.height }}
                    data={locations}
                    renderItem={({ item, index }: { item: JobAddress, index: number }) => (
                        <ListItem
                            title={item.formatted_address}
                            description={item.description}
                            accessoryLeft={() => <Icon name='pin-outline' width={24} height={24} fill="white" />}
                            onPress={() => onLocationSelect(item)}
                        />
                    )}
                />
            </Card>
        </View>
    );
};