import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Card, useTheme } from '@ui-kitten/components';
import { TextDetailPill } from '../common/TextDetailPill';
import Stripe from 'stripe';

interface PaymentDetailsProps {
    paymentDetails: {
        stripe_checkout_session_id: string;
        stripe_checkout_session: Stripe.Checkout.Session;
    };
}

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({ paymentDetails }) => {
    const theme = useTheme();
    const styles = getStyles(theme);

    if (!paymentDetails) {
        return null;
    }

    const { stripe_checkout_session_id, stripe_checkout_session } = paymentDetails;

    const renderPaymentStatus = () => {
        if (!stripe_checkout_session) {
            return (
                <TextDetailPill
                    label="Status"
                    value="Payment processing - Checkout session created"
                    valueStyle={styles.processingText}
                />
            );
        }

        const { payment_status, status, amount_total, currency } = stripe_checkout_session;
        let amount = (amount_total || 0) / 100;
        let date = new Date(stripe_checkout_session.created * 1000).toLocaleString('en-AU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
        return (
            <>
                <TextDetailPill label="Date/Time" value={date} />
                <TextDetailPill label="Payment Status" value={payment_status || ''}
                    valueThemeColor={payment_status === 'paid' ? theme['color-success-800'] : null}
                />
                <TextDetailPill label="Session Status" value={status || ''}
                    valueThemeColor={status === 'complete' ? theme['color-success-800'] : null}
                />
                <TextDetailPill
                    label="Amount"
                    value={`$${(amount).toFixed(2)} ${currency?.toUpperCase() || ''}`}
                    valueThemeColor={amount && amount > 0 ? theme['color-success-900'] : null}
                />
            </>
        );
    };

    return (
        <Card style={styles.container} disabled={true}
            header={() => <Text category="h6" style={styles.title}>Payment Details</Text>}
        >
            {/* <TextDetailPill label="Session ID" value={stripe_checkout_session_id} /> */}
            {renderPaymentStatus()}
            {stripe_checkout_session && stripe_checkout_session.customer_details && (
                <View style={styles.customerDetails}>
                    <Text style={styles.detailsTitle}>Customer Details:</Text>
                    <TextDetailPill
                        label="Name"
                        value={stripe_checkout_session.customer_details.name || ''}
                    />
                    <TextDetailPill
                        label="Email"
                        value={stripe_checkout_session.customer_details.email || ''}
                    />
                </View>
            )}
        </Card>
    );
};

const getStyles = (theme: any) => StyleSheet.create({
    container: {
        marginVertical: 10,
        flex: 1,
    },
    title: {
        marginBottom: 10,
    },
    processingText: {
        color: theme['color-info-500'],
        fontWeight: 'bold',
    },
    customerDetails: {
        marginTop: 10,
    },
    detailsTitle: {
        fontWeight: 'bold',
        marginBottom: 5,
    },
});
