import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import { Layout, Text, Button, Icon } from "@ui-kitten/components";
import { RouteStartEndSelector } from './RouteStartEndSelector';
import { RouteActions } from './RouteActions';
import { ROUTE_START_END_OPTION, RouteLocation } from '../../models/Route.model';
import { Company, CompanySavedAddress } from '../../models/Company.model';
import { ROUTE_ACTION_TYPES } from './createRouteContainer.component';
import { RouteGroupConfig } from './RouteGroupConfig';
import { RouteGroup } from '../../types/route.types';
import * as StorageController from '../../functions/storageController';

const { width, height } = Dimensions.get('window');

interface RouteConfigPanelProps {
    routeGroups: RouteGroup[];
    onUpdateRouteGroups: (groups: RouteGroup[]) => void;
    onSelectAddress: (groupIndex: number, addressType: 'start' | 'end' | 'roundTrip') => void;
    routeAction: ROUTE_ACTION_TYPES;
    setRouteAction: (action: ROUTE_ACTION_TYPES) => void;
    onCalculateRoute: () => void;
    setShowRouteLegsPanel: (show: boolean) => void;
    disableKeepInOrder: boolean;
}

export const RouteConfigPanel: React.FC<RouteConfigPanelProps> = ({
    routeGroups,
    onUpdateRouteGroups,
    onSelectAddress,
    routeAction,
    setRouteAction,
    onCalculateRoute,
    setShowRouteLegsPanel,
    disableKeepInOrder,
}) => {
    const [companySavedAddresses, setCompanySavedAddresses] = useState<CompanySavedAddress[]>([]);

    useEffect(() => {
        const company = StorageController.getCurrentCompany() as Company;
        setCompanySavedAddresses(company.settings.address_book);
    }, []);

    return (
        <Layout style={{ flex: 1, flexDirection: 'row', borderRadius: 10, borderWidth: 1 }}>
            <ScrollView contentContainerStyle={styles.scrollView}>
                <Layout style={styles.section}>
                    <Text category="s1">Route Groups</Text>
                    <RouteGroupConfig
                        routeGroups={routeGroups}
                        onUpdateRouteGroups={onUpdateRouteGroups}
                        companySavedAddresses={companySavedAddresses}
                    />
                </Layout>
            </ScrollView>
            {/* <View style={styles.actionsSection}>
                <RouteActions
                    routeAction={routeAction}
                    setRouteAction={setRouteAction}
                    onCalculateRoute={onCalculateRoute}
                    setShowRouteLegsPanel={setShowRouteLegsPanel}
                    disableKeepInOrder={disableKeepInOrder}
                />
            </View> */}
        </Layout>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        // alignItems: 'center',
        marginBottom: 10,
    },
    title: {
        fontWeight: 'bold',
    },
    scrollView: {
        flexGrow: 1,
    },
    section: {
        padding: 10,
        marginBottom: 10,
        borderRadius: 8,
        maxHeight: height * 0.5,
    },
    actionsSection: {
        padding: 10,
        borderRadius: 8,
    },
});
