import React, { useState, useRef, useMemo, useEffect } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card,
    Input
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import Toast from 'react-native-toast-message';
import * as HoldingController from "../../../functions/holding.controller"
import * as StorageController from "../../../functions/storageController"
import * as JobController from "../../../functions/job.controller"
import { CustomerDetailsCard } from '../details/customerDetailsCard.component';
import { TowingDetailsCard } from '../newJobComponents/towingDetailsCard.component';
import { TowingLocationDetailsCard } from '../newJobComponents/towingLocationDetailsCard.component';
import { ServiceDetailsCard } from '../details/serviceDetailsCard.component';
import { ClientDetailsCard } from '../details/ClientDetailsCard.component';
import { OptionsDetailsCard } from '../details/optionsDetailsCard.component';
import { InventoryDetailsCard } from '../details/inventory/inventoryDetailsCard.component';
import { OtherDetailsCard } from '../details/otherDetailsCard.component';
import { ReportDetailsCard } from '../details/reportDetailsCard.component';
import { LogDetailsCard } from '../details/logDetailsCard.component';
import { InvoicedTotalCard } from '../details/jobInvoicedTotalDetailsCard.component';
import { Job, JobDetails, JobTowingDetails, LineItem, TowingLocation, JobCustomerDetails, JobVehicleDetails, JobEquipmentDetails, Service, JobClientRate, JobOptions } from '../../../models/Job.model';
import { Holding } from '../../../models/Holding.model';


interface CreateJobFromHoldingProps {
    holdingJobId: string,
    holdingItemId: string,
    onCreateJob: (job: Job) => void,
    onCloseModal: () => void,
    visible: boolean
}

export const CreateJobFromHolding = ({
    holdingJobId,
    holdingItemId,
    onCreateJob,
    onCloseModal,
    visible
}: CreateJobFromHoldingProps) => {

    const [JobState, setJob] = useState(null as Job | null) // MAKE NEW JOB TO SAVE, ONLY COPY OVER THE DETAILS
    const [holdingJob, setHoldingJob] = useState(null as Job | null)
    const [holdingJobLogs, setHoldingJobLogs] = useState([] as any[])
    const [holdingState, setHoldingItem] = useState(null as Holding | null)
    // const [towingLocationDetails, setTowingLocationDetails] = useState({ ...TOWING_LOCATION_STRUCT })
    const [showHoldingJobReport, setShowHoldingJobReport] = useState(true)
    const [previousJobItems, setPreviousJobItems] = useState([] as LineItem[])
    const towingLocationDetailsRef = useRef(new TowingLocation({}))
    const { width, height } = Dimensions.get('window')

    const [showNotificationWidget, setShowNotificationWidget] = useState(false)
    const [notificationWidgetProps, setNotificationWidgetProps] = useState({ title: "", text: "" })
    const notificationWidgetTimeoutRef = useRef(null as any)
    const onShowNotificationWidget = (title: string, text: string) => {
        setNotificationWidgetProps({ title, text })
        setShowNotificationWidget(true)
        if (notificationWidgetTimeoutRef.current) clearTimeout(notificationWidgetTimeoutRef.current)
        notificationWidgetTimeoutRef.current = setTimeout(() => {
            setShowNotificationWidget(false)
        }, 2000)
    }


    const RenderNotificationWidget = ({ title, text }: any) => {
        // replacing a toast
        return (
            <Card
                disabled={true}
                style={{ padding: 0, backgroundColor: 'rgba(20,50,0,0.7)' }}
                status='success'
                header={() => <Text>{title}</Text>}
            >
                <View style={{ margin: -15 }}>
                    <Text>{text}</Text>
                </View>
            </Card>
        )
    }

    // create fading animation for notification widget
    const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0
    useEffect(() => {
        Animated.timing(
            fadeAnim,
            {
                toValue: showNotificationWidget ? 1 : 0,
                duration: 500,
                useNativeDriver: true
            }
        ).start();
    }, [showNotificationWidget])



    const JobRef = useRef(JobState)
    const onSetJob = (value: Job, caller = null as string | null) => {
        value = new Job(value)
        console.log("🚀============== ~ file: createJobFromHoldingModal.component.tsx:126 ~ onSetJob ~ value🚀==============", value, caller)
        JobRef.current = value
        setJob(value)
    }

    const onSetHoldingItem = (value: Holding) => {
        value = new Holding(value)
        setHoldingItem(value)
    }

    useEffect(() => {
        if (visible) load()
        else {
            // onSetJob(null)
            // setHoldingItem(null)
            // setTowingLocationDetails({ ...TOWING_LOCATION_STRUCT })
            setHoldingJob(null)
            setHoldingJobLogs([])
        }
    }, [holdingItemId, holdingJobId, visible])


    const load = async () => {
        try {

            const holdingItem = await HoldingController.findByPk(holdingItemId) as Holding
            if (holdingItem) {
                onSetHoldingItem(holdingItem)
            }

            // there may not be a job that is holding the item
            if (holdingJobId) {
                const job = await JobController.getJobById(holdingJobId) as Job
                prefillNewJobFromHoldingJob(job, holdingItem)
                setPreviousJobItems(getPreviousJobItems(job))
                setHoldingJob(job)
                const holdingJobLogs = await JobController.getJobLogsByJobId(holdingJobId)
                setHoldingJobLogs(holdingJobLogs)
            } else {
                const pickupTowingLocation = new TowingLocation({
                    location: holdingItem?.details?.location,
                    address: holdingItem?.details?.address,
                    address_type: "Holding",
                    location_type: "Holding",
                    holding_reason: "Holding",
                })
                const newJobTowingDetails = new JobTowingDetails({
                    towing_locations: [pickupTowingLocation, pickupTowingLocation],
                    towing_type: "Vehicle",
                    distance_kms: 0,
                    duration_seconds: 0,
                    cost_per_km: 0,
                    covered_distance_kms: 0,
                    total_cost: 0,
                    holding_item_id: holdingItem?._id,
                    equipment_details: holdingItem.details.equipment_details,
                    vehicle_details: holdingItem.details.vehicle_details,
                })
                const newJobOptions = new JobOptions({
                    towing_job_options: true
                })

                const newJobDetails = new JobDetails({
                    location: holdingItem?.details?.location,
                    address: holdingItem?.details?.address,
                    selected_services: holdingItem?.details?.selected_services,
                    report: { items: [] },
                    customer_details: holdingItem?.details?.customer_details,
                    vehicle_details: holdingItem?.details?.vehicle_details,
                    towing_details: newJobTowingDetails,
                    client_rate: holdingItem?.details?.client_rate || new JobClientRate({}),
                    options: newJobOptions
                })

                const newJobForPrefill = new Job({
                    case_id: holdingItem?.case_id,
                    company_id: StorageController.getCurrentCompany()._id,
                    client_id: holdingItem?.client_id,
                    client_company_id: holdingItem?.client_company_id,
                    vendor_company_id: holdingItem.vendor_company_id,
                    details: newJobDetails,
                    client_reference_id: holdingItem?.details.client_reference_id,
                })
                prefillNewJobFromHoldingJob(newJobForPrefill, holdingItem)
            }
        } catch (err) {
            console.log(err)
        }
    }

    /**
     * 
     * @param {Job} holdingJob 
     * @param {Holding} holdingItem 
     */
    const prefillNewJobFromHoldingJob = (holdingJob: Job, holdingItem: Holding) => {
        //Begin to support new object structure
        /**
         * @type {JobCustomerDetails}
         */
        const customer_details = holdingJob.details.customer_details
        const client_details = {
            client_id: holdingJob?.client_id,
            client_company_id: holdingJob?.client_company_id,
            client_rate: holdingJob?.details?.client_rate,
            client_reference_id: holdingJob?.client_reference_id
        }
        const pickup_towing_location = holdingJob?.details?.towing_details?.pickup_towing_location
        const previous_dropoff_towing_location = holdingJob?.details?.towing_details?.dropoff_towing_location
        const original_dropoff_towing_location = holdingJob.details.towing_details?.original_dropoff_towing_location

        /**
         * @type {JobTowingDetails}
         */
        const td = holdingJob?.details?.towing_details || new JobTowingDetails({})
        const dropoffLocation = () => {
            if (original_dropoff_towing_location) return original_dropoff_towing_location
            else if (previous_dropoff_towing_location) return previous_dropoff_towing_location
        }
        const towing_details = new JobTowingDetails({
            towing_type: td?.towing_type,
            description: td?.dropoff_address_type || "",
            vehicle_details: td?.vehicle_details,
            equipment_details: td?.equipment_details,

            // Maning the pickup location the same as the OG dropoff location
            pickup_towing_location: new TowingLocation({
                name: pickup_towing_location?.name,
                note: pickup_towing_location?.note,
                location: previous_dropoff_towing_location?.location,
                address: previous_dropoff_towing_location?.address,
                address_type: previous_dropoff_towing_location?.address_type,
                location_type: previous_dropoff_towing_location?.location_type,
                holding_reason: previous_dropoff_towing_location?.holding_reason,
            }),
            cost_per_km: td.cost_per_km || 0,
            covered_distance_kms: td.covered_distance_kms,
            distance_kms: null,
            duration_seconds: null,
            total_cost: null,
            directions_polyline: null,
            holding_item_id: holdingItem?._id,
        })



        const details = new JobDetails({
            name: customer_details?.name,
            phone: customer_details?.phone,
            email: customer_details?.email,
            location: holdingJob?.details?.location,
            address: holdingJob?.details?.address,
            selected_services: holdingJob?.details?.selected_services,
            customer_details: customer_details,
            client_details: client_details,
            client_rate: client_details?.client_rate,
            options: holdingJob?.details?.options,
            report: { items: [] }
        })



        if (original_dropoff_towing_location) {
            towing_details.dropoff_towing_location = original_dropoff_towing_location
            details.address = original_dropoff_towing_location.address
            details.location = original_dropoff_towing_location.location
        }

        // set this after others
        details.towing_details = towing_details



        const newJob = new Job({
            company_id: holdingJob?.company_id,
            client_id: holdingJob?.client_id,
            client_company_id: client_details.client_company_id,
            client_reference_id: client_details?.client_reference_id,
            vendor_company_id: holdingJob?.vendor_company_id,
            is_client_created: holdingJob?.is_client_created,
            is_vendor_created: holdingJob?.is_vendor_created,
            case_id: holdingJob?.case_id,
            contract_id: holdingJob.contract_id || "unknown",
            dispatchable: true,
            details: details,
            start_time: new Date().getTime(),
            vendor_eta: holdingJob?.vendor_eta || 60,
        })
        onSetJob(newJob)
    }

    const getPreviousJobItems = (job: Job) => {
        try {
            if (!job) return []
            if (!job?.details?.report?.items) return []
            const previousJobItems = job?.details?.report?.items
            return previousJobItems
        } catch (err) {
            console.log(err)
            return []
        }
    }

    const getJobCreatedBy = () => {
        if (JobState?.is_client_created && JobState?.company_id == StorageController.getCurrentCompany()._id) {
            return "local_client"
        }
        else if (JobState?.is_client_created && JobState?.company_id != StorageController.getCurrentCompany()._id) {
            return "client"
        }
        else {
            return "vendor"
        }
    }

    const styles = StyleSheet.create({
        backdrop: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        modalContainer: {
            width: width * 0.9,
            height: height * 0.9,
            padding: 20,
            borderRadius: 10,
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
            borderColor: 'rgba(0, 0, 0, 1)',
            borderWidth: 1,
        },
        groupContainer: {
            margin: 0,
            flex: 1,
            // backgroundColor: '#252e56',
        },
    });


    /////////////////////////////////////////////////
    /////////////////////////////////////////////////
    //// Create Job
    /////////////////////////////////////////////////
    /////////////////////////////////////////////////


    const createJob = async () => {
        try {
            let _Job = { ...JobRef.current } as Job
            if (!_Job.is_scheduled) _Job.start_time = new Date().getTime()
            const createdJob = await JobController.createJob(new Job(_Job))
            const updatedHolding = await HoldingController.updateHoldingSetReleased(holdingItemId as string, createdJob?._id as string)
            if (updatedHolding) {
                Toast.show({
                    text1: 'Holding Released',
                    text2: `Holding ${holdingState?._id} released successfully`,
                    type: 'success'
                });
            }
            if (createdJob) {
                Toast.show({
                    text1: 'Job Created',
                    text2: `Job ${createdJob._id} created successfully`,
                    type: 'success'
                });
                onCreateJob(createdJob)
            }
            else {
                Toast.show({
                    text1: 'Error',
                    text2: `Error creating job`,
                    type: 'error'
                });
            }
        } catch (err) {
            console.log("🚀============== ~ file: createJobFromHoldingModal.component.tsx:397 ~ createJob ~ err🚀==============", err)
            Toast.show({
                text1: 'Error',
                text2: `Error creating job`,
                type: 'error'
            });
        }
    }




    /////////////////////////////////////////////////
    /////////////////////////////////////////////////
    //// ADD DAYS HELD TO ITEM LIST
    /////////////////////////////////////////////////
    /////////////////////////////////////////////////





    const calculateTimeElapsed = (holdingItemObject: Holding) => {
        const holdingTime = new Date(holdingItemObject?.holding_time).getTime()
        // get the difference in milliseconds
        const holding_time_elapsed = Date.now() - holdingTime
        // Calculate days, hours, and minutes separately
        const days = Math.floor(holding_time_elapsed / (1000 * 60 * 60 * 24));
        const hours = Math.floor((holding_time_elapsed % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((holding_time_elapsed % (1000 * 60 * 60)) / (1000 * 60));

        return {
            days: days,
            hours: hours,
            minutes: minutes,
            readable: `${days} days ${hours} hours ${minutes} minutes`
        }
    }

    const addHeldDaysToItems = (coveredDays: string, ratePerDay: string) => {
        const totalDays = holdingState?.getTimeElapsed().days || 0;
        const validCoveredDays = Number(coveredDays) || 0;
        const validRatePerDay = Number(ratePerDay) || 0;
        const coveredDaysCost = validCoveredDays * validRatePerDay;
        const chargeableDays = Math.max(0, totalDays - validCoveredDays);
        const cost = chargeableDays * validRatePerDay;

        // Create an item for the covered days as a bill back item
        let coveredDaysItem = null;
        let chargableDaysItem = null;

        if (validCoveredDays > 0) {
            coveredDaysItem = new LineItem({
                name: `Holding COVERED - ${validCoveredDays} days of ${totalDays} @ $${validRatePerDay}/day`,
                description: `Holding COVERED - ${validCoveredDays} days of ${totalDays} @ $${validRatePerDay}/day`,
                quantity: 1,
                cost: coveredDaysCost,
                customerCost: false,
                billing: "Bill Back",
                billAllBack: true
            });
        }

        // Create an item for the held days as a customer cost item
        if (chargeableDays > 0) {
            chargableDaysItem = new LineItem({
                name: `Holding Customer Cost - ${validCoveredDays > 0 ? `(Covered ${validCoveredDays} days) - ` : ''}Held ${totalDays} days @ $${validRatePerDay}/day`,
                description: `Holding Customer Cost - ${validCoveredDays > 0 ? `(Covered ${validCoveredDays} days) - ` : ''}Held ${totalDays} days @ $${validRatePerDay}/day`,
                quantity: 1,
                cost: cost,
                customerCost: true,
                billing: "Customer Cost",
                billAllBack: false
            });
        }


        let _Job = JobState
        if (!_Job) return
        if (coveredDaysItem) _Job.addLineItem(coveredDaysItem)
        if (chargableDaysItem) _Job.addLineItem(chargableDaysItem)

        onSetJob(_Job)
    }

    const RenderAddHeldDaysToItemsCard = () => {
        const [coveredDays, setCoveredDays] = useState("")
        const [ratePerDay, setRatePerDay] = useState("")
        return (
            <Card
                style={[styles.groupContainer]}
                disabled={true}
                status='warning'
                header={() => <Text category='s1'>Add Held Days to Items</Text>}
                footer={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button appearance='outline' status='success' onPress={() => { addHeldDaysToItems(coveredDays, ratePerDay) }}>Add</Button>
                    </View>
                }
            >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>{calculateTimeElapsed(holdingState as Holding).readable}</Text>
                </View>
                {/* Covered days and /day rate input */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 3 }}>
                    <Input placeholder='$/Day' size="small" style={{ flex: 1 }} label="Rate Per Day ($)" value={ratePerDay} onChangeText={setRatePerDay} />
                    <Input placeholder='Covered days' size="small" style={{ flex: 1 }} label="Covered Days" value={coveredDays} onChangeText={setCoveredDays} />
                </View>
                <Text>
                    - This will add a new item to the job report with the number of days held and the rate per day.
                </Text>
            </Card>
        )
    }



    const onChangeTowingDetails = (field: any, value: any) => {
        let _towing_details = new JobTowingDetails(JobState?.details?.towing_details)
        //@ts-ignore
        _towing_details[field] = value
        if (!JobState) return
        JobState.details.towing_details = _towing_details
        onSetJob(JobState)
    }


    const addTowingDistanceItem = (towingItems: LineItem[]) => {
        try {
            if (!towingItems) return
            if (towingItems.length == 0) return
            let _Job = JobState as Job
            towingItems.forEach(item => {
                _Job.addLineItem(item)
            });
            onSetJob(_Job)
        } catch (err) {
            console.log(err)
        }
    }

    const onSetTowingLocationDetails = (locationDetailsObj: JobTowingDetails) => {
        // Merge location details with towing details
        let _towing_details = new JobTowingDetails(JobState?.details?.towing_details) // get existing details
        _towing_details.towing_locations = locationDetailsObj.towing_locations
        _towing_details.towing_legs = locationDetailsObj.towing_legs
        _towing_details.distance_kms = locationDetailsObj.distance_kms
        _towing_details.duration_seconds = locationDetailsObj.duration_seconds
        _towing_details.cost_per_km = locationDetailsObj.cost_per_km
        _towing_details.covered_distance_kms = locationDetailsObj.covered_distance_kms
        _towing_details.total_cost = locationDetailsObj.total_cost
        _towing_details.original_dropoff_towing_location = locationDetailsObj.original_dropoff_towing_location
        _towing_details.direction_polylines = locationDetailsObj.direction_polylines
        _towing_details.directions_polyline = locationDetailsObj.directions_polyline
        if (!JobState) return
        JobState.details.location = _towing_details.towing_locations[0]?.location
        JobState.details.address = _towing_details.towing_locations[0]?.address
        JobState.details.towing_details = _towing_details
        onSetJob(JobState)
    }


    /**
     * Shows the details of the holding item
     */
    const HoldingDetailsCard = ({ style }: any) => {
        if (!holdingState) return null
        return (
            <Card
                style={[style]}
                disabled={true}
                status='warning'
                header={() => <Text category='s1'>Holding Details</Text>}
            >
                <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1'>{holdingState.getTimeElapsed().readable}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='s1'>Held From: </Text>
                        <Text>{holdingState?.getHoldingTime()}</Text>
                    </View>
                    <Text category='s1'>Line Items: </Text>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        {holdingState?.getLineItems().length > 0 &&
                            <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                                {holdingState?.getLineItems().map((item, index) =>
                                    <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderWidth: 1 }}>
                                        <View style={{ borderColor: 'gray', borderWidth: 1 }}>
                                            <Text>{item.name}</Text>
                                        </View>
                                        <View style={{ borderColor: 'gray', borderWidth: 1 }}>
                                            <Text>${(item.quantity * item.cost).toFixed(2)}</Text>
                                        </View>
                                    </View>
                                )}
                            </View>
                        }
                    </View>
                </View>
            </Card>
        )
    }


    const onSetCustomerDetails = (customerDetails: JobCustomerDetails) => {
        let _Job = JobState as Job
        _Job.details.customer_details = customerDetails
        onSetJob(_Job)
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// Get Holding Params - If previos job exists or not
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const getCustomerDetails = (): JobCustomerDetails => {
        if (!holdingState?.holding_job_id) {
            return holdingState?.details.customer_details as JobCustomerDetails
        } else {
            return JobState?.details?.customer_details as JobCustomerDetails
        }
    }

    const getTowingDetails = () => {
        if (!holdingState?.holding_job_id) {
            return holdingState?.details.towing_details
        } else {
            return new JobTowingDetails({
                pickup_towing_location: holdingState?.details.location,
                distance_kms: 0,
                duration_seconds: 0,
                cost_per_km: 0,
                covered_distance_kms: 0,
                total_cost: 0,
                holding_item_id: holdingState?._id
            })
        }
    }

    const getClientReferenceId = () => {
        if (!holdingState?.holding_job_id) {
            return holdingState?.details.client_reference_id || ""
        } else {
            return JobState?.client_reference_id || ""
        }
    }

    const getSelectedServices = () => {
        if (!holdingState?.holding_job_id) {
            return holdingState?.details.selected_services as Service[] || []
        } else {
            return JobState?.details.selected_services as Service[] || []
        }
    }



    const hasPreviousJob = holdingState?.holding_job_id ? true : false






    if (!holdingState || !JobState) return null

    return (
        <Layout style={[styles.modalContainer]}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text category="h6">Create Job from Holding</Text>
                {holdingState?.holding_job_id &&
                    <Button
                        appearance='outline'
                        status='warning'
                        onPress={() => { setShowHoldingJobReport(!showHoldingJobReport) }}
                    >
                        {showHoldingJobReport ? "Hide Holding Job Report" : "Show Holding Job Report"}
                    </Button>
                }
                <Button status='danger' appearance='outline' onPress={onCloseModal}>X</Button>
            </View>
            <View style={{ flex: 1, flexDirection: 'row' }}>
                {showNotificationWidget &&
                    <View style={{ position: 'absolute', top: -15, left: 0, zIndex: 1000 }}>
                        <Animated.View style={{ opacity: fadeAnim }}>
                            <RenderNotificationWidget
                                title={notificationWidgetProps.title}
                                text={notificationWidgetProps.text}
                            />
                        </Animated.View>
                    </View>
                }
                <ScrollView style={{ flex: 1, overflow: "hidden" }}>
                    <View style={{ flexDirection: 'column', gap: 5 }}>
                        <View style={{ flexDirection: 'row', gap: 5 }}>
                            <CustomerDetailsCard
                                style={[styles.groupContainer]}
                                allowEdit={true}
                                customerDetails={JobState?.details.customer_details as JobCustomerDetails}
                                onChangeCustomerDetails={onSetCustomerDetails}
                            />
                            <TowingDetailsCard
                                setRefs={() => { }}
                                style={[styles.groupContainer]}
                                allowEdit={true}
                                onChangeTowingDetails={onChangeTowingDetails}
                                defaultTowingObj={JobState?.details?.towing_details || new JobTowingDetails({})}


                            />
                        </View>
                        <View style={{ flexDirection: 'row', gap: 5 }}>
                            <OptionsDetailsCard
                                allowEdit={true}
                                job={JobState || new Job({})}
                                onSetDispatchable={(e) => onSetJob({ ...JobState, dispatchable: e } as Job, "dispatchable")}
                                onSetIsScheduled={(e) => onSetJob({ ...JobState, is_scheduled: e } as Job, "isScheduled")}
                                onSetVendorEta={(e) => onSetJob({ ...JobState, vendor_eta: e } as Job, "vendorEta")}
                                onSet_start_time={(e) => onSetJob({ ...JobState, start_time: e } as Job, "start_time")}
                                onSetPriority={(e) => onSetJob({ ...JobState, details: { ...JobState?.details, priority: e } } as Job, "priority")}
                            />
                            <ClientDetailsCard

                                job={JobState}
                                clientReferenceId={JobState?.client_reference_id}
                                jobCreatedBy={getJobCreatedBy()}
                                fn_onSetClientRate={(e) => onSetJob({ ...JobState, details: { ...JobState?.details, client_rate: e } } as Job, "clientRateSelected")}
                                fn_onSetClient={(e) => onSetJob({ ...JobState, client_id: e._id, client_company_id: e.linkedClientAccountId ?? null } as Job, "clientSelected")}
                                fn_onSetClientRefrerence={(value) => {
                                    onSetJob({ ...JobState, client_reference_id: value } as Job)
                                }}
                                allowEdit={true}
                                isNewJob={false}
                                jobCreateSource={getJobCreatedBy()}
                                style={styles.groupContainer}
                                setRefs={() => { }}
                                onInputFocus={() => { }}
                                // clientReferenceId={JobState?.client_reference_id}
                                // client_id={holdingState.client_id || null}
                                // client_rate={JobState?.details.client_rate}
                                // client_company_id={JobState?.client_company_id}
                                // //@ts-ignore
                                // vendor_company_id={JobState?.vendor_company_id}
                                // is_client_created={JobState?.is_client_created}
                                // is_vendor_created={JobState?.is_vendor_created}
                                // jobCreatedBy={getJobCreatedBy()}
                                // fn_onSetClientRate={(e) => onSetJob({ ...JobState, details: { ...JobState?.details, client_rate: e } } as Job, "clientRateSelected")}
                                // fn_onSetClient={(e) => onSetJob({ ...JobState, client_id: e } as Job, "clientSelected")}
                                // fn_onSetClientRefrerence={(value) => {
                                //     onSetJob({ ...JobState, client_reference_id: value } as Job)
                                // }}
                                // allowEdit={true}
                                // isNewJob={false}
                                // jobCreateSource={getJobCreatedBy()}
                            />
                        </View>
                        <ServiceDetailsCard
                            updateJobServices={(services) => onSetJob({ ...JobState, details: { ...JobState?.details, selected_services: services } } as Job, "updateJobServices")}
                            allowEdit={true}
                            selectedServices={getSelectedServices()}
                            canSetTimes={false} />
                        <TowingLocationDetailsCard
                            validateOnLoad={true}
                            allowEdit={true}
                            defaultTowingLocationObj={JobState?.details.towing_details as JobTowingDetails}
                            onAddCostToItems={addTowingDistanceItem}
                            onChangeTowingLocations={onSetTowingLocationDetails}

                        />
                        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 5 }}>

                            <View style={{ flex: 1, minWidth: 'auto', flexDirection: 'row' }}>
                                <RenderAddHeldDaysToItemsCard />
                                <HoldingDetailsCard style={styles.groupContainer} />
                                {/* <RenderAddPreviousItemsToJobCard /> */}
                            </View>
                            <View style={{ flex: 3, minWidth: 'auto' }}>
                                <InventoryDetailsCard
                                    style={[styles.groupContainer]}
                                    allowEdit={true}
                                    selectedItems={JobState?.details?.report?.items || []}
                                    jobSource={"vendor"}
                                    onChangeSelectedItems={(e) => onSetJob({ ...JobState, details: { ...JobState?.details, report: { ...JobState?.details.report, items: e } } } as Job, "selectedItems")}
                                    vendorProfile={null} />
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', gap: 5 }}>
                            <OtherDetailsCard
                                style={[styles.groupContainer]}
                                allowEdit={true}
                                dispatchComments={JobState?.details?.comments || ""}
                                onSetDispatchComments={(e) => onSetJob({ ...JobState, details: { ...JobState?.details, comments: e } } as Job, "dispatchComments")}
                            />
                        </View>
                        <InvoicedTotalCard
                            job={JobState as Job}
                            style={styles.groupContainer}
                        />
                        <Button appearance='outline' status='success' onPress={createJob}>Create Job</Button>
                    </View>
                </ScrollView>
                {holdingState?.holding_job_id && showHoldingJobReport &&
                    <View style={{ flex: 1, maxWidth: '40%' }}>
                        <ScrollView style={{}}>
                            {!holdingJob && <Text category="h6">Loading Holding Job...</Text>}
                            {holdingJob &&
                                <ReportDetailsCard
                                    style={[styles.groupContainer]}
                                    job={holdingJob}
                                />
                            }
                            {!holdingJobLogs && <Text category="h6">Loading Holding Job Logs...</Text>}
                            {holdingJobLogs &&
                                <LogDetailsCard
                                    logs={holdingJobLogs}
                                />
                            }
                        </ScrollView>
                    </View>
                }
            </View>
        </Layout>
    )


}
