import React from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle, Input } from '@ui-kitten/components';
import { CompanyAlerts } from '../../models/Company.model';
import Tooltip from '../modals/Tooltip';

interface CompanyAlertOptionsProps {
    companyAlerts: CompanyAlerts;
    onSetCompanyAlertOptions: (companyAlerts: CompanyAlerts) => void;
    style?: any;
}

export const CompanyAlertOptions: React.FC<CompanyAlertOptionsProps> = ({
    companyAlerts,
    onSetCompanyAlertOptions,
    style = {}
}) => {
    const textCategory = 's2';

    const updateAlertOption = (key: keyof CompanyAlerts, value: boolean | number) => {
        const updatedAlerts = new CompanyAlerts({
            ...companyAlerts,
            [key]: value
        });
        onSetCompanyAlertOptions(updatedAlerts);
    };

    const RenderToggle = (label: string, key: keyof CompanyAlerts, tooltipText: string) => (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
            <Toggle
                checked={companyAlerts[key] as boolean}
                onChange={(nextChecked) => updateAlertOption(key, nextChecked)}
            >
                <Tooltip text={tooltipText}>
                    <Text category={textCategory}>
                        {label}
                    </Text>
                </Tooltip>
            </Toggle>
        </View>
    );

    const RenderInput = (label: string, key: keyof CompanyAlerts) => (
        <Input
            label={label}
            placeholder={label}
            value={(companyAlerts[key] as number).toString()}
            onChangeText={(nextValue) => updateAlertOption(key, parseInt(nextValue) || 0)}
            keyboardType="numeric"
        />
    );

    return (
        <Card
            style={[style, { flex: 1 }]}
            disabled
            header={() => <Text category='h6'>Alerts</Text>}
            status="basic"
        >
            {RenderToggle(
                'Enable Over ETA Alerts',
                'enable_over_eta_alerts',
                'Alert popup when a job is over the ETA'
            )}
            {RenderToggle(
                'Enable Close To ETA Alerts',
                'enable_close_to_eta_alerts',
                'Alert popup when a job is close to the ETA'
            )}
            {companyAlerts.enable_close_to_eta_alerts && (
                <Card>
                    {RenderInput('Close To ETA Threshold (Minutes)', 'close_to_eta_alert_time_theshold')}
                </Card>
            )}
            {RenderToggle(
                'Enable Unassigned Job Time Alerts',
                'enable_unassigned_job_time_alerts',
                'Alert popup when a job is unassigned for a certain time'
            )}
            {companyAlerts.enable_unassigned_job_time_alerts && (
                <Card>
                    {RenderInput('Unassigned Job Time Threshold (Minutes)', 'unassigned_job_time_threshold')}
                </Card>
            )}
            {RenderToggle(
                'Enable Assigned Driver Not Accepted Job Time Alerts',
                'enable_assigned_driver_not_accepted_job_time_alerts',
                'Alert popup when a job is assigned to a driver but not accepted for a certain time'
            )}
            {companyAlerts.enable_assigned_driver_not_accepted_job_time_alerts && (
                <Card>
                    {RenderInput('Assigned Driver Not Accepted Job Time Threshold (Minutes)', 'assigned_driver_not_accepted_job_time_threshold')}
                </Card>
            )}
        </Card>
    );
};
