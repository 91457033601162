import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, FlatList, Alert, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, List, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Toggle, OverflowMenu, MenuItem, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as CompanyController from '../../functions/company.controller'
import * as GoogleController from '../../functions/google.controller'
import { Job, JobAddress, JobLocation } from '../../models/Job.model'
import { ServiceAreaEdit, ServiceAreaSettings } from './serviceAreaEdit.component'
//@ts-ignore
import { SketchPicker, CirclePicker, SwatchesPicker, SliderPicker, ChromePicker } from 'react-color'
//@ts-ignore
import debounce from 'lodash.debounce';
import { useNotification } from '../../context/notifications.context';
import { Company, CompanySavedAddress, CompanySettings, JobOptions, ScheduleSettings, SMSSettings, EmailSettings, Surcharge, ServiceArea, CompanyWebhookSettings, CompanyTheme, IServiceAreaCoord, CompanyAlerts, CompanyAppOptions, DriverSettings, CompanyCreateJobMandatoryFields, CompanyContactInfo, CompanyRoles, CompanyBusinessInfo, CompanyIntegrations, CompanyPartsNetworkSettings } from '../../models/Company.model';
import { SavedAddresses } from './address/savedAddresses.component';
import { StripeSubscriptionSettings } from './stripeSubscriptionSettings.component';
import { PaymentSettings } from './paymentSettings.component';
import { CompanySubscription, SUBSCRIPTION_FEATURES } from '../../models/CompanySubscription.model';
import * as CompanySubscriptionController from '../../functions/CompanySubscription.controller'
import Tooltip from '../modals/Tooltip';
import { CompanySecret } from '../../models/CompanySecret.model';
import * as CompanySecretController from '../../functions/companySecret.controller'
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
import { AddressDetailsCard } from '../job/details/addressDetailsCard.component';
import { DriverAppOptions } from './driverAppOptions.component';
import { ServiceSettings } from './serviceSettings.component';
import { CreateJobMandatoryFields } from './createJobMandatoryFields.component';
import { EmailSettingsComponent } from './emailSettings.component';
import { SMSSettingsComponent } from './smsSettings.component';
import { ScheduleSettingsComponent } from './scheduleSettings.component';
import { JobOptionsComponent } from './jobOptions.component';
import { ContactInfoComponent } from './contactInfo.component';
import { WebhookSettingsComponent } from './webhookSettings.component';
import { CompanyRolesComponent } from './companyRoles.component';
import { CompanyThemePicker } from './CompanyThemePicker.component';
import { CompanyAlertOptions } from './CompanyAlertOptions.component';
import { CompanyBusinessSettings } from './CompanyBusinessSettings.component';
import { IntegrationSettings } from './integrationSettings.component';
import { CompanyPartsNetworkSettingsComponent } from './CompanyPartsNetworkSettings.component';


export const CompanySettingsContainer = ({ navigation }: any) => {

    const [company, setCompany] = useState(new Company({}) as Company)
    const companyRef = useRef(new Company({}) as Company)
    const [originalCompanyObj, setOriginalCompanyObj] = useState(new Company({}) as Company)

    const [showServiceAreaModal, setShowServiceAreaModal] = React.useState(false)
    const { showNotification } = useNotification();
    const [unsavedChanges, setUnsavedChanges] = useState(false)

    // const [serviceAreaData, setServiceAreaData] = useState([] as IServiceAreaCoord[]);



    const handleCompanyChangeEvent = useCallback((action: IAction) => {
        onSetCompany(new Company({}), false)
        setUnsavedChanges(false)
        // setServiceAreaData([])
        setTimeout(async () => {
            load()
        }, 100);
    }, [company]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent,
    })




    const MENU_TABS = {
        NOTIFICATIONS: 'NOTIFICATIONS',
        JOB_OPTIONS: 'JOB_OPTIONS',
        COMPANY_INFO: 'COMPANY_INFO',
        PAYMENT_OPTIONS: 'PAYMENT_OPTIONS',
        OTHER: 'OTHER',
        SUBSCRIPTION: 'SUBSCRIPTION'
    }

    const [selectedMenuTab, setSelectedMenuTab] = useState(MENU_TABS.JOB_OPTIONS)

    const RenderMenuTabs = () => {
        return (
            <View style={{ flexDirection: 'column' }}>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.JOB_OPTIONS)}
                    appearance={selectedMenuTab == MENU_TABS.JOB_OPTIONS ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.JOB_OPTIONS ? 'primary' : 'basic'}
                >
                    Job Options
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.NOTIFICATIONS)}
                    appearance={selectedMenuTab == MENU_TABS.NOTIFICATIONS ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.NOTIFICATIONS ? 'primary' : 'basic'}
                >
                    Notifications
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.COMPANY_INFO)}
                    appearance={selectedMenuTab == MENU_TABS.COMPANY_INFO ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.COMPANY_INFO ? 'primary' : 'basic'}
                >
                    Company Info
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.PAYMENT_OPTIONS)}
                    appearance={selectedMenuTab == MENU_TABS.PAYMENT_OPTIONS ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.PAYMENT_OPTIONS ? 'primary' : 'basic'}
                >
                    Payment Options
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.OTHER)}
                    appearance={selectedMenuTab == MENU_TABS.OTHER ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.OTHER ? 'primary' : 'basic'}
                >
                    Other
                </Button>
                <Button
                    onPress={() => setSelectedMenuTab(MENU_TABS.SUBSCRIPTION)}
                    appearance={selectedMenuTab == MENU_TABS.SUBSCRIPTION ? 'filled' : 'outline'}
                    status={selectedMenuTab == MENU_TABS.SUBSCRIPTION ? 'primary' : 'basic'}
                >
                    Subscription
                </Button>
            </View>
        )
    }






    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            load();
        });

        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);



    const load = async () => {
        let id = StorageController.getCurrentCompany()._id
        let company = await CompanyController.getCompanyById(id) as Company
        let companySubscription = await CompanySubscriptionController.getActiveSubscriptionByCompanyId(id)
        if (!company) return
        company.company_subscription = new CompanySubscription(companySubscription)
        company = new Company(company)
        onSetCompany(company, false)
        setOriginalCompanyObj(company)


        // load the service_area[0] to data
        // if (company.settings.service_areas && company.settings.service_areas.length > 0) {
        //     setServiceAreaData(company.settings.service_areas[0].coords)
        // }

    }


    const onSetCompany = async (company: Company, changes = true) => {
        company = new Company(company)
        companyRef.current = company
        setCompany(company)
        if (changes) {
            setUnsavedChanges(true)
        }
    }


    const companySecretRef = useRef({} as CompanySecret)
    const unsavedCompanySecretChangesRef = useRef(false)
    const onSetCompanySecret = async (nextValue: CompanySecret) => {
        console.log("🚀============== ~ file: companySettings.component.tsx:188 ~ onSetCompanySecret ~ nextValue🚀==============", nextValue)
        setUnsavedChanges(true)
        unsavedCompanySecretChangesRef.current = true
        companySecretRef.current = nextValue
    }



    //setting that is a location of company as an address
    const onSetAddress = async (result: JobAddress) => {
        let coy = new Company({ ...companyRef.current })
        coy.settings.location.address = result
        coy.settings.location.coords = new JobLocation(coy.settings.location.coords)
        onSetCompany(coy)
    }

    const onSetLocation = async (location: { lat: number, lng: number }) => {
        let coy = new Company({ ...companyRef.current })
        coy.settings.location.address = new JobAddress(coy.settings.location.address)
        coy.settings.location.coords = new JobLocation(location)
        onSetCompany(coy)
    }

    const onSetSavedAddress = (savedAddress: CompanySavedAddress) => {
        let coy = new Company({ ...company })
        coy.settings.location.address = savedAddress.address
        coy.settings.location.coords = new JobLocation(savedAddress.location)
        onSetCompany(coy)
    }

    const onSave = async () => {
        // set the company that matches the id in storage controller
        let result = await CompanyController.updateCompany(company)
        //console.log("result", result)
        if (result.status == 200) {
            showNotification('Notice', 'Company Settings Saved');
            setUnsavedChanges(false)
            setOriginalCompanyObj(company)
        }
        else {
            showNotification('Error', 'Company Settings Failed to Save, try again later or refresh..');
        }
        if (unsavedCompanySecretChangesRef.current) {
            if (!companySecretRef.current?._id) {
                companySecretRef.current = await CompanySecretController.createCompanySecret(companySecretRef.current)
                console.log("🚀============== ~ file: companySettings.component.tsx:240 ~ onSave ~ companySecretRef.current🚀==============", companySecretRef.current)
            }
            else {
                const secretSaveResult = await CompanySecretController.updateCompanySecret(companySecretRef.current)
                console.log("🚀============== ~ file: companySettings.component.tsx:258 ~ onSave ~ secretSaveResult🚀==============", secretSaveResult)
            }
            unsavedCompanySecretChangesRef.current = false
        }
    }

    const onCancel = async () => {
        onSetCompany(originalCompanyObj, false)
        setUnsavedChanges(false)
    }

    ///////////////////////////////////////
    //#region Service Area
    ///////////////////////////////////////
    const [selectedServiceAreaIndex, setSelectedServiceAreaIndex] = useState(0)
    const [showConfirmServiceAreaDeleteModal, setShowConfirmServiceAreaDeleteModal] = useState(false)

    const RenderServiceAreaDeleteModal = () => {
        return (
            <Modal
                visible={showConfirmServiceAreaDeleteModal}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setShowConfirmServiceAreaDeleteModal(false)}>
                <Card disabled={true}>
                    <Text>Are you sure you want to delete this service area ? </Text>
                    <Button onPress={() => onDeleteServiceArea(selectedServiceAreaIndex)} status="danger" > Delete </Button>
                    <Button onPress={() => setShowConfirmServiceAreaDeleteModal(false)} status="basic" > Cancel </Button>
                </Card>
            </Modal>
        )
    }

    const onServiceAreaSave = async (serviceArea: any) => {
        let newServiceAreas = company.settings?.service_areas
        //@ts-ignore
        newServiceAreas[selectedServiceAreaIndex] = serviceArea
        onSetCompany({ ...company, settings: { ...company.settings, service_areas: newServiceAreas } } as Company)
        setShowServiceAreaModal(false)
    }

    const confirmDeleteServiceArea = async (index: number) => {
        setSelectedServiceAreaIndex(index)
        setShowConfirmServiceAreaDeleteModal(true)
    }

    const onDeleteServiceArea = async (index: number) => {
        let newServiceAreas = company.settings.service_areas
        newServiceAreas.splice(index, 1)
        onSetCompany({ ...company, settings: { ...company.settings, service_areas: newServiceAreas } } as Company)
    }


    const onEditServiceArea = async (index: number) => {
        setSelectedServiceAreaIndex(index)
        setShowServiceAreaModal(true)
    }

    const onAddNewServiceArea = async () => {
        let serviceArea = new ServiceArea()
        let newServiceAreas = company.settings.service_areas as ServiceArea[]
        newServiceAreas.push(serviceArea)
        onSetCompany({ ...company, settings: { ...company.settings, service_areas: newServiceAreas } } as Company)
    }

    const RenderServiceAreaModal = () => {
        return (
            <Modal
                visible={showServiceAreaModal}
                backdropStyle={styles.backdrop}
                style={{ width: '90%', height: '90%' }
                }
                onBackdropPress={() => setShowServiceAreaModal(false)}>
                <ServiceAreaEdit
                    fn_onSave={(serviceArea: ServiceArea) => onServiceAreaSave(serviceArea)}
                    fn_onCancel={() => setShowServiceAreaModal(false)}
                    var_serviceArea={company.settings?.service_areas?.[selectedServiceAreaIndex]}
                />
                {/* <Button onPress={() => setShowServiceAreaModal(false)}>Close</Button> */}
            </Modal>
        )
    }


    ///////////////////////////////////////
    //#region SETTINGS CHECKBOXES
    ///////////////////////////////////////
    const SettingsCheckBox = ({ checked, onChange, label }: any) => (
        <CheckBox
            checked={checked || false}
            onChange={onChange}
        >
            {label}
        </CheckBox>
    );
    const CardWithCheckboxes = ({ headerText, settings = {}, settingKey, setCompany, checkBoxes }: any) => {
        // Ensure the settingKey exists, otherwise initialize as empty object
        const settingValues = settings[settingKey] || {};
        return (
            <Card disabled header={() => <Text category='h6' > {headerText} </Text>} status="basic">
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    {
                        checkBoxes.map(({ label, key }: any) => (
                            <View key={key} style={{ margin: 5, width: "25%" }} >
                                <SettingsCheckBox
                                    label={label}
                                    checked={settingValues[key] || false}
                                    onChange={(nextChecked: boolean) => setCompany({
                                        ...company, settings: {
                                            ...company.settings, [settingKey]: {
                                                ...settingValues,
                                                [key]: nextChecked
                                            }
                                        }
                                    })}
                                />
                            </View>
                        ))}
                </View>
            </Card >
        );
    };



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region APP OPTIONS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onUpdateAppOptions = (updatedOptions: CompanyAppOptions) => {
        onSetCompany({ ...company, settings: { ...company.settings, app_options: updatedOptions } } as Company);
    };

    const onUpdateAllowDriverCreateJob = (allow: boolean) => {
        onSetCompany({ ...company, settings: { ...company.settings, driver_settings: { ...company.settings.driver_settings, allow_driver_create_job: allow } } } as Company);
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region DRIVER SETTINGS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onUpdateDriverSettings = (updatedSettings: DriverSettings) => {
        onSetCompany({ ...company, settings: { ...company.settings, driver_settings: updatedSettings } } as Company);
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region MANDATORY FIELDS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onUpdateMandatoryFields = (updatedFields: CompanyCreateJobMandatoryFields) => {
        onSetCompany({ ...company, settings: { ...company.settings, create_job_mandatory_fields: updatedFields } } as Company);
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region EMAIL SETTINGS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onUpdateEmailSettings = (updatedSettings: EmailSettings) => {
        onSetCompany({ ...company, settings: { ...company.settings, email: updatedSettings } } as Company);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region SMS SETTINGS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onUpdateSMSSettings = (updatedSettings: SMSSettings) => {
        onSetCompany({ ...company, settings: { ...company.settings, sms: updatedSettings } } as Company);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region SCHEDULE SETTINGS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onUpdateScheduleSettings = (updatedSettings: ScheduleSettings) => {
        onSetCompany({ ...company, settings: { ...company.settings, scheduleSettings: updatedSettings } } as Company);
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region JOB OPTIONS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onUpdateJobOptions = (updatedOptions: JobOptions) => {
        onSetCompany({ ...company, settings: { ...company.settings, job_options: updatedOptions } } as Company);
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region SAVED ADDRESSES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onUpdateSavedAddresses = (updatedAddresses: CompanySavedAddress[]) => {
        onSetCompany({ ...company, settings: { ...company.settings, address_book: updatedAddresses } } as Company);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region CONTACT INFO
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onUpdateContactInfo = (updatedInfo: CompanyContactInfo) => {
        onSetCompany({ ...company, settings: { ...company.settings, contact_info: updatedInfo } } as Company);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region WEBHOOK SETTINGS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onUpdateWebhookSettings = (updatedSettings: CompanyWebhookSettings) => {
        onSetCompany({ ...company, settings: { ...company.settings, webhook_settings: updatedSettings } } as Company);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region COMPANY ROLES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onUpdateCompanyRoles = (updatedRoles: CompanyRoles) => {
        onSetCompany({ ...company, settings: { ...company.settings, company_roles: updatedRoles } } as Company);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region COMPANY ALERTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onSetCompanyAlertOptions = (updatedAlerts: CompanyAlerts) => {
        onSetCompany({ ...company, settings: { ...company.settings, alerts: updatedAlerts } } as Company);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region BUSINESS INFO
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onUpdateBusinessInfo = (updatedInfo: CompanyBusinessInfo) => {
        onSetCompany({ ...company, settings: { ...company.settings, business_info: updatedInfo } } as Company);
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region INTEGRATION SETTINGS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onUpdateIntegrationSettings = (updatedSettings: CompanyIntegrations) => {
        onSetCompany({ ...company, settings: { ...company.settings, integrations: updatedSettings } } as Company);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region PARTS NETWORK SETTINGS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onUpdatePartsNetworkSettings = (updatedSettings: CompanyPartsNetworkSettings) => {
        onSetCompany({ ...company, settings: { ...company.settings, parts_network: updatedSettings } } as Company);
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const RenderCompanyLocation = useCallback(({
        style = {},
        address = company.settings?.location?.address,
        location = company.settings?.location?.coords,
        onSetAddress = () => { },
        onSetLocation = () => { },
        onSetSavedAddress = () => { }
    }: {
        style?: any,
        address?: JobAddress,
        location?: JobLocation,
        onSetAddress?: (address: JobAddress) => void,
        onSetLocation?: (location: JobLocation) => void,
        onSetSavedAddress?: (address: CompanySavedAddress) => void
    }) => {
        console.log("RenderCompanyLocation", address, location)
        const getAddressDisplayValue = () => {
            let addressDisplayValue = ""
            try {

                if (typeof address == "string") {
                    addressDisplayValue = address
                } else {
                    addressDisplayValue = GoogleController.addressBuilder(address)
                }
                return addressDisplayValue
            } catch (error) {
                console.log(error)
                return ""
            }
        }
        return (
            <Card
                disabled
                style={[style, { flex: 1 }]}
                header={() => <Text category='h6' > Set Company Location </Text>}
                status="basic"
            >
                <Layout style={{ flex: 1, flexDirection: 'column' }}>
                    {/* set address input */}
                    <AddressDetailsCard
                        address={address}
                        onSetAddress={onSetAddress}
                        onSetLocation={onSetLocation}
                        addressDisplayValue={getAddressDisplayValue()}
                        onSetSavedAddress={onSetSavedAddress}
                    />
                    <Layout style={{ flex: 1, flexDirection: 'row' }}>
                        <Input
                            size='small'
                            label="Latitude"
                            placeholder="Latitude"
                            disabled={true}
                            defaultValue={location?.lat.toString()}
                        />
                        <Input
                            size='small'
                            label="Longitude"
                            placeholder="Longitude"
                            disabled={true}
                            defaultValue={location?.lng.toString()}
                        />
                    </Layout>
                </Layout>
            </Card>
        )
    }, [company, onSetAddress, onSetLocation, onSetSavedAddress])



    return (
        <View
            key={company?._id}
            style={{ flex: 1, flexDirection: 'row' }}>
            <RenderServiceAreaModal />
            <RenderServiceAreaDeleteModal />
            <View style={{ flex: 1, borderRightWidth: 1, borderColor: 'black' }}>
                <RenderMenuTabs />
            </View>
            <View style={{ flex: 5 }}>
                <View style={{ flexDirection: 'row', backgroundColor: 'rgba(0,0,0,0.3)' }}>
                    <View style={{ flex: 1 }}>
                        {
                            unsavedChanges ? <Text style={{ color: 'red' }
                            } > Unsaved Changes </Text> : <Text></Text >
                        }
                    </View>
                    <View style={{ flex: 3 }}>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            onPress={onSave}
                            accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                            status="success"
                        >
                            Save
                        </Button>
                    </View>
                    <View style={{ flex: 1 }}>
                        <Button
                            onPress={onCancel}
                            accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                            status="warning"
                        >
                            Cancel
                        </Button>
                    </View>
                </View>
                <ScrollView style={{ flex: 1 }}>
                    <Layout style={{ flex: 1, flexDirection: 'row' }}>

                        {/* 
                        //#region JOB OPTIONS
                        */}
                        {selectedMenuTab == MENU_TABS.JOB_OPTIONS &&
                            <View style={{ flex: 1, gap: 10, flexWrap: 'wrap', flexDirection: 'row' }}>
                                <JobOptionsComponent
                                    jobOptions={company.settings?.job_options || new JobOptions({})}
                                    onUpdateJobOptions={onUpdateJobOptions}
                                    style={{ minWidth: 300 }}
                                />
                                <DriverAppOptions
                                    appOptions={company?.settings?.app_options}
                                    onUpdateAppOptions={onUpdateAppOptions}
                                    allowDriverCreateJob={company?.settings?.driver_settings?.allow_driver_create_job || false}
                                    onUpdateAllowDriverCreateJob={onUpdateAllowDriverCreateJob}
                                    style={{ minWidth: 300 }}
                                />
                                <CreateJobMandatoryFields
                                    mandatoryFields={company.settings?.create_job_mandatory_fields || new CompanyCreateJobMandatoryFields({})}
                                    onUpdateMandatoryFields={onUpdateMandatoryFields}
                                    style={{ minWidth: 400 }}
                                />
                                <ServiceSettings
                                    driverSettings={company?.settings?.driver_settings}
                                    onUpdateDriverSettings={onUpdateDriverSettings}
                                    style={{ minWidth: 400 }}
                                />
                                <ScheduleSettingsComponent
                                    scheduleSettings={company.settings?.scheduleSettings || new ScheduleSettings({})}
                                    onUpdateScheduleSettings={onUpdateScheduleSettings}
                                    style={{ minWidth: 500 }}
                                />
                            </View>
                        }
                        {/* 
                        //#region NOTIFICATIONS
                        */}
                        {
                            selectedMenuTab == MENU_TABS.NOTIFICATIONS &&
                            <View style={{ flex: 1, flexDirection: 'row', gap: 10, flexWrap: 'wrap' }}>
                                <EmailSettingsComponent
                                    emailSettings={company.settings?.email || new EmailSettings({})}
                                    onUpdateEmailSettings={onUpdateEmailSettings}
                                    style={{ minWidth: 400 }}
                                />

                                <SMSSettingsComponent
                                    smsSettings={company.settings?.sms || new SMSSettings({})}
                                    onUpdateSMSSettings={onUpdateSMSSettings}
                                    style={{ minWidth: 400 }}
                                />
                                <CompanyAlertOptions
                                    companyAlerts={company.settings?.alerts || new CompanyAlerts({})}
                                    onSetCompanyAlertOptions={onSetCompanyAlertOptions}
                                    style={{ minWidth: 400 }}
                                />
                            </View>
                        }
                        {/* 
                        //#region COMPANY INFO
                        */}
                        {
                            selectedMenuTab == MENU_TABS.COMPANY_INFO &&
                            <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                <RenderCompanyLocation
                                    onSetAddress={onSetAddress}
                                    onSetLocation={onSetLocation}
                                    onSetSavedAddress={onSetSavedAddress}
                                    style={{ minWidth: 500 }}
                                />
                                <CompanyBusinessSettings
                                    businessInfo={company.settings?.business_info || new CompanyBusinessInfo({})}
                                    onUpdateBusinessInfo={onUpdateBusinessInfo}
                                    style={{ minWidth: 500 }}
                                />
                                <ServiceAreaSettings
                                    company={company}
                                    onAddNewServiceArea={onAddNewServiceArea}
                                    onEditServiceArea={onEditServiceArea}
                                    confirmDeleteServiceArea={confirmDeleteServiceArea}
                                    style={{ minWidth: 500 }}
                                    var_serviceArea={null}
                                />
                                <ContactInfoComponent
                                    contactInfo={company.settings?.contact_info || new CompanyContactInfo({})}
                                    onUpdateContactInfo={onUpdateContactInfo}
                                    style={{ minWidth: 500 }}
                                />
                                <SavedAddresses
                                    company={company}
                                    onSave={(updatedAddressBook: CompanySavedAddress[]) => {
                                        onUpdateSavedAddresses(updatedAddressBook)
                                    }}
                                    style={{ minWidth: 500, maxHeight: 700 }}
                                />


                                <CompanyRolesComponent
                                    company={company}
                                    onUpdateCompanyRoles={onUpdateCompanyRoles}
                                    style={{ minWidth: 500 }}
                                />
                                <CompanyPartsNetworkSettingsComponent
                                    company={company}
                                    onUpdatePartsNetworkSettings={onUpdatePartsNetworkSettings}
                                    style={{ minWidth: 500 }}
                                />
                            </View>
                        }
                        {/* 
                        //#region PAYMENT OPTIONS
                        */}
                        {
                            selectedMenuTab == MENU_TABS.PAYMENT_OPTIONS &&
                            <View style={{ flex: 1, gap: 10 }}>
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    {/* Square payment options */}
                                    <PaymentSettings
                                        company={company}
                                        onSetCompany={onSetCompany}
                                        onSetCompanySecret={onSetCompanySecret}
                                        style={{ minWidth: 500 }}
                                    />
                                </View>
                            </View>

                        }
                        {/* 
                        //#region OTHER
                        */}
                        {
                            selectedMenuTab == MENU_TABS.OTHER &&
                            <View style={{ flex: 1, flexDirection: 'column', gap: 10 }}>
                                <View style={{ flexDirection: 'column', gap: 5 }}>
                                    {/* Theme settings */}
                                    {
                                        // company.company_subscription?.features[SUBSCRIPTION_FEATURES.WHITE_LABEL] ||
                                        true && //TODO: REMOVE THE TRUE
                                        <CompanyThemePicker company={company} onSetCompany={onSetCompany} />
                                    }
                                    {
                                        // company.company_subscription?.features[SUBSCRIPTION_FEATURES.API_ACCESS] || 
                                        true && //TODO: REMOVE THE TRUE
                                        <WebhookSettingsComponent
                                            company={company}
                                            onSetCompanySecret={onSetCompanySecret}
                                        />
                                    }
                                    {
                                        <IntegrationSettings
                                            integrations={company.settings?.integrations || new CompanyIntegrations({})}
                                            onUpdateIntegrations={onUpdateIntegrationSettings}
                                            companySecret={companySecretRef.current}
                                            onUpdateCompanySecret={onSetCompanySecret}
                                            style={{ marginTop: 10 }}
                                        />
                                    }
                                </View>
                            </View>
                        }
                        {/* 
                        //#region SUBSCRIPTION
                        */}
                        {
                            selectedMenuTab == MENU_TABS.SUBSCRIPTION &&
                            <StripeSubscriptionSettings company={company} onSetCompany={onSetCompany} />
                        }
                    </Layout>
                </ScrollView>
            </View>
        </View >
    )
}


// const CreateSurchargeModal = ({ visible, setVisible, onSave }: any) => {
//     const [surcharge, setSurcharge] = useState(new Surcharge({ name: '', amount: '', type: 'flat' }))

//     const onSetSurcharge = (surcharge: Surcharge) => {
//         setSurcharge(surcharge)
//     }


//     const save = async (surcharge: Surcharge) => {
//         // check if the surcharge has a name and amount
//         if (surcharge.name && surcharge.amount) {
//             // Check if amount is a number
//             if (isNaN(surcharge.amount)) {
//                 alert('Surcharge amount must be a number')
//                 return
//             }
//             onSave(surcharge)
//         }
//         else {
//             alert('Surcharge must have a name and amount')
//         }
//     }



//     return (
//         <Modal
//             visible={visible}
//             backdropStyle={styles.backdrop}
//             onBackdropPress={() => setVisible(false)}>
//             <Card disabled={true}>
//                 <Text>Surcharge Name</Text>
//                 <Input
//                     placeholder='Surcharge Name'
//                     value={surcharge.name}
//                     onChangeText={(nextValue: any) => onSetSurcharge({ ...surcharge, name: nextValue })}
//                 />
//                 <Text>Surcharge Amount</Text>
//                 <Input
//                     placeholder='Surcharge Amount'
//                     value={surcharge.amount}
//                     onChangeText={(nextValue: any) => onSetSurcharge({ ...surcharge, amount: nextValue })}
//                 />
//                 <Text>Surcharge Type</Text>
//                 {/* Toggle between flat or percentage */}
//                 <Toggle
//                     checked={surcharge.type == 'flat'}
//                     onChange={(nextChecked: boolean) => {
//                         let type = nextChecked ? 'flat' : 'percentage'
//                         onSetSurcharge({ ...surcharge, type: type })
//                     }}
//                 >
//                     {surcharge.type == 'flat' ? 'Flat' : 'Percentage'}
//                 </Toggle>
//                 <Button onPress={() => save(surcharge)} status="success" > Save </Button>
//                 <Button onPress={() => setVisible(false)} status="basic" > Cancel </Button>
//             </Card>
//         </Modal>
//     )
// }



const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});

