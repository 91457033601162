import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, FlatList, Alert, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, List, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Toggle, OverflowMenu, MenuItem, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as CompanyController from '../../functions/company.controller'
import * as GoogleController from '../../functions/google.controller'

import NotificationContext from '../../context/notifications.context';
import { InventoryScreen } from './inventoryScreen.component';
import * as InventoryController from '../../functions/Inventory.controller'
import { InventoryCategory, InventoryItem, InventoryStock, CustomPricing } from '../../models/Inventory.model';
import { InventoryCategoryScreen } from './inventoryCategoryScreen.component';


export const InventoryContainerScreen = ({ navigation }: any) => {
    const [useNewView, setUseNewView] = useState(false);

    return (
        <Layout style={{ flex: 1, flexDirection: 'column' }}>
            <Layout style={{ flex: 1 }}>
                <InventoryCategoryScreen navigation={navigation} />
            </Layout>
            {/* <Layout style={{ padding: 10 }}>
                <Toggle
                    checked={useNewView}
                    onChange={() => setUseNewView(!useNewView)}
                >
                    {useNewView ? 'Old Inventory View' : 'New Inventory View'}
                </Toggle>
            </Layout>

            
            {useNewView ? (
                <Layout style={{ flex: 1 }}>
                    <InventoryCategoryScreen navigation={navigation} />
                </Layout>
            ) : (
                // ... existing InventoryScreen ...
                <Layout style={{ flex: 5 }}>
                    <InventoryScreen style={{ flex: 1, borderWidth: 1, borderColor: "#000" }} navigation={navigation} />
                </Layout>
            )} */}
        </Layout>
    )
}