import { LINE_ITEM_BILLED_TO } from "./Job.model";

// support old line item format
// if (item.hasOwnProperty("billed_to")) {
//     if (item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST) {
//         total += item.cost;
//     }
// } else {
// }


enum LINE_ITEM_INCOME_OR_EXPENSE {
    INCOME = "income",
    EXPENSE = "expense"
}

enum LINE_ITEM_EXPENSE_TYPE {
    NONE = "none",
    DRIVER_PAYMENT = "driver",
    OTHER = "other",
    REBATE = "rebate",
    COMMISSION = "commission"
}

export class LineItem {
    _id: string;
    name: string;
    description: string;
    cost: number;
    billed_to: LINE_ITEM_BILLED_TO;
    quantity: number;
    paid: boolean;
    type: string;
    customerCost?: boolean;
    billAllBack?: boolean;
    billing?: string; //used to display in the report - not set or used in calculations

    inventoryItemId?: string | null;
    inventoryStockId?: string | null;
    customPricingId?: string | null;
    is_gst_inclusive?: boolean;
    picked?: boolean;
    income_or_expense?: LINE_ITEM_INCOME_OR_EXPENSE;
    expense_type?: LINE_ITEM_EXPENSE_TYPE;


    constructor(item: Partial<LineItem> = {}) {
        this._id = item?._id ? item._id : Math.random().toString(36).substring(7);
        this.name = item?.name || "";
        this.description = item?.description || "";
        this.cost = item?.cost || 0;
        if (item.billed_to) {
            this.billed_to = item.billed_to;
            this.customerCost = item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST;
            this.billAllBack = item.billed_to == LINE_ITEM_BILLED_TO.BILL_ALL_BACK;
            this.billing = item.billed_to == LINE_ITEM_BILLED_TO.CUSTOMER_COST ? "Customer Cost" : "Bill All Back";
        } else {
            this.billed_to = item.customerCost ? LINE_ITEM_BILLED_TO.CUSTOMER_COST : LINE_ITEM_BILLED_TO.BILL_ALL_BACK;
            this.billAllBack = item.billAllBack || false;
            this.customerCost = item.customerCost || false;
            this.billing = item.billing || "";
        }
        this.quantity = item?.quantity || 0;
        this.paid = item?.paid || false;
        this.type = item?.type || "item";

        this.inventoryItemId = item?.inventoryItemId || null;
        this.inventoryStockId = item?.inventoryStockId || null;
        this.customPricingId = item?.customPricingId || null;
        this.is_gst_inclusive = item?.is_gst_inclusive ?? true;
        this.picked = item?.picked || false;
        this.income_or_expense = item?.income_or_expense || LINE_ITEM_INCOME_OR_EXPENSE.INCOME;
        this.expense_type = item?.expense_type || LINE_ITEM_EXPENSE_TYPE.NONE;
        // if cost is not a number, make it a number    
        if (typeof this.cost !== 'number') {
            try {
                this.cost = parseFloat(this.cost);
            } catch (error) {
                console.log(error);
                this.cost = 0;
            }
        }

        // if quantity is not a number, make it a number    
        if (typeof this.quantity !== 'number') {
            try {
                this.quantity = parseInt(this.quantity);
            } catch (error) {
                console.log(error);
                this.quantity = 0;
            }
        }
    }

    getGSTOnlyAmount() {
        let gstAmount = 0;
        const totalCost = (this.cost * this.quantity);
        if (this.is_gst_inclusive) {
            gstAmount = totalCost / 1.1;
            gstAmount = totalCost - gstAmount;
        } else {
            gstAmount = totalCost * 0.1;
        }

        return gstAmount;
    }

    getCostWithGST() {
        if (this.is_gst_inclusive) {
            return this.cost * this.quantity;
        } else {
            return this.cost * this.quantity * 1.1;
        }
    }

    getCostWithoutGST() {
        if (this.is_gst_inclusive) {
            return this.cost * this.quantity / 1.1;
        } else {
            return this.cost * this.quantity;
        }
    }

    getTotalCost() {
        return this.cost * this.quantity;
    }

}
