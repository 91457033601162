import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
//@ts-ignore
import { Text, IndexPath } from "@ui-kitten/components";
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as ClientController from '../../../functions/client.controller'
import * as VendorProfileController from '../../../functions/vendorProfile.controller'
import { CLIENT_RATE_BILLED_TO, Job, JobClientRate } from '../../../models/Job.model';
import { Client, ClientSettings } from '../../../models/Client.model';
import { VendorJobClientDetailsCard } from './client/VendorJobClientDetailsCard.component';
import { LocalClientJobClientDetailsCard } from './client/LocalClientJobClientDetailsCard.component';
import { ClientCreatedJobClientDetailsCard } from './client/ClientCreatedJobClientDetailsCard.component';
import { NewJobClientDetailsCard } from './client/NewJobClientDetailsCard.component';

interface ClientDetailsCardProps {
    job: Job | null;
    clientReferenceId: string | null;
    jobCreatedBy: string;
    fn_onSetClient: (e: Client) => void;
    fn_onSetClientRate: (e: JobClientRate) => void;
    fn_onSetClientRefrerence: (e: string) => void;
    externalRefNumber?: string | null;
    allowEdit: boolean;
    isNewJob: boolean;
    jobCreateSource: string;
    style: any;
    setRefs: (e: any) => void;
    onInputFocus: (e: any) => void;
}

export const ClientDetailsCard = ({
    job = null as Job | null,
    clientReferenceId = null as string | null,
    jobCreatedBy = "vendor",

    fn_onSetClient = (e: any) => { },
    fn_onSetClientRate = (e: JobClientRate) => { },
    fn_onSetClientRefrerence = (e: any) => { },
    allowEdit = true,
    externalRefNumber = null as string | null,
    isNewJob = false,
    jobCreateSource = "vendor", //if new job need this to determine if vendor or local client
    style = { flex: 1 },
    setRefs = (e: any) => { },
    onInputFocus = (e: any) => { },
}: ClientDetailsCardProps) => {
    const [JobClientRateState, setJobClientRateState] = useState(new JobClientRate(job?.details?.client_rate))
    //@ts-ignore
    const [clientRateIndex, setClientRateIndex] = useState(new IndexPath(0) as any)
    //@ts-ignore
    const [clientsSelectIndexPath, setClientsSelectIndexPath] = useState(new IndexPath(0) as any)
    const [clientRateOptions, setClientRateOptions] = useState([] as JobClientRate[])
    const [hasChosenClient, setHasChosenClient] = useState(false)
    const [hasChosenClientRate, setHasChosenClientRate] = useState(false)
    const [linkedClientCompany, setLinkedClientCompany] = useState(null as any)
    const [isOwnVendorJob, setIsOwnVendorJob] = useState(jobCreatedBy == "vendor")
    const [isLocalClientJob, setIsLocalClientJob] = useState(jobCreatedBy == "local_client")
    const [isClientCreatedJob, setIsClientCreatedJob] = useState(jobCreatedBy == "client")
    const [showCustomClientRate, setShowCustomClientRate] = useState(false)
    const [clients, setClients] = useState([] as Client[])
    const memoizedClients = useMemo(() => clients, [clients])
    const clientReferenceIdRef = useRef(null as any);

    const [canChangeClient, setCanChangeClient] = useState(true)

    // const [clientDisplayValue, setClientDisplayValue] = useState( clients ? clients?.[clientsSelectIndexPath?.row]?.name : "---- Select Client ----")

    const clientDisplayValue = clients ? clients?.[clientsSelectIndexPath?.row]?.name : "---- Select Client ----"
    const clientRatesDisplayValue = clients ? `${clients[clientsSelectIndexPath?.row]?.settings?.rates?.[clientRateIndex?.row]?.name || "NO RATE FOUND"} : $${memoizedClients[clientsSelectIndexPath?.row]?.settings?.rates?.[clientRateIndex?.row]?.cost || "0"}` : null

    const getClientRateDisplayValue = useCallback(() => {
        if (!job) return null

        if (job.client_id && job.details.client_rate) {
            return `${job.details.client_rate.name} : $${job.details.client_rate.cost}`
        }
        if (clients) {
            return `${clients[clientsSelectIndexPath?.row]?.settings?.rates?.[clientRateIndex?.row]?.name || "NO RATE FOUND"} : $${memoizedClients[clientsSelectIndexPath?.row]?.settings?.rates?.[clientRateIndex?.row]?.cost || "0"}`
        }
        return null
    }, [job, clients, clientsSelectIndexPath, clientRateIndex])

    useEffect(() => {
        try {
            if (setRefs) setRefs([clientReferenceId]);
            if (isNewJob) {
                loadNewJobClients()
            } else {
                loadExistingJob()
            }
        } catch (e) {
            console.log("🚀============== ~ file: ClientDetailsCard.component.tsx:101 ~ useEffect ~ e🚀==============", e)
        }
    }, [])

    useEffect(() => {
        setJobClientRateState(new JobClientRate(job?.details?.client_rate))
    }, [job?.details?.client_rate])

    useEffect(() => {
        if (clientReferenceId && clientReferenceIdRef.current) {
            clientReferenceIdRef.current.value = clientReferenceId;
        }
    }, [clientReferenceId]);

    useEffect(() => {

    }, [])

    const onSetClient = (client: Client) => {
        if (!client) return
        fn_onSetClient(client)
        // setClientDisplayValue(client?.name || "---- Select Client ----")
        setHasChosenClient(true)
        setHasChosenClientRate(false)
        setClientRateOptions(client?.settings?.rates || [])
        setClientRateIndex(new IndexPath(0))
        onSetClientRate(client.settings?.rates?.[0] || new JobClientRate({}))
    }

    const onSetClientRate = (client_rate: JobClientRate) => {
        client_rate.billed_to = client_rate.billed_to
        const c = new JobClientRate(client_rate)
        setHasChosenClientRate(true)
        setJobClientRateState(c)
        fn_onSetClientRate(c)
    }

    const loadNewJobClients = async () => {
        if (jobCreateSource == "vendor") {
            let c = await ClientController.getClientsByCompanyId(StorageController.getCurrentCompany()._id)
            console.log("🚀============== ~ file: ClientDetailsCard.component.tsx:117 ~ loadNewJobClients ~ c🚀==============", c)
            setClients(c)
        }
        else if (jobCreateSource == "local_client") {
            const member = StorageController.getAppState().selectedMembership
            if (!member) return
            const client_id = member.client_id
            try {
                const client = await ClientController.getClientById(client_id)
                setClients([client])
                setClientsSelectIndexPath(new IndexPath(0))
                setHasChosenClient(true)
                setHasChosenClientRate(false)
                setClientRateOptions(client.settings?.rates)
                setClientRateIndex(new IndexPath(0))
                onSetClient(client)
            } catch (err) {
                console.log("🚀============== ~ file: ClientDetailsCard.component.js:167 ~ loadNewJobClients ~ err🚀==============", err)
            }
        }
        return
    }

    const loadExistingJob = async () => {
        if (!job) return
        setIsOwnVendorJob(jobCreatedBy == "vendor")
        setIsLocalClientJob(jobCreatedBy == "local_client")
        setIsClientCreatedJob(jobCreatedBy == "client")

        let _canChangeClient = true
        if (job.company_id != StorageController.getCurrentCompany()._id) {
            _canChangeClient = false
            setCanChangeClient(_canChangeClient)
        }

        if (job.client_company_id) {
            const _linkedClientCompany = await CompanyController.getCompanyById(job.client_company_id)
            setLinkedClientCompany(_linkedClientCompany)
        }
        let _clients = StorageController.getCurrentCompany()?.clients || [] as Client[]
        // _clients = await ClientController.getClientsByCompanyId(StorageController.getCurrentCompany()._id)
        // console.log("🚀============== ~ file: ClientDetailsCard.component.tsx:152 ~ loadExistingJob ~ _clients🚀==============", _clients)

        if (isClient()) {
            _clients = _clients.filter((client) => client._id === StorageController.getAppState().selectedMembership?.client_id)
        }

        if (!_canChangeClient) {
            _clients = _clients.filter((client) => client._id === job.client_id)
        }

        setClients(_clients)
        const clientIndex = _clients.findIndex((client) => client._id === job.client_id);

        const selectedIndexPath = clientIndex !== -1 ?
            new IndexPath(clientIndex) as any :
            new IndexPath(0) as any;
        if (clientIndex === -1) {
            setClientsSelectIndexPath(null);
            // onClientSelected(selectedIndexPath)
        } else {
            // setClientsSelectIndexPath(selectedIndexPath);
            onClientSelected(selectedIndexPath)
        }

        let current_rate = new JobClientRate(job.details.client_rate)
        const clientRateIndex = _clients[selectedIndexPath.row]?.settings?.rates?.findIndex((rate: JobClientRate) => rate.name === current_rate?.name);
        const selectedClientRateIndexPath = clientRateIndex !== -1 ? new IndexPath(clientRateIndex) : null;
        setClientRateOptions(_clients[selectedIndexPath.row]?.settings?.rates || [])

        if (selectedClientRateIndexPath !== null) {
            setClientRateIndex(selectedClientRateIndexPath);
            setHasChosenClientRate(true)
        }
        else {
            let _clientRateOptions = _clients[selectedIndexPath.row]?.settings?.rates
            _clientRateOptions.push(current_rate)
            setClientRateOptions(_clientRateOptions)
            const clientRateIndex = _clientRateOptions.findIndex((rate: JobClientRate) => rate.name === current_rate?.name);
            const selectedClientRateIndexPath = clientRateIndex !== -1 ? new IndexPath(clientRateIndex) : null;
            setClientRateIndex(selectedClientRateIndexPath);
            setHasChosenClientRate(true)
        }
    }

    const getClientRates = async (selectedClient: Client, index: any) => {
        if (!selectedClient) return
        let rates = []
        if (selectedClient.linkedClientAccountId) {
            const selectedClientsVendorProfile = await VendorProfileController.getVendorProfileByCompanyIdAndLinkedVendorAccountId(
                selectedClient.linkedClientAccountId,
                StorageController.getCurrentCompany()._id
            )
            if (selectedClientsVendorProfile) {
                rates = selectedClientsVendorProfile.settings?.rates || []
            }
        }
        if (selectedClient.settings?.rates) {
            rates = [...rates, ...selectedClient.settings.rates]
        }
        let _clients = [...clients]
        if (!_clients[index.row].settings) _clients[index.row].settings = new ClientSettings()
        if (!_clients[index.row].settings.rates) _clients[index.row].settings.rates = []
        _clients[index.row].settings.rates = rates
        setClients(_clients)
        setClientRateOptions(rates)
    }

    const onClientSelected = (index: any) => {
        try {
            setClientsSelectIndexPath(index)
            setHasChosenClient(true)
            setHasChosenClientRate(false)
            getClientRates(clients[index.row], index)
            onSetClient(clients[index.row])
            setShowCustomClientRate(false)
            setClientRateIndex(new IndexPath(0))
        } catch (e) {
            console.log("🚀============== ~ file: ClientDetailsCard.component.tsx:247 ~ onClientSelected ~ e🚀==============", e)
        }
    }

    const onSetClientBilling = (billed_to: CLIENT_RATE_BILLED_TO) => {
        JobClientRateState.billed_to = billed_to
        onSetClientRate(JobClientRateState)
    }

    const onSetCustomerPaid = (customer_paid: boolean) => {
        JobClientRateState.customer_paid = customer_paid
        onSetClientRate(JobClientRateState)
    }

    const onSetClientRefrerenceValue = (value: any) => {
        fn_onSetClientRefrerence(value)
    }

    const onClientRateSelectedIndex = (index: any) => {
        setHasChosenClientRate(true)
        setClientRateIndex(index)
        onSetClientRate(clientRateOptions[index.row])
    }

    const isClient = () => {
        if (StorageController.getAppState().selectedMembership?.is_client) return true
        return false
    }

    const commonProps = {
        style,
        clientRateOptions,
        clientRateIndex: clientRateIndex || new IndexPath(0),
        clientRatesDisplayValue: getClientRateDisplayValue() || "",
        onClientRateSelectedIndex,
        clientReferenceId,
        jobCreateSource,
        onSetClientRefrerenceValue,
        JobClientRateState,
        onSetClientBilling,
        onSetCustomerPaid,
        showCustomClientRate,
        allowEdit,
        onSetClientRate,
        setShowCustomClientRate,
        onInputFocus,
        hasChosenClient,
        hasChosenClientRate,
        externalRefNumber,
    };

    if (isNewJob) {
        console.log("🚀============== ~ file: ClientDetailsCard.component.tsx:290 ~ isNewJob🚀==============", isNewJob)
        return <NewJobClientDetailsCard
            {...commonProps}
            onClientSelected={onClientSelected}
            clients={memoizedClients}
            clientsSelectIndexPath={clientsSelectIndexPath}
            clientDisplayValue={clientDisplayValue}
            jobCreateSource={jobCreateSource as "vendor" | "client" | "local_client"}
        // hasChosenClient={hasChosenClient}
        // hasChosenClientRate={hasChosenClientRate}
        />
    }

    if (isOwnVendorJob) {
        console.log("🚀============== ~ file: ClientDetailsCard.component.tsx:294 ~ isOwnVendorJob🚀==============", isOwnVendorJob)
        return <VendorJobClientDetailsCard
            {...commonProps}
            clients={memoizedClients}
            clientsSelectIndexPath={clientsSelectIndexPath}
            clientDisplayValue={clientDisplayValue}
            onClientSelected={onClientSelected}
            jobCreateSource={jobCreateSource as "vendor" | "client" | "local_client"}
        />
    }

    if (isLocalClientJob) {
        console.log("🚀============== ~ file: ClientDetailsCard.component.tsx:294 ~ isLocalClientJob🚀==============", isLocalClientJob)
        return <LocalClientJobClientDetailsCard
            {...commonProps}
            clientDisplayValue={clientDisplayValue || "---- Select Client ----"}
            jobCreateSource={jobCreateSource as "vendor" | "client" | "local_client"}
        />
    }

    if (isClientCreatedJob) {
        console.log("🚀============== ~ file: ClientDetailsCard.component.tsx:294 ~ isClientCreatedJob🚀==============", isClientCreatedJob)
        return <ClientCreatedJobClientDetailsCard
            {...commonProps}
            linkedClientCompany={linkedClientCompany}
            jobCreateSource={jobCreateSource as "vendor" | "client" | "local_client"}
        />
    }

    return <Text>Client Details Card</Text>;
}
