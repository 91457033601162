import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from "@ui-kitten/components";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, OverlayView, TrafficLayer, MarkerClusterer, Polygon } from '@react-google-maps/api';
import * as UpdateController from '../../functions/update.controller';
import * as UserController from '../../functions/user.controller';
import * as StorageController from "../../functions/storageController"
import * as CompanyController from "../../functions/company.controller"
import * as MessageController from "../../functions/message.controller"
import * as GoogleController from "../../functions/google.controller"
import * as JobsController from '../../functions/job.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _ from 'lodash'
import Toast from 'react-native-toast-message';
// import { AddressSearch } from './searchAddress.component';
import DistanceTool from './distanceTool.component';
import { set } from 'react-native-reanimated';
import { User } from '../../models/User.model';
import { useAppStateContext } from '../../contexts/AppStateContext';
import { Job, JOB_STATUS } from '../../models/Job.model';
import { Client } from '../../models/Client.model';
import { JobDetailContainer } from '../job/details/jobDetailContainer.component';
import { FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { JobReportModal } from '../dashboardComponents/jobReportModal.component';
import DMModal from '../common/Modal';
import { MemberDeviceInfoCard } from '../members/memberDeviceInfoCard.component';
import { Badge } from '../job/components/Badge.component';
interface DriverInfoWindowProps {
    onClose: () => void;
    driver: any;
    selectDriverChat?: (driver: any) => void;
    enableChat?: boolean;
}


export const DriverInfoWindow = ({
    onClose,
    driver,
    selectDriverChat = () => { },
    enableChat = false,
}: DriverInfoWindowProps) => {
    const theme = useTheme();
    const [location, setLocation] = useState({ lat: 0, lng: 0 });
    const [timestamp, setTimestamp] = useState(new Date().getTime());
    const [heading, setHeading] = useState(0);
    const [user, setUser] = useState(null as User | null);
    const [speed, setSpeed] = useState(0);
    const [userLoaded, setUserLoaded] = useState(false);
    const [jobs, setJobs] = useState([] as Job[]);
    const { getCurrentJobs, getCurrentClients } = useAppStateContext();

    const [showJobDetailsModal, setShowJobDetailsModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null as Job | null);

    useEffect(() => {
        if (getCurrentJobs()) {
            const ignoreStatuses = [JOB_STATUS.COMPLETE, JOB_STATUS.CANCELLED, JOB_STATUS.TRANSFERRED_OUT]
            const jobs = getCurrentJobs()?.filter((job: Job) => job.member_id == driver._id && !ignoreStatuses.includes(job.status))
            console.log("🚀============== ~ file: driverInfoWindow.component.tsx:79 ~ useEffect ~ jobs🚀==============", jobs)
            setJobs(jobs)
        }
    }, [])

    const getClientForJob = useCallback((job: Job) => {
        return getCurrentClients()?.find((client: Client) => client._id == job.client_id)
    }, [getCurrentClients])

    const onSelectJob = (job: Job) => {
        setSelectedJob(job)
        setShowJobDetailsModal(true)
    }

    const closeJobDetailsModal = () => {
        setShowJobDetailsModal(false)
        setSelectedJob(null)
    }

    const RenderMemoizedJobDetails = useMemo(() => {
        if (!selectedJob) return null
        if (selectedJob.status == JOB_STATUS.COMPLETE || selectedJob.status == JOB_STATUS.CANCELLED || selectedJob.status == JOB_STATUS.TRANSFERRED_OUT) {
            return (
                <JobReportModal
                    showReportDetailsModal={showJobDetailsModal}
                    setShowReportDetailsModal={closeJobDetailsModal}
                    job={selectedJob}
                />
            )
        }
        return (
            <DMModal
                visible={showJobDetailsModal}
                onClose={closeJobDetailsModal}
                style={{ width: "90%" }}
            >
                <JobDetailContainer
                    selectedJob={selectedJob}
                    fn_closeJobDetailsModal={closeJobDetailsModal}
                />
            </DMModal>
        )
    }, [driver, showJobDetailsModal, selectedJob])


    useEffect(() => {
        if (driver?.location?.coords) {
            setLocation({
                lat: driver?.location?.coords.latitude,
                lng: driver?.location?.coords.longitude,
            });
            setTimestamp(driver?.location?.timestamp)
            setHeading(driver?.location?.coords?.heading)
            setSpeed(driver?.location?.coords?.speed)
        } else if (driver?.details?.location?.coords) {
            setLocation({
                lat: driver?.details?.location?.coords.latitude,
                lng: driver?.details?.location?.coords.longitude,
            });
            setTimestamp(driver?.details?.location?.timestamp)
            setHeading(driver?.details?.location?.coords?.heading)
            setSpeed(driver?.details?.location?.coords?.speed)
        }
        if (!userLoaded) {
            loadUser();
            setUserLoaded(true);
        }
    }, [driver])

    const loadUser = async () => {
        const user = await UserController.getUser(driver.user_id) as User;
        if (user) {
            setUser(user as User);
        }
    }
    const CorrectIcon = (props: any) => <Icon {...props} name="checkmark-circle-2-outline" fill="green" width={24} height={24} />
    const IncorrectIcon = (props: any) => <Icon {...props} name="close-circle-outline" fill="red" width={24} height={24} />

    return (
        <InfoWindow
            position={{
                lat: location.lat,
                lng: location.lng,
            }}
            onCloseClick={() => {
                onClose();
            }}
            options={{
                // backgroundColor: theme['background-basic-color-1'],
                // padding: '0px',
                // borderRadius: '8px',
                // maxWidth: '400px'
            }}
        >
            <ErrorBoundary>
                <Layout style={{
                    padding: 0,
                    borderRadius: 8,
                    backgroundColor: theme['background-basic-color-1'],
                    minWidth: 280,
                    maxWidth: 400,
                }}>
                    {RenderMemoizedJobDetails}
                    {jobs.length > 0 &&
                        <View style={{ marginVertical: 4 }}>
                            {jobs.map((job: Job) => (
                                <View key={job._id} style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: 4,
                                    backgroundColor: theme['background-basic-color-2'],
                                    padding: 8,
                                    borderRadius: 4
                                }}>
                                    <View style={{ flex: 1 }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                                            <Badge status={job.status} />
                                            <Text category="s2" style={{ fontWeight: 'bold' }}>{getClientForJob(job)?.name}</Text>
                                        </View>
                                        <Text category="c1">{job?.details?.address?.suburb}</Text>
                                    </View>
                                    <Button
                                        size="tiny"
                                        appearance="ghost"
                                        status="primary"
                                        onPress={() => onSelectJob(job)}
                                        style={{ marginLeft: 8 }}
                                    >
                                        View
                                    </Button>
                                </View>
                            ))}
                        </View>
                    }
                    <MemberDeviceInfoCard member={driver} />
                </Layout>
            </ErrorBoundary>
        </InfoWindow>
    )
}