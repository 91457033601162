import React, { useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle, Input, Select, SelectItem, Layout, Button, Spinner, Divider } from '@ui-kitten/components';
import { Company } from '../../models/Company.model';
import { CompanySecret, WebhookConfig } from '../../models/CompanySecret.model';
import * as CompanySecretController from '../../functions/companySecret.controller';
import axios from 'axios';
import { useNotification } from '../../context/notifications.context';

// Create a clean axios instance for webhook tests
const webhookAxios = axios.create();

interface WebhookSettingsProps {
    company: Company;
    onSetCompanySecret: (companySecret: CompanySecret) => void;
    style?: any;
}

export const WebhookSettingsComponent: React.FC<WebhookSettingsProps> = ({
    company,
    onSetCompanySecret,
    style = {}
}) => {
    const [companySecret, setCompanySecret] = useState(null as CompanySecret | null);
    const companySecretRef = useRef(companySecret);
    const [isTesting, setIsTesting] = useState(false);
    const { showNotification } = useNotification();

    useEffect(() => {
        setCompanySecret(null);
        load();
    }, [company]);

    const load = async () => {
        if (!company || !company._id) return;
        const companySecret = new CompanySecret(await CompanySecretController.findByCompany(company?._id));
        console.log("🚀============== ~ file: webhookSettings.component.tsx:37 ~ load ~ companySecret🚀==============", companySecret)
        setCompanySecret(companySecret);
        companySecretRef.current = companySecret
    };

    const _onSetCompanySecret = async (companySecret: CompanySecret) => {
        companySecretRef.current = companySecret;
        setCompanySecret(companySecret);
        onSetCompanySecret(companySecret);
    };

    const updateWebhookSetting = (key: keyof WebhookConfig, value: any) => {
        const updatedWebhook = new WebhookConfig({
            ...companySecretRef.current?.webhook,
            [key]: value
        });
        const updatedCompanySecret = new CompanySecret({
            ...companySecretRef.current,
            webhook: updatedWebhook
        });
        _onSetCompanySecret(updatedCompanySecret);
    };

    const httpMethods = [
        { title: 'POST', value: 'POST' },
        { title: 'GET', value: 'GET' },
        { title: 'PUT', value: 'PUT' },
        { title: 'PATCH', value: 'PATCH' },
    ];

    const authTypes = [
        { title: 'None', value: 'none' },
        { title: 'Bearer Token', value: 'bearer' },
        { title: 'Basic Auth', value: 'basic' }
    ];

    const testWebhook = async () => {
        if (!companySecret?.webhook.url) {
            showNotification('Error', 'Please enter a webhook URL first');
            return;
        }

        setIsTesting(true);
        try {
            const config: any = {
                timeout: companySecret.webhook.timeout || 5000,
                headers: {}
            };

            // Add authentication headers if configured
            if (companySecret.webhook.auth_type === 'bearer' && companySecret.webhook.bearer_token) {
                config.headers['Authorization'] = `Bearer ${companySecret.webhook.bearer_token}`;
            } else if (companySecret.webhook.auth_type === 'basic' &&
                companySecret.webhook.basic_auth_username &&
                companySecret.webhook.basic_auth_password) {
                const auth = btoa(`${companySecret.webhook.basic_auth_username}:${companySecret.webhook.basic_auth_password}`);
                config.headers['Authorization'] = `Basic ${auth}`;
            }

            // Prepare test payload
            const testPayload = {
                test: true,
                timestamp: new Date().toISOString(),
                company_id: company._id,
                message: 'This is a test webhook notification'
            };

            const method = (companySecret.webhook.method || 'POST').toLowerCase();
            // Use the clean axios instance instead
            //@ts-ignore
            const response = await webhookAxios[method](companySecret.webhook.url, testPayload, config);

            showNotification(
                'Success',
                `Webhook test successful! Status: ${response.status}\nResponse: ${JSON.stringify(response.data).slice(0, 100)}`
            );
        } catch (error: any) {
            let errorMessage = 'Unknown error occurred';
            if (error.response) {
                // Server responded with error
                errorMessage = `Server responded with status ${error.response.status}: ${JSON.stringify(error.response.data)}`;
            } else if (error.request) {
                // Request made but no response
                errorMessage = `No response received (timeout: ${companySecret.webhook.timeout}ms)`;
            } else {
                // Error setting up request
                errorMessage = error.message;
            }
            showNotification('Error', `Webhook test failed: ${errorMessage}`);
        } finally {
            setIsTesting(false);
        }
    };

    return (
        <Card style={style} disabled status="basic">
            <View style={{ flexDirection: 'column', gap: 15 }}>
                {/* Header with Enable Toggle */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text category='h6'>Basic Webhook Settings</Text>
                    <Toggle
                        checked={companySecret?.webhook.enabled}
                        onChange={(nextChecked) => updateWebhookSetting('enabled', nextChecked)}
                    >
                        Enable Webhook
                    </Toggle>
                </View>
                <Divider />
                <Text category='c1'>
                    Webhooks are used to notify you when certain events occur in your company.
                </Text>
                <Text category='c1' appearance='hint'>
                    You can also use webhooks to trigger actions in external services.
                    Use the "Test Webhook" button to verify that your webhook is working.
                </Text>

                {companySecret?.webhook.enabled && (
                    <>
                        {/* Main Content Split */}
                        <Layout style={{ flexDirection: 'row', gap: 20 }}>
                            {/* Left Side - Request Configuration */}
                            <Layout style={{ flex: 3, gap: 10 }}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Text category='s1'>Request Configuration</Text>
                                    <Button
                                        size='small'
                                        status='info'
                                        disabled={isTesting || !companySecret?.webhook.url}
                                        accessoryLeft={isTesting ? (props) => <Spinner size='tiny' /> : undefined}
                                        onPress={testWebhook}
                                    >
                                        {isTesting ? 'Testing...' : 'Test Webhook'}
                                    </Button>
                                </View>

                                {/* Method and URL on same row */}
                                <View style={{ flexDirection: 'row', gap: 10 }}>
                                    <Select
                                        style={{ flex: 1 }}
                                        label="Method"
                                        value={companySecret?.webhook.method || 'POST'}
                                        //@ts-ignore
                                        onSelect={(index) => updateWebhookSetting('method', httpMethods[index.row].value)}
                                    >
                                        {httpMethods.map(method => (
                                            <SelectItem key={method.value} title={method.title} />
                                        ))}
                                    </Select>

                                    <Input
                                        style={{ flex: 3 }}
                                        label="Webhook URL"
                                        placeholder="https://your-api-endpoint.com/webhook"
                                        value={companySecret?.webhook.url}
                                        onChangeText={(nextValue) => updateWebhookSetting('url', nextValue)}
                                    />
                                </View>

                                <Input
                                    label="Timeout (ms)"
                                    placeholder="5000"
                                    keyboardType="numeric"
                                    value={companySecret?.webhook.timeout.toString()}
                                    onChangeText={(nextValue) => updateWebhookSetting('timeout', parseInt(nextValue) || 5000)}
                                />
                            </Layout>

                            {/* Right Side - Authentication */}
                            <Layout style={{ flex: 2, gap: 10 }}>
                                <Text category='s1'>Authentication</Text>

                                <Select
                                    label="Auth Type"
                                    value={authTypes.find(type => type.value === companySecret?.webhook.auth_type)?.title}
                                    //@ts-ignore
                                    onSelect={(index) => updateWebhookSetting('auth_type', authTypes[index.row].value)}
                                >
                                    {authTypes.map(type => (
                                        <SelectItem key={type.value} title={type.title} />
                                    ))}
                                </Select>

                                {companySecret?.webhook.auth_type === 'bearer' && (
                                    <>
                                        <Input
                                            label="Bearer Token"
                                            placeholder="Enter your bearer token"
                                            value={companySecret?.webhook.bearer_token}
                                            onChangeText={(nextValue) => updateWebhookSetting('bearer_token', nextValue)}
                                        />
                                        <Text appearance="hint" category='c1'>
                                            Header: Authorization: Bearer {companySecret?.webhook.bearer_token}
                                        </Text>
                                    </>
                                )}

                                {companySecret?.webhook.auth_type === 'basic' && (
                                    <>
                                        <Input
                                            label="Username"
                                            placeholder="Username"
                                            value={companySecret?.webhook.basic_auth_username}
                                            onChangeText={(nextValue) => updateWebhookSetting('basic_auth_username', nextValue)}
                                        />
                                        <Input
                                            label="Password"
                                            placeholder="Password"
                                            secureTextEntry
                                            value={companySecret?.webhook.basic_auth_password}
                                            onChangeText={(nextValue) => updateWebhookSetting('basic_auth_password', nextValue)}
                                        />
                                    </>
                                )}
                            </Layout>
                        </Layout>
                    </>
                )}
            </View>
        </Card>
    );
};
