import React from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle } from '@ui-kitten/components';
import { Company, CompanyPartsNetworkSettings } from '../../models/Company.model';

interface CompanyPartsNetworkSettingsProps {
    company: Company;
    onUpdatePartsNetworkSettings: (updatedSettings: CompanyPartsNetworkSettings) => void;
    style?: any;
}

export const CompanyPartsNetworkSettingsComponent: React.FC<CompanyPartsNetworkSettingsProps> = ({
    company,
    onUpdatePartsNetworkSettings,
    style = {}
}) => {
    const updateSettings = (enabled: boolean) => {
        const updatedSettings = new CompanyPartsNetworkSettings({
            ...company.settings.parts_network,
            is_enabled: enabled
        });
        onUpdatePartsNetworkSettings(updatedSettings);
    };

    return (
        <Card
            style={[style, { flex: 1 }]}
            disabled
            header={() => <Text category='h6'>Parts Network</Text>}
            status="basic"
        >
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text category='s1'>Enable Parts Network</Text>
                <Toggle
                    checked={company.settings.parts_network?.is_enabled || false}
                    onChange={updateSettings}
                />
            </View>
        </Card>
    );
}; 