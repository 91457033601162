import React from 'react';
import { View } from 'react-native';
import { Text, Layout, Icon } from "@ui-kitten/components";

interface ImageFieldProps {
    field: string,
    value: string | null,
    label: string,
    disabled: boolean
}

export const ImageField = ({
    field,
    value,
    label,
    disabled
}: ImageFieldProps) => {
    return (
        <Layout style={{ padding: 10, borderRadius: 5, borderWidth: 1, borderColor: '#ccc' }}>
            <Text category='label'>{label}</Text>
            <View style={{ 
                height: 20, 
                justifyContent: 'center', 
                alignItems: 'center',
                backgroundColor: '#f5f5f5',
                borderRadius: 5
            }}>
                <Icon 
                    name='image-outline'
                    width={20}
                    height={20}
                    fill='#999'
                />
            </View>
        </Layout>
    )
} 