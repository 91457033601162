import React, { useState, useEffect, useRef, useReducer, useCallback, createContext, useContext, RefObject } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
import { Divider, Icon, Layout, Text, Card, Button, Input, Modal, Select, SelectItem, List, ListItem, Toggle } from '@ui-kitten/components';

import * as ClientController from "../../functions/client.controller";
import * as StorageController from "../../functions/storageController";
import * as CompanyController from "../../functions/company.controller";
import { Client, ClientSettings, ClientContactInfo, ClientOther } from "../../models/Client.model";
import { Company, CompanySavedAddress } from '../../models/Company.model';
import { CLIENT_RATE_BILLED_TO, JobClientRate, JobAddress, JobLocation } from '../../models/Job.model';
// import AddressSearch from '../job/details/addressSearch.component';
import DMModal from '../common/Modal';
import { ClientRateModal } from './clientRateModal.component';
import { AddressDetailsCard } from '../common/address/AddressComponents';



const useInputState = (initialValue = '') => {
    const [value, setValue] = React.useState(initialValue);
    return { value, onChangeText: setValue };
};


interface ClientEditScreenProps {
    var_selectedClientToEdit: Client;
    fn_onEditClient: (client: Client) => void;
    fn_onDeleteClient: (client: Client) => void;
}


export const ClientEditScreen = ({
    var_selectedClientToEdit,
    fn_onEditClient,
    fn_onDeleteClient,
}: ClientEditScreenProps) => {
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [client, setClient] = useState<Client | null>(null);
    const nameRef = useRef("" as any);
    const emailRef = useRef("" as any);
    const phoneRef = useRef("" as any);
    const addressRef = useRef("" as any);
    const accountCodeRef = useRef("" as any);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [clientCompanyLinkedCompany, setClientCompanyLinkedCompany] = useState<Company | null>(null);
    const clientRef = useRef<Client | null>(client);
    let _client: Client = {} as Client;
    const { width, height } = useWindowDimensions();
    const [primaryContactEditMode, setPrimaryContactEditMode] = useState(false);
    const [secondaryContactEditMode, setSecondaryContactEditMode] = useState(false);

    useEffect(() => {
        onSetClient(var_selectedClientToEdit, false);
        _client = JSON.parse(JSON.stringify(client));
        if (var_selectedClientToEdit?.linkedClientAccountId) {
            getClientCompanyLinkedInfo();
        } else {
            setClientCompanyLinkedCompany(null);
        }
    }, [var_selectedClientToEdit]);

    const getClientCompanyLinkedInfo = async () => {
        try {
            // console.log("🚀 ~ file: clientEdit.component.js:47 ~ getClientCompanyLinkedInfo ~ Getting company:", client)
            if (!var_selectedClientToEdit.linkedClientAccountId) {
                setClientCompanyLinkedCompany(null)
                return
            }
            const company = await CompanyController.getCompanyById(var_selectedClientToEdit.linkedClientAccountId) as Company
            setClientCompanyLinkedCompany(company)
        } catch (e) {
            console.log(e)
        }
    }

    const onSetClient = (value: Client, changes = true) => {
        clientRef.current = value;
        setClient(value);
        console.log("🚀============== ~ file: clientEdit.component.tsx:78 ~ onSetClient ~ value🚀==============", value)
        if (changes) setUnsavedChanges(true);
    };

    const checkCanEditFields = () => {
        if (!client) return false;
        let canEdit = false;

        if (StorageController.getAppState().selectedMembership?.is_admin) {
            canEdit = true;
            if (!client.deleted) {
                canEdit = true;
            } else {
                canEdit = false;
            }
        }
        return canEdit;
    };

    const onCancel = () => {
        onSetClient(var_selectedClientToEdit, false);
        setUnsavedChanges(false);
    };

    const onSaveClientProfile = () => {
        ClientController.updateClient(clientRef.current as Client);
        fn_onEditClient(clientRef.current as Client);
        setUnsavedChanges(false);
        forceUpdate();
    };


    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

    const deleteClient = () => {
        if (!client) return
        ClientController.tagClientDeleted(client._id as string);
        fn_onDeleteClient(client!);
    };


    const DeleteClientModal = () => (
        <Modal
            visible={showConfirmDeleteModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setShowConfirmDeleteModal(false)}
        >
            <Card disabled={true}>
                <Text category='h6'>
                    Delete client?
                </Text>
                <Text>
                    Are you sure you want to delete this client?
                </Text>
                <Text>Past jobs will not be affected</Text>
                <Text status='warning'>This action cannot be undone.</Text>
                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        appearance='ghost'
                        onPress={() => setShowConfirmDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        status='danger'
                        onPress={() => {
                            setShowConfirmDeleteModal(false)
                            deleteClient()
                        }}>
                        YES - Delete
                    </Button>
                </Layout>
            </Card>
        </Modal>
    );



    /**
     * RATES
     */


    // split that
    const newRateNameRef = useRef<string>('');
    const newRateCostRef = useRef<number | string>('');
    const editRateIndexRef = useRef<number | null>(null);

    const [newRateError, setNewRateError] = useState("");
    const [isAddRateDisabled, setIsAddRateDisabled] = useState(true);

    const validateNewRate = useCallback(() => {
        if (!newRateNameRef.current) {
            setNewRateError("Name is required");
            setIsAddRateDisabled(true);
            return;
        }

        const cost = Number(newRateCostRef.current);
        if (isNaN(cost) || cost <= 0) {
            setNewRateError("Cost must be a positive number");
            setIsAddRateDisabled(true);
            return;
        }

        setNewRateError("");
        setIsAddRateDisabled(false);
    }, []);

    useEffect(() => {
        validateNewRate();
    }, [validateNewRate]);

    const handleAddRate = (rate: JobClientRate) => {
        if (!client) return;
        if (!client?.settings) client!.settings = new ClientSettings();
        if (!client?.settings.rates) client.settings.rates = [];
        const newRates = [...client.settings.rates, rate];
        const updatedClient = { ...client, settings: { ...client.settings, rates: newRates } };
        onSetClient(updatedClient);
        setShowNewRateModal(false);
        forceUpdate();
    };


    const handleDeleteRate = (index: number) => {
        if (!client) return
        const newRates = [...client.settings.rates];
        newRates.splice(index, 1);
        const updatedClient = { ...client, settings: { ...client.settings, rates: newRates } };
        onSetClient(updatedClient);
        // console.log("🚀 ~ file: clientEdit.component.js:131 ~ handleDeleteRate ~ updatedClient:", updatedClient)
        forceUpdate();
    };

    const onEditRate = (index: number) => {
        if (!client) return;
        editRateIndexRef.current = index;
        setShowEditRateModal(true);
    };

    const handleEditRate = (rate: JobClientRate) => {
        const newRates = [...client!.settings!.rates!];
        newRates[editRateIndexRef.current as number] = rate;
        const updatedClient = new Client({ ...client, settings: { ...client!.settings!, rates: newRates } });
        onSetClient(updatedClient);
        editRateIndexRef.current = null;
        setShowEditRateModal(false);
        forceUpdate();
    };




    const [showNewRateModal, setShowNewRateModal] = useState(false);
    const [showEditRateModal, setShowEditRateModal] = useState(false);

    const [showConfirmDeleteRateModal, setShowConfirmDeleteRateModal] = useState(false);
    const [rateToDelete, setRateToDelete] = useState(null as number | null);

    const DeleteRateModal = () => {
        return (
            <DMModal
                visible={showConfirmDeleteRateModal}
                onClose={() => setShowConfirmDeleteRateModal(false)}
            >
                <Card disabled={true}>
                    <Text category='h6'>
                        Delete rate?
                    </Text>
                    <Text>
                        Are you sure you want to delete this rate?
                    </Text>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button
                            appearance='ghost'
                            onPress={() => setShowConfirmDeleteRateModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            status='danger'
                            onPress={() => {
                                if (rateToDelete !== null) {
                                    setShowConfirmDeleteRateModal(false)
                                    handleDeleteRate(rateToDelete)
                                }
                            }}>
                            Delete
                        </Button>
                    </Layout>
                </Card>
            </DMModal>
        )
    };

    const onDeleteRate = (index: number) => {
        setRateToDelete(index);
        setShowConfirmDeleteRateModal(true);
    };



    /**
     * 
     * CLIENT LINKING
     */

    const [companyFoundByLinkId, setCompanyFoundByLinkId] = useState(null as Company | null)
    const [showCompanyLinkModal, setShowCompanyLinkModal] = useState(false)
    const clientLinkIdTextRef = useRef(null as string | null)
    const validateClientLinkId = async (clientLinkId: string) => {
        try {
            // get company by vendor link id
            const company = await CompanyController.getCompanyById(clientLinkId) as Company
            // console.log("🚀 ~ file: clientEdit.component.js:323 ~ validateClientLinkId ~ company:", company)
            if (company) {
                setCompanyFoundByLinkId(company)
                setShowCompanyLinkModal(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const linkExistingClientAccount = async (linkedClientAccountId: string) => {
        try {
            if (!client) return
            // get company by vendor link id
            const linkedClientResult = await ClientController.linkExistingClientAccount(linkedClientAccountId, client._id as string)
            // console.log("🚀 ~ file: clientEdit.component.js:323 ~ validateClientLinkId ~ company:", linkedClientResult)
            if (linkedClientResult) {
                // console.log("🚀 ~ file: clientEdit.component.js:354 ~ linkedClient:", linkedClientResult)
                onSetClient(new Client({ ...client, linkedClientAccountId: linkedClientAccountId }))
                onSaveClientProfile()
            }
        } catch (e) {
            console.log(e)
        }
    }


    const ClientLinkModal = () => {
        return (
            <DMModal
                visible={showCompanyLinkModal}
                onClose={() => setShowCompanyLinkModal(false)}
            >
                <Card disabled={true}>
                    <Text category='h6' style={{ marginBottom: 15 }}>Link Client Account</Text>
                    {companyFoundByLinkId ? (
                        <>
                            <Text category='s1' style={{ marginBottom: 10 }}>Company found:</Text>
                            <View style={{ marginBottom: 15 }}>
                                <Text>Name: {companyFoundByLinkId.name}</Text>
                                <Text>Email: {companyFoundByLinkId.settings?.contact_info?.email}</Text>
                                <Text>Phone: {companyFoundByLinkId.settings?.contact_info?.phone}</Text>
                            </View>
                            <Text>Are you sure you want to link this client account?</Text>
                            <Text>This will give this company access to all jobs associated with this client account.</Text>
                            <Text status='warning'>This action cannot be undone.</Text>
                            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 15 }}>
                                <Button
                                    status='basic'
                                    onPress={() => {
                                        setShowCompanyLinkModal(false);
                                        setCompanyFoundByLinkId(null);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    status='primary'
                                    onPress={() => {
                                        setShowCompanyLinkModal(false);
                                        linkExistingClientAccount(companyFoundByLinkId?._id || "");
                                        setCompanyFoundByLinkId(null);
                                    }}
                                >
                                    Link Account
                                </Button>
                            </Layout>
                        </>
                    ) : (
                        <>
                            <Input
                                label='Client Link ID'
                                defaultValue={clientLinkIdTextRef.current || ""}
                                onChangeText={(nextValue: any) => clientLinkIdTextRef.current = nextValue}
                                style={{ marginBottom: 15 }}
                            />
                            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Button
                                    status='basic'
                                    onPress={() => setShowCompanyLinkModal(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    status='primary'
                                    onPress={() => validateClientLinkId(clientLinkIdTextRef.current as string)}
                                >
                                    Validate
                                </Button>
                            </Layout>
                        </>
                    )}
                </Card>
            </DMModal>
        );
    };

    const handleAddressChange = (address: any) => {
        console.log("🚀============== ~ file: clientEdit.component.tsx:418 ~ handleAddressChange ~ address🚀==============", address)
        let _c = new Client({ ...clientRef.current })
        _c.contact_info.address = address
        onSetClient(_c)
    };

    const handleLocationChange = (location: any) => {
        console.log("🚀============== ~ file: clientEdit.component.tsx:430 ~ handleLocationChange ~ location🚀==============", location)
        let _c = new Client({ ...clientRef.current })
        _c.contact_info.location = location
        onSetClient(_c)
    };


    return (
        <Layout style={{ flex: 1, flexDirection: 'column', height: height }}>
            <DeleteClientModal />
            <ClientRateModal
                visible={showEditRateModal}
                onClose={() => setShowEditRateModal(false)}
                onSave={handleEditRate}
                isEditMode={true}
                accountCode={client?.account_code}
                initialRate={client?.settings?.rates[editRateIndexRef.current as number]}
            />
            <ClientRateModal
                visible={showNewRateModal}
                onClose={() => setShowNewRateModal(false)}
                onSave={handleAddRate}
                isEditMode={false}
                accountCode={client?.account_code}
            />
            <DeleteRateModal />
            <ClientLinkModal />
            <Layout style={{ flex: 10 }}>
                {client &&
                    <View style={{ flexDirection: 'column' }}>
                        <Layout style={{ flexDirection: 'row', gap: 20 }}>
                            <View style={{ flex: 1 }}>
                                <Button
                                    onPress={() => setShowConfirmDeleteModal(true)}
                                    status='danger'
                                    disabled={!checkCanEditFields()}
                                    style={{ marginBottom: 10 }}
                                >
                                    Delete Client
                                </Button>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <Text category='s1'>{client.name}</Text>
                            </View>
                            <View style={{ flex: 3 }}>
                                {
                                    unsavedChanges &&
                                    <Text
                                        style={{ color: 'red' }}
                                        category='h6'
                                    >Unsaved Changes</Text>
                                }
                            </View>
                            <View style={{ flexDirection: 'column' }}>
                                {!client.linkedClientAccountId && (
                                    <View style={{ marginBottom: 10 }}>
                                        <Text category='s1'>This client is not linked to a client account.</Text>
                                        <Button
                                            status='primary'
                                            size='tiny'
                                            accessoryLeft={(props: any) => <Icon {...props} name="link-outline" />}
                                            onPress={() => setShowCompanyLinkModal(true)}>
                                            Link Client Account
                                        </Button>
                                    </View>
                                )}
                                {client.linkedClientAccountId && (
                                    <View style={{ marginBottom: 10 }}>
                                        <Text category='s1'>This client is linked to a client account.</Text>
                                    </View>
                                )}
                            </View>
                            <Layout style={{ flex: 1 }}>
                                <Button
                                    onPress={() => { onSaveClientProfile() }}
                                    disabled={!checkCanEditFields()}
                                    accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                                    status="success"
                                >
                                    Save
                                </Button>
                            </Layout>
                            <Layout style={{ flex: 1 }}>
                                <Button
                                    onPress={onCancel}
                                    disabled={!checkCanEditFields()}
                                    accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                                    status="warning"
                                >
                                    Cancel
                                </Button>
                            </Layout>
                        </Layout>
                        <Divider />

                        {/* Rates */}
                        <View style={{ height: height - 200 }}>
                            <ScrollView contentContainerStyle={{ flexDirection: 'row' }}>
                                <Layout style={{ flex: 1, flexDirection: 'column', gap: 20 }}>
                                    <Card
                                        disabled={true}
                                        // style={{ flex: 1 }}
                                        status='basic'
                                        header={() => <Text category='h6'>Rates</Text>}
                                    >
                                        <Layout style={{ flexDirection: "row" }}>
                                            <Layout style={{ flex: 1 }}>
                                                <Layout style={{}}>
                                                    <Button onPress={() => setShowNewRateModal(true)} disabled={!checkCanEditFields()} status="success">Add Rate</Button>
                                                </Layout>
                                                <Layout style={{}}>
                                                    {client.settings?.rates?.map((rate: JobClientRate, index: number) => (
                                                        <Card key={index} disabled={true}>
                                                            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <Text category='label'>{rate.name}</Text>
                                                                <Text>${rate.cost}</Text>
                                                                <Text>{rate.billed_to == CLIENT_RATE_BILLED_TO.BILL_ALL_BACK ? "Bill All Back" : "Customer Cost"}</Text>
                                                                <Button onPress={() => onEditRate(index)} size='tiny' disabled={!checkCanEditFields()}>
                                                                    <Icon name='edit-2-outline' width={15} height={15} status="info" />
                                                                </Button>
                                                                <Button onPress={() => onDeleteRate(index)} size='tiny' status="danger" disabled={!checkCanEditFields()}>
                                                                    <Icon name='trash-2-outline' width={15} height={15} />
                                                                </Button>
                                                            </Layout>
                                                            {/* <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            </Layout> */}

                                                        </Card>
                                                    ))}
                                                </Layout>
                                            </Layout>
                                        </Layout>
                                    </Card>

                                    <AddressDetailsCard
                                        allowEdit={checkCanEditFields()}
                                        onSetAddress={(address: JobAddress) => {
                                            let _c = new Client({ ...client });
                                            _c.contact_info.address = new JobAddress(address);
                                            onSetClient(_c);
                                        }}
                                        onSetLocation={(location: JobLocation) => {
                                            let _c = new Client({ ...client });
                                            _c.contact_info.location = new JobLocation(location);
                                            onSetClient(_c);
                                        }}
                                        onSetSavedAddress={(savedAddress: CompanySavedAddress) => {
                                            let _c = new Client({ ...client });
                                            _c.contact_info.address = new JobAddress(savedAddress.address);
                                            _c.contact_info.location = new JobLocation(savedAddress.location);
                                            onSetClient(_c);
                                        }}
                                        address={client.contact_info?.address}
                                        addressDisplayValue={client.contact_info?.address?.formatted_address || ""}
                                        addressName="Client Address"
                                    />
                                    {/*
                                    //////////////////////////////////////////////////////////////////
                                    //////////////////////////////////////////////////////////////////
                                    //#region CONTACT INFO
                                    //////////////////////////////////////////////////////////////////
                                    //////////////////////////////////////////////////////////////////
                                    */}

                                </Layout>
                                <Layout style={{ flex: 1, flexDirection: 'column' }}>
                                    {/*
                                    //////////////////////////////////////////////////////////////////
                                    //////////////////////////////////////////////////////////////////
                                    //#region CLIENT MAIN DETAILS
                                    //////////////////////////////////////////////////////////////////
                                    //////////////////////////////////////////////////////////////////
                                    */}
                                    <View style={{ flexDirection: 'row' }}>
                                        <Card
                                            disabled={true}
                                            style={{ flexBasis: '50%' }}
                                            status='basic'
                                            header={() => <Text category='h6'>Client Details</Text>}
                                        >
                                            <Input
                                                ref={nameRef}
                                                label='Name'
                                                value={client.name || ""}
                                                disabled={!checkCanEditFields()}
                                                onChange={(e: any) => onSetClient({ ...client, name: e.target.value })}
                                                autoCapitalize="words"
                                                caption={() => <Text category='c1'>The name of the client - Will appear in the job title and invoices.</Text>}
                                            />
                                            <Input
                                                ref={emailRef}
                                                label='Email'
                                                value={client.contact_info?.email || ""}
                                                disabled={!checkCanEditFields()}
                                                onChange={(e: any) => onSetClient({ ...client, contact_info: { ...client.contact_info, email: e.target.value } })}
                                                keyboardType="email-address"
                                                autoCapitalize="none"
                                                caption={() => <Text category='c1'>The primary email address, this is where post job emails will go.</Text>}
                                            />
                                            <Input
                                                ref={phoneRef}
                                                label='Phone'
                                                value={client.contact_info?.phone || ""}
                                                disabled={!checkCanEditFields()}
                                                onChange={(e: any) => onSetClient({ ...client, contact_info: { ...client.contact_info, phone: e.target.value } })}
                                            />
                                        </Card>
                                        {/*
                                    //////////////////////////////////////////////////////////////////
                                    //////////////////////////////////////////////////////////////////
                                    //#region OTHER
                                    //////////////////////////////////////////////////////////////////
                                    //////////////////////////////////////////////////////////////////
                                    */}
                                        <Card
                                            disabled={true}
                                            style={{ flexBasis: '50%' }}
                                            status='basic'
                                            header={() => <Text category='h6'>Other</Text>}
                                        >
                                            <Input
                                                ref={addressRef}
                                                label='Details'
                                                value={client.details || ""}
                                                disabled={!checkCanEditFields()}
                                                multiline={true}
                                                numberOfLines={4}
                                                onChange={(e: any) => onSetClient({ ...client, details: e.target.value })}
                                                caption={() => <Text category='c1'>Any additional details about the client.</Text>}
                                            />
                                            <Input
                                                ref={accountCodeRef}
                                                label='Account Code'
                                                value={client.account_code || ""}
                                                disabled={!checkCanEditFields()}
                                                onChange={(e: any) => onSetClient({ ...client, account_code: e.target.value })}
                                                caption={() => <Text category='c1'>The account code for the client. For use in invoices and other documents.</Text>}
                                            />
                                            <Text category='p1' status='info' style={{ marginTop: 20 }} selectable>Client id: {client._id || ""}</Text>
                                        </Card>

                                    </View>
                                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <RenderContactInfo
                                            contactInfo={client.contact_info}
                                            editMode={primaryContactEditMode}
                                            onSetContactInfo={(updates) => {
                                                onSetClient({
                                                    ...client,
                                                    contact_info: { ...client.contact_info, ...updates }
                                                });
                                            }}
                                            onToggleEdit={() => setPrimaryContactEditMode(!primaryContactEditMode)}
                                            onCancel={() => setPrimaryContactEditMode(false)}
                                            title="Primary Contact"
                                            type="primary"
                                            canEdit={checkCanEditFields()}
                                        />
                                        <RenderContactInfo
                                            contactInfo={client.contact_info}
                                            onCancel={() => setSecondaryContactEditMode(false)}
                                            editMode={secondaryContactEditMode}
                                            onSetContactInfo={(updates) => {
                                                onSetClient({
                                                    ...client,
                                                    contact_info: { ...client.contact_info, ...updates }
                                                });
                                            }}
                                            onToggleEdit={() => setSecondaryContactEditMode(!secondaryContactEditMode)}
                                            title="Secondary Contact"
                                            type="secondary"
                                            canEdit={checkCanEditFields()}
                                        />
                                    </Layout>

                                    {clientCompanyLinkedCompany &&
                                        <Layout style={{ flex: 1, flexDirection: 'column' }}>
                                            <Card
                                                disabled={true}
                                                status='basic'
                                                header={() => <Text category='h6'>LINKED - Client Company Details</Text>}
                                            >
                                                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    {/* .settings.contact_info.phone && email */}
                                                    <Text category='label'>Name</Text>
                                                    <Text>{clientCompanyLinkedCompany.name}</Text>
                                                </Layout>
                                                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    {/* .settings.contact_info.phone && email */}
                                                    <Text category='label'>Email</Text>
                                                    <Text>{clientCompanyLinkedCompany.settings?.contact_info?.email}</Text>
                                                </Layout>
                                                <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    {/* .settings.contact_info.phone && email */}
                                                    <Text category='label'>Phone</Text>
                                                    <Text>{clientCompanyLinkedCompany.settings?.contact_info?.phone}</Text>
                                                </Layout>
                                            </Card>
                                        </Layout>
                                    }
                                </Layout>
                            </ScrollView>
                        </View>
                    </View>
                }
            </Layout>
        </Layout>
    )

}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
});


interface RenderContactInfoProps {
    contactInfo: ClientContactInfo
    editMode: boolean
    onSetContactInfo: (updates: Partial<ClientContactInfo>) => void
    onToggleEdit: () => void
    onCancel: () => void
    title: string
    type: 'primary' | 'secondary'
    canEdit: boolean
}

const RenderContactInfo = (props: RenderContactInfoProps) => {
    const { contactInfo, editMode, onSetContactInfo, onToggleEdit, onCancel, title, type, canEdit } = props

    if (!contactInfo) return null

    const Header = () => (
        <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Text category='h6'>{title}</Text>
            {canEdit && (
                // on cancel revert to original values
                <View style={{ flexDirection: 'row' }}>

                    <Button onPress={onToggleEdit}
                        size='tiny'
                        appearance={editMode ? 'filled' : 'outline'}
                        accessoryLeft={(props: any) => <Icon {...props} name={editMode ? 'checkmark-outline' : 'edit-outline'} width={24} height={24} />
                        }
                    >
                        {/* {editMode ? 'Save' : 'Edit'} */}
                    </Button>
                    {editMode &&
                        <Button onPress={onCancel}
                            size='tiny'
                            accessoryLeft={(props: any) => <Icon {...props} name='close-outline' width={24} height={24} />}
                            status='danger'
                            appearance='ghost'
                        >
                            {/* Cancel */}
                        </Button>
                    }
                </View>
            )}
        </Layout>
    );

    const nameValue = type === 'primary' ? contactInfo.primary_contact_name : contactInfo.secondary_contact_name;
    const emailValue = type === 'primary' ? contactInfo.primary_contact_email : contactInfo.secondary_contact_email;
    const phoneValue = type === 'primary' ? contactInfo.primary_contact_phone : contactInfo.secondary_contact_phone;

    const handleChange = (field: string, value: string) => {
        const updates = {
            [`${type}_contact_${field}`]: value
        };
        onSetContactInfo(updates);
    };

    return (
        <Card
            disabled={true}
            status='basic'
            header={Header}
            style={{ flexBasis: '50%' }}
        >
            {editMode ? (
                <>
                    <Input
                        label='Name'
                        value={nameValue || ""}
                        disabled={!canEdit}
                        onChange={(e: any) => handleChange('name', e.target.value)}
                    />
                    <Input
                        label='Email'
                        value={emailValue || ""}
                        disabled={!canEdit}
                        onChange={(e: any) => handleChange('email', e.target.value)}
                    />
                    <Input
                        label='Phone'
                        value={phoneValue || ""}
                        disabled={!canEdit}
                        onChange={(e: any) => handleChange('phone', e.target.value)}
                    />
                </>
            ) : (
                <>
                    <Layout style={{ marginBottom: 10 }}>
                        <Text category='label'>Name</Text>
                        <Text>{nameValue || "-"}</Text>
                    </Layout>
                    <Layout style={{ marginBottom: 10 }}>
                        <Text category='label'>Email</Text>
                        <Text>{emailValue || "-"}</Text>
                    </Layout>
                    <Layout style={{ marginBottom: 10 }}>
                        <Text category='label'>Phone</Text>
                        <Text>{phoneValue || "-"}</Text>
                    </Layout>
                </>
            )}
        </Card>
    )
}
