import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    useTheme,
    Radio,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import { Job, JobClientRate, LINE_ITEM_BILLED_TO } from '../../../models/Job.model';
import { LineItem } from '../../../models/LineItem.model';
import { Holding } from '../../../models/Holding.model';
import { Case } from '../../../models/Case.model';
import * as StorageController from '../../../functions/storageController'


interface InvoicedTotalCardProps {
    job?: Job;
    holding?: Holding;
    caseItem?: Case;
    canEdit?: boolean;
    style?: any;
}

export const InvoicedTotalCard = ({
    job,
    holding,
    caseItem,
    canEdit = false,
    style = {}
}: InvoicedTotalCardProps) => {


    const [lineItems, setLineItemsState] = useState<LineItem[]>([]);
    const [customerCostItems, setCustomerCostItems] = useState<LineItem[]>([]);
    const [billBackItems, setBillBackItems] = useState<LineItem[]>([]);
    const [clientRate, setClientRate] = useState<JobClientRate>(new JobClientRate({}));



    useEffect(() => {
        if (!job) return
        const j = new Job(job);
        setLineItemsState(j.getLineItems());
        setCustomerCostItems(j.getTotalCustomerCostItems());
        setBillBackItems(j.getTotalBillBackItems());
        setClientRate(j.getClientRate());
    }, [job])

    useEffect(() => {
        if (!holding) return
        const h = new Holding(holding);
        setLineItemsState(h.getLineItems());
        setCustomerCostItems(h.getTotalCustomerCostItems());
        setBillBackItems(h.getTotalBillBackItems());
    }, [holding])

    useEffect(() => {
        if (!caseItem) return
        const c = new Case(caseItem);
        setLineItemsState(c.getAllLineItems());
        setCustomerCostItems(c.getTotalCustomerCostItems());
        setBillBackItems(c.getTotalBillBackItems());
    }, [caseItem])


    const calculateTotalAmountsWithGST = (type: "customer_cost" | "bill_all_back" | "all") => {
        if (!job) return { GSTTotal: 0, totalWithoutGST: 0, totalWithGST: 0, costNoGST: 0 };
        const j = new Job(job);
        j.addLineItem(new LineItem({
            ...clientRate,
            billed_to: clientRate.billed_to == 'customer_cost' ? LINE_ITEM_BILLED_TO.CUSTOMER_COST : LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
            cost: clientRate.cost,
            quantity: 1
        }))
        return j.details.report.calculateTotalAmountsWithGST(type);
    }

    const calculateBillBackWithClientRate = (): { GSTTotal: number, totalWithoutGST: number, totalWithGST: number, costNoGST: number } => {
        if (!clientRate) return { GSTTotal: 0, totalWithoutGST: 0, totalWithGST: 0, costNoGST: 0 };
        const j = new Job(job);
        if (clientRate.billed_to == 'bill_all_back') {
            j.addLineItem(new LineItem({
                ...clientRate,
                billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
                cost: clientRate.cost,
                quantity: 1
            }))
        }
        let total = j.details.report.calculateTotalAmountsWithGST('bill_all_back');
        // if (clientRate.billed_to == 'bill_all_back') {
        //     total.totalWithGST += clientRate.cost;
        //     total.costNoGST += clientRate.cost;
        // }
        return total;
    }

    const calculateCustomerCostWithClientRate = (): { GSTTotal: number, totalWithoutGST: number, totalWithGST: number, costNoGST: number } => {
        if (!clientRate) return { GSTTotal: 0, totalWithoutGST: 0, totalWithGST: 0, costNoGST: 0 };
        const j = new Job(job);
        if (clientRate.billed_to == 'customer_cost') {
            j.addLineItem(new LineItem({
                ...clientRate,
                billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
                cost: clientRate.cost,
                quantity: 1
            }))
        }
        let total = j.details.report.calculateTotalAmountsWithGST('customer_cost');
        // if (clientRate.billed_to == 'customer_cost') {
        //     total.totalWithGST += clientRate.cost;
        //     total.costNoGST += clientRate.cost;
        // }
        return total;
    }

    const calculateTotalAmountsWithClientRate = () => {
        if (!clientRate) return 0;
        const j = new Job(job);
        j.addLineItem(new LineItem({
            ...clientRate,
            billed_to: clientRate.billed_to == 'customer_cost' ? LINE_ITEM_BILLED_TO.CUSTOMER_COST : LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
            cost: clientRate.cost,
            quantity: 1
        }))
        let total = j.details.report.calculateTotalAmountsWithGST('all');
        // if (clientRate.billed_to == 'customer_cost') {
        //     total.totalWithGST += clientRate.cost;
        //     total.costNoGST += clientRate.cost;
        // }
        return total.totalWithGST;
    }



    const calculateInvoiceTotal = () => {
        let total = 0;
        let totalWithGST = 0;
        try {
            const items = [...lineItems]
            if (clientRate) {
                items.push(new LineItem({
                    ...clientRate,
                    billed_to: clientRate.billed_to == 'customer_cost' ? LINE_ITEM_BILLED_TO.CUSTOMER_COST : LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
                    cost: clientRate.cost,
                    quantity: 1
                }))
            }
            items.forEach((item) => {
                total += item.cost * item.quantity;
                totalWithGST += item.getCostWithGST();
            })
            if (clientRate) {
                // total += clientRate.cost || 0;
                // totalWithGST += clientRate.getCostWithGST();
            }
            return total.toFixed(2);
        } catch (err) {
            console.log("🚀============== ~ file: jobInvoicedTotalDetailsCard.component.js:96 ~ calculateOutstantingCustomerCostTotal ~ err🚀==============", err)
            return total.toFixed(2);
        }
    }

    /**
     * Total of all customer cost items that have not been paid
     */
    const calculateOutstantingCustomerCostTotal = () => {
        let total = 0;
        try {
            const items = [...customerCostItems]
            if (clientRate && clientRate?.billed_to == "customer_cost") {
                items.push(new LineItem({
                    ...clientRate,
                    billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
                    cost: clientRate.cost,
                    quantity: 1,
                    paid: clientRate.customer_paid
                }))
            }
            items.forEach((item) => {
                if (!item.paid) {
                    if (isGSTRegistered) {
                        total += item.getCostWithGST();
                    } else {
                        total += item.getTotalCost();
                    }
                }
            })
            // if (clientRate && clientRate?.billed_to == "customer_cost" && !clientRate?.customer_paid) {
            //     total += Number(clientRate.cost);
            // }
            return total.toFixed(2);
        }
        catch (err) {
            console.log("🚀============== ~ file: jobInvoicedTotalDetailsCard.component.js:127 ~ calculateOutstantingCustomerCostTotal ~ err🚀==============", err)
            return total;
        }
    }

    const RenderClientRate = () => {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderWidth: 1 }}>
                <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                    <Text style={{ flex: 1, textAlign: "left" }}>Callout </Text>
                </View>
                <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                    <Text style={{ flex: 1, textAlign: "left" }}>{clientRate.name}</Text>
                </View>
                {(clientRate && clientRate.billed_to == "customer_cost") ?
                    <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1, backgroundColor: clientRate.customer_paid ? 'green' : 'orange' }}>
                        <Text style={{ textAlign: "left" }}>{clientRate.customer_paid ? "--PAID--" : "--NOT PAID--"}</Text>
                        {canEdit &&
                            // Button to toggle paid or not
                            <Button
                                size='tiny'
                                appearance='outline'
                                status={clientRate.customer_paid ? 'warning' : 'success'}
                                onPress={() => {
                                    clientRate.customer_paid = !clientRate.customer_paid;
                                    setClientRate(new JobClientRate({ ...clientRate }));
                                    // set the job state to update
                                    if (job) {
                                        const j = new Job(job);
                                        j.details.client_rate = clientRate;
                                        j.save();
                                    }
                                    if (holding) {
                                        const h = new Holding(holding);
                                        h.details.client_rate = clientRate;
                                        h.save();
                                    }
                                }}
                            >
                                {clientRate.customer_paid ? "Mark Unpaid" : "Mark Paid"}
                            </Button>
                        }
                    </View>
                    :
                    <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}></View>
                }
                <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                    <Text style={{ flex: 1, textAlign: "right" }}>${clientRate.cost}</Text>
                </View>
            </View>
        )
    }

    const isGSTRegistered = StorageController.getCurrentCompany().settings?.business_info?.gst_registered;

    return (
        <Card
            disabled
            header={() => <Text style={{ alignSelf: 'center' }}>Invoiced Total</Text>}
            style={[style, { gap: 1 }]}
            status='primary'
        >
            <View style={{ flexDirection: 'column', flex: 1 }}>
                <View style={{ flexDirection: 'row', flex: 1, gap: 5, flexWrap: 'wrap' }}>

                    <View style={{ flexDirection: 'column', flex: 1, justifyContent: 'space-between', gap: 20, minWidth: 350 }}>
                        <View>
                            <View style={{ height: 1, width: "100%", backgroundColor: 'white', margin: 5 }}></View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='label' style={{ flex: 1, textAlign: "left" }}>Bill Back</Text>
                            </View>
                            {(clientRate && clientRate.billed_to == "bill_all_back") &&
                                <RenderClientRate />
                            }
                            {billBackItems.length > 0 &&
                                <>
                                    {billBackItems.map((item, index) => {
                                        return (
                                            <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderWidth: 1 }}>
                                                <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                                    <Text style={{ textAlign: "left" }}>{item.name}</Text>
                                                </View>
                                                <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                                    <Text style={{ textAlign: "left" }}>{item.quantity} X {item.cost}</Text>
                                                </View>
                                                {isGSTRegistered &&
                                                    <>
                                                        <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                                            <Text style={{ textAlign: "left" }}>{item.is_gst_inclusive ? "(GST INC)" : "(GST EXC)"}</Text>
                                                        </View>
                                                        <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                                            <Text style={{ textAlign: "right" }}>${item.getCostWithGST().toFixed(2)}</Text>
                                                        </View>
                                                    </>
                                                }
                                                {!isGSTRegistered &&
                                                    <View style={{ flex: 2, borderColor: 'gray', borderWidth: 1 }}>
                                                        <Text style={{ textAlign: "right" }}>${(item.quantity * item.cost).toFixed(2)}</Text>
                                                    </View>
                                                }
                                            </View>
                                        )
                                    }
                                    )}
                                </>
                            }
                        </View>
                        <View>
                            {isGSTRegistered &&
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderBottomWidth: 1 }}>
                                    <Text category='label' style={{ flex: 3, textAlign: "left" }}>GST</Text>
                                    <View style={{ flex: 1 }}>
                                        <Text style={{ textAlign: "right" }}>${calculateTotalAmountsWithGST("bill_all_back").GSTTotal?.toFixed(2)}</Text>
                                    </View>
                                </View>
                            }
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderBottomWidth: 1 }}>
                                <Text category='label' style={{ flex: 3, textAlign: "left" }}>Bill Back Total</Text>
                                <View style={{ flex: 1 }}>
                                    {isGSTRegistered ?
                                        <Text style={{ textAlign: "right" }}>${calculateBillBackWithClientRate()?.totalWithGST?.toFixed(2)}</Text>
                                        :
                                        <Text style={{ textAlign: "right" }}>${calculateBillBackWithClientRate()?.costNoGST?.toFixed(2)}</Text>
                                    }
                                </View>
                            </View>

                        </View>
                    </View>
                    <View style={{ flexDirection: 'column', flex: 1, justifyContent: "space-between", gap: 20, minWidth: 350 }}>
                        <View>
                            <View style={{ height: 1, width: "100%", backgroundColor: 'white', margin: 5 }}></View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='label' style={{ flex: 1, textAlign: "left" }}>Customer Cost Items</Text>
                            </View>
                            {(clientRate && clientRate?.billed_to == "customer_cost") &&
                                <RenderClientRate />
                            }
                            {customerCostItems.length > 0 &&
                                <>
                                    {customerCostItems.map((item, index) => {
                                        return (
                                            <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderWidth: 1 }}>
                                                <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                                    <Text style={{ textAlign: "left" }}>{item.name}</Text>
                                                </View>
                                                <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                                    <Text style={{ textAlign: "left" }}>{item.quantity} X {item.cost}</Text>
                                                </View>
                                                <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1, backgroundColor: item.paid ? 'green' : 'orange' }}>
                                                    <Text style={{ textAlign: "left" }}>{item.paid ? "--PAID--" : "--NOT PAID--"}</Text>
                                                    {canEdit &&
                                                        // Button to toggle paid or not
                                                        <Button
                                                            size='tiny'
                                                            appearance='outline'
                                                            status={item.paid ? 'warning' : 'success'}
                                                            onPress={() => {
                                                                item.paid = !item.paid;
                                                                console.log("🚀============== ~ file: jobInvoicedTotalDetailsCard.component.tsx:305 ~ {customerCostItems.map ~ item🚀==============", item)
                                                                let item_id = item._id;
                                                                setCustomerCostItems([...customerCostItems]);
                                                                // set the job state to update
                                                                if (job) {
                                                                    const j = new Job(job);
                                                                    // update the line item matching the id
                                                                    const lineItem = j.getLineItems().find((l) => l._id == item_id);
                                                                    if (!lineItem) return;
                                                                    lineItem.paid = item.paid;
                                                                    j.details.report.items = lineItems;
                                                                    j.save();
                                                                }
                                                                if (holding) {
                                                                    const h = new Holding(holding);
                                                                    // update the line item matching the id
                                                                    const lineItem = h.getLineItems().find((l) => l._id == item_id);
                                                                    if (!lineItem) return;
                                                                    lineItem.paid = item.paid;
                                                                    h.line_items = lineItems;
                                                                    h.save();
                                                                }
                                                            }}
                                                        >
                                                            {item.paid ? "Mark Unpaid" : "Mark Paid"}
                                                        </Button>
                                                    }
                                                </View>
                                                {isGSTRegistered &&
                                                    <>
                                                        <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                                            <Text style={{ textAlign: "left" }}>{item.is_gst_inclusive ? "(GST INC)" : "(GST EXC)"}</Text>
                                                        </View>
                                                        <View style={{ flex: 1, borderColor: 'gray', borderWidth: 1 }}>
                                                            <Text style={{ textAlign: "right" }}>${item.getCostWithGST()?.toFixed(2)}</Text>
                                                        </View>
                                                    </>
                                                }
                                                {!isGSTRegistered &&
                                                    <View style={{ flex: 2, borderColor: 'gray', borderWidth: 1 }}>
                                                        <Text style={{ textAlign: "right" }}>${(item.quantity * item.cost)?.toFixed(2)}</Text>
                                                    </View>
                                                }
                                            </View>
                                        )
                                    }
                                    )}
                                </>
                            }
                        </View>
                        <View>
                            {isGSTRegistered &&
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderBottomWidth: 1 }}>
                                    <Text category='label' style={{ flex: 3, textAlign: "left" }}>GST</Text>
                                    <View style={{ flex: 1 }}>
                                        <Text style={{ textAlign: "right" }}>${calculateTotalAmountsWithGST("customer_cost").GSTTotal?.toFixed(2)}</Text>
                                    </View>
                                </View>
                            }
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderBottomWidth: 1 }}>
                                <Text category='label' style={{ flex: 3, textAlign: "left" }}>Customer Cost Total</Text>
                                <View style={{ flex: 1 }}>
                                    {isGSTRegistered ?
                                        <Text style={{ textAlign: "right" }}>${calculateCustomerCostWithClientRate()?.totalWithGST?.toFixed(2)}</Text>
                                        :
                                        <Text style={{ textAlign: "right" }}>${calculateCustomerCostWithClientRate()?.costNoGST?.toFixed(2)}</Text>
                                    }
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderBottomWidth: 1 }}>
                                <Text category='label' style={{ flex: 3, textAlign: "left" }}>Outstanding Customer Cost Total</Text>
                                <View style={{ flex: 1 }}>
                                    <Text style={{ textAlign: "right" }}>${calculateOutstantingCustomerCostTotal()}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{ height: 1, width: "100%", backgroundColor: 'white', margin: 5 }}></View>

                {/* Show totals */}
                <View style={{ height: 1, width: "100%", backgroundColor: 'white', margin: 5 }}></View>
                <View style={{ gap: 10 }}>
                    {isGSTRegistered &&
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderBottomWidth: 1 }}>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='label' style={{ flex: 3, textAlign: "left" }}>GST Total</Text>
                                <Text style={{ textAlign: "right" }}>${calculateTotalAmountsWithGST("all").GSTTotal?.toFixed(2)}</Text>
                            </View>
                        </View>
                    }
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderColor: 'gray', borderBottomWidth: 1 }}>
                        <Text category='label' style={{ flex: 3, textAlign: "left" }}>Grand Total</Text>
                        <View style={{ flex: 1 }}>
                            {isGSTRegistered ?
                                <Text style={{ textAlign: "right" }}>${calculateTotalAmountsWithClientRate()?.toFixed(2)}</Text>
                                :
                                <Text style={{ textAlign: "right" }}>${calculateInvoiceTotal()}</Text>
                            }
                        </View>
                    </View>
                </View>
            </View>
        </Card>
    )

}