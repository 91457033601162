import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Layout, List, ListItem, Text, Button, Modal, Card, Input } from '@ui-kitten/components';
import { InventoryItem } from '../../models/Inventory.model';
import * as InventoryController from '../../functions/Inventory.controller';
import { LineItem } from '../../models/Job.model';

export const ManageSales = () => {
    const [items, setItems] = useState<LineItem[]>([]);
    const [selectedItem, setSelectedItem] = useState<LineItem | null>(null);

    useEffect(() => {
        fetchInventoryItems();
    }, []);

    const fetchInventoryItems = async () => {
        // Implement fetching inventory items
        // This is a placeholder
        const fetchedItems = await InventoryController.getInventoryItemsByCategory(''); // needs to be an id
        if (fetchedItems) {
            setItems(fetchedItems);
        }
    };

    const updateItem = async (updatedItem: LineItem) => {
        // Implement updating item
        // This is a placeholder
        setSelectedItem(null);
        fetchInventoryItems();
    };

    const renderItemCard = ({ item }: { item: LineItem }) => (
        <ListItem
            title={item.name}
            description={`Price: $${item.cost}, Quantity: ${item.quantity}`}
            accessoryRight={() => <Button size="small" onPress={() => setSelectedItem(item)}>Edit</Button>}
        />
    );

    const renderEditItemModal = () => (
        <Modal
            visible={!!selectedItem}
            backdropStyle={styles.backdrop}
            onBackdropPress={() => setSelectedItem(null)}
        >
            <Card disabled>
                <Text category="h6">Edit Item</Text>
                <Input
                    label="Price"
                    value={selectedItem?.cost.toString()}
                    onChangeText={(text) => setSelectedItem({ ...selectedItem!, cost: parseFloat(text) })}
                    keyboardType="numeric"
                />
                <Input
                    label="Quantity"
                    value={selectedItem?.quantity.toString()}
                    onChangeText={(text) => setSelectedItem({ ...selectedItem!, quantity: parseInt(text) })}
                    keyboardType="numeric"
                />
                <Button onPress={() => selectedItem && updateItem(selectedItem)}>Save Changes</Button>
            </Card>
        </Modal>
    );

    return (
        <Layout style={styles.container}>
            <Text category="h5">Manage Sales</Text>
            <List
                data={items}
                renderItem={renderItemCard}
            />
            {renderEditItemModal()}
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});
