import React from 'react';
import { View, ViewStyle, StyleProp, NativeSyntheticEvent, TextInputEndEditingEventData } from 'react-native';
import { Input, Button, Text, OverflowMenu, MenuItem } from '@ui-kitten/components';
import { LineItem } from '../../../../models/LineItem.model';
import { ExtendedInventoryItem } from '../../../../models/Inventory.model';

interface AddItemFormProps {
    newLineItem: LineItem | null;
    onNewLineItemChange: (item: LineItem) => void;
    menuVisible: boolean;
    setMenuVisible: (visible: boolean) => void;
    onMenuItemSelect: (index: any) => void;
    filteredMenuItems: ExtendedInventoryItem[];
    setMenuSearchQuery: (query: string) => void;
    customItemCost: string;
    setCustomItemCost: (cost: string) => void;
    customItemQuantity: string;
    setCustomItemQuantity: (quantity: string) => void;
    handleCostChange: (text: string) => void;
    handleQuantityChange: (text: string) => void;
    validatePrice: (value: string) => void;
    validateQuantity: (value: string) => void;
    priceError: string;
    quantityError: string;
    handleAddNewItem: () => void;
    style?: StyleProp<ViewStyle>;
}

export const AddItemForm = ({
    newLineItem,
    onNewLineItemChange,
    menuVisible,
    setMenuVisible,
    onMenuItemSelect,
    filteredMenuItems,
    setMenuSearchQuery,
    customItemCost,
    setCustomItemCost,
    customItemQuantity,
    setCustomItemQuantity,
    handleCostChange,
    handleQuantityChange,
    validatePrice,
    validateQuantity,
    priceError,
    quantityError,
    handleAddNewItem,
    style
}: AddItemFormProps) => {
    return (
        <View style={[{
            flexDirection: 'column',
            borderTopWidth: 3,
            borderTopColor: '#e0e0e0',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8
        }, style]}>
            <View style={{ flexDirection: 'row' }}>
                <Text category='s1'>Add Item</Text>
            </View>

            <View style={{
                flexDirection: 'row',
                gap: 10,
                flexWrap: 'wrap',
                borderWidth: 1,
                borderColor: '#e0e0e0',
                padding: 10,
                borderRadius: 8
            }}>
                <OverflowMenu
                    anchor={() => (
                        <Input
                            label="Name"
                            size='small'
                            placeholder="Add item name..."
                            value={newLineItem?.name}
                            onChangeText={text => {
                                onNewLineItemChange(new LineItem({ ...newLineItem, name: text }));
                                setMenuSearchQuery(text);
                            }}
                            style={{ margin: 10, flex: 1, minWidth: 100 }}
                        />
                    )}
                    visible={menuVisible}
                    onBackdropPress={() => setMenuVisible(false)}
                    onSelect={onMenuItemSelect}
                >
                    {filteredMenuItems.map((item, index) => (
                        <MenuItem
                            key={index}
                            title={`${item.name} - $${item.default_price}`}
                        />
                    ))}
                </OverflowMenu>
                <Input
                    placeholder="Cost"
                    label="Cost"
                    size='small'
                    value={customItemCost.toString()}
                    onChangeText={(text) => {
                        validatePrice(text);
                        setCustomItemCost(text);
                    }}
                    onBlur={(event: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
                        const text = event.nativeEvent.text;
                        if (text === '') {
                            handleCostChange('0');
                        }
                        handleCostChange(text);
                    }}
                    keyboardType="numeric"
                    style={{ margin: 10, flex: 1, minWidth: 100 }}
                    status={priceError ? 'danger' : 'basic'}
                    caption={priceError}
                />
                <Input
                    placeholder="Quantity"
                    label="Quantity"
                    size='small'
                    value={customItemQuantity.toString()}
                    onChangeText={(text) => {
                        setCustomItemQuantity(text);
                        validateQuantity(text);
                    }}
                    onBlur={(event: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
                        const text = event.nativeEvent.text;
                        if (text === '') {
                            handleQuantityChange('0');
                        }
                        handleQuantityChange(text);
                    }}
                    keyboardType="numeric"
                    style={{ margin: 10, flex: 1, minWidth: 100 }}
                    status={quantityError ? 'danger' : 'basic'}
                    caption={quantityError}
                />
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Button
                        onPress={handleAddNewItem}
                        disabled={!newLineItem?.name || !!quantityError || !!priceError}
                        style={{ marginLeft: 10 }}
                    >
                        Add
                    </Button>
                </View>
            </View>
        </View>
    );
}; 