import React from 'react';
import { View } from 'react-native';
import { Card, Text, CheckBox } from '@ui-kitten/components';
import { CompanyCreateJobMandatoryFields, CompanySettings } from '../../models/Company.model';

interface CreateJobMandatoryFieldsProps {
    mandatoryFields: CompanyCreateJobMandatoryFields;
    onUpdateMandatoryFields: (updatedFields: CompanyCreateJobMandatoryFields) => void;
    style?: any;
}

export const CreateJobMandatoryFields: React.FC<CreateJobMandatoryFieldsProps> = ({
    mandatoryFields,
    onUpdateMandatoryFields,
    style = {}
}) => {
    const updateMandatoryField = (key: string, value: boolean) => {
        const updatedFields = new CompanyCreateJobMandatoryFields({
            ...mandatoryFields,
            [key]: value
        });
        onUpdateMandatoryFields(updatedFields);
    };

    const checkBoxes = [
        { label: 'Customer Name', key: 'customer_name' },
        { label: 'Customer Phone', key: 'customer_phone' },
        { label: 'Customer Email', key: 'customer_email' },
        { label: 'Rego', key: 'vehicle_rego' },
        { label: 'Make', key: 'vehicle_make' },
        { label: 'Model', key: 'vehicle_model' },
        { label: 'Colour', key: 'vehicle_colour' },
        { label: 'Type', key: 'vehicle_type' },
        { label: 'Year', key: 'vehicle_year' },
        { label: 'VIN', key: 'vehicle_vin' },
        { label: 'Client Reference #', key: 'client_reference_id' },
    ];

    return (
        <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Create Job Mandatory Fields</Text>} status="basic">
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {checkBoxes.map(({ label, key }) => (
                    <View key={key} style={{ margin: 5, width: "25%" }}>
                        <CheckBox
                            checked={mandatoryFields[key as keyof CompanyCreateJobMandatoryFields] || false}
                            onChange={(nextChecked) => updateMandatoryField(key, nextChecked)}
                        >
                            {label}
                        </CheckBox>
                    </View>
                ))}
            </View>
        </Card>
    );
};
