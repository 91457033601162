import React from 'react';
import { View } from 'react-native';
import DataGrid from 'react-data-grid';

interface InvoiceDataGridProps {
  columns: any[];
  rows: any[];
  onRowsChange: (rows: any[]) => void;
  onFill: (args: any) => void;
  onCopy: (args: any) => void;
  onPaste: (args: any) => void;
  sortDirection: 'ASC' | 'DESC';
  onSort: (args: any) => void;
  selectedRows: Set<any>;
  onSelectedRowsChange: (rows: Set<any>) => void;
  onCellContextMenu: (args: any) => void;
  topSummaryRows: any[];
  onCellClick: (args: any, event: any) => void;
}

export const InvoiceDataGrid = (props: InvoiceDataGridProps) => (
  <View style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, height: "100%" }}>
    <DataGrid
      style={{ height: "100%" }}
      columns={props.columns}
      rows={props.rows}
      rowKeyGetter={(row) => row.id}
      onRowsChange={props.onRowsChange}
      onFill={props.onFill}
      onCopy={props.onCopy}
      onPaste={props.onPaste}
      rowHeight={30}
      //@ts-ignore
      sortDirection={props.sortDirection}
      onSort={props.onSort}
      selectedRows={props.selectedRows}
      onSelectedRowsChange={props.onSelectedRowsChange}
      onCellContextMenu={props.onCellContextMenu}
      className="rdg-dark"
      direction="ltr"
      topSummaryRows={props.topSummaryRows}
      onCellClick={props.onCellClick}
    />
  </View>
);