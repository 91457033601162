import { LINE_ITEM_BILLED_TO } from './LineItem.model';

export enum SURCHARGE_TYPE {
    FLAT_RATE = 'flat_rate',
    PER_KM = 'per_km',
    PERCENTAGE = 'percentage',
    // PER_HOUR = 'per_hour',
    // PER_DAY = 'per_day'
}

export interface SurchargeDefinition {
    id: string;
    name: string;
    type: SURCHARGE_TYPE;
    defaultRate: number;
    description?: string;
    enabled?: boolean;
    billed_to?: LINE_ITEM_BILLED_TO;
    account_code?: string | null; // for xero account code items
    default_enabled?: boolean;
}

// Default surcharge definitions - could be moved to a separate config file or API
export const DEFAULT_SURCHARGE_DEFINITIONS: SurchargeDefinition[] = [
    {
        id: 'fuel_surcharge',
        name: 'Fuel Surcharge',
        type: SURCHARGE_TYPE.PER_KM,
        defaultRate: 0.5,
        description: 'Fuel surcharge per kilometer',
        billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
        default_enabled: false
    },
    {
        id: 'towing_surcharge',
        name: 'Towing Surcharge',
        type: SURCHARGE_TYPE.PER_KM,
        defaultRate: 1.0,
        description: 'Additional towing fee per kilometer',
        billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
        default_enabled: false
    },
    {
        id: 'after_hours',
        name: 'After Hours Fee',
        type: SURCHARGE_TYPE.FLAT_RATE,
        defaultRate: 50,
        description: 'Flat rate for after hours service',
        billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
        default_enabled: false
    },
    {
        id: 'weekend_surcharge',
        name: 'Weekend Surcharge',
        type: SURCHARGE_TYPE.PERCENTAGE,
        defaultRate: 15,
        description: '15% surcharge for weekend service',
        billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
        default_enabled: false
    },
    {
        id: 'waiting_time',
        name: 'Waiting Time',
        type: SURCHARGE_TYPE.FLAT_RATE,
        defaultRate: 80,
        description: 'Charge for waiting time',
        billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
        default_enabled: false
    }
]; 