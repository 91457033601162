import React, { useState } from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import { Text, Layout, useTheme, List, Spinner, Button, Icon } from '@ui-kitten/components';
import { Order, OrderStatus } from '../../models/Order.model';
import { Company } from '../../models/Company.model';
import { TextDetailPill } from '../common/TextDetailPill';
import { FontAwesome } from '@expo/vector-icons';
import { JobReportModal } from '../dashboardComponents/jobReportModal.component';
import { Job } from '../../models/Job.model';
import * as JobController from '../../functions/job.controller';
interface OrderListProps {
  orders: Order[];
  isLoading: boolean;
  currentCompany: Company;
  onSelectOrder: (order: Order) => void;
  selectedOrderId?: string;
  activeTab: string;
}
export enum ORDERS_TABS {
  VENDOR_ORDERS = "vendorOrders",
  CLIENT_ORDERS = "clientOrders"
}

export const OrderList: React.FC<OrderListProps> = ({
  orders,
  isLoading,
  currentCompany,
  onSelectOrder,
  selectedOrderId,
  activeTab
}) => {
  const theme = useTheme();
  const [showJobReportModal, setShowJobReportModal] = useState(false);
  const [relatedJob, setRelatedJob] = useState<Job | null>(null);
  const getStatusColor = (status: OrderStatus): string => {
    switch (status) {
      case OrderStatus.REQUEST:
        return theme['color-request-500'];
      case OrderStatus.AWAITING_PAYMENT:
        return theme['color-complete-500'];
      case OrderStatus.PROCESSING:
        return theme['color-unassigned-500'];
      case OrderStatus.READY:
        return theme['color-assigned-300'];
      case OrderStatus.SHIPPED:
        return theme['color-assigned-500'];
      case OrderStatus.DELIVERED:
        return theme['color-pending-500'];
      case OrderStatus.COMPLETED:
        return theme['color-complete-500'];
      case OrderStatus.CANCELLED:
        return theme['color-cancelled-500'];
      default:
        return theme['color-basic-600'];
    }
  };



  const getStatusText = (status: OrderStatus): string => {
    // capitalize the first letter of the status and remove the underscores
    return status.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }

  const isSelected = (orderId: string) => {
    return selectedOrderId === orderId;
  };

  const renderEmptyList = () => (
    <View style={styles.emptyListContainer}>
      <Text category="s1" style={styles.emptyListText}>
        {activeTab === 'history' ? 'No order history' : activeTab === 'vendorOrders' ? 'No outgoing orders' : 'No incoming orders'}
      </Text>
    </View>
  );

  const onShowJobReportModal = async (jobId: string) => {
    if (!jobId) return;
    const job = await JobController.getJobById(jobId);
    if (job) {
      setRelatedJob(job);
      setShowJobReportModal(true);
    }
  }

  const renderOrderItem = ({ item }: { item: Order }) => {
    if (!item) return null;
    const isVendor = activeTab !== 'myOrders';
    return (
      <TouchableOpacity
        style={[
          styles.orderItem,
          {
            backgroundColor: isSelected(item._id as string) ? theme['color-success-800'] : 'transparent',
            borderBottomWidth: 1,
            borderBottomColor: theme['color-basic-300']
          }
        ]}
        onPress={() => onSelectOrder(item)}
      >
        <View style={styles.orderHeader}>
          <View style={styles.orderHeaderLeft}>
            <Text category="s1" style={styles.orderId}>{`ORDER #${item.friendly_id || item._id?.slice(-6)}`}</Text>
          </View>
          <TextDetailPill
            label=""
            value={getStatusText(item.status)}
            valueThemeColor={getStatusColor(item.status)}
            size="small"
          />
        </View>
        <View style={[styles.orderDetails, { flexDirection: 'row', gap: 4 }]}>
          <View style={{ flexDirection: 'column', gap: 4, flex: 1 }}>
            <Text category="s2">{isVendor ? `Client: ${item.details.client_company_name}` : `Vendor: ${item.details.vendor_company_name}`}</Text>
            <Text category="s2">{`Total: $${item.total_amount.toFixed(2)}`}</Text>
          </View>
          <View style={{ flexDirection: 'column', gap: 4, flex: 3 }}>
            <Text category="s2">{`${item.items.length} items`}</Text>
            <View style={{ flexDirection: 'row' }}>
              <FontAwesome name="map-marker" size={16} color={theme['color-primary-500']} />
              <Text category="s2">{`${item.details.address.formatted_address ? item.details.address.formatted_address : 'N/A'}`}</Text>
            </View>
          </View>
        </View>
        <View style={styles.orderFooter}>
          {item.related_job_id && (
            <TouchableOpacity style={styles.iconContainer} onPress={() => onShowJobReportModal(item.related_job_id as string)}>
              <FontAwesome name="briefcase" size={16} color={theme['color-primary-500']} />
              <Text category="c1" style={styles.iconText}>Job Attached</Text>
            </TouchableOpacity>
          )}
          {item.status === OrderStatus.COMPLETED && (
            <View style={styles.iconContainer}>
              <FontAwesome name="check-circle" size={16} color={theme['color-success-500']} />
              <Text category="c1" style={styles.iconText}>Paid</Text>
            </View>
          )}
        </View>
      </TouchableOpacity>
    );
  };

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <Spinner size="large" />
      </View>
    );
  }

  return (
    <Layout style={styles.container}>
      <Text category="h6" style={styles.title}>
        {activeTab === 'history' ? 'Order History' : activeTab === 'vendorOrders' ? 'Outgoing Orders' : 'Incoming Orders'}
      </Text>
      <List
        data={orders}
        renderItem={renderOrderItem}
        ListEmptyComponent={renderEmptyList}
        keyExtractor={(item) => item._id as string}
        contentContainerStyle={styles.listContent}
        ItemSeparatorComponent={() => <View style={{ height: 1, backgroundColor: theme['color-basic-300'] }} />}
      />
      {relatedJob && (
        <JobReportModal
          setShowReportDetailsModal={setShowJobReportModal}
          showReportDetailsModal={showJobReportModal}
          job={relatedJob}
        />
      )}
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  title: {
    marginBottom: 16,
  },
  listContent: {
    paddingBottom: 16,
  },
  orderItem: {
    // borderRadius: 8,
    padding: 4,
    // marginBottom: 1,
    // elevation: 2,
    // shadowColor: '#000',
    // shadowOffset: { width: 0, height: 2 },
    // shadowOpacity: 0.1,
    // shadowRadius: 4,
  },
  orderHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
  },
  orderHeaderLeft: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  orderId: {
    marginLeft: 8,
    fontWeight: 'bold',
  },
  orderDetails: {
    marginBottom: 8,
  },
  orderFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 16,
  },
  iconText: {
    marginLeft: 4,
  },
  emptyListContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20,
  },
  emptyListText: {
    textAlign: 'center',
    color: 'gray',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
