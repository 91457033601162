import React from 'react';
import { ModalProps, ScrollView, useWindowDimensions } from 'react-native';
import {
  Modal,
  View,
  TouchableWithoutFeedback,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import { Portal } from '@gorhom/portal';
import { NotificationProvider } from '../../context/notifications.context';
interface DMModalProps {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
  style?: ViewStyle;
}

const DMModal: React.FC<DMModalProps> = ({
  visible,
  onClose,
  children,
  style,
}) => {
  const { width, height } = useWindowDimensions();

  if (!visible) return null;

  return (
    <Portal>
      <TouchableWithoutFeedback onPress={onClose}>
        <View style={styles.overlay}>
          <TouchableWithoutFeedback>
            <View style={[styles.content, { maxWidth: width * 0.99, maxHeight: height * 0.99 }, style]}>
              <NotificationProvider>
                {/* <ScrollView> */}
                {children}
                {/* </ScrollView> */}
              </NotificationProvider>
            </View>
          </TouchableWithoutFeedback>
        </View>
      </TouchableWithoutFeedback>
    </Portal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    padding: 0,
    borderRadius: 10,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});

export default DMModal;
