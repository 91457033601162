import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Button, Layout, useTheme, Icon, Modal, Input } from '@ui-kitten/components';
import { InventoryItem, InventoryCategory, PricingTier } from '../../models/Inventory.model';
import { Company } from '../../models/Company.model';
import { Client } from '../../models/Client.model';
import { CartItem } from './PartsNetworkContainerScreen';

interface PartsNetworkItemDetailProps {
    item: InventoryItem;
    onAddToCart: (item: InventoryItem, quantity: number, price: number, isGSTInclusive: boolean) => void;
    cartQuantity: number;
    currentCompany: Company;
    vendor: Company;
    ownClientProfile: Client | null;
    category: InventoryCategory | null;
    stockLevel?: number;
}

// First define the component without memo
const PartsNetworkItemDetailBase: React.FC<PartsNetworkItemDetailProps> = ({
    item,
    onAddToCart,
    cartQuantity,
    currentCompany,
    vendor,
    ownClientProfile,
    category,
    stockLevel = 0,
}) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const [modalVisible, setModalVisible] = useState(false);
    const [quantity, setQuantity] = useState(cartQuantity > 0 ? cartQuantity.toString() : '1');
    const [error, setError] = useState<string>('');

    // Update quantity when modal opens
    useEffect(() => {
        if (modalVisible) {
            setQuantity(cartQuantity > 0 ? cartQuantity.toString() : '1');
        }
    }, [modalVisible, cartQuantity]);

    const [_cartQuantity, setCartQuantity] = useState(cartQuantity);
    useEffect(() => {
        setCartQuantity(cartQuantity);
    }, [cartQuantity])

    // Memoize the tier price calculation
    const { tierPrice, tier } = useMemo(() => {
        // Early return if we don't have necessary data
        if (!category || !ownClientProfile || !item._id) {
            return { tierPrice: null, tier: null };
        }

        // Find the client tier - avoid creating unnecessary objects
        const clientTier = category.pricing_tiers.find(t =>
            t.client_ids.includes(ownClientProfile._id || '')
        );

        if (!clientTier) {
            return { tierPrice: null, tier: null };
        }

        // Calculate price based on tier rules
        let calculatedPrice = null;

        // Check item-specific price first
        if (clientTier.item_prices && clientTier.item_prices[item._id]) {
            calculatedPrice = clientTier.item_prices[item._id];
        }
        // Then multiplier
        else if (clientTier.multiplier) {
            calculatedPrice = item.default_price * clientTier.multiplier;
        }
        // Finally fixed price
        else if (clientTier.fixed_price) {
            calculatedPrice = clientTier.fixed_price;
        }

        return {
            tierPrice: calculatedPrice,
            tier: clientTier
        };
    }, [category?.pricing_tiers, ownClientProfile, item._id, item.default_price]);

    const handleQuantityChange = useCallback((value: string) => {
        setError('');
        if (!/^\d*$/.test(value)) {
            setError('Please enter a valid number');
            return;
        }
        setQuantity(value);
    }, []);

    const incrementQuantity = useCallback(() => {
        setQuantity(prev => {
            const currentQty = parseInt(prev) || 0;
            return (currentQty + 1).toString();
        });
        setError('');
    }, []);

    const decrementQuantity = useCallback(() => {
        setQuantity(prev => {
            const currentQty = parseInt(prev) || 0;
            if (currentQty > 1) {
                return (currentQty - 1).toString();
            }
            setError('Quantity must be greater than 0');
            return prev;
        });
    }, []);

    const handleAddToCart = useCallback(() => {
        const parsedQuantity = parseInt(quantity, 10);
        if (isNaN(parsedQuantity)) {
            setError('Please enter a valid number');
            return;
        }
        if (parsedQuantity <= 0) {
            setError('Quantity must be greater than 0');
            return;
        }
        if (parsedQuantity > stockLevel) {
            setError('Quantity cannot exceed available stock');
            return;
        }
        const priceToUse = tierPrice !== null ? tierPrice : item.default_price;
        onAddToCart(item, parsedQuantity, priceToUse, tier?.is_gst_inclusive ?? true);
        setModalVisible(false);
        setError('');
    }, [quantity, tierPrice, item, tier, onAddToCart, stockLevel]);

    // Memoize the price display text
    const priceDisplayText = useMemo(() => {
        if (tierPrice !== null) {
            return `Your Price: $${tierPrice.toFixed(2)} ${tier?.is_gst_inclusive ? '(GST Inclusive)' : '(GST Exclusive)'}`;
        }
        return `Price: $${item.default_price.toFixed(2)}`;
    }, [tierPrice, tier?.is_gst_inclusive, item.default_price]);

    const isInCart = cartQuantity > 0;



    return (
        <Layout style={[styles.container, isInCart && styles.highlightedContainer]}>
            <View style={styles.topRow}>
                <Text category='s1' style={styles.name}>{item.name}</Text>
                <View style={styles.rightColumn}>
                    <Button
                        size='small'
                        onPress={() => setModalVisible(true)}
                        accessoryLeft={(props) => <Icon {...props} name='shopping-cart-outline' />}
                    />
                    {_cartQuantity > 0 && (
                        <Text style={styles.cartQuantity}>{_cartQuantity}</Text>
                    )}
                </View>
            </View>
            <Text category='p2' style={styles.description}>{item.description ?? 'No description available'}</Text>
            <View style={styles.bottomRow}>
                <Text style={[
                    styles.detailTextPill,
                    { backgroundColor: tierPrice !== null ? theme['color-info-500'] : theme['color-success-500'] }
                ]}>
                    {priceDisplayText}
                </Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <Text category='p1'>SKU: </Text>
                <Text category='p2'>{item.sku ?? 'N/A'}</Text>
            </View>
            <View style={{ flexDirection: 'row' }}>
                <Text category='p1'>Stock Level: </Text>
                <Text category='p2' style={{ color: stockLevel > 0 ? theme['color-success-600'] : theme['color-danger-600'] }}>
                    {stockLevel}
                </Text>
            </View>

            <Modal
                visible={modalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => {
                    setModalVisible(false);
                    setError('');
                }}
            >
                <Layout style={styles.modalContainer}>
                    <Text category='h6' style={styles.modalTitle}>Add to Cart</Text>
                    <Text category='s1'>{item.name}</Text>
                    <Text category='c1' style={styles.stockText}>Available Stock: {stockLevel}</Text>

                    <View style={styles.quantityContainer}>
                        <Button
                            size='small'
                            onPress={decrementQuantity}
                            style={styles.quantityButton}
                            disabled={parseInt(quantity) <= 1}
                        >
                            -
                        </Button>
                        <Input
                            placeholder="Quantity"
                            value={quantity}
                            onChangeText={handleQuantityChange}
                            keyboardType="numeric"
                            style={styles.quantityInput}
                            status={error ? 'danger' : 'basic'}
                        />
                        <Button
                            size='small'
                            onPress={incrementQuantity}
                            style={styles.quantityButton}
                            disabled={parseInt(quantity) >= stockLevel}
                        >
                            +
                        </Button>
                    </View>

                    {error && (
                        <Text status='danger' category='c1' style={styles.errorText}>
                            {error}
                        </Text>
                    )}

                    <View style={styles.modalActions}>
                        <Button
                            status='basic'
                            onPress={() => {
                                setModalVisible(false);
                                setError('');
                            }}
                            style={styles.modalButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={handleAddToCart}
                            style={styles.modalButton}
                            disabled={!!error || parseInt(quantity) > stockLevel}
                        >
                            Add
                        </Button>
                    </View>
                </Layout>
            </Modal>
        </Layout>
    );
};

// Then create the memoized version
const PartsNetworkItemDetail = React.memo(PartsNetworkItemDetailBase, (prevProps, nextProps) => {
    // Deep comparison for props that affect rendering
    return prevProps.item._id === nextProps.item._id &&
        prevProps.cartQuantity === nextProps.cartQuantity &&
        prevProps.stockLevel === nextProps.stockLevel &&
        prevProps.category?._id === nextProps.category?._id &&
        prevProps.ownClientProfile?._id === nextProps.ownClientProfile?._id;
});

const getStyles = (theme: any) => StyleSheet.create({
    container: {
        padding: 8,
        borderWidth: 1,
        borderColor: theme['color-basic-400'],
        marginBottom: 4,
        borderRadius: 8,
    },
    topRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
    },
    name: {
        flex: 1,
        fontWeight: 'bold',
    },
    rightColumn: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    description: {
        // marginBottom: 8,
    },
    bottomRow: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    detailTextPill: {
        borderRadius: 16,
        paddingHorizontal: 8,
        paddingVertical: 2,
        marginRight: 8,
    },
    highlightedContainer: {
        backgroundColor: theme['color-primary-100'],
    },
    cartQuantity: {
        position: 'absolute',
        top: -5,
        right: -5,
        backgroundColor: theme['color-danger-500'],
        color: 'white',
        borderRadius: 12,
        minWidth: 20,
        height: 20,
        paddingHorizontal: 6,
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 2,
        // borderColor: 'white',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        padding: 16,
        borderRadius: 8,
        minWidth: 300,
        gap: 16,
    },
    modalTitle: {
        textAlign: 'center',
        marginBottom: 8,
    },
    quantityContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
    },
    quantityButton: {
        minWidth: 40,
    },
    quantityInput: {
        flex: 1,
        textAlign: 'center',
    },
    errorText: {
        textAlign: 'center',
    },
    modalActions: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 8,
    },
    modalButton: {
        flex: 1,
    },
    stockText: {
        textAlign: 'center',
        marginBottom: 8,
    },
});

// Export the memoized version
export default PartsNetworkItemDetail;
