import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, Button, Layout, useTheme, Icon, Modal, Input } from '@ui-kitten/components';
import { InventoryItem, InventoryCategory, PricingTier } from '../../models/Inventory.model';
import { Company } from '../../models/Company.model';
import { Client } from '../../models/Client.model';
import { CartItem } from './PartsNetworkContainerScreen';

interface PartsNetworkItemDetailProps {
    item: InventoryItem;
    onAddToCart: (item: InventoryItem, quantity: number, price: number, isGSTInclusive: boolean) => void;
    cartQuantity: number;
    currentCompany: Company;
    vendor: Company;
    ownClientProfile: Client | null;
    category: InventoryCategory;
}

const PartsNetworkItemDetail: React.FC<PartsNetworkItemDetailProps> = ({
    item,
    onAddToCart,
    cartQuantity,
    currentCompany,
    vendor,
    ownClientProfile,
    category,
}) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const [modalVisible, setModalVisible] = useState(false);
    const [quantity, setQuantity] = useState('1');
    const [tierPrice, setTierPrice] = useState<number | null>(null);
    const [tier, setTier] = useState<PricingTier | null>(null);
    const [error, setError] = useState<string>('');

    useEffect(() => {
        calculateTierPrice();
    }, [item, currentCompany, vendor, category]);

    const calculateTierPrice = () => {
        let clientTier = category.pricing_tiers.find(tier =>
            tier.client_ids.includes(ownClientProfile?._id ?? '')
        );
        clientTier = new PricingTier(clientTier);

        if (clientTier) {
            setTier(clientTier);
            if (clientTier.item_prices[item._id ?? '']) {
                setTierPrice(clientTier.item_prices[item._id ?? '']);
            } else if (clientTier.multiplier) {
                setTierPrice(item.default_price * clientTier.multiplier);
            } else if (clientTier.fixed_price) {
                setTierPrice(clientTier.fixed_price);
            }
        } else {
            setTierPrice(null);
        }
    };

    const handleQuantityChange = (value: string) => {
        setError('');
        // Only allow numbers
        if (!/^\d*$/.test(value)) {
            setError('Please enter a valid number');
            return;
        }
        setQuantity(value);
    };

    const incrementQuantity = () => {
        const currentQty = parseInt(quantity) || 0;
        setQuantity((currentQty + 1).toString());
        setError('');
    };

    const decrementQuantity = () => {
        const currentQty = parseInt(quantity) || 0;
        if (currentQty > 1) {
            setQuantity((currentQty - 1).toString());
            setError('');
        } else {
            setError('Quantity must be greater than 0');
        }
    };

    const handleAddToCart = () => {
        const parsedQuantity = parseInt(quantity, 10);
        if (isNaN(parsedQuantity)) {
            setError('Please enter a valid number');
            return;
        }
        if (parsedQuantity <= 0) {
            setError('Quantity must be greater than 0');
            return;
        }
        const priceToUse = tierPrice !== null ? tierPrice : item.default_price;
        onAddToCart(item, parsedQuantity, priceToUse, tier?.is_gst_inclusive ?? true);
        setModalVisible(false);
        setQuantity('1');
        setError('');
    };

    const isInCart = cartQuantity > 0;

    return (
        <Layout style={[styles.container, isInCart && styles.highlightedContainer]}>
            <View style={styles.topRow}>
                <Text category='s1' style={styles.name}>{item.name}</Text>
                <View style={styles.rightColumn}>
                    <Button
                        size='small'
                        onPress={() => setModalVisible(true)}
                        accessoryLeft={(props) => <Icon {...props} name='shopping-cart-outline' />}
                    />
                    {cartQuantity > 0 && (
                        <Text style={styles.cartQuantity}>{cartQuantity}</Text>
                    )}
                </View>
            </View>
            <Text category='p2' style={styles.description}>{item.description ?? 'No description available'}</Text>
            <View style={styles.bottomRow}>
                {/* <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-500'] }]}>
                    Base: ${item.default_price.toFixed(2)}
                </Text> */}
                {tierPrice !== null ? (
                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-info-500'] }]}>
                        Your Price: ${tierPrice.toFixed(2)} {tier?.is_gst_inclusive ? '(GST Inclusive)' : '(GST Exclusive)'}
                    </Text>
                ) : (
                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-500'] }]}>
                        Price: ${item.default_price.toFixed(2)}
                    </Text>
                )}
            </View>
            <View style={{ flexDirection: 'row' }}>
                <Text category='p1'>SKU: </Text>
                <Text category='p2'>{item.sku ?? 'N/A'}</Text>
            </View>

            <Modal
                visible={modalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => {
                    setModalVisible(false);
                    setError('');
                    setQuantity('1');
                }}
            >
                <Layout style={styles.modalContainer}>
                    <Text category='h6' style={styles.modalTitle}>Add to Cart</Text>
                    <Text category='s1'>{item.name}</Text>

                    <View style={styles.quantityContainer}>
                        <Button
                            size='small'
                            onPress={decrementQuantity}
                            style={styles.quantityButton}
                        >
                            -
                        </Button>
                        <Input
                            placeholder="Quantity"
                            value={quantity}
                            onChangeText={handleQuantityChange}
                            keyboardType="numeric"
                            style={styles.quantityInput}
                            status={error ? 'danger' : 'basic'}
                        />
                        <Button
                            size='small'
                            onPress={incrementQuantity}
                            style={styles.quantityButton}
                        >
                            +
                        </Button>
                    </View>

                    {error && (
                        <Text status='danger' category='c1' style={styles.errorText}>
                            {error}
                        </Text>
                    )}

                    <View style={styles.modalActions}>
                        <Button
                            status='basic'
                            onPress={() => {
                                setModalVisible(false);
                                setError('');
                                setQuantity('1');
                            }}
                            style={styles.modalButton}
                        >
                            Cancel
                        </Button>
                        <Button
                            onPress={handleAddToCart}
                            style={styles.modalButton}
                        >
                            Add
                        </Button>
                    </View>
                </Layout>
            </Modal>
        </Layout>
    );
};

const getStyles = (theme: any) => StyleSheet.create({
    container: {
        padding: 8,
        borderWidth: 1,
        borderColor: theme['color-basic-400'],
        marginBottom: 4,
        borderRadius: 8,
    },
    topRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
    },
    name: {
        flex: 1,
        fontWeight: 'bold',
    },
    rightColumn: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    description: {
        // marginBottom: 8,
    },
    bottomRow: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    detailTextPill: {
        borderRadius: 16,
        paddingHorizontal: 8,
        paddingVertical: 2,
        marginRight: 8,
    },
    highlightedContainer: {
        backgroundColor: theme['color-primary-100'],
    },
    cartQuantity: {
        position: 'absolute',
        top: -5,
        right: -5,
        backgroundColor: theme['color-danger-500'],
        color: 'white',
        borderRadius: 12,
        minWidth: 20,
        height: 20,
        paddingHorizontal: 6,
        justifyContent: 'center',
        alignItems: 'center',
        // borderWidth: 2,
        // borderColor: 'white',
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        padding: 16,
        borderRadius: 8,
        minWidth: 300,
        gap: 16,
    },
    modalTitle: {
        textAlign: 'center',
        marginBottom: 8,
    },
    quantityContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
    },
    quantityButton: {
        minWidth: 40,
    },
    quantityInput: {
        flex: 1,
        textAlign: 'center',
    },
    errorText: {
        textAlign: 'center',
    },
    modalActions: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 8,
    },
    modalButton: {
        flex: 1,
    },
});

export default PartsNetworkItemDetail;
