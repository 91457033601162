var config = require('../config/config.js');
const axios = require('axios');
import * as StorageController from './storageController';
import { Order, OrderSearchParams } from '../models/Order.model';

var api = config.api;
var testApi = config.test_api;

async function getApi() {
    if (await StorageController.getUseTestApi()) {
        return testApi;
    }
    return api;
}

axios.interceptors.request.use(
    async (config: any) => {
        let token = await getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
};

export async function initiateStripeConnectOnboarding(companyId: string): Promise<string> {
    try {
        const api = await getApi();
        const response = await axios.post(`${api}/parts_network/stripe/initiate_stripe_connect_onboarding`, { companyId: companyId });
        return response.data.url;
    } catch (error) {
        console.error('Error initiating Stripe Connect onboarding:', error);
        throw error;
    }
}

export async function completeStripeConnectOnboarding(companyId: string, accountId: string): Promise<void> {
    try {
        const api = await getApi();
        await axios.get(`${api}/parts_network/stripe/complete_stripe_connect_onboarding/${companyId}/${accountId}`);
    } catch (error) {
        console.error('Error completing Stripe Connect onboarding:', error);
        throw error;
    }
}

export async function createMarketplacePayment(companyId: string, amount: number, currency: string, description: string): Promise<string> {
    try {
        const api = await getApi();
        const response = await axios.post(`${api}/parts_network/stripe/create_marketplace_payment`, { companyId, amount, currency, description });
        return response.data.clientSecret;
    } catch (error) {
        console.error('Error creating marketplace payment:', error);
        throw error;
    }
}

// // Add this with the other Stripe Connect routes
// router.get('/parts_network/stripe/check_connect_status/:companyId', CompanySubscriptionController.checkStripeConnectStatus);
export interface CheckStripeConnectStatusResponse {
    account: any;
    isSetup: boolean;
    message: string;
    accountLink: string;
    loginLink: any;
}
export async function checkStripeConnectStatus(companyId: string): Promise<CheckStripeConnectStatusResponse> {
    try {
        const api = await getApi();
        const response = await axios.get(`${api}/parts_network/stripe/check_connect_status/${companyId}`);
        return response.data;
    } catch (error) {
        console.error('Error checking Stripe Connect status:', error);
        throw error;
    }
}

