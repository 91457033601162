import React from 'react';
import { View } from 'react-native';
import { Button, Card, Icon, Text } from '@ui-kitten/components';
import { TowingAddressDetailsCard } from './towingAddressDetailsCard.component';
import { TowingLocation, JobLocation, JOB_LOCATION_TYPE, JobAddress } from '../../../models/Job.model';
import { CompanySavedAddress } from '../../../models/Company.model';
import * as GoogleController from '../../../functions/google.controller';

interface TowingLocationsListProps {
    towingLocations: TowingLocation[];
    towingLegs: any[];
    originalDropoffLocation: TowingLocation | null;
    allowEdit: boolean;
    pickupAddressDisplayValue: string | null;
    dropoffAddressDisplayValue: string | null;
    onAddLocation: (index: number) => void;
    onRemoveLocation: (index: number) => void;
    onRemoveAndSaveAsOriginalDestination: (index: number) => void;
    onRemoveOriginalDropoffLocation: () => void;
    onLocationObjectChange: (location: TowingLocation, index: number) => void;
    onAddressChange: (address: JobAddress, index: number) => void;
    onLocationChange: (location: JobLocation, index: number) => void;
    onSavedAddressSelect: (savedAddress: CompanySavedAddress, index: number) => void;
    onAddressLocationChange: (address: JobAddress, location: JobLocation, index: number) => void;
}



export const TowingLocationsList = ({
    towingLocations,
    towingLegs,
    originalDropoffLocation,
    allowEdit,
    pickupAddressDisplayValue,
    dropoffAddressDisplayValue,
    onAddLocation,
    onRemoveLocation,
    onRemoveAndSaveAsOriginalDestination,
    onRemoveOriginalDropoffLocation,
    onLocationObjectChange,
    onAddressChange,
    onLocationChange,
    onSavedAddressSelect,
    onAddressLocationChange,
}: TowingLocationsListProps) => {
    return (
        <Card
            status="basic"
            header={() => <Text style={{ alignSelf: 'center' }}>{"Towing Locations"}</Text>}
            disabled={true}
        >
            {towingLocations.map((towingLocation, index) => {
                const showAddLocationButton = !towingLocation.time ||
                    !(towingLocations[index - 1]?.time && towingLocations[index + 1]?.time) ||
                    (index === 0 && !towingLocations[index + 1]?.time);

                const cardName = index === 0 ? "Pickup" :
                    index === towingLocations.length - 1 ? "Dropoff" :
                        `Location ${index}`;

                return (
                    <React.Fragment key={index}>
                        {towingLocation.time && towingLocation.time != 0 ? (
                            <RenderTowingLocationInfoOnlyCard towing_location={towingLocation} />
                        ) : (
                            <TowingAddressDetailsCard
                                isDropoff={index === towingLocations.length - 1}
                                index={index}
                                collapsed={false}
                                towingLocationObject={towingLocation}
                                cardName={cardName}
                                allowEdit={allowEdit}
                                style={{ marginHorizontal: -21 }}
                                onRemoveTowingLocation={() => onRemoveLocation(index)}
                                onToggleCollapse={() => { }}
                                onSetTowingLocationObject={(updatedLocation) => onLocationObjectChange(updatedLocation, index)}
                                onSetAddress={(address) => onAddressChange(address, index)}
                                onSetLocation={(location) => onLocationChange(location, index)}
                                onSetAddressLocation={(address, location) => onAddressLocationChange(address, location, index)}
                                onRemoveAndSaveAsOriginalDestination={() => onRemoveAndSaveAsOriginalDestination(index)}
                                defaultAddressDisplayValue={
                                    index === 0 ? pickupAddressDisplayValue || "" :
                                        index === towingLocations.length - 1 ? dropoffAddressDisplayValue || "" :
                                            ""
                                }
                                onSetAddressType={() => { }}
                                onSetSavedAddress={(savedAddress) => onSavedAddressSelect(savedAddress, index)}
                            />
                        )}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            {showAddLocationButton ? (
                                <Button
                                    style={{ margin: 3 }}
                                    size='small'
                                    status='success'
                                    appearance='filled'
                                    onPress={() => onAddLocation(index + 1)}
                                    accessoryLeft={() => <Icon name='plus-outline' width={24} height={24} fill="white" />}
                                >
                                    Add location
                                </Button>
                            ) : (
                                <View />
                            )}
                            {towingLocation.location_type === JOB_LOCATION_TYPE.HOLDING &&
                                index === towingLocations.length - 1 && (
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Icon name='cube-outline' width={24} height={24} fill="orange" />
                                        <Text category='s1' style={{ margin: 5 }}>
                                            This will create a Holding when the job is complete
                                        </Text>
                                    </View>
                                )}
                            {index !== towingLocations.length - 1 && towingLegs[index] && (
                                <>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Icon name='arrow-right-outline' width={24} height={24} fill="white" />
                                        <Text>{towingLegs[index]?.directions?.distance_text}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                        <Icon name='clock-outline' width={24} height={24} fill="white" />
                                        <Text>{towingLegs[index]?.directions?.duration_text}</Text>
                                    </View>
                                </>
                            )}
                        </View>
                    </React.Fragment>
                );
            })}

            {originalDropoffLocation && (
                <View>
                    <View style={{ width: "100%", height: 4, backgroundColor: 'white' }} />
                    <Text category='s1'>
                        Original Dropoff - {GoogleController.addressBuilder(originalDropoffLocation?.address)}
                        When removed from holding, the job created will retain this as the dropoff location
                    </Text>
                    <TowingAddressDetailsCard
                        defaultAddressDisplayValue=''
                        index={0}
                        isDropoff={true}
                        collapsed={true}
                        towingLocationObject={originalDropoffLocation}
                        cardName={"Original Dropoff"}
                        allowEdit={false}
                        style={{}}
                        onRemoveTowingLocation={onRemoveOriginalDropoffLocation}
                        onToggleCollapse={() => { }}
                        onSetTowingLocationObject={() => { }}
                        onSetAddress={() => { }}
                        onSetLocation={() => { }}
                        onSetAddressLocation={() => { }}
                        onSetAddressType={() => { }}
                        onSetSavedAddress={() => { }}
                        onRemoveAndSaveAsOriginalDestination={() => { }}
                    />
                </View>
            )}
        </Card>
    );
};



const RenderTowingLocationInfoOnlyCard = ({ towing_location }: { towing_location: TowingLocation }) => {
    const formatDate = (date: any) => {
        return Intl.DateTimeFormat('en-AU', {
            day: '2-digit',
            month: 'short',
            year: '2-digit',
            hour: "2-digit",
            minute: '2-digit',
            hour12: true,
        }).format(date);
    };

    return (
        <Card
            status="info"
            header={() => <Text style={{ alignSelf: 'center' }}>{towing_location.name} - COMPLETE</Text>}
            disabled={true}
        >
            <View style={{ flexDirection: 'column', justifyContent: "space-between", margin: -15 }}>
                <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: 'row' }}>
                        <Icon name='clock-outline' width={24} height={24} fill="white" />
                        <Text style={{ margin: 5 }}>{towing_location.time ? formatDate(new Date(towing_location.time)) : ""}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Icon name='pin-outline' width={24} height={24} fill="white" />
                        <Text style={{ margin: 5 }}>{GoogleController.addressBuilder(towing_location.address)}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Icon name='pin-outline' width={24} height={24} fill="white" />
                        <Text style={{ margin: 5 }}>{towing_location.location_type}: </Text>
                        {towing_location.location_type == JOB_LOCATION_TYPE.HOLDING && (
                            <Text style={{ margin: 5 }}>{towing_location.holding_reason}</Text>
                        )}
                        {towing_location.location_type == JOB_LOCATION_TYPE.ADDRESS && (
                            <Text style={{ margin: 5 }}>{towing_location.address_type}</Text>
                        )}
                    </View>
                </View>
            </View>
        </Card>
    );
};