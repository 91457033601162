import React, { useState, useRef } from 'react';
import { View } from "react-native";
import {
    Button,
    Icon,
    Layout,
    Card,
    Input,
    Modal,
    Text,
    List,
    ListItem
} from "@ui-kitten/components";

interface OptionsFieldProps {
    field: string,
    options: string[],
    label: string,
    disabled: boolean,
    onAddOption: Function,
    onRemoveOption: Function,
    onUpdateOption: Function
}

export const OptionsField = ({
    field,
    options,
    label = "Options",
    disabled,
    onAddOption,
    onRemoveOption,
    onUpdateOption
}: OptionsFieldProps) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionText, setSelectedOptionText] = useState("");
    const [showEditOptionModal, setShowEditOptionModal] = useState(false);

    const RenderNewOptionModal = () => {
        const optionTextRef = useRef("");
        return (
            <Modal
                visible={showEditOptionModal}
                backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                onBackdropPress={() => setShowEditOptionModal(false)}
            >
                <Card disabled={true}>
                    <Input
                        label="Edit Option"
                        defaultValue={selectedOptionText}
                        onChangeText={(val: string) => optionTextRef.current = val}
                    />
                    <Button
                        onPress={() => {
                            onUpdateOption(field, selectedOption, optionTextRef.current);
                            setShowEditOptionModal(false);
                        }}
                    >
                        Save
                    </Button>
                </Card>
            </Modal>
        )
    }

    const RenderButtons = () => {
        return (
            <Layout style={{ flexDirection: 'row', gap: 10, justifyContent: 'space-between' }}>
                <Button
                    appearance='outline'
                    disabled={disabled}
                    onPress={() => {
                        onAddOption(field, 'New Option');
                    }}
                    accessoryLeft={(props: any) => <Icon {...props} name="plus-outline" />}
                    status="success"
                >
                    Add Option
                </Button>
            </Layout>
        )
    }

    const RenderOptionsList = () => {
        return (
            <View style={{ flex: 5 }}>
                <List
                    data={options}
                    renderItem={({ item }: any) => (
                        <ListItem
                            title={item}
                            style={{
                                border: '1px solid black'
                            }}
                            accessoryRight={() =>
                                <View style={{ flexDirection: 'row' }}>
                                    <Button
                                        status='success'
                                        appearance='outline'
                                        onPress={() => {
                                            setSelectedOptionText(item);
                                            setSelectedOption(item);
                                            setShowEditOptionModal(true);
                                        }}
                                    >
                                        <Icon name='edit-outline' fill="white" width={15} height={15} />
                                    </Button>
                                    <Button
                                        status="danger"
                                        appearance='outline'
                                        onPress={() => {
                                            onRemoveOption(field, item);
                                        }}
                                    >
                                        <Icon name='trash-2-outline' fill="white" width={15} height={15} />
                                    </Button>
                                </View>
                            }
                            onPress={() => {
                                setSelectedOptionText(item);
                                setSelectedOption(item);
                            }}
                        />
                    )}
                />
            </View>
        )
    }

    return (
        <Card
            style={{ flex: 1 }}
            header={() => <Text category='s1'>{label}</Text>}
            disabled
            status="primary"
        >
            <RenderNewOptionModal />
            <View style={{ flex: 1, gap: 10 }}>
                <RenderButtons />
                <RenderOptionsList />
            </View>
        </Card>
    )
} 