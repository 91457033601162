import React, { useState, useEffect, useMemo } from 'react';
import { StyleSheet, View, Animated } from 'react-native';
import { Layout, Text, Icon, Button } from '@ui-kitten/components';
import * as StorageController from '../../functions/storageController';
import { Company } from '../../models/Company.model';
import { PartsNetworkSettings } from './PartsNetworkSettings';
import { VendorList } from './VendorList';
import { OrderList } from './OrderList';
import { OrderDetail } from './OrderDetail';
import { ManageSales } from './ManageSales';
import { VendorStore } from './VendorStore';
import { Cart } from './Cart';
import { InventoryItem } from '../../models/Inventory.model';
import { Order, OrderSearchParams, OrderStatus } from '../../models/Order.model';
import { useAppStateChange } from '../../hooks/appStateChange.hook';
import { IAction } from '../../functions/update.controller';
import * as OrderController from '../../functions/order.controller';
import { useNotification } from '../../context/notifications.context';

export interface CartItem extends InventoryItem {
    quantity: number;
    vendorId: string;
    vendorName: string;
    isGSTInclusive: boolean;
}

// Add this new type for our menu structure
interface MenuTab {
    key: string;
    title: string;
    subTabs?: { key: string; title: string }[];
}



export const PartsNetworkContainerScreen = ({ navigation }: any) => {
    const [currentCompany, setCurrentCompany] = useState<Company | null>(null);
    const [activeTab, setActiveTab] = useState('vendors');
    const [selectedVendor, setSelectedVendor] = useState<Company | null>(null);
    const [cart, setCart] = useState<CartItem[]>([]);
    const [cartVisible, setCartVisible] = useState(false);
    const slideAnim = useState(new Animated.Value(400))[0]; // Assuming 400 is the width of the cart
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { showNotification } = useNotification();
    const [showHistorical, setShowHistorical] = useState(false);
    useEffect(() => {
        onLoad();
    }, []);

    useEffect(() => {
        if (currentCompany && activeTab !== 'vendors' && activeTab !== 'settings') {
            fetchOrders();
        }
    }, [currentCompany, activeTab]);

    const onLoad = () => {
        const company = StorageController.getCurrentCompany();
        setCurrentCompany(new Company(company));
        setActiveTab('vendors');
        setCart([]);
        setSelectedVendor(null);
        setCartVisible(false);
    }

    const fetchOrders = async () => {
        if (!currentCompany || !currentCompany._id) return;

        setIsLoading(true);
        try {
            let searchStatuses;
            if (showHistorical) {
                searchStatuses = [OrderStatus.COMPLETED, OrderStatus.CANCELLED];
            } else {
                searchStatuses = [
                    OrderStatus.REQUEST,
                    OrderStatus.PENDING,
                    OrderStatus.PROCESSING,
                    OrderStatus.READY,
                    OrderStatus.SHIPPED,
                    OrderStatus.DELIVERED,
                    OrderStatus.AWAITING_PAYMENT
                ];
            }

            let searchParams: OrderSearchParams = {
                status: searchStatuses,
                limit: 100,
                offset: 0
            };

            if (activeTab === 'vendorOrders') {
                searchParams.vendor_company_id = currentCompany._id;
            } else {
                searchParams.client_company_id = currentCompany._id;
            }

            const fetchedOrders = await OrderController.searchOrders(searchParams);
            if (fetchedOrders) {
                setOrders(fetchedOrders);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCompanyChangeEvent = (action: IAction) => {
        onLoad()
    };

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: (action: IAction) => {
            handleCompanyChangeEvent(action)
        },
    })

    // First, let's define our tab types more clearly
    type TabType = 'vendors' | 'clientOrders_current' | 'clientOrders_history' | 'vendorOrders_current' | 'vendorOrders_history' | 'sales' | 'settings';

    const tabnames = {
        vendors: 'Vendors',
        clientOrders_current: 'Current Incoming Orders',
        clientOrders_history: 'Incoming Order History',
        vendorOrders_current: 'Current Outgoing Orders',
        vendorOrders_history: 'Outgoing Order History',
        sales: 'Manage Sales',
        settings: 'Settings'
    } as const;

    // Create specific fetch functions for each order type
    const fetchClientOrdersCurrent = async () => {
        if (!currentCompany?._id) return;
        setIsLoading(true);
        try {
            const searchParams: OrderSearchParams = {
                client_company_id: currentCompany._id,
                status: [
                    OrderStatus.REQUEST,
                    OrderStatus.PENDING,
                    OrderStatus.PROCESSING,
                    OrderStatus.READY,
                    OrderStatus.SHIPPED,
                    OrderStatus.DELIVERED,
                    OrderStatus.AWAITING_PAYMENT
                ],
                limit: 100,
                offset: 0
            };
            const fetchedOrders = await OrderController.searchOrders(searchParams);
            if (fetchedOrders)
                setTimeout(() => setOrders(fetchedOrders), 0);
        } catch (error) {
            console.error('Error fetching client current orders:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchClientOrdersHistory = async () => {
        if (!currentCompany?._id) return;
        setIsLoading(true);
        try {
            const searchParams: OrderSearchParams = {
                client_company_id: currentCompany._id,
                status: [OrderStatus.COMPLETED, OrderStatus.CANCELLED],
                limit: 100,
                offset: 0
            };
            const fetchedOrders = await OrderController.searchOrders(searchParams);
            if (fetchedOrders) setTimeout(() => setOrders(fetchedOrders), 0);
        } catch (error) {
            console.error('Error fetching client order history:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchVendorOrdersCurrent = async () => {
        if (!currentCompany?._id) return;
        setIsLoading(true);
        try {
            const searchParams: OrderSearchParams = {
                vendor_company_id: currentCompany._id,
                status: [
                    OrderStatus.REQUEST,
                    OrderStatus.PENDING,
                    OrderStatus.PROCESSING,
                    OrderStatus.READY,
                    OrderStatus.SHIPPED,
                    OrderStatus.DELIVERED,
                    OrderStatus.AWAITING_PAYMENT
                ],
                limit: 100,
                offset: 0
            };
            const fetchedOrders = await OrderController.searchOrders(searchParams);
            if (fetchedOrders) setTimeout(() => setOrders(fetchedOrders), 0);
        } catch (error) {
            console.error('Error fetching vendor current orders:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchVendorOrdersHistory = async () => {
        if (!currentCompany?._id) return;
        setIsLoading(true);
        try {
            const searchParams: OrderSearchParams = {
                vendor_company_id: currentCompany._id,
                status: [OrderStatus.COMPLETED, OrderStatus.CANCELLED],
                limit: 100,
                offset: 0
            };
            const fetchedOrders = await OrderController.searchOrders(searchParams);
            if (fetchedOrders) setTimeout(() => setOrders(fetchedOrders), 0);
        } catch (error) {
            console.error('Error fetching vendor order history:', error);
        } finally {
            setIsLoading(false);
        }
    };

    // Function to handle tab changes
    const handleTabChange = (newTab: TabType) => {
        setActiveTab(newTab);
        setSelectedOrder(null);

        // Call appropriate fetch function based on tab
        switch (newTab) {
            case 'clientOrders_current':
                fetchClientOrdersCurrent();
                break;
            case 'clientOrders_history':
                fetchClientOrdersHistory();
                break;
            case 'vendorOrders_current':
                fetchVendorOrdersCurrent();
                break;
            case 'vendorOrders_history':
                fetchVendorOrdersHistory();
                break;
        }
    };

    const handleBackToVendors = () => {
        setSelectedVendor(null);
    };

    const addToCart = (item: InventoryItem, quantity: number, vendorId: string, vendorName: string, isGSTInclusive: boolean) => {
        setCart((prevCart: CartItem[]) => {
            const existingItem = prevCart.find((cartItem: CartItem) => cartItem._id === item._id);
            if (existingItem) {
                return prevCart.map((cartItem: CartItem) =>
                    cartItem._id === item._id
                        ? { ...cartItem, quantity: cartItem.quantity + quantity } as CartItem
                        : cartItem
                );
            } else {
                const newCartItem: CartItem = {
                    ...item,
                    quantity,
                    vendorId,
                    vendorName,
                    toLineItem: item.toLineItem, // Assuming InventoryItem has this method
                    isGSTInclusive
                };
                return [...prevCart, newCartItem];
            }
        });
    };

    const updateCartItemQuantity = (itemId: string, newQuantity: number) => {
        setCart((prevCart: CartItem[]) =>
            prevCart.map((item: CartItem) =>
                item._id === itemId
                    ? {
                        ...item,
                        quantity: newQuantity,
                        vendorId: selectedVendor?._id ?? item.vendorId,
                        vendorName: selectedVendor?.name ?? item.vendorName,
                    } as CartItem
                    : item
            )
        );
    };

    const removeFromCart = (itemId: string) => {
        setCart((prevCart: CartItem[]) => prevCart.filter((item: CartItem) => item._id !== itemId));
    };

    const toggleCart = () => {
        setCartVisible(!cartVisible);
        Animated.timing(slideAnim, {
            toValue: cartVisible ? 400 : 0,
            duration: 300,
            useNativeDriver: true,
        }).start();
    };

    const renderSettingsButton = () => (
        <View style={{ flexDirection: 'row' }}>
            <View style={styles.cartButtonContainer}>
                <Button
                    appearance="ghost"
                    accessoryLeft={(props) => <Icon {...props} name="shopping-cart-outline" />}
                    onPress={toggleCart}
                />
                {cart.length > 0 && (
                    <View style={styles.cartBadge}>
                        <Text style={styles.cartBadgeText}>{cart.length}</Text>
                    </View>
                )}
            </View>
            <Button
                appearance="ghost"
                accessoryLeft={(props) => <Icon {...props} name="settings-2-outline" />}
                onPress={() => setActiveTab('settings')}
            />
        </View>
    );

    const handleOrderUpdated = () => {
        setSelectedOrder(null);
        fetchOrders(); // Refresh the order list
    };

    const renderContent = useMemo(() => {
        const baseTab = activeTab.split('_')[0]; // Gets 'clientOrders' from 'clientOrders_current'

        switch (baseTab) {
            case 'vendors':
                return selectedVendor ? (
                    <VendorStore
                        vendor={selectedVendor}
                        onBack={handleBackToVendors}
                        addToCart={(item, quantity, isGSTInclusive) => addToCart(item, quantity, selectedVendor._id, selectedVendor.name, isGSTInclusive)}
                        cart={cart}
                        clientCompany={currentCompany ?? new Company({})}
                    />
                ) : (
                    <VendorList onSelectVendor={setSelectedVendor} />
                );
            case 'clientOrders':
            case 'vendorOrders':
                return (
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                        <View style={{ flex: 1 }}>
                            <OrderList
                                orders={orders}
                                isLoading={isLoading}
                                currentCompany={currentCompany ?? new Company({})}
                                onSelectOrder={setSelectedOrder}
                                selectedOrderId={selectedOrder?._id}
                                activeTab={baseTab}
                            />
                        </View>
                        {selectedOrder && (
                            <View style={{ flex: 2 }}>
                                <OrderDetail
                                    order={selectedOrder}
                                    currentCompany={currentCompany ?? new Company({})}
                                    onOrderUpdated={handleOrderUpdated}
                                    onBack={() => setSelectedOrder(null)}
                                    activeTab={baseTab}
                                />
                            </View>
                        )}
                    </View>
                );
            case 'sales':
                return <ManageSales />;
            case 'settings':
                return <PartsNetworkSettings company={currentCompany} />;
            default:
                return null;
        }
    }, [activeTab, selectedOrder, orders, isLoading, currentCompany, selectedVendor, cart]);

    const renderTabs = () => {
        const tabs: MenuTab[] = [
            { key: 'vendors', title: tabnames.vendors },
            {
                key: 'clientOrders',
                title: 'Incoming Orders',
                subTabs: [
                    { key: 'clientOrders_current', title: 'Current Orders' },
                    { key: 'clientOrders_history', title: 'Order History' }
                ]
            },
            { key: 'sales', title: tabnames.sales },
        ];

        if (currentCompany?.settings.payment_settings.stripe.stripe_connect_account_id) {
            tabs.splice(2, 0, {
                key: 'vendorOrders',
                title: 'Outgoing Orders',
                subTabs: [
                    { key: 'vendorOrders_current', title: 'Current Orders' },
                    { key: 'vendorOrders_history', title: 'Order History' }
                ]
            });
        }

        return tabs.map((tab) => (
            <View key={tab.key} style={styles.tabGroup}>
                <Button
                    style={[styles.tabButton, !tab.subTabs && { marginLeft: 0 }]}
                    appearance={activeTab.startsWith(tab.key) ? 'filled' : 'outline'}
                    onPress={() => {
                        if (!tab.subTabs) {
                            handleTabChange(tab.key as TabType);
                        } else {
                            // If it has subTabs, navigate to the "current" version
                            const currentTab = `${tab.key}_current` as TabType;
                            handleTabChange(currentTab);
                        }
                    }}
                >
                    {tab.title}
                </Button>
                {tab.subTabs && (
                    <View style={styles.subTabContainer}>
                        {tab.subTabs.map((subTab) => (
                            <Button
                                key={subTab.key}
                                size="small"
                                style={styles.subTabButton}
                                appearance={activeTab === subTab.key ? 'filled' : 'outline'}
                                status={activeTab === subTab.key ? 'success' : 'basic'}
                                onPress={() => handleTabChange(subTab.key as TabType)}
                            >
                                {subTab.title}
                            </Button>
                        ))}
                    </View>
                )}
            </View>
        ));
    };

    const handleCheckoutComplete = (order: Order) => {
        setCart([]);
        showNotification("Order Created", "Order created successfully", () => {
            setActiveTab('clientOrders_current');
        })
    };

    const memoizedCart = useMemo(() => {

        return (
            <Animated.View style={[
                styles.cartContainer,
                {
                    transform: [{ translateX: slideAnim }],
                    height: '100%'
                },
            ]}>
                <Cart
                    items={cart}
                    updateQuantity={updateCartItemQuantity}
                    removeItem={removeFromCart}
                    onClose={toggleCart}
                    currentCompany={currentCompany ?? new Company({})}
                    onCheckoutComplete={handleCheckoutComplete}
                />
            </Animated.View>
        )
    }, [cart, slideAnim, toggleCart]);

    return (
        <View style={{ flex: 1 }}>
            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 5, alignItems: 'center' }}>
                <Text category='h6'>Parts Network</Text>
                {renderSettingsButton()}
            </Layout>
            <Layout style={styles.container}>
                <View style={styles.tabContainer}>
                    {renderTabs()}
                </View>
                <View style={styles.content}>
                    {renderContent}
                </View>
            </Layout>
            {memoizedCart}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    tabContainer: {
        width: 200,
        padding: 16,
        borderRightWidth: 1,
        borderRightColor: '#ccc',
    },
    tabButton: {
        marginBottom: 4,
    },
    content: {
        flex: 1,
    },
    cartContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: 400,
        backgroundColor: 'white',
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: -2, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    cartButtonContainer: {
        position: 'relative',
    },
    cartBadge: {
        position: 'absolute',
        top: -5,
        right: -5,
        backgroundColor: 'red',
        borderRadius: 10,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    cartBadgeText: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
    },
    tabGroup: {
        marginBottom: 8,
    },
    subTabContainer: {
        marginLeft: 20,
        marginTop: 4,
    },
    subTabButton: {
        marginTop: 4,
        marginBottom: 4,
    },
});
