var config = require('../config/config.js')
global.Buffer = global.Buffer || require('buffer').Buffer
const axios = require('axios');
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon } from '@react-google-maps/api';
import * as StorageController from './storageController'
import { JobAddress, JobDirections, JobLocation } from '../models/Job.model';
import { Route, IGoogleOptimizeRouteRequest, IGoogleRouteLeg, RouteLeg } from '../models/Route.model';
import polyline from '@mapbox/polyline';
import OSRM from 'osrm';
var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}
export const apiKey = 'AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM';

export const API_KEY = "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM"



export interface IWaypoint {
    via?: boolean;
    vehicleStopover?: boolean;
    placeId?: string;
    address?: string;
    location: {
        latLng: {
            latitude: number;
            longitude: number;
        }
    }
}

//Get predictions
export async function getpredictions(search_term: string) {
    try {
        // console.log(search_term)
        const response = await axios.post(getApi() + `/googleplaceautocomplete`, { search_term: search_term });
        console.log(response);
        return response.data
    } catch (error) {
        console.log(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//Get predictions
export async function getpredictionsAutocomplete(search_term: string): Promise<google.maps.places.AutocompleteResponse | undefined> {
    try {
        // console.log(search_term)
        const response = await axios.post(getApi() + `/googleplaceautocomplete`, { search_term: search_term });
        //console.log(response);
        console.log("🚀============== ~ file: google.controller.ts:39 ~ getpredictionsAutocomplete ~ response🚀==============", response.data)
        return response.data
    } catch (error) {
        console.log(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//Get prediction info
export async function getPredictions(place_id: string) {
    try {
        // console.log(place_id)
        const response = await axios.get(getApi() + `/googleplacesresult/` + place_id);
        //console.log(response);
        return response.data
    } catch (error) {
        console.log(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

export async function getPredictionsPlace(place_id: string) {
    try {
        // console.log(place_id)
        const response = await axios.get(getApi() + `/googleplacesresult/` + place_id);
        //console.log(response);
        return response.data
    } catch (error) {
        console.log(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}




export async function getRouteDirections(origin: IWaypoint, destination: IWaypoint, intermediates: IWaypoint[]) {
    try {
        const data = {
            origin: origin,
            destination: destination,
            intermediates: intermediates
        }
        const result = await axios.post(getApi() + `/google/routes`, data);
        return result.data
    } catch (error) {
        console.log(error);
    }
}


// export async function getOptimizeRoute(request: IGoogleOptimizeRouteRequest) {
//     try {
//         const result = await axios.post(getApi() + `/google/routes/optimise`, request);
//         return result.data
//     } catch (error) {
//         console.log(error);
//     }
// }

export function addressBuilder(addressObj: JobAddress) {
    var address = ""
    if (!addressObj) {
        return ""
    }
    // address is a google places api result
    // need street number/name, usburb, city, state, country, post code
    try {
        // console.log(addressObj)
        addressObj.unit_number ? address += addressObj.unit_number + "/" : null
        addressObj.street_number ? address += addressObj.street_number + " " : null
        addressObj.street_name ? address += addressObj.street_name + ", " : null
        addressObj.suburb ? address += addressObj.suburb + ", " : null
        addressObj.city ? address += addressObj.city + ", " : null
        addressObj.state ? address += addressObj.state + ", " : null
        addressObj.country ? address += addressObj.country + ", " : null
        addressObj.post_code ? address += addressObj.post_code : null
        return address
    } catch (error) {
        console.log(error)
        return ""
    }
}







export async function uploadGoogleImages(lat: any, lon: any) {
    let imageArr = []
    var imageSaveResponse
    try {
        const response = await axios.get(getApi() + `/google/places/` + lat + "/" + lon, {
            responseType: 'arraybuffer'
        }).then((response: any) => Buffer.from(response.data, 'binary').toString('base64'));
        var res = "data:image/png;base64," + response
        // console.log(response)
        // res = response
        // console.log(res)

        let b64Data = res.split(';base64,').pop();
        let imageType = res.split('data:')[1].split(';')[0].split('/')[1];

        var requestObj = {
            data: res,
            contentType: `data:image/${imageType}`,
        }
        imageSaveResponse = requestObj
        // console.log(imageSaveResponse)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
    return imageSaveResponse
}

/**
 * 
 * @param {*} origin 
 * @param {*} destination 
 * @returns {JobDirections}
 */
export async function getDirections(origin: JobLocation, destination: JobLocation) {
    try {
        origin = new google.maps.LatLng(origin.lat, origin.lng) as any;
        destination = new google.maps.LatLng(destination.lat, destination.lng) as any;
        // let service = new google.maps.DistanceMatrixService();
        const service = new google.maps.DirectionsService();
        const request = {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC
        };
        const response = await service.route(request) as any;
        if (response.status === 'OK') {
            const directions = new JobDirections({
                distance_text: response.routes[0].legs[0].distance.text,
                distance_value: response.routes[0].legs[0].distance.value,
                duration_text: response.routes[0].legs[0].duration.text,
                duration_value: response.routes[0].legs[0].duration.value,
                polyline: response.routes[0].overview_polyline,
            })
            return directions
        }
        return null
    } catch (error) {
        console.log(error);
        return null
    }
}


export async function getAddressFromLatLngAllResultsAsDMAddress(lat: number, lng: number) {
    try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`
        const response = await fetch(url)
        const data = await response.json()
        let results = [] as JobAddress[]
        if (data.status === 'OK') {
            // console.log("🚀============== ~ file: google.controller:262 ~ getAddressFromLatLng ~ data.results🚀==============", data.results)
            data.results.forEach((result: any) => {
                const sortedAddress = sortAddress(result.address_components)
                let address = new JobAddress({
                    ...sortedAddress,
                    description: result.geometry.location_type || "",
                    formatted_address: result.formatted_address,
                    location: new JobLocation({
                        lat: result.geometry.location.lat,
                        lng: result.geometry.location.lng
                    })
                })
                results.push(address)
            })
            return results
        } else {
            console.log("error")
        }
    } catch (error) {
        console.log(error)
        return null
    }
}

// get address from lat lng, export all results in an array
export async function getAddressFromLatLngAllResults(lat: number, lng: number) {
    try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`
        const response = await fetch(url)
        const data = await response.json()
        if (data.status === 'OK') {
            // console.log("🚀============== ~ file: google.controller:262 ~ getAddressFromLatLng ~ data.results🚀==============", data.results)
            return data.results
        } else {
            console.log("error")
        }
    } catch (error) {
        console.log(error)
        return null
    }
}

/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// UTILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/




export async function getAddressFromLatLng(lat: number, lng: number) {
    try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${API_KEY}`
        const response = await fetch(url)
        const data = await response.json()
        if (data.status === 'OK') {
            // console.log("🚀============== ~ file: google.controller:262 ~ getAddressFromLatLng ~ data.results🚀==============", data.results)
            const address = data.results[0].address_components
            let addressObject = await sortAddress(address)
            addressObject.formatted_address = data.results[0].formatted_address
            addressObject.location = new JobLocation({
                lat: lat,
                lng: lng
            })
            const formattedAddress = data.results[0].formatted_address
            return { address: addressObject, formattedAddress: formattedAddress }
        } else {
            console.log("error")
        }
    } catch (error) {
        console.log(error)
        return null
    }
}


//Sort Address
export function sortAddress(address_components: any) {
    var address = {} as any
    for (let component of address_components) {
        // console.log(component)
        if (component.types.includes("subpremise")) {
            address.unit_number = component.long_name
        }
        if (component.types.includes("street_number")) {
            address.street_number = component.long_name
        }
        if (component.types.includes("route")) {
            address.street_name = component.long_name
        }
        if (component.types.includes("locality")) {
            address.suburb = component.long_name
        }
        if (component.types.includes("administrative_area_level_2")) {
            address.city = component.short_name
        }
        if (component.types.includes("administrative_area_level_1")) {
            address.state = component.long_name
        }
        if (component.types.includes("country")) {
            address.country = component.long_name
        }
        if (component.types.includes("postal_code")) {
            address.post_code = component.long_name
        }
    }
    return address
}

export function decodePolyline(encoded: any) {
    if (!encoded) {
        return [];
    }
    let points = [];
    let index = 0, len = encoded.length;
    let lat = 0, lng = 0;

    while (index < len) {
        let b, shift = 0, result = 0;
        do {
            b = encoded.charCodeAt(index++) - 63;
            result |= (b & 0x1f) << shift;
            shift += 5;
        } while (b >= 0x20);

        let dlat = ((result & 1) ? ~(result >> 1) : (result >> 1));
        lat += dlat;

        shift = 0;
        result = 0;
        do {
            b = encoded.charCodeAt(index++) - 63;
            result |= (b & 0x1f) << shift;
            shift += 5;
        } while (b >= 0x20);

        let dlng = ((result & 1) ? ~(result >> 1) : (result >> 1));
        lng += dlng;

        points.push({ lat: lat / 1E5, lng: lng / 1E5 });
    }

    return points;
}


function encodeNumber(num: any) {
    var encodeString = "";
    while (num >= 0x20) {
        encodeString += (String.fromCharCode((0x20 | (num & 0x1f)) + 63));
        num >>= 5;
    }
    encodeString += (String.fromCharCode(num + 63));
    return encodeString;
}
function encodeSignedNumber(num: any) {
    var sgn_num = num << 1;
    if (num < 0) {
        sgn_num = ~(sgn_num);
    }
    return (encodeNumber(sgn_num));
}
export function encodePolyline(points: any) {
    var plat = 0;
    var plng = 0;
    var encoded_points = "";
    for (var i = 0; i < points.length; i++) {
        var lat = Math.round(points[i].lat * 1e5);
        var lng = Math.round(points[i].lng * 1e5);
        var dlat = lat - plat;
        var dlng = lng - plng;
        plat = lat;
        plng = lng;
        encoded_points += encodeSignedNumber(dlat) + encodeSignedNumber(dlng);
    }
    return encoded_points;
}
