import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { Card, Text, Button, Icon, ListItem } from '@ui-kitten/components';
import { Job } from '../../models/Job.model';

interface PropertyPathBuilderProps {
    onSelectPath: (path: string) => void;
    onClose?: () => void;
    style?: any;
}

interface PropertyNode {
    key: string;
    value: any;
    path: string;
    isExpanded?: boolean;
}

export const PropertyPathBuilder = ({ onSelectPath, onClose, style }: PropertyPathBuilderProps) => {
    const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());

    // Create a sample job to inspect its structure
    const sampleJob = new Job({});
    
    const isObject = (value: any): boolean => {
        return value !== null && typeof value === 'object' && !Array.isArray(value);
    };

    const toggleNode = (path: string) => {
        const newExpanded = new Set(expandedNodes);
        if (newExpanded.has(path)) {
            newExpanded.delete(path);
        } else {
            newExpanded.add(path);
        }
        setExpandedNodes(newExpanded);
    };

    const renderPropertyNode = (node: PropertyNode) => {
        const isExpandable = isObject(node.value);
        const isExpanded = expandedNodes.has(node.path);
        
        const renderAccessoryRight = () => (
            <View style={{ flexDirection: 'row', gap: 5 }}>
                <Button
                    size='tiny'
                    status='info'
                    appearance='ghost'
                    onPress={() => onSelectPath(node.path)}
                    accessoryLeft={(props: any) => <Icon {...props} name='checkmark-outline'/>}
                />
                {isExpandable && (
                    <Button
                        size='tiny'
                        appearance='ghost'
                        onPress={() => toggleNode(node.path)}
                        accessoryLeft={(props: any) => (
                            <Icon {...props} name={isExpanded ? 'chevron-down' : 'chevron-right'} />
                        )}
                    />
                )}
            </View>
        );

        return (
            <View key={node.path}>
                <ListItem
                    title={node.key}
                    accessoryRight={renderAccessoryRight}
                    onPress={() => onSelectPath(node.path)}
                />
                {isExpanded && isExpandable && (
                    <View style={{ marginLeft: 20 }}>
                        {Object.entries(node.value).map(([key, value]) => (
                            renderPropertyNode({
                                key,
                                value,
                                path: `${node.path}.${key}`
                            })
                        ))}
                    </View>
                )}
            </View>
        );
    };

    const baseModels = {
        'job': sampleJob,
        // Add other base models as needed
    };

    return (
        <Card style={style}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                <Text category='h6'>Select Property Path</Text>
                {onClose && (
                    <Button
                        size='small'
                        status='basic'
                        appearance='ghost'
                        onPress={onClose}
                        accessoryLeft={(props: any) => <Icon {...props} name='close'/>}
                    />
                )}
            </View>
            <ScrollView style={{ maxHeight: 400 }}>
                {Object.entries(baseModels).map(([key, value]) => 
                    renderPropertyNode({
                        key,
                        value,
                        path: key
                    })
                )}
            </ScrollView>
        </Card>
    );
};
