import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { StyleSheet, View, ScrollView, ImageProps as RNImageProps, useWindowDimensions, LayoutChangeEvent } from 'react-native';
import { Layout, List, ListItem, Text, Button, Icon, Autocomplete, AutocompleteItem, Input, useTheme } from '@ui-kitten/components';
import * as InventoryController from '../../functions/Inventory.controller';
import { Company } from '../../models/Company.model';
import { InventoryCategory, InventoryItem, InventoryStock } from '../../models/Inventory.model';
import PartsNetworkItemDetail from './PartsNetworkItemDetail';
import { CartItem } from './PartsNetworkContainerScreen';
import * as ClientController from '../../functions/client.controller';
import { Client } from '../../models/Client.model';
import { LoadingSpinner } from '../common/LoadingSpinner';

interface VendorStoreProps {
    vendor: Company;
    onBack: () => void;
    addToCart: (item: InventoryItem, quantity: number, isGSTInclusive: boolean) => void;
    cart: CartItem[];
    clientCompany: Company;
    selectedClientProfile?: Client | null;
}

export const VendorStore: React.FC<VendorStoreProps> = ({
    vendor,
    onBack,
    addToCart,
    cart,
    clientCompany,
    selectedClientProfile
}) => {
    const [categories, setCategories] = useState<InventoryCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<InventoryCategory | null>(null);
    const [items, setItems] = useState<InventoryItem[]>([]);
    const [filteredItems, setFilteredItems] = useState<InventoryItem[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [ownClientProfile, setOwnClientProfile] = useState<Client | null>(selectedClientProfile || null);
    const [itemStockLevels, setItemStockLevels] = useState<{ [key: string]: number }>({});
    const [sortType, setSortType] = useState<'stock' | 'name' | 'price'>('name');
    const [sortAscending, setSortAscending] = useState(true);
    const [inStockOnly, setInStockOnly] = useState(true);
    const theme = useTheme();
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log("🚀============== ~ file: VendorStore.tsx:36 ~ useEffect ~ vendor🚀==============", vendor)
        console.time('fetchCategories and fetchClientProfile');
        fetchCategories();
        fetchClientProfile();
        console.timeEnd('fetchCategories and fetchClientProfile');
    }, [vendor, clientCompany, selectedClientProfile]);

    useEffect(() => {
        console.time('setFilteredItems');
        setFilteredItems(items);
        console.timeEnd('setFilteredItems');
    }, [items]);


    const fetchCategories = async () => {
        console.time('fetchCategories');
        const vendorCategories = await InventoryController.getInventoryCategoriesByCompanyId(vendor._id);

        console.log("🚀============== ~ file: VendorStore.tsx:46 ~ fetchCategories ~ vendorCategories🚀==============", vendorCategories)
        if (vendorCategories) {
            //categories that arent deleted
            const filteredCategories = vendorCategories.filter(category => !category.deleted);
            setCategories(filteredCategories);
        }
        console.timeEnd('fetchCategories');
    };

    const fetchClientProfile = async () => {
        console.time('fetchClientProfile');
        if (selectedClientProfile) {
            setOwnClientProfile(selectedClientProfile);
        } else {
            console.log("🚀============== ~ file: VendorStore.tsx:55 ~ fetchClientProfile ~ vendor._id, clientCompany._id🚀==============", vendor._id, clientCompany._id)
            const clientProfile = await ClientController.getClientByCompanyAndClientLinkingId(vendor._id, clientCompany._id);
            console.log("🚀============== ~ file: VendorStore.tsx:56 ~ fetchClientProfile ~ clientProfile🚀==============", clientProfile)
            if (clientProfile) {
                console.log("🚀============== ~ file: VendorStore.tsx:54 ~ fetchClientProfile ~ clientProfile🚀==============", clientProfile)
                setOwnClientProfile(clientProfile);
            }
        }
        console.timeEnd('fetchClientProfile');
    };



    const fetchItems = async (category: InventoryCategory) => {
        console.time('fetchItems');
        setIsLoading(true);
        const categoryId = category._id;
        let newItems: InventoryItem[] = [];
        let newStockLevels: { [key: string]: number } = {};

        if (categoryId) {
            try {
                console.time('fetchItems-network');
                // Load items and stock in parallel
                const [categoryItems, stock] = await Promise.all([
                    InventoryController.getInventoryItemsByCategory(categoryId),
                    InventoryController.getInventoryStockByCategoryId(categoryId)
                ]);
                console.timeEnd('fetchItems-network');
                console.log("Items ", categoryItems?.length, "Stock ", stock?.length);

                if (categoryItems) {
                    newItems = categoryItems;
                }

                console.time('fetchItems-stockProcessing');
                // Process stock levels - optimized version
                if (stock && Array.isArray(stock)) {
                    newStockLevels = stock.reduce((acc: { [key: string]: number }, stockItem) => {
                        const itemId = typeof stockItem.inventory_item_id === 'string'
                            ? stockItem.inventory_item_id
                            : (stockItem.inventory_item_id as InventoryItem)._id;

                        if (itemId) {
                            acc[itemId] = (acc[itemId] || 0) + (stockItem.quantity || 0);
                        }
                        return acc;
                    }, {});
                }
                console.timeEnd('fetchItems-stockProcessing');

            } catch (error) {
                console.error('Error loading category data:', error);
                setIsLoading(false);
            }
            setIsLoading(false);
        }

        // Batch all state updates together at the end
        console.time('fetchItems-stateUpdates');
        setItems(newItems);
        setFilteredItems(newItems);
        setItemStockLevels(newStockLevels);
        setSelectedCategory(category);
        setSearchQuery('');
        console.timeEnd('fetchItems-stateUpdates');

        console.timeEnd('fetchItems');
    };

    const cartQuantity = useCallback((itemId: string | null | undefined): number => {
        // console.time('cartQuantity');
        if (!itemId) return 0;
        const itemCount = cart.filter(cartItem => cartItem._id === itemId).reduce((acc, cartItem) => acc + cartItem.quantity, 0);
        return itemCount;
    }, [cart]);

    const handleAddToCart = (item: InventoryItem, quantity: number, price: number, isGSTInclusive: boolean) => {
        console.time('handleAddToCart');
        const updatedItem = new InventoryItem({ ...item, default_price: price });
        console.log("🚀============== ~ file: VendorStore.tsx:87 ~ handleAddToCart ~ updatedItem🚀==============", updatedItem, isGSTInclusive)
        addToCart(updatedItem, quantity, isGSTInclusive);
        console.timeEnd('handleAddToCart');
    };



    const filteredItemsList = useMemo(() =>
        items.filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        ), [items, searchQuery]);

    const handleSort = (type: 'stock' | 'name' | 'price') => {
        console.time('handleSort');
        if (type === sortType) {
            setSortAscending(!sortAscending);
        } else {
            setSortType(type);
            setSortAscending(true);
        }
        console.timeEnd('handleSort');
    };

    const sortedItems = useMemo(() => {
        console.time('getSortedItems');
        let sorted = [...filteredItemsList];

        // First apply in stock filter if enabled
        if (inStockOnly) {
            sorted = sorted.filter(item => {
                const itemId = item._id;
                return itemId ? (itemStockLevels[itemId] || 0) > 0 : false;
            });
        }

        switch (sortType) {
            case 'stock':
                sorted.sort((a, b) => {
                    const aId = a._id;
                    const bId = b._id;
                    const stockA = aId ? (itemStockLevels[aId] || 0) : 0;
                    const stockB = bId ? (itemStockLevels[bId] || 0) : 0;
                    return sortAscending ? stockA - stockB : stockB - stockA;
                });
                break;
            case 'name':
                sorted.sort((a, b) => {
                    return sortAscending
                        ? a.name.localeCompare(b.name)
                        : b.name.localeCompare(a.name);
                });
                break;
            case 'price':
                sorted.sort((a, b) => {
                    return sortAscending
                        ? a.default_price - b.default_price
                        : b.default_price - a.default_price;
                });
                break;
        }
        console.timeEnd('getSortedItems');
        return sorted;
    }, [filteredItemsList, sortType, sortAscending, itemStockLevels, inStockOnly]);

    const renderSortIcon = (isActive: boolean) => {
        if (!isActive) return undefined;
        return (props?: Partial<RNImageProps>) => (
            <Icon {...props} name={sortAscending ? 'arrow-up-outline' : 'arrow-down-outline'} />
        );
    };

    return (
        <Layout style={[styles.container, { maxHeight: windowHeight * 0.8 }]}>
            {isLoading && <LoadingSpinner />}
            <View style={styles.header}>
                <Button
                    appearance="ghost"
                    status="basic"
                    accessoryLeft={(props) => <Icon {...props} name="arrow-back" />}
                    onPress={onBack}
                >
                    Back to Vendors
                </Button>
                <Text category="h6" style={styles.title}>SELECTED VENDOR: {vendor.name}</Text>
            </View>
            <View style={styles.contentContainer}>
                <View style={styles.categoriesContainer}>
                    <Text category="h6">Vendor Categories</Text>
                    <List
                        ItemSeparatorComponent={() => <View style={{ height: 1, backgroundColor: '#ccc' }} />}
                        data={categories}
                        renderItem={({ item }) => (
                            <ListItem
                                style={{
                                    backgroundColor: selectedCategory?._id === item._id ? theme['color-success-900'] : 'transparent'
                                }}
                                title={item.name}
                                description={item.description || 'No description'}
                                onPress={() => fetchItems(item)}
                            />
                        )}
                    />
                </View>
                <View style={styles.itemsContainer}>
                    {selectedCategory && (
                        <View style={{}}
                        >
                            <View style={styles.searchAndSortContainer}>
                                <View style={styles.searchContainer}>
                                    <Input
                                        size='small'
                                        placeholder='Search items'
                                        value={searchQuery}
                                        onChangeText={setSearchQuery}
                                        style={styles.searchInput}
                                        accessoryLeft={(props) => (
                                            <Icon
                                                {...props}
                                                name={'search-outline'}
                                            />
                                        )}
                                    />
                                    <Button
                                        appearance="ghost"
                                        status={searchQuery && searchQuery.length > 0 ? 'danger' : 'basic'}
                                        accessoryLeft={(props) => <Icon {...props} name="close" />}
                                        onPress={() => setSearchQuery('')}
                                    />
                                </View>
                                <View style={styles.sortButtonsContainer}>
                                    <Button
                                        size='tiny'
                                        appearance={inStockOnly ? 'filled' : 'outline'}
                                        status={inStockOnly ? 'success' : 'basic'}
                                        onPress={() => setInStockOnly(!inStockOnly)}
                                    >
                                        In Stock Only
                                    </Button>
                                    <Button
                                        size='tiny'
                                        appearance={sortType === 'stock' ? 'filled' : 'outline'}
                                        onPress={() => handleSort('stock')}
                                        accessoryRight={renderSortIcon(sortType === 'stock')}
                                    >
                                        Stock
                                    </Button>
                                    <Button
                                        size='tiny'
                                        appearance={sortType === 'name' ? 'filled' : 'outline'}
                                        onPress={() => handleSort('name')}
                                        accessoryRight={renderSortIcon(sortType === 'name')}
                                    >
                                        Name
                                    </Button>
                                    <Button
                                        size='tiny'
                                        appearance={sortType === 'price' ? 'filled' : 'outline'}
                                        onPress={() => handleSort('price')}
                                        accessoryRight={renderSortIcon(sortType === 'price')}
                                    >
                                        Price
                                    </Button>
                                </View>
                            </View>
                            <List
                                // style={{ height: containerHeight - 50 }}
                                data={sortedItems}
                                renderItem={({ item }) => {
                                    return <RenderItemListItem
                                        item={item}
                                        handleAddToCart={handleAddToCart}
                                        cartQuantity={cartQuantity}
                                        clientCompany={clientCompany}
                                        vendor={vendor}
                                        ownClientProfile={ownClientProfile}
                                        selectedCategory={selectedCategory}
                                        itemStockLevels={itemStockLevels}
                                    />
                                }}
                            />
                        </View>
                    )}
                </View>
            </View>
        </Layout>
    );
}

interface RenderItemListItemProps {
    item: InventoryItem;
    handleAddToCart: (item: InventoryItem, quantity: number, price: number, isGSTInclusive: boolean) => void;
    cartQuantity: (itemId: string | null | undefined) => number;
    clientCompany: Company;
    vendor: Company;
    ownClientProfile: Client | null;
    selectedCategory: InventoryCategory | null;
    itemStockLevels: { [key: string]: number };
}

const RenderItemListItem = ({
    item,
    handleAddToCart,
    cartQuantity,
    clientCompany,
    vendor,
    ownClientProfile,
    selectedCategory,
    itemStockLevels
}: RenderItemListItemProps) => {
    const itemId = item._id;
    if (!itemId) return null;

    return (
        <PartsNetworkItemDetail
            key={itemId}
            item={item}
            onAddToCart={handleAddToCart}
            cartQuantity={cartQuantity(itemId)}
            currentCompany={clientCompany}
            vendor={vendor}
            ownClientProfile={ownClientProfile}
            category={selectedCategory}
            stockLevel={itemStockLevels[itemId]}
        />
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
    },
    title: {
        flex: 1,
        textAlign: 'center',
    },
    contentContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    categoriesContainer: {
        flex: 1,
        marginRight: 16,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 8,
    },
    itemsContainer: {
        flex: 3,
        gap: 8
    },
    searchAndSortContainer: {
        flexDirection: 'row',
        gap: 8,
        marginBottom: 8,
    },
    sortButtonsContainer: {
        flexDirection: 'row',
        gap: 8,
        alignItems: 'center',
        flex: 1,
    },
    searchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    searchInput: {
        flex: 1,
    },
});
