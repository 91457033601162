import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline, OverlayView } from '@react-google-maps/api';
import * as UpdateController from '../../../functions/update.controller';
import * as UserController from '../../../functions/user.controller';
import * as StorageController from "../../../functions/storageController"
import * as MessageController from "../../../functions/message.controller"
import * as GoogleController from "../../../functions/google.controller"
import * as JobsController from '../../../functions/job.controller'
import ErrorBoundary from '../../ErrorBoundary.component';
//@ts-ignore
import _ from 'lodash'
//@ts-ignore
import debounce from 'lodash.debounce';
import Toast from 'react-native-toast-message';
// import { AddressSearch } from '../searchAddress.component';
import DistanceTool from './../distanceTool.component';
import { RoadsideJobInfo } from './../RoadsideJobInfo.component';
import { TowingJobInfo } from './../TowingJobInfo.component';
import { MemberDeviceInfoCard } from '../../members/memberDeviceInfoCard.component';
import { Job, JobTowingDetails, TowingLeg } from '../../../models/Job.model';
import { Member } from '../../../models/Member.model';
import { Company } from '../../../models/Company.model'
import { darkMapStyles, lightMapStyles } from './../mapStyles';

export const RenderDriverMarker = ({ driver, onDriverMarkerClick, icon }: { driver: Member, onDriverMarkerClick: any, icon: any }) => {
    // const initials = driver.user?.name.split(" ").map((n: string) => n[0]).join("")
    const initials = driver?.name.split(" ").map((n: string) => n[0]).join("")
    const capitalizedInitials = initials.toUpperCase()
    if (isNaN(driver?.details?.location?.coords?.latitude) || driver.details?.location?.coords == undefined) {
        return null
    }
    return (
        <React.Fragment key={driver._id}>
            {driver.online &&
                // !hideDriverIconTimeout(driver) &&
                <Marker
                    key={driver._id}
                    //@ts-ignore
                    icon={icon}
                    // icon={icon}
                    label={{
                        text: capitalizedInitials,
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "bold",
                        fontFamily: "Arial",
                    }}
                    position={{ lat: driver.details?.location?.coords.latitude, lng: driver.details?.location?.coords.longitude }}
                    onClick={() => {
                        // ////console.log("==== Marker Clicked ==== info window", driver, showDriverInfoWindow)
                        onDriverMarkerClick(driver)
                    }}
                />
            }
        </React.Fragment>
    )
}

export const RenderDriverMarkers = ({ drivers, onDriverMarkerClick, assignedDriverIds, onRouteDriverIds }: { drivers: Member[], onDriverMarkerClick: any, assignedDriverIds: string[], onRouteDriverIds: string[] }) => {
    if (drivers.length === 0) return null;
    return (
        <>
            {drivers?.map((driver: Member) => {
                if (!driver) return null;
                const icon = renderDriverIcon(driver, assignedDriverIds, onRouteDriverIds);
                return <RenderDriverMarker key={driver?._id} driver={driver} onDriverMarkerClick={onDriverMarkerClick} icon={icon} />;
            })}
        </>
    );
}


function renderDriverIcon(driver: Member, assignedList: string[] = [], onRouteList: string[] = []) {
    if (!driver) return null
    const star2 = `<svg fill="#ff9321" height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-5.17 -5.17 57.37 57.37" xml:space="preserve" stroke="#ffff00" stroke-width="0.00047032000000000004" transform="rotate(0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#000000" stroke-width="1.693152"> <g> <path d="M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759 c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713 v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336 h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z"></path> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g><g id="SVGRepo_iconCarrier"> <g> <path d="M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759 c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713 v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336 h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z"></path> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>`
    const url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(star2);
    let heading = 0
    // converted by gpt
    const star2Path = `M29.395,0H17.636c-3.117,0-5.643,3.467-5.643,6.584v34.804c0,3.116,2.526,5.644,5.643,5.644h11.759c3.116,0,5.644-2.527,5.644-5.644V6.584C35.037,3.467,32.511,0,29.395,0z M34.05,14.188v11.665l-2.729,0.351v-4.806L34.05,14.188z M32.618,10.773c-1.016,3.9-2.219,8.51-2.219,8.51H16.631l-2.222-8.51C14.41,10.773,23.293,7.755,32.618,10.773z M15.741,21.713v4.492l-2.73-0.349V14.502L15.741,21.713z M13.011,37.938V27.579l2.73,0.343v8.196L13.011,37.938z M14.568,40.882l2.218-3.336h13.771l2.219,3.336H14.568z M31.321,35.805v-7.872l2.729-0.355v10.048L31.321,35.805z`;
    try {
        if (driver.details.location.coords.heading) {
            heading = driver.details.location.coords.heading
            heading = parseInt(heading as any)
        }
    } catch (e) {
        ////console.log(e)
    }
    const assignedIcon = {
        path: star2Path,
        fillColor: "#A25917",
        fillOpacity: 0.9,
        strokeColor: "black",
        strokeWeight: 1.5,
        strokeOpacity: 1,
        // url: url,
        labelOrigin: new google.maps.Point(25, 25),
        rotation: heading,
        scaledSize: new google.maps.Size(50, 50), // Adjust the size as needed
        anchor: new google.maps.Point(25, 25),
    };

    const offlineIcon = {
        path: "M 0 0 L 0 0",
        fillColor: "transparent",
        fillOpacity: 0,
        strokeColor: "transparent",
        strokeWeight: 0,
    };

    const unassignedIcon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 15,
        fillColor: getColorFromId(driver.user?._id as string),
        fillOpacity: 0.85,
        strokeColor: getDriverIconColor(driver),
        strokeWeight: 4,
    };

    if (!driver.online) {
        return offlineIcon
    }

    if (driver.online && hideDriverIconTimeout(driver)) {
        return offlineIcon
    }

    if (onRouteList.includes(driver._id)) {
        return assignedIcon
    }

    if (assignedList.includes(driver._id)) {
        return assignedIcon
    }

    return unassignedIcon
};



const hideDriverIconTimeout = (driver: Member) => {
    const now = new Date();
    const lastUpdated = new Date(driver.details?.location?.timestamp);
    const day = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const h = 3600 * 1000; // 1 hour in milliseconds
    const m = 60 * 1000; // 1 minute in milliseconds
    const s = 1000; // 1 second in milliseconds

    //@ts-ignore
    const diff = now - lastUpdated;

    if (diff > h * 12) {
        return true;
    } else {
        return false;
    }
}

const isDriverOnlineTimeout = (driver: Member) => {
    const now = new Date();
    const lastUpdated = new Date(driver.details?.location?.timestamp);
    const day = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const h = 3600 * 1000; // 1 hour in milliseconds
    const m = 60 * 1000; // 1 minute in milliseconds
    const s = 1000; // 1 second in milliseconds

    //@ts-ignore
    const diff = now - lastUpdated;

    if (diff >= h * 2) {
        return false;
    } else {
        return true;
    }
};

const isDriverAwayTimeout = (driver: Member) => {
    const now = new Date();
    const lastUpdated = new Date(driver.details?.location?.timestamp);
    const day = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const h = 3600 * 1000; // 1 hour in milliseconds
    const m = 60 * 1000; // 1 minute in milliseconds
    const s = 1000; // 1 second in milliseconds

    //@ts-ignore
    const diff = now - lastUpdated;

    if (diff > (h * 2) && diff <= (h * 12)) {
        return true;
    } else {
        return false;
    }
};


// driver icon colour based on last online time
const getDriverIconColor = (driver: Member) => {
    if (isDriverOnlineTimeout(driver)) {
        return "#00FF00"; // Green color as a hexadecimal string
    } else if (isDriverAwayTimeout(driver)) {
        return "#FFA500"; // Orange color as a hexadecimal string
    } else {
        return "#FF0000"; // Red color as a hexadecimal string
    }
};


function getColorFromId(id: string, lightest = 50, darkest = 40) {
    if (!id) return "#4160aa"
    // Convert the name to a number using a simple hash function
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
        hash = id.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Generate a color based on the number
    const h = hash % 360;
    const s = 50;
    const l = lightest + (darkest - lightest) * (hash % 100) / 100;
    return `hsl(${h}, ${s}%, ${l}%)`;
}