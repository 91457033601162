import React, { useCallback, useEffect, useState } from 'react';
//@ts-ignore
import { View } from 'react-native';
import { Button, Layout, Input, Text, Card, Icon } from '@ui-kitten/components';
//@ts-ignore
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline, useJsApiLoader, Polygon, InfoWindow } from '@react-google-maps/api';
//@ts-ignore
import { SketchPicker, CirclePicker } from 'react-color'
import * as StorageController from '../../functions/storageController';


import { Company, ServiceArea, IServiceAreaCoord } from '../../models/Company.model';
import { fill } from 'lodash';
const mapLibs = ["places", "visualization"]


interface IServiceAreaSettingsProps {
  var_serviceArea: ServiceArea | null;
  style?: any;
  onAddNewServiceArea: () => void;
  onEditServiceArea: (index: number) => void;
  confirmDeleteServiceArea: (index: number) => void;
  company: Company;
}

export const ServiceAreaSettings = ({
  onAddNewServiceArea,
  onEditServiceArea,
  confirmDeleteServiceArea,
  company,
  style = {}
}: IServiceAreaSettingsProps) => {

  return (
    <Card
      disabled={true}
      style={[style, { flex: 1 }]}
      status='basic'
      header={() => <Text category='h6' > Service Area Settings </Text>}
    >
      <Layout style={{ flexDirection: "row" }}>
        <Layout style={{ flex: 1 }}>
          <Layout style={{}}>
            <Button onPress={() => onAddNewServiceArea()} status="success" > Add New Area </Button>
          </Layout>
          <Layout style={{}}>
            {
              company.settings?.service_areas?.map((serviceArea: ServiceArea, index) => (
                <Card key={index} disabled={true} >
                  <Layout style={{ flexDirection: 'row', justifyContent: 'space-between' }} >
                    <Text category='label' style={{ flex: 3 }} > {serviceArea?.name} </Text>
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                      <Button onPress={() => onEditServiceArea(index)} size='tiny' >
                        <Icon name='edit-2-outline' width={15} height={15} status="info" />
                      </Button>
                      <Button onPress={() => confirmDeleteServiceArea(index)} size='tiny' status="danger" >
                        <Icon name='trash-2-outline' width={15} height={15} />
                      </Button>
                    </View>
                  </Layout>
                </Card>
              ))}
          </Layout>
        </Layout>
      </Layout>
    </Card>
  )
}


interface ServiceAreaEditProps {
  fn_onSave: (serviceArea: ServiceArea) => void;
  fn_onCancel?: () => void;
  var_serviceArea: ServiceArea | null;
  style?: any;
}


export const ServiceAreaEdit = ({
  fn_onSave,
  fn_onCancel,
  var_serviceArea,
  style = {}
}: ServiceAreaEditProps) => {

  const [points, setPoints] = useState<IServiceAreaCoord[]>([]);
  const [map, setMap] = React.useState(null)
  const mapRef = React.useRef(null as any);
  const [center, setCenter] = useState({ lat: -27.4705, lng: 153.0260 });
  const [fillColor, setFillColor] = useState('#FF0000');
  const fillColorRef = React.useRef(fillColor);
  const [serviceAreaName, setServiceAreaName] = useState('Service Area');
  const centerRef = React.useRef(center);
  const [completeShape, setCompleteShape] = useState(true);
  const [otherServiceAreas, setOtherServiceAreas] = useState([] as ServiceArea[]);
  const [showOtherServiceAreas, setShowOtherServiceAreas] = useState(false);

  React.useEffect(() => {
    if (var_serviceArea != null && var_serviceArea.coords != null) {
      try {
        const p = var_serviceArea.coords.map((point) => {
          const lat = parseFloat(Number(point.lat).toFixed(3));
          const lng = parseFloat(Number(point.lng).toFixed(3));
          return { lat, lng };
        })

        setServiceAreaName(var_serviceArea.name)
        onSetFillColor(var_serviceArea.color)

        // console.log("🚀 ~ file: serviceAreaEdit.component.js:28 ~ React.useEffect ~ p:", p)
        setPoints(p)
      } catch (err) {
        // console.log("🚀 ~ file: serviceAreaEdit.component.js:35 ~ React.useEffect ~ err:", err)
        console.log(err)
      }
    } else {
      setCompleteShape(false)
    }

    return () => {

    }
  }, [var_serviceArea])

  useEffect(() => {
    const company = StorageController.getCurrentCompany()
    const serviceAreas = company?.settings?.service_areas || []
    setOtherServiceAreas(serviceAreas)
    if (company.settings?.location?.coords?.lat && company.settings?.location?.coords?.lng) {
      setCenter({ lat: company.settings.location.coords.lat, lng: company.settings.location.coords.lng })
    }
  }, [showOtherServiceAreas])

  const onSetFillColor = (color: string) => {
    fillColorRef.current = color;
    setFillColor(color)
  }



  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
    //@ts-ignore
    libraries: mapLibs  // Required for heatmap
  })
  const onLoad = React.useCallback(function callback(map: any) {
    // console.log("🚀 ~ file: serviceAreaEdit.component.js:19 ~ onLoad ~ map:", map)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const addPoint = (e: google.maps.MapMouseEvent) => {
    if (!e.latLng) return;
    setPoints([...points, { lat: e.latLng.lat(), lng: e.latLng.lng() }]);
    updateMapCenter();
  };

  const deleteLastPoint = () => {
    setPoints(points.slice(0, -1));
    updateMapCenter();
  };

  const deleteAllPoints = () => {
    setPoints([]);
    updateMapCenter();
  };

  const deletePoint = (selectedPointIndex: number) => {
    let newPoints = [...points];
    newPoints = newPoints.filter((point, index) => index !== selectedPointIndex);
    setPoints(newPoints);
  };

  // stops map from moving when adding points
  const handleCenterChanged = () => {
    if (map !== null) {
      const newCenter = mapRef.current.state.map.center
      centerRef.current = { lat: newCenter.lat, lng: newCenter.lng };
    }
  };

  const handleSave = useCallback(() => {
    const coords = points.map((point) => {
      const lat = parseFloat(Number(point.lat).toFixed(3));
      const lng = parseFloat(Number(point.lng).toFixed(3));
      return { lat, lng };
    })
    const serviceArea = new ServiceArea({
      coords: coords,
      name: serviceAreaName,
      type: "serviceArea",
      color: fillColor,
    })
    fn_onSave(serviceArea)
  }, [points, serviceAreaName, fillColor])

  const updateMapCenter = () => {
    setCenter(centerRef.current);
  };


  const onSetCompleteShape = () => {
    setCompleteShape(true);
  };

  const onEditShape = () => {
    setCompleteShape(false);
  };

  const onShowOtherServiceAreas = (value: boolean) => {
    setShowOtherServiceAreas(value);
  };

  const onDragPoint = (index: number, e: any) => {
    const newPoints = [...points];
    newPoints[index] = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    setPoints(newPoints);
  };


  const [infoWindowCoords, setInfoWindowCoords] = useState({ lat: 0, lng: 0 });
  const [showDeleteInfoWindow, setShowDeleteInfoWindow] = useState(false);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(0);

  const RenderDeleteInfoWindow = () => {
    return (
      <InfoWindow
        //@ts-ignore
        visible={showDeleteInfoWindow}
        position={infoWindowCoords}
        onCloseClick={() => setShowDeleteInfoWindow(false)}
      >
        <Card
          style={{ flexDirection: "column" }}
          status='danger'
          header={(props: any) => <Text {...props}>Delete Point</Text>}
        >
          <Layout style={{ flex: 1, flexDirection: "row", gap: 10 }}>
            <Button
              style={{ flex: 1 }}
              status='danger'
              appearance='outline'
              onPress={() => {
                deletePoint(selectedMarkerIndex)
                setShowDeleteInfoWindow(false)
              }}>Delete</Button>
            <Button
              style={{ flex: 1 }}
              status='warning'
              appearance='outline'
              onPress={() => setShowDeleteInfoWindow(false)}>Cancel</Button>
          </Layout>
        </Card>
      </InfoWindow>
    )
  }

  const onMarkerClick = (point: google.maps.MapMouseEvent, markerIndex: number) => {
    if (!point.latLng) return;
    const { lat, lng } = point.latLng.toJSON();
    setSelectedMarkerIndex(markerIndex);
    setInfoWindowCoords({ lat, lng });
    setShowDeleteInfoWindow(true);
  }



  const containerStyle = {
    width: '100%',
    height: '600px',
  };
  const dotIcon = {
    path: 'M0,-5a5,5 0 1,0 10,0a5,5 0 1,0 -10,0',
    fillColor: fillColor,
    fillOpacity: 1,
    scale: 1,
    strokeColor: '#000000',
    strokeWeight: 1,
  };

  return (
    <Card
      style={[style, { flexDirection: "column" }]}
      status='primary'
      disabled={true}
      header={() => {
        return (
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text category="s1">Service Area</Text>
            <View style={{ flexDirection: 'row' }}>
              {/* Show other service areas */}
              <Button
                status='info'
                appearance={showOtherServiceAreas ? 'filled' : 'outline'}
                onPress={() => onShowOtherServiceAreas(!showOtherServiceAreas)}>
                <>
                  {showOtherServiceAreas ? 'Hide' : 'Show'} Other Service Areas
                </>
              </Button>
            </View>
            <Button
              status='danger'
              appearance='outline'
              onPress={() => fn_onCancel ? fn_onCancel() : null}>
              <Icon name='close-outline' width={20} height={20} fill="white" />
            </Button>
          </View>
        )
      }}>
      <Layout style={{ flexDirection: "row" }}>
        <Layout style={{ flex: 5 }}>

          {isLoaded &&
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={containerStyle}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onCenterChanged={handleCenterChanged}
              zoom={8}
              onClick={addPoint}
              center={center}
              options={{
                disableDefaultUI: true,
                zoomControl: true,
                fullscreenControl: false,
                streetViewControl: false,
                // mapTypeControl: false,
                // scaleControl: false,
                rotateControl: false,
                scrollwheel: true,
                // draggable: allowEdit,
                clickableIcons: false,
                // gestureHandling: 'greedy'
              }}
            >
              {showDeleteInfoWindow &&
                <RenderDeleteInfoWindow />
              }
              {showOtherServiceAreas && otherServiceAreas &&
                otherServiceAreas.map((serviceArea, index) => {
                  const coords = serviceArea.coords.map((point) => {
                    return { lat: point.lat, lng: point.lng };
                  })
                  return (
                    <Polygon
                      key={index}
                      path={coords}
                      options={{
                        clickable: false,
                        fillColor: serviceArea.color,
                        fillOpacity: 0.35,
                        strokeColor: serviceArea.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                  )
                })
              }
              {points.map((point, index) =>
                <Marker
                  key={index}
                  position={{ lat: point.lat, lng: point.lng }}
                  icon={dotIcon}
                />
                // <Marker key={index} position={{ lat: point.lat, lng: point.lng }} />
              )}

              {points.length > 1 &&
                <>
                  {completeShape == true ?
                    <Polygon
                      path={points}
                      options={{
                        fillColor: fillColor,
                        fillOpacity: 0.35,
                        strokeColor: fillColor,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                      }}
                    />
                    :
                    <>
                      {points.map((point, index) => (
                        <Marker
                          key={index}
                          position={point}
                          icon={dotIcon}
                          draggable={true}
                          onDragEnd={(e) => onDragPoint(index, e)}
                          onClick={(point) => onMarkerClick(point, index)}
                        />
                      ))
                      }
                      <Polyline
                        path={points}
                        options={{
                          strokeColor: fillColor,
                          strokeOpacity: 1,
                          strokeWeight: 2,
                        }}
                      />
                    </>
                  }
                </>
              }

            </GoogleMap>
          }

        </Layout>
        <Layout style={{ flex: 2, flexDirection: "column", gap: 10 }}>
          <Layout style={{ flex: 1, flexDirection: "row", marginLeft: 20 }}>
            <Input
              label={(evaProps: any) => <Text {...evaProps}>Service Area Name</Text>}
              placeholder='Service Area Name'
              value={serviceAreaName}
              onChangeText={(nextValue: string) => setServiceAreaName(nextValue)}
            />
          </Layout>
          <Card
            style={{ flexDirection: "column" }}
            status='primary'
            header={(props: any) => <Text {...props}>Actions</Text>}
          >
            <Layout style={{ flex: 1, flexDirection: "row", gap: 10 }}>
              <Button
                style={{ flex: 1 }}
                status='warning'
                appearance='outline'
                onPress={deleteLastPoint}>Delete Last Point</Button>
              <Button
                style={{ flex: 1 }}
                status='danger'
                appearance='outline'
                onPress={deleteAllPoints}>Delete All Points</Button>
            </Layout>
          </Card>
          <Card
            style={{ flexDirection: "column" }}
            status='primary'
            header={(props: any) =>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text {...props}>Shape</Text>
                <View>
                  {completeShape &&
                    <Text category='s2'>Complete</Text>
                  }
                  {!completeShape &&
                    <Text status='warning' category='s2'>Editing</Text>
                  }
                </View>
              </View>
            }
          >
            <Layout style={{ flex: 1, flexDirection: "row", gap: 10 }}>
              {completeShape &&
                <Button
                  style={{ flex: 1 }}
                  status='info'
                  appearance='outline'
                  onPress={() => onEditShape()}>Edit</Button>
              }
              {!completeShape &&
                <Button
                  style={{ flex: 1 }}
                  status='success'
                  appearance='outline'
                  onPress={() => onSetCompleteShape()}>Complete Line</Button>
              }
            </Layout>
          </Card>
          <Card
            style={{ flexDirection: "column" }}
            status='primary'
            header={(props: any) => <Text {...props}>Color</Text>}
          >
            <View style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}>
              <CirclePicker
                color={fillColor}
                onChangeComplete={(color: any, event: any) => onSetFillColor(color.hex)}
              />
            </View>
          </Card>

          <Layout style={{ flex: 1, flexDirection: "row" }}>
            <Button
              style={{ flex: 1 }}
              status='success'
              appearance='outline'
              onPress={() => handleSave()}
              accessoryLeft={(props: any) => <Icon {...props} name='save-outline' width={20} height={20} fill="white" />}
            >
              Save
            </Button>
          </Layout>
        </Layout>
      </Layout >
    </Card>
  );
};