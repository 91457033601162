import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
} from "react-native";
import {
    Modal,
    Card,
    Input,
    Button,
    Select,
    SelectItem,
    Text,
    Toggle,
} from "@ui-kitten/components";
import { SurchargeDefinition, SURCHARGE_TYPE } from '../../models/Surcharge.model';
import { LINE_ITEM_BILLED_TO } from '../../models/LineItem.model';

interface SurchargeModalProps {
    visible: boolean;
    surcharge?: SurchargeDefinition;
    onClose: () => void;
    onSave: (surcharge: SurchargeDefinition) => void;
}

export const SurchargeModal = ({
    visible,
    surcharge,
    onClose,
    onSave,
}: SurchargeModalProps) => {
    const [formData, setFormData] = useState<Partial<SurchargeDefinition>>({
        enabled: true,
        billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
    });

    useEffect(() => {
        if (surcharge) {
            setFormData(surcharge);
        } else {
            setFormData({
                enabled: true,
                billed_to: LINE_ITEM_BILLED_TO.CUSTOMER_COST,
            });
        }
    }, [surcharge, visible]);

    const formatType = (type: string) => {
        return type
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };

    const handleSave = () => {
        if (!formData.name || !formData.type || formData.defaultRate === undefined) {
            alert('Please fill in all required fields');
            return;
        }

        onSave({
            id: surcharge?.id || `surcharge_${Date.now()}`,
            name: formData.name,
            type: formData.type as SURCHARGE_TYPE,
            defaultRate: Number(formData.defaultRate),
            description: formData.description,
            enabled: formData.enabled || true,
            default_enabled: formData.default_enabled || false,
            billed_to: formData.billed_to || LINE_ITEM_BILLED_TO.CUSTOMER_COST,
            account_code: formData.account_code,
        });
    };

    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={onClose}
        >
            <Card disabled>
                <Text category="h6" style={styles.title}>
                    {surcharge ? 'Edit Surcharge' : 'Create New Surcharge'}
                </Text>

                <View style={styles.form}>
                    <Input
                        label="Name"
                        placeholder="Enter surcharge name"
                        value={formData.name}
                        onChangeText={(text) => setFormData({ ...formData, name: text })}
                    />

                    <Input
                        label="Description"
                        placeholder="Enter description"
                        value={formData.description}
                        onChangeText={(text) => setFormData({ ...formData, description: text })}
                    />

                    <View style={styles.buttonGroup}>
                        {Object.values(SURCHARGE_TYPE).map((type) => (
                            <Button
                                key={type}
                                status={formData.type === type ? 'success' : 'basic'}
                                appearance={formData.type === type ? 'filled' : 'outline'}
                                onPress={() => setFormData({ ...formData, type })}
                                style={styles.typeButton}
                            >
                                {formatType(type)}
                            </Button>
                        ))}
                    </View>

                    <Input
                        label="Default Rate"
                        placeholder="Enter default rate"
                        keyboardType="numeric"
                        value={formData.defaultRate?.toString()}
                        onChangeText={(text) => setFormData({ ...formData, defaultRate: parseFloat(text) || 0 })}
                    />

                    <View style={styles.buttonGroup}>
                        {Object.values(LINE_ITEM_BILLED_TO).map((billedTo) => (
                            <Button
                                key={billedTo}
                                status={formData.billed_to === billedTo ? 'primary' : 'basic'}
                                appearance={formData.billed_to === billedTo ? 'filled' : 'outline'}
                                onPress={() => setFormData({ ...formData, billed_to: billedTo })}
                                style={styles.typeButton}
                            >
                                {formatType(billedTo)}
                            </Button>
                        ))}
                    </View>

                    <Input
                        label="Account Code (Optional)"
                        placeholder="Enter account code"
                        value={formData.account_code || ''}
                        onChangeText={(text) => setFormData({ ...formData, account_code: text })}
                    />
                    <View style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Toggle
                            style={styles.toggle}
                            checked={formData.default_enabled || false}
                            onChange={(checked) => setFormData({ ...formData, default_enabled: checked })}
                        >
                            {evaProps => <Text {...evaProps}>Enable by Default on Job Creation</Text>}
                        </Toggle>
                    </View>
                </View>

                <View style={styles.buttons}>
                    <Button status="basic" onPress={onClose}>
                        Cancel
                    </Button>
                    <Button status="primary" onPress={handleSave}>
                        Save
                    </Button>
                </View>
            </Card>
        </Modal>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    title: {
        marginBottom: 16,
    },
    form: {
        gap: 8,
        marginBottom: 16,
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 8,
    },
    toggle: {
        marginTop: 8,
    },
    buttonGroup: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    typeButton: {
        flex: 1,
        marginHorizontal: 4,
    },
}); 