import React, { useState, useEffect, useRef } from 'react'; // Add useState and useEffect
import { View, StyleSheet, Animated, ScrollView } from 'react-native';
import { Text, Button, Layout, useTheme, Icon, OverflowMenu, MenuItem, Modal, CheckBox } from '@ui-kitten/components';
import { InventoryItem, InventoryStock, InventoryLocation } from '../../models/Inventory.model';
import { CustomPricing } from '../../models/Inventory.model';
import { formatTimestamp } from '../../functions/utils';
import * as StorageController from '../../functions/storageController';
import * as InventoryController from '../../functions/Inventory.controller'; // Import the function
import AddCustomPricingModal from './AddCustomPricingModal.component';
import AddInventoryStockModal from './AddInventoryStockModal.component'; // Import the new modal
import Toast from 'react-native-toast-message';
import EditCustomPricingModal from './EditCustomPricingModal.component'; // Import the edit modal
import { JobAddress } from '../../models/Job.model';
import DMModal from '../common/Modal'

interface InventoryItemDetailProps {
    item: InventoryItem;
    onEdit: () => void;
    onRemove: () => void;
    // Add props for selection
    isSelected?: boolean;
    onSelect?: () => void;
}

const InventoryItemDetail: React.FC<InventoryItemDetailProps> = ({ item, onEdit, onRemove, isSelected, onSelect }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const [expanded, setExpanded] = useState(false); // State for expansion
    const [customPricings, setCustomPricings] = useState<CustomPricing[]>([]); // State for custom pricing
    const [animation] = useState(new Animated.Value(0)); // Animation state
    const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
    const [deleteModalVisible, setDeleteModalVisible] = useState(false); // State for delete modal visibility
    const [pricingToDelete, setPricingToDelete] = useState<CustomPricing | null>(null); // State to hold pricing to delete
    const [inventoryStock, setInventoryStock] = useState<InventoryStock[]>([]); // State for inventory stock
    const [stockModalVisible, setStockModalVisible] = useState(false); // State for stock modal visibility
    const [stockToEdit, setStockToEdit] = useState<InventoryStock | null>(null); // State for editing stock
    const [deleteStockModalVisible, setDeleteStockModalVisible] = useState(false); // State for delete stock modal visibility
    const [stockToDelete, setStockToDelete] = useState<InventoryStock | null>(null); // State to hold stock to delete
    const [editModalVisible, setEditModalVisible] = useState(false); // State for edit modal visibility
    const [pricingToEdit, setPricingToEdit] = useState<CustomPricing | null>(null); // State for pricing to edit
    const [allLocations, setAllLocations] = useState<InventoryLocation[]>([]);

    const inventoryItemSelected = useRef<InventoryItem | null>(null);


    const toggleExpand = () => {
        setExpanded(!expanded);
        Animated.timing(animation, {
            toValue: expanded ? 0 : 1, // Toggle between 0 and 1
            duration: 100,
            useNativeDriver: false,
        }).start();
    };


    useEffect(() => {
        if (expanded) {
            const fetchData = async () => {
                // Fetch locations first
                const company = StorageController.getCurrentCompany();
                const fetchedLocations = await InventoryController.getAllInventoryLocationsByCompanyId(company._id);
                if (fetchedLocations) {
                    setAllLocations(fetchedLocations);
                }

                // Then fetch stock
                if (!item._id) return;
                const stock = await InventoryController.getInventoryStockByInventoryItemId(item._id);
                if (stock) {
                    setInventoryStock(stock);
                } else {
                    setInventoryStock([]);
                }
            };
            fetchData();
        }
    }, [expanded, item.company_id]);

    const animatedHeight = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 500], // Adjust the output range as needed
    });


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CUSTOM PRICING
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const handleAddCustomPricing = async (newPricing: CustomPricing) => {
        // ensure the client is unique to this item, just check locally for now
        const clientExists = customPricings.find(pricing =>
            pricing.client_id === newPricing.client_id &&
            pricing.inventory_item_id === newPricing.inventory_item_id
        );
        if (clientExists) {
            console.log("🚀============== ~ file: InventoryItemDetail.component.tsx:79 ~ handleAddCustomPricing ~ clientExists🚀==============", clientExists)
            Toast.show({
                text1: 'Client price already exists for this item',
                type: 'error'
            });
            return;
        }

        const addedPricing = await InventoryController.createCustomPricing(newPricing);
        if (addedPricing) {
            setCustomPricings([...customPricings, addedPricing]);
            setModalVisible(false);
        }
        setModalVisible(false);
    };

    const handleDeleteCustomPricing = async () => {
        if (pricingToDelete) {
            const deleted = await InventoryController.deleteCustomPricing(pricingToDelete._id ?? '');
            if (deleted) {
                setCustomPricings(customPricings.filter(pricing => pricing._id !== pricingToDelete._id));
            }
            setDeleteModalVisible(false);
            setPricingToDelete(null);
        }
    };

    const getClientName = (client_id: string) => {
        const company = StorageController.getCurrentCompany();
        if (company) {
            const client = company.clients.find(client => client._id === client_id);
            return client?.name ?? 'N/A';
        }
        return 'N/A';
    };

    const handleOpenEditModal = (pricing: CustomPricing) => {
        setPricingToEdit(pricing);
        setEditModalVisible(true);
    };

    const handleUpdateCustomPricing = async (pricing: CustomPricing) => {
        const updatedPricing = await InventoryController.updateCustomPricing(pricing._id ?? '', pricing);
        if (updatedPricing) {
            setCustomPricings(customPricings.map(p => p._id === updatedPricing._id ? updatedPricing : p));
            setEditModalVisible(false);
            setPricingToEdit(null);
        }
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// INVENTORY STOCK
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const handleAddInventoryStock = async (stock: InventoryStock) => {
        try {
            if (!item._id || !item.company_id) return;

            stock.inventory_item_id = item._id;
            stock.company_id = item.company_id;

            const addedStock = await InventoryController.createInventoryStock(stock);
            if (addedStock) {
                // Fetch the location details for the new stock
                if (addedStock.location_id) {
                    const location = await InventoryController.getInventoryLocationById(addedStock.location_id);
                    if (location) {
                        addedStock.location = location;
                    }
                }
                setInventoryStock([...inventoryStock, addedStock]);
                setStockModalVisible(false);
            }
        } catch (error) {
            console.error('Error adding inventory stock:', error);
            Toast.show({
                type: 'error',
                text1: 'Failed to add inventory stock'
            });
        }
    };

    const handleEditInventoryStock = async (stock: InventoryStock) => {
        // Only send the necessary data for update
        const updateData = new InventoryStock({
            _id: stock._id,
            inventory_item_id: stock.inventory_item_id,
            location_id: stock.location_id,
            quantity: stock.quantity
        });

        const updatedStock = await InventoryController.updateInventoryStock(stock._id!, updateData);
        if (updatedStock) {
            // Keep the existing location data instead of fetching it again
            const updatedStockWithLocation = {
                ...updatedStock,
                location: stock.location // Preserve the existing location data
            };

            setInventoryStock(inventoryStock.map(s =>
                s._id === updatedStockWithLocation._id ? updatedStockWithLocation : s
            ));
            setStockModalVisible(false);
            setStockToEdit(null);
        }
    };

    const handleDeleteInventoryStock = async () => {
        if (stockToDelete) {
            const deleted = await InventoryController.deleteInventoryStock(stockToDelete._id ?? '');
            if (deleted) {
                setInventoryStock(inventoryStock.filter(stock => stock._id !== stockToDelete._id));
            }
            setDeleteStockModalVisible(false);
            setStockToDelete(null);
        }
    };
    const handleAddEditInventoryStockToItem = async (stock?: InventoryStock) => {
        setStockModalVisible(true);
        setStockToEdit(stock?._id ? stock : null);
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const getLocationName = (locationId: string) => {
        const location = allLocations.find(loc => loc._id === locationId);
        return location?.name ?? 'Unknown Location';
    };

    const getLocationAddress = (locationId: string) => {
        const location = allLocations.find(loc => loc._id === locationId);
        return location?.address;
    };

    const renderInventoryStock = () => (
        <Layout style={{ borderWidth: 1, borderColor: '#aaa', borderRadius: 5, padding: 10, margin: 10, flex: 1 }}>
            <Text category='s1' style={[styles.label]}>Inventory Stock</Text>
            <Button
                appearance='ghost'
                size='small'
                onPress={() => handleAddEditInventoryStockToItem()}
                accessoryLeft={<Icon name='plus-outline' style={styles.icon} />}
            >
                Add Inventory Stock
            </Button>
            {inventoryStock.map((stock) => (
                <View key={stock._id} style={{ width: '100%', borderWidth: 1, borderColor: '#aaa', borderRadius: 5, padding: 8, marginVertical: 4 }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <View style={{ flex: 1 }}>
                            <Text category='s1' style={{ fontWeight: 'bold' }}>
                                {getLocationName(stock.location_id!)}
                            </Text>
                            {getLocationAddress(stock.location_id!) && (
                                <Text category='c1' appearance='hint'>
                                    {new JobAddress(getLocationAddress(stock.location_id!)!).addressToString()}
                                </Text>
                            )}
                        </View>
                        <View style={{ flexDirection: 'row', gap: 5 }}>
                            <Button
                                appearance='ghost'
                                size='small'
                                onPress={() => handleAddEditInventoryStockToItem(stock)}
                                accessoryLeft={<Icon name='edit-outline' style={styles.icon} />}
                            >
                                Edit
                            </Button>
                            <Button
                                appearance='ghost'
                                size='small'
                                status='danger'
                                onPress={() => { setStockToDelete(stock); setDeleteStockModalVisible(true); }}
                                accessoryLeft={<Icon name='trash-outline' style={styles.icon} />}
                            >
                                Delete
                            </Button>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 8 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                            <Text category='s2'>Quantity:</Text>
                            <Text
                                style={[
                                    styles.detailTextPill,
                                    { backgroundColor: theme['color-primary-100'] }
                                ]}
                                category='s1'
                            >
                                {stock.quantity ?? '0'}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                            <Text category='s2'>Last Updated:</Text>
                            <Text
                                style={[
                                    styles.detailTextPill,
                                    { backgroundColor: theme['color-basic-300'] }
                                ]}
                                category='c1'
                            >
                                {formatTimestamp(stock.updatedAt ?? '')}
                            </Text>
                        </View>
                    </View>
                </View>
            ))}
        </Layout>
    );

    return (
        <View style={[styles.container, { borderColor: isSelected ? theme['color-primary-500'] : theme['color-basic-400'] }]}>
            {onSelect && (
                <CheckBox
                    checked={isSelected}
                    onChange={onSelect}
                // style={{ position: 'absolute', top: 10, left: 10 }}
                />
            )}
            <View style={{ flexDirection: 'column', flex: 1 }}>


                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={styles.row}>
                        <Icon name='pricetags-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text category='s1' style={[styles.label]}>
                            Name: <Text style={[styles.detailTextPill, { backgroundColor: theme['color-info-900'] }]}> {item.name} </Text>
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Icon name='hash-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text category='s1' style={[styles.label]}>
                            SKU: <Text category='s2'> {item.sku ?? 'N/A'} </Text>
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Icon name='shopping-cart-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-500'] }]}>
                            ${Number(item?.default_price).toFixed(2) || 'N/A'}
                        </Text>
                        <Button size='small' appearance='ghost' status='primary' onPress={toggleExpand}
                            accessoryLeft={<Icon name={expanded ? 'chevron-up-outline' : 'chevron-down-outline'} fill={theme['color-primary-500']} style={styles.icon} />}>
                        </Button>
                    </View>
                </View>
                <View style={styles.row}>
                    <Icon name='info-outline' fill={theme['color-primary-500']} style={styles.icon} />
                    <Text category='s1' style={[styles.label]}>
                        Description: <Text category='p1'> {item.description ?? 'N/A'} </Text>
                    </Text>
                </View>
                <Animated.View style={{ maxHeight: animatedHeight, overflow: 'hidden' }}>
                    {expanded && (
                        <ScrollView>
                            <View style={{ gap: 10, padding: 10, margin: 30, flexDirection: 'row' }}>
                                {/* <Layout style={{ borderWidth: 1, borderColor: '#aaa', borderRadius: 5, padding: 10, margin: 10, flex: 1 }}>
                                    <Text category='s1' style={[styles.label]}>Custom Pricing</Text>
                                    <Button
                                        appearance='ghost'
                                        size='small'
                                        onPress={() => setModalVisible(true)}
                                        accessoryLeft={<Icon name='plus-outline' style={styles.icon} />} // Added icon
                                    >
                                        Add Custom Pricing
                                    </Button>

                                    {customPricings.map((pricing) => (
                                        <View key={pricing._id} style={{ width: '100%', borderWidth: 1, borderColor: '#aaa', borderRadius: 5 }}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                                                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', flex: 1 }}>
                                        
                                                    <Text category='s1'>{getClientName(pricing.client_id ?? '')}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', flex: 1 }}>
                                                    <Text category='s1'>Price: </Text>
                                                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-500'] }]}>${pricing.price}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', flex: 1 }}>
                                                    <Button
                                                        appearance='ghost'
                                                        size='small'
                                                        onPress={() => handleOpenEditModal(pricing)} // Update edit handler
                                                        accessoryLeft={<Icon name='edit-outline' style={styles.icon} />}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        appearance='ghost'
                                                        size='small'
                                                        onPress={() => { setPricingToDelete(pricing); setDeleteModalVisible(true); }}
                                                        accessoryLeft={<Icon name='trash-outline' style={styles.icon} />} // Added icon
                                                    >
                                                        Delete
                                                    </Button>
                                                </View>
                                            </View>
                                        </View>
                                    ))} 
                                </Layout> */}
                                {renderInventoryStock()}
                            </View>
                        </ScrollView>
                    )}
                </Animated.View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={styles.row}>
                        <Icon name='calendar-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text category='p1' style={[styles.label]}>
                            Created At: <Text category='p2' style={[styles.detailTextPill, { backgroundColor: theme['color-basic-500'] }]}> {formatTimestamp(item.createdAt ?? '')} </Text>
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Icon name='edit-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text category='p1' style={[styles.label]}>
                            Updated At: <Text category='p2' style={[styles.detailTextPill, { backgroundColor: theme['color-basic-500'] }]}> {formatTimestamp(item.updatedAt ?? '')} </Text>
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <Button
                            appearance='ghost'
                            status='primary'
                            size='small'
                            accessoryLeft={<Icon name='edit-outline' style={styles.icon} />}
                            onPress={onEdit}
                        >
                            Edit
                        </Button>
                        <Button
                            appearance='ghost'
                            status='danger'
                            size='small'
                            accessoryLeft={<Icon name='trash-outline' style={styles.icon} />}
                            onPress={onRemove}
                        >
                            Remove
                        </Button>
                    </View>
                </View>

                <AddCustomPricingModal
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    onAddPricing={handleAddCustomPricing}
                    companyId={item.company_id ?? ''} // Pass company ID to fetch clients
                    inventoryItemId={item._id ?? ''}
                />

                <Modal visible={deleteModalVisible} onBackdropPress={() => setDeleteModalVisible(false)} backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <View style={{ padding: 20 }}>
                        <Text>Are you sure you want to delete this pricing?</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Button status='primary' onPress={handleDeleteCustomPricing}>Yes, Delete</Button>
                            <Button appearance='ghost' status='danger' onPress={() => setDeleteModalVisible(false)}>Cancel</Button>
                        </View>
                    </View>
                </Modal>

                <DMModal
                    visible={stockModalVisible}
                    onClose={() => {
                        setStockModalVisible(false);
                        setStockToEdit(null);
                    }}
                >
                    <AddInventoryStockModal
                        visible={stockModalVisible}
                        onClose={() => {
                            setStockModalVisible(false);
                            setStockToEdit(null);
                        }}
                        onAddStock={handleAddInventoryStock}
                        onEditStock={handleEditInventoryStock}
                        stock={stockToEdit}
                        inventoryItemId={item._id ?? ''}
                        existingLocations={inventoryStock.map(stock => stock.location_id!)}
                    />
                </DMModal>

                <Modal visible={deleteStockModalVisible} onBackdropPress={() => setDeleteStockModalVisible(false)} backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <Layout style={{ padding: 20 }}>
                        <Text>Are you sure you want to delete this stock?</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Button status='primary' onPress={handleDeleteInventoryStock}>Yes, Delete</Button>
                            <Button appearance='ghost' status='danger' onPress={() => setDeleteStockModalVisible(false)}>Cancel</Button>
                        </View>
                    </Layout>
                </Modal>

                <EditCustomPricingModal
                    visible={editModalVisible}
                    onClose={() => { setEditModalVisible(false); setPricingToEdit(null); }}
                    onUpdatePricing={handleUpdateCustomPricing}
                    pricing={pricingToEdit}
                    clientName={getClientName(pricingToEdit?.client_id ?? '')}
                />
            </View>
        </View>
    );
};

const getStyles = (theme: any) => StyleSheet.create({
    container: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        marginBottom: 10,
        borderRadius: 5,
        flexDirection: 'row',
    },
    label: {
        marginBottom: 5,
        color: theme['text-basic-color']
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: 10,
    },
    detailTextPill: {
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 8,
        paddingVertical: 2,
        // borderColor: theme['color-primary-500'], // Use theme color for border
    },
});

export default InventoryItemDetail;