//https://github.com/emqx/MQTT-Client-Examples/blob/master/mqtt-client-React/
import React, { createContext, useEffect, useState } from 'react';
import mqtt, { IClientPublishOptions } from 'mqtt';
import Toast from 'react-native-toast-message';
import * as StorageController from '../functions/storageController';
import * as UpdateController from '../functions/update.controller';
import * as MembershipController from '../functions/membership.controller';
import * as UserController from '../functions/user.controller';
import { Message } from '../models/Message.model';
import { Member } from '../models/Member.model';
var config = require('../config/config.js')
var host = config.mqtt




export enum CONNECTION_STATUS {
    CONNECTING = "connecting",
    CONNECTED = "connected",
    RECONNECTING = "reconnecting",
    DISCONNECTED = "disconnected",
    ERROR = "error",
}

interface IPayload {
    topic: string,
    message: any
}

interface ISubscription {
    topic: string,
    qos: any
}

interface IPublishOptions extends mqtt.IClientPublishOptions {
    topic: string,
    qos?: any,
    payload: any
}

var client = {} as mqtt.Client;
var subscriptions: ISubscription[] = [];
var connectionStatus = CONNECTION_STATUS.DISCONNECTED;
var currentPayload = {}

var company_id = ""
var member_id = ""
var user_id = ""



export function getConnectionStatus() {
    return connectionStatus;
}

export function getSubscriptions() {
    return subscriptions;
}

export function getClient() {
    return client;
}



var privateSubscription = {
    topic: `company_${company_id}/member_${member_id}`,
    qos: 0,
};
var companySubscription = {
    topic: `company_${company_id}`,
    qos: 0,
};

export const connect = async function () {
    try {
        const appState = await StorageController.loadStateFromDisk(false)
        const company = StorageController.getCurrentCompany()
        const membership = StorageController.getAppState().selectedMembership
        const user = StorageController.getAppState().user
        if (!company || !membership) return
        company_id = company._id
        member_id = membership?._id
        user_id = user._id

        privateSubscription.topic = `member/${member_id}`
        companySubscription.topic = `company/${company_id}`
        // console.log('company_id', company_id)
        // console.log('member_id', member_id)
        // console.log('user_id', user_id)
        //connect to the broker
        // removing client id because it causes problems with server connections to multiple logins
        // defaultOptions.clientId = `dashboard_member_${member_id}`;

        // create will packet with user info and all memberships
        let membership_ids: string[] = []
        const memberships = StorageController.getAppState().memberships
        if (memberships) {
            StorageController.getAppState().memberships?.forEach(membership => {
                membership_ids.push(membership._id)
            })
        }
        var will = {
            topic: 'user/status/offline',
            payload: JSON.stringify({
                user_id: user_id,
                membership_ids: membership_ids,
                status: "offline",
                last_seen: new Date(),
            }),
            qos: 0,
            retain: false
        }


        // get auth token from storage
        setTimeout(async () => {
            const authToken = await StorageController.getAccessToken()
            // rand num 0-10000
            const randId = Math.floor(Math.random() * 10000)
            const clientId = `dashboard_${randId}_${StorageController.appState?.user?.name || "unknown"}`
            //Connect to the server
            var defaultOptions = {
                clientId: clientId,
                keepalive: 120,
                username: authToken,
                protocol: 'wss',
                protocolId: 'MQTT',
                protocolVersion: 4,
                clean: true,
                reconnectPeriod: 1000,
                connectTimeout: 30 * 1000,
                will: will,
                rejectUnauthorized: false
            } as mqtt.IClientOptions;
            mqttConnect(host, defaultOptions);
        }, 100)
    } catch (e) {
        console.log("🚀============== ~ file: mqtt.service.ts:67 ~ connect ~ e🚀==============", e)
    }
}



// Connect to the broker
function mqttConnect(mqttHost: string, mqttOption: mqtt.IClientOptions) {
    connectionStatus = CONNECTION_STATUS.CONNECTING
    // console.log('Connecting', mqttOption);
    client = mqtt.connect(mqttHost, mqttOption);
    // console.log("🚀============== ~ file: mqtt.service.js:99 ~ mqttConnect ~ client🚀==============", client)
    mqttEventHandler()
    // checkMqttConnectionStatus()
};

// check mqtt connection status using a set interval
let clientConnectionInterval = null
let currentConnectionStatus = ""
checkMqttConnectionStatus()


export function checkMqttConnectionStatus() {
    clientConnectionInterval = setInterval(() => {
        // console.log("Checking MQTT Connection Status", client)
        if (client.connected) {
            if (currentConnectionStatus != "connected") {
                currentConnectionStatus = "connected"
                UpdateController.dispatchEventMqtt({ type: UpdateController.MQTT_ACTIONS.CONNECTED, data: "connected" })
            }
            return
        }
        if (client.reconnecting) {
            if (currentConnectionStatus != "reconnecting") {
                currentConnectionStatus = "reconnecting"
                UpdateController.dispatchEventMqtt({ type: UpdateController.MQTT_ACTIONS.RECONNECTING, data: "reconnecting" })
            }
            return
        }
        if (!client.connected && !client.reconnecting) {
            if (currentConnectionStatus != "disconnected") {
                currentConnectionStatus = "disconnected"
                UpdateController.dispatchEventMqtt({ type: UpdateController.MQTT_ACTIONS.DISCONNECTED, data: "disconnected" })
            }
            return
        }
    }, 1000);
}

// unsubscribe from all subscriptions
export function mqttUnSubAll() {
    if (client) {
        mqttUnSub(privateSubscription)
        mqttUnSub(companySubscription)
        let _subs = JSON.parse(JSON.stringify(subscriptions)) as ISubscription[]
        _subs.forEach((sub: ISubscription) => {
            console.log("Unsubscribing from", sub);
            mqttUnSub(sub)
        })
    }
}

//Successful connection then subscribe to topics
export function handleConnection() {
    connectionStatus = CONNECTION_STATUS.CONNECTED
    createACLSubscriptions()
    publishOnlineMessage()
}




const publishOnlineMessage = () => {
    // var opts = {
    //     topic: `member/status/online`,
    //     qos: 0,
    //     payload: member_id
    // }
    // asyncMqttPublish(opts)
    try {

        let membership_ids = [] as string[]
        StorageController.getAppState().memberships?.forEach(membership => {
            membership_ids.push(membership._id)
        })
        var opts = {
            topic: 'user/status/online',
            payload: JSON.stringify({
                user_id: user_id,
                membership_ids: membership_ids,
                status: "online",
                last_seen: new Date(),
            }),
            qos: 0,
            retain: false
        }
        asyncMqttPublish(opts)
    }
    catch (err) {
        console.log(err)
    }
}



//Publish Company wide message
export const publishCompany = (message: any) => {
    //Get subscription params
    var company_id = StorageController.getCurrentCompany()._id
    //publish to the topic
    var opts = {
        topic: `company/${company_id}`,
        qos: 0,
        payload: message,
    }
    asyncMqttPublish(opts)
}

// Publish to private message
export const publishPrivate = (message: any) => {
    //Get subscription params
    var member_id = StorageController.getAppState().selectedMembership?._id
    //publish to the topic
    var opts = {
        topic: `member/${member_id}`,
        qos: 0,
        payload: message,
    }
    asyncMqttPublish(opts)
    // mqttPublish(opts)
}



/* =================================================================
    ACL
================================================================= */

// variable subscriptions
/*
Company-Wide Updates for Vendors
vendor/{vendorID}/updates

Driver Locations for Specific Jobs
job/{clientID}/{vendorID}/{driverID}/{jobID}/location

// Drivers location while not on a job
location/vendor/{vendorID}/driver/{driverID}

Job Status for Specific Jobs
job/{clientID}/{vendorID}/{driverID}/{jobID}/status

Instant Messaging Between Entities
chat/{senderID}/{receiverID} 

Subscription Strategies
Clients
Subscribe to: job/{clientID}/+/+/+/status 
and
job/{clientID}/+/+/+/location
This allows them to get updates only related to jobs they've created, and to see the driver's location only when assigned to their job.

Vendors
Subscribe to: vendor/{vendorID}/updates
This allows them to get company-wide updates.

Drivers
Subscribe to: job/+/+/{driverID}/+/status and job/+/+/{driverID}/+/location
This allows them to get updates for jobs they are assigned to, regardless of the client or vendor.

Instant Messaging
Subscribe to: chat/{theirOwnID}/#
This allows receiving of messages directed at them.
*/

export async function createACLSubscriptions() {
    try {

        // get user id
        const appState = await StorageController.loadStateFromDisk(false)
        if (!appState) return
        const memberships = await UserController.getMemberships(appState.user._id) as Member[]
        var user_id = appState.user._id
        // get all memberships
        // var memberships = StorageController.getAppState().memberships

        // generate ACL rules per membership
        var acl_rules = [] as string[]
        memberships?.forEach(membership => {
            // get company id
            var company_id = membership.company_id
            // get membership id
            var member_id = membership._id

            let aclSubscriptions = [
                `vendor/${company_id}/updates`,
                `job/+/+/${member_id}/+/#`,
                `job/+/${company_id}/+/+/#`,
                `job/${company_id}/+/+/+/#`, // if company is a client company
                `chat/member/${member_id}/#`,
                `chat/company/${company_id}/#`,
                `location/vendor/${company_id}/driver/+`,
                `company/${company_id}`,
                `member/${member_id}`,
                `route/${company_id}/#`,
                `order/${company_id}/#`,
                `order/+/${company_id}/#`,
            ]
            if (membership.is_client) {
                var client_id = membership.client_id
                aclSubscriptions.push(`job/${client_id}/+/+/+/#`)
            } else {
                aclSubscriptions.push(`job/+/${company_id}/+/+/#`)
            }
            acl_rules = [...acl_rules, ...aclSubscriptions]
        })


        // all users
        acl_rules.push(`chat/user/${user_id}/#`)
        acl_rules.push(`user/${user_id}`)


        // subscribe to all rules
        acl_rules.forEach(rule => {
            var opts = {
                topic: rule,
                qos: 2,
            }
            mqttSub(opts)
        })
    } catch (err) {
        console.log("🚀============== ~ file: mqtt.service.ts:364 ~ createACLSubscriptions ~ err🚀==============", err)
    }
}













//function that handles mqtt events
const mqttEventHandler = () => {
    // console.log('handler', client)
    handleConnection();
    client.on('connect', function (err) {
        if (!err) {
            console.log('mqtt = Connected')
            UpdateController.dispatchEventMqtt({ type: UpdateController.MQTT_ACTIONS.CONNECTED, data: "connected" })
        }
    })
    client.on('error', (err) => {
        console.error('Connection error: ', err);
        UpdateController.dispatchEventMqtt({ type: UpdateController.MQTT_ACTIONS.ERROR, data: err })
        // client.end(); // close the connection... but leaving this out because it retries
    });
    client.on('reconnect', () => {
        connectionStatus = CONNECTION_STATUS.RECONNECTING
        UpdateController.dispatchEventMqtt({ type: UpdateController.MQTT_ACTIONS.RECONNECTING, data: "reconnecting" })
    });
    client.on('message', (topic, message) => {
        const payload: IPayload = { topic, message: message };
        handleMessage(payload);
        currentPayload = { topic: topic, message: message };
    });
}


//TODO fix temp functions here
function handleMessage(payload: IPayload) {
    payload.message = new TextDecoder("utf-8").decode(payload.message);
    let data: any = {}
    const topic = payload.topic
    if (isJson(payload.message)) {
        data = JSON.parse(payload.message)
    }
    // console.log("🚀 ~ file: mqtt.service.js:397 ~ handleMessage ~ data:", topic, data)
    //===========================================
    // TEST ACL
    //===========================================
    // Matching for job related topics
    if (/^job\/.+\/.+\/.+\/.+\/.*$/.test(topic)) {
        // job/6287a30c002db270e534eb9a/622a115d9bdeb67966f19c2a/640d6ebcb8ad3309985789fa/65262471aa0408c3397d14d3/status
        // console.log("🚀 ~ file: mqtt.service.js:405 ~ JOB UPDATE handleMessage ~ data:", topic, data)
        if (topic.includes("location")) {
            // TODO:this comes through broken for drivers location - using the other one for drivers loaction
            // console.log("🚀 ~ file: mqtt.service.js:407 ~ handleMessage ~ location message:", data)
            // driverLocationUpdate(data)
            driverJobLocationUpdate(data)
            return
        }
        if (topic.includes("status")) {
            mqttUpdateLocalStateFromServer("JOBS", data)
            // console.log("🚀 ~ file: mqtt.service.js:414 ~ handleMessage ~ data:", data)
            return
        }
        // Defaul just update jobs
        mqttUpdateLocalStateFromServer("JOBS", data)
        // console.log("🚀 ~ file: mqtt.service.js:418 ~ handleMessage ~ mqttUpdateLocalStateFromServer:", data)
        return
    }

    if (/^route\/.+\/.*$/.test(topic)) {
        mqttUpdateLocalStateFromServer("ROUTES", data)
        return
    }


    if (/^order\/.+\/.+\/.*$/.test(topic)) {
        mqttUpdateLocalStateFromServer("ORDERS", data)
        return
    }

    // Matching for chat related topics
    if (/^chat\/member\/.+\/.*$/.test(topic)) {
        // console.log(`MQTT 401 Received member chat message: `, data, topic);
        const CHAT_STATUS = {
            MEMBER_CHAT_READ: "MEMBER_CHAT_READ",
            MEMBER_CHAT_IS_TYPING: "MEMBER_CHAT_IS_TYPING",
            MEMBER_CHAT_UPDATE: "MEMBER_CHAT_UPDATE",
        }

        switch (data.update) {
            case CHAT_STATUS.MEMBER_CHAT_READ:
                // console.log("🚀 ~ file: mqtt.service.js:614 ~ handleMessage ~ CHAT_STATUS.MEMBER_CHAT_READ", data)
                UpdateController.dispatchEventMqtt({ type: UpdateController.STATE_ACTIONS.MQTT_ON_MEMBER_CHAT_READ, data: { recipient_id: data.data.recipient_id, sender_id: data.data.sender_id } })
                break;
            case CHAT_STATUS.MEMBER_CHAT_IS_TYPING:
                UpdateController.dispatchEventMqtt({ type: UpdateController.STATE_ACTIONS.MEMBER_CHAT_IS_TYPING, data: { recipient_id: data.data.recipient_id, sender_id: data.data.sender_id } })
                // console.log("🚀 ~ file: mqtt.service.js:614 ~ handleMessage ~ CHAT_STATUS.MEMBER_CHAT_IS_TYPING", data)
                break;
            case CHAT_STATUS.MEMBER_CHAT_UPDATE:
                UpdateController.dispatchEventMqtt({ type: UpdateController.STATE_ACTIONS.MEMBER_CHAT_UPDATE, data: { recipient_id: data.data.recipient_id, sender_id: data.data.sender_id } })
                // console.log("🚀 ~ file: mqtt.service.js:614 ~ handleMessage ~ CHAT_STATUS.MEMBER_CHAT_UPDATE", data)
                break;
            default:
                break;
        }
        return
    }

    if (/vendor\/.+\/updates/.test(topic)) {
        // console.log(`Received vendor update: ${data}`);
        return
    }

    else if (/chat\/user\/.+\/.+/.test(topic)) {
        // console.log(`Received user chat message: ${data}`);
        return
    }
    else if (/chat\/company\/.+\/.+/.test(topic)) {
        // console.log(`Received company chat message: ${data}`);
        return
    }
    else if (/company\/.+/.test(topic)) {
        // console.log(`Received company message: ${data}`);
    }
    else if (/member\/.+/.test(topic)) {
        // console.log(`Received member message: ${data}`);
    }
    else if (/user\/.+/.test(topic)) {
        // console.log(`Received user message: ${data}`);
    }

    // location/vendor/$vendor_id/driver/$driver_id
    else if (/location\/.*$/.test(topic)) {
        driverLocationUpdate(data)
        return
    }
    else {
        // console.log(`Received unknown message:`, data, topic);
    }

    //Is a company message
    if (payload.topic.includes("company/")) {
        // if the topic is for the current company
        // get companyId from topic
        let topic_company_id = payload.topic.split("/")[1]
        if (isJson(payload.message.toString())) {
            // console.log("🚀 ~ file: mqtt.service.js:488 ~ handleMessage ~ payload.message.toString():", payload.message.toString())
            // console.log("MQTT MESSAGE", JSON.parse(payload.message))
            data = JSON.parse(payload.message)
            if (data.hasOwnProperty("update")) {
                handleUpdateMessages(data, "company", topic_company_id)
            }
        } else {
        }
    }
    // is a private message
    else if (payload.topic.includes("member/")) {
        // get memberId from topic
        let topic_member_id = payload.topic.split("/")[1]
        // console.log("MEMBER MQTT MESSAGE", payload.message)
        if (isJson(payload.message.toString())) {
            data = JSON.parse(payload.message.toString())
            if (data.hasOwnProperty("update")) {
                // console.log("🚀============== ~ file: mqtt.service.js:511 ~ handleMessage ~ data🚀==============", data)
                handleUpdateMessages(data, "member", topic_member_id)
            }
        }
    }
    // else if (payload.topic.includes("location/")) {
    //     // console.log("=== LOCATION UPDATE ===", payload.message.toString())
    //     if (isJson(payload.message.toString())) {
    //         data = JSON.parse(payload.message.toString())
    //         // console.log(data)
    //         driverLocationUpdate(data)
    //     }
    // }
    if (payload.topic.includes("channel_")) {
        // chatMessageUpdate()
        if (isJson(payload.message.toString())) {
            data = JSON.parse(payload.message.toString())
            chatMessageUpdate(data.message)
            // data: {
            //     "message": {
            //         "channel_id": "62d1764ff95492fc5668dce2",
            //         "sender_id": "6270ee80fef10d18143ef31f",
            //         "message": "bruh",
            //         "type": "chat",
            //         "read": false
            //     },
            //     "update": true
            // }
            console.log("channel message", data)
            Toast.show({
                type: 'success',
                text1: "New Message: ",
                text2: `${data.message.message}👋`
            });
        } else {
            Toast.show({
                type: 'success',
                text1: "New Message: ",
                text2: `${payload.message}👋`
            });
        }
    }
}

const handleUpdateMessages = (data: any, updateFrom: string, id: string) => {
    // console.log("handleUpdateMessages", data, data.update)
    if (updateFrom == "company") {
        const company = StorageController.getCurrentCompany()
        if (id != company._id) {
            data.otherId = id
            data.updateFrom = "company"
            UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.UPDATED_OTHER_COMPANY, data: data })
            // return
        }
    }
    if (updateFrom == "member") {
        if (id != StorageController.getAppState().selectedMembership?._id) {
            data.otherId = id
            data.updateFrom = "member"
            UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.UPDATED_OTHER_COMPANY, data: data })
            // return
        }
    }
    switch (data.update) {
        case "COMPANY":
            mqttUpdateLocalStateFromServer("DEFAULT", data)
            break;
        case "JOBS":
            // mqttUpdateLocalStateFromServer("JOBS", data)
            break;
        case "HOLDINGS":
            mqttUpdateLocalStateFromServer("HOLDINGS", data)
            break;
        case "MEMBERS":
            mqttUpdateLocalStateFromServer("MEMBERS", data)
            // console.log("🚀============== ~ file: mqtt.service.ts:621 ~ handleUpdateMessages ~ data🚀==============", data)
            break;
        case "DRIVERS":
            mqttUpdateLocalStateFromServer("DRIVERS", data)
            break;
        case "ROUTE":
            mqttUpdateLocalStateFromServer("ROUTE", data)
            break;
        case "MESSAGES":
            mqttUpdateLocalStateFromServer("MESSAGES", data)
            break;
        case "ALL":
            mqttUpdateLocalStateFromServer("DEFAULT", data)
            break;
        case "CLIENTS_UPDATE":
            mqttUpdateLocalStateFromServer("CLIENTS", data)
            break;
        case "DRIVER_JOB_LOCATION":
            mqttUpdateLocalStateFromServer("DRIVER_JOB_LOCATION", data)
            break;
        default:
            mqttUpdateLocalStateFromServer("DEFAULT", data)
            break;
    }
}




function isJson(str: string) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

// ===============================
// CHAT MESSAGES
// ========================
export const subscribeToChannel = async (channel_id: string) => {
    // console.log('subscribing to _id', channel_id)
    // create a subscription
    var sub = {
        topic: "channel_" + channel_id,
        qos: 2,
    }
    mqttSub(sub)
}
// publish chat messages
export const publishChatChannelMessage = async (payload: Message) => {
    var opts = {
        //@ts-ignore
        topic: `channel_${StorageController.appState.selectedChannel?._id}`,
        qos: 2,
        payload: JSON.stringify(payload),
    }
    // console.log('publishing chat message', opts)
    asyncMqttPublish(opts)
}

async function chatMessageUpdate(msg: any) {
    UpdateController.readyMessageUpdate(msg);
    // console.log('chat updated by server')
}



//=-================================
// Location messages - TODO: REMOVE AS OLD
//=-================================
// subscribe to location updates from each driver
export const subscribeToDriverLocation = async (driver_id: string) => {
    var sub = {
        topic: "location/member_" + driver_id,
        qos: 2,
    }
    mqttSub(sub)
}

// Subscribe to each driver's location
export const subscribeToAllDriverLocations = async () => {
    // console.log('subscribing to all drivers')
    try {
        if (!StorageController.appState.drivers) return
        for (var driver of StorageController.appState.drivers) {
            subscribeToDriverLocation(driver._id)
        }
    }
    catch (err) {
        console.log(err)
    }
}

// subscribe to all drivers of all companies
export const subscribeToAllDrivers = async () => {
    // console.log('subscribing to all drivers')
    try {
        if (!StorageController.appState.companies) return
        for (var company of StorageController.appState.companies) {
            const drivers = company.members.filter(d => d.is_driver == true)
            for (var driver of drivers) {
                subscribeToDriverLocation(driver._id)
            }
        }
    }
    catch (err) {
        console.log(err)
    }
}


// save drivers location to local state
async function driverLocationUpdate(data: any) {
    mqttUpdateLocalStateFromServer("DRIVER_LOCATION", data)
}

async function driverJobLocationUpdate(data: any) {
    mqttUpdateLocalStateFromServer("DRIVER_JOB_LOCATION", data)
}




//=-================================
// Local messages
//=-================================
async function mqttUpdateLocalStateFromServer(type: string, data: any) {
    UpdateController.updateFromMqtt(type, data);
    // console.log('updated by server', type, data)
}


// ===============================
// MQTT FUNCTIONS
// ========================


//async function publish to mqtt server
function asyncMqttPublish(context: IPublishOptions) {
    return new Promise((resolve, reject) => {
        const { topic, qos, payload } = context;
        client.publish(topic, payload, { qos }, (err, result) => {
            if (err) reject(err)
            else resolve(result)
        })
    })
}


function mqttPublish(context: IPublishOptions) {
    if (client) {
        const { topic, qos, payload } = context;
        client.publish(topic, payload, { qos }, error => {
            if (error) {
                console.log('Publish error: ', error);
            }
        });
    }
}

function mqttSub(subscription: ISubscription) {
    // console.log('subscribing to', subscription, client)
    if (client) {
        const { topic, qos } = subscription;
        // if subscription already exists, return
        if (subscriptions.find(sub => sub.topic === topic)) {
            return
        }
        client?.subscribe(topic, { qos }, (error) => {
            if (error) {
                console.log('Subscribe to topics error', error)
                return
            }
            subscriptions.push(subscription)
        });
    }
};

function mqttUnSub(subscription: ISubscription) {
    if (client) {
        const { topic } = subscription;
        client.unsubscribe(topic, (error: any) => {
            if (error) {
                console.log('Unsubscribe error', error)
                return
            }
            // remove subscription from array
            subscriptions = subscriptions.filter(sub => sub.topic !== topic)
        });
    }
};