import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as HoldingController from "../../../functions/holding.controller"
import * as StorageController from "../../../functions/storageController"
import * as JobController from "../../../functions/job.controller"
import { Holding } from "../../../models/Holding.model";
import { Job, JobAddress, JobClientRate, JobCustomerDetails, JobLocation, JobVehicleDetails, Service, JobNote, JOB_NOTE_TYPE } from "../../../models/Job.model";
import { LineItem } from '../../../models/LineItem.model';
import { ReportDetailsCard } from '../details/reportDetailsCard.component';
import { CreateJobFromHolding } from '../modals/createJobFromHoldingModal.component';
import { InventoryDetailsCard } from './inventory/inventoryDetailsCard.component';
import { InvoicedTotalCard } from '../details/jobInvoicedTotalDetailsCard.component';
import * as ClientController from '../../../functions/client.controller'
import { LocationDetailsCard } from '../details/locationDetailsCard.component';
import { Client } from '../../../models/Client.model';
import * as ImageController from '../../../functions/image.controller';

import { CustomerDetailsCard } from '../details/customerDetailsCard.component';
import { VehicleDetailsCard } from '../details/vehicleDetailsCard.component';
import { ClientDetailsCard } from '../details/ClientDetailsCard.component';
import { ImageDetailsCard } from '../details/imageDetailsCard.component';

import { useNotification } from '../../../context/notifications.context';
import * as ImagePicker from 'expo-image-picker';
import { formatTimestamp } from '../../../functions/utils';


interface JobNotesListProps {
    notes: JobNote[];
    onAddNote: (note: JobNote) => void;
    onDeleteNote: (note: JobNote) => void;
    onUpdateNote: (note: JobNote) => void;
}

export const JobNotesList = ({ notes, onAddNote, onDeleteNote, onUpdateNote }: JobNotesListProps) => {
    const [showNewNoteField, setShowNewNoteField] = useState(false);
    const addAndCloseNote = (note: JobNote) => {
        onAddNote(note);
        setShowNewNoteField(false);
    }

    useEffect(() => {
        if (notes.length === 0) setShowNewNoteField(true);
    }, [notes])

    return (
        <Card
            status='basic'
            style={{ flex: 1, padding: 10 }}
            disabled={true}
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category="h6">Notes</Text>
                    <Button onPress={() => { setShowNewNoteField(true) }}>Add Note</Button>
                </View>
            }
        >
            {showNewNoteField && <AddNoteFields onAddNote={addAndCloseNote} />}
            <List
                data={notes}
                ItemSeparatorComponent={Divider}
                renderItem={({ item, index }: { item: JobNote, index: number }) => {
                    const membership = StorageController.getCurrentCompany()?.members?.find(m => m._id === item.member_id);
                    const timestamp = formatTimestamp(item.created_time);
                    const description = `${membership?.name} - ${timestamp}`
                    return (
                        <ListItem
                            title={item.note}
                            description={description}
                            accessoryLeft={(props: any) => <Icon {...props} name='file-text-outline' />}
                        // accessoryRight={(props: any) =>
                        //     <Button
                        //         appearance='ghost'
                        //         status='danger'
                        //         onPress={() => onDeleteNote(item)}
                        //     >
                        //         <Icon {...props} name='trash-2-outline' width={20} height={20} fill='red' />
                        //     </Button>
                        // }
                        />
                    )
                }}
            />
        </Card>
    )
}


const AddNoteFields = ({ onAddNote }: { onAddNote: (note: JobNote) => void }) => {
    const [note, setNote] = useState('');

    const createNote = (text: string) => {
        if (text == "") return
        onAddNote(new JobNote({
            note: text,
            member_id: StorageController.getAppState().selectedMembership?._id as string,
            type: JOB_NOTE_TYPE.GENERAL,
            created_time: Date.now()
        }));
        setNote('');
    }
    return (
        <Layout style={{ padding: 10 }}>
            <Text category="h6">Add Note</Text>
            <Input
                multiline
                textStyle={{ minHeight: 64 }}
                placeholder='Add a note'
                value={note}
                onChangeText={setNote}
            />
            <Button onPress={() => createNote(note)}>Add Note</Button>
        </Layout>
    )
}