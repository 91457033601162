import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Card, Text, Button, Input, Select, SelectItem, IndexPath } from '@ui-kitten/components';
import { CustomIntegration } from '../../models/Company.model';
import DMModal from '../common/Modal';

interface IntegrationModalProps {
    visible: boolean;
    integration?: CustomIntegration;
    onClose: () => void;
    onSave: (integration: CustomIntegration) => void;
}

export const IntegrationModal = ({ visible, integration, onClose, onSave }: IntegrationModalProps) => {
    const [name, setName] = useState(integration?.name || '');
    const [baseUrl, setBaseUrl] = useState(integration?.base_url || '');
    const [authType, setAuthType] = useState(integration?.auth_type || 'none');
    const [authConfig, setAuthConfig] = useState(integration?.auth_config || {});

    // Update state when integration prop changes
    useEffect(() => {
        if (integration) {
            setName(integration.name);
            setBaseUrl(integration.base_url);
            setAuthType(integration.auth_type);
            setAuthConfig(integration.auth_config);
        } else {
            // Reset form when creating new integration
            setName('');
            setBaseUrl('');
            setAuthType('none');
            setAuthConfig({});
        }
    }, [integration, visible]);  // Also trigger on visible changes

    const renderAuthFields = () => {
        switch (authType) {
            case 'basic':
                return (
                    <>
                        <Input
                            label="Username"
                            value={authConfig.username}
                            onChangeText={(text) => setAuthConfig({ ...authConfig, username: text })}
                        />
                        <Input
                            label="Password"
                            secureTextEntry
                            value={authConfig.password}
                            onChangeText={(text) => setAuthConfig({ ...authConfig, password: text })}
                        />
                    </>
                );
            case 'bearer':
                return (
                    <Input
                        label="Bearer Token"
                        value={authConfig.token}
                        onChangeText={(text) => setAuthConfig({ ...authConfig, token: text })}
                    />
                );
            case 'custom':
                return (
                    <>
                        <Input
                            label="Header Name"
                            value={authConfig.custom_header}
                            onChangeText={(text) => setAuthConfig({ ...authConfig, custom_header: text })}
                        />
                        <Input
                            label="Header Value"
                            value={authConfig.custom_value}
                            onChangeText={(text) => setAuthConfig({ ...authConfig, custom_value: text })}
                        />
                    </>
                );
            default:
                return null;
        }
    };

    const handleSave = () => {
        onSave(new CustomIntegration({
            ...integration,
            name,
            base_url: baseUrl,
            auth_type: authType,
            auth_config: authConfig,
            enabled: integration?.enabled ?? true
        }));
        onClose();
    };

    return (
        <DMModal visible={visible} onClose={onClose}>
            <Card>
                <Text category='h6' style={{ marginBottom: 15 }}>
                    {integration ? 'Edit Integration' : 'New Integration'}
                </Text>
                <View style={{ gap: 15 }}>
                    <Input
                        label="Integration Name"
                        placeholder="Enter integration name"
                        value={name}
                        onChangeText={setName}
                    />
                    <Input
                        label="Base URL"
                        placeholder="https://api.example.com"
                        value={baseUrl}
                        onChangeText={setBaseUrl}
                    />
                    <Select
                        label="Authentication Type"
                        selectedIndex={new IndexPath(['none', 'basic', 'bearer', 'custom'].indexOf(authType))}
                        value={authType}
                        onSelect={(index) => {
                            //@ts-ignore
                            const newType = ['none', 'basic', 'bearer', 'custom'][index.row];
                            setAuthType(newType as any);
                            setAuthConfig({}); // Reset auth config when type changes
                        }}
                    >
                        <SelectItem title="No Authentication" />
                        <SelectItem title="Basic Auth" />
                        <SelectItem title="Bearer Token" />
                        <SelectItem title="Custom" />
                    </Select>
                    {renderAuthFields()}
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }}>
                        <Button status='basic' onPress={onClose}>
                            Cancel
                        </Button>
                        <Button onPress={handleSave}>
                            Save
                        </Button>
                    </View>
                </View>
            </Card>
        </DMModal>
    );
};
