import React from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle } from '@ui-kitten/components';
import { CompanyRoles, Company } from '../../models/Company.model';
import { SUBSCRIPTION_FEATURES } from '../../models/CompanySubscription.model';

interface CompanyRolesProps {
    company: Company;
    onUpdateCompanyRoles: (updatedRoles: CompanyRoles) => void;
    style?: any;
}

export const CompanyRolesComponent: React.FC<CompanyRolesProps> = ({
    company,
    onUpdateCompanyRoles,
    style = {}
}) => {
    const updateCompanyRole = (key: keyof CompanyRoles, value: boolean) => {
        const updatedRoles = new CompanyRoles({
            ...company.settings.company_roles,
            [key]: value
        });
        onUpdateCompanyRoles(updatedRoles);
    };

    const RenderToggle = (label: string, key: keyof CompanyRoles, featureKey: SUBSCRIPTION_FEATURES, override?: boolean) => (
        (company.company_subscription?.features[featureKey] || override) && (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                <Text category='h6'>{label}</Text>
                <Toggle
                    checked={company.settings.company_roles?.[key] || false}
                    onChange={(nextChecked) => updateCompanyRole(key, nextChecked)}
                />
            </View>
        )
    );

    return (
        <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Company Role</Text>} status="basic">
            <View style={{ flexDirection: 'column' }}>
                {RenderToggle('Show Contracts', 'showContracts', SUBSCRIPTION_FEATURES.SERVICE_NETWORK, true)}
                {RenderToggle('Show Cases', 'showCases', SUBSCRIPTION_FEATURES.CASE_MANAGEMENT, true)}
            </View>
        </Card>
    );
};
