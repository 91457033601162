import React, { useState, useRef, useMemo, useCallback } from 'react';
import { View, ScrollView } from 'react-native';
import { Card, Text, Button, Input, Toggle, Select, SelectItem, Icon, Divider, List, ListItem, IndexPath } from '@ui-kitten/components';
import { CustomIntegration, IntegrationEndpoint, WEBHOOK_EVENT_TYPE } from '../../models/Company.model';
import { EndpointPropertyBuilder } from './endpointPropertyBuilder.component';
import DMModal from '../common/Modal';
import { IntegrationModal } from './integrationModal.component';
import { CompanySecret } from '../../models/CompanySecret.model';

interface IntegrationSettingsProps {
    integrations: {
        enabled: boolean;
        custom_integrations: { [key: string]: CustomIntegration };
    };
    onUpdateIntegrations: (integrations: any) => void;
    companySecret?: CompanySecret;
    onUpdateCompanySecret?: (secret: CompanySecret) => void;
    style?: any;
}

export const IntegrationSettings = ({ 
    integrations, 
    onUpdateIntegrations, 
    companySecret,
    onUpdateCompanySecret,
    style 
}: IntegrationSettingsProps) => {
    const [selectedIntegration, setSelectedIntegration] = useState<string | null>(null);
    const [selectedEndpoint, setSelectedEndpoint] = useState<{
        integrationName: string;
        eventType: WEBHOOK_EVENT_TYPE;
    } | null>(null);
    const [showNewIntegrationModal, setShowNewIntegrationModal] = useState(false);
    const [showEditIntegrationModal, setShowEditIntegrationModal] = useState(false);
    const [showNewEndpointModal, setShowNewEndpointModal] = useState(false);
    
    // Move these state declarations up here
    const [showAddHeaderModal, setShowAddHeaderModal] = useState(false);
    const [newHeaderKey, setNewHeaderKey] = useState('');
    const [newHeaderValue, setNewHeaderValue] = useState('');
    const [showAddSecretModal, setShowAddSecretModal] = useState(false);
    const [selectedIntegrationForSecret, setSelectedIntegrationForSecret] = useState('');
    const [newSecretKey, setNewSecretKey] = useState('');
    const [newSecretValue, setNewSecretValue] = useState('');

    // Use refs to track current values
    const integrationsRef = useRef(integrations);
    const companySecretRef = useRef(companySecret);

    // Update refs when props change
    React.useEffect(() => {
        integrationsRef.current = integrations;
        companySecretRef.current = companySecret;
    }, [integrations, companySecret]);

    // Memoize state updates
    const updateIntegrationState = useCallback((newIntegrations: any) => {
        integrationsRef.current = newIntegrations;
        onUpdateIntegrations(newIntegrations);
    }, [onUpdateIntegrations]);

    const updateSecretState = useCallback((newSecret: CompanySecret) => {
        companySecretRef.current = newSecret;
        if (onUpdateCompanySecret) {
            onUpdateCompanySecret(newSecret);
        }
    }, [onUpdateCompanySecret]);

    // Memoize the add/update functions
    const addNewIntegration = useCallback((integration: CustomIntegration) => {
        const newIntegrations = {
            ...integrationsRef.current,
            custom_integrations: {
                ...integrationsRef.current.custom_integrations,
                [integration.name]: integration
            }
        };
        updateIntegrationState(newIntegrations);
        setShowNewIntegrationModal(false);
    }, [updateIntegrationState]);

    const updateIntegrationSecret = useCallback((integrationName: string, key: string, value: string) => {
        if (!companySecretRef.current || !onUpdateCompanySecret) return;
        console.log("🚀============== ~ file: integrationSettings.component.tsx:98 ~ updateIntegrationSecret ~ companySecretRef.current🚀==============", companySecretRef.current)
        let currentIntegrationSecrets = {};
        try{
            currentIntegrationSecrets = companySecretRef.current.integrations[integrationName] || {};
        } catch(e){
            console.log(e);
        }
        
        const updatedSecret = new CompanySecret({
            ...companySecretRef.current,
            integrations: {
                ...companySecretRef.current.integrations,
                [integrationName]: {
                    ...currentIntegrationSecrets,
                    [key]: value
                }
            }
        });
        updateSecretState(updatedSecret);
    }, [companySecret, integrations]);

    // Memoize the secrets list renderer
    const renderSecretsList = useCallback((integrationName: string) => {
        if (!companySecretRef.current || !companySecretRef.current.integrations) {
            return null;
        }
        
        const integrationSecrets = companySecretRef.current.integrations[integrationName] || {};
        
        if (Object.keys(integrationSecrets).length === 0) {
            return (
                <Text appearance='hint'>No secrets configured for this integration</Text>
            );
        }
        
        return Object.entries(integrationSecrets).map(([key, value]) => (
            <View key={key} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5 }}>
                <View>
                    <Text category='s1'>{key}</Text>
                    <Input
                        value={value?.toString() || ''}
                        onChangeText={(newValue) => updateIntegrationSecret(integrationName, key, newValue)}
                        secureTextEntry
                    />
                </View>
                <Button
                    size='small'
                    status='danger'
                    appearance='ghost'
                    onPress={() => deleteIntegrationSecret(integrationName, key)}
                    accessoryLeft={(props) => <Icon {...props} name='trash-outline'/>}
                />
            </View>
        ));
    }, [updateIntegrationSecret]);

    // Memoize the add secret modal
    const AddSecretModal = useMemo(() => (
        <DMModal
            visible={showAddSecretModal}
            onClose={() => setShowAddSecretModal(false)}
        >
            <Card>
                <Text category='h6'>Add Integration Secret</Text>
                <Select
                    label="Integration"
                    value={selectedIntegrationForSecret}
                    //@ts-ignore
                    onSelect={(index: IndexPath) => {
                        const integrationNames = Object.keys(integrationsRef.current?.custom_integrations || {});
                        setSelectedIntegrationForSecret(integrationNames[index.row]);
                    }}
                >
                    {Object.keys(integrationsRef.current?.custom_integrations || {}).map(name => 
                        <SelectItem key={name} title={name} />
                    )}
                </Select>
                <Input
                    label="Secret Key"
                    placeholder="Enter secret key"
                    value={newSecretKey}
                    onChangeText={setNewSecretKey}
                />
                <Input
                    label="Secret Value"
                    placeholder="Enter secret value"
                    value={newSecretValue}
                    onChangeText={setNewSecretValue}
                    secureTextEntry
                />
                <Button onPress={() => {
                    if (selectedIntegrationForSecret && newSecretKey) {
                        updateIntegrationSecret(selectedIntegrationForSecret, newSecretKey, newSecretValue);
                        setShowAddSecretModal(false);
                        setNewSecretKey('');
                        setNewSecretValue('');
                    }
                }}>
                    Add Secret
                </Button>
            </Card>
        </DMModal>
    ), [showAddSecretModal, selectedIntegrationForSecret, newSecretKey, newSecretValue, updateIntegrationSecret]);

    const addNewEndpoint = (eventType: WEBHOOK_EVENT_TYPE) => {
        if (!selectedIntegration) return;

        const integration = integrations.custom_integrations[selectedIntegration];
        onUpdateIntegrations({
            ...integrations,
            custom_integrations: {
                ...integrations.custom_integrations,
                [selectedIntegration]: {
                    ...integration,
                    endpoints: {
                        ...integration.endpoints,
                        [eventType]: new IntegrationEndpoint()
                    }
                }
            }
        });
        setShowNewEndpointModal(false);
    };

    const updateIntegration = (updatedIntegration: CustomIntegration) => {
        onUpdateIntegrations({
            ...integrations,
            custom_integrations: {
                ...integrations.custom_integrations,
                [updatedIntegration.name]: updatedIntegration
            }
        });
        setShowEditIntegrationModal(false);
    };

    const deleteIntegration = (name: string) => {
        const newIntegrations = { ...integrations.custom_integrations };
        delete newIntegrations[name];
        onUpdateIntegrations({
            ...integrations,
            custom_integrations: newIntegrations
        });
    };

    const deleteEndpoint = (integrationName: string, eventType: WEBHOOK_EVENT_TYPE) => {
        const integration = integrations.custom_integrations[integrationName];
        const newEndpoints = { ...integration.endpoints };
        delete newEndpoints[eventType];
        
        onUpdateIntegrations({
            ...integrations,
            custom_integrations: {
                ...integrations.custom_integrations,
                [integrationName]: {
                    ...integration,
                    endpoints: newEndpoints
                }
            }
        });
    };

    const deleteIntegrationSecret = (integrationName: string, key: string) => {
        if (!companySecret || !onUpdateCompanySecret) return;
        
        // Make sure the integration exists before trying to delete
        if (!companySecret.integrations[integrationName]) return;
        
        const updatedSecrets = { ...companySecret.integrations[integrationName] };
        delete updatedSecrets[key];
        
        const updatedSecret = new CompanySecret({
            ...companySecret,
            integrations: {
                ...companySecret.integrations,
                [integrationName]: updatedSecrets
            }
        });
        onUpdateCompanySecret(updatedSecret);
    };

    const renderGeneralSettings = () => (
        <Card style={{ marginBottom: 15 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Text category='h6'>Integration Settings</Text>
            </View>
            <Toggle
                style={{ marginTop: 10 }}
                checked={integrations?.enabled || false}
                onChange={(checked) => onUpdateIntegrations({ ...integrations, enabled: checked })}
            >
                Enable Integrations
            </Toggle>

            {/* Integration Secrets */}
            <View style={{ marginTop: 15 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                    <Text category='s1'>Integration Secrets</Text>
                    <Button
                        size='small'
                        onPress={() => setShowAddSecretModal(true)}
                        accessoryLeft={(props) => <Icon {...props} name='plus-outline'/>}
                        disabled={!integrations?.custom_integrations || Object.keys(integrations.custom_integrations).length === 0}
                    >
                        Add Secret
                    </Button>
                </View>
                
                {integrations?.custom_integrations && Object.keys(integrations.custom_integrations).length > 0 ? (
                    Object.keys(integrations.custom_integrations).map(integrationName => (
                        <Card key={integrationName} style={{ marginBottom: 10 }}>
                            <Text category='h6'>{integrationName}</Text>
                            {renderSecretsList(integrationName)}
                        </Card>
                    ))
                ) : (
                    <Text appearance='hint'>No integrations configured</Text>
                )}
            </View>

            {/* Add Secret Modal */}
            {AddSecretModal}
        </Card>
    );

    const renderIntegrationsList = () => (
        <Card style={{ flex: 1, minHeight: 400 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                <Text category='h6'>Integrations</Text>
                <Button
                    size='small'
                    onPress={() => setShowNewIntegrationModal(true)}
                    accessoryLeft={(props) => <Icon {...props} name='plus-outline'/>}
                >
                    Add Integration
                </Button>
            </View>
            <ScrollView>
                {Object.entries(integrations.custom_integrations).map(([name, integration]) => {
                    // Ensure integration is an instance of CustomIntegration
                    const integrationInstance = new CustomIntegration({
                        ...integration,
                        name: name // Ensure name is set
                    });
                        
                    return (
                        <ListItem
                            key={name}
                            title={name}
                            description={`${Object.keys(integrationInstance.endpoints || {}).length} endpoints`}
                            accessoryLeft={(props) => (
                                <Icon {...props} name={integrationInstance.enabled ? 'checkmark-circle-outline' : 'circle-outline'} />
                            )}
                            accessoryRight={(props) => (
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <Button
                                        size='tiny'
                                        appearance='ghost'
                                        onPress={() => {
                                            setSelectedIntegration(name);
                                            setShowEditIntegrationModal(true);
                                        }}
                                        accessoryLeft={(props) => <Icon {...props} name='edit-outline'/>}
                                    />
                                    <Button
                                        size='tiny'
                                        status='danger'
                                        appearance='ghost'
                                        onPress={() => deleteIntegration(name)}
                                        accessoryLeft={(props) => <Icon {...props} name='trash-outline'/>}
                                    />
                                </View>
                            )}
                            onPress={() => setSelectedIntegration(name)}
                            style={{
                                backgroundColor: selectedIntegration === name ? 
                                    'rgba(143, 155, 179, 0.16)' : 'transparent'
                            }}
                        />
                    );
                })}
            </ScrollView>
        </Card>
    );

    const renderEndpointsList = () => {
        let allEndpoints: any[] = []
        try{
            allEndpoints = selectedIntegration ? 
                Object.entries(integrations.custom_integrations[selectedIntegration].endpoints || {})
                .map(([eventType, endpoint]) => ({
                    integrationName: selectedIntegration,
                    eventType: eventType as WEBHOOK_EVENT_TYPE,
                    endpoint,
                    enabled: endpoint.enabled
                })) : [];
        } catch(e){
            console.log(e);
        }

        return (
            <Card style={{ flex: 1, minHeight: 400 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                    <Text category='h6'>Activated Endpoints List</Text>
                    <Button
                        size='small'
                        onPress={() => setShowNewEndpointModal(true)}
                        disabled={!selectedIntegration}
                        accessoryLeft={(props) => <Icon {...props} name='plus-outline'/>}
                    >
                        Add Endpoint
                    </Button>
                </View>
                <ScrollView>
                    {allEndpoints.map(({ integrationName, eventType, endpoint, enabled }) => (
                        <ListItem
                            key={`${integrationName}-${eventType}`}
                            title={eventType}
                            description={`${endpoint.url || 'No URL set'}`}
                            accessoryLeft={(props) => (
                                <Icon {...props} name={enabled ? 'checkmark-circle-outline' : 'close-circle-outline'} />
                            )}
                            onPress={() => setSelectedEndpoint({ integrationName, eventType })}
                            style={{
                                backgroundColor: selectedEndpoint?.integrationName === integrationName && 
                                                selectedEndpoint?.eventType === eventType ? 
                                                'rgba(143, 155, 179, 0.16)' : 'transparent'
                            }}
                            accessoryRight={(props) => (
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <Button
                                        size='tiny'
                                        status='danger'
                                        appearance='ghost'
                                        onPress={() => deleteEndpoint(integrationName, eventType)}
                                        accessoryLeft={(props) => <Icon {...props} name='trash-outline'/>}
                                    />
                                </View>
                            )}
                        />
                    ))}
                </ScrollView>
            </Card>
        );
    };

    const renderEndpointSettings = () => {
        if (!selectedEndpoint) {
            return (
                <Card style={{ flex: 2, minHeight: 400 }}>
                    <Text category='h6'>Selected Endpoint Settings</Text>
                    <Text appearance='hint' style={{ marginTop: 20 }}>Select an endpoint from the list to view its settings</Text>
                </Card>
            );
        }

        const integration = integrations.custom_integrations[selectedEndpoint.integrationName];
        const endpoint = integration.endpoints[selectedEndpoint.eventType] || new IntegrationEndpoint();

        const updateEndpoint = (updates: Partial<IntegrationEndpoint>) => {
            const updatedIntegration = {
                ...integration,
                endpoints: {
                    ...integration.endpoints,
                    [selectedEndpoint.eventType]: {
                        ...endpoint,
                        ...updates
                    }
                }
            };
            onUpdateIntegrations({
                ...integrations,
                custom_integrations: {
                    ...integrations.custom_integrations,
                    [selectedEndpoint.integrationName]: updatedIntegration
                }
            });
        };

        const addHeader = () => {
            if (newHeaderKey.trim()) {
                updateEndpoint({
                    headers: {
                        ...endpoint.headers,
                        [newHeaderKey]: newHeaderValue
                    }
                });
                setNewHeaderKey('');
                setNewHeaderValue('');
                setShowAddHeaderModal(false);
            }
        };

        const removeHeader = (key: string) => {
            const newHeaders = { ...endpoint.headers };
            delete newHeaders[key];
            updateEndpoint({ headers: newHeaders });
        };

        return (
            <Card style={{ flex: 2, minHeight: 400 }}>
                <ScrollView>
                    <Text category='h6' style={{ marginBottom: 15 }}>
                        {selectedEndpoint.eventType} Settings
                    </Text>
                    
                    <View style={{ gap: 15 }}>
                        <Toggle
                            checked={endpoint.enabled}
                            onChange={(checked) => updateEndpoint({ enabled: checked })}
                        >
                            Enable Endpoint
                        </Toggle>

                        <Input
                            label="Endpoint URL"
                            placeholder="https://api.example.com/webhook"
                            value={endpoint.url}
                            onChangeText={(text) => updateEndpoint({ url: text })}
                        />

                        {/* Headers Section */}
                        <Card>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                                <Text category='s1'>Headers</Text>
                                <Button
                                    size='small'
                                    onPress={() => setShowAddHeaderModal(true)}
                                    accessoryLeft={(props) => <Icon {...props} name='plus-outline'/>}
                                >
                                    Add Header
                                </Button>
                            </View>
                            {Object.entries(endpoint.headers || {}).map(([key, value]) => (
                                <View key={key} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 5 }}>
                                    <Text>{key}: {value}</Text>
                                    <Button
                                        size='small'
                                        status='danger'
                                        appearance='ghost'
                                        onPress={() => removeHeader(key)}
                                        accessoryLeft={(props) => <Icon {...props} name='close-outline'/>}
                                    />
                                </View>
                            ))}
                        </Card>

                        {/* Method Selection */}
                        <Select
                            label="HTTP Method"
                            selectedIndex={new IndexPath(['GET', 'POST', 'PUT', 'DELETE'].indexOf(endpoint.method))}
                            value={endpoint.method}  // Changed this line
                            //@ts-ignore
                            onSelect={(index: IndexPath) => updateEndpoint({ 
                                method: ['GET', 'POST', 'PUT', 'DELETE'][index.row] as any 
                            })}
                        >
                            {['GET', 'POST', 'PUT', 'DELETE'].map(method => 
                                <SelectItem key={method} title={method} />
                            )}
                        </Select>

                        {/* Query Template */}
                        <Input
                            label="Query Template"
                            placeholder="id=${job._id}&status=${job.status}"
                            value={endpoint.query_template}
                            onChangeText={(text) => updateEndpoint({ query_template: text })}
                            caption="Use ${variable} syntax for dynamic values. Format: key=${value}&key2=${value2}"
                        />

                        {/* Body Template Builder */}
                        <EndpointPropertyBuilder
                            endpoint={endpoint}
                            eventType={selectedEndpoint.eventType}
                            onChange={(template) => updateEndpoint({ body_template: template })}
                        />
                    </View>

                    {/* Add Header Modal */}
                    <DMModal
                        visible={showAddHeaderModal}
                        onClose={() => setShowAddHeaderModal(false)}
                    >
                        <Card>
                            <Text category='h6' style={{ marginBottom: 10 }}>Add Custom Header</Text>
                            <Input
                                label="Header Name"
                                placeholder="X-Custom-Header"
                                value={newHeaderKey}
                                onChangeText={setNewHeaderKey}
                                style={{ marginBottom: 10 }}
                            />
                            <Input
                                label="Header Value"
                                placeholder="header-value"
                                value={newHeaderValue}
                                onChangeText={setNewHeaderValue}
                                style={{ marginBottom: 10 }}
                            />
                            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }}>
                                <Button status='basic' onPress={() => setShowAddHeaderModal(false)}>
                                    Cancel
                                </Button>
                                <Button onPress={addHeader}>
                                    Add Header
                                </Button>
                            </View>
                        </Card>
                    </DMModal>
                </ScrollView>
            </Card>
        );
    };

    return (
        <View style={[{ gap: 15 }, style]}>
            {renderGeneralSettings()}
            {integrations.enabled && (
                <View style={{ flexDirection: 'row', gap: 15 }}>
                    {renderIntegrationsList()}
                    {selectedIntegration && (
                        <View style={{ flex: 3, gap: 15 }}>
                            {renderEndpointsList()}
                            {renderEndpointSettings()}
                        </View>
                    )}
                </View>
            )}

            <IntegrationModal
                visible={showNewIntegrationModal}
                onClose={() => setShowNewIntegrationModal(false)}
                onSave={addNewIntegration}
            />

            <IntegrationModal
                visible={showEditIntegrationModal}
                integration={selectedIntegration ? integrations.custom_integrations[selectedIntegration] : undefined}
                onClose={() => setShowEditIntegrationModal(false)}
                onSave={updateIntegration}
            />

            {/* Add New Endpoint Modal */}
            <DMModal
                visible={showNewEndpointModal}
                onClose={() => setShowNewEndpointModal(false)}
            >
                <Card>
                    <Text category='h6' style={{ marginBottom: 15 }}>Add New Endpoint</Text>
                    <View style={{ gap: 15 }}>
                        <Select
                            label="Event Type"
                            placeholder="Select event type"
                            //@ts-ignore
                            onSelect={(index: IndexPath) => {
                                const eventTypes = Object.values(WEBHOOK_EVENT_TYPE);
                                addNewEndpoint(eventTypes[index.row]);
                            }}
                        >
                            {Object.values(WEBHOOK_EVENT_TYPE).map(type => 
                                <SelectItem key={type} title={type} />
                            )}
                        </Select>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 10 }}>
                            <Button 
                                status='basic' 
                                onPress={() => setShowNewEndpointModal(false)}
                            >
                                Cancel
                            </Button>
                        </View>
                    </View>
                </Card>
            </DMModal>
        </View>
    );
};
