import React, { useState, useEffect, useReducer, useRef } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, CheckBox, Select, SelectItem, IndexPath, Autocomplete, AutocompleteItem } from "@ui-kitten/components";

import * as MemberController from "../../functions/membership.controller";
import * as UserController from "../../functions/user.controller";
import * as StorageController from "../../functions/storageController";
import * as ClientController from "../../functions/client.controller";
import ErrorBoundary from '../ErrorBoundary.component';
import { MemberPermissions, Member } from '../../models/Member.model';
import { User } from '../../models/User.model';
import { Client } from '../../models/Client.model';

const useInputState = (initialValue = '') => {
    const [value, setValue] = React.useState(initialValue);
    return { value, onChangeText: setValue };
};


interface NewMemberModalProps {
    fn_onAddMember: () => void;
    fn_closeModal: () => void;
}

export const NewMemberModal = ({
    fn_onAddMember,
    fn_closeModal,
}: NewMemberModalProps) => {


    //user vars
    const [inputUserName, setInputUserName] = useState("")
    const [inputUserEmail, setInputUserEmail] = useState("")
    const [inputUserPhone, setInputUserPhone] = useState("")
    const [inputUsername, setInputUsername] = useState("")
    const [userFound, setUserFound] = useState(false);
    const [isNewUser, setIsNewUser] = useState(true);
    const [selectedUser, setSelectedUser] = useState(null as User | null);

    const [userSearchResults, setUserSearchResults] = useState([] as User[]);


    //member vars
    const inputMemberTempPassword = useInputState();

    const [checkboxIsAdmin, setCheckboxIsAdmin] = useState(false);
    const [checkboxIsDriver, setCheckboxIsDriver] = useState(false);
    const [checkboxIsOperator, setCheckboxIsOperator] = useState(false);
    const [checkboxIsClient, setCheckboxIsClient] = useState(false);
    const tempPermissions = useRef(new MemberPermissions())
    const [permissions, setPermissions] = useState({});

    const [clients, setClients] = useState([] as any[])
    const [selectedClientIndex, setSelectedClientIndex] = useState(new IndexPath(0) as any)
    const selectedClientIndexRef = useRef(selectedClientIndex)

    const [errorText, setErrorText] = useState('');

    const [showUserSearch, setShowUserSearch] = useState(false)

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();




    useEffect(() => {
        const perms = new MemberPermissions()
        tempPermissions.current = perms
        setPermissions(perms)
    }, [])

    // get clients from selected company
    useEffect(() => {
        const company = StorageController.getCurrentCompany()
        ClientController.getClientsByCompanyId(company._id).then((clients: Client[]) => {
            setClients(clients)
        })
    }, [])

    //function to verify inputs
    const verifyInputs = () => {
        // console.log("Verifying inputs", checkboxIsAdmin, checkboxIsDriver, checkboxIsOperator, tempPermissions.current, inputUserName, inputUserEmail, inputUserPhone, inputUsername, inputMemberName, inputMemberEmail, inputMemberPhone, inputMemberDetails, inputMemberAddress, inputMemberTempPassword, inputMemberUsername)

        // if new user, create password validation
        if (isNewUser) {
            if (inputMemberTempPassword.value === '') {
                setErrorText("Please enter a temporary password")
                return false
            }
            if (inputMemberTempPassword.value.length < 6) {
                setErrorText("Password must be at least 6 characters")
                return false
            }
        }
        if (!userFound) {
            if (inputUserName === '') {
                setErrorText("Please Type a name")
                return false
            }
            if (inputUserEmail === '') {
                setErrorText("Please Type an email")
                return false
            }
            if (inputUserPhone === '') {
                setErrorText("Please Type a phone number")
                return false
            }
            if (inputUsername.length < 5) {
                setErrorText("Name must be at least 5 characters")
                return false
            }
            if (inputUsername === '') {
                setErrorText("Please Type a username")
                return false
            }
        }
        if (!checkboxIsAdmin && !checkboxIsDriver && !checkboxIsOperator && !checkboxIsClient) {
            setErrorText("Please select a role")
            return false
        }
        if (checkboxIsClient && selectedClientIndex === null) {
            setErrorText("Please select a client")
            return false
        }
        // if (inputMemberUsername.value === '') {
        //     setErrorText("Please enter a username")
        //     return false
        // }

        // if (inputMemberName.value === '') {
        //     setErrorText("Please enter a name")
        //     return false
        // }
        // if (inputMemberEmail.value === '') {
        //     setErrorText("Please enter an email")
        //     return false
        // }
        // if (inputMemberPhone.value === '') {
        //     setErrorText("Please enter a phone number")
        //     return false
        // }
        // if (inputMemberDetails.value === '') {
        //     setErrorText("Please enter a details")
        //     return false
        // }
        return true
    }

    const verifyAndSubmit = async () => {
        if (verifyInputs()) {
            let newMember = new Member({
                is_admin: checkboxIsAdmin,
                is_driver: checkboxIsDriver,
                is_operator: checkboxIsOperator,
                is_client: checkboxIsClient,
                permissions: tempPermissions.current,
            })
            if (checkboxIsClient) {
                newMember.client_id = clients[selectedClientIndex]?._id
            }


            if (isNewUser) {
                let newUser = new User({
                    name: inputUserName,
                    email: inputUserEmail,
                    phone: inputUserPhone,
                    username: inputUsername.toLowerCase(),
                    password: inputMemberTempPassword.value
                })
                console.log("New user ", newUser)
                try {

                    const user = await MemberController.createUser(newUser)
                    console.log("User created", user)
                    newMember.user_id = user.id
                    const member = await MemberController.createMember(newMember, user)
                    console.log("New member", newMember)
                    fn_onAddMember()
                    fn_closeModal()
                } catch (error) {
                    setErrorText(`Error: ${error}`)
                }
            }
            else {
                try {
                    if (selectedUser) {
                        const member = await MemberController.createMember(newMember, selectedUser)
                        fn_onAddMember()
                        fn_closeModal()
                    }
                } catch (error) {
                    console.log("Error creating member", error)
                }
            }
        }
        console.log("in verify and submit")
    }


    const renderOption = (user: User, index: number) => (
        <AutocompleteItem
            style={{ flex: 1, flexDirection: 'row', border: '1px solid' }}
            key={user._id}
            title={user.username}
        >
            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                <Text>    </Text>
                <Text>{user.name}</Text>
                <Text>    </Text>
                <Text>{user.email}</Text>
                <Text>    </Text>
                <Text>{user.phone}</Text>
            </Layout>
        </AutocompleteItem>
    );


    const searchUser = (value: string) => {
        if (value.length > 2) {
            UserController.searchUser(value)
                .then((users: User[]) => {
                    // set only the first 5 results
                    // if email, phone or username == value then show
                    users = users.filter((user) => {
                        return user.email == value || user.username == value
                    })
                    if (users.length > 5) {
                        users = users.slice(0, 5)
                        setUserSearchResults(users)
                    }
                    else {
                        setUserSearchResults(users)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
        else {
            setUserSearchResults([])
        }
    }

    const onSelectUser = (userIndex: any) => {
        let user = userSearchResults[userIndex] as User
        console.log("🚀============== ~ file: newMemberModal.component.tsx:232 ~ onSelectUser ~ user🚀==============", user)
        console.log("onSelectUser", user)
        const company = StorageController.getCurrentCompany()
        MemberController.getMembersByCompanyId(company._id).then((members: Member[]) => {
            let member = members.find((member) => {
                console.log("member", member)
                return member.user?._id === user._id
            })
            if (member) {
                setErrorText("This user is already a member")
                return
            }
            else {
                setErrorText("")
                setIsNewUser(false)
                setUserFound(true)
                setSelectedUser(user)
                setInputUserName(user.name)
                setInputUserEmail(user.email)
                setInputUserPhone(user.phone)
                setInputUsername(user.username)
            }
        }
        )
    }


    const clearUser = () => {
        setUserFound(false)
        setSelectedUser(null)
        setInputUserName('')
        setInputUserEmail('')
        setInputUserPhone('')
        setInputUsername('')
        setIsNewUser(true)
    }



    const onSetClientIndex = (index: any) => {
        setSelectedClientIndex(index.row)
        selectedClientIndexRef.current = index.row
    }





    return (
        <Layout style={{ padding: 10, border: '1px solid', width: 700 }}>
            <View style={{}}>

                {showUserSearch ?
                    <Card
                        status='primary'
                        disabled
                        header={(props: any) =>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Text category='s1'>Search For existing User</Text>
                                <Button
                                    appearance='filled'
                                    status='danger'
                                    size='tiny'
                                    onPress={() => { setShowUserSearch(false) }}
                                    accessoryLeft={(props: any) => <Icon {...props} name='close-outline' />}
                                />
                            </View>
                        }
                    >
                        <Layout style={{ flex: 1 }}>
                            <ErrorBoundary>
                                <Autocomplete
                                    placeholder='Search for user'
                                    label='Search for user'
                                    onSelect={(index: any) => {
                                        onSelectUser(index)
                                    }}
                                    onChangeText={(value: string) => {
                                        searchUser(value)
                                    }}
                                    style={styles.input}
                                    status='primary'
                                >
                                    <>
                                        {userSearchResults.length > 0 && userSearchResults.map(renderOption)}
                                    </>
                                </Autocomplete>
                            </ErrorBoundary>
                        </Layout>
                    </Card>
                    :
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1 }}></View>
                        <View style={{ flex: 1 }}>
                            <Button
                                size="small"
                                appearance='outline'
                                status='warning'
                                onPress={() => { setShowUserSearch(true) }}
                                style={{
                                    marginLeft: 5
                                }}
                                accessoryLeft={(props: any) => <Icon {...props} name='search-outline' />}
                            >
                                <Text style={{}}>Search for existing User</Text>
                            </Button>
                        </View>
                        <View style={{ flex: 1 }}></View>
                    </View>
                }
                <Card
                    disabled
                    status='primary'
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            {userFound ?
                                <Text>Edit User</Text> :
                                <Text>Create new User</Text>
                            }
                        </View>
                    }
                >
                    <Layout style={{ flexDirection: 'column', gap: 10 }}>
                        {userFound &&
                            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                                <Button size="small" onPress={() => { clearUser() }}>Clear</Button>
                            </Layout>
                        }
                        <Input
                            disabled={userFound}
                            style={styles.input}
                            status='primary'
                            placeholder='Name'
                            label='Name'
                            value={inputUserName}
                            onChangeText={(value: string) => {
                                setInputUserName(value)
                            }}
                        />
                        <Input
                            disabled={userFound}
                            style={styles.input}
                            status='primary'
                            placeholder='Email'
                            label='Email'
                            value={inputUserEmail}
                            onChangeText={(value: string) => {
                                setInputUserEmail(value)
                            }}
                        />

                        <Input
                            disabled={userFound}
                            style={styles.input}
                            status='primary'
                            placeholder='Phone'
                            label='Phone'
                            value={inputUserPhone}
                            onChangeText={(value: string) => {
                                setInputUserPhone(value)
                            }}
                        />
                        <Input
                            disabled={userFound}
                            style={styles.input}
                            status='primary'
                            placeholder='Username'
                            label='Username'
                            value={inputUsername}
                            onChangeText={(value: string) => {
                                setInputUsername(value)
                            }}
                        />
                        {!userFound &&
                            <View style={{ flexDirection: 'row', gap: 10 }}>
                                <Input
                                    style={styles.input}
                                    status='primary'
                                    placeholder='Temp Password'
                                    label='Member Temp Password'
                                    {...inputMemberTempPassword}
                                />
                            </View>
                        }
                    </Layout>
                </Card>
                <Text>
                    Membership details:
                </Text>
            </View>

            <Layout style={{ flex: 1, flexDirection: 'column' }}>
                <Text>Member Roles:</Text>
                <CheckBox
                    checked={checkboxIsAdmin}
                    onChange={(state: boolean) => setCheckboxIsAdmin(state)}>
                    Admin
                </CheckBox>
                <CheckBox
                    checked={checkboxIsDriver}
                    onChange={(state: boolean) => setCheckboxIsDriver(state)}>
                    Driver
                </CheckBox>
                <CheckBox
                    checked={checkboxIsOperator}
                    onChange={(state: boolean) => setCheckboxIsOperator(state)}>
                    Operator
                </CheckBox>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                    <CheckBox
                        checked={checkboxIsClient}
                        onChange={(state: boolean) => setCheckboxIsClient(state)}>
                        Client
                    </CheckBox>
                    {checkboxIsClient &&
                        <Select
                            style={{ width: '50%' }}
                            placeholder='Select Client'
                            value={clients[selectedClientIndex]?.name || 'Please select a client'}
                            selectedIndex={selectedClientIndex}
                            onSelect={(index: any) => onSetClientIndex(index)}
                        >
                            {clients.map((client, index) => (
                                <SelectItem key={index} title={client.name} />
                            ))}
                        </Select>
                    }
                </View>
                <Text status='warning'>{errorText}</Text>
            </Layout>
            <View style={{ flexDirection: 'row', height: 3, backgroundColor: 'grey', margin: 15 }}></View>
            {/* <Layout style={{ flex: 1, flexDirection: 'column' }}>
                <Text category='label'>Member permissions:</Text>
                <Layout style={{ flexDirection: 'row', flexWrap: 'wrap', width: '100%' }}>
                    {Object.keys(_permissions).map((key, index) => (
                        <Layout key={index} style={{ width: '25%' }}>
                            <CheckBox
                                checked={permissions[key] || false}
                                onChange={(checked) => {
                                    setPermissions({ ...permissions, [key]: checked });
                                    tempPermissions.current[key] = checked
                                    console.log(tempPermissions.current, permissions)
                                }}
                            >
                                {key.replace(/_/g, ' ').split(' ').map((word) => word[0].toUpperCase() + word.slice(1)).join(' ')}
                            </CheckBox>
                        </Layout>
                    ))}
                </Layout>
            </Layout> */}
            <View style={{ flexDirection: 'row', gap: 10, justifyContent: "space-between" }}>
                <Button status='danger' appearance='outline' onPress={() => { fn_closeModal() }}>Cancel</Button>
                <Button status='success' onPress={() => { verifyAndSubmit() }}>Create</Button>
            </View>
        </Layout>
    )

}


const styles = StyleSheet.create({
    input: {
        flex: 1,
        margin: 2,
        // minWidth: 250
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    controlContainer: {
        borderRadius: 4,
        margin: 2,
        padding: 6,
        backgroundColor: '#3366FF',
    },
});
