import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Spinner, Toggle } from '@ui-kitten/components';
import 'react-data-grid/lib/styles.css';
import moment from 'moment';
//@ts-ignore
import DataGrid, { Row, RowRendererProps, SelectColumn, textEditor, useFocusRef, Too, Column, DataGridProps } from 'react-data-grid';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as ClientController from '../../functions/client.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import * as GoogleController from '../../functions/google.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import { ScrollView } from 'react-native-gesture-handler';
//@ts-ignore
import { CSVLink } from "react-csv";
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { LogDetailsCard } from '../job/details/logDetailsCard.component';
import { Job, JobAddress, JobCustomerDetails, JobDetails, JobLocation, JobReport, JobTowingDetails, JobVehicleDetails, LineItem, Service } from '../../models/Job.model'
import { Case } from '../../models/Case.model';
import { Holding } from '../../models/Holding.model';
import { Invoice, InvoiceItem } from '../../models/Invoice.model';
import * as Utils from './invoiceUtils';
import * as Formatters from './invoiceFormatters';
import Tooltip from '../modals/Tooltip';
import { Member } from '../../models/Member.model';
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';
import { FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { HoldingInvoiceRow, JobInvoiceRow } from './invoicingTypes';
import { DescriptionOptions } from './rowCreationUtils';


interface IExportInvoiceModalProps {
    csvExportModalVisible: boolean;
    setExportModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    handleConfirmSubmitCheckbox: (value: any) => void;
    setDueDate: React.Dispatch<React.SetStateAction<Date>>;
    setTaxType: React.Dispatch<React.SetStateAction<string>>;
    setExportServices: React.Dispatch<React.SetStateAction<boolean>>;
    setExportLineItems: React.Dispatch<React.SetStateAction<boolean>>;
    setExportBillBackItems: React.Dispatch<React.SetStateAction<boolean>>;
    setExportCustomerCostItems: React.Dispatch<React.SetStateAction<boolean>>;
    generateCsvData: (jobs: Job[]) => any[];
    transformData: any[];
    transformDataQuickBooks: any[]; // New prop for QuickBooks data
    jobs: Job[];
    sortedData: JobInvoiceRow[] | HoldingInvoiceRow[];
    memoizedInvoiceDueDate: Date;
    memoizedInvoiceTaxType: string;
    exportLineItems: boolean;
    exportBillBackItems: boolean;
    exportCustomerCostItems: boolean;
    exportServices: boolean;
    confirmSetInvoiceSubmit: boolean;
    invoiceTaxTypeRef: React.MutableRefObject<string>;
    invoiceDueDateRef: React.MutableRefObject<Date>;
    exportServicesRef: React.MutableRefObject<boolean>;
    exportLineItemsRef: React.MutableRefObject<boolean>;
    exportBillBackItemsRef: React.MutableRefObject<boolean>;
    exportCustomerCostItemsRef: React.MutableRefObject<boolean>;
    confirmSetInvoiceSubmitRef: React.MutableRefObject<boolean>;
    descriptionOptions: DescriptionOptions;
    setDescriptionOptions: React.Dispatch<React.SetStateAction<DescriptionOptions>>;
}
export const ExportInvoiceModal = ({
    csvExportModalVisible,
    setExportModalVisible,
    handleConfirmSubmitCheckbox,
    setDueDate,
    setTaxType,
    setExportServices,
    setExportLineItems,
    setExportBillBackItems,
    setExportCustomerCostItems,
    generateCsvData,
    transformData,
    transformDataQuickBooks, // New prop for QuickBooks data
    jobs,
    sortedData,
    memoizedInvoiceDueDate,
    memoizedInvoiceTaxType,
    exportLineItems,
    exportBillBackItems,
    exportCustomerCostItems,
    exportServices,
    confirmSetInvoiceSubmit,
    invoiceTaxTypeRef,
    invoiceDueDateRef,
    exportServicesRef,
    exportLineItemsRef,
    exportBillBackItemsRef,
    exportCustomerCostItemsRef,
    confirmSetInvoiceSubmitRef,
    descriptionOptions,
    setDescriptionOptions

}: IExportInvoiceModalProps) => {
    const [showDescriptionOptions, setShowDescriptionOptions] = useState(false);

    const handleExport = (exportType: 'xero' | 'quickbooks') => {
        if (confirmSetInvoiceSubmitRef.current) {
            const job_ids = sortedData?.reduce((ids: string[], item: JobInvoiceRow | HoldingInvoiceRow) => {
                if (!item.isSubRow) {
                    ids.push(item._id as string);
                }
                return ids;
            }, []);
            JobController.submitInvoices(job_ids);
        }
        setExportModalVisible(false);
    };

    return (
        <ErrorBoundary>
            <Modal
                visible={csvExportModalVisible}
                backdropStyle={styles.backdrop}
                onBackdropPress={() => setExportModalVisible(false)}>
                <Card
                    header={() =>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Text style={{}}>Select options to export</Text>
                            <Button
                                size='tiny'
                                status='danger'
                                // appearance='ghost'
                                onPress={() => {
                                    setExportModalVisible(false)
                                }}
                                style={{ marginLeft: 'auto' }}
                            >
                                <Icon name='close-outline' fill='white' width={15} height={15} />
                            </Button>

                        </View>
                    }
                    status='primary'
                    disabled={true}
                >
                    <View style={{ flexDirection: 'row', gap: 5 }}>
                        <View style={{}}>
                            <Card
                                header={() => <Text>Export to Accounting Software</Text>}
                                status='warning'
                                disabled={true}
                                style={{ flexDirection: 'column' }}
                            >
                                {/* Export to Xero format */}
                                <View style={{ flexDirection: 'column', gap: 5 }}>
                                    <Text>Due Date</Text>
                                    <Datepicker
                                        date={memoizedInvoiceDueDate}
                                        onSelect={(nextDate: Date) => {
                                            setDueDate(nextDate)
                                            invoiceDueDateRef.current = nextDate;
                                        }}
                                        max={new Date(2099, 0, 0)}
                                    />
                                    {!StorageController.getAppState().selectedMembership?.is_client &&
                                        <CheckBox checked={confirmSetInvoiceSubmit} onChange={(nextChecked: boolean) => handleConfirmSubmitCheckbox(nextChecked)}>
                                            Set Invoices as Submitted
                                        </CheckBox>
                                    }
                                    <CheckBox
                                        checked={exportServices}
                                        onChange={(nextChecked: boolean) => {
                                            setExportServices(nextChecked);
                                            exportServicesRef.current = nextChecked;
                                        }}>
                                        Export Services
                                    </CheckBox>
                                    <View style={{
                                        gap: 5,
                                        borderTopColor: 'grey',
                                        borderTopWidth: 1,
                                        marginTop: 4
                                    }}>
                                        <CheckBox
                                            checked={exportLineItems}
                                            onChange={(nextChecked: boolean) => {
                                                setExportLineItems(nextChecked);
                                                exportLineItemsRef.current = nextChecked;
                                            }}>
                                            Export Line Items
                                        </CheckBox>
                                        {exportLineItems ?
                                            <View style={{ flexDirection: 'column', paddingLeft: 15 }}>
                                                <CheckBox
                                                    checked={exportBillBackItems}
                                                    onChange={(nextChecked: boolean) => {
                                                        setExportBillBackItems(nextChecked);
                                                        exportBillBackItemsRef.current = nextChecked;
                                                    }}>
                                                    Export Bill Back Items
                                                </CheckBox>
                                                <CheckBox
                                                    checked={exportCustomerCostItems}
                                                    onChange={(nextChecked: boolean) => {
                                                        setExportCustomerCostItems(nextChecked);
                                                        exportCustomerCostItemsRef.current = nextChecked;
                                                    }}>
                                                    Export Customer Cost Items
                                                </CheckBox>
                                            </View>
                                            : <></>
                                        }
                                    </View>
                                    <Text>Tax Type</Text>
                                    <Select
                                        placeholder='Select Tax Type'
                                        value={memoizedInvoiceTaxType}
                                        onSelect={(nextValue: any) => {
                                            let taxTypes = ["GST on Income", "GST on Expenses", "No GST"];
                                            let selectedTaxType = taxTypes[nextValue.row];
                                            invoiceTaxTypeRef.current = selectedTaxType;
                                            setTaxType(selectedTaxType);
                                        }}>
                                        <SelectItem title='GST on Income' />
                                        <SelectItem title='GST on Expenses' />
                                        <SelectItem title='No GST' />
                                    </Select>
                                </View>
                                <View style={{ width: '100%', height: 1, margin: 20 }}></View>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Button
                                        appearance='outline'
                                        status='success'
                                        onPress={() => {}}>
                                        <CSVLink 
                                            data={transformData} 
                                            onClick={() => handleExport('xero')} 
                                            filename={"invoices_xero.csv"}
                                        >
                                            <Text>Export To Xero CSV</Text>
                                        </CSVLink>
                                    </Button>
                                    <Button
                                        appearance='outline'
                                        status='info'
                                        onPress={() => {}}>
                                        <CSVLink 
                                            data={transformDataQuickBooks} 
                                            onClick={() => handleExport('quickbooks')} 
                                            filename={"invoices_quickbooks.csv"}
                                        >
                                            <Text>Export To QuickBooks CSV</Text>
                                        </CSVLink>
                                    </Button>
                                </View>

                            </Card>
                        </View>
                        <View style={{}}>
                            <View style={{ flexDirection: 'column' }}>
                                <Card
                                    header={() => <Text>Export All</Text>}
                                    status='info'
                                    disabled={true}
                                >
                                    <Button
                                        appearance='outline'
                                        status='success'
                                        onPress={() => {
                                        }}>
                                        <CSVLink data={generateCsvData(jobs)} onClick={() => {
                                            setExportModalVisible(false)
                                        }}
                                            filename={new Date().toLocaleDateString("en-AU", {
                                                year: "numeric",
                                                month: "short",
                                                day: "2-digit",
                                            }) + "-export.csv"}
                                        >
                                            <Text>Export All</Text>
                                        </CSVLink>
                                    </Button>
                                </Card>
                            </View>
                        </View>
                    </View>
                    <View style={{ flexDirection: 'column', marginTop: 10 }}>
                        <CheckBox
                            checked={showDescriptionOptions}
                            onChange={(nextChecked: boolean) => setShowDescriptionOptions(nextChecked)}
                        >
                            Show description options
                        </CheckBox>
                        
                        {showDescriptionOptions && (
                            <View style={{ marginLeft: 20, marginTop: 10 }}>
                                <CheckBox
                                    checked={descriptionOptions.includeServiceNames}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeServiceNames: nextChecked }))}
                                >
                                    Include Service Names
                                </CheckBox>
                                <CheckBox
                                    checked={descriptionOptions.includeVehicleDetails}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeVehicleDetails: nextChecked }))}
                                >
                                    Include Vehicle Details
                                </CheckBox>
                                <CheckBox
                                    checked={descriptionOptions.includeCustomerDetails}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeCustomerDetails: nextChecked }))}
                                >
                                    Include Customer Details
                                </CheckBox>
                                <CheckBox
                                    checked={descriptionOptions.includeDriverName}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeDriverName: nextChecked }))}
                                >
                                    Include Driver Name
                                </CheckBox>
                                <CheckBox
                                    checked={descriptionOptions.includeComments}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeComments: nextChecked }))}
                                >
                                    Include Comments
                                </CheckBox>
                                <CheckBox
                                    checked={descriptionOptions.includeClientRate}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeClientRate: nextChecked }))}
                                >
                                    Include Client Rate
                                </CheckBox>
                                <CheckBox
                                    checked={descriptionOptions.includeAddressDetails}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeAddressDetails: nextChecked }))}
                                >
                                    Include Address Details
                                </CheckBox>
                                <CheckBox
                                    checked={descriptionOptions.includeTowingDetails}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeTowingDetails: nextChecked }))}
                                >
                                    Include Towing Details
                                </CheckBox>
                                <CheckBox
                                    checked={descriptionOptions.includeEquipmentDetails}
                                    onChange={(nextChecked: boolean) => setDescriptionOptions(prev => ({ ...prev, includeEquipmentDetails: nextChecked }))}
                                >
                                    Include Equipment Details
                                </CheckBox>
                            </View>
                        )}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button
                            appearance='ghost'
                            status='danger'
                            onPress={() => setExportModalVisible(false)}>
                            Cancel
                        </Button>
                    </View>
                </Card>
            </Modal>
        </ErrorBoundary>
    )
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 5,
        borderBottomWidth: 1,
        borderBottomColor: 'grey'
    }
});