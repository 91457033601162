import React, { useState, useEffect, useMemo } from 'react';
import { StyleSheet, View, Animated } from 'react-native';
import { Layout, Text, Icon, Button } from '@ui-kitten/components';
import * as StorageController from '../../functions/storageController';
import { Company } from '../../models/Company.model';
import { PartsNetworkSettings } from './PartsNetworkSettings';
import { VendorList } from './VendorList';
import { OrderList } from './OrderList';
import { OrderDetail } from './OrderDetail';
import { ManageSales } from './ManageSales';
import { VendorStore } from './VendorStore';
import { Cart } from './Cart';
import { InventoryItem } from '../../models/Inventory.model';
import { Order } from '../../models/Order.model';
import { useAppStateChange } from '../../hooks/appStateChange.hook';
import { IAction } from '../../functions/update.controller';
import * as OrderController from '../../functions/order.controller';
import { useNotification } from '../../context/notifications.context';

export interface CartItem extends InventoryItem {
    quantity: number;
    vendorId: string;
    vendorName: string;
}

export const PartsNetworkContainerScreen = ({ navigation }: any) => {
    const [currentCompany, setCurrentCompany] = useState<Company | null>(null);
    const [activeTab, setActiveTab] = useState('vendors');
    const [selectedVendor, setSelectedVendor] = useState<Company | null>(null);
    const [cart, setCart] = useState<CartItem[]>([]);
    const [cartVisible, setCartVisible] = useState(false);
    const slideAnim = useState(new Animated.Value(400))[0]; // Assuming 400 is the width of the cart
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
    const [orders, setOrders] = useState<Order[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { showNotification } = useNotification();
    useEffect(() => {
        onLoad();
    }, []);

    useEffect(() => {
        if (currentCompany && activeTab !== 'vendors' && activeTab !== 'settings') {
            fetchOrders();
        }
    }, [currentCompany, activeTab]);

    const onLoad = () => {
        const company = StorageController.getCurrentCompany();
        setCurrentCompany(new Company(company));
        setActiveTab('vendors');
        setCart([]);
        setSelectedVendor(null);
        setCartVisible(false);
    }

    const fetchOrders = async () => {
        if (!currentCompany || !currentCompany._id) return;

        setIsLoading(true);
        try {
            let fetchedOrders;
            const companyType = activeTab === 'vendorOrders' ? 'vendor' : 'client';
            if (activeTab === 'history') {
                fetchedOrders = await OrderController.getOrdersByCompany(companyType, currentCompany._id);
            } else {
                fetchedOrders = await OrderController.getOrdersByCompany(companyType, currentCompany._id);
            }
            if (fetchedOrders) {
                setOrders(fetchedOrders.orders);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCompanyChangeEvent = (action: IAction) => {
        onLoad()
    };

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: (action: IAction) => {
            handleCompanyChangeEvent(action)
        },
    })

    const handleBackToVendors = () => {
        setSelectedVendor(null);
    };

    const addToCart = (item: InventoryItem, quantity: number, vendorId: string, vendorName: string) => {
        setCart((prevCart: CartItem[]) => {
            const existingItem = prevCart.find((cartItem: CartItem) => cartItem._id === item._id);
            if (existingItem) {
                return prevCart.map((cartItem: CartItem) =>
                    cartItem._id === item._id
                        ? { ...cartItem, quantity: cartItem.quantity + quantity } as CartItem
                        : cartItem
                );
            } else {
                const newCartItem: CartItem = {
                    ...item,
                    quantity,
                    vendorId,
                    vendorName,
                    toLineItem: item.toLineItem // Assuming InventoryItem has this method
                };
                return [...prevCart, newCartItem];
            }
        });
    };

    const updateCartItemQuantity = (itemId: string, newQuantity: number) => {
        setCart((prevCart: CartItem[]) =>
            prevCart.map((item: CartItem) =>
                item._id === itemId
                    ? {
                        ...item,
                        quantity: newQuantity,
                        vendorId: selectedVendor?._id ?? item.vendorId,
                        vendorName: selectedVendor?.name ?? item.vendorName,
                    } as CartItem
                    : item
            )
        );
    };

    const removeFromCart = (itemId: string) => {
        setCart((prevCart: CartItem[]) => prevCart.filter((item: CartItem) => item._id !== itemId));
    };

    const toggleCart = () => {
        setCartVisible(!cartVisible);
        Animated.timing(slideAnim, {
            toValue: cartVisible ? 400 : 0,
            duration: 300,
            useNativeDriver: true,
        }).start();
    };

    const renderSettingsButton = () => (
        <View style={{ flexDirection: 'row' }}>
            <View style={styles.cartButtonContainer}>
                <Button
                    appearance="ghost"
                    accessoryLeft={(props) => <Icon {...props} name="shopping-cart-outline" />}
                    onPress={toggleCart}
                />
                {cart.length > 0 && (
                    <View style={styles.cartBadge}>
                        <Text style={styles.cartBadgeText}>{cart.length}</Text>
                    </View>
                )}
            </View>
            <Button
                appearance="ghost"
                accessoryLeft={(props) => <Icon {...props} name="settings-2-outline" />}
                onPress={() => setActiveTab('settings')}
            />
        </View>
    );

    const handleOrderUpdated = () => {
        setSelectedOrder(null);
        fetchOrders(); // Refresh the order list
    };

    const renderContent = useMemo(() => {
        switch (activeTab) {
            case 'vendors':
                return selectedVendor ? (
                    <VendorStore
                        vendor={selectedVendor}
                        onBack={handleBackToVendors}
                        addToCart={(item, quantity) => addToCart(item, quantity, selectedVendor._id, selectedVendor.name)}
                        cart={cart}
                        clientCompany={currentCompany ?? new Company({})}
                    />
                ) : (
                    <VendorList onSelectVendor={setSelectedVendor} />
                );
            case 'myOrders':
            case 'vendorOrders':
            case 'history':
                return (
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                        <View style={{ flex: 1 }}>
                            <OrderList
                                orders={orders}
                                isLoading={isLoading}
                                currentCompany={currentCompany ?? new Company({})}
                                onSelectOrder={setSelectedOrder}
                                selectedOrderId={selectedOrder?._id}
                                activeTab={activeTab}
                            />
                        </View>
                        {selectedOrder && (
                            <View style={{ flex: 2 }}>
                                <OrderDetail
                                    order={selectedOrder}
                                    currentCompany={currentCompany ?? new Company({})}
                                    onOrderUpdated={handleOrderUpdated}
                                    onBack={() => setSelectedOrder(null)}
                                    activeTab={activeTab}
                                />
                            </View>
                        )}
                    </View>
                )
            case 'sales':
                return <ManageSales />;
            case 'settings':
                return <PartsNetworkSettings company={currentCompany} />;
            default:
                return null;
        }
    }, [activeTab, selectedOrder, orders, isLoading, currentCompany, selectedVendor, cart]);



    const renderTabs = () => {
        const tabs = [
            { key: 'vendors', title: 'Vendors' },
            { key: 'myOrders', title: 'Incoming Orders' },
            { key: 'history', title: 'Order History' },
            { key: 'sales', title: 'Manage Sales' },
        ];

        // Add the "Outgoing Orders" tab only if Stripe Connect is set up
        if (currentCompany?.settings.payment_settings.stripe.stripe_connect_account_id) {
            tabs.splice(2, 0, { key: 'vendorOrders', title: 'Outgoing Orders' });
        }

        return tabs.map((tab) => (
            <Button
                key={tab.key}
                style={styles.tabButton}
                appearance={activeTab === tab.key ? 'filled' : 'outline'}
                onPress={() => {
                    setActiveTab(tab.key);
                    setSelectedOrder(null);
                }}
            >
                {tab.title}
            </Button>
        ));
    };

    const handleCheckoutComplete = (order: Order) => {
        setCart([]);
        showNotification("Order Created", "Order created successfully", () => {
            setActiveTab('myOrders');
        })
    };

    const memoizedCart = useMemo(() => {

        return (
            <Animated.View style={[
                styles.cartContainer,
                {
                    transform: [{ translateX: slideAnim }],
                    height: '100%'
                },
            ]}>
                <Cart
                    items={cart}
                    updateQuantity={updateCartItemQuantity}
                    removeItem={removeFromCart}
                    onClose={toggleCart}
                    currentCompany={currentCompany ?? new Company({})}
                    onCheckoutComplete={handleCheckoutComplete}
                />
            </Animated.View>
        )
    }, [cart, slideAnim, toggleCart]);

    return (
        <View style={{ flex: 1 }}>
            <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', padding: 5, alignItems: 'center' }}>
                <Text category='h6'>Parts Network</Text>
                {renderSettingsButton()}
            </Layout>
            <Layout style={styles.container}>
                <View style={styles.tabContainer}>
                    {renderTabs()}
                </View>
                <View style={styles.content}>
                    {renderContent}
                </View>
            </Layout>
            {memoizedCart}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    tabContainer: {
        width: 200,
        padding: 16,
        borderRightWidth: 1,
        borderRightColor: '#ccc',
    },
    tabButton: {
        marginBottom: 8,
    },
    content: {
        flex: 1,
    },
    cartContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        width: 400,
        backgroundColor: 'white',
        elevation: 5,
        shadowColor: '#000',
        shadowOffset: { width: -2, height: 0 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    cartButtonContainer: {
        position: 'relative',
    },
    cartBadge: {
        position: 'absolute',
        top: -5,
        right: -5,
        backgroundColor: 'red',
        borderRadius: 10,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    cartBadgeText: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
    },
});
