import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions,
    ActivityIndicator
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card,
    useTheme,
    //@ts-ignore
    CheckBox,
    Select,
    SelectItem,
    //@ts-ignore
    IndexPath,
    Spinner
} from "@ui-kitten/components";
import * as StorageController from "../../functions/storageController"
import * as JobController from "../../functions/job.controller"
import * as GoogleController from '../../functions/google.controller';
import * as RouteController from "../../functions/route.controller"
import * as InventoryController from "../../functions/Inventory.controller"
import { Job, JobAddress, JOB_STATUS } from "../../models/Job.model";
import { Route, RouteDetails, ROUTE_STATUS, RouteLeg, RouteLocation, ROUTE_LOCATION_TYPE, ROUTE_START_END_OPTION } from '../../models/Route.model';
import { RoutePlanningMap } from './routePlanningMap.component';
import { JobReportModal } from '../dashboardComponents/jobReportModal.component';
import { ConfirmRemoveRouteLocationModal } from './ConfirmRemoveRouteLocationModal';
import { CreateJobForRoute } from './createJobForRoute.component';
import { RouteSummary } from './RouteSummary';
import Toast from 'react-native-toast-message';
import { RoutesList } from './RoutesList';
import { SelectedLocations } from './SelectedLocations';
import { RouteOptimizationRequest, VroomSummary, RouteGroup } from '../../types/route.types';
import { CompanySavedAddress } from '../../models/Company.model';
import { RouteConfigPanel } from './RouteConfigPanel';
import { RouteActions } from './RouteActions';

import { AvailableJobs } from './AvailableJobs';
import { VroomSummaryDisplay } from './VroomSummaryDisplay';
const { width, height } = Dimensions.get('window');


export enum ROUTE_ACTION_TYPES {
    GET_CURRENT_ROUTE = 'GET_CURRENT_ROUTE',
    GET_OPTIMIZED_ROUTE = 'GET_OPTIMIZED_ROUTE',
}


export const CreateRouteContainer = ({ onClose }: any) => {
    //#region State Declarations
    const [routeState, setRouteState] = useState<Route>(new Route({}));
    const [selectedRouteLocation, setSelectedRouteLocation] = useState<RouteLocation | null>(null);
    const [selectedRouteLocationIndex, setSelectedRouteLocationIndex] = useState<number>(0);
    const [availableJobs, setAvailableJobs] = useState<Job[]>([]);
    const [showJobReportModal, setShowJobReportModal] = useState(false);
    const routeStateRef = useRef(routeState);
    const [selectedLegIndex, setSelectedLegIndex] = useState<number | null>(null);
    const [routeAction, setRouteAction] = useState<ROUTE_ACTION_TYPES>(ROUTE_ACTION_TYPES.GET_OPTIMIZED_ROUTE);
    const theme = useTheme() as any;
    const [showConfirmRemoveRouteLocationModal, setShowConfirmRemoveRouteLocationModal] = useState(false);
    const [showCreateJobForRouteModal, setShowCreateJobForRouteModal] = useState(false);
    const [selectedMarkerIndex, setSelectedMarkerIndex] = useState<number | null>(null);
    const [isRouteCreated, setIsRouteCreated] = useState(false);

    const [selectedJobReportModalJob, setSelectedJobReportModalJob] = useState<Job | null>(null);

    const [routeLocations, setRouteLocations] = useState<RouteLocation[]>([]);
    const [optimizedRoutes, setOptimizedRoutes] = useState<Route[]>([]);
    const [numberOfRoutes, setNumberOfRoutes] = useState(1);
    const [numberOfRoutesError, setNumberOfRoutesError] = useState<string | null>(null);
    const [showOptimizedRoutes, setShowOptimizedRoutes] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState<Route | null>(null);
    const [selectedRouteIndex, setSelectedRouteIndex] = useState<number | null>(null);
    const [customStartAddress, setCustomStartAddress] = useState<any | null>(null);
    const [customEndAddress, setCustomEndAddress] = useState<any | null>(null);
    const [customStartPin, setCustomStartPin] = useState<CompanySavedAddress | null>(null);
    const [customEndPin, setCustomEndPin] = useState<CompanySavedAddress | null>(null);

    const [isJobListCollapsed, setIsJobListCollapsed] = useState(false);
    const [isSelectedLocationsCollapsed, setIsSelectedLocationsCollapsed] = useState(false);
    const jobListWidth = useRef(new Animated.Value(width * 0.2)).current;
    const jobListHeight = useRef(new Animated.Value(height * 0.7)).current;
    const configPanelLeft = useRef(new Animated.Value(width * 0.22)).current;
    const selectedLocationsLeft = useRef(new Animated.Value(width * 0.22)).current;
    const optimizedRoutesPanelLeft = useRef(new Animated.Value(width * 0.22)).current;
    const selectedLocationsHeight = useRef(new Animated.Value(height * 0.5)).current;
    const [routeStartEndOption, setRouteStartEndOption] = useState<ROUTE_START_END_OPTION>(ROUTE_START_END_OPTION.ROUND_TRIP);

    const [plannedStartTime, setPlannedStartTime] = useState<Date | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [vroomSummary, setVroomSummary] = useState<VroomSummary | null>(null);
    const [routeGroups, setRouteGroups] = useState<RouteGroup[]>([
        { numberOfVehicles: 1, startEndOption: ROUTE_START_END_OPTION.ROUND_TRIP }
    ]);
    const [companySavedAddresses, setCompanySavedAddresses] = useState<CompanySavedAddress[]>([]);
    //#endregion

    //#region Effects
    useEffect(() => {
        load()
    }, []);
    //#endregion

    //#region Data Loading and Initialization
    const load = async () => {
        const company = StorageController.getCurrentCompany();
        const r = new Route({ ...routeState, company_id: company._id, details: new RouteDetails() });
        setRouteState(r);
        const incompleteJobsWithoutRoute = await RouteController.getAvailableJobsForRoute(company._id);
        setAvailableJobs(incompleteJobsWithoutRoute);

        const savedAddresses = company.settings.address_book
        setCompanySavedAddresses(savedAddresses);
    }
    //#endregion

    const onSetRouteState = (newState: Route, caller: string = 'unknown') => {
        newState = new Route(newState);
        // updated the job ids from the locations
        const jobIds = newState.details.locations.map(location => location.job_id);
        newState.job_ids = jobIds;
        routeStateRef.current = newState;
        newState.details.legs.forEach((leg: RouteLeg) => {
        });
        // setRouteState(newState);
    }

    //#region Route Generation and Optimization
    const generateRoute = async (action: ROUTE_ACTION_TYPES = routeAction) => {
        setIsLoading(true);
        setSelectedRoute(null);
        setSelectedRouteIndex(null);

        if (routeAction === ROUTE_ACTION_TYPES.GET_OPTIMIZED_ROUTE) {
            try {
                const optimizationRequest: RouteOptimizationRequest = {
                    locations: routeLocations,
                    routeGroups: routeGroups,
                    customStartAddress: customStartAddress,
                    customEndAddress: customEndAddress,
                    numberOfRoutes: numberOfRoutes
                };
                const result = await RouteController.calculateOptimisedRoute(optimizationRequest);
                if (result.routes.length > 0) {
                    setOptimizedRoutes(result.routes);
                    setShowOptimizedRoutes(true);
                    if (result.summary) {
                        setVroomSummary(result.summary);
                        setShowVroomSummary(true);
                    }
                }
                toggleJobList(true);
            } catch (error) {
                console.error("Error generating route:", error);
                Toast.show({
                    type: 'error',
                    text1: 'Error',
                    text2: 'Failed to generate route',
                });
            } finally {
                setIsLoading(false);
            }
        }
        else {
            getRouteDirections();
        }
    };

    const getRouteDirections = async (locations: RouteLocation[] = routeLocations) => {
        try {
            // create a temp route with the locations
            const tempRoute = new Route({ details: { locations: locations } as RouteDetails });
            console.log("🚀============== ~ file: createRouteContainer.component.tsx:482 ~ getRouteDirections ~ tempRoute🚀==============", tempRoute)
            const result = await RouteController.calculateOrderedRoute(tempRoute);

            if (result.details.legs.length > 0) {
                const newRoute = new Route({
                    ...result,
                    details: {
                        ...result.details,
                        overview_polyline: result.details.overview_polyline
                    } as RouteDetails
                });
                setOptimizedRoutes([newRoute]);
                setShowOptimizedRoutes(true);
                console.log("🚀============== ~ file: createRouteContainer.component.tsx:496 ~ getRouteDirections ~ newRoute🚀==============", newRoute)
            }
        } catch (error) {
            console.error("Error getting route directions:", error);
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Failed to get route directions',
            });
        }
    };


    const [roundTripAddress, setRoundTripAddress] = useState<CompanySavedAddress | null>(null);
    const [startAddress, setStartAddress] = useState<CompanySavedAddress | null>(null);
    const [endAddress, setEndAddress] = useState<CompanySavedAddress | null>(null);

    const onSelectRoundTripAddress = (address: CompanySavedAddress) => {
        setCustomStartPin(address);
        setRoundTripAddress(address);
        console.log("🚀============== ~ file: createRouteContainer.component.tsx:187 ~ onSelectRoundTripAddress ~ address🚀==============", address)
    }

    const onSelectStartAddress = (address: CompanySavedAddress) => {
        setCustomStartPin(address);
        setStartAddress(address);
    }

    const onSelectEndAddress = (address: CompanySavedAddress) => {
        setCustomEndPin(address);
        setEndAddress(address);
    }


    const getOptimizeRoute = async () => {
        try {
            const optimizationRequest: RouteOptimizationRequest = {
                locations: routeLocations,
                routeGroups: routeGroups,
                // startAddress: startAddress,
                // endAddress: endAddress,
                // roundTripAddress: roundTripAddress,
                // startLocationIndex: routeStartLocationIndex,
                // endLocationIndex: routeEndLocationIndex,
                // numberOfRoutes: numberOfRoutes,
                customStartAddress: customStartAddress,
                customEndAddress: customEndAddress,
                numberOfRoutes: numberOfRoutes
            };
            const result = await RouteController.calculateOptimisedRoute(optimizationRequest);
            if (result.routes.length > 0) {
                setOptimizedRoutes(result.routes);
                setShowOptimizedRoutes(true);
                if (result.summary) {
                    setVroomSummary(result.summary);
                    setShowVroomSummary(true);
                }
            }
        } catch (error) {
            console.error("Error optimizing route:", error);
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Failed to optimize route',
            });
        }
    };
    //#endregion

    const onSelectLocationFromMap = (location: RouteLocation, routeIndex: number) => {
        setSelectedRouteIndex(routeIndex);
        setSelectedRoute(optimizedRoutes[routeIndex]);
        setSelectedRouteLocationIndex(location.index);
        setSelectedRouteLocation(location);
    }

    //#region EVENTS
    /**
     * Remove location from locations
     * @param index
     * @returns
     */
    const onRemoveLocation = (index: number) => {
        const updatedLocations = [...routeLocations];
        updatedLocations.splice(index, 1);
        setRouteLocations(updatedLocations);
    }

    const isJobSelected = (job: Job) => {
        return routeLocations.some(location => location.job_id === job._id);
    }

    const getJobOrderBadge = (job: Job | null) => {
        if (!job) return null;
        const indices = routeLocations
            .filter(location => location.job_id === job._id)
            .map(location => location.index + 1);
        return indices.length > 0 ? indices.join(', ') : null;
    }

    const onSelectJob = (jobs: Job | Job[]) => {
        const jobsArray = Array.isArray(jobs) ? jobs : [jobs];
        const newLocations: RouteLocation[] = [];

        jobsArray.forEach(job => {
            const existingLocations = routeLocations.filter(loc => loc.job_id === job._id);
            if (existingLocations.length > 0) {
                // Remove all locations associated with this job
                setRouteLocations(prevLocations => prevLocations.filter(loc => loc.job_id !== job._id));
            } else {
                // Add locations for this job
                if (job.details.options.towing_job_options && job.details.towing_details) {
                    // For towing jobs, add a location for each towing location
                    job.details.towing_details.towing_locations.forEach((towingLocation: any, index: number) => {
                        const towingLocations = job.details.towing_details?.towing_locations || [];
                        newLocations.push(new RouteLocation({
                            index: routeLocations.length + newLocations.length,
                            job_id: job._id,
                            job_location_index: index,
                            name: `${job.details.customer_details.name || `Job ${job._id}`} - ${towingLocation.name}`,
                            address: towingLocation.address,
                            location: towingLocation.location,
                            is_towing_location: true,
                            route_location_type: index === 0 ? ROUTE_LOCATION_TYPE.PICKUP : index === towingLocations.length - 1 ? ROUTE_LOCATION_TYPE.DELIVERY : ROUTE_LOCATION_TYPE.INTERMEDIATE
                        }));
                    });
                } else {
                    // For non-towing jobs, add a single location
                    newLocations.push(new RouteLocation({
                        index: routeLocations.length + newLocations.length,
                        job_id: job._id,
                        job_location_index: 0,
                        name: job.details.customer_details.name || `Job ${job._id}`,
                        address: job.details.address,
                        location: job.details.location,
                        is_towing_location: false
                    }));
                }
            }
        });

        if (newLocations.length > 0) {
            setRouteLocations(prevLocations => [...prevLocations, ...newLocations]);
        }
    }
    //#endregion


    const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState<number | null>(null);

    const onMapClick = async (event: any) => {
        try {
            const location = event.latLng.toJSON();
            const addressObj = await GoogleController.getAddressFromLatLng(location.lat, location.lng);
            console.log("🚀============== ~ file: createRouteContainer.component.tsx:253 ~ onMapClick ~ addressObj🚀==============", addressObj)
        } catch (e) {
            console.error("Error in onMapClick:", e);
        }
    };

    const onDragMarker = async (location: any, index: number) => {
        try {
            const locations = await GoogleController.getAddressFromLatLngAllResults(location.lat, location.lng);
            if (locations && locations.length > 0) {
                const address = new JobAddress(locations[0].formatted_address);
                const updatedLocations = [...routeState.details.locations];
                updatedLocations[index].location = location;
                updatedLocations[index].address = address;
                updateRouteState({ details: { locations: updatedLocations } as RouteDetails }, 'onDragMarker');

                generateRoute()
            }
        } catch (e) {
            console.error("Error in onDragMarker:", e);
        }
    };

    const onJobCreated = (job: Job) => {
        setShowCreateJobForRouteModal(false);
        // add the job to the available jobs
        setAvailableJobs([job, ...availableJobs]);
        // select the job
        onSelectJob(job);
    }

    const updateRouteState = (updates: Partial<Route>, caller: string = 'unknown') => {
        const newState = {
            ...routeState,
            details: new RouteDetails({
                ...routeState.details,
                ...updates.details
            })
        };
        onSetRouteState(newState as Route, caller);
    };

    //#region UI Interaction Handlers
    const onCreateRoutes = async () => {
        try {
            const nonCreatedRoutes = optimizedRoutes.filter(route => !route._id);
            const createdRoutes = await Promise.all(nonCreatedRoutes.map(route => RouteController.createRoute(route)));
            const successfulRoutes = createdRoutes.filter(route => route !== null) as Route[];

            if (successfulRoutes.length > 0) {
                // Assign jobs to routes
                for (const route of successfulRoutes) {
                    await JobController.setRouteIdForJobs(route.job_ids, route._id as string);
                }

                setOptimizedRoutes(successfulRoutes);
                setIsRouteCreated(true);
                Toast.show({
                    type: 'success',
                    text1: 'Success',
                    text2: `${successfulRoutes.length} route(s) created successfully`,
                });
            } else {
                throw new Error('No routes were created');
            }
        } catch (error) {
            console.error("Error creating routes:", error);
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Failed to create routes',
            });
        }
    };

    const onCreateRoute = async (route: Route) => {
        console.log("🚀============== ~ file: createRouteContainer.component.tsx:360 ~ onCreateRoute ~ route🚀==============", route)
        try {
            if (route._id) {
                // Route already created
                Toast.show({
                    type: 'info',
                    text1: 'Route already created',
                    text2: 'Route already created',
                });
                return;

            }
            const createdRoute = await RouteController.createRoute(route);
            if (createdRoute) {
                // Assign jobs to route
                await JobController.setRouteIdForJobs(createdRoute.job_ids, createdRoute._id as string);

                // Update the optimizedRoutes state
                setOptimizedRoutes(prevRoutes =>
                    prevRoutes.map((r, index) => index === selectedRouteIndex ? createdRoute : r)
                );

                // Update the selectedRoute
                setSelectedRoute(createdRoute);

                setIsRouteCreated(true);
                Toast.show({
                    type: 'success',
                    text1: 'Success',
                    text2: 'Route created successfully',
                });
            } else {
                throw new Error('Failed to create route');
            }
        } catch (error) {
            console.error("Error creating route:", error);
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Failed to create route',
            });
        }
    };

    const onUpdateRouteName = (index: number, newName: string) => {
        const updatedRoutes = [...optimizedRoutes];
        updatedRoutes[index].name = newName;
        setOptimizedRoutes(updatedRoutes);
    };

    const onAssignDriverToRoute = async (driverId: string) => {
        const company = StorageController.getAppState().selectedCompany;
        const memberId = driverId;
        const jobIds = routeState.details.locations.map((loc: any) => loc.job_id).filter((id: string) => id);
        if (!company) {
            console.error("Company not found");
            return;
        }
        const companyId = company._id;
        const routeResult = await RouteController.assignDriverToRoute(routeState._id as string, memberId);
        const jobResult = await JobController.assignMultipleJobsToMember(jobIds, memberId, companyId);
        return { route: routeResult, jobs: jobResult };
    }

    const onSelectStartEndOption = (option: ROUTE_START_END_OPTION) => {
        const updatedDetails = { ...routeState.details, startEndOption: option };
        setCustomStartPin(null);
        setCustomEndPin(null);
        if (option === ROUTE_START_END_OPTION.DEFAULT) {
            updatedDetails.startLocationIndex = null;
            updatedDetails.endLocationIndex = null;
            setCustomStartAddress(null);
            setCustomEndAddress(null);
        } else if (option === ROUTE_START_END_OPTION.ROUND_TRIP) {
            updatedDetails.startLocationIndex = 0;
            updatedDetails.endLocationIndex = 0;
            setCustomStartAddress(null);
            setCustomEndAddress(null);
        } else if (option === ROUTE_START_END_OPTION.START_FIRST_END_LAST) {
            updatedDetails.startLocationIndex = 0;
            updatedDetails.endLocationIndex = routeState.details.locations.length - 1;
            setCustomStartAddress(null);
            setCustomEndAddress(null);
        }
        else if (option === ROUTE_START_END_OPTION.CUSTOM) {
            updatedDetails.startLocationIndex = 0;
            updatedDetails.endLocationIndex = routeState.details.locations.length - 1;
            setCustomStartAddress(null);
            setCustomEndAddress(null);
        }
        setRouteStartEndOption(option);
        // Don't reset custom addresses for CUSTOM option

        // updateRouteState({ details: updatedDetails as RouteDetails }, 'onSelectStartEndOption');
    };


    const handleUpdatePlannedStartTime = (time: Date) => {
        setPlannedStartTime(time);
        // updateRouteState({ planned_start_time: time.getTime() }, 'handleUpdatePlannedStartTime');
    };


    //#region Animations

    const toggleJobList = (collapse?: boolean) => {
        const newCollapsedState = collapse !== undefined ? collapse : !isJobListCollapsed;
        setIsJobListCollapsed(newCollapsedState);

        Animated.parallel([
            Animated.timing(jobListWidth, {
                toValue: newCollapsedState ? 50 : width * 0.2,
                duration: 300,
                useNativeDriver: false,
            }),
            Animated.timing(jobListHeight, {
                toValue: newCollapsedState ? 100 : height * 0.7,
                duration: 300,
                useNativeDriver: false,
            }),
            Animated.timing(configPanelLeft, {
                toValue: newCollapsedState ? 60 : width * 0.22,
                duration: 300,
                useNativeDriver: false,
            }),
            Animated.timing(selectedLocationsLeft, {
                toValue: newCollapsedState ? 60 : width * 0.22,
                duration: 300,
                useNativeDriver: false,
            }),
            Animated.timing(optimizedRoutesPanelLeft, {
                toValue: newCollapsedState ? 60 : width * 0.22,
                duration: 300,
                useNativeDriver: false,
            }),
        ]).start();
    };

    const toggleSelectedLocations = () => {
        setIsSelectedLocationsCollapsed(!isSelectedLocationsCollapsed);
        Animated.timing(selectedLocationsHeight, {
            toValue: isSelectedLocationsCollapsed ? height * 0.5 : 100,
            duration: 300,
            useNativeDriver: false,
        }).start();
    };

    const onClickMarkerEvent = (routeLocation: RouteLocation, routeIndex: number) => {
        onSelectLocationFromMap(routeLocation, routeIndex);
    }
    //#endregion

    //#region Rendering
    const MemoizedRouteMap = useMemo(() => {
        const routesToDisplay = showOptimizedRoutes ? optimizedRoutes : [new Route({ details: { locations: routeLocations } as RouteDetails })];
        return (
            <RoutePlanningMap
                routes={optimizedRoutes}
                selectedRoute={selectedRoute}
                selectedMarkerIndex={selectedMarkerIndex}
                setSelectedMarkerIndex={setSelectedMarkerIndex}
                highlightedLegIndex={selectedLegIndex}
                setHighlightedLegIndex={setSelectedLegIndex}
                hoveredMarkerIndex={hoveredMarkerIndex}
                setHoveredMarkerIndex={setHoveredMarkerIndex}
                onMapClick={onMapClick}
                onDragMarker={onDragMarker}
                onClickMarker={onClickMarkerEvent}
                routeGroups={routeGroups}
                companySavedAddresses={companySavedAddresses}
                customStartPin={customStartPin}
                customEndPin={customEndPin}
                previewRouteLocations={routeLocations}
            />
        )
    }, [routeLocations, optimizedRoutes, showOptimizedRoutes, selectedLegIndex, hoveredMarkerIndex, selectedMarkerIndex, selectedRoute, customStartPin, customEndPin, routeGroups])

    const selectOptimizedRoute = (route: Route, index: number) => {
        if (selectedRouteIndex === index) {
            // If the clicked route is already selected, deselect it
            setSelectedRouteIndex(null);
            setSelectedRoute(null);
        } else {
            // Otherwise, select the new route
            setSelectedRouteIndex(index);
            setSelectedRoute(route);
            setRouteState(route);
            // setRoutePolyline(route.details.overview_polyline);
        }
    }

    const handleMoveLocation = async (fromIndex: number, toIndex: number) => {
        if (selectedRoute) {
            const updatedLocations = [...selectedRoute.details.locations];
            const [movedItem] = updatedLocations.splice(fromIndex, 1);
            updatedLocations.splice(toIndex, 0, movedItem);

            // Update indices
            updatedLocations.forEach((location, index) => {
                location.index = index;
            });

            const updatedRoute = new Route({
                ...selectedRoute,
                details: {
                    ...selectedRoute.details,
                    locations: updatedLocations
                } as RouteDetails
            });

            // Recalculate route - kept in this order
            const recalculatedRoute = await RouteController.calculateOrderedRoute(updatedRoute);

            // Update only the selected route in the optimizedRoutes array
            const updatedOptimizedRoutes = optimizedRoutes.map((route, index) =>
                index === selectedRouteIndex ? recalculatedRoute : route
            );

            setSelectedRoute(recalculatedRoute);
            setOptimizedRoutes(updatedOptimizedRoutes);
        }
    };

    const handleRemoveLocation = async (index: number) => {
        if (selectedRoute) {
            const updatedLocations = [...selectedRoute.details.locations];
            const removedLocation = updatedLocations.splice(index, 1)[0];

            // Deselect the job from the jobs list
            if (removedLocation.job_id) {
                const removedJob = availableJobs.find(job => job._id === removedLocation.job_id) || new Job({ _id: removedLocation.job_id });
                setRouteLocations(prevLocations => prevLocations.filter(loc => loc.job_id !== removedLocation.job_id));
            }

            const updatedRoute = new Route({
                ...selectedRoute,
                details: {
                    ...selectedRoute.details,
                    locations: updatedLocations
                } as RouteDetails
            });

            // Recalculate route - kept in this order
            const recalculatedRoute = await RouteController.calculateOrderedRoute(updatedRoute);

            // Update only the selected route in the optimizedRoutes array
            const updatedOptimizedRoutes = optimizedRoutes.map((route, i) =>
                i === selectedRouteIndex ? recalculatedRoute : route
            );

            setSelectedRoute(recalculatedRoute);
            setOptimizedRoutes(updatedOptimizedRoutes);

            // Update routeLocations state
        }
    };

    const handleMoveToAnotherRoute = async (locationIndex: number, targetRouteIndex: number) => {
        if (!selectedRoute || selectedRouteIndex === null) return;

        const newOptimizedRoutes = [...optimizedRoutes];
        const sourceRoute = newOptimizedRoutes[selectedRouteIndex];
        const targetRoute = newOptimizedRoutes[targetRouteIndex];

        // Remove location from source route
        const [movedLocation] = sourceRoute.details.locations.splice(locationIndex, 1);

        // Add location to target route
        targetRoute.details.locations.push(movedLocation);

        // Update indices for both routes
        sourceRoute.details.locations.forEach((location, index) => {
            location.index = index;
        });
        targetRoute.details.locations.forEach((location, index) => {
            location.index = index;
        });

        // Recalculate both routes
        const recalculatedSourceRoute = await RouteController.calculateOrderedRoute(sourceRoute);
        const recalculatedTargetRoute = await RouteController.calculateOrderedRoute(targetRoute);

        newOptimizedRoutes[selectedRouteIndex] = recalculatedSourceRoute;
        newOptimizedRoutes[targetRouteIndex] = recalculatedTargetRoute;

        // Update state
        setOptimizedRoutes(newOptimizedRoutes);
        setSelectedRoute(recalculatedSourceRoute);
    };
    //#endregion

    const LoadingOverlay = () => (
        <View style={StyleSheet.absoluteFill}>
            <View style={{
                flex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <ActivityIndicator size="large" color="#ffffff" />
                <Text style={{ color: '#ffffff', marginTop: 10 }}>Calculating route...</Text>
            </View>
        </View>
    );

    // Add this state near the other state declarations
    const [showVroomSummary, setShowVroomSummary] = useState(false);

    const handleSelectAddress = (groupIndex: number, addressType: 'start' | 'end' | 'roundTrip') => {
        // Implement address selection logic here
        // You might want to open a modal or navigate to an address selection screen
        console.log(`Selecting ${addressType} address for group ${groupIndex}`);
    };

    return (
        <Layout style={styles.container}>
            <View style={styles.topBar}>
                <Button appearance="filled" status="danger" accessoryLeft={<Icon name="close" />} onPress={onClose} />
            </View>
            <CreateJobForRouteModal
                showCreateJobForRouteModal={showCreateJobForRouteModal}
                setShowCreateJobForRouteModal={setShowCreateJobForRouteModal}
                onJobCreated={onJobCreated}
            />
            <JobReportModal
                showReportDetailsModal={showJobReportModal}
                setShowReportDetailsModal={setShowJobReportModal}
                job={selectedJobReportModalJob as Job}
                showImages={true}
                showLogs={true}
            />
            <ConfirmRemoveRouteLocationModal
                visible={showConfirmRemoveRouteLocationModal}
                onClose={() => setShowConfirmRemoveRouteLocationModal(false)}
                onRemove={() => onRemoveLocation(selectedRouteLocationIndex)}
            />
            <View style={styles.mapContainer}>
                {MemoizedRouteMap}
            </View>
            <Animated.View style={[styles.sidePanel, { height: jobListHeight, width: jobListWidth }]}>
                <Button
                    size='small'
                    status='info'
                    onPress={() => toggleJobList()}
                    accessoryLeft={<Icon name={isJobListCollapsed ? 'chevron-right' : 'chevron-left'} />}
                >
                    {isJobListCollapsed ? '' : 'Hide Jobs'}
                </Button>
                {!isJobListCollapsed && (
                    <Layout style={{ flexDirection: 'column', flex: 1, gap: 3 }}>
                        <ScrollView style={styles.availableJobsContainer}>
                            <AvailableJobs
                                availableJobs={availableJobs}
                                onSelectJob={onSelectJob}
                                isJobSelected={isJobSelected}
                                getJobOrderBadge={getJobOrderBadge}
                                onCreateJob={() => setShowCreateJobForRouteModal(true)}
                            />
                        </ScrollView>
                    </Layout>
                )}
            </Animated.View>
            <Animated.View style={[styles.floatingPanel, { left: configPanelLeft }]}>
                <View style={{ flexDirection: 'row' }}>

                    <RouteConfigPanel
                        routeGroups={routeGroups}
                        onUpdateRouteGroups={setRouteGroups}
                        onSelectAddress={handleSelectAddress}
                        routeAction={routeAction}
                        setRouteAction={setRouteAction}
                        onCalculateRoute={generateRoute}
                        setShowRouteLegsPanel={() => { }}
                        disableKeepInOrder={routeGroups.reduce((sum, group) => sum + group.numberOfVehicles, 0) > 1}
                    />
                    <View style={{ position: 'absolute', top: 0, right: -300, maxWidth: 300, maxHeight: 80 }}>
                        <RouteActions
                            routeAction={routeAction}
                            setRouteAction={setRouteAction}
                            onCalculateRoute={generateRoute}
                            setShowRouteLegsPanel={() => { }}
                            disableKeepInOrder={routeGroups.reduce((sum, group) => sum + group.numberOfVehicles, 0) > 1}
                        />
                    </View>
                </View>
            </Animated.View>
            {selectedRoute && (
                <Animated.View style={[
                    styles.selectedLocationsPanel,
                    { left: selectedLocationsLeft, height: selectedLocationsHeight }
                ]}>
                    <Layout style={{ flexDirection: 'row', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomColor: theme['color-primary-300'], paddingBottom: 10, paddingTop: 10 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Button
                                size='small'
                                status='info'
                                appearance='ghost'
                                onPress={toggleSelectedLocations}
                                accessoryLeft={<Icon name={isSelectedLocationsCollapsed ? 'chevron-down' : 'chevron-up'} />}
                            >
                            </Button>
                            <Text category='s1'>Selected Locations</Text>
                        </View>
                        <Button
                            size='small'
                            status='danger'
                            onPress={() => setSelectedRoute(null)}
                            accessoryLeft={<Icon name='close' />}
                        />
                    </Layout>
                    {!isSelectedLocationsCollapsed && (
                        <SelectedLocations
                            locations={selectedRoute.details.locations}
                            onRemoveLocation={handleRemoveLocation}
                            onMoveLocation={handleMoveLocation}
                            onMoveToAnotherRoute={handleMoveToAnotherRoute}
                            availableRoutes={optimizedRoutes.map((route, index) => ({ index, name: route.name }))}
                        />
                    )}
                </Animated.View>
            )}
            {showOptimizedRoutes && (
                <Animated.View style={[styles.optimizedRoutesPanel, { left: optimizedRoutesPanelLeft }]}>
                    <Layout level='2' style={{ borderWidth: 1, padding: 3, borderRadius: 8 }}>
                        <RoutesList
                            routes={optimizedRoutes}
                            selectedRouteIndex={selectedRouteIndex}
                            onSelectRoute={selectOptimizedRoute}
                            onCreateRoutes={onCreateRoutes}
                            isRouteCreated={isRouteCreated}
                            onUpdateRouteName={onUpdateRouteName}
                        />
                    </Layout>
                </Animated.View>
            )}
            {selectedRoute && (
                <Layout style={styles.routeSummaryPanel}>
                    <RouteSummary
                        route={selectedRoute}
                        routeReadyForCreation={true}
                        onCreateRoute={() => onCreateRoute(selectedRoute)}
                        onAssignDriver={onAssignDriverToRoute}
                        // isRouteCreated={isRouteCreated}
                        onUpdatePlannedStartTime={handleUpdatePlannedStartTime}
                        onSelectRoute={() => { }}
                        onSelectJobId={() => { }}
                        onUpdateRouteStatus={() => { }}
                        onUpdateRoute={(updatedRoute) => {
                            setSelectedRoute(updatedRoute);
                            setOptimizedRoutes(prevRoutes =>
                                prevRoutes.map((r, index) => index === selectedRouteIndex ? updatedRoute : r)
                            );
                        }}
                        selectedRouteIndex={selectedRouteIndex}
                    />
                </Layout>
            )}
            {isLoading && <LoadingOverlay />}
            {showVroomSummary && vroomSummary && (
                <VroomSummaryDisplay
                    summary={vroomSummary}
                    onClose={() => setShowVroomSummary(false)}
                />
            )}
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    mapContainer: {
        ...StyleSheet.absoluteFillObject,
    },
    sidePanel: {
        position: 'absolute',
        top: 10,
        left: 10,
        width: width * 0.2,
        borderRadius: 8,
        padding: 10,
        zIndex: 1000,
        overflow: 'hidden',
    },
    availableJobsContainer: {
        marginTop: 10,
        maxHeight: height * 0.7,
    },
    floatingPanel: {
        position: 'absolute',
        top: 10,
        left: width * 0.22,
        // width: width * 0.56,
        borderRadius: 8,
        padding: 10,
        zIndex: 1000,
    },
    selectedLocationsPanel: {
        position: 'absolute',
        top: '20%',
        width: width * 0.3,
        maxHeight: '50%',
        borderRadius: 8,
        padding: 10,
        zIndex: 1000,
        overflow: 'hidden',
    },
    optimizedRoutesPanel: {
        position: 'absolute',
        bottom: 10,
        maxHeight: '30%',
        // width: width * 0.56,
        borderRadius: 8,
        padding: 10,
        zIndex: 1000,
    },
    routeSummaryPanel: {
        position: 'absolute',
        top: 10,
        right: 10,
        width: '30%',
        // backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: 8,
        padding: 10,
        zIndex: 1000,
    },
    topBar: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 11000,
    },
});



const CreateJobForRouteModal = ({
    showCreateJobForRouteModal,
    setShowCreateJobForRouteModal,
    onJobCreated,
}: {
    showCreateJobForRouteModal: boolean;
    setShowCreateJobForRouteModal: (show: boolean) => void;
    onJobCreated: (job: Job) => void;
}) => {
    return (
        <Modal
            visible={showCreateJobForRouteModal}
            onBackdropPress={() => setShowCreateJobForRouteModal(false)}
            backdropStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
            <CreateJobForRoute
                onJobCreated={onJobCreated}
            />
        </Modal>
    )
}