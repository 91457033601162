import React from 'react';
import { View, ViewStyle, StyleProp } from 'react-native';
import { List, Text } from '@ui-kitten/components';
import { LineItem } from '../../../../models/LineItem.model';
import { LineItemComponent } from './LineItem.component';

interface LineItemsListProps {
    title: string;
    items: LineItem[];
    total: string;
    allowEdit: boolean;
    onRemove: (item: LineItem) => void;
    onUpdate: (item: LineItem) => void;
    style?: StyleProp<ViewStyle>;
    containerStyle?: StyleProp<ViewStyle>;
    listStyle?: StyleProp<ViewStyle>;
}

export const LineItemsList = ({
    title,
    items,
    total,
    allowEdit,
    onRemove,
    onUpdate,
    style,
    containerStyle,
    listStyle
}: LineItemsListProps) => {
    return (
        <View style={[{ flex: 1, minWidth: 400 }, style]}>
            <Text category='s1'>{title}</Text>
            <List
                data={items}
                renderItem={({ item }) => (
                    <LineItemComponent
                        item={item}
                        allowEdit={allowEdit}
                        onRemove={() => onRemove(item)}
                        onUpdate={onUpdate}
                    />
                )}
                ListEmptyComponent={<Text appearance="hint">No {title.toLowerCase()}.</Text>}
                style={[{ maxHeight: 400, marginHorizontal: 10 }, listStyle]}
                contentContainerStyle={containerStyle}
            />
            <Text style={{ margin: 10, fontWeight: 'bold', textAlign: 'right' }}>
                {title} Total: ${total}
            </Text>
        </View>
    );
}; 