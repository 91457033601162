import React, { useState, useEffect, useReducer, useRef } from 'react';
import { StyleSheet, View, ScrollView, useWindowDimensions } from "react-native";
import {
    Button,
    Icon,
    Layout,
    Card,
    Input,
    Modal,
    Text,
    List,
    ListItem,
    IndexPath,
    Select,
    SelectItem,
    Toggle,
    Radio,
    RadioGroup
} from "@ui-kitten/components";

import { Service, LINE_ITEM_BILLED_TO, ServiceTagsArr, SERVICE_FIELD_TYPES } from '../../models/Job.model';
import DMModal from '../common/Modal';
import { InputTextField } from './fields/InputTextField.component';
import { InputNumberField } from './fields/InputNumberField.component';
import { OptionsField } from './fields/OptionsField.component';
import { NewFieldModal } from './modals/NewFieldModal.component';
import { ImageField } from './fields/ImageField.component';

interface ServiceEditorProps {
    service: Service,
    canEditField: boolean,
    selectedService: Service,
    unsavedChangesRef: any,
    FIELD_TYPES: Record<string, string>,
    handleServiceCancel: Function,
    handleServiceSave: Function,
    onSetUnsavedChangesRef: Function,
    capitalizeFirstLetter: Function
}

const ServiceEditor = ({
    service,
    // _unsavedChanges = false,
    canEditField,
    selectedService,
    unsavedChangesRef,
    FIELD_TYPES,
    handleServiceCancel,
    handleServiceSave,
    onSetUnsavedChangesRef,
    capitalizeFirstLetter
}: ServiceEditorProps) => {

    const [serviceState, setServiceState] = useState(service);
    const [selectedField, setSelectedField] = useState(null as null | typeof service.fields[keyof typeof service.fields]);
    const [serviceOptions, setServiceOptions] = useState(service.options);
    const [selectedOption, setSelectedOption] = useState(null);
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const [showNewFieldModal, setShowNewFieldModal] = useState(false);


    const [optionEditInputField, setOptionEditInputField] = useState("");
    const [selectedOptionText, setSelectedOptionText] = useState("");

    const [selectedDestination, setSelectedDestination] = useState(null);
    const [selectedDestinationIndex, setSelectedDestinationIndex] = useState(0);
    const [selectedDestinationName, setSelectedDestinationName] = useState("");
    const [selectedDestinationTime, setSelectedDestinationTime] = useState("");
    const [selectedDestinationTypes, setSelectedDestinationTypes] = useState([]); //radio options
    const [selectedDestinationTypeText, setSelectedDestinationTypeText] = useState("");
    const [selectedDestinationTypeIndex, setSelectedDestinationTypeIndex] = useState(0);
    const [selectedDestinationPhotos, setSelectedDestinationPhotos] = useState([]);
    const [selectedDestinationAddress, setSelectedDestinationAddress] = useState("");

    const [unsavedChanges, setUnsavedChanges] = useState(false);

    const { width, height } = useWindowDimensions();

    const onSetServiceState = (service: Service, changes = true) => {
        const s = new Service(service)
        setUnsavedChanges(changes);
        // unsavedChangesRef.current = changes;
        onSetUnsavedChangesRef(changes);
        setServiceState(s);
    }

    useEffect(() => {
        setServiceState(service);
        setSelectedField(null);
    }, [service])

    // useEffect(() => {
    //     setUnsavedChanges(_unsavedChanges);
    // }, [_unsavedChanges])













    // fields
    const addField = (field: string) => {
        let _service = serviceState;
        _service.addField(field);




        // _service.fields[fieldKey] = val;
        // _service.values[fieldKey] = "";
        onSetServiceState(_service);
        forceUpdate();
    }

    const removeField = (field: string) => {
        let _service = serviceState;
        try {
            _service.removeField(field);
            // delete _service.fields?.[field];
            // delete _service.types?.[field];
            // delete _service.values?.[field];
            // delete _service.options?.[field];
            // delete _service.invoiced?.[field];
            // delete _service.required?.[field];
            // delete _service.driver_editable?.[field];
            // delete _service.other?.[field];
            onSetServiceState(_service);
        } catch (e) {
            console.log("Error removing field: ", e);
        }
    }

    const updateField = (field: string, value: any) => {
        let _service = serviceState;
        _service.fields[field] = value;
        onSetServiceState(_service);
    }

    // types
    const addType = (field: string, type: any) => {
        let _service = serviceState;
        _service.types[field] = type;
        onSetServiceState(_service);
    }

    const removeType = (field: string) => {
        let _service = serviceState;
        delete _service.types[field];
        onSetServiceState(_service);
    }

    const updateType = (field: string, type: any) => {
        let _service = serviceState;
        _service.types[field] = type;
        if (type === SERVICE_FIELD_TYPES.options || type === SERVICE_FIELD_TYPES.radio) {
            _service.options[field] = [];
        }

        onSetServiceState(_service);
        forceUpdate();
    }

    // values
    const addValue = (field: string, value: any) => {
        let _service = serviceState;
        _service.values[field] = value;
        onSetServiceState(_service);
    }

    const removeValue = (field: string) => {
        let _service = serviceState;
        delete _service.values[field];
        onSetServiceState(_service);
    }

    const updateValue = (field: string, value: any) => {
        let _service = serviceState;
        _service.values[field] = value;
        onSetServiceState(_service);
        forceUpdate();
    }

    // options
    const addOption = (field: string, option: string) => {
        let _service = serviceState;
        if (!_service.options[field]) {
            _service.options[field] = [];
        }
        option = `${option} ${_service.options[field].length}`;
        _service.options[field].push(option);
        //console.log("pushed:", _service.options[field]);
        onSetServiceState(_service);
        forceUpdate();
    }

    const removeOption = (field: string, option: string) => {
        let _service = serviceState;
        _service.options[field] = _service.options[field].filter((o) => o !== option);
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateOption = (field: string, option: string, value: any) => {
        let _service = serviceState;
        //console.log('Updating option', field, option, value);
        // find index of option
        let index = _service.options[field].indexOf(option);
        _service.options[field][index] = value;
        //console.log('update option', _service.options[field][index], _service);
        setSelectedOption(value);
        onSetServiceState(_service);
        // forceUpdate();
    }






    const updateInvoiced = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState })
        if (!_service.hasOwnProperty("invoiced")) {
            _service.invoiced = {};
            _service.invoiced[field] = value;
        } else {
            _service.invoiced[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateRequired = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState })
        if (!_service.hasOwnProperty("required")) {
            _service.required = {};
            _service.required[field] = value;
        } else {
            _service.required[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    // driver_editable
    const updateDriverEditable = (field: string, value: boolean) => {
        let _service = new Service({ ...serviceState });
        if (!_service.hasOwnProperty("driver_editable")) {
            _service.driver_editable = {};
            _service.driver_editable[field] = value;
        } else {
            _service.driver_editable[field] = value;
        }
        onSetServiceState(_service);
        forceUpdate();
    }

    const updateServiceTags = (tags: string[]) => {
        let _service = new Service({ ...serviceState })
        _service.tags = tags || [];
        onSetServiceState(_service);
        forceUpdate();
    }


    const handleSave = () => {
        handleServiceSave(serviceState);
        setUnsavedChanges(false);
    }

    const handleCancel = () => {
        handleServiceCancel();
    }

    // const handleDelete = () => {
    //     handleServiceDelete(serviceState);
    // }


    // if selectedService.other[field] does not exist, create it

    const handleFieldSelect = (field: string) => {
        // check has fields
        let _service = new Service({ ...serviceState })
        if (!_service.driver_editable) {
            _service.driver_editable = {};
        }
        if (!_service.invoiced) {
            _service.invoiced = {};
        }
        if (!_service.required) {
            _service.required = {};
        }
        if (!_service.driver_editable[field]) {
            _service.driver_editable[field] = false;
        }
        if (!_service.invoiced[field]) {
            _service.invoiced[field] = false;
        }
        if (!_service.required[field]) {
            _service.required[field] = false;
        }
        const c = unsavedChanges;
        onSetServiceState(_service, c);
        setSelectedField(field);
    }





    return (
        <Layout style={{ flex: 1, flexDirection: 'column', height: height * 0.9 }}>
            <NewFieldModal
                onClose={() => setShowNewFieldModal(false)}
                addField={addField}
                showNewFieldModal={showNewFieldModal} />
            <Layout style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 1, flexDirection: 'row' }}></View>
                <View style={{ flex: 2, justifyContent: 'center', alignContent: 'center' }}>
                    {
                        unsavedChanges ? <Text category='h6' style={{ color: 'red' }}>Unsaved Changes</Text> : <Text></Text>
                    }
                </View>
                <Layout style={{ flex: 1 }}>
                    <Button
                        disabled={!canEditField}
                        onPress={handleSave}
                        accessoryLeft={(props: any) => <Icon {...props} name="save-outline" />}
                        status="success"
                    >
                        Save
                    </Button>
                </Layout>
                <Layout style={{ flex: 1 }}>
                    <Button
                        disabled={!canEditField}
                        onPress={handleCancel}
                        accessoryLeft={(props: any) => <Icon {...props} name="close-outline" />}
                        status="warning"
                    >
                        Cancel
                    </Button>
                </Layout>
            </Layout>
            <Layout style={{ flex: 15, flexDirection: 'column' }}>
                <Layout style={{ flex: 2, flexDirection: 'row', border: '1px solid', gap: 10 }}>
                    <Layout style={{ flex: 1, flexDirection: 'column' }}>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Text category="h5">Service : {serviceState.name}</Text>
                            <Text category="p1">Service ID: {serviceState._id || ""}</Text>
                        </View>
                        <InputTextField
                            field="name"
                            disabled={!canEditField}
                            label="Name"
                            value={serviceState.name}
                            onChange={updateField}
                        />
                        <Layout style={{ flex: 2 }}>
                            <InputTextField
                                field="description"
                                disabled={!canEditField}
                                label="Description"
                                value={serviceState.description}
                                onChange={updateField}
                            />
                        </Layout>
                    </Layout>
                    <Layout style={{ flex: 1 }}>
                        <Text category='h6'>Tags</Text>
                        <Text>Select the categories this service is applicable to. This assists in showing the clients what services you have at a glance.</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                            {ServiceTagsArr.map((tag, index) => (
                                <Button
                                    size='small'
                                    key={index}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        let tags = serviceState.tags || [];
                                        if (tags.includes(tag)) {
                                            tags = tags.filter((t) => t !== tag);
                                        } else {
                                            tags.push(tag);
                                        }
                                        updateServiceTags(tags);
                                    }}
                                    appearance={serviceState.tags?.includes(tag) ? 'filled' : 'outline'}
                                >
                                    {tag}
                                </Button>

                            ))}
                        </View>
                    </Layout>
                </Layout>
                <Layout style={{ flex: 5, flexDirection: 'row' }}>
                    <Card
                        style={{ flex: 1 }}
                        header={() => <Text category='s1'>Fields</Text>}
                        disabled
                        status="warning"
                    >
                        <Layout style={{ flex: 1, flexDirection: 'column', margin: -15, marginLeft: -20, marginRight: -20, gap: 5 }}>
                            <View style={{ flexDirection: 'row', gap: 5, justifyContent: 'space-between' }}>
                                <Button
                                    style={{ flex: 1 }}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        // addField('newField');
                                        setShowNewFieldModal(true);
                                    }}
                                    accessoryLeft={(props: any) => <Icon {...props} name="plus-outline" />}
                                    status="success"
                                />
                                <Button
                                    style={{ flex: 1 }}
                                    disabled={!canEditField}
                                    onPress={() => {
                                        removeField(selectedField as string);
                                        setSelectedField(null);
                                    }}
                                    accessoryLeft={(props: any) => <Icon {...props} name="minus-outline" />}
                                    status="danger"
                                />
                            </View>
                            <List
                                data={Object.keys(serviceState.fields)}
                                renderItem={({ item }: { item: string }) => (
                                    <ListItem
                                        title={serviceState.fields[item]}
                                        style={{
                                            backgroundColor: selectedField === item ? 'green' : '',
                                            border: '1px solid black'
                                        }}
                                        onPress={() => {
                                            handleFieldSelect(item);
                                            console.log('selected field', height);
                                        }}
                                    />
                                )}
                            />
                        </Layout>
                    </Card>
                    <Layout style={{ flex: 3 }}>
                        <Card
                            style={{ flex: 1 }}
                            disabled
                            header={() => <Text category='s1'>Field Details</Text>}
                            status="primary"
                        >
                            {selectedField &&
                                <ScrollView style={{ height: height * 0.6 }}>
                                    <Layout style={{ flexDirection: 'row', gap: 10 }}>
                                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', gap: 3 }}>
                                            <View style={{ flex: 1 }}>
                                                <InputTextField
                                                    field={selectedField}
                                                    label="Field Name"
                                                    value={serviceState.fields[selectedField]}
                                                    onChange={(field: string, value: string) => {
                                                        let _service = serviceState;
                                                        _service.fields[field] = value;
                                                        onSetServiceState(_service);
                                                        forceUpdate();
                                                    }}
                                                    disabled={!canEditField}
                                                />
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Select
                                                    disabled={!canEditField}
                                                    label="Field Type"
                                                    value={serviceState.types[selectedField]}
                                                    onSelect={(index: any) => {
                                                        let value = Object.keys(FIELD_TYPES)[index.row];
                                                        updateType(selectedField, value);
                                                    }}
                                                >
                                                    {Object.keys(FIELD_TYPES).map((type, index) => (
                                                        <SelectItem key={index} title={type} />
                                                    ))}
                                                </Select>
                                            </View>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row', gap: 3 }}>
                                            <View>
                                                <View style={{ flex: 1 }}>
                                                    <Toggle
                                                        disabled={!canEditField}
                                                        checked={serviceState.invoiced?.[selectedField] || false}
                                                        onChange={(checked: boolean) => {
                                                            updateInvoiced(selectedField, checked);
                                                        }}
                                                    >
                                                        Invoiced
                                                    </Toggle>
                                                </View>
                                                <View style={{ flex: 1 }}>
                                                    <Toggle
                                                        disabled={!canEditField}
                                                        checked={serviceState.required?.[selectedField] || false}
                                                        onChange={(checked: boolean) => {
                                                            updateRequired(selectedField, checked);
                                                        }}
                                                    >
                                                        Required
                                                    </Toggle>
                                                </View>
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Toggle
                                                    disabled={!canEditField}
                                                    checked={serviceState.driver_editable?.[selectedField] || false}
                                                    onChange={(checked: boolean) => {
                                                        updateDriverEditable(selectedField, checked);
                                                    }}
                                                >
                                                    Driver Editable
                                                </Toggle>
                                            </View>
                                        </View>
                                    </Layout>
                                    <Layout style={{ flex: 3, flexDirection: 'row' }}>
                                        <Layout style={{ flex: 2, flexDirection: 'row' }}>
                                            {/* Value */}
                                            {(serviceState.types[selectedField] === FIELD_TYPES.input ||
                                                serviceState.types[selectedField] === FIELD_TYPES.date ||
                                                serviceState.types[selectedField] === FIELD_TYPES.time) &&
                                                <Layout style={{ flex: 1 }}>
                                                    <InputTextField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default Value"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {/* is cost/rebate/commission */}
                                            {(serviceState.types[selectedField] === FIELD_TYPES.cost ||
                                                serviceState.types[selectedField] === FIELD_TYPES.driver_rebate ||
                                                serviceState.types[selectedField] === FIELD_TYPES.driver_commission) &&
                                                <Layout style={{ flex: 1 }}>
                                                    <InputNumberField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default $ value"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {serviceState.types[selectedField] === FIELD_TYPES.quantity &&
                                                <Layout style={{ flex: 1 }}>
                                                    <InputNumberField
                                                        field={selectedField}
                                                        value={serviceState.values[selectedField] as string}
                                                        onChange={updateValue}
                                                        label={"Default quantity"}
                                                        disabled={!canEditField}
                                                    />
                                                </Layout>
                                            }
                                            {/* Options */}
                                            {(serviceState.types[selectedField] === FIELD_TYPES.options ||
                                                serviceState.types[selectedField] === FIELD_TYPES.radio) &&
                                                <OptionsField
                                                    field={selectedField}
                                                    options={serviceState.options[selectedField]}
                                                    label={"Options"}
                                                    disabled={!canEditField}
                                                    onAddOption={addOption}
                                                    onRemoveOption={removeOption}
                                                    onUpdateOption={updateOption}
                                                />
                                            }
                                        </Layout>
                                    </Layout>
                                </ScrollView>
                            }
                        </Card>
                    </Layout>
                    {selectedService &&
                        <Layout style={{ flex: 2, border: '1px solid' }}>
                            <Layout style={{ flex: 1 }}>
                                <Text category='h6'>Preview</Text>
                            </Layout>
                            <Layout style={{ flex: 15 }}>
                                <Card disabled>
                                    <Text category='h5'>{selectedService?.name}</Text>
                                    {Object.keys(selectedService?.fields).map((field, index) => (
                                        <Layout key={index} style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", margin: 5, height: selectedService?.fields.length || 1 * 60 }}>
                                            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                                                <Text category="s1">{selectedService?.fields[field]}</Text>
                                                {selectedService?.required?.[field] && (
                                                    <Text category="s1" style={{ color: 'red' }}>*</Text>
                                                )}
                                                {selectedService?.driver_editable?.[field] && (
                                                    <Icon
                                                        name='car-outline'
                                                        width={16}
                                                        height={16}
                                                        fill='#228B22'
                                                        style={{ marginTop: 2 }}
                                                    />
                                                )}
                                                {selectedService?.invoiced?.[field] && (
                                                    <Icon
                                                        name='attach-outline'
                                                        width={16}
                                                        height={16}
                                                        fill='#4169E1'
                                                        style={{ marginTop: 2 }}
                                                    />
                                                )}
                                            </View>
                                            {(selectedService?.types?.[field] === "input" ||
                                                selectedService?.types?.[field] === "cost" ||
                                                selectedService?.types?.[field] === "address" ||
                                                selectedService?.types?.[field] === "driver_rebate" ||
                                                selectedService?.types?.[field] === "driver_commission" ||
                                                selectedService?.types?.[field] === "quantity") &&
                                                <Input
                                                    value={selectedService?.values[field] as string || ""}
                                                />}
                                            {selectedService?.types?.[field] === "options" &&
                                                selectedService?.options[field] &&
                                                <Select
                                                    //@ts-ignore
                                                    value={selectedService?.values?.[field] || ""}
                                                //@ts-ignore
                                                // selectedIndex={new IndexPath(selectedService?.values?.[field] as number)}
                                                >
                                                    {selectedService?.options[field].map((option, index) => (
                                                        <SelectItem
                                                            key={index}
                                                            title={option}
                                                            //@ts-ignore
                                                            value={option}
                                                        />
                                                    ))}
                                                </Select>}
                                            {selectedService?.types?.[field] === "toggle" && <Toggle
                                                checked={selectedService?.values[field] as boolean}
                                            />}
                                            {selectedService?.types?.[field] === "radio" &&
                                                <RadioGroup
                                                    //@ts-ignore
                                                    selectedIndex={new IndexPath(selectedService?.values?.[field] as number)}
                                                >
                                                    {selectedService?.options &&
                                                        <>
                                                            {selectedService?.options?.[field]?.map((option, index) => {
                                                                return (<Radio key={index}>{option}</Radio>)
                                                            })}
                                                        </>
                                                    }
                                                </RadioGroup>
                                            }
                                            {selectedService?.types?.[field] === "image" &&
                                                <ImageField
                                                    field={field}
                                                    value={selectedService?.values[field] as string}
                                                    label="Image Upload"
                                                    disabled={!canEditField}
                                                />
                                            }
                                        </Layout>
                                    ))}
                                </Card>
                            </Layout>
                        </Layout>
                    }
                </Layout>
            </Layout>
        </Layout>
    );
}




export default ServiceEditor;