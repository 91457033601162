import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    LayoutChangeEvent,
    FlatList,
    Animated
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    useTheme,
    List,
    ListItem,
    Card,
} from "@ui-kitten/components";
import JobListItem from "./jobListItem.component"
import { Job, JOB_STATUS } from '../../models/Job.model';
import { Client } from '../../models/Client.model';
import { Member } from '../../models/Member.model';
import { CompactJobListItem } from "./CompactJobListItem";
import * as JobController from '../../functions/job.controller';
import * as StorageController from '../../functions/storageController';
import AssignJobModal from './modals/assignJobModal.component';
import { IAction, useAppStateChange } from '../../hooks/appStateChange.hook';
import { Company } from '../../models/Company.model';
import { debounce } from 'lodash';
import BulkActionConfirmationModal from './modals/BulkActionConfirmationModal.component';


interface JobsListProps {
    selectedJobId: string | null;
    jobs: Job[];
    showCompact?: boolean;
    fn_onEndReached: () => void;
    fn_zoomToMarker: (job: Job) => void;
    fn_selectJob: (job: Job) => void;
    var_viewedBy: string;
    var_vendorProfiles: any;
    drivers: Member[];
    clients: Client[];
    fn_setDirections: (driver: Member, job: Job) => void;
    fn_openAssign: (job: Job) => void;
    fn_vendorAcceptJob: (job_id: string, eta: number) => void;
    // sortBy: JOBS_LIST_SORT_BY;
    // setSortBy: (sort: JOBS_LIST_SORT_BY) => void;
}

const JobsList: React.FC<JobsListProps> = ({
    selectedJobId,
    jobs,
    showCompact = false,
    fn_onEndReached,
    fn_zoomToMarker,
    fn_selectJob,
    var_viewedBy,
    var_vendorProfiles,
    drivers,
    clients,
    fn_setDirections,
    fn_openAssign,
    fn_vendorAcceptJob,
    // sortBy,
    // setSortBy
}) => {
    const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
    const selectedJobIdsRef = useRef<string[]>(selectedJobs);
    const selectedJobsRef = useRef<Job[]>([]);
    const [bulkAssignJobModalVisible, setBulkAssignJobModalVisible] = useState(false);
    const [bulkActionsDisabled, setBulkActionsDisabled] = useState(true);

    const [containerWidth, setContainerWidth] = useState(1000);
    const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());

    const [sortBy, setSortBy] = useState<JOBS_LIST_SORT_BY>(JOBS_LIST_SORT_BY.DEFAULT);
    const [showOrderControls, setShowOrderControls] = useState(false);

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [confirmationAction, setConfirmationAction] = useState<() => void>(() => { });
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const theme = useTheme();

    const [delayedJobsState, setDelayedJobsState] = useState(jobs);
    const debounceSetDelayedJobsState = useCallback(debounce(setDelayedJobsState, 300), [])

    const lastContainerWidth = useRef(0);
    const SMALL_WIDTH = 900;

    const COLLAPSED_HEIGHT = 79;
    const EXPANDED_HEIGHT = 300; // Adjust this value based on your expanded item height


    const sortJobs = useCallback((jobsToSort: Job[]) => {
        return jobsToSort.sort((a: Job, b: Job) => {
            // First sort by status
            if (sortBy === JOBS_LIST_SORT_BY.DEFAULT) {
                const statusOrder: { [key: string]: number } = {
                    [JOB_STATUS.REQUEST]: 1,
                    [JOB_STATUS.UNASSIGNED]: 2,
                    [JOB_STATUS.ASSIGNED]: 3,
                    [JOB_STATUS.PENDING]: 4,
                    [JOB_STATUS.COMPLETE]: 5,
                    [JOB_STATUS.CANCELLED]: 6,
                    [JOB_STATUS.QUOTE]: 7
                };

                if (statusOrder[a.status] !== statusOrder[b.status]) {
                    return statusOrder[a.status] - statusOrder[b.status];
                }
            }

            // Then apply the selected sort
            switch (sortBy) {
                case JOBS_LIST_SORT_BY.START_TIME_DESC:
                    return (new Date(b.start_time || 0)).getTime() - (new Date(a.start_time || 0)).getTime();
                case JOBS_LIST_SORT_BY.START_TIME_ASC:
                    return (new Date(a.start_time || 0)).getTime() - (new Date(b.start_time || 0)).getTime();
                case JOBS_LIST_SORT_BY.UPDATED_TIME_DESC:
                    return (new Date(b.updatedAt || 0)).getTime() - (new Date(a.updatedAt || 0)).getTime();
                case JOBS_LIST_SORT_BY.UPDATED_TIME_ASC:
                    return (new Date(a.updatedAt || 0)).getTime() - (new Date(b.updatedAt || 0)).getTime();
                case JOBS_LIST_SORT_BY.PRIORITY_DESC:
                case JOBS_LIST_SORT_BY.PRIORITY_ASC:
                    const aPriority = a.details?.priority?.value || 0;
                    const bPriority = b.details?.priority?.value || 0;

                    // First compare if the job is not pending. assigned, requests and unassigned should be at the top
                    if (a.status !== JOB_STATUS.PENDING && b.status === JOB_STATUS.PENDING) {
                        return -1;
                    }
                    if (b.status !== JOB_STATUS.PENDING && a.status === JOB_STATUS.PENDING) {
                        return 1;
                    }

                    // Then compare priorities (high priority should be first)
                    if (aPriority !== bPriority) {
                        // Since 1=high, 2=low, we want high (1) at the top
                        // So we want 1 to be treated as highest, 0 as middle, 2 as lowest
                        const getPriorityValue = (p: number) => {
                            if (p === 1) return -1;  // High priority becomes lowest number (sorts first)
                            if (p === 0) return 0;   // Normal priority stays in middle
                            return 1;                // Low priority becomes highest number (sorts last)
                        };
                        return getPriorityValue(aPriority) - getPriorityValue(bPriority);
                    }

                    // If priorities are equal, sort by how close they are to ETA
                    // or how overdue they are
                    const now = new Date().getTime();
                    const aEtaTime = a.start_time || new Date(a.createdAt || 0).getTime() + ((a.vendor_eta || 0) * 60 * 1000);
                    const bEtaTime = b.start_time || new Date(b.createdAt || 0).getTime() + ((b.vendor_eta || 0) * 60 * 1000);
                    const aOverdue = now - aEtaTime;
                    const bOverdue = now - bEtaTime;

                    // Most overdue or closest to ETA should be first
                    return bOverdue - aOverdue;
                default:
                    return 0;
            }
        });
    }, [sortBy]);

    const calculateJobPriority = (job: Job) => {
        const now = new Date().getTime();
        const creationTime = new Date(job.createdAt || 0).getTime();
        // @ts-ignore - eta is added in a newer version
        const eta = job.eta ? parseInt(job.eta) : 0;

        // Calculate how overdue the job is based on creation time + ETA
        return now - (creationTime + (eta * 60 * 1000));
    };

    useEffect(() => {
        const sortedJobs = sortJobs(jobs)
        debounceSetDelayedJobsState([...sortedJobs]);

        const unassignedCount = sortedJobs.filter(job => job.status === JOB_STATUS.UNASSIGNED).length
        const assignedCount = sortedJobs.filter(job => job.status === JOB_STATUS.ASSIGNED).length
        const pendingCount = sortedJobs.filter(job => job.status === JOB_STATUS.PENDING).length
        const completedCount = sortedJobs.filter(job => job.status === JOB_STATUS.COMPLETE).length
        const cancelledCount = sortedJobs.filter(job => job.status === JOB_STATUS.CANCELLED).length

        // console.log("JOBS LIST", "u:", unassignedCount, "a:", assignedCount, "p:", pendingCount, "c:", completedCount, "ca:", cancelledCount)
    }, [jobs, sortBy]);

    const getItemLayout = useCallback((data, index) => {
        let offset = 0;
        for (let i = 0; i < index; i++) {
            offset += expandedItems.has(data[i]._id) ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT;
        }
        const length = expandedItems.has(data[index]._id) ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT;
        return { length, offset, index };
    }, [expandedItems]);

    const getDriverForJob = useCallback((job: Job) => {
        return drivers.find((driver: Member) => driver._id === job.member_id) || new Member()
    }, [drivers])


    const getClientForJob = useCallback((job: Job) => {
        return clients.find((client: Client) => client._id === job.client_id) || new Client({})
    }, [clients])


    const onLayoutChange = (event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        // console.log("🚀============== ~ file: jobsList.component.tsx:82 ~ onLayoutChange ~ width🚀==============", width)
        if (width !== lastContainerWidth.current) {
            lastContainerWidth.current = width;
            onSetContainerWidth(width);
        }
    };

    const onSetContainerWidth = debounce(setContainerWidth, 300);

    const onSetSelectedJobs = (jobIds: string[]) => {
        selectedJobIdsRef.current = jobIds
        selectedJobsRef.current = jobs.filter(job => jobIds.includes(job._id))
        setSelectedJobs(jobIds)
    }

    const toggleJobSelection = (job: Job) => {
        const newSelectedJobs = selectedJobIdsRef.current.includes(job._id)
            ? selectedJobIdsRef.current.filter(id => id !== job._id)
            : [...selectedJobIdsRef.current, job._id]
        onSetSelectedJobs(newSelectedJobs)
        setBulkActionsDisabled(newSelectedJobs.length === 0)
    };

    const onClearSelection = () => {
        onSetSelectedJobs([])
    }

    useAppStateChange({
        onUpdatedSelectedCompany: (action: IAction) => {
            console.log("== onUpdatedSelectedCompany ==")
            onSetSelectedJobs([])
        },
        onUpdatedJobs: (action: IAction) => {
            // console.log("== onUpdatedJobs ==")
        }
    })

    const onSelectedJob = (job: Job) => {
        fn_selectJob(job)
    }

    const onEndReached = () => {
        if (fn_onEndReached) {
            fn_onEndReached()
        }
    }

    const regularJobListItem = useCallback(({ item }: { item: Job }) => {
        const driver = getDriverForJob(item)
        const client = getClientForJob(item)
        return (
            <View style={{ flex: 1 }}>

                <JobListItem
                    selectedJobId={selectedJobId as string}
                    job={item}
                    driver={driver}
                    client={client}
                    var_viewedBy={var_viewedBy || "vendor"}
                    fn_zoomToMarker={fn_zoomToMarker}
                    fn_selectJob={onSelectedJob}
                    fn_setDirections={fn_setDirections}
                    fn_openAssign={fn_openAssign}
                    fn_vendorAcceptJob={fn_vendorAcceptJob}
                />
            </View>
        )
    }, [selectedJobId, drivers, selectedJobs]);

    const isSelected = useCallback((job: Job) => {
        return selectedJobIdsRef.current.includes(job._id);
    }, [selectedJobIdsRef.current]);

    const RenderMemoizedList = useMemo(() => {
        return (
            <List
                data={delayedJobsState.map(j => new Job(j))}
                renderItem={({ item }) => {
                    return showCompact ?
                        <CompactJobListItem
                            job={item}
                            driver={getDriverForJob(item)}
                            client={getClientForJob(item)}
                            onAssign={fn_openAssign}
                            onViewDetails={onSelectedJob}
                            isSelected={isSelected(item)}
                            onCheckboxChange={() => toggleJobSelection(item)}
                            containerWidth={containerWidth}
                            selectedJobId={selectedJobId}
                        />
                        : regularJobListItem({ item })
                }}
                initialNumToRender={10}
                maxToRenderPerBatch={10}
                keyExtractor={(item) => item._id}
                onEndReached={fn_onEndReached}
                onEndReachedThreshold={0.5}
                getItemLayout={getItemLayout}
                extraData={delayedJobsState}
            />
        );
    }, [showCompact, containerWidth, delayedJobsState, selectedJobsRef.current, selectedJobId]);

    const showConfirmationModal = (action: () => void, actionName: string) => {
        const affectedJobs = selectedJobsRef.current.map(job => job.friendly_id).join('\n');
        setConfirmationMessage(`Are you sure you want to ${actionName} the following jobs?\n\n${affectedJobs}`);
        setConfirmationAction(() => action);
        setConfirmationModalVisible(true);
    };

    const onBulkAssign = async (driver: Member) => {
        showConfirmationModal(() => {
            const driver_id = driver._id;
            const company = StorageController.getCurrentCompany();
            if (!company) {
                console.error("== No company found ==");
                return;
            }
            JobController.assignMultipleJobsToMember(selectedJobIdsRef.current, driver_id, company._id);
            setBulkAssignJobModalVisible(false);
        }, 'assign');
    };

    const onBulkUnassign = async () => {
        showConfirmationModal(() => {
            JobController.setJobsUnassignedMultiple(selectedJobsRef.current);
            setBulkAssignJobModalVisible(false);
        }, 'unassign');
    };

    const onBulkSetPending = async () => {
        showConfirmationModal(() => {
            JobController.setJobsPendingMultiple(selectedJobsRef.current);
            setBulkAssignJobModalVisible(false);
        }, 'set as pending');
    };

    const onBulkComplete = async () => {
        showConfirmationModal(() => {
            JobController.setJobsCompleteMultiple(selectedJobsRef.current);
            setBulkAssignJobModalVisible(false);
        }, 'complete');
    };

    const onBulkCancel = async () => {
        showConfirmationModal(() => {
            JobController.setJobCancelledMultiple(selectedJobsRef.current);
            setBulkAssignJobModalVisible(false);
        }, 'cancel');
    };

    const RenderMemoizedBulkActionButtons = React.useCallback(() => {
        const jobsSelected = selectedJobsRef.current.length > 0;
        const canUnassign = jobsSelected && selectedJobsRef.current.every(job => job.status === JOB_STATUS.ASSIGNED);
        const canAssign = jobsSelected && selectedJobsRef.current.every(job => job.status === JOB_STATUS.UNASSIGNED);
        const canSetPending = jobsSelected && selectedJobsRef.current.every(job => job.status === JOB_STATUS.ASSIGNED);
        const canComplete = jobsSelected && selectedJobsRef.current.every(job => job.status === JOB_STATUS.PENDING);
        const canCancel = jobsSelected && selectedJobsRef.current.every(job => [JOB_STATUS.UNASSIGNED, JOB_STATUS.ASSIGNED, JOB_STATUS.PENDING].includes(job.status));
        const canClear = jobsSelected
        return (
            <View style={styles.bulkActionBar}>
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="unassigned"
                    accessoryLeft={(props) => <Icon {...props} name="person-remove-outline" />}
                    onPress={onBulkUnassign}
                    disabled={bulkActionsDisabled || !canUnassign}
                >
                    Unassign
                </Button>
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="assigned"
                    accessoryLeft={(props) => <Icon {...props} name="person-add-outline" />}
                    onPress={() => setBulkAssignJobModalVisible(true)}
                    disabled={bulkActionsDisabled || !canAssign}
                >
                    Assign
                </Button>
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="pending"
                    accessoryLeft={(props) => <Icon {...props} name="clock-outline" />}
                    onPress={onBulkSetPending}
                    disabled={bulkActionsDisabled || !canSetPending}
                >
                    Pending
                </Button>
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="complete"
                    accessoryLeft={(props) => <Icon {...props} name="checkmark-circle-2-outline" />}
                    onPress={onBulkComplete}
                    disabled={bulkActionsDisabled || !canComplete}
                >
                    Complete
                </Button>
                {/* <Button
                    style={styles.bulkActionButton}
                    size="small"
                    status="cancelled"
                    accessoryLeft={(props) => <Icon {...props} name="close-circle-outline" />}
                    onPress={onBulkCancel}
                    disabled={bulkActionsDisabled || !canCancel}
                >
                    Cancel
                </Button> */}
                <Button
                    style={styles.bulkActionButton}
                    size="small"
                    appearance='ghost'
                    status="primary"
                    onPress={onClearSelection}
                    disabled={bulkActionsDisabled || !jobsSelected}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                            <Icon name="close-outline" fill={theme['color-primary-500']} style={{ width: 16, height: 16 }} />
                            <Text style={{ fontSize: 12, color: jobsSelected ? theme['color-primary-500'] : theme['color-basic-500'] }} numberOfLines={1}>Clear</Text>
                            <Text style={{ fontSize: 12, color: jobsSelected ? theme['color-primary-500'] : theme['color-basic-500'] }} numberOfLines={1}>({selectedJobsRef.current.length})</Text>
                        </View>
                    </View>
                </Button>
            </View>
        );
    }, [bulkActionsDisabled, selectedJobs]);

    return (
        <View style={styles.container} onLayout={onLayoutChange}>
            <OrderByControls
                sortBy={sortBy}
                setSortBy={setSortBy}
                showOrderControls={showOrderControls}
                setShowOrderControls={setShowOrderControls}
            />
            <BulkActionConfirmationModal
                visible={confirmationModalVisible}
                message={confirmationMessage}
                onConfirm={() => {
                    confirmationAction();
                    setConfirmationModalVisible(false);
                }}
                onCancel={() => setConfirmationModalVisible(false)}
            />
            {showCompact && (
                <RenderMemoizedBulkActionButtons />
            )}
            {RenderMemoizedList}
            <AssignJobModal
                var_member_id={null}
                var_assignJobModalVisible={bulkAssignJobModalVisible}
                selectedJob={null}
                fn_assignMember={onBulkAssign}
                fn_dismiss={() => setBulkAssignJobModalVisible(false)}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    bulkActionBar: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 8,
        flexWrap: 'wrap',
        gap: 4,
    },
    bulkActionButton: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '15%',
        minWidth: 80,
        maxWidth: 120,
    },
});


// Add new enum for sort options
export enum JOBS_LIST_SORT_BY {
    DEFAULT = 'default',
    START_TIME_ASC = 'start_time_asc',
    START_TIME_DESC = 'start_time_desc',
    UPDATED_TIME_ASC = 'updated_time_asc',
    UPDATED_TIME_DESC = 'updated_time_desc',
    PRIORITY_ASC = 'priority_asc',
    PRIORITY_DESC = 'priority_desc'
}

// Add new OrderByControls component
export const OrderByControls = ({ sortBy, setSortBy, showOrderControls, setShowOrderControls }: {
    sortBy: JOBS_LIST_SORT_BY,
    setSortBy: (sort: JOBS_LIST_SORT_BY) => void,
    showOrderControls: boolean,
    setShowOrderControls: (show: boolean) => void
}) => {
    const theme = useTheme();

    return (
        <View style={{ padding: 2, marginTop: 2 }}>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 4 }}>
                <Button
                    size="tiny"
                    style={{ flex: 1 }}
                    status={sortBy === JOBS_LIST_SORT_BY.DEFAULT ? 'primary' : 'basic'}
                    appearance={sortBy === JOBS_LIST_SORT_BY.DEFAULT ? 'filled' : 'outline'}
                    onPress={() => setSortBy(JOBS_LIST_SORT_BY.DEFAULT)}
                    accessoryLeft={(props) => <Icon {...props} name="clock-outline" />}
                >
                    Default
                </Button>
                <Button
                    size="tiny"
                    style={{ flex: 1 }}
                    status={sortBy === JOBS_LIST_SORT_BY.START_TIME_DESC ? 'warning' : 'basic'}
                    appearance={sortBy === JOBS_LIST_SORT_BY.START_TIME_DESC ? 'filled' : 'outline'}

                    onPress={() => setSortBy(JOBS_LIST_SORT_BY.START_TIME_DESC)}
                    accessoryLeft={(props) => <Icon {...props} name="clock-outline" />}
                >
                    Newest
                </Button>
                <Button
                    size="tiny"
                    style={{ flex: 1 }}
                    status={sortBy === JOBS_LIST_SORT_BY.START_TIME_ASC ? 'danger' : 'basic'}
                    appearance={sortBy === JOBS_LIST_SORT_BY.START_TIME_ASC ? 'filled' : 'outline'}

                    onPress={() => setSortBy(JOBS_LIST_SORT_BY.START_TIME_ASC)}
                    accessoryLeft={(props) => <Icon {...props} name="clock-outline" />}
                >
                    Oldest
                </Button>
                <Button
                    size="tiny"
                    style={{ flex: 1 }}
                    status={sortBy === JOBS_LIST_SORT_BY.UPDATED_TIME_DESC ? 'info' : 'basic'}
                    appearance={sortBy === JOBS_LIST_SORT_BY.UPDATED_TIME_DESC ? 'filled' : 'outline'}

                    onPress={() => setSortBy(JOBS_LIST_SORT_BY.UPDATED_TIME_DESC)}
                    accessoryLeft={(props) => <Icon {...props} name="refresh-outline" />}
                >
                    Updated
                </Button>
                {/* <Button
                    size="tiny"
                    appearance={sortBy === JOBS_LIST_SORT_BY.UPDATED_TIME_ASC ? 'filled' : 'outline'}
                    onPress={() => setSortBy(JOBS_LIST_SORT_BY.UPDATED_TIME_ASC)}
                    accessoryLeft={(props) => <Icon {...props} name="refresh-outline" />}
                >
                    Oldest
                </Button> */}
                {/* <Button
                    size="tiny"
                    appearance={sortBy === JOBS_LIST_SORT_BY.PRIORITY_DESC ? 'filled' : 'outline'}
                    onPress={() => setSortBy(JOBS_LIST_SORT_BY.PRIORITY_DESC)}
                    accessoryLeft={(props) => <Icon {...props} name="alert-triangle-outline" />}
                >
                    Overdue
                </Button> */}
                <Button
                    size="tiny"
                    style={{ flex: 1 }}
                    status={sortBy === JOBS_LIST_SORT_BY.PRIORITY_ASC ? 'primary' : 'basic'}
                    appearance={sortBy === JOBS_LIST_SORT_BY.PRIORITY_ASC ? 'filled' : 'outline'}

                    onPress={() => setSortBy(JOBS_LIST_SORT_BY.PRIORITY_ASC)}
                    accessoryLeft={(props) => <Icon {...props} name="alert-triangle-outline" />}
                >
                    Priority
                </Button>
            </View>
        </View>
    );
};

export default JobsList;