import { useState, useRef, useEffect } from 'react';
import { JobTowingDetails, TowingLocation, JobLocation, JobAddress, TowingLeg, JOB_TOWING_START_END_OPTION } from '../../../../models/Job.model';
import * as GoogleController from '../../../../functions/google.controller';
import * as OsrmController from '../../../../functions/osrm.controller';
import * as StorageController from '../../../../functions/storageController';

interface UseTowingLocationDetailsProps {
    defaultTowingLocationObj?: JobTowingDetails;
    validateOnLoad?: boolean;
    onChangeTowingLocations?: (t: any) => void;
    onAddCostToItems?: (c: any) => void;
}

export const useTowingLocationDetails = ({
    defaultTowingLocationObj = new JobTowingDetails({}),
    validateOnLoad = false,
    onChangeTowingLocations = (t: any) => { },
    onAddCostToItems = (c: any) => { },
}: UseTowingLocationDetailsProps) => {
    // State
    const [jobTowingDetails, setJobTowingDetails] = useState(defaultTowingLocationObj || new JobTowingDetails({}));
    const [pickupTowingLocation, setPickupTowingLocation] = useState(
        jobTowingDetails.pickup_towing_location ||
        new TowingLocation({
            address: jobTowingDetails.pickup_address,
            location: jobTowingDetails.pickup_location,
            address_type: jobTowingDetails.pickup_address_type,
            location_type: jobTowingDetails.pickup_location_type,
            holding_reason: jobTowingDetails.pickup_holding_reason,
        }) ||
        null
    );
    const [dropoffTowingLocation, setDropoffTowingLocation] = useState(
        jobTowingDetails.dropoff_towing_location ||
        new TowingLocation({
            address: jobTowingDetails.dropoff_address,
            location: jobTowingDetails.dropoff_location,
            address_type: jobTowingDetails.dropoff_address_type,
            location_type: jobTowingDetails.dropoff_location_type,
            holding_reason: jobTowingDetails.dropoff_holding_reason,
        }) ||
        null
    );
    const [original_dropoff_towing_location, setOriginalDropoffTowingLocation] = useState<TowingLocation | null>(jobTowingDetails.original_dropoff_towing_location || null);
    const [costPerKm, setCostPerKm] = useState(jobTowingDetails.cost_per_km || "");
    const [coveredDistance, setCoveredDistance] = useState(jobTowingDetails.covered_distance_kms || "");
    const [directionsPolyline, setDirectionsPolyline] = useState(jobTowingDetails.directions_polyline || null);
    const [directionsPolylines, setDirectionsPolylines] = useState([] as any[]);
    const [addCoveredCostToBillBack, setAddCoveredCostToBillBack] = useState(false);
    const [selectedLocationIndex, setSelectedLocationIndex] = useState(0);
    const [showLocationSelectInfoWindow, setShowLocationSelectInfoWindow] = useState(false);
    const [InfoWindowListSelectedIndex, setInfoWindowListSelectedIndex] = useState(0);
    const [locationSearchResults, setLocationSearchResults] = useState([] as JobAddress[] | null);
    const [startEndOption, setStartEndOption] = useState(jobTowingDetails.start_end_option || JOB_TOWING_START_END_OPTION.NONE);
    const [startLocation, setStartLocation] = useState(jobTowingDetails.start_location || null);
    const [endLocation, setEndLocation] = useState(jobTowingDetails.end_location || null);

    // Refs
    const jobTowingDetailsRef = useRef(jobTowingDetails || new JobTowingDetails({}));
    const distanceValueRef = useRef(0);
    const durationValueRef = useRef(0);
    const costPerKmRef = useRef(costPerKm);
    const coveredDistanceRef = useRef(coveredDistance);
    const totalCostRef = useRef(0);

    // Get company location
    const companyLocation = StorageController.getCurrentCompany().settings?.location?.coords || { lat: 0, lng: 0, lon: 0 };
    const [center, setCenter] = useState(companyLocation);

    useEffect(() => {
        const companySettings = StorageController.getCurrentCompany().settings;
        if (companySettings?.job_options?.towingOptions?.default_cost_per_km) {
            setCostPerKm(companySettings.job_options.towingOptions.default_cost_per_km.toString());
        }
        if (companySettings?.job_options?.towingOptions?.default_covered_distance_km) {
            setCoveredDistance(companySettings.job_options.towingOptions.default_covered_distance_km.toString());
        }
    }, []);

    useEffect(() => {
        let _towingLocationObj = new JobTowingDetails(defaultTowingLocationObj);
        setJobTowingDetails(_towingLocationObj);
        if (_towingLocationObj.towing_legs && _towingLocationObj.towing_legs.length > 0) {
            const polylines = _towingLocationObj.towing_legs.map((towingLeg: TowingLeg) => {
                return towingLeg.directions.decodePolyline();
            }) || [];
            setDirectionsPolylines(polylines as any);
        }
        jobTowingDetailsRef.current = _towingLocationObj;
    }, [defaultTowingLocationObj]);

    useEffect(() => {
        const hasPickupAndDropoff = pickupTowingLocation && dropoffTowingLocation;
        const hasTowingLegs = jobTowingDetails.towing_legs && jobTowingDetails.towing_legs.length > 0;
        const towingLegDistance = jobTowingDetails.towing_legs[0]?.directions?.distance_value;

        if (validateOnLoad) {
            validateTowingLegs();
        } else if (hasPickupAndDropoff && !hasTowingLegs) {
            validateTowingLegs();
        } else if (hasPickupAndDropoff && hasTowingLegs && towingLegDistance == 0) {
            validateTowingLegs();
        }
    }, []);

    const onSetTowingDetails = (towingDetailsObj: JobTowingDetails, caller: any = null) => {
        const towingDetails = new JobTowingDetails(towingDetailsObj);
        if (towingDetails.towing_locations?.length > 0) {
            towingDetails.towing_locations[0].name = "Pickup";
            towingDetails.towing_locations[towingDetails.towing_locations.length - 1].name = "Dropoff";
        }

        const totalDistance = towingDetails.getTotalDistance() / 1000;
        distanceValueRef.current = totalDistance;
        durationValueRef.current = towingDetails.getTotalDuration() / 60;
        costPerKmRef.current = costPerKm;
        coveredDistanceRef.current = coveredDistance;
        totalCostRef.current = totalDistance * Number(costPerKm);

        if (towingDetails.towing_legs?.length > 0) {
            const polylines = towingDetails.towing_legs.map(towingLeg => {
                return towingLeg.directions.decodePolyline();
            }) || [];
            setDirectionsPolylines(polylines as any);
        }

        setJobTowingDetails(towingDetails);
        jobTowingDetailsRef.current = towingDetails;
        setPickupTowingLocation(towingDetailsObj.pickup_towing_location);
        setDropoffTowingLocation(towingDetailsObj.dropoff_towing_location);
        if (towingDetailsObj.original_dropoff_towing_location) {
            setOriginalDropoffTowingLocation(new TowingLocation(towingDetailsObj.original_dropoff_towing_location));
        } else {
            setOriginalDropoffTowingLocation(null);
        }
        onChangeTowingLocations(towingDetails);
    };

    const findFirstNullLocationIndex = () => {
        let index = 0;
        for (const location of jobTowingDetailsRef.current.towing_locations) {
            if (!location.location.lat || !location.location.lng) {
                return index;
            }
            index++;
        }
        return -1;
    };

    const updateOrCreateTowingLeg = async (fromIndex: number, toIndex: number) => {
        const fromLocation = jobTowingDetailsRef.current.towing_locations[fromIndex]?.location;
        const toLocation = jobTowingDetailsRef.current.towing_locations[toIndex]?.location;

        if (!fromLocation || !toLocation) return null;
        if (toLocation.lat == 0 && toLocation.lng == 0) return null;
        if (fromLocation.lat == 0 && fromLocation.lng == 0) return null;

        const osrmDirections = await OsrmController.getJobDirections(fromLocation, toLocation);

        return new TowingLeg({
            index: jobTowingDetailsRef.current.towing_legs.length,
            origin_towing_location_index: fromIndex ?? 0,
            destination_towing_location_index: toIndex,
            origin: fromLocation,
            destination: toLocation,
            directions: osrmDirections,
            cost_per_km: costPerKmRef.current,
            covered_distance_kms: coveredDistanceRef.current,
            total_cost: totalCostRef.current,
        });
    };

    const validateTowingLegs = async () => {
        try {
            let shouldUpdate = false;
            jobTowingDetailsRef.current.towing_legs = [];
            jobTowingDetailsRef.current.start_leg = null;
            jobTowingDetailsRef.current.end_leg = null;

            let numberOfLegs = 0;

            for (let i = 0; i < jobTowingDetailsRef.current.towing_locations.length - 1; i++) {
                const newLeg = await updateOrCreateTowingLeg(i, i + 1);
                if (newLeg) {
                    numberOfLegs++;
                    jobTowingDetailsRef.current.addTowingLegAtIndex(newLeg, i);
                    shouldUpdate = true;
                }
            }

            switch (jobTowingDetailsRef.current.start_end_option) {
                case JOB_TOWING_START_END_OPTION.ROUND_TRIP:
                    if (jobTowingDetailsRef.current.start_location) {
                        const startLeg = await createStartLeg();
                        if (startLeg) {
                            numberOfLegs++;
                            jobTowingDetailsRef.current.start_leg = startLeg;
                            shouldUpdate = true;
                        }

                        jobTowingDetailsRef.current.end_location = new TowingLocation({ ...jobTowingDetailsRef.current.start_location });
                        const endLeg = await createEndLeg();
                        if (endLeg) {
                            numberOfLegs++;
                            jobTowingDetailsRef.current.end_leg = endLeg;
                            shouldUpdate = true;
                        }
                    } else {
                        if (jobTowingDetailsRef.current.start_leg || jobTowingDetailsRef.current.end_leg) {
                            jobTowingDetailsRef.current.start_leg = null;
                            jobTowingDetailsRef.current.end_leg = null;
                            shouldUpdate = true;
                        }
                    }
                    break;
                case JOB_TOWING_START_END_OPTION.START_ONLY:
                    if (jobTowingDetailsRef.current.start_location) {
                        const startLeg = await createStartLeg();
                        if (startLeg) {
                            numberOfLegs++;
                            jobTowingDetailsRef.current.start_leg = startLeg;
                            shouldUpdate = true;
                        }
                    } else {
                        if (jobTowingDetailsRef.current.start_leg) {
                            jobTowingDetailsRef.current.start_leg = null;
                            shouldUpdate = true;
                        }
                    }
                    break;
                case JOB_TOWING_START_END_OPTION.END_ONLY:
                    if (jobTowingDetailsRef.current.end_location) {
                        const endLeg = await createEndLeg();
                        if (endLeg) {
                            numberOfLegs++;
                            jobTowingDetailsRef.current.end_leg = endLeg;
                            shouldUpdate = true;
                        }
                    } else {
                        if (jobTowingDetailsRef.current.end_leg) {
                            jobTowingDetailsRef.current.end_leg = null;
                            shouldUpdate = true;
                        }
                    }
                    break;
                case JOB_TOWING_START_END_OPTION.START_END:
                    if (jobTowingDetailsRef.current.start_location) {
                        const startLeg = await createStartLeg();
                        if (startLeg) {
                            numberOfLegs++;
                            jobTowingDetailsRef.current.start_leg = startLeg;
                            shouldUpdate = true;
                        }
                    }
                    if (jobTowingDetailsRef.current.end_location) {
                        const endLeg = await createEndLeg();
                        if (endLeg) {
                            numberOfLegs++;
                            jobTowingDetailsRef.current.end_leg = endLeg;
                            shouldUpdate = true;
                        }
                    }
                    break;
                case JOB_TOWING_START_END_OPTION.NONE:
                    jobTowingDetailsRef.current.start_leg = null;
                    jobTowingDetailsRef.current.end_leg = null;
                    jobTowingDetailsRef.current.start_location = null;
                    jobTowingDetailsRef.current.end_location = null;
                    shouldUpdate = true;
                    break;
            }

            if (numberOfLegs == 0) {
                jobTowingDetailsRef.current.start_leg = null;
                jobTowingDetailsRef.current.end_leg = null;
                shouldUpdate = true;
                setDirectionsPolylines([]);
            }

            if (shouldUpdate) {
                onSetLegs(jobTowingDetailsRef.current.towing_legs);
            }
        } catch (e) {
            console.log("Error in validateTowingLegs:", e);
        }
    };

    const onSetLegs = (legs: TowingLeg[]) => {
        const uniqueLegs = legs.reduce((acc, leg) => {
            const key = `${leg.origin_towing_location_index}-${leg.destination_towing_location_index}`;
            if (!acc.has(key)) {
                acc.set(key, leg);
            }
            return acc;
        }, new Map());

        const validLegs = Array.from(uniqueLegs.values());

        jobTowingDetailsRef.current.towing_legs = validLegs;
        jobTowingDetailsRef.current.reIndexTowingLegs();

        if (validLegs && validLegs.length > 0) {
            const polylines = validLegs.map((towingLeg: TowingLeg) => {
                return GoogleController.decodePolyline(towingLeg.directions.polyline);
            });
            setDirectionsPolylines(polylines as any);
        }
        onSetTowingDetails(jobTowingDetailsRef.current, "onSetLegs");
    };

    const createStartLeg = async () => {
        if (!jobTowingDetailsRef.current.start_location) return null;
        const pickup = jobTowingDetailsRef.current.towing_locations[0].location;
        if (!pickup.lat || pickup.lat == 0) return null;
        if (!jobTowingDetailsRef.current.start_location.location) return null;
        const osrmDirections = await OsrmController.getJobDirections(
            jobTowingDetailsRef.current.start_location.location,
            pickup
        );
        return new TowingLeg({
            index: -1,
            origin_towing_location_index: -1,
            destination_towing_location_index: 0,
            origin: jobTowingDetailsRef.current.start_location,
            destination: pickup,
            directions: osrmDirections,
            cost_per_km: costPerKmRef.current,
            covered_distance_kms: coveredDistanceRef.current,
            total_cost: totalCostRef.current,
        });
    };

    const createEndLeg = async () => {
        if (!jobTowingDetailsRef.current.end_location) return null;
        const dropoff = jobTowingDetailsRef.current.towing_locations[jobTowingDetailsRef.current.towing_locations.length - 1].location;
        if (!dropoff.lat || dropoff.lat == 0) return null;
        if (!jobTowingDetailsRef.current.end_location.location) return null;
        const osrmDirections = await OsrmController.getJobDirections(
            dropoff,
            jobTowingDetailsRef.current.end_location.location
        );
        return new TowingLeg({
            index: -2,
            origin_towing_location_index: jobTowingDetailsRef.current.towing_locations.length - 1,
            destination_towing_location_index: -2,
            origin: dropoff,
            destination: jobTowingDetailsRef.current.end_location,
            directions: osrmDirections,
            cost_per_km: costPerKmRef.current,
            covered_distance_kms: coveredDistanceRef.current,
            total_cost: totalCostRef.current,
        });
    };

    const onMapClick = async (event: any) => {
        try {
            const location = event.latLng.toJSON();
            const addressObj = await getAddressFromLatLng(event.latLng.toJSON().lat, event.latLng.toJSON().lng);
            const address = new JobAddress(addressObj?.address);
            let index = findFirstNullLocationIndex();
            if (index == -1) {
                jobTowingDetailsRef.current.addNewLocationAtIndex(jobTowingDetailsRef.current.towing_locations.length);
                index = jobTowingDetailsRef.current.towing_locations.length - 1;
            }
            jobTowingDetailsRef.current.towing_locations[index].location = location;
            jobTowingDetailsRef.current.towing_locations[index].address = address;
            onSetTowingDetails(jobTowingDetailsRef.current, "onMapClick");
            setTimeout(() => {
                validateTowingLegs();
            }, 100);
        } catch (e) {
            console.log("Error in onMapClick:", e);
        }
    };

    const onDragMarker = async (location: JobLocation, index: number) => {
        setSelectedLocationIndex(index);
        const locations = await GoogleController.getAddressFromLatLngAllResultsAsDMAddress(location.lat, location.lng);
        if (locations) {
            setLocationSearchResults(locations);
            setInfoWindowListSelectedIndex(index);
            setShowLocationSelectInfoWindow(true);
        }
    };

    const getAddressFromLatLng = async (lat: any, lng: any, type: string = "none") => {
        return await GoogleController.getAddressFromLatLng(lat, lng);
    };

    const onAddTowingCostToItems = (items: any[]) => {
        onAddCostToItems(items);
    };

    const addNewLocation = (index: number) => {
        const newTowingLocations = jobTowingDetails.addNewLocationAtIndex(index);
        jobTowingDetails.towing_locations = newTowingLocations;
        onSetTowingDetails(jobTowingDetails, "addNewLocation");
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const onRemoveAndSaveAsOriginalDestination = (index: number) => {
        // First save the location we want to set as original
        // const locationToSave = new TowingLocation(jobTowingDetailsRef.current.towing_locations[index]);
        // Then remove it and update locations
        jobTowingDetailsRef.current.removeLocationAndSetAsOriginalDropoffAtIndex(index);
        // Set it as the original dropoff location
        setOriginalDropoffTowingLocation(jobTowingDetailsRef.current.original_dropoff_towing_location);
        onSetTowingDetails(jobTowingDetailsRef.current, "onRemoveAndSaveAsOriginalDestination");
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const removeOriginalDropoffLocation = () => {
        setOriginalDropoffTowingLocation(null);
        const newTowingLocations = jobTowingDetailsRef.current.removeOriginalDropoffLocation();
        jobTowingDetailsRef.current.towing_locations = newTowingLocations;
        onSetTowingDetails(jobTowingDetailsRef.current, "removeOriginalDropoffLocation");
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const onLocationObjectChange = (location: TowingLocation, index: number) => {
        let tDetails = new JobTowingDetails(jobTowingDetailsRef.current);
        tDetails.updateLocationAtIndex(index, location);
        onChangeTowingLocations(tDetails);
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const onAddressChange = (address: JobAddress, index: number) => {
        let tDetails = new JobTowingDetails(jobTowingDetailsRef.current);
        tDetails.towing_locations[index].address = address;
        onChangeTowingLocations(tDetails);
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const onLocationChange = (location: JobLocation, index: number) => {
        let tDetails = new JobTowingDetails(jobTowingDetailsRef.current);
        tDetails.towing_locations[index].location = location;
        onChangeTowingLocations(tDetails);
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const onAddressLocationChange = (address: JobAddress, location: JobLocation, index: number) => {
        let tDetails = new JobTowingDetails(jobTowingDetailsRef.current);
        tDetails.towing_locations[index].address = address;
        tDetails.towing_locations[index].location = location;
        onChangeTowingLocations(tDetails);
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const onSavedAddressSelect = (savedAddress: any, index: number) => {
        let tDetails = new JobTowingDetails(jobTowingDetailsRef.current);
        tDetails.towing_locations[index].address = savedAddress.address;
        tDetails.towing_locations[index].location = new JobLocation({
            lat: savedAddress.location.lat,
            lng: savedAddress.location.lng,
            latitude: savedAddress.location.lat,
            longitude: savedAddress.location.lng,
            lon: savedAddress.location.lng
        });
        tDetails.towing_locations[index].location_type = savedAddress.type;
        onChangeTowingLocations(tDetails);
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const onRemoveLocation = (index: number) => {
        let tDetails = new JobTowingDetails(jobTowingDetailsRef.current);
        const newTowingLocations = tDetails.removeLocationAtIndex(index);
        tDetails.towing_locations = newTowingLocations;
        onChangeTowingLocations(tDetails);
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const onLocationSelect = (location: any) => {
        let tDetails = new JobTowingDetails(jobTowingDetailsRef.current);
        tDetails.towing_locations[InfoWindowListSelectedIndex].address = location;
        tDetails.towing_locations[InfoWindowListSelectedIndex].location = new JobLocation({
            lat: location.location.lat,
            lng: location.location.lng,
            latitude: location.location.lat,
            longitude: location.location.lng,
            lon: location.location.lng
        });
        onChangeTowingLocations(tDetails);
        setShowLocationSelectInfoWindow(false);
        setInfoWindowListSelectedIndex(0);
        setTimeout(() => {
            validateTowingLegs();
        }, 100);
    };

    const handleRouteTypeChange = (newOption: JOB_TOWING_START_END_OPTION) => {
        setStartEndOption(newOption);
        let tDetails = new JobTowingDetails(jobTowingDetailsRef.current);
        tDetails.start_end_option = newOption;

        switch (newOption) {
            case JOB_TOWING_START_END_OPTION.NONE:
                setStartLocation(null);
                setEndLocation(null);
                tDetails.start_location = null;
                tDetails.end_location = null;
                tDetails.start_leg = null;
                tDetails.end_leg = null;
                break;
            case JOB_TOWING_START_END_OPTION.START_ONLY:
                tDetails.start_location = jobTowingDetailsRef.current.start_location;
                tDetails.end_location = null;
                tDetails.start_leg = jobTowingDetailsRef.current.start_leg;
                tDetails.end_leg = null;
                break;
            case JOB_TOWING_START_END_OPTION.END_ONLY:
                tDetails.start_location = null;
                tDetails.end_location = jobTowingDetailsRef.current.end_location;
                tDetails.start_leg = null;
                tDetails.end_leg = jobTowingDetailsRef.current.end_leg;
                break;
            case JOB_TOWING_START_END_OPTION.ROUND_TRIP:
                tDetails.start_location = jobTowingDetailsRef.current.start_location;
                if (jobTowingDetailsRef.current.start_location) {
                    tDetails.end_location = new TowingLocation({
                        ...jobTowingDetailsRef.current.start_location,
                        name: "End"
                    });
                    setEndLocation(tDetails.end_location);
                } else {
                    tDetails.end_location = null;
                }
                break;
            default:
                tDetails.start_location = jobTowingDetailsRef.current.start_location;
                tDetails.end_location = jobTowingDetailsRef.current.end_location;
                tDetails.start_leg = jobTowingDetailsRef.current.start_leg;
                tDetails.end_leg = jobTowingDetailsRef.current.end_leg;
        }

        onSetTowingDetails(tDetails, "handleRouteTypeChange");
        validateTowingLegs();
    };

    return {
        // State
        jobTowingDetails,
        pickupTowingLocation,
        dropoffTowingLocation,
        original_dropoff_towing_location,
        costPerKm,
        coveredDistance,
        directionsPolyline,
        directionsPolylines,
        addCoveredCostToBillBack,
        selectedLocationIndex,
        showLocationSelectInfoWindow,
        InfoWindowListSelectedIndex,
        locationSearchResults,
        startEndOption,
        startLocation,
        endLocation,
        center,

        // State setters
        setCostPerKm,
        setCoveredDistance,
        setAddCoveredCostToBillBack,
        setInfoWindowListSelectedIndex,
        setShowLocationSelectInfoWindow,

        // Methods
        onSetTowingDetails,
        validateTowingLegs,
        onMapClick,
        onDragMarker,
        onAddTowingCostToItems,
        addNewLocation,
        removeOriginalDropoffLocation,
        onRemoveAndSaveAsOriginalDestination,
        handleRouteTypeChange,
        onLocationObjectChange,
        onAddressChange,
        onLocationChange,
        onAddressLocationChange,
        onSavedAddressSelect,
        onRemoveLocation,
        onLocationSelect,
    };
}; 