import React, { useState, useEffect, useMemo } from 'react';
import {
    StyleSheet,
    View,
    useWindowDimensions,
    Modal, // Import React Native Modal
    TouchableWithoutFeedback,
    TextInputEndEditingEventData,
    //@ts-ignore
} from "react-native";
import {
    Card,
    Button,
    Icon,
    Input,
    List,
    ListItem,
    Divider,
    Text,
    Layout,
    OverflowMenu,
    useTheme,
    IndexPath,
    MenuItem,
} from "@ui-kitten/components";
import * as StorageController from '../../../../functions/storageController';
import * as CompanyController from '../../../../functions/company.controller';
import ErrorBoundary from '../../../ErrorBoundary.component';
import Toast from 'react-native-toast-message';
import { LineItem, Job, LINE_ITEM_BILLED_TO } from '../../../../models/Job.model';
import { InventoryItem, InventoryCategory, ExtendedInventoryCategory, ExtendedInventoryItem } from '../../../../models/Inventory.model';
import { Company } from '../../../../models/Company.model';
import { VendorProfile } from '../../../../models/VendorProfile.model';
//@ts-ignore
import { ListItemProps } from '@ui-kitten/components';
import * as InventoryController from '../../../../functions/Inventory.controller';
import { LineItemComponent } from './LineItem.component'; // Added import
import InventoryItemDetail from '../../../inventory/InventoryItemDetail.component';
import { ScrollView } from 'react-native-gesture-handler';
import { NativeSyntheticEvent } from 'react-native';
import { Client } from '../../../../models/Client.model';
import { PricingTier } from '../../../../models/Inventory.model';

interface InventoryDetailsCardProps {
    style?: any;
    selectedItems: LineItem[];
    onChangeSelectedItems: (items: LineItem[]) => void;
    vendorProfile: VendorProfile | null | undefined;
    jobSource: string;
    allowEdit?: boolean;
    selectedClient?: Client | null;
}

export const InventoryDetailsCard = ({
    style,
    selectedItems = [],
    onChangeSelectedItems = (items) => { },
    vendorProfile,
    jobSource,
    allowEdit = true,
    selectedClient
}: InventoryDetailsCardProps) => {

    const theme = useTheme();
    const { width: windowWidth } = useWindowDimensions();
    const [companyInventoryList, setCompanyInventoryList] = useState<ExtendedInventoryCategory[]>([]);
    const [selectedLineItems, setSelectedLineItems] = useState<LineItem[]>(selectedItems);
    const [totalSelectedInventoryCost, setTotalSelectedInventoryCost] = useState<string>('0.00');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
    const [newLineItem, setNewLineItem] = useState<LineItem | null>(null); // New state for item name
    const [filteredItems, setFilteredItems] = useState<InventoryItem[]>([]); // New state for filtered items
    const [menuSearchQuery, setMenuSearchQuery] = useState<string>('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [isInventoryListVisible, setIsInventoryListVisible] = useState(false);
    const [quantityError, setQuantityError] = useState('');
    const [priceError, setPriceError] = useState('');

    useEffect(() => {
        loadVendorInventory()
    }, [])

    const IS_SMALL_SCREEN = windowWidth < 768;



    useEffect(() => {
        // Added here because broke on holding item removing?
        setSelectedLineItems(selectedItems)
    }, [selectedItems])


    /**
    * Update the selected inventory list when the selected inventory items change
    */
    useEffect(() => {
        if (selectedItems != null && selectedItems.length > 0) {

            let total = 0;
            selectedItems.forEach(item => {
                if (!item.quantity) item.quantity = 1;
                if (!item.cost) item.cost = 0;
                total += item.cost * item.quantity;
            });
            setSelectedLineItems(selectedItems)
            setTotalSelectedInventoryCost(total.toFixed(2));
        }
    }, [selectedItems]);


    const onSetSelectedItems = (items: LineItem[]) => {
        onChangeSelectedItems(items);
    };

    const loadVendorInventory = async () => {
        if (jobSource === "client" && vendorProfile && vendorProfile.linkedVendorAccountId) {
            const linkedCompany = await CompanyController.getCompanyById(vendorProfile.linkedVendorAccountId) as Company;
            if (linkedCompany?.inventory?.length > 0) {
                const inv = await InventoryController.getInventoryCategoriesAndItemsByCompanyId(linkedCompany._id)
                setCompanyInventoryList(inv || [])
            }
        } else if (jobSource === "vendor" || jobSource === "local_client") {
            const company = StorageController.getCurrentCompany()
            const inv = await InventoryController.getInventoryCategoriesAndItemsByCompanyId(company._id)
            setCompanyInventoryList(inv || [])
        } else {
            setCompanyInventoryList([]);
        }
    };

    const memoBillBackItems = useMemo(() => {
        return selectedLineItems.filter(item => item.billAllBack)
    }, [selectedLineItems])

    const memoCustomerCostItems = useMemo(() => {
        return selectedLineItems.filter(item => item.customerCost)
    }, [selectedLineItems])

    const memoBillBackItemsTotal = useMemo(() => {
        let total = 0;
        memoBillBackItems.forEach(item => {
            total += item.cost * item.quantity;
        });
        return total.toFixed(2);
    }, [memoBillBackItems])

    const memoCustomerCostItemsTotal = useMemo(() => {
        let total = 0;
        memoCustomerCostItems.forEach(item => {
            total += item.cost * item.quantity;
        });
        return total.toFixed(2);
    }, [memoCustomerCostItems])


    const onAddItemToSelectedItems = async (item: InventoryItem) => {
        try {
            const category = companyInventoryList.find(c =>
                c.inventoryItems.some(i => i._id === item._id)
            );
            const tierPrice = category ? calculateItemTierPrice(item, category) : null;

            const lineItem = new LineItem({
                name: item.name,
                description: item.description,
                quantity: 1,
                cost: tierPrice !== null ? tierPrice : item.default_price,
                billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
                inventoryItemId: item._id,
                // inventoryStockId: item.inventoryStockId, // TODO: add this when supported
                // customPricingId: item.customPricingId, // TODO: add this when supported
            });

            let newSelectedItems = [...selectedItems, lineItem];
            onSetSelectedItems(newSelectedItems);
            Toast.show({
                type: 'success',
                position: 'bottom',
                text1: 'Item Added',
                text2: `${item.name} added to selected items`,
                visibilityTime: 1000,
                autoHide: true,
            });
        } catch (error) {
            console.error("Failed to add item:", error);
            Toast.show({
                type: 'error',
                position: 'bottom',
                text1: 'Error',
                text2: 'Failed to add item to selected items',
                visibilityTime: 1000,
                autoHide: true,
            });
        }
    }

    const flattenedInventoryList = useMemo<ExtendedInventoryItem[]>(() => {
        return companyInventoryList.flatMap(category =>
            category.inventoryItems.map(item => (new ExtendedInventoryItem({
                ...item,
                category_id: category._id,
                categoryName: category.name
            })))
        );
    }, [companyInventoryList]);

    const filteredInventoryList = useMemo(() => {
        if (!searchQuery) return flattenedInventoryList;
        return flattenedInventoryList.filter(item =>
            item.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [searchQuery, flattenedInventoryList]);

    // New useEffect to filter inventory based on newItemName
    useEffect(() => {
        if (newLineItem?.name) {
            const matches = flattenedInventoryList.filter((item) => {
                return item.name.toLowerCase().includes(newLineItem.name.toLowerCase());
            });
            console.log("🚀============== ~ file: inventoryDetailsCard.component.tsx:191 ~ useEffect ~ matches🚀==============", matches)
            setFilteredItems(matches);
        } else {
            setFilteredItems([]);
        }
    }, [newLineItem, flattenedInventoryList]);


    const filteredMenuItems = useMemo(() => {
        if (!menuSearchQuery) return [];
        setMenuVisible(true)
        return flattenedInventoryList.filter(item =>
            item.name.toLowerCase().includes(menuSearchQuery.toLowerCase())
        );
    }, [menuSearchQuery, flattenedInventoryList]);

    const renderMenuToggleButton = () => (
        <Button onPress={() => setMenuVisible(true)}>
            Search Items
        </Button>
    );

    const onMenuItemSelect = (index: IndexPath) => {
        const selectedItem = filteredMenuItems[index.row];
        if (selectedItem) {
            const lineItem = selectedItem.toLineItem();
            setNewLineItem(lineItem);
            setCustomItemCost(lineItem.cost.toString());
            setCustomItemQuantity(lineItem.quantity.toString());
            // setNewLineItem(new LineItem({
            //     name: selectedItem.name,
            //     description: selectedItem.description,
            //     quantity: 1,
            //     cost: selectedItem.default_price,
            //     billed_to: LINE_ITEM_BILLED_TO.BILL_ALL_BACK,
            //     inventoryItemId: selectedItem._id,
            //     // inventoryStockId: selectedItem.inventoryStockId, TODO:add these when supported
            //     // customPricingId: selectedItem.custom_pricing_id,
            // }));
        }
        setMenuVisible(false);
    };

    const validateQuantity = (value: string) => {
        if (value === '') {
            setQuantityError('');
        } else if (isNaN(Number(value))) {
            setQuantityError('Invalid quantity');
        } else {
            setQuantityError('');
        }
    };

    const validatePrice = (value: string) => {
        if (value === '') {
            setPriceError('');
        } else if (isNaN(Number(value))) {
            setPriceError('Invalid price');
        } else {
            setPriceError('');
        }
    };

    useEffect(() => {
        if (newLineItem) {
            validateQuantity(newLineItem.quantity.toString());
            validatePrice(newLineItem.cost.toString());
        }
    }, [newLineItem?.quantity, newLineItem?.cost]);

    const handleCostChange = (text: string) => {
        if (/^\d*\.?\d*$/.test(text)) {
            setNewLineItem(new LineItem({ ...newLineItem, cost: text }));
            validatePrice(text);
        }
    };

    const handleQuantityChange = (text: string) => {
        if (/^\d*\.?\d*$/.test(text)) {
            setNewLineItem(new LineItem({ ...newLineItem, quantity: text }));
            validateQuantity(text);
        }
    };

    const handleAddNewItem = () => {
        if (newLineItem && !quantityError && !priceError) {
            const itemToAdd = new LineItem({
                ...newLineItem,
                quantity: newLineItem.quantity || 1, // Default to 1 if quantity is empty
                cost: newLineItem.cost || 0 // Default to 0 if cost is empty
            });
            onSetSelectedItems([...selectedLineItems, itemToAdd]);
            Toast.show({
                type: 'success',
                position: 'bottom',
                text1: 'Item Added',
                text2: `${newLineItem.name} added to selected items`,
                visibilityTime: 1000,
                autoHide: true,
            });
            setNewLineItem(null);
            setFilteredItems([]);
            setQuantityError('');
            setPriceError('');
        }
    };

    const getCategoryNameFromId = (categoryId: string) => {
        const category = companyInventoryList.find(c => c._id === categoryId);
        return category?.name || '';
    }

    const calculateItemTierPrice = (item: InventoryItem, category: ExtendedInventoryCategory): number | null => {
        if (!selectedClient) return null;

        const clientTier = category.pricing_tiers?.find(tier =>
            tier.client_ids.includes(selectedClient._id as string)
        );

        if (clientTier) {
            if (clientTier.item_prices?.[item._id as string]) {
                return clientTier.item_prices[item._id as string];
            } else if (clientTier.multiplier) {
                return item.default_price * clientTier.multiplier;
            } else if (clientTier.fixed_price) {
                return clientTier.fixed_price;
            }
        }
        return null;
    };

    const RenderInventoryItem = ({ item }: { item: ExtendedInventoryItem | null }) => {
        if (!item) return null;

        const category = companyInventoryList.find(c => c._id === item.inventory_category_id);
        const tierPrice = category ? calculateItemTierPrice(item, category) : null;

        return (
            <View style={{ flexDirection: 'column', borderWidth: 1, borderColor: '#e0e0e0', padding: 3, borderRadius: 8 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text category='s1' style={[styles.detailTextPill]}>{item.name}</Text>
                    <View style={{ flexDirection: 'row', gap: 3, alignItems: 'center' }}>
                        <Icon name='shopping-cart-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-800'] }]}> ${item.default_price.toFixed(2)} </Text>
                        {tierPrice !== null && (
                            <Text style={[styles.detailTextPill, { backgroundColor: theme['color-info-500'] }]}>
                                Client Price: ${tierPrice.toFixed(2)}
                            </Text>
                        )}
                    </View>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Text>{getCategoryNameFromId(item.inventory_category_id as string)}</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text category='s1' style={{ fontSize: 8 }}>SKU: </Text>
                        <Text style={{ fontSize: 8 }}>{item.sku ?? 'N/A'}</Text>
                    </View>
                    <Button
                        size='tiny'
                        status='success'
                        // appearance='outline'
                        accessoryLeft={<Icon name='plus-outline' />}
                        onPress={() => onAddItemToSelectedItems(item)} />
                </View>
            </View>
        )
    }

    const toggleInventoryList = () => {
        setIsInventoryListVisible(!isInventoryListVisible);
    };

    const renderInventoryList = () => (
        <View style={styles.modalContent}>
            <Input
                label="Search items..."
                placeholder="Search items..."
                value={searchQuery}
                onChangeText={text => setSearchQuery(text)}
                style={{ margin: 10 }}
            />
            <ScrollView style={{ maxHeight: 500 }}>
                {filteredInventoryList.map((item: ExtendedInventoryItem) => (
                    <RenderInventoryItem key={item._id} item={item} />
                ))}
            </ScrollView>
            <Button onPress={toggleInventoryList} style={styles.closeButton}>Close</Button>
        </View>
    );

    // Separate lists for customer cost and billback items
    const customerCostItems = useMemo(() => {
        return selectedLineItems.filter(item => item.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST);
    }, [selectedLineItems]);

    const billbackItems = useMemo(() => {
        return selectedLineItems.filter(item => item.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK);
    }, [selectedLineItems]);

    // Calculate totals for each list
    const customerCostTotal = useMemo(() => {
        return customerCostItems.reduce((total, item) => total + item.cost * item.quantity, 0).toFixed(2);
    }, [customerCostItems]);

    const billbackTotal = useMemo(() => {
        return billbackItems.reduce((total, item) => total + item.cost * item.quantity, 0).toFixed(2);
    }, [billbackItems]);

    const [customItemCost, setCustomItemCost] = useState('0');
    const [customItemQuantity, setCustomItemQuantity] = useState('1');

    return (
        <Layout style={style}>
            <View style={{ flexDirection: 'row', alignItems: 'flex-start', margin: 10, flexWrap: 'wrap', gap: 10 }}>
                <View style={{ flexDirection: 'column', maxWidth: 450 }}>
                    <Divider />
                    {IS_SMALL_SCREEN ? (
                        <>
                            <Button onPress={toggleInventoryList}>
                                {isInventoryListVisible ? 'Hide Inventory List' : 'Show Inventory List'}
                            </Button>
                            <Modal
                                visible={isInventoryListVisible}
                                transparent={true}
                                animationType="slide"
                                onRequestClose={toggleInventoryList}
                            >
                                <TouchableWithoutFeedback onPress={toggleInventoryList}>
                                    <Layout style={styles.modalOverlay}>
                                        <TouchableWithoutFeedback>
                                            <Layout style={styles.modalContainer}>
                                                {renderInventoryList()}
                                            </Layout>
                                        </TouchableWithoutFeedback>
                                    </Layout>
                                </TouchableWithoutFeedback>
                            </Modal>
                        </>
                    ) : (
                        <View>
                            <Button onPress={toggleInventoryList}>
                                {isInventoryListVisible ? 'Hide Inventory List' : 'Show Inventory List'}
                            </Button>
                            {isInventoryListVisible && renderInventoryList()}
                        </View>
                    )}
                </View>
                <View style={{ flexDirection: 'column', flex: 1, minWidth: 200 }}>
                    <View style={{ flexDirection: 'column', borderTopWidth: 3, borderTopColor: '#e0e0e0', borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text category='s1'>Add Item</Text>
                        </View>

                        <View style={{ flexDirection: 'row', gap: 10, flexWrap: 'wrap', borderWidth: 1, borderColor: '#e0e0e0', padding: 10, borderRadius: 8 }}>
                            <OverflowMenu
                                anchor={() => {
                                    return (
                                        <Input
                                            label="Name"
                                            size='small'
                                            placeholder="Add item name..."
                                            value={newLineItem?.name}
                                            onChangeText={text => {
                                                setNewLineItem(new LineItem({ ...newLineItem, name: text }))
                                                setMenuSearchQuery(text)
                                            }}
                                            style={{ margin: 10, flex: 1, minWidth: 100 }}
                                        />
                                    )
                                }}
                                visible={menuVisible}
                                onBackdropPress={() => setMenuVisible(false)}
                                onSelect={onMenuItemSelect}
                            >
                                {filteredMenuItems.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        title={`${item.name} - $${item.default_price}`}
                                    />
                                ))}
                            </OverflowMenu>
                            <Input
                                placeholder="Cost"
                                label="Cost"
                                size='small'
                                value={customItemCost.toString()}
                                // onChangeText={handleCostChange}
                                onChangeText={(text) => {
                                    validatePrice(text);
                                    setCustomItemCost(text);
                                }}
                                onBlur={(event: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
                                    const text = event.nativeEvent.text;
                                    if (text === '') {
                                        handleCostChange('0');
                                    }
                                    handleCostChange(text);
                                }}
                                keyboardType="numeric"
                                style={{ margin: 10, flex: 1, minWidth: 100 }}
                                status={priceError ? 'danger' : 'basic'}
                                caption={priceError}
                            />
                            <Input
                                placeholder="Quantity"
                                label="Quantity"
                                size='small'
                                value={customItemQuantity.toString()}
                                onChangeText={(text) => {
                                    setCustomItemQuantity(text);
                                    validateQuantity(text);
                                }}
                                onBlur={(event: NativeSyntheticEvent<TextInputEndEditingEventData>) => {
                                    const text = event.nativeEvent.text;
                                    if (text === '') {
                                        handleQuantityChange('0');
                                    }
                                    handleQuantityChange(text);
                                }}
                                keyboardType="numeric"
                                style={{ margin: 10, flex: 1, minWidth: 100 }}
                                status={quantityError ? 'danger' : 'basic'}
                                caption={quantityError}
                            />
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Button
                                    onPress={handleAddNewItem}
                                    disabled={!newLineItem?.name || !!quantityError || !!priceError}
                                    style={{ marginLeft: 10 }}
                                >
                                    Add
                                </Button>
                            </View>
                        </View>
                    </View>
                    <Divider />
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20, flexWrap: 'wrap', gap: 5 }}>
                        <View style={{ flex: 1, minWidth: 450 }}>
                            <Text category='s1'>Customer Cost Items</Text>
                            <List
                                data={customerCostItems}
                                renderItem={({ item }) => (
                                    <LineItemComponent
                                        item={item}
                                        allowEdit={allowEdit}
                                        onRemove={() => {
                                            const updatedItems = selectedLineItems.filter(i => i !== item);
                                            onSetSelectedItems(updatedItems);
                                        }}
                                        onUpdate={(updatedItem) => {
                                            const updatedItems = selectedLineItems.map(i => i === item ? updatedItem : i);
                                            onSetSelectedItems(updatedItems);
                                        }}
                                    />
                                )}
                                ListEmptyComponent={<Text appearance="hint">No customer cost items.</Text>}
                                style={{ maxHeight: 400, marginHorizontal: 10 }}
                            />
                            <Text style={{ margin: 10, fontWeight: 'bold', textAlign: 'right' }}>
                                Customer Cost Total: ${customerCostTotal}
                            </Text>
                        </View>
                        <View style={{ flex: 1, minWidth: 400 }}>
                            <Text category='s1'>Billback Items</Text>
                            <List
                                data={billbackItems}
                                renderItem={({ item }) => (
                                    <LineItemComponent
                                        item={item}
                                        allowEdit={allowEdit}
                                        onRemove={() => {
                                            const updatedItems = selectedLineItems.filter(i => i !== item);
                                            onSetSelectedItems(updatedItems);
                                        }}
                                        onUpdate={(updatedItem) => {
                                            const updatedItems = selectedLineItems.map(i => i === item ? updatedItem : i);
                                            onSetSelectedItems(updatedItems);
                                        }}
                                    />
                                )}
                                ListEmptyComponent={<Text appearance="hint">No billback items.</Text>}
                                style={{ maxHeight: 400, marginHorizontal: 10 }}
                            />
                            <Text style={{ margin: 10, fontWeight: 'bold', textAlign: 'right' }}>
                                Billback Total: ${billbackTotal}
                            </Text>
                        </View>
                    </View>
                    <Divider />
                    <Text style={{ margin: 10, fontWeight: 'bold', textAlign: 'right' }}>
                        Total: ${totalSelectedInventoryCost}
                    </Text>
                </View>
                <Divider />
            </View>
        </Layout>
    );

}

const styles = StyleSheet.create({
    searchInput: {
        margin: 10,
    },
    sectionTitle: {
        margin: 10,
    },
    selectedItemsList: {
        maxHeight: 200,
        marginHorizontal: 10,
    },
    totalCost: {
        margin: 10,
        fontWeight: 'bold',
        textAlign: 'right',
    },
    rowContainer: {
        flexDirection: 'row',  // Align children in a row
        alignItems: 'center',  // Center items vertically
        margin: 10,            // Optional: add margin for spacing
    },
    detailTextPill: {
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 3,
        paddingVertical: 1,
        // borderColor: theme['color-primary-500'], // Use theme color for border
    },
    icon: {
        width: 15,
        height: 15,
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        // backgroundColor: 'transparent',
        padding: 20,
        borderRadius: 10,
        width: '90%',
        maxHeight: '80%',
    },
    modalContent: {
        flex: 1,
    },
    closeButton: {
        marginTop: 20,
    },
});
