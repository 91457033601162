import React, { useState, useEffect, useRef, useMemo } from 'react';
import { StyleSheet, View, ScrollView, Linking } from 'react-native';
import { Text, Button, Layout, Divider, Input, Icon, useTheme, TopNavigation, TopNavigationAction, Modal, Card, Spinner } from '@ui-kitten/components';
import { Order, OrderStatus } from '../../models/Order.model';
import { updateOrder, processPayment, createJobFromOrder, cancelOrder, CancelOrderResult } from '../../functions/order.controller';
import { Company } from '../../models/Company.model';
import { InventoryCategory, InventoryItem, PricingTier } from '../../models/Inventory.model';
import * as InventoryController from '../../functions/Inventory.controller';
import * as ClientController from '../../functions/client.controller';
import { Client } from '../../models/Client.model';
import * as OrderController from '../../functions/order.controller';
import { OrderDetailsResponse } from '../../functions/order.controller';
import { AddItemModal } from './AddItemModal';
import { CartItem } from './PartsNetworkContainerScreen';
import { PaymentDetails } from './PaymentDetails';
import DMModal from '../common/Modal';
import { TextDetailPill } from '../common/TextDetailPill';
import { JobReportModal } from '../dashboardComponents/jobReportModal.component';
import { Job } from '../../models/Job.model';
import * as JobController from '../../functions/job.controller';
import * as StorageController from '../../functions/storageController'

interface OrderDetailProps {
    order: Order;
    currentCompany: Company;
    onOrderUpdated: () => void;
    onBack: () => void;
    activeTab: string;
}

export const OrderDetail: React.FC<OrderDetailProps> = ({ order, currentCompany, onOrderUpdated, onBack, activeTab }) => {
    const [orderDetails, setOrderDetails] = useState<OrderDetailsResponse | null>(null);
    const [editedOrder, setEditedOrder] = useState<Order | null>(order);
    const [isEditing, setIsEditing] = useState(false);
    const [clientCompany, setClientCompany] = useState<Company | null>(null);
    const [clientProfile, setClientProfile] = useState<Client | null>(null);
    const [isAddItemModalVisible, setIsAddItemModalVisible] = useState(false);
    const [orderItems, setOrderItems] = useState<OrderDetailsResponse['items']>({});
    const [statusToConfirm, setStatusToConfirm] = useState<OrderStatus | null>(null);
    const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [paymentUrl, setPaymentUrl] = useState<string | null>(null);
    const pollIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const theme = useTheme();
    const styles = getStyles(theme);
    const [isCreatingJob, setIsCreatingJob] = useState(false);
    const [jobCreatedSuccessfully, setJobCreatedSuccessfully] = useState(false);
    const [createdJob, setCreatedJob] = useState<Job | null>(null);
    const [showJobReportModal, setShowJobReportModal] = useState(false);
    const [relatedJob, setRelatedJob] = useState<Job | null>(null);
    const [isLoadingJob, setIsLoadingJob] = useState(false);
    const [isCancellingOrder, setIsCancellingOrder] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const isVendor = activeTab === 'vendorOrders';

    useEffect(() => {
        const company = StorageController.getCurrentCompany()
        if (!company) return
        if (isVendor && order.vendor_company_id == company._id) {
            console.log("🚀============== ~ file: OrderDetail.tsx:58 ~ useEffect ~ isVendor🚀==============", isVendor)
            onLoad();
        }
        else if (!isVendor && order.client_company_id == company._id) {
            console.log("🚀============== ~ file: OrderDetail.tsx:61 ~ useEffect ~ isVendor🚀==============", isVendor)
            onLoad()
        }
        else {
            // onBack()
            console.log("🚀============== ~ file: OrderDetail.tsx:61 ~ useEffect ~ order🚀==============", order)
        }

    }, [order, isVendor]);

    const onLoad = async () => {
        // onSetEditedOrder(null)
        setIsLoading(true);
        await fetchOrderDetails();
        await fetchClientProfile();
        setIsLoading(false);
        onSetEditedOrder(order)
    }

    useEffect(() => {
        return () => {
            if (pollIntervalRef.current) {
                clearInterval(pollIntervalRef.current);
            }
        };
    }, []);

    const fetchOrderDetails = async () => {
        if (!order._id) return;
        const details = await OrderController.getOrderById(order._id);
        console.log("🚀============== ~ file: OrderDetail.tsx:56 ~ fetchOrderDetails ~ details🚀==============", details)
        if (details) {
            setOrderDetails(details);
            onSetEditedOrder(details.order);
            setOrderItems(details.items);
            setClientCompany(details.clientCompany);
        }
    };

    const editedOrderRef = useRef<Order | null>(editedOrder);

    const onSetEditedOrder = (order: Order | null) => {
        editedOrderRef.current = order;
        setEditedOrder(order);
    };

    const fetchClientProfile = async () => {
        if (!isVendor && order.vendor_company_id && order.client_company_id) {
            const profile = await ClientController.getClientByCompanyAndClientLinkingId(order.vendor_company_id, order.client_company_id);
            setClientProfile(profile);
        }
    };



    const handleUpdateOrder = async () => {
        if (editedOrderRef.current && editedOrderRef.current._id) {
            const updatedOrder = await updateOrder(editedOrderRef.current._id, editedOrderRef.current);
            if (updatedOrder) {
                onOrderUpdated();
                setIsEditing(false);
                fetchOrderDetails(); // Reload the order to ensure we have the latest data
            }
            onSetEditedOrder(editedOrderRef.current);
        }
    };

    const handleStatusChange = (newStatus: Order['status']) => {
        setStatusToConfirm(newStatus);
    };

    const confirmStatusChange = async () => {
        if (statusToConfirm && editedOrderRef.current) {
            const updatedOrder = { ...editedOrderRef.current, status: statusToConfirm };
            onSetEditedOrder(updatedOrder);
            setStatusToConfirm(null);

            // Save/update the order
            if (updatedOrder._id) {
                const savedOrder = await updateOrder(updatedOrder._id, updatedOrder);
                if (savedOrder) {
                    onOrderUpdated();
                    fetchOrderDetails(); // Reload the order to ensure we have the latest data
                }
            }
        }
    };

    const cancelStatusChange = () => {
        setStatusToConfirm(null);
    };

    const handleQuantityChange = (itemId: string | null, newQuantity: number) => {
        if (!editedOrderRef.current) return
        const updatedItems = editedOrderRef.current.items.map(item =>
            item.inventory_item_id === itemId ? { ...item, quantity: newQuantity } : item
        );
        const newTotalAmount = calculateTotalCost(updatedItems);
        onSetEditedOrder({ ...editedOrderRef.current, items: updatedItems, total_amount: newTotalAmount });
    };

    const handlePriceChange = (itemId: string | null, newPrice: number) => {
        if (!editedOrderRef.current) return
        const updatedItems = editedOrderRef.current.items.map(item =>
            item.inventory_item_id === itemId ? { ...item, price: newPrice } : item
        );
        const newTotalAmount = calculateTotalCost(updatedItems);
        onSetEditedOrder({ ...editedOrderRef.current, items: updatedItems, total_amount: newTotalAmount });
    };

    const handleAddItems = (item: InventoryItem, quantity: number) => {
        if (!editedOrderRef.current) return
        const updatedItems = [
            ...editedOrderRef.current.items,
            {
                inventory_item_id: item._id,
                quantity: quantity,
                price: item.default_price,
            }
        ] as Order['items'];
        const newTotalAmount = calculateTotalCost(updatedItems);
        onSetEditedOrder({ ...editedOrderRef.current, items: updatedItems as Order['items'], total_amount: newTotalAmount });
        // also add the items to the orderdetails items
        setOrderItems({
            ...orderItems,
            [item._id as string]: {
                item: item,
                category: item.inventory_category_id,
                tierPrice: item.default_price,
                quantity: quantity,
            }
        });
    };

    const handleRemoveItem = (itemId: string | null) => {
        if (!editedOrderRef.current) return
        const updatedItems = editedOrderRef.current.items.filter(item => item.inventory_item_id !== itemId);
        const newTotalAmount = calculateTotalCost(updatedItems);
        onSetEditedOrder({ ...editedOrderRef.current, items: updatedItems, total_amount: newTotalAmount });
    };

    const renderStatusButtons = () => {
        if (!editedOrderRef.current) return
        const statuses: OrderStatus[] = [OrderStatus.REQUEST, OrderStatus.AWAITING_PAYMENT, OrderStatus.PROCESSING, OrderStatus.READY, OrderStatus.SHIPPED, OrderStatus.DELIVERED, OrderStatus.COMPLETED, OrderStatus.CANCELLED];
        return (
            <View style={styles.statusButtonContainer}>
                {statuses.map((status) => (
                    <Button
                        key={status}
                        size="tiny"
                        appearance={editedOrderRef.current && editedOrderRef.current.status === status ? 'filled' : 'outline'}
                        onPress={() => handleStatusChange(status)}
                        style={styles.statusButton}
                    >
                        {status}
                    </Button>
                ))}
            </View>
        );
    };

    const renderItemDetails = (item: Order['items'][number], index: number) => {
        const itemDetails = orderItems[item.inventory_item_id as string];
        if (!itemDetails) return null;

        const { item: inventoryItem, category, tierPrice } = itemDetails;

        return (
            <View key={index} style={styles.itemRow}>
                <Text style={styles.itemName}>{inventoryItem.name || 'Unknown Item'}</Text>
                {isVendor && isEditing ? (
                    <>
                        <Input
                            style={styles.itemQuantityInput}
                            size="small"
                            value={item.quantity.toString()}
                            onChangeText={(text) => handleQuantityChange(item.inventory_item_id, parseInt(text) || 0)}
                            keyboardType="numeric"
                        />
                        <Input
                            style={styles.itemPriceInput}
                            size="small"
                            value={item.price.toFixed(2)}
                            onChangeText={(text) => handlePriceChange(item.inventory_item_id, parseFloat(text) || 0)}
                            keyboardType="numeric"
                        />
                    </>
                ) : (
                    <>
                        <Text style={styles.itemQuantity}>{item.quantity}</Text>
                        <Text style={styles.itemPrice}>${item.price.toFixed(2)}</Text>
                    </>
                )}
                <Text style={styles.itemTotal}>${(item.quantity * item.price).toFixed(2)}</Text>
                {isVendor && isEditing && (
                    <View style={styles.itemActions}>
                        <Button
                            size="tiny"
                            status="danger"
                            onPress={() => handleRemoveItem(item.inventory_item_id)}
                            accessoryLeft={(props) => <Icon {...props} name="trash-2-outline" />}
                        />
                    </View>
                )}
            </View>
        );
    };

    const renderItemsList = useMemo(() => {
        if (!editedOrderRef.current) return
        return editedOrderRef.current.items.map((item, index) => renderItemDetails(item, index));
    }, [editedOrder, orderItems, isEditing]);

    const BackIcon = (props: any) => (
        <Icon {...props} name='arrow-back' />
    );

    const EditIcon = (props: any) => (
        <Icon {...props} name='edit-2-outline' />
    );

    const renderBackAction = () => (
        <TopNavigationAction icon={BackIcon} onPress={onBack} />
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Render Edit Action
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderEditAction = () => (
        isVendor && (
            <TopNavigationAction
                icon={EditIcon}
                onPress={isEditing ? handleUpdateOrder : () => setIsEditing(true)}
            />
        )
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region Edit Buttons
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const RenderVendorEditButtons = () => {
        // Only show edit buttons if the order is in "request" status
        if (!editedOrderRef.current) return
        if (editedOrderRef.current.status !== OrderStatus.REQUEST) {
            return null;
        }

        return (
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                {isEditing ? (
                    <>
                        <Button
                            onPress={handleUpdateOrder}
                            accessoryLeft={(props) => <Icon {...props} name="checkmark-outline" />}
                            appearance="ghost"
                            status="success"
                            style={{ marginRight: 8 }}
                        >
                            Save
                        </Button>
                        <Button
                            onPress={() => {
                                setIsEditing(false);
                                fetchOrderDetails(); // This will reload the original order
                            }}
                            accessoryLeft={(props) => <Icon {...props} name="close-outline" />}
                            appearance="ghost"
                            status="danger"
                        >
                            Cancel
                        </Button>
                    </>
                ) : (
                    <Button
                        onPress={() => setIsEditing(true)}
                        accessoryLeft={(props) => <Icon {...props} name="edit-2-outline" />}
                        appearance="ghost"
                        status="warning"
                    >
                        Edit
                    </Button>
                )}
            </View>
        );
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Job Creation
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handleCreateJob = async () => {
        if (!editedOrderRef.current) return
        if (editedOrderRef.current._id) {
            setIsCreatingJob(true);
            const result = await createJobFromOrder(editedOrderRef.current._id);
            console.log("🚀============== ~ file: OrderDetail.tsx:313 ~ handleCreateJob ~ result🚀==============", result)
            setIsCreatingJob(false);
            if (result.success && result.order && result.job) {
                onSetEditedOrder(result.order);
                setCreatedJob(result.job);
                setJobCreatedSuccessfully(true);
            } else {
                // Show an error message
                alert('Failed to create job: ' + (result.message || 'Unknown error'));
            }
        }
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Job Created Success Modal
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const JobCreatedSuccessModal = () => (
        <DMModal visible={jobCreatedSuccessfully} onClose={() => setJobCreatedSuccessfully(false)}>
            <Card disabled>
                <Text category="h6" style={styles.modalTitle}>Job Created Successfully</Text>
                <Text>The job has been created from this order.</Text>
                <View style={styles.modalButtonContainer}>
                    <Button onPress={() => setJobCreatedSuccessfully(false)} status="basic" style={styles.modalButton}>
                        Close
                    </Button>
                    <Button onPress={() => {
                        setJobCreatedSuccessfully(false);
                        setShowJobReportModal(true);
                    }} status="primary" style={styles.modalButton}>
                        View Job Details
                    </Button>
                </View>
            </Card>
        </DMModal>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Cancel Order
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handleCancelOrder = async () => {
        if (!editedOrderRef.current) return
        if (editedOrderRef.current._id) {
            setIsCancellingOrder(true);
            const result = await cancelOrder(editedOrderRef.current._id);
            setIsCancellingOrder(false);
            if (result.success) {
                onSetEditedOrder(result.order as Order);
                onOrderUpdated();
                alert('Order cancelled successfully');
            } else {
                alert('Failed to cancel order: ' + result.message);
            }
        }
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Order Actions
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderOrderActions = () => (
        <View style={styles.actionsContainer}>
            {isVendor ? (
                <>
                    <Text category="s1" style={styles.actionTitle}>Update Status</Text>
                    {renderStatusButtons()}
                    {editedOrder && !editedOrder.related_job_id &&
                        ![OrderStatus.REQUEST, OrderStatus.AWAITING_PAYMENT].includes(editedOrder.status) && (
                            <Button
                                status="info"
                                onPress={handleCreateJob}
                                accessoryLeft={(props) =>
                                    isCreatingJob ?
                                        <Spinner size="small" /> :
                                        <Icon {...props} name="file-text-outline" />
                                }
                                style={styles.actionButton}
                                disabled={isCreatingJob}
                            >
                                {isCreatingJob ? 'Creating Job...' : 'Create Job'}
                            </Button>
                        )}
                </>
            ) : (
                <>
                    {editedOrder && editedOrder.status === 'awaiting_payment' && (
                        <Button
                            status="success"
                            onPress={handlePaymentProcess}
                            accessoryLeft={(props) => <Icon {...props} name="credit-card-outline" />}
                            style={styles.actionButton}
                        >
                            Process Payment
                        </Button>
                    )}
                </>
            )}
            {editedOrder && [OrderStatus.REQUEST, OrderStatus.AWAITING_PAYMENT, OrderStatus.PROCESSING].includes(editedOrder.status) && (
                <Button
                    status="danger"
                    onPress={handleCancelOrder}
                    accessoryLeft={(props) =>
                        isCancellingOrder ?
                            <Spinner size="small" /> :
                            <Icon {...props} name="close-circle-outline" />
                    }
                    style={styles.actionButton}
                    disabled={isCancellingOrder}
                >
                    {isCancellingOrder ? 'Cancelling...' : 'Cancel Order'}
                </Button>
            )}
        </View>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Order Info
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderOrderInfo = () => (
        <View style={styles.orderInfoContainer}>
            <View style={styles.orderInfoItem}>
                <Icon name="hash-outline" style={styles.infoIcon} fill={theme['text-hint-color']} />
                <View>
                    <Text style={styles.infoLabel}>Order ID</Text>
                    <Text style={styles.infoValue}>{editedOrder && (editedOrder.friendly_id || editedOrder._id) || 'N/A'}</Text>
                </View>
            </View>
            <View style={styles.orderInfoItem}>
                <Icon name="activity-outline" style={styles.infoIcon} fill={theme['text-hint-color']} />
                <View>
                    <Text style={styles.infoLabel}>Status</Text>
                    <Text style={styles.infoValue}>{editedOrder && editedOrder.status || 'N/A'}</Text>
                </View>
            </View>
            <View style={styles.orderInfoItem}>
                <Icon name="credit-card-outline" style={styles.infoIcon} fill={theme['text-hint-color']} />
                <View>
                    <Text style={styles.infoLabel}>Total Amount</Text>
                    <Text style={styles.infoValue}>${editedOrder && editedOrder.total_amount.toFixed(2) || 'N/A'}</Text>
                </View>
            </View>
            {editedOrder && editedOrder.related_job_id && (
                <View style={styles.orderInfoItem}>
                    <Icon name="file-text-outline" style={styles.infoIcon} fill={theme['text-hint-color']} />
                    <View>
                        <Text style={styles.infoLabel}>Related Job ID</Text>
                        <Text style={styles.infoValue}>{editedOrder && editedOrder.related_job_id || 'N/A'}</Text>
                    </View>
                    <Button
                        size="small"
                        status="info"
                        appearance="ghost"
                        accessoryLeft={(props) => <Icon {...props} name="eye-outline" />}
                        onPress={handleOpenJobDetails}
                        disabled={isLoadingJob || !relatedJob}
                        style={styles.viewJobButton}
                    >
                        {isLoadingJob ? 'Loading...' : 'View Job'}
                    </Button>
                </View>
            )}
        </View>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Calculate Total Cost
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const calculateTotalCost = (items: Order['items']) => {
        return items.reduce((total, item) => total + (item.quantity * item.price), 0);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Status Confirmation Modal
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const StatusConfirmationModal = ({ visible, onConfirm, onCancel, newStatus }: {
        visible: boolean;
        onConfirm: () => void;
        onCancel: () => void;
        newStatus: Order['status'];
    }) => {
        return (
            <DMModal visible={visible} onClose={onCancel}>
                <Card disabled>
                    <Text category="h6" style={styles.modalTitle}>Confirm Status Update</Text>
                    <Text>Are you sure you want to update the order status to "{newStatus}"?</Text>
                    <View style={styles.modalButtonContainer}>
                        <Button onPress={onCancel} status="basic" style={styles.modalButton}>
                            Cancel
                        </Button>
                        <Button onPress={onConfirm} status="primary" style={styles.modalButton}>
                            Confirm
                        </Button>
                    </View>
                </Card>
            </DMModal>
        );
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Payment Process
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handlePaymentProcess = async () => {
        setIsProcessingPayment(true);
        setIsPaymentModalVisible(true);
        if (!editedOrderRef.current) return
        const result = await processPayment(editedOrderRef.current._id as string, currentCompany);
        if (result.success && result.checkoutSession) {
            setPaymentUrl(result.checkoutSession.url);
            Linking.openURL(result.checkoutSession.url);
            startPolling();
        } else {
            console.error('Payment initiation failed:', result.message);
            // Show error message to user
        }
        setIsProcessingPayment(false);
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Payment Confirmation Modal
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const startPolling = () => {
        if (pollIntervalRef.current) {
            clearInterval(pollIntervalRef.current);
        }
        pollIntervalRef.current = setInterval(checkOrderStatus, 10000); // Poll every 10 seconds
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Check Order Status
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const checkOrderStatus = async () => {
        if (!editedOrderRef.current) return
        const updatedOrder = await OrderController.pollOrderStatus(editedOrderRef.current._id as string);
        if (updatedOrder && updatedOrder.status !== OrderStatus.AWAITING_PAYMENT) {
            if (pollIntervalRef.current) {
                clearInterval(pollIntervalRef.current);
            }
            onSetEditedOrder(updatedOrder);
            setIsPaymentModalVisible(false);
            onOrderUpdated();
        }
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Payment Confirmation Modal
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const PaymentConfirmationModal = ({ visible, onCancel }: {
        visible: boolean;
        onCancel: () => void;
    }) => {
        return (
            <DMModal visible={visible} onClose={onCancel}>
                <Card disabled>
                    <Text category="h6" style={styles.modalTitle}>Payment Processing</Text>
                    {isProcessingPayment ? (
                        <View style={styles.spinnerContainer}>
                            <Spinner size="large" />
                            <Text style={styles.processingText}>Processing payment...</Text>
                        </View>
                    ) : paymentUrl ? (
                        <>
                            <Text>Please complete the payment in the opened browser window.</Text>
                            <Button onPress={() => Linking.openURL(paymentUrl)} style={styles.modalButton}>
                                Reopen Payment Window
                            </Button>
                            <Button onPress={checkOrderStatus} style={styles.modalButton}>
                                Check Payment Status
                            </Button>
                        </>
                    ) : (
                        <Text>Ready to process payment</Text>
                    )}
                    <Button onPress={onCancel} status="basic" style={styles.modalButton}>
                        Cancel
                    </Button>
                </Card>
            </DMModal>
        );
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Payment Details
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderPaymentDetails = () => (
        <View style={styles.paymentDetailsContainer}>
            {editedOrder && <PaymentDetails paymentDetails={editedOrder.payment_details} />}
        </View>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Order Items
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const renderOrderItems = () => (
        <View style={{ flexDirection: 'column', flex: 1 }}>
            <Text category="h6" style={styles.sectionTitle}>Items</Text>

            <View style={styles.itemsHeader}>
                <Text style={styles.itemName}>Item</Text>
                <Text style={styles.itemQuantity}>Qty</Text>
                <Text style={styles.itemPrice}>Price</Text>
                <Text style={styles.itemTotal}>Total</Text>
            </View>
            {renderItemsList}
            <Divider style={styles.divider} />
            {/* order total */}
            {editedOrder && <TextDetailPill label="Order Total" value={`$${editedOrder.total_amount.toFixed(2)}`} />}
        </View>
    );

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Open Job Details
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const handleOpenJobDetails = () => {
        if (relatedJob) {
            setCreatedJob(relatedJob);
            setShowJobReportModal(true);
        }
    };

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Fetch Related Job
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    useEffect(() => {
        const fetchRelatedJob = async () => {
            if (editedOrderRef.current && editedOrderRef.current.related_job_id) {
                setIsLoadingJob(true);
                try {
                    const job = await JobController.getJobById(editedOrderRef.current.related_job_id);
                    setRelatedJob(job);
                } catch (error) {
                    console.error('Error fetching related job:', error);
                }
                setIsLoadingJob(false);
            }
        };

        fetchRelatedJob();
    }, [editedOrderRef.current?.related_job_id]);

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Render Loading Spinner
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const RenderLoadingSpinner = () => (
        <View style={styles.spinnerContainer}>
            <Spinner size="large" />
        </View>
    );

    if (!editedOrder) return <></>

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region Render Order Details
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    return (
        <Layout style={styles.container}>
            <View style={styles.header}>
                <Text category="h6" style={styles.title}>Order Details</Text>
                <Button
                    onPress={onBack}
                    accessoryLeft={(props) => <Icon {...props} name="close-outline" />}
                    appearance="ghost"
                    status="danger"
                >
                </Button>
            </View>
            <ScrollView>
                {isLoading ? <RenderLoadingSpinner /> : (
                    <>
                        {renderOrderInfo()}
                        {renderOrderActions()}
                        {isVendor && RenderVendorEditButtons()}
                        <View style={{ flexDirection: 'row' }}>
                            {renderOrderItems()}
                            <View style={{ flex: 1 }}>
                                {renderPaymentDetails()}
                            </View>
                        </View>

                        {isVendor && isEditing && (
                            <Button
                                appearance="ghost"
                                status="info"
                                accessoryLeft={(props) => <Icon {...props} name="plus-outline" />}
                                onPress={() => setIsAddItemModalVisible(true)}
                                style={styles.addItemButton}
                            >
                                Add Item
                            </Button>
                        )}
                    </>
                )}
            </ScrollView>

            <AddItemModal
                visible={isAddItemModalVisible}
                onClose={() => setIsAddItemModalVisible(false)}
                onAddItems={handleAddItems}
                vendorCompany={currentCompany}
                clientCompanyId={editedOrder && editedOrder.client_company_id || ''}
                //@ts-ignore
                cart={editedOrder.items}
            />
            <StatusConfirmationModal
                visible={statusToConfirm !== null}
                onConfirm={confirmStatusChange}
                onCancel={cancelStatusChange}
                newStatus={statusToConfirm || '' as Order['status']}
            />
            <PaymentConfirmationModal
                visible={isPaymentModalVisible}
                onCancel={() => {
                    setIsPaymentModalVisible(false);
                    if (pollIntervalRef.current) {
                        clearInterval(pollIntervalRef.current);
                    }
                }}
            />
            <JobCreatedSuccessModal />
            {(createdJob || relatedJob) && (
                <JobReportModal
                    showReportDetailsModal={showJobReportModal}
                    setShowReportDetailsModal={setShowJobReportModal}
                    job={createdJob || relatedJob as Job}
                />
            )}
        </Layout>
    );
};

const getStyles = (theme: any) => StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 8,
    },
    container: {
        flex: 1,
        padding: 16,
    },
    title: {
        marginBottom: 16,
    },
    orderInfoContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 16,
        backgroundColor: theme['background-basic-color-2'],
    },
    orderInfoItem: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    infoIcon: {
        width: 24,
        height: 24,
        marginRight: 8,
    },
    infoLabel: {
        fontSize: 12,
        color: theme['text-hint-color'],
    },
    infoValue: {
        fontSize: 16,
        fontWeight: 'bold',
        color: theme['text-basic-color'],
    },
    divider: {
        marginVertical: 16,
    },
    sectionTitle: {
        marginBottom: 8,
        paddingHorizontal: 16,
    },
    itemsHeader: {
        flexDirection: 'row',
        paddingBottom: 8,
        borderBottomWidth: 1,
        borderBottomColor: theme['border-basic-color-3'],
        paddingHorizontal: 16,
    },
    itemRow: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 8,
        borderBottomWidth: 1,
        borderBottomColor: theme['border-basic-color-3'],
        paddingHorizontal: 16,
    },
    itemName: {
        flex: 3,
    },
    itemQuantity: {
        flex: 1,
        textAlign: 'center',
    },
    itemPrice: {
        flex: 1,
        textAlign: 'right',
    },
    itemTotal: {
        flex: 1,
        textAlign: 'right',
    },
    itemQuantityInput: {
        flex: 1,
        marginHorizontal: 4,
    },
    itemPriceInput: {
        flex: 1,
        marginHorizontal: 4,
    },
    itemActions: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginLeft: 8,
    },
    addItemButton: {
        alignSelf: 'flex-start',
        marginTop: 8,
        marginLeft: 16,
    },
    actionsContainer: {
        marginTop: 16,
        marginBottom: 16,
        paddingHorizontal: 16,
        backgroundColor: theme['background-basic-color-2'],
        borderRadius: 8,
        padding: 16,
    },
    actionTitle: {
        marginBottom: 8,
        fontWeight: 'bold',
    },
    actionButton: {
        marginBottom: 8,
    },
    statusButtonContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 8,
    },
    statusButton: {
        margin: 4,
        borderRadius: 16,
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalTitle: {
        marginBottom: 10,
    },
    modalButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20,
    },
    modalButton: {
        marginLeft: 10,
    },
    spinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
    },
    processingText: {
        marginTop: 10,
    },
    paymentDetailsContainer: {
        marginTop: 16,
        paddingHorizontal: 16,
    },
    viewJobButton: {
        marginLeft: 8,
    },
});
