import React from 'react';
import { View, StyleSheet, FlatList } from 'react-native';
import { Input, Button, Layout, Text } from '@ui-kitten/components';
import { TouchableWithoutFeedback } from 'react-native';
import { RenderInventoryItem } from './RenderInventoryItem.component';
import { Client } from '../../../../models/Client.model';
import { ExtendedInventoryItem, ExtendedInventoryCategory } from '../../../../models/Inventory.model';
import { LineItem } from '../../../../models/LineItem.model';
import DMModal from '../../../common/Modal';

interface InventoryListModalProps {
    visible: boolean;
    onClose: () => void;
    searchQuery: string;
    setSearchQuery: (text: string) => void;
    filteredInventoryList: ExtendedInventoryItem[];
    selectedClient: Client;
    theme: any;
    onSetSelectedItems: (items: LineItem[]) => void;
    selectedItems: LineItem[];
    companyInventoryList: ExtendedInventoryCategory[];
    getCategoryNameFromId: (id: string) => string;
    getPricingTier: (item: ExtendedInventoryItem) => any;
    isSmallScreen?: boolean;
    itemStockLevels: { [key: string]: number };
}

export const InventoryListModal = ({
    visible,
    onClose,
    searchQuery,
    setSearchQuery,
    filteredInventoryList,
    selectedClient,
    theme,
    onSetSelectedItems,
    selectedItems,
    companyInventoryList,
    getCategoryNameFromId,
    getPricingTier,
    isSmallScreen = false,
    itemStockLevels
}: InventoryListModalProps) => {
    console.log("🚀============== ~ file: InventoryListModal.component.tsx:44 ~ itemStockLevels🚀==============", itemStockLevels)

    const renderInventoryList = () => (
        <View style={styles.modalContent}>
            <Input
                label="Search items..."
                placeholder="Search items..."
                value={searchQuery}
                onChangeText={text => setSearchQuery(text)}
                style={{ margin: 10 }}
            />
            <FlatList
                data={filteredInventoryList}
                renderItem={({ item }) => <RenderInventoryItem
                    selectedClient={selectedClient}
                    item={item}
                    theme={theme}
                    onSetSelectedItems={onSetSelectedItems}
                    selectedItems={selectedItems}
                    companyInventoryList={companyInventoryList}
                    getCategoryNameFromId={getCategoryNameFromId}
                    pricingTier={getPricingTier(item)}
                    itemStockLevel={itemStockLevels[item._id as string] || 0}
                />}
                keyExtractor={(item) => item._id as string}
                style={{ maxHeight: 500 }}
            />
            <Button onPress={onClose} style={styles.closeButton}>Close</Button>
        </View>
    );

    if (isSmallScreen) {
        return (
            <DMModal
                visible={visible}
                onClose={onClose}
            >
                <TouchableWithoutFeedback onPress={onClose}>
                    <Layout style={styles.modalOverlay}>
                        <TouchableWithoutFeedback>
                            <Layout style={styles.modalContainer}>
                                {renderInventoryList()}
                            </Layout>
                        </TouchableWithoutFeedback>
                    </Layout>
                </TouchableWithoutFeedback>
            </DMModal>
        );
    }

    return visible ? renderInventoryList() : null;
};

const styles = StyleSheet.create({
    modalContent: {
        flex: 1,
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        padding: 20,
        borderRadius: 10,
        width: '90%',
        maxHeight: '80%',
    },
    closeButton: {
        marginTop: 20,
    },
    itemInfo: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    stockLevel: {
        fontSize: 12,
        color: '#666',
        backgroundColor: '#f0f0f0',
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 4,
    },
}); 