import React from 'react';
//@ts-ignore
import { Image, AccessibilityRole, ImageProps, ImageStyle, StyleSheet, TouchableOpacity, View, Text as RNText, } from "react-native";
import { ApplicationProvider, Button, Icon, IconRegistry, Layout, Text, List, ListItem, Card, useTheme } from "@ui-kitten/components";
import * as ClientController from "../../functions/client.controller";
import * as StorageController from "../../functions/storageController";
import { Client } from "../../models/Client.model";

const LinkedIcon = (style: any) => (
    <Icon {...style} name='link-outline' width={15} height={15} fill={'#4169E1'} />
);

interface ClientsListProps {
    var_selectedClientToEdit: any,
    var_clients: any,
    fn_onSelectClient: any
}


export const ClientsList = ({
    var_selectedClientToEdit,
    var_clients,
    fn_onSelectClient
}: ClientsListProps) => {
    //@ts-ignore
    const theme = useTheme() as any

    const [clientsList, setClientsList] = React.useState([] as Client[])

    React.useEffect(() => {
        // ClientController.getClientsByCompanyId(StorageController.getAppState().selectedCompany._id).then(res => {
        //     setClientsList(res)
        // })
    }, [var_selectedClientToEdit])

    const setClient = (client: Client) => {
        // console.log(item, props)

        fn_onSelectClient(client)
    }


    const renderItem = ({ item, index }: { item: Client, index: number }) => {
        return (
            <ListItem
                style={{
                    backgroundColor: var_selectedClientToEdit && var_selectedClientToEdit._id == item._id ? theme['color-success-500'] : "",
                    border: '1px solid black'
                }}
                title={() => {
                    return <Text category='s1'>{item.name}</Text>
                }}
                description={() => {
                    return (
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 5 }}>
                            {item.linkedClientAccountId && (
                                <View style={{ marginLeft: 8 }}>
                                    <LinkedIcon />
                                </View>
                            )}
                            <Text category='c1' style={{ color: theme['text-basic-color'], fontSize: 10 }}>
                                Acc code: {item.account_code}
                            </Text>
                        </View>
                    )
                }}
                onPress={() => { setClient(item) }}
            />
        );
    }

    return (
        <Layout style={styles.listContainer}>
            {/* Active clients */}
            <Layout style={{ flex: 3 }}>
                <Text style={{ textAlign: 'center', backgroundColor: "green" }}>Active Clients</Text>
                <List
                    contentContainerStyle={styles.contentContainer}
                    data={
                        var_clients.filter((client: Client) => {
                            return !client.deleted
                        })
                    }
                    renderItem={renderItem}
                />
            </Layout>
            {/* deleted clients */}
            <Layout style={{ flex: 1 }}>
                <Text style={{ textAlign: 'center', backgroundColor: "red" }}>Deleted Clients</Text>
                <List
                    contentContainerStyle={styles.contentContainer}
                    data={
                        var_clients.filter((client: Client) => {
                            return client.deleted
                        })
                    }
                    renderItem={renderItem}
                />
            </Layout>
        </Layout>
    )
}


const styles = StyleSheet.create({
    container: {
        maxHeight: "100%",
        height: "100%",
        overflow: "hidden"
    },
    contentContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
        overflow: "hidden"
    },
    listContainer: {
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    tinyLogo: {
        width: "100%",
        height: 100,
    },
    item: {
        marginVertical: 4,
    },
});