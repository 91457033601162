import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
//@ts-ignore
import { StyleSheet, TouchableOpacity, View, Platform, ScrollView, Dimensions, useWindowDimensions } from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    useTheme,
    Select,
    SelectItem,
    Toggle,
    OverflowMenu,
    MenuItem,
    Modal,
    //@ts-ignore
    Spinner,
    Radio,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import AssignJobList from '../assignJobList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import { ServicesContainer } from '../services/servicesContainer.component';
import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
import * as HoldingController from '../../../functions/holding.controller'
//@ts-ignore
import debounce from 'lodash.debounce';
import ErrorBoundary from '../../ErrorBoundary.component';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import { Job, JobCustomerDetails, JobTowingDetails, Service, TowingLocation, JobVehicleDetails, JobLocation, JobAddress } from '../../../models/Job.model';
import { LineItem } from '../../../models/LineItem.model';
import { Member } from '../../../models/Member.model';
import { Company } from '../../../models/Company.model';
import { Client } from '../../../models/Client.model';


export const TransferJobComponent = ({
    job,
    onTransfer,
    canEdit = true,
    showModalDirect = false,
    onClose,
}: any) => {

    const [companies, setCompanies] = useState<Company[]>([]);
    const [showTransferModal, setShowTransferModal] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    //@ts-ignore
    const theme = useTheme() as any;

    useEffect(() => {
        setSelectedCompany(null);
    }, [job]);

    const onCloseModal = () => {
        setShowTransferModal(false);
        onClose();
    }



    useEffect(() => {
        // Fetch companies from StorageController's app state
        const fetchCompanies = async () => {
            try {
                const currentCompany = StorageController.getCurrentCompany(); // maybe check if we can
                const companies = StorageController.getAppState().companies
                if (!companies || !companies.length) return;
                const filteredCompanies = companies.filter((company) => company._id !== job.company_id); //Should be ours... but this could work in future
                setCompanies(filteredCompanies);
            } catch (error) {
                console.error('Error fetching companies:', error);
            }
        };

        fetchCompanies();
    }, []);


    const onSelectCompany = (companyIndex: any) => {
        const company = companies[companyIndex.row];
        console.log("🚀============== ~ file: transferJob.component.tsx:62 ~ onSelectCompany ~ company🚀==============", company)
        setSelectedCompany(company);
    }

    const handleTransferJob = async () => {
        if (!selectedCompany) {
            console.error('No company selected');
            return;
        }

        try {
            // Transfer job to selected company
            const transferResult = await JobsController.transferJob(job._id, job.company_id, selectedCompany._id);
            console.log("🚀============== ~ file: transferJob.component.tsx:84 ~ handleTransferJob ~ transferResult🚀==============", transferResult)
            onTransfer(transferResult);
            setShowTransferModal(false);
        } catch (error) {
            console.error('Error transferring job:', error);
        }
    };



    return (
        <View>
            <RenderTransferJobModal
                canEdit={canEdit}
                showTransferModal={showTransferModal || showModalDirect}
                setShowTransferModal={setShowTransferModal}
                onCloseModal={onCloseModal}
                selectedCompany={selectedCompany}
                companies={companies}
                onSelectCompany={onSelectCompany}
                handleTransferJob={handleTransferJob}
            />
            {!showModalDirect &&
                <Button
                    status='warning'
                    appearance='outline'
                    size='tiny'
                    onPress={() => setShowTransferModal(true)}
                    disabled={!canEdit}
                    accessoryLeft={() => (<Icon name="collapse-outline" fill={theme['text-basic-color']} width={20} height={20} />)}
                >
                    Transfer Job
                </Button>
            }
        </View>
    );
};

const RenderTransferJobModal = ({
    canEdit = true,
    showTransferModal,
    setShowTransferModal,
    onCloseModal,
    selectedCompany,
    companies,
    onSelectCompany,
    handleTransferJob,
}: any) => {
    return (
        <Modal
            visible={showTransferModal}
            backdropStyle={styles.backdrop}
            onBackdropPress={onCloseModal}>
            <Card
                status='warning'
                disabled={true}
                header={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category='h6'>Transfer Job</Text>
                        <Button
                            status='danger'
                            size='tiny'
                            onPress={onCloseModal}
                        >
                            <Icon name='close-outline' width={15} height={15} fill='white' />
                        </Button>
                    </View>}
            >
                <Text>Select a company to transfer the job:</Text>
                <Select
                    placeholder="Select company"
                    value={selectedCompany ? selectedCompany.name : ''}
                    onSelect={onSelectCompany}
                    disabled={!canEdit}
                >
                    {companies.map((company: Company) => (
                        <SelectItem key={company._id} title={company.name} />
                    ))}
                </Select>
                <View style={{ height: 20 }}></View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        status='success'
                        disabled={!canEdit || !selectedCompany}
                        onPress={handleTransferJob}>
                        Transfer Job
                    </Button>
                    <Button
                        appearance='ghost'
                        status='danger'
                        onPress={onCloseModal}
                    >
                        Cancel
                    </Button>
                </View>
            </Card>
        </Modal>
    );
}

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});

export default TransferJobComponent;