import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline, OverlayView } from '@react-google-maps/api';
import * as UpdateController from '../../../functions/update.controller';
import * as UserController from '../../../functions/user.controller';
import * as StorageController from "../../../functions/storageController"
import * as MessageController from "../../../functions/message.controller"
import * as GoogleController from "../../../functions/google.controller"
import * as JobsController from '../../../functions/job.controller'
import ErrorBoundary from '../../ErrorBoundary.component';
//@ts-ignore
import _ from 'lodash'
//@ts-ignore
import debounce from 'lodash.debounce';
import Toast from 'react-native-toast-message';
// import { AddressSearch } from '../searchAddress.component';
import DistanceTool from './../distanceTool.component';
import { RoadsideJobInfo } from './../RoadsideJobInfo.component';
import { TowingJobInfo } from './../TowingJobInfo.component';
import { MemberDeviceInfoCard } from '../../members/memberDeviceInfoCard.component';
import { Job, JobTowingDetails, TowingLeg } from '../../../models/Job.model';
import { Member } from '../../../models/Member.model';
import { Company } from '../../../models/Company.model'
import { darkMapStyles, lightMapStyles } from './../mapStyles';
export const RenderServiceAreaPolygons = ({ serviceAreas }: any) => {
    const [serviceAreasToHide, setServiceAreasToHide] = useState([])

    const onHideServiceAreaIndex = (index: number) => {
        let newServiceAreasToHide = [...serviceAreasToHide]
        //@ts-ignore
        if (newServiceAreasToHide.includes(index)) {
            newServiceAreasToHide = newServiceAreasToHide.filter((i) => i !== index)
        } else {
            (index as any)
        }
        setServiceAreasToHide(newServiceAreasToHide)
    }



    if (serviceAreas == null || serviceAreas.length == 0) return null;
    let coords = [] as any
    serviceAreas.map((serviceArea: any, index: number) => {
        coords.push(serviceArea.coords)
    })
    let polygons = [] as any
    coords.forEach((coord: any, index: number) => {
        // ensure lat and lng exist
        if (coord == null || coord.length == 0) return
        let polygon = coord.map((point: any) => {

            return { lat: point.lat || 0, lng: point.lng || 0 }
        })
        polygons.push(polygon)
    })
    return polygons.map((polygon: any, index: number) => {
        return (
            <React.Fragment key={index}>
                <Polygon
                    // key={index}
                    path={polygon}
                    options={{
                        fillColor: serviceAreas[index].color,
                        //@ts-ignore
                        fillOpacity: serviceAreasToHide.includes(index) ? 0.05 : 0.2,
                        strokeColor: serviceAreas[index].color,
                        //@ts-ignore
                        strokeOpacity: serviceAreasToHide.includes(index) ? 0.1 : 1,
                        strokeWeight: 2,
                    }}
                />
                <OverlayView
                    // key={index}
                    position={polygon[0]}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <Layout style={{
                        borderColor: 'black',
                        borderWidth: 1,
                        backgroundColor: serviceAreas[index].color,
                        color: "white",
                        padding: "5px",
                        borderRadius: "5px"
                    }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text category='label'>{serviceAreas[index].name}</Text>
                            <Button
                                appearance='ghost'
                                onPress={() => onHideServiceAreaIndex(index)}
                                style={{ padding: 0, height: 10, width: 10 }}
                            >
                                {/* @ts-ignore */}
                                {serviceAreasToHide.includes(index) ?
                                    <Icon name="eye-off-outline" width={20} height={20} fill="white" />
                                    :
                                    <Icon name="eye-outline" width={20} height={20} fill="white" />
                                }
                            </Button>
                        </View>
                    </Layout>
                </OverlayView>
            </React.Fragment>
        )
    })
}
