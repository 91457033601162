import { Job, JobNote, JOB_NOTE_TYPE, JobCustomerDetails } from './Job.model';
import { LineItem } from './LineItem.model';
import { Holding } from './Holding.model';
import { Invoice } from './Invoice.model';
import { Service } from './Job.model';
import * as CaseController from '../functions/case.controller';
import { Image } from './Image.model';


export enum CASE_STATUS {
    ONGOING = "ongoing",
    PENDING = "pending",
    COMPLETE = "complete",
    CANCELLED = "cancelled"
}

/**
 * Represents a Case.
 */
export class Case {

    _id: string;
    name: string;
    details: CaseDetails;
    status: CASE_STATUS
    company_id: string
    job_ids: Job[]
    holding_ids: Holding[]
    invoice_ids: Invoice[]
    completed_at: number
    pending_at: number
    createdAt: string
    updatedAt: string


    constructor(caseObj: any = {}) {
        this._id = caseObj._id || null;
        this.name = caseObj.name || null;
        this.details = new CaseDetails(caseObj.details || {});
        this.status = caseObj.status || null;
        this.company_id = caseObj.company_id || null;
        this.job_ids = caseObj.job_ids ? caseObj.job_ids.map((job: Job) => new Job(job)) : [];
        this.holding_ids = caseObj.holding_ids ? caseObj.holding_ids.map((holding: Holding) => new Holding(holding)) : [];
        this.invoice_ids = caseObj.invoice_ids ? caseObj.invoice_ids.map((invoice: Invoice) => new Invoice(invoice)) : [];
        this.completed_at = caseObj.completed_at || null;
        this.pending_at = caseObj.pending_at || null;
        this.createdAt = caseObj.createdAt || null;
        this.updatedAt = caseObj.updatedAt || null;
    }

    get friendly_id() {
        return this.name
    }

    /**
     * Set the status of the case.
     * @param {CASE_STATUS} status - The new status.
     * @returns {Promise<Case>} - A promise that resolves to the updated Case instance.
     */
    async setStatus(status: CASE_STATUS) {
        try {
            const response: any = await CaseController.setCaseStatus(this._id, status);
            this.status = status;
            return response?.data;
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Get all line items for billing back in all jobs and holdings associated with the case.
     * @returns {LineItem[]} - An array of line items.
     */
    getTotalBillBackItems() {
        let lineItems = [] as LineItem[];
        this.job_ids.forEach((job: Job) => {
            const items = job.getTotalBillBackItems();
            if (job.details?.client_rate?.billed_to === 'bill_all_back') {
                const rateItem = job.details.client_rate.generateLineItem();
                rateItem.name = 'Callout - ' + rateItem.name;
                lineItems = [...lineItems, rateItem];
            }
            lineItems = [...lineItems, ...items];
        });
        this.holding_ids.forEach(holding => {
            const items = holding.getTotalBillBackItems();
            lineItems = [...lineItems, ...items];
        });
        return lineItems;
    }

    /**
     * Get all line items for customer cost in all jobs and holdings associated with the case.
     * @returns {LineItem[]} - An array of line items.
     */
    getTotalCustomerCostItems() {
        let lineItems: LineItem[] = [];
        this.job_ids.forEach(job => {
            const items = job.getTotalCustomerCostItems();
            if (job.details?.client_rate?.billed_to === 'customer_cost') {
                const rateItem = job.details.client_rate.generateLineItem();
                rateItem.name = 'Callout - ' + rateItem.name;
                lineItems = [...lineItems, rateItem];
            }
            lineItems = [...lineItems, ...items];
        });
        this.holding_ids.forEach(holding => {
            const items = holding.getTotalCustomerCostItems();
            lineItems = [...lineItems, ...items];
        });
        return lineItems;
    }

    /**
     * Get all line items from jobs and holdings
     * @returns {LineItem[]} - An array of line items.
     */
    getAllLineItems() {
        let lineItems: LineItem[] = [];
        this.job_ids.forEach(job => {
            const items = job.getLineItems();
            lineItems = [...lineItems, ...items];
        });
        this.holding_ids.forEach(holding => {
            const items = holding.line_items;
            lineItems = [...lineItems, ...items];
        });
        return lineItems;
    }

    /**
     * Get the total cost of all jobs associated with the case.
     * @returns {number} - The total cost.
     */
    getTotalCost() {
        let total = 0;
        this.job_ids.forEach(job => {
            total += job.getTotalLineItemCostCostIncludingClientRate();
        });
        this.holding_ids.forEach(holding => {
            total += holding.getTotalCost();
        })
        return total;
    }

    /**
     * Get the total bill back of all jobs associated with the case.
     * @returns {number} - The total bill back.
     */
    getTotalBillBack() {
        let total = 0;
        this.job_ids.forEach(job => {
            total += job.getTotalBillback();
            const clientRate = job.details?.client_rate;
            if (clientRate) {
                if (clientRate.billed_to === 'bill_all_back') {
                    total += clientRate.cost;
                }
            }
        });
        this.holding_ids.forEach(holding => {
            total += holding.getTotalCostBilledToCompany();
        })
        return total;
    }

    /**
     * Get the total customer cost of all jobs associated with the case.
     * @returns {number} - The total customer cost.
     */
    getTotalCustomerCost() {
        let total = 0;
        try {
            this.job_ids.forEach(job => {
                total += job.getTotalCustomerCost();
                const clientRate = job.details?.client_rate;
                if (clientRate) {
                    if (clientRate.billed_to === 'customer_cost') {
                        total += clientRate.cost;
                    }
                }
            });
            this.holding_ids.forEach(holding => {
                total += holding.getTotalCostBilledToCustomer();
            })
            return total;
        } catch (error) {
            console.log(error);
            return total;
        }
    }

    /**
     * Get the total customer paid of all jobs and holdings associated with the case.
     */
    getTotalCustomerPaid() {
        let total = 0;
        const totalCustomerCostItems = this.getTotalCustomerCostItems();
        totalCustomerCostItems.forEach(item => {
            if (item.paid) {
                total += Number(item.cost) * Number(item.quantity);
            }

        });
        return total;
    }

    /**
     * Get the names of all services in all jobs associated with the case.
     * @returns {string[]} - An array of service names.
     */
    getServicesNames() {
        let services: string[] = [];
        this.job_ids.forEach(job => {
            const names = job.getServicesNames();
            services = [...services, ...names];
        });
        return services;
    }

    getCaseServiceNames() {
        return this.details.selected_services.map(service => service.name).join(", ");
    }

    /**
     * Merge and sort jobs and holdings by date.
     * @returns {[]} - An array of jobs and holdings
     */
    getJobsAndHoldings() {
        let jobsAndHoldings = [];
        if (this.job_ids.length === 0 && this.holding_ids.length === 0) return [];
        jobsAndHoldings = [...this.job_ids, ...this.holding_ids]
        return jobsAndHoldings.sort((a, b) => {
            if (a.createdAt && b.createdAt) {
                return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
            }
            return 0;
        });
        // return jobsAndHoldings.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
}



export class CaseDetails {
    notes: JobNote[]
    comments: string | null
    customer_details: JobCustomerDetails | null
    images: Image[]
    client_reference_id: string | null
    selected_services: Service[]
    contract_id: string | null
    assigned_member_id: string | null

    constructor(details: any = {}) {
        this.notes = details.job_notes ? details.job_notes.map((note: JobNote) => new JobNote(note)) : [];
        this.comments = details.comments || null;
        this.customer_details = details.customer_details ? new JobCustomerDetails(details.customer_details) : null;
        this.images = details.images ? details.images.map((image: Image) => new Image(image)) : [];
        this.client_reference_id = details.client_reference_id || null;
        this.selected_services = details.selected_services ? details.selected_services.map((service: Service) => new Service(service)) : [];
        this.contract_id = details.contract_id || null;
        this.assigned_member_id = details.assigned_member_id || null;
    }

    addNote(note: JobNote) {
        this.notes.push(note);
    }

    deleteNote(note: JobNote) {
        this.notes = this.notes.filter(n => n._id !== note._id);
    }

    updateNote(note: JobNote) {
        this.notes = this.notes.map(n => {
            if (n._id === note._id) {
                return note;
            }
            return n;
        });
    }

}