import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Animated,
    ScrollView,
    Dimensions,
    useWindowDimensions,
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Input,
    Modal,
    Layout,
    Text,
    List,
    ListItem,
    Divider,
    Card
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import * as HoldingController from "../../../functions/holding.controller"
import * as StorageController from "../../../functions/storageController"
import * as JobController from "../../../functions/job.controller"
import * as ImageController from "../../../functions/image.controller"
import * as UpdateController from "../../../functions/update.controller"
import { Holding } from "../../../models/Holding.model";
import { Job, JobAddress, JobLocation, LineItem } from "../../../models/Job.model";
import { ReportDetailsCard } from '../details/reportDetailsCard.component';
import { CreateJobFromHolding } from '../modals/createJobFromHoldingModal.component';
import { InventoryDetailsCard } from '../details/inventory/inventoryDetailsCard.component';
import { ImageDetailsCard } from '../details/imageDetailsCard.component';
import { InvoicedTotalCard } from '../details/jobInvoicedTotalDetailsCard.component';
import { VehicleDetailsCard } from '../details/vehicleDetailsCard.component';
import { CustomerDetailsCard } from '../details/customerDetailsCard.component';
import { ServiceDetailsCard } from '../details/serviceDetailsCard.component';
import * as ClientController from '../../../functions/client.controller'
import { LocationDetailsCard } from '../details/locationDetailsCard.component';
import { Client } from '../../../models/Client.model';
import { JobNotesList } from '../details/jobNotes.component';
import { isArray, set } from 'lodash';
import { useNotification } from '../../../context/notifications.context';
// const { width, height } = Dimensions.get('window');


interface HoldingDetailsContainerProps {
    holdingItem: Holding,
    fn_onClose: Function,
    fn_onReleaseAndCreateJobHolding: Function,
    fn_onReleaseOnlyHolding: Function,
    disabled: boolean
}

export const HoldingDetailsContainer = ({
    holdingItem,
    fn_onClose,
    fn_onReleaseAndCreateJobHolding,
    fn_onReleaseOnlyHolding,
    disabled = false
}: HoldingDetailsContainerProps) => {
    const TOGGLE_VIEW = {
        HOLDING: 'holding',
        RELEASED: 'released',
        NONE: 'none'
    }

    const [holdingState, setHolding] = useState(null as Holding | null);
    const [holdingJob, setHoldingJob] = useState(null as Job | null);
    const [releasedJob, setReleasedJob] = useState(null as Job | null);
    const [holdingClient, setHoldingClient] = useState(null as Client | null);
    const [toggleJobToShow, setToggleJobToShow] = useState(TOGGLE_VIEW.NONE);
    const [images, setImages] = useState([] as Image[])
    // const { showNotification } = useNotification()
    const { width: windowWidth, height: windowHeight } = useWindowDimensions()
    const [showNotificationWidget, setShowNotificationWidget] = useState(false)
    const [notificationWidgetProps, setNotificationWidgetProps] = useState({ title: "", text: "" })
    const notificationWidgetTimeoutRef = useRef(null as any)
    const onShowNotificationWidget = (title: string, text: string) => {
        // showNotification(title, text, () => {
        //     fn_onClose()
        // })
        // setNotificationWidgetProps({ title, text })
        // setShowNotificationWidget(true)
        // if (notificationWidgetTimeoutRef.current) clearTimeout(notificationWidgetTimeoutRef.current)
        // notificationWidgetTimeoutRef.current = setTimeout(() => {
        //     setShowNotificationWidget(false)
        // }, 2000)
    }


    const RenderNotificationWidget = ({ title, text }: any) => {
        // replacing a toast
        return (
            <Card
                disabled={true}
                style={{ padding: 0, backgroundColor: 'rgba(20,50,0,0.7)' }}
                status='success'
                header={() => <Text>{title}</Text>}
            >
                <View style={{ margin: -15 }}>
                    <Text>{text}</Text>
                </View>
            </Card>
        )
    }

    // create fading animation for notification widget
    const fadeAnim = useRef(new Animated.Value(0)).current  // Initial value for opacity: 0
    useEffect(() => {
        Animated.timing(
            fadeAnim,
            {
                toValue: showNotificationWidget ? 1 : 0,
                duration: 500,
                useNativeDriver: true
            }
        ).start();
    }, [showNotificationWidget])


    useEffect(() => {
        load()
        return () => {
            setHolding(null)
            setHoldingJob(null)
            setReleasedJob(null)
            setHoldingClient(null)
            setImages([])
            setToggleJobToShow(TOGGLE_VIEW.NONE)
        }
    }, [holdingItem])

    const load = async () => {
        if (holdingItem) {
            const holding = await fetchHolding(holdingItem._id as string);
            if (holding) {
                onSetHolding(holding)
                fetchHoldingImages(holding)
                await getJobsIfNotLoaded(holding);
                const client = await getClientDetails(holding.client_id as string);
                if (client) {
                    setHoldingClient(client)
                }
            }
        }
    }

    const fetchHolding = async (holdingId: string) => {
        try {
            const response = await HoldingController.findByPk(holdingId);
            if (response) {
                return response
            }
        } catch (error) {
            console.error(error);
        }
    }

    const fetchHoldingImages = async (holding: Holding) => {
        try {
            let imageArr = [] as any
            let jobImages = [] as any
            const holdingImages = await ImageController.fetchHoldingImages(holding._id as string);
            if (holdingImages && Array.isArray(holdingImages)) {
                imageArr = [...imageArr, ...holdingImages]
            }
            if (holding.holding_job_id) {
                const holdingJobImages = await ImageController.fetchJobImages(holding.holding_job_id);
                if (holdingJobImages) {
                    jobImages = [...jobImages, ...holdingJobImages]
                }
            }
            if (holding.released_job_id) {
                const releasedJobImages = await ImageController.fetchJobImages(holding.released_job_id);
                if (releasedJobImages) {
                    jobImages = [...jobImages, ...releasedJobImages]
                }
            }
            const images = [...imageArr, ...jobImages]
            setImages(images)
        } catch (error) {
            console.error(error);
        }
    }

    const onSetHolding = (holding: Holding) => {
        setHolding(new Holding(holding))
    }

    const getJobsIfNotLoaded = async (holding: Holding) => {
        if (holding?.holding_job_id) {
            const holdingJob = await JobController.getJobById(holding?.holding_job_id)
            if (holdingJob) {
                setHoldingJob(holdingJob)
            }
        }
        else {
            setHoldingJob(null)
        }
        if (holding?.released_job_id) {
            const releasedJob = await JobController.getJobById(holding?.released_job_id)
            if (releasedJob) {
                setReleasedJob(releasedJob)
            }
        } else {
            setReleasedJob(null)
        }
    }


    const getClientDetails = async (client_id: string) => {
        if (client_id) {
            const client = await ClientController.getClientById(client_id)
            return client
        }
    }



    const addHeldDaysToItems = (coveredDays: string, ratePerDay: string) => {
        try {
            if (!holdingState) return
            const totalDays = holdingState?.getTimeElapsed().days;
            const validCoveredDays = Number(coveredDays) || 0;
            const validRatePerDay = Number(ratePerDay) || 0;
            const coveredDaysCost = validCoveredDays * validRatePerDay;
            const chargeableDays = Math.max(0, totalDays - validCoveredDays);
            const cost = chargeableDays * validRatePerDay;

            // Create an item for the covered days as a bill back item
            let coveredDaysItem = null;
            let chargableDaysItem = null;

            if (validCoveredDays > 0) {
                coveredDaysItem = new LineItem({
                    name: `Holding COVERED - ${validCoveredDays} days of ${totalDays} @ $${validRatePerDay}/day`,
                    description: `Holding COVERED - ${validCoveredDays} days of ${totalDays} @ $${validRatePerDay}/day`,
                    quantity: 1,
                    cost: coveredDaysCost,
                    customerCost: false,
                    billing: "Bill Back",
                    billAllBack: true
                });
            }

            // Create an item for the held days as a customer cost item
            if (chargeableDays > 0) {
                chargableDaysItem = new LineItem({
                    name: `Holding Rate - ${validCoveredDays > 0 ? `(Covered ${validCoveredDays} days) - ` : ''}Held ${totalDays} days @ $${validRatePerDay}/day`,
                    description: `Holding Rate - ${validCoveredDays > 0 ? `(Covered ${validCoveredDays} days) - ` : ''}Held ${totalDays} days @ $${validRatePerDay}/day`,
                    quantity: 1,
                    cost: cost,
                    customerCost: true,
                    billing: "Customer Cost",
                    billAllBack: false
                });
            }

            let items = [...holdingState.line_items] || []
            if (coveredDaysItem) items.push(coveredDaysItem)
            if (chargableDaysItem) items.push(chargableDaysItem)
            onSetHolding({ ...holdingState, line_items: items } as Holding)
        } catch (error) {
        }
    }

    const RenderAddHeldDaysToItemsCard = ({ style }: any) => {
        const [coveredDays, setCoveredDays] = useState("")
        const [ratePerDay, setRatePerDay] = useState("")
        return (
            <Card
                style={style}
                disabled={true}
                status='warning'
                header={() => <Text category='s1'>Add Held Days to Items</Text>}
                footer={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button appearance='outline' status='success' onPress={() => { addHeldDaysToItems(coveredDays, ratePerDay) }}>Add</Button>
                    </View>
                }
            >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>{holdingState?.getTimeElapsed()?.readable}</Text>
                </View>
                {/* Covered days and /day rate input */}
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 3 }}>
                    <Input placeholder='Covered days' size="small" style={{ flex: 1 }} label="Covered Days" value={coveredDays} onChangeText={setCoveredDays} />
                    <Input placeholder='$/Day' size="small" style={{ flex: 1 }} label="Rate Per Day ($)" value={ratePerDay} onChangeText={setRatePerDay} />
                </View>
                <Text>
                    - This will add a new item to the job report with the number of days held and the rate per day.
                </Text>
            </Card>
        )
    }

    const RenderClientDetailsCard = ({ style }: any) => {
        return (
            <Card
                disabled={true}
                style={[style]}
                status='primary'
                header={() => <Text category='s1'>Client Details</Text>}
            >
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>{holdingClient?.name}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Email: </Text>
                    <Text>{holdingClient?.contact_info?.email}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Phone: </Text>
                    <Text>{holdingClient?.contact_info?.phone}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category='s1'>Address</Text>
                    <Text>{holdingClient?.contact_info?.address?.formatted_address || ""}</Text>
                </View>
            </Card>
        )
    }
    const onSaveHolding = async (showNotification = true) => {
        try {
            if (!holdingState) return
            await holdingState.save()
            // display notification
            if (showNotification) {
                fn_onClose()
                // onShowNotificationWidget("Holding Updated", `Holding updated successfully`)
            }
            return
        } catch (e) {
            console.log("🚀============== ~ file: holdingDetailsContainer.component.tsx:344 ~ onSaveHolding ~ e🚀==============", e)
        }
    }

    const onReleaseOnlyHolding = async () => {
        try {
            if (!holdingState) return
            const saveResult = await onSaveHolding()
            const response = await holdingState.setReleased()
            if (response) {
                fn_onReleaseOnlyHolding(holdingState)
            }
        } catch (e) {
        }
    }

    const onCreateJobAndReleaseHolding = async () => {
        try {
            await onSaveHolding(false)
            console.log("🚀============== ~ file: holdingDetailsContainer.component.tsx:365 ~ onCreateJobAndReleaseHolding ~ onSaveHolding🚀==============")
            fn_onReleaseAndCreateJobHolding(holdingState)
        } catch (e) {
            console.log("🚀============== ~ file: holdingDetailsContainer.component.tsx:365 ~ onCreateJobAndReleaseHolding ~ e🚀==============", e)
        }
    }

    if (!holdingState) return null

    const getAddress = () => {
        // if theres no holding job, attempt to get from details.address
        if (!holdingJob) {
            return holdingState.details.address || holdingState.details.towing_details.dropoff_towing_location.address
        } else {
            return holdingState.details.towing_details.dropoff_towing_location.address
        }
    }

    const getLocation = () => {
        // if theres no holding job, attempt to get from details.location
        if (!holdingJob) {
            return holdingState.details.location
        } else {
            return holdingState.details.towing_details.dropoff_towing_location.location
        }
    }


    return (
        <Layout style={{ width: "100%", height: "100%", justifyContent: 'center', alignItems: 'center' }}>
            {showNotificationWidget &&
                <View style={{ position: 'absolute', top: -15, left: 0, zIndex: 1000 }}>
                    <Animated.View style={{ opacity: fadeAnim }}>
                        <RenderNotificationWidget
                            title={notificationWidgetProps.title}
                            text={notificationWidgetProps.text}
                        />
                    </Animated.View>
                </View>
            }
            <Card
                disabled={true}
                status='primary'
                style={{ width: "100%", height: "100%" }}
                header={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text category="h6">Holding Details: {holdingItem.createdAt}</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
                            <Button
                                onPress={() => {
                                    onSaveHolding()
                                }}
                                status="warning">
                                <>
                                    <Icon name="save-outline" width={24} height={24} fill={"white"} />
                                    Save
                                </>
                            </Button>
                            <Button status="danger" onPress={() => { fn_onClose() }}>
                                <>
                                    <Icon name="close-outline" width={24} height={24} fill={"white"} />
                                    Close
                                </>
                            </Button>
                        </View>
                    </View>
                }
                footer={() => (
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View></View>
                        <View style={{ flexDirection: 'row', gap: 5 }}>
                            <Button
                                disabled={disabled}
                                onPress={() => onCreateJobAndReleaseHolding()}
                                appearance='outline'
                                status="success">
                                Save & Release Holding And Create Job
                            </Button>
                            <Button
                                disabled={disabled}
                                onPress={() => onReleaseOnlyHolding()}
                                appearance='outline'
                                status="warning">
                                Save & Release Holding Only
                            </Button>
                        </View>
                    </View>
                )}
            >
                <View style={{ width: "100%", height: "100%", flexDirection: 'column', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderBottomColor: 'black', borderBottomWidth: 1 }}>
                        <View style={{ flex: 1, flexDirection: 'row', gap: 5 }}>
                            {/* Buttons to switch which report to show */}
                            <Button
                                disabled={!holdingItem.holding_job_id}
                                appearance={toggleJobToShow === TOGGLE_VIEW.HOLDING ? 'filled' : 'outline'}
                                onPress={() => setToggleJobToShow(TOGGLE_VIEW.HOLDING)}
                                status={toggleJobToShow === TOGGLE_VIEW.HOLDING ? 'primary' : 'basic'}
                            >HOLDING</Button>
                            <Button
                                disabled={!holdingItem.released_job_id}
                                appearance={toggleJobToShow === TOGGLE_VIEW.RELEASED ? 'filled' : 'outline'}
                                onPress={() => setToggleJobToShow(TOGGLE_VIEW.RELEASED)}
                                status={toggleJobToShow === TOGGLE_VIEW.RELEASED ? 'primary' : 'basic'}
                            >RELEASED</Button>
                            <Button
                                appearance={toggleJobToShow === TOGGLE_VIEW.NONE ? 'filled' : 'outline'}
                                onPress={() => setToggleJobToShow(TOGGLE_VIEW.NONE)}
                                status={toggleJobToShow === TOGGLE_VIEW.NONE ? 'primary' : 'basic'}
                            >HIDE</Button>
                        </View>
                        <View style={{ flex: 2, flexDirection: 'row', gap: 5 }}>
                            <Button
                                onPress={() => onCreateJobAndReleaseHolding()}
                                appearance='outline'
                                status="success">
                                Save & Release Holding And Create Job
                            </Button>
                            <Button
                                onPress={() => onReleaseOnlyHolding()}
                                appearance='outline'
                                status="warning">
                                Save & Release Holding Only
                            </Button>
                        </View>
                        {/* <View style={{ flex: 1, flexDirection: 'row', gap: 5}}></View> */}
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        {toggleJobToShow != TOGGLE_VIEW.NONE &&
                            <View style={{ flex: 1, height: windowHeight * 0.8, flexDirection: 'row', justifyContent: 'space-between' }}>
                                {toggleJobToShow === TOGGLE_VIEW.HOLDING &&
                                    <>
                                        {holdingJob &&
                                            <ScrollView style={{ flex: 1 }}>
                                                <ReportDetailsCard
                                                    job={holdingJob}
                                                />
                                            </ScrollView>
                                        }
                                    </>
                                }
                                {toggleJobToShow === TOGGLE_VIEW.RELEASED &&
                                    <>
                                        {releasedJob &&
                                            <ScrollView style={{ flex: 1 }}>
                                                <ReportDetailsCard
                                                    job={releasedJob}
                                                />
                                            </ScrollView>
                                        }
                                    </>
                                }
                            </View>
                        }
                        <View style={{ flex: 2 }}>
                            <ScrollView style={[styles.scrollContainer, { height: windowHeight * 0.7 }]}>
                                <View style={{ flexDirection: 'row', flexWrap: "wrap", flex: 1 }}>
                                    <View style={styles.imageAreaStyle}>
                                        <ImageDetailsCard
                                            images={images}
                                            propStyle={{ flex: 1 }}
                                            onSetLocalImages={setImages}
                                            onSetImages={async (images) => { }}
                                            showUploadButton={false}
                                        />
                                    </View>
                                    <View style={styles.mainAreaStyle}>
                                        {/* <ScrollView style={{ flex: 1 }}> */}
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                            <CustomerDetailsCard
                                                customerDetails={holdingState.details.customer_details}
                                                allowEdit={true}
                                                onChangeCustomerDetails={(customerDetails) => {
                                                    onSetHolding({ ...holdingState, details: { ...holdingState.details, customer_details: customerDetails } } as Holding)
                                                }}
                                                style={[styles.groupContainer, { flexBasis: "33%" }]}
                                            />
                                            <VehicleDetailsCard
                                                allowEdit={true}
                                                onChangeVehicleDetails={(vehicleDetails) => {
                                                    onSetHolding({ ...holdingState, details: { ...holdingState.details, vehicle_details: vehicleDetails } } as Holding)
                                                }}
                                                vehicleDetails={holdingState.details.vehicle_details}
                                                style={[styles.groupContainer, { flexBasis: "33%" }]}
                                            />
                                            <RenderClientDetailsCard style={[styles.groupContainer, { flexBasis: "33%" }]} />
                                        </View>
                                        <View style={{ flexDirection: 'row', flexWrap: "wrap" }}>
                                            <LocationDetailsCard
                                                style={{ flex: 2, flexBasis: "80%" }}
                                                cardTitle={"Holding Location"}
                                                address={getAddress()}
                                                location={getLocation()}
                                                allowEdit={false}
                                                onSetAddress={function (address: JobAddress): void {
                                                }}
                                                onSetLocation={function (location: JobLocation): void {
                                                }}
                                                searchDisplayValue={''} />
                                            <View style={{ flex: 1, flexBasis: "30%" }}>
                                                <RenderAddHeldDaysToItemsCard style={{}} />
                                            </View>
                                        </View>
                                        <InventoryDetailsCard
                                            jobSource={'vendor'}
                                            onChangeSelectedItems={(items) => { onSetHolding({ ...holdingState, line_items: items } as Holding); }}
                                            selectedItems={holdingState.line_items}
                                            vendorProfile={null} />
                                        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                                            <JobNotesList
                                                notes={holdingState.details.notes}
                                                onAddNote={(note) => {
                                                    onSetHolding({ ...holdingState, details: { ...holdingState.details, notes: [...holdingState.details.notes, note] } } as Holding)
                                                }}
                                                onDeleteNote={(note) => {
                                                    onSetHolding({ ...holdingState, details: { ...holdingState.details, notes: holdingState.details.notes.filter(n => n !== note) } } as Holding)
                                                }}
                                                onUpdateNote={(note) => {
                                                    onSetHolding({ ...holdingState, details: { ...holdingState.details, notes: holdingState.details.notes.map(n => n === note ? note : n) } } as Holding)
                                                }}

                                            />
                                        </View>
                                        <InvoicedTotalCard
                                            holding={holdingState as Holding}
                                            style={{ flex: 1 }}
                                        />
                                        {/* </ScrollView> */}
                                    </View>
                                    <View style={styles.serviceAreaStyle}>
                                        <ServiceDetailsCard
                                            allowEdit={true}
                                            selectedServices={holdingState.details.selected_services}
                                            updateJobServices={(services) => {
                                                onSetHolding({ ...holdingState, details: { ...holdingState.details, selected_services: services } } as Holding);
                                            }}
                                            canSetTimes={true} />
                                    </View>
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </Card>
        </Layout >
    )
}

const styles = StyleSheet.create({
    modal: {
        // width: width * 0.9,
        // height: height * 0.9,
        padding: 0,
        margin: 0
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    groupContainer: {
        margin: 0,
        flex: 1,
        minWidth: 250,
    },
    groupContainerLarge: {
        margin: 0,
        minWidth: 250,
    },
    imageAreaStyle: {
        flexBasis: "20%",
        minWidth: 250,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    mainAreaStyle: {
        flexBasis: "60%",
        minWidth: 350,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    serviceAreaStyle: {
        flexBasis: "20%",
        minWidth: 250,
        // height: '100%',
        flexGrow: 1,
        flexShrink: 1,
    },
    scrollContainer: {
        // flex: 1,
        // overflow: 'hidden',
        height: '100%',
        width: '100%',
    },
});