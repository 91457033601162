import React from 'react';
import { ScrollView } from 'react-native';
import { Button, Icon, Layout } from '@ui-kitten/components';
import { FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { HoldingDetailsContainer } from '../job/holding/holdingDetailsContainer.component';
import { Job } from '../../models/Job.model';
import { Holding } from '../../models/Holding.model';

interface JobDetailsPanelProps {
  selectedItem: Job | Holding | null;
  onClose: () => void;
  holdingsOrJobs: 'holdings' | 'jobs';
}

export const JobDetailsPanel = ({ selectedItem, onClose, holdingsOrJobs }: JobDetailsPanelProps) => (
  <Layout style={{
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: "50%"
  }}>
    <Button
      style={{ position: 'absolute', top: 0, right: 0, zIndex: 10 }}
      status='danger'
      onPress={onClose}
    >
      <Icon name="close-outline" width={30} height={30} />
    </Button>
    <ScrollView style={{ flex: 1 }}>
      {holdingsOrJobs === "holdings" ?
        <HoldingDetailsContainer
          fn_onClose={onClose}
          fn_onReleaseAndCreateJobHolding={() => {}}
          holdingItem={selectedItem as Holding}
          fn_onReleaseOnlyHolding={onClose}
          disabled={false}
        />
        :
        <FullJobReport job={selectedItem as Job} onClose={onClose} showImages={false} showLogs={false} />
      }
    </ScrollView>
  </Layout>
);