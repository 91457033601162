import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';

//@ts-ignore
import _ from 'lodash'


import { AddressSearch } from '../searchAddress.component';
import { JobAddress } from '../../../models/Job.model';


export const RenderAddressSearch = ({
    setShowSearchMarker,
    setCenter,
    setSearchMarkerPosition,
}: any) => {
    return (
        <AddressSearch
            style={{
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
            }}
            fn_onSelect={(address: JobAddress) => {
                try {
                    setShowSearchMarker(true)
                    setCenter(address.location)
                    setSearchMarkerPosition(address);
                } catch (err) {
                }
            }}
        />
    )
}