import { JobAddress, JobCustomerDetails, JobLocation, JobVehicleDetails } from "./Job.model";

export class Order {
  _id?: string;
  client_company_id: string | null = null;
  vendor_company_id: string | null = null;
  items: OrderItem[] = [];
  total_amount: number = 0;
  payment_details: any;
  related_job_id: string | null = null;
  status: OrderStatus = OrderStatus.DRAFT;
  details: OrderDetails = {
    dispatch_comments: "",
    customer_details: new JobCustomerDetails({}),
    vehicle_details: new JobVehicleDetails({}),
    address: new JobAddress({}),
    location: new JobLocation({}),
    delivery_fee: 0,
    scheduled_date: null,
    client_company_name: "",
    vendor_company_name: "",
  };
  friendly_id: string = "";
  createdAt: Date = new Date();
  updatedAt: Date = new Date();

  constructor(data: Partial<Order>) {
    Object.assign(this, data);
  }
}

export interface OrderDetails {
  dispatch_comments: string;
  customer_details: JobCustomerDetails;
  vehicle_details: JobVehicleDetails;
  address: JobAddress;
  location: JobLocation;
  delivery_fee: number;
  scheduled_date: Date | null;
  client_company_name: string;
  vendor_company_name: string;
}

  export interface OrderItem {
    inventory_item_id: string;
    quantity: number;
    price: number;
  }
  

  export enum OrderStatus {
    DRAFT = 'draft',
    REQUEST = 'request',
    AWAITING_PAYMENT = 'awaiting_payment',
    PROCESSING = 'processing',
    READY = 'ready',
    SHIPPED = 'shipped',
    DELIVERED = 'delivered',
    PENDING = 'pending',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
  }
  
  export interface OrderSearchParams {
    client_company_id?: string;
    vendor_company_id?: string;
    item_sale_id?: string;
    status?: OrderStatus;
    min_total_price?: number;
    max_total_price?: number;
    start_date?: Date;
    end_date?: Date;
    limit?: number;
    offset?: number;
  }
  
