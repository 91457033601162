import React, { useState, useRef, useEffect } from 'react';
import { View, ScrollView, StyleSheet, Modal, TouchableWithoutFeedback } from 'react-native';
import { Text, CheckBox, useTheme, Spinner, Button, Layout } from '@ui-kitten/components';
import { Route, RouteItem, RouteLocation } from '../../models/Route.model';
import { Job, LineItem } from '../../models/Job.model';
import * as RouteController from '../../functions/route.controller';
import * as JobController from '../../functions/job.controller';
import { InventoryDetailsCard } from '../job/details/inventory/inventoryDetailsCard.component';

interface ItemPickerProps {
    route: Route;
    onRouteUpdate: (updatedRoute: Route) => void;
}

export const ItemPicker: React.FC<ItemPickerProps> = ({ route, onRouteUpdate }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [updatedRoute, setUpdatedRoute] = useState(new Route(route));
    const loadingIdRef = useRef<string | null>(null);
    const theme = useTheme();
    const [showInventoryModal, setShowInventoryModal] = useState(false);
    const [selectedLocationIndex, setSelectedLocationIndex] = useState<number | null>(null);

    const handleItemStatusChange = async (item: RouteItem, nextChecked: boolean) => {
        try {
            loadingIdRef.current = item.id;
            setIsLoading(true);

            const newRoute = new Route({ ...updatedRoute });
            newRoute.updateItemStatus(item.id, nextChecked);
            setUpdatedRoute(newRoute);

            // Save changes automatically
            await RouteController.updateRoute(newRoute);
            onRouteUpdate(newRoute);

            setIsLoading(false);
            loadingIdRef.current = null;
        } catch (error) {
            console.error("Error updating item status:", error);
            setIsLoading(false);
            loadingIdRef.current = null;
        }
    };

    const handleAddItems = async (newItems: LineItem[]) => {
        if (selectedLocationIndex === null) return;

        try {
            setIsLoading(true);

            // Update the job with new line items
            const location = updatedRoute.details.locations[selectedLocationIndex];
            const job = await JobController.getJobById(location.job_id);
            if (!job) throw new Error("Job not found");

            const updatedJob = new Job({ ...job });
            updatedJob.details.report.items = [...(updatedJob.getLineItems() || []), ...newItems];
            await updatedJob.save();

            // Update the route with new items
            const newRoute = new Route({ ...updatedRoute });
            newRoute.details.locations[selectedLocationIndex].inventory_items = [
                ...location.inventory_items,
                ...newItems
            ];
            newRoute.syncItemsWithLocations();

            await RouteController.updateRoute(newRoute);
            setUpdatedRoute(newRoute);
            onRouteUpdate(newRoute);

            setIsLoading(false);
            setShowInventoryModal(false);
        } catch (error) {
            console.error("Error adding items:", error);
            setIsLoading(false);
        }
    };

    const styles = StyleSheet.create({
        locationContainer: {
            marginBottom: 16,
            padding: 8,
            borderRadius: 8,
            borderWidth: 1,
            borderColor: theme['color-basic-500'],
        },
        locationTitle: {
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 4,
        },
        locationAddress: {
            marginBottom: 8,
        },
        itemContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 8,
            marginBottom: 4,
            borderRadius: 4,
        },
        saveButton: {
            marginTop: 16,
        },
        modalOverlay: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        modalContainer: {
            padding: 20,
            borderRadius: 10,
            width: '90%',
            maxHeight: '90%',
        },
    });

    const renderItemsByLocation = () => {
        return updatedRoute.details.locations.map((location: RouteLocation, locationIndex: number) => (
            <View key={locationIndex} style={styles.locationContainer}>
                <Text style={styles.locationTitle}>Stop {locationIndex + 1}: {location.name}</Text>
                <Text style={styles.locationAddress}>{location.address.addressToString()}</Text>
                {location.inventory_items.map((lineItem: LineItem) => {
                    const routeItem = updatedRoute.details.itemsToHandle.find(item => item.lineItemId === lineItem._id);
                    if (!routeItem) return null;

                    return (
                        <View key={routeItem.id} style={[
                            styles.itemContainer,
                            { backgroundColor: routeItem.isPicked ? theme['color-success-600'] : 'transparent' }
                        ]}>
                            {loadingIdRef.current === routeItem.id && isLoading && <Spinner size='small' />}
                            <Text>{lineItem.name} (Qty: {lineItem.quantity})</Text>
                            <View style={{ flexDirection: 'row', gap: 10 }}>
                                <Text>{routeItem.locationType === 'pickup' ? 'Pick up' : 'Deliver'}</Text>
                            </View>
                            <CheckBox
                                checked={routeItem.isPicked}
                                onChange={(nextChecked: boolean) => handleItemStatusChange(routeItem, nextChecked)}
                            >
                                {routeItem.isPicked ? 'Picked' : 'Not picked'}
                            </CheckBox>
                        </View>
                    );
                })}
                <Button onPress={() => {
                    setSelectedLocationIndex(locationIndex);
                    setShowInventoryModal(true);
                }}>
                    Add Items
                </Button>
            </View>
        ));
    };

    return (
        <ScrollView>
            {renderItemsByLocation()}
            <Modal
                visible={showInventoryModal}
                transparent={true}
                animationType="slide"
                onRequestClose={() => setShowInventoryModal(false)}
            >
                <TouchableWithoutFeedback onPress={() => setShowInventoryModal(false)}>
                    <View style={styles.modalOverlay}>
                        <TouchableWithoutFeedback>
                            <Layout style={styles.modalContainer}>
                                <InventoryDetailsCard
                                    selectedItems={[]}
                                    onChangeSelectedItems={handleAddItems}
                                    vendorProfile={null}
                                    jobSource="vendor"
                                    allowEdit={true}
                                />
                                <Button onPress={() => setShowInventoryModal(false)}>Close</Button>
                            </Layout>
                        </TouchableWithoutFeedback>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>
        </ScrollView>
    );
};

