import React from 'react';
import { Button } from '@ui-kitten/components';

interface ExportButtonProps {
  onPress: () => void;
}

export const ExportButton = ({ onPress }: ExportButtonProps) => (
  <Button appearance='outline' onPress={onPress}>
    Export To Csv
  </Button>
);