//@ts-ignore
import React, { useState, useEffect, useRef, TouchableOpacity, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from "@ui-kitten/components";
//@ts-ignore
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, LatLngBounds, TransitLayer, DirectionsService, DirectionsRenderer, OverlayView, TrafficLayer, MarkerClusterer, Polygon } from '@react-google-maps/api';
import * as UpdateController from '../../functions/update.controller';
import * as UserController from '../../functions/user.controller';
import * as StorageController from "../../functions/storageController"
import * as CompanyController from "../../functions/company.controller"
import * as MessageController from "../../functions/message.controller"
import * as GoogleController from "../../functions/google.controller"
import * as JobsController from '../../functions/job.controller'
import * as MembershipController from '../../functions/membership.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _ from 'lodash'
import { darkMapStyles, lightMapStyles } from './mapStyles';

import Toast from 'react-native-toast-message';
// import { AddressSearch } from './searchAddress.component';
import { DriverInfoWindow } from './driverInfoWindow.component';
import DistanceTool from './distanceTool.component';
import { VendorProfile } from '../../models/VendorProfile.model';
import { CompanyContract, ServiceArea } from '../../models/Company.model';
import { Job, JOB_STATUS, JobAddress, JobLocation, JobTowingDetails, TowingLeg } from '../../models/Job.model';
import { Member } from '../../models/Member.model';
import { DeviceDetails } from '../../models/DeviceDetails.model';
import { JobClusterComponent } from './map-components/JobCluster.component';
import { RenderDriverMarkers } from './map-components/DriverMarkers.component';
import { RenderJobIcon } from './map-components/JobIcon.component';
import { RoadsideJobInfo } from './RoadsideJobInfo.component';
import { TowingJobInfo } from './TowingJobInfo.component';
import { RenderServiceAreaPolygons } from './map-components/ServiceAreaPolygon.component';
import { RenderToggles } from './map-components/mapToggles.component';
import { RenderAddressSearch } from './map-components/MapAddressSearch.component';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';
const mapLibs = ["places", "visualization"]
const containerStyle = {
    width: '100%',
    height: '100%'
};

interface IDriverJobLocation {
    driver_location: {
        timestamp: number,
        mocked: boolean,
        coords: {
            altitude: number,
            heading: number,
            altitudeAccuracy: number,
            latitude: number,
            speed: number,
            longitude: number,
            accuracy: number
        },
        device_details: DeviceDetails
    },

    job: Job,
    update: string
}

interface IServiceNetworkMapProps {
    vendorProfiles: VendorProfile[]
    filteredVendorProfiles: VendorProfile[];
    selectedVendorProfile: VendorProfile | null;
    contracts: CompanyContract[];
    selectedContract: CompanyContract | null;
    onSelectedVendorProfile: (vendorProfile: VendorProfile) => void;
    jobs: Job[];
}


/**
 * Shows a map of providers and their service areas
 * Selecting a provider icon can show the provider's info and a create job button
 * Also shows realtime driver locations that are assigned to this client
 */

export default function ServiceNetworkMap({
    vendorProfiles,
    filteredVendorProfiles,
    selectedVendorProfile,
    contracts,
    selectedContract,
    onSelectedVendorProfile,
    jobs
}: IServiceNetworkMapProps) {

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// JOBS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [selectedJobMarker, setSelectedJobMarker] = useState<Job | null>(null);
    const [showJobInfoWindow, setShowJobInfoWindow] = useState(false);
    const jobsRef = useRef<Job[]>(jobs)
    const memoizedJobs = useMemo(() => jobs, [jobs])
    const selectedJobMarkerRef = useRef<Job | null>(selectedJobMarker)
    const selectedJobRef = useRef<Job | null>(selectedJob)

    const onSelectJob = (job: Job) => {
        setSelectedJob(job)
        selectedJobRef.current = job
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// DRIVERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [selectedDriver, setSelectedDriver] = useState<Member | null>(null);
    const [selectedDriverMarker, setSelectedDriverMarker] = useState<Member | null>(null);
    const [showDriverInfoWindow, setShowDriverInfoWindow] = useState(false);
    const [drivers, setDrivers] = useState<Member[]>([])
    const driversRef = useRef<Member[]>(drivers)
    const selectedDriverMarkerRef = useRef<Member | null>(selectedDriverMarker)
    const assignedDriversIdListRef = useRef<string[]>([])
    const onRouteDriversIdListRef = useRef<string[]>([])

    const memoizedSelectedDriverMarker = useMemo(() => selectedDriver, [selectedDriver])

    const memoizedDrivers = useMemo(() => {
        console.log("🚀============== ~ file: serviceNetworkMap.component.tsx:163 ~ memoizedDrivers ~ drivers🚀==============", drivers)
        return drivers
        if (jobs.length == 0) return []
        const driverIds = jobs.map((j) => j?.member_id)
        if (driverIds.length == 0) return []
        const d = driverIds.map((id) => {
            return drivers.find((d) => d?._id == id)
        }).filter((driver, index, self) =>
            driver && index === self.findIndex((d) => d?._id === driver._id)
        )
        return d as Member[]
    }, [drivers, jobs])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// VENDORS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [selectedVendor, setSelectedVendor] = useState<VendorProfile | null>(null);
    const [showVendorInfoWindow, setShowVendorInfoWindow] = useState(false);



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// SERVICE AREAS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [serviceAreaPolygons, setServiceAreaPolygons] = React.useState([] as ServiceArea[]);
    const [showServiceAreaPolygons, setShowServiceAreaPolygons] = React.useState(true);

    const [selectedServiceArea, setSelectedServiceArea] = React.useState<ServiceArea | null>(null);
    const [showServiceAreaInfoWindow, setShowServiceAreaInfoWindow] = React.useState(false);

    const [showSelectedVendorProfilePolygon, setShowSelectedVendorProfilePolygon] = React.useState(false);
    const [selectedVendorProfilePolygon, setSelectedVendorProfilePolygon] = React.useState([] as any);

    const [serviceAreaPolygonColours, setServiceAreaPolygonColours] = React.useState([] as any);
    const [serviceAreaFillColor, setServiceAreaFillColor] = React.useState("rgba(250,190,200,0.35)")

    const onClickServiceAreaPolygon = (vendorProfile: VendorProfile | null) => {
        if (vendorProfile == null) return
        onSelectedVendorProfile(vendorProfile)
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// EVENTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [eventsRegistered, setEventsRegistered] = useState(false);
    const eventTokensRef = React.useRef([] as any);
    const driverLocationUpdateEventRef = React.useRef(null);
    const jobDriverLocationUpdateEventRef = React.useRef(null);


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// MAP
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [distanceDirections, setDistanceDirections] = useState(null);
    const [map, setMap] = useState(null);
    const [showDarkMap, setShowDarkMap] = useState(true);
    const [center, setCenter] = React.useState({ lat: -27.4705, lng: 153.0260 })
    const mapRef = useRef(null);

    const [showTowingPolyline, setShowTowingPolyline] = useState(false);
    const [towingPolylines, setTowingPolylines] = useState([] as any[]);
    const [showTrafficFlow, setShowTrafficFlow] = useState(false);

    const [showSearchMarker, setShowSearchMarker] = useState(false);
    const [searchMarkerPosition, setSearchMarkerPosition] = useState(null as JobAddress | null);
    const [showSearchMarkerInfoWindow, setShowSearchMarkerInfoWindow] = useState(false);


    const onLoad = React.useCallback(function callback(map: any) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])
    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// USE EFFECTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    useEffect(() => {
        setCenter(StorageController.getCurrentCompany()?.settings?.location?.coords || { lat: -27.4705, lng: 153.0260 })
    }, []);

    useEffect(() => {
        setTimeout(() => {
            initLoadDrivers()
        }, 1000)
    }, [])

    useEffect(() => {
        jobsRef.current = jobs
    }, [jobs])

    useEffect(() => {
        driversRef.current = drivers
    }, [drivers])

    useEffect(() => {
        if (selectedVendorProfile) {
            setSelectedVendor(selectedVendorProfile)
        }
    }, [selectedVendorProfile])


    useEffect(() => {
        // Draw polygons for each vendor profile
        let colours = [] as any
        const polygons = filteredVendorProfiles.map((vendorProfile) => {
            let polygon = null
            let colour = serviceAreaFillColor
            // if (vendorProfile.linkedVendorAccountId) {
            //     polygon = vendorProfile.settings?.service_areas[0]?.coords;
            //     colour = vendorProfile.settings?.service_areas[0]?.color;
            // } else {
            //     polygon = vendorProfile.service_areas[0]?.coords;
            //     colour = vendorProfile.service_areas[0]?.color;
            // }
            polygon = vendorProfile.service_areas[0]?.coords;
            colour = vendorProfile.service_areas[0]?.color;
            colours.push(colour)
            if (polygon == null) return null;
            return polygon.map((point: any) => {
                const lat = parseFloat(Number(point.lat).toFixed(3));
                const lng = parseFloat(Number(point.lng).toFixed(3));
                return { lat, lng };
            });
        });
        // remove nulls
        const filteredPolygons = polygons.filter((polygon) => polygon != null)
        setServiceAreaPolygonColours(colours)
        setServiceAreaPolygons(filteredPolygons);
        setShowServiceAreaPolygons(true);
        // console.log("🚀 ~ file: serviceNetworkMap.component.js:131 ~ useEffect ~ polygons:", polygons)
    }, [filteredVendorProfiles]);

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// EVENTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */



    const handleCompanyChangeEvent = async (action: IAction) => {
        // close windows and stuff or something
    }

    const handleUpdatedMapDirectionsEvent = async (action: IAction) => {
        setDistanceDirections(action.data)
    }

    const handleDriverJobLocationUpdateEvent = (action: IAction) => {
        try {
            const data = action.data
            updateDriverLocation(data)
        } catch (e) {
            console.log("Error getting drivers", e)
        }
    };

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent,
        onUpdatedMapDirections: handleUpdatedMapDirectionsEvent,
        onUpdatedDriverJobLocation: handleDriverJobLocationUpdateEvent
    })



    // get all assigned drivers to jobs
    const initLoadDrivers = async () => {
        const jobs = jobsRef.current
        // make promise.all to get all drivers
        const promises = jobs.map(async (job) => {
            if (job.member_id) {
                return await MembershipController.getMemberById(job.member_id)
            }
        })
        const d = await Promise.all(promises) as Member[]
        setDrivers(d)
    }



    const updateDriverLocation = useCallback((updateData: IDriverJobLocation) => {

        if (!updateData || !updateData.job) return
        const job = jobsRef.current.find((j) => j._id == updateData.job._id)
        if (job == null) {
            return
        }
        let driver = new Member({
            _id: updateData.job?.member_id,
            name: "Driver",
            details: {
                location: updateData.driver_location
            },
            online: true
        })

        let drivers = [...driversRef.current]
        drivers = drivers.filter((d) => !!d)
        // remove any duplicate drivers
        drivers = drivers.filter((d, index, self) =>
            index === self.findIndex((t) => (
                t._id === d._id
            ))
        )
        const index = drivers.findIndex((d) => d._id == driver._id)
        if (index == -1) {
            drivers.push(driver)
        }
        else {
            drivers[index] = driver
            if (selectedDriverMarkerRef.current?._id == driver._id) {
                setSelectedDriver(driver)
                selectedDriverMarkerRef.current = driver
            }
        }
        setDrivers(drivers)
    }, [drivers, jobs])

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// MARKERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onJobMarkerClick = (job: Job) => {
        setSelectedJob(job);
        setSelectedJobMarker(job);
        selectedJobMarkerRef.current = job;  // Make sure to update the ref
        setShowJobInfoWindow(true);
    }

    const onClickTowingMapMarker = (towing_details: JobTowingDetails) => {
        try {
            const polylines = towing_details.towing_legs.map((towingLeg: TowingLeg) => {
                return GoogleController.decodePolyline(towingLeg.directions.polyline)
            })
            setShowTowingPolyline(true)
            setTowingPolylines(polylines)
        } catch (e) {
        }
    }

    const onDriverMarkerClick = (driver: Member) => {
        setSelectedDriver(driver)
        setSelectedDriverMarker(driver)
        setShowDriverInfoWindow(true)
    }

    const onVendorMarkerClick = (vendor: VendorProfile) => {
        setSelectedVendor(vendor)
        setShowVendorInfoWindow(true)
    }

    const RenderMemoizedAssignedJobClusterComponent = useMemo(() => {
        return (
            <JobClusterComponent
                status={JOB_STATUS.ASSIGNED}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])


    const RenderMemoizedUnassignedJobClusterComponent = useMemo(() => {
        return (
            <JobClusterComponent
                status={JOB_STATUS.UNASSIGNED}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])


    const RenderMemoizedPendingJobClusterComponent = useMemo(() => {
        return (
            <JobClusterComponent
                status={JOB_STATUS.PENDING}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])



    const RenderMemoizedCompleteJobClusterComponent = useMemo(() => {
        return (
            <JobClusterComponent
                status={JOB_STATUS.COMPLETE}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])


    const RenderMemoizedCancelledJobClusterComponent = useMemo(() => {
        return (
            <JobClusterComponent
                status={JOB_STATUS.CANCELLED}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])


    const RenderMemoizedRequestJobClusterComponent = useMemo(() => {
        return (
            <JobClusterComponent
                status={JOB_STATUS.REQUEST}
                jobs={memoizedJobs}
                onClick={onJobMarkerClick}
                onClickTowingMapMarker={onClickTowingMapMarker}
                renderJobIcon={RenderJobIcon}
            />
        )
    }, [memoizedJobs])



    const RenderMemoizedDriverMarkers = useMemo(() => {
        return (
            <RenderDriverMarkers
                drivers={memoizedDrivers || []}
                onDriverMarkerClick={onDriverMarkerClick}
                renderDriverIcon={RenderDriverMarkers}
                assignedDriverIds={assignedDriversIdListRef.current}
                onRouteDriverIds={onRouteDriversIdListRef.current}
            />
        )
    }, [memoizedJobs, memoizedDrivers])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */



    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs  // Required for heatmap
    })

    return isLoaded ? (<>
        <RenderToggles
            onSetServiceAreasShown={setShowServiceAreaPolygons}
            setShowDarkMap={setShowDarkMap}
            setShowTrafficFlow={setShowTrafficFlow}
            showDarkMap={showDarkMap}
            showServiceAreaPolygons={showServiceAreaPolygons}
            showTrafficFlow={showTrafficFlow}
        />
        <View
            style={{
                justifyContent: "space-between",
                position: "absolute",
                bottom: 0,
                left: 0,
                zIndex: 1000,
                flexDirection: 'row',
            }}
        >
            <RenderAddressSearch
                setShowSearchMarker={setShowSearchMarker}
                setCenter={setCenter}
                setSearchMarkerPosition={setSearchMarkerPosition}
            />
        </View>
        <GoogleMap
            ref={mapRef}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            // mapTypeId={"26158c831fac3bb4"}  // this will make the map dark
            options={{
                styles: showDarkMap ? darkMapStyles : lightMapStyles,
                disableDefaultUI: true,
                zoomControl: false
            }}
        >
            {RenderMemoizedAssignedJobClusterComponent}
            {RenderMemoizedUnassignedJobClusterComponent}
            {RenderMemoizedPendingJobClusterComponent}
            {RenderMemoizedCompleteJobClusterComponent}
            {RenderMemoizedCancelledJobClusterComponent}
            {RenderMemoizedRequestJobClusterComponent}
            {RenderMemoizedDriverMarkers}
            {showJobInfoWindow && selectedJobMarkerRef.current?.details?.location &&
                <InfoWindow
                    //@ts-ignore
                    visible={showJobInfoWindow}
                    position={
                        {
                            lat: selectedJobMarkerRef.current?.details?.location?.lat,
                            lng: selectedJobMarkerRef.current?.details?.location?.lon // for some reason locations come through as lon
                        } as google.maps.LatLngLiteral}
                    style={{ display: showJobInfoWindow ? "flex" : "none", justifyContent: 'center', alignItems: 'center' }}
                    onCloseClick={() => setShowJobInfoWindow(false)}
                >
                    {selectedJobMarkerRef.current?.details?.towing_details ?
                        <TowingJobInfo
                            job={selectedJobMarkerRef.current}
                            setShowJobInfoWindow={setShowJobInfoWindow}
                            setShowTowingPolyline={setShowTowingPolyline}
                            setTowingPolylines={setTowingPolylines}
                            fn_selectJob={onSelectJob}
                        />
                        :
                        <RoadsideJobInfo
                            job={selectedJobMarkerRef.current ? selectedJobMarkerRef.current : new Job({})}
                            setShowJobInfoWindow={setShowJobInfoWindow}
                            setShowTowingPolyline={setShowTowingPolyline}
                            setTowingPolylines={setTowingPolylines}
                            fn_selectJob={onSelectJob}
                        />
                    }

                </InfoWindow>
            }
            {showDriverInfoWindow &&
                <DriverInfoWindow
                    driver={memoizedSelectedDriverMarker}
                    onClose={() => { setShowDriverInfoWindow(false) }}
                    selectDriverChat={() => { }}
                    enableChat={false}
                />
            }
            {showServiceAreaPolygons && serviceAreaPolygons.length > 0 &&
                serviceAreaPolygons.map((polygon: any, index: number) => {
                    if (polygon == null) return null;
                    return (
                        <React.Fragment key={index}>
                            <Polygon
                                // key={index}
                                paths={polygon}
                                options={{
                                    fillColor: serviceAreaPolygonColours[index],
                                    fillOpacity: 0.4,
                                    strokeColor: serviceAreaPolygonColours[index],
                                    strokeOpacity: 1,
                                    strokeWeight: 2,
                                }}
                                onClick={(event: any) => {
                                    // create alert to ask to create job
                                    onClickServiceAreaPolygon(filteredVendorProfiles[index])
                                }}
                            >
                            </Polygon>
                            <OverlayView
                                position={polygon[0]}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={(width, height) => ({
                                    x: -(width / 2),
                                    y: -(height / 2),
                                })}
                            >
                                <Layout
                                    style={{
                                        // backgroundColor: "white",
                                        borderRadius: 5,
                                        padding: 5,
                                        border: "1px solid black",
                                        borderColor: serviceAreaPolygonColours[index],
                                        boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                                    }}
                                >
                                    <Text category="label">{filteredVendorProfiles[index]?.name}</Text>
                                </Layout>
                            </OverlayView>
                        </React.Fragment>
                    )
                })
            }
            {showSelectedVendorProfilePolygon && selectedVendorProfilePolygon.length > 0 &&
                <>
                    <Polygon
                        paths={selectedVendorProfilePolygon}
                        options={{
                            fillColor: serviceAreaFillColor,
                            fillOpacity: 0.4,
                            strokeColor: serviceAreaFillColor,
                            strokeOpacity: 1,
                            strokeWeight: 2,
                        }}
                        onClick={(event) => {
                            // create alert to ask to create job
                            onClickServiceAreaPolygon(selectedVendorProfile)
                        }}
                    />
                    <OverlayView
                        position={selectedVendorProfilePolygon[0]}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={(width, height) => ({
                            x: -(width / 2),
                            y: -(height / 2),
                        })}
                    >
                        <Layout
                            style={{
                                // backgroundColor: "white",
                                flexDirection: 'column',
                                borderRadius: 5,
                                padding: 5,
                                border: "1px solid black",
                                borderColor: serviceAreaFillColor,
                                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
                            }}
                        >
                            <Card
                                status="success"
                                header={() => <Text category="h6">{selectedVendorProfile?.name}</Text>}
                            >
                                <Text category="label">{selectedVendorProfile?.details}</Text>
                            </Card>
                        </Layout>
                    </OverlayView>
                </>
            }
            {showSearchMarker && searchMarkerPosition &&
                <Marker
                    position={searchMarkerPosition.location}
                    onClick={() => {
                        setShowSearchMarkerInfoWindow(true)
                    }}
                />
            }
            {showSearchMarkerInfoWindow &&
                <InfoWindow
                    position={searchMarkerPosition?.location}
                    onCloseClick={() => {
                        setShowSearchMarkerInfoWindow(false)
                    }}
                >
                    <Layout style={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        padding: "5px",
                        backgroundColor: "#121721",
                    }}>
                        <Text category='label'>Address:</Text>
                        <Text>
                            {searchMarkerPosition?.description}
                        </Text>
                        <Button
                            appearance="ghost"
                            onPress={() => {
                                setShowSearchMarker(false)
                                setShowSearchMarkerInfoWindow(false)
                            }}
                        >Dismiss</Button>
                    </Layout>
                </InfoWindow>
            }
            {showTrafficFlow && <TrafficLayer
                onLoad={onLoad}
                weight={2}
                options={{
                    //@ts-ignore
                    showTrafficFlow: true,
                    showTrafficIncidents: true,
                    autoRefresh: true
                }}
            />}
        </GoogleMap >
    </>
    ) : null

}

