import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
//@ts-ignore
import { StyleSheet, TouchableOpacity, View, Platform, ScrollView, Dimensions, useWindowDimensions } from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    useTheme,
    Select,
    SelectItem,
    Toggle,
    OverflowMenu,
    MenuItem,
    Modal,
    //@ts-ignore
    Spinner,
    Radio,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import AssignJobList from '../assignJobList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import { ServicesContainer } from '../services/servicesContainer.component';
import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
import * as HoldingController from '../../../functions/holding.controller'
//@ts-ignore
import debounce from 'lodash.debounce';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../ErrorBoundary.component';
import { JobDetailImages } from '../jobDetailsImages.component';
import { event, set } from 'react-native-reanimated';
import NotificationContext from '../../../context/notifications.context';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import AddressSearch from './addressSearch.component';
import { JobDetailMap } from './mapSelectLocation.component';

// import { AddressDetailsCard } from './addressDetailsCard.component';
import { ClientDetailsCard } from './ClientDetailsCard.component';
import { CustomerDetailsCard } from './customerDetailsCard.component';
// import { ItemsDetailsCard } from './itemsDetailsCard.component';
import { ImageDetailsCard } from './imageDetailsCard.component';
import { OptionsDetailsCard } from './optionsDetailsCard.component';
import { OtherDetailsCard } from './otherDetailsCard.component';
import { PaymentDetailsCard } from './paymentDetailsCard.component';
import { ReportDetailsCard } from './reportDetailsCard.component';
import { ServiceDetailsCard } from './serviceDetailsCard.component';
import { TimeDetailsCard } from './timeDetailsCard.component';
import { VehicleDetailsCard } from './vehicleDetailsCard.component';
import { LogDetailsCard } from './logDetailsCard.component';
import { VendorDetailsCard } from './vendorDetailsCard.component';
import { InvoicedTotalCard } from './jobInvoicedTotalDetailsCard.component';
import { TransferJobComponent } from '../transfer/transferJob.component';

import { LocationDetailsCard } from './locationDetailsCard.component';
import { TowingDetailsCard } from '../newJobComponents/towingDetailsCard.component';
import { TowingLocationDetailsCard } from '../newJobComponents/towingLocationDetailsCard.component';
import { InventoryDetailsCard } from './inventory/inventoryDetailsCard.component';
import { Job, JobCustomerDetails, JobTowingDetails, Service, TowingLocation, JobVehicleDetails, JobLocation, JobAddress, JOB_STATUS, JobNote } from '../../../models/Job.model';
import { LineItem } from '../../../models/LineItem.model';
import { Member } from '../../../models/Member.model';
import { Client } from '../../../models/Client.model';
import { JobNotesList } from './jobNotes.component';
import { max } from 'lodash';

var config = require('../../../config/config.js');
const pdfApi = config.pdf_api

interface JobDocumentsProps {
    job: Job;
    onSelectDocument: (document: any) => void;
}

export const JobDocuments = ({
    job,
    onSelectDocument
}: JobDocumentsProps) => {

    const [pdfUris, setPdfUris] = useState<any[]>([]);

    const generatePdfUrl = (pdfUrl: string) => {
        return `${pdfApi}/${pdfUrl}`
    }

    useEffect(() => {
        load();
    }, []);

    const openPdfNewTab = async (pdfItem: IPdfItem) => {
        const signedUrl = await JobsController.getSignedUrlForPdf(pdfItem.key);
        window.open(signedUrl, '_blank');
    }

    interface IPdfItem {
        uri: string;
        name: string;
        key: string;
    }

    const load = async () => {
        try {
            const pdfs = await JobsController.getPdfsByJobId(job._id);
            const uris = pdfs?.Contents?.map((pdf: any) => {
                let nameStrArr = pdf.Key.split("/");
                let name = nameStrArr[nameStrArr.length - 1];
                return {
                    uri: generatePdfUrl(pdf.Key as string),
                    name: name,
                    key: pdf.Key
                } as IPdfItem
            })
            setPdfUris(uris);
        } catch (error) {
            console.log("🚀============== ~ file: jobDocuments.component.tsx:127 ~ load ~ error🚀==============", error)

        }
    }

    if (!pdfUris.length) return null;

    return (
        <Card
            disabled
            status='primary'
            style={{ marginBottom: 10 }}
            header={(props: any) => (
                <Text {...props}>Job Documents</Text>
            )}
        >
            <View style={{ margin: -15, maxHeight: 200 }}>
                {/* List of pdf uris, on click opens uri */}
                <List
                    data={pdfUris}
                    renderItem={({ item }: { item: IPdfItem }) => (
                        <ListItem
                            title={item.name}
                            onPress={() => openPdfNewTab(item)}
                            accessoryLeft={(props: any) => (
                                <Icon {...props} name='file-text-outline' />
                            )}
                        />
                    )}
                />
            </View>
        </Card>
    )
}
