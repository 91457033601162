import React, { useState } from 'react';
import { View, ScrollView } from 'react-native';
import { Text, Button, Icon, useTheme, Layout, OverflowMenu, MenuItem } from '@ui-kitten/components';
import { RouteLocation, ROUTE_LOCATION_TYPE } from '../../models/Route.model';

interface SelectedLocationsProps {
    locations: RouteLocation[];
    onRemoveLocation: (index: number) => void;
    onMoveLocation: (fromIndex: number, toIndex: number) => void;
    onMoveToAnotherRoute: (locationIndex: number, targetRouteIndex: number) => void;
    availableRoutes: { index: number; name: string }[];
}

export const SelectedLocations: React.FC<SelectedLocationsProps> = ({
    locations,
    onRemoveLocation,
    onMoveLocation,
    onMoveToAnotherRoute,
    availableRoutes,
}) => {
    const theme = useTheme();
    const [menuVisible, setMenuVisible] = useState<number | null>(null);

    const getLocationIcon = (location: RouteLocation) => {
        if (location.is_towing_location) {
            let icon, text, color;
            switch (location.route_location_type) {
                case ROUTE_LOCATION_TYPE.PICKUP:
                    icon = 'upload-outline';
                    text = 'Pickup';
                    color = 'success';
                    break;
                case ROUTE_LOCATION_TYPE.DELIVERY:
                    icon = 'download-outline';
                    text = 'Dropoff';
                    color = 'warning';
                    break;
                default:
                    icon = 'pin-outline';
                    text = 'Location';
                    color = 'primary';
            }
            return (
                <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: theme[`color-${color}-100`], borderRadius: 20, paddingHorizontal: 8, paddingVertical: 4 }}>
                    <Icon name={icon} fill={theme[`color-${color}-500`]} style={{ width: 16, height: 16, marginRight: 4 }} />
                    <Text style={{ fontSize: 12, fontWeight: 'bold' }}>{text}</Text>
                </View>
            );
        }else{
            return (
                <View style={{ flexDirection: 'row', alignItems: 'center', backgroundColor: theme[`color-info-100`], borderRadius: 20, paddingHorizontal: 8, paddingVertical: 4 }}>
                    <Icon name='pin-outline' fill={theme[`color-info-500`]} style={{ width: 16, height: 16, marginRight: 4 }} />
                    <Text style={{ fontSize: 12, fontWeight: 'bold' }}>Location</Text>
                </View>
            );
        }
        return null;
    };

    const renderMoveToRouteButton = (index: number) => (
        <Button
            size="small"
            appearance="ghost"
            onPress={() => setMenuVisible(index)}
            accessoryLeft={(props) => <Icon {...props} name='more-vertical-outline' />}
        />
    );

    return (
        <Layout style={{ flexDirection: 'column', flex: 1, gap: 3 }}>
            <ScrollView>
                {locations.map((location, index) => (
                    <Layout key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Button
                                size="small"
                                appearance="ghost"
                                disabled={index === 0}
                                onPress={() => onMoveLocation(index, index - 1)}
                                accessoryLeft={(props) => <Icon {...props} name='arrow-upward-outline' />}
                            />
                            <Button
                                size="small"
                                appearance="ghost"
                                disabled={index === locations.length - 1}
                                onPress={() => onMoveLocation(index, index + 1)}
                                accessoryLeft={(props) => <Icon {...props} name='arrow-downward-outline' />}
                            />
                            {getLocationIcon(location)}
                        </View>
                        <View style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start', marginLeft: 10 }}>
                            <Text style={{ textAlign: 'left', fontSize: 12, fontWeight:'bold'   }}> {location.address.suburb}</Text>
                            <Text style={{ textAlign: 'left', fontSize: 10 }}>{location.name}</Text>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Button size="small" status="danger" onPress={() => onRemoveLocation(index)} accessoryLeft={<Icon name='trash-outline' />} />
                            <OverflowMenu
                                anchor={() => renderMoveToRouteButton(index)}
                                visible={menuVisible === index}
                                onBackdropPress={() => setMenuVisible(null)}
                                onSelect={(indexPath) => {
                                    onMoveToAnotherRoute(index, indexPath.row);
                                    setMenuVisible(null);
                                }}
                            >
                                {availableRoutes.map((route, routeIndex) => (
                                    <MenuItem key={routeIndex} title={`Move to ${route.name}`} />
                                ))}
                            </OverflowMenu>
                        </View>
                    </Layout>
                ))}
            </ScrollView>
        </Layout>
    );
};