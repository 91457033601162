import { useCallback, useEffect } from 'react';
//@ts-ignore
import { Dispatcher } from 'flux';
import { eventAppStateChange, eventDriverLocationChanged, eventJobDriverLocationChanged } from '../functions/update.controller';
import { Job } from '../models/Job.model';
import Toast from 'react-native-toast-message';
export interface IAction {
    type?: string;
    data?: any;
    job?: Job;
}

interface AppStateChangeCallbacks {
    onSetAppState?: (action: IAction) => void;
    onNavigated?: (action: IAction) => void;
    onUpdatedSelectedCompany?: (action: IAction) => void;
    onSetUser?: (action: IAction) => void;
    onSetLoggedIn?: (action: IAction) => void;
    onSetLoggedOut?: (action: IAction) => void;
    onSetSelectedMembership?: (action: IAction) => void;
    onSetSelectedCompany?: (action: IAction) => void;
    onSetSelectedJob?: (action: IAction) => void;
    onSetSelectedMemberToEdit?: (action: IAction) => void;
    onSetMemberList?: (action: IAction) => void;
    onSetSelectedClientToEdit?: (action: IAction) => void;
    onSetMessageChannels?: (action: IAction) => void;
    onSetSelectedChannel?: (action: IAction) => void;
    onSetUnreadChannels?: (action: IAction) => void;
    onSetSelectedChat?: (action: IAction) => void;
    onSetJobsList?: (action: IAction) => void;
    onUpdatedSelectedJob?: (action: IAction) => void;
    onUpdatedSelectedJobs?: (action: IAction) => void;
    onUpdatedSelectedDrivers?: (action: IAction) => void;
    onUpdatedSelectedDriver?: (action: IAction) => void;
    onUpdatedSelectedChannels?: (action: IAction) => void;
    onUpdatedSelectedChannel?: (action: IAction) => void;
    onUpdatedChannels?: (action: IAction) => void;
    onUpdatedUnreadMessages?: (action: IAction) => void;
    onMqttOnMemberChatRead?: (action: IAction) => void;
    onMemberChatIsTyping?: (action: IAction) => void;
    onMemberChatUpdate?: (action: IAction) => void;
    onMessageUpdate?: (action: IAction) => void;
    onCompanyUpdate?: (action: IAction) => void;
    onMembersUpdate?: (action: IAction) => void;
    onDriversUpdate?: (action: IAction) => void;
    onHoldingsUpdate?: (action: IAction) => void;
    onUserUpdate?: (action: IAction) => void;
    onJobsUpdate?: (action: IAction) => void;
    onRoutesUpdate?: (action: IAction) => void;
    onClientsUpdate?: (action: IAction) => void;
    onUpdatedOtherCompany?: (action: IAction) => void;
    onMqttEvent?: (action: IAction) => void;
    onUpdatedCompanyTheme?: (action: IAction) => void;
    onLoggedOut?: (action: IAction) => void;
    onLoggedIn?: (action: IAction) => void;
    onUpdatedAllData?: (action: IAction) => void;
    onVendorProfilesUpdate?: (action: IAction) => void;
    onDefault?: (action: IAction) => void;
    onUpdatedSelectedMembership?: (action: IAction) => void;
    onCreatedJob?: (action: IAction) => void;
    onUpdatedJobs?: (action: IAction) => void;
    onUpdatedCompanies?: (action: IAction) => void;
    onUpdatedClients?: (action: IAction) => void;
    onUpdatedMessageChannels?: (action: IAction) => void;
    onUpdatedDriverLocation?: (action: IAction) => void;
    onUpdatedMapDirections?: (action: IAction) => void;
    onUpdatedMapShowServiceArea?: (action: IAction) => void;
    onUpdatedDriverJobLocation?: (action: IAction) => void;
    onUpdatedOrders?: (action: IAction) => void;
}

export function useAppStateChange(callbacks: AppStateChangeCallbacks = {}) {


    const handleAppStateChange = useCallback((action: IAction) => {
        callbacks.onDefault?.(action);
        switch (action.type) {
            case STATE_ACTIONS.SET_APP_STATE:
                callbacks.onSetAppState?.(action);
                break;
            case STATE_ACTIONS.NAVIGATED:
                callbacks.onNavigated?.(action);
                break;
            case STATE_ACTIONS.SET_USER:
                callbacks.onSetUser?.(action);
                break;
            case STATE_ACTIONS.SET_LOGGED_IN:
                callbacks.onSetLoggedIn?.(action);
                break;
            case STATE_ACTIONS.SET_LOGGED_OUT:
                callbacks.onSetLoggedOut?.(action);
                break;
            case STATE_ACTIONS.SET_SELECTED_MEMBERSHIP:
                callbacks.onSetSelectedMembership?.(action);
                break;
            case STATE_ACTIONS.SET_SELECTED_COMPANY:
                callbacks.onSetSelectedCompany?.(action);
                break;
            case STATE_ACTIONS.SET_SELECTED_JOB:
                callbacks.onSetSelectedJob?.(action);
                break;
            case STATE_ACTIONS.SET_SELECTED_MEMBER_TO_EDIT:
                callbacks.onSetSelectedMemberToEdit?.(action);
                break;
            case STATE_ACTIONS.SET_MEMBER_LIST:
                callbacks.onSetMemberList?.(action);
                break;
            case STATE_ACTIONS.SET_SELECTED_CLIENT_TO_EDIT:
                callbacks.onSetSelectedClientToEdit?.(action);
                break;
            case STATE_ACTIONS.SET_MESSAGE_CHANNELS:
                callbacks.onSetMessageChannels?.(action);
                break;
            case STATE_ACTIONS.SET_SELECTED_CHANNEL:
                callbacks.onSetSelectedChannel?.(action);
                break;
            case STATE_ACTIONS.SET_UNREAD_CHANNELS:
                callbacks.onSetUnreadChannels?.(action);
                break;
            case STATE_ACTIONS.SET_SELECTED_CHAT:
                callbacks.onSetSelectedChat?.(action);
                break;
            case STATE_ACTIONS.SET_JOBS_LIST:
                callbacks.onSetJobsList?.(action);
                break;
            case STATE_ACTIONS.UPDATED_SELECTED_COMPANY:
                callbacks.onUpdatedSelectedCompany?.(action);
                break;
            case STATE_ACTIONS.UPDATED_SELECTED_MEMBERSHIP:
                callbacks.onUpdatedSelectedMembership?.(action);
                break;
            case STATE_ACTIONS.UPDATED_SELECTED_JOB:
                callbacks.onUpdatedSelectedJob?.(action);
                break;
            case STATE_ACTIONS.UPDATED_SELECTED_JOBS:
                callbacks.onUpdatedSelectedJobs?.(action);
                break;
            case STATE_ACTIONS.UPDATED_SELECTED_DRIVER:
                callbacks.onUpdatedSelectedDriver?.(action);
                break;
            case STATE_ACTIONS.UPDATED_SELECTED_DRIVERS:
                callbacks.onUpdatedSelectedDrivers?.(action);
                break;
            case STATE_ACTIONS.UPDATED_SELECTED_CHANNEL:
                callbacks.onUpdatedSelectedChannel?.(action);
                break;
            case STATE_ACTIONS.UPDATED_CHANNELS:
                callbacks.onUpdatedChannels?.(action);
                break;
            case STATE_ACTIONS.UPDATED_UNREAD_MESSAGES:
                callbacks.onUpdatedUnreadMessages?.(action);
                break;
            case STATE_ACTIONS.MQTT_ON_MEMBER_CHAT_READ:
                callbacks.onMqttOnMemberChatRead?.(action);
                break;
            case STATE_ACTIONS.MEMBER_CHAT_IS_TYPING:
                callbacks.onMemberChatIsTyping?.(action);
                break;
            case STATE_ACTIONS.MEMBER_CHAT_UPDATE:
                callbacks.onMemberChatUpdate?.(action);
                break;
            case STATE_ACTIONS.MESSAGE_UPDATE:
                callbacks.onMessageUpdate?.(action);
                break;
            case STATE_ACTIONS.COMPANY_UPDATE:
                callbacks.onCompanyUpdate?.(action);
                break;
            case STATE_ACTIONS.MEMBERS_UPDATE:
                callbacks.onMembersUpdate?.(action);
                break;
            case STATE_ACTIONS.DRIVERS_UPDATE:
                callbacks.onDriversUpdate?.(action);
                break;
            case STATE_ACTIONS.HOLDINGS_UPDATE:
                callbacks.onHoldingsUpdate?.(action);
                break;
            case STATE_ACTIONS.USER_UPDATE:
                callbacks.onUserUpdate?.(action);
                break;
            case STATE_ACTIONS.JOBS_UPDATE:
                callbacks.onJobsUpdate?.(action);
                break;
            case STATE_ACTIONS.ROUTES_UPDATE:
                callbacks.onRoutesUpdate?.(action);
                break;
            case STATE_ACTIONS.CLIENTS_UPDATE:
                callbacks.onClientsUpdate?.(action);
                break;
            case STATE_ACTIONS.UPDATED_OTHER_COMPANY:
                callbacks.onUpdatedOtherCompany?.(action);
                break;
            case STATE_ACTIONS.MQTT_EVENT:
                callbacks.onMqttEvent?.(action);
                break;
            case STATE_ACTIONS.UPDATED_COMPANY_THEME:
                callbacks.onUpdatedCompanyTheme?.(action);
                break;
            case STATE_ACTIONS.LOGGED_OUT:
                callbacks.onLoggedOut?.(action);
                break;
            case STATE_ACTIONS.LOGGED_IN:
                callbacks.onLoggedIn?.(action);
                break;
            case STATE_ACTIONS.UPDATED_ALL_DATA:
                callbacks.onUpdatedAllData?.(action);
                break;
            case STATE_ACTIONS.VENDOR_PROFILES_UPDATE:
                callbacks.onVendorProfilesUpdate?.(action);
                break;
            case STATE_ACTIONS.CREATED_JOB:
                callbacks.onCreatedJob?.(action);
                break;
            case STATE_ACTIONS.UPDATED_JOBS:
                callbacks.onUpdatedJobs?.(action);
                break;
            case STATE_ACTIONS.UPDATED_COMPANIES:
                callbacks.onUpdatedCompanies?.(action);
                break;
            case STATE_ACTIONS.UPDATED_CLIENTS:
                callbacks.onUpdatedClients?.(action);
                break;
            case STATE_ACTIONS.UPDATED_MESSAGE_CHANNELS:
                callbacks.onUpdatedMessageChannels?.(action);
                break;
            case STATE_ACTIONS.UPDATED_DRIVER_LOCATION:
                callbacks.onUpdatedDriverLocation?.(action);
                break;
            case STATE_ACTIONS.UPDATED_MAP_DIRECTIONS:
                callbacks.onUpdatedMapDirections?.(action);
                break;
            case STATE_ACTIONS.UPDATED_MAP_SHOW_SERVICE_AREA:
                callbacks.onUpdatedMapShowServiceArea?.(action);
                break;
            case STATE_ACTIONS.UPDATED_DRIVER_JOB_LOCATION:
                // console.log("🚀============== ~ file: appStateChange.hook.ts:230 action🚀==============", action)
                callbacks.onUpdatedDriverJobLocation?.(action);

                break;
            case STATE_ACTIONS.UPDATED_ORDERS:
                Toast.show({
                    text1: "Orders updated",
                    type: "success"
                })
                callbacks.onUpdatedOrders?.(action);
                break;
            default:
                console.log('Unknown action type: ', action);
                break;
        }
    }, [callbacks]);

    useEffect(() => {
        const token = eventAppStateChange.register(handleAppStateChange);
        //IF BROKEN MAKE THESE DIFFERENT HANDLERS
        const driverToken = eventDriverLocationChanged.register(handleAppStateChange);
        const jobToken = eventJobDriverLocationChanged.register(handleAppStateChange);
        return () => {
            eventAppStateChange.unregister(token);
            eventDriverLocationChanged.unregister(driverToken);
            eventJobDriverLocationChanged.unregister(jobToken);
        };
    }, [handleAppStateChange]);

    const dispatchEventStateChange = (action: IAction) => {
        eventAppStateChange.dispatch(action);
    };

    return { dispatchEventStateChange };
}

export enum STATE_ACTIONS {
    SET_APP_STATE = 'SET_APP_STATE',
    SET_USER = 'SET_USER',
    SET_LOGGED_IN = 'SET_LOGGED_IN',
    SET_LOGGED_OUT = 'SET_LOGGED_OUT',
    SET_SELECTED_MEMBERSHIP = 'SET_SELECTED_MEMBERSHIP',
    SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY',
    SET_SELECTED_JOB = 'SET_SELECTED_JOB',
    SET_SELECTED_MEMBER_TO_EDIT = 'SET_SELECTED_MEMBER_TO_EDIT',
    SET_MEMBER_LIST = 'SET_MEMBER_LIST',
    SET_SELECTED_CLIENT_TO_EDIT = 'SET_SELECTED_CLIENT_TO_EDIT',
    SET_MESSAGE_CHANNELS = 'SET_MESSAGE_CHANNELS',
    SET_SELECTED_CHANNEL = 'SET_SELECTED_CHANNEL',
    SET_UNREAD_CHANNELS = 'SET_UNREAD_CHANNELS',
    SET_SELECTED_CHAT = 'SET_SELECTED_CHAT',
    SET_JOBS_LIST = 'SET_JOBS_LIST',

    UPDATED_SELECTED_COMPANY = 'UPDATED_SELECTED_COMPANY',
    UPDATED_SELECTED_MEMBERSHIP = 'UPDATED_SELECTED_MEMBERSHIP',
    UPDATED_SELECTED_JOB = 'UPDATED_SELECTED_JOB',
    UPDATED_SELECTED_JOBS = 'UPDATED_SELECTED_JOBS',
    UPDATED_SELECTED_DRIVER = 'UPDATED_SELECTED_DRIVER',
    UPDATED_SELECTED_DRIVERS = 'UPDATED_SELECTED_DRIVERS',
    UPDATED_SELECTED_CHANNEL = 'UPDATED_SELECTED_CHANNEL',
    UPDATED_CHANNELS = 'UPDATED_CHANNELS',


    CREATED_JOB = 'CREATED_JOB',
    UPDATED_JOBS = 'UPDATED_JOBS',
    UPDATED_COMPANIES = 'UPDATED_COMPANIES',
    UPDATED_CLIENTS = 'UPDATED_CLIENTS',
    UPDATED_MESSAGE_CHANNELS = 'UPDATED_MESSAGE_CHANNELS',
    UPDATED_DRIVER_LOCATION = 'UPDATED_DRIVER_LOCATION',
    UPDATED_MAP_DIRECTIONS = 'UPDATED_MAP_DIRECTIONS',
    UPDATED_MAP_SHOW_SERVICE_AREA = 'UPDATED_MAP_SHOW_SERVICE_AREA',
    UPDATED_DRIVER_JOB_LOCATION = 'UPDATED_DRIVER_JOB_LOCATION',
    UPDATED_ORDERS = 'UPDATED_ORDERS',

    UPDATED_UNREAD_MESSAGES = 'UPDATED_UNREAD_MESSAGES',

    MQTT_ON_MEMBER_CHAT_READ = 'MQTT_ON_MEMBER_CHAT_READ',
    MEMBER_CHAT_IS_TYPING = 'MEMBER_CHAT_IS_TYPING',
    MEMBER_CHAT_UPDATE = 'MEMBER_CHAT_UPDATE',

    MESSAGE_UPDATE = 'MESSAGE_UPDATE',
    COMPANY_UPDATE = 'COMPANY_UPDATE',
    MEMBERS_UPDATE = 'MEMBERS_UPDATE',
    DRIVERS_UPDATE = 'DRIVERS_UPDATE',
    HOLDINGS_UPDATE = 'HOLDINGS_UPDATE',
    USER_UPDATE = 'USER_UPDATE',
    JOBS_UPDATE = 'JOBS_UPDATE',
    CLIENTS_UPDATE = 'CLIENTS_UPDATE',
    ROUTES_UPDATE = 'ROUTES_UPDATE',
    DEFAULT = 'DEFAULT',

    UPDATED_OTHER_COMPANY = 'UPDATED_OTHER_COMPANY',

    MQTT_EVENT = 'MQTT_EVENT',
    NAVIGATED = 'NAVIGATED',
    UPDATED_COMPANY_THEME = 'UPDATED_COMPANY_THEME',

    LOGGED_OUT = 'LOGGED_OUT',
    LOGGED_IN = 'LOGGED_IN',

    UPDATED_ALL_DATA = 'UPDATED_ALL_DATA',
    VENDOR_PROFILES_UPDATE = 'VENDOR_PROFILES_UPDATE',
}