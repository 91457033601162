import React, { useState, useEffect } from 'react';
import { View, ScrollView } from 'react-native';
import { Button, Icon, Text } from '@ui-kitten/components';
import * as RouteController from '../../functions/route.controller';
import * as StorageController from '../../functions/storageController';
import { Job, JobLocation } from '../../models/Job.model';
import { Route, RouteLocation } from '../../models/Route.model';
import { Client } from '../../models/Client.model';

interface EditableLocationsProps {
    route: Route;
    onLocationMove: (index: number, direction: 'up' | 'down') => void;
    onLocationEdit: (index: number) => void;
    onLocationRemove: (index: number) => void;
    onLocationsUpdate: (updatedLocations: RouteLocation[]) => void;
    styles: any;
}

export const EditableLocations: React.FC<EditableLocationsProps> = ({
    route,
    onLocationMove,
    onLocationEdit,
    onLocationRemove,
    onLocationsUpdate,
    styles,
}) => {
    const [jobsInfo, setJobsInfo] = useState<{[key: string]: Job}>({});
    const company = StorageController.getCurrentCompany();

    const handleLocationRemove = (index: number) => {
        console.log("🚀============== ~ file: EditableLocations.tsx:31 ~ handleLocationRemove ~ index🚀==============", index)
        onLocationRemove(index);
    }

    const getClientName = (client_id: string) => {
        const client = company.clients.find((client: Client) => client._id === client_id);
        return client ? client.name : 'N/A';
    }

    useEffect(() => {
        const loadJobsInfo = async () => {
            try {
                const jobs = await RouteController.getAllJobsByRouteId(route._id);
                route.updateLocations(jobs)
                setJobsInfo(jobs);
                if (route.details.locations.length > 0) {
                    onLocationsUpdate(route.details.locations);
                }
            } catch (error) {
                console.error(`Error loading jobs for route ${route._id}:`, error);
            }
        };

        loadJobsInfo();
    }, []);

    return (
        <ScrollView>
            {route.details.locations.map((location, index) => {
                const jobInfo = new Job(jobsInfo[location.job_id]);
                return (
                    <View key={index} style={styles.locationItem}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View style={{ flexDirection: 'row', marginRight: 8 }}>
                                <Button
                                    size="tiny"
                                    appearance="ghost"
                                    status="info"
                                    accessoryLeft={(props) => <Icon {...props} name='arrow-upward-outline' />}
                                    onPress={() => onLocationMove(index, 'up')}
                                    disabled={index === 0}
                                />
                                <Button
                                    size="tiny"
                                    appearance="ghost"
                                    status="warning"
                                    accessoryLeft={(props) => <Icon {...props} name='arrow-downward-outline' />}
                                    onPress={() => onLocationMove(index, 'down')}
                                    disabled={index === route.details.locations.length - 1}
                                />
                            </View>
                            <View>
                                <Text>{`${index + 1}. ${location.name}`}</Text>
                                {jobInfo ? (
                                    <>
                                        <Text category="c1">{`Client: ${getClientName(jobInfo.client_id || '') || 'N/A'}`}</Text>
                                        <Text category="c1">{`Service: ${jobInfo?.getServicesNames() || 'N/A'}`}</Text>
                                        <Text category="c1"><Text style={{fontWeight:'bold'}}>{location.address.suburb || location.address.city}</Text> {location.address.addressToString()}</Text>
                                    </>
                                ) : (
                                    <Text category="c1">Loading job info...</Text>
                                )}
                                {location.is_towing_location && (
                                    <Text style={{ marginLeft: 8, fontStyle: 'italic' }}>
                                        (Towing: {location.route_location_type})
                                    </Text>
                                )}
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Button
                                size="tiny"
                                appearance="ghost"
                                status="info"
                                accessoryLeft={(props) => <Icon {...props} name='edit-2-outline' />}
                                onPress={() => onLocationEdit(index)}
                            />
                            <Button
                                size="tiny"
                                appearance="ghost"
                                status="danger"
                                accessoryLeft={(props) => <Icon {...props} name='trash-2-outline' />}
                                onPress={() => handleLocationRemove(index)}
                            />
                        </View>
                    </View>
                );
            })}
        </ScrollView>
    );
};