import React, { useState, useEffect, useReducer, useCallback, useRef, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, Text as RNText, Platform, useWindowDimensions, View, AppState, TouchableOpacity, ScrollView } from "react-native";
//@ts-ignore
import { Button, Icon, Layout, Card, Input, Modal, Text, Spinner, RangeDatepicker, CheckBox, List, OverflowMenu, MenuItem, useTheme } from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import debounce from 'lodash.debounce';
import JobsList from './jobsList.component';
import { JobDetailContainer } from './details/jobDetailContainer.component'
// import { JobDetailContainer } from './details/_JobDetailsContainer.component'
import { HoldingList } from './holdingList.component';
import { CaseList } from './casesList.component';
import DriversList from '../Drivers/DriversList.component';
import AssignJobModal from './modals/assignJobModal.component';
import Map from '../map/map.component';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as ServiceController from '../../functions/service.controller'
import * as HoldingController from '../../functions/holding.controller'
import * as MqttService from '../../services/mqtt.service'
import * as CaseController from '../../functions/case.controller'
import * as RouteController from '../../functions/route.controller'
import * as CompanyController from '../../functions/company.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import _log from '../../utils/log'
import CreateJobModal from './modals/createJobModal.component';
import { CreateRouteContainer } from '../routes/createRouteContainer.component';
import { CreateHoldingContainer } from './holding/createHolding.component';
import { HoldingDetailsModalContainer } from './holding/holdingDetailModal';
import { HoldingDetailsContainer } from './holding/holdingDetailsContainer.component';
import { Job, Service, JOB_STATUS } from '../../models/Job.model';
import { Company } from '../../models/Company.model';
import { Member } from '../../models/Member.model';
import { Case } from '../../models/Case.model';
import { HOLDING_STATUS, Holding } from '../../models/Holding.model';
import { Client } from '../../models/Client.model';
import { Route, ROUTE_STATUS } from '../../models/Route.model';
import { JobReportModal, FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { CreateJobFromHoldingModal } from './holding/createJobFromHoldingModal';
import { CalendarContainer } from '../schedule/calendarContainer.components';
import { CreateCaseContainer } from '../cases/createCaseContainer.component';
import { CaseDetailsContainer } from '../cases/caseDetailsContainer.component';
import Toast from 'react-native-toast-message';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';
import { RoutesList } from '../routes/routesList.component';
import { JobStatusFilterButtons } from './components/JobStatusFilterButtons';
import { FilterPanel } from './FilterPanel';
import { SearchJobsHoldingsCases, SEARCH_TYPE } from './SearchJobsHoldingsCases';
import { CounterBadge } from './components/CounterBadge'
import { CaseStatusFilterButtons, CASE_STATUS_FILTER } from './CaseStatusFilterButtons'
import { HoldingStatusFilterButtons } from './HoldingStatusFilterButtons'
import { ViewModeButtons } from './ViewModeButtons';
import { LIST_TABS, ListPanelTabs } from './ListPanelTabs';
import DMModal from '../common/Modal';
import { VendorProfile } from '../../models/VendorProfile.model';
import { OrderList, ORDERS_TABS } from '../PartsNetwork/OrderList';
import { Order, OrderSearchParams, OrderStatus } from '../../models/Order.model';
import * as OrderController from '../../functions/order.controller';
import { OrderDetail } from '../PartsNetwork/OrderDetail';
import { CreateOrderComponent } from '../PartsNetwork/CreateOrderComponent';
import { isEqual } from 'lodash';
/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// ENUMS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

enum JOB_STATUS_FILTER {
    // ALL = "all",
    INCOMPLETE = "incomplete", //all
    REQUEST = "request",
    QUOTE = "quote",
    UNASSIGNED = "unassigned",
    ASSIGNED = "assigned",
    PENDING = "pending",
    COMPLETE = "complete",
    CANCELLED = "cancelled"
}


enum VIEW_MODE {
    LIST = "LIST",
    MAP = "MAP",
    CALENDAR = "CALENDAR"
}





const JobsScreen = ({ navigation, route }: any) => {
    const theme = useTheme()
    // #region STATE VARIABLES
    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    ////////         STATE VARIABLES                        //////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    // Jobs
    const [fetchedJobs, setFetchedJobs] = useState([] as Job[]) // fetched from server
    const [filteredJobs, setFilteredJobs] = useState([] as Job[]) // filter operations on this
    const [completeJobs, setCompleteJobs] = useState([] as Job[])
    const [cancelledJobs, setCancelledJobs] = useState([] as Job[])
    const [selectedJob, setSelectedJob] = useState(null as Job | null)

    const [jobSearchResults, setJobSearchResults] = useState([] as Job[])
    const [holdingSearchResults, setHoldingSearchResults] = useState([] as Holding[])
    const [caseSearchResults, setCaseSearchResults] = useState([] as Case[])
    const [resetSearch, setResetSearch] = useState(0 as any)

    const [jobDetailsModalOpen, setJobDetailsModalOpen] = useState(false)

    // Map
    const [zoomToJob, setZoomToJob] = useState(null as Job | null)

    // Filters
    const [jobFilterApplied, setJobFilterApplied] = useState(JOB_STATUS_FILTER.INCOMPLETE)
    const [holdingFilterApplied, setHoldingFilterApplied] = useState(HOLDING_STATUS.HELD)
    const [caseFilterApplied, setCaseFilterApplied] = useState(CASE_STATUS_FILTER.ONGOING)
    const [showFilters, setShowFilters] = useState(false)

    const [filteredClients, setFilteredClients] = useState([] as string[])

    // Clients
    const [clients, setClients] = useState(StorageController.getCurrentCompany()?.clients as Client[] || [] as Client[])

    // Company
    const [selectedCompany, setSelectedCompany] = useState(StorageController.getCurrentCompany())

    //Holding
    const [enableHoldings, setEnableHoldings] = useState(false)
    const [showCreateHolding, setShowCreateHolding] = useState(false)
    const [showReleasedList, setShowReleasedList] = useState(false)
    const [selectedHolding, setSelectedHolding] = useState(null as Holding | null)
    const [holdingDetailsOpen, setHoldingDetailsOpen] = useState(false)
    const [showReleaseHoldingOptions, setShowReleaseHoldingOptions] = useState(false)
    const [holdingscount, setHoldingsCount] = useState(0)

    //Drivers
    const [drivers, setDrivers] = useState([] as Member[])
    const [filteredDrivers, setFilteredDrivers] = useState([] as string[])

    //Routes
    const [enableRoutes, setEnableRoutes] = useState(true)
    const [routes, setRoutes] = useState([] as Route[])
    const [selectedRoute, setSelectedRoute] = useState(null as Route | null)

    const onSetRoutes = (routes: Route[]) => {
        routesRef.current = routes
        setRoutes(routes)
    }


    // Cases
    const [enableCases, setEnableCases] = useState(true)

    // Orders
    const [enableOrders, setEnableOrders] = useState(false)
    const [orders, setOrders] = useState([] as Order[])

    //LOADING
    const [loadingText, setLoadingText] = useState('')
    const [renderSpinner, setRenderSpinner] = useState(false)

    //TABS
    const [selectedListPanelTab, setSelectedListPanelTab] = useState(LIST_TABS.JOBS)
    const [selectedTab, setSelectedTab] = useState(LIST_TABS.JOBS)



    //Views
    const [viewMode, setViewMode] = useState(VIEW_MODE.MAP)

    const [vendorProfiles, setVendorProfiles] = useState([] as VendorProfile[])


    // Get screenDimensions
    const windowWidth = useWindowDimensions().width
    const windowHeight = useWindowDimensions().height

    useEffect(() => {
        // if is a mobile device set the view mode to list
        if (windowWidth < 800) {
            setViewMode(VIEW_MODE.LIST)
        }
    }, [windowWidth])

    const IS_SMALL_SCREEN = useWindowDimensions().width < 800

    const [showCompactJobList, setShowCompactJobList] = useState(false)




    // #region REFS AND MEMOIZED VALUES
    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// REFS AND MEMOIZED VALUES                        /////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    //JOBS
    const selectedJobRef = useRef(null as Job | null)
    const fetchedJobsRef = useRef([] as Job[]) // jobs fetched from server - no need to render
    const memoizedFilteredJobs = useMemo(() => filteredJobs, [filteredJobs]) //render this

    const completeJobsRef = useRef([] as Job[]) // used to store complete jobs
    const memoizedCompleteJobs = useMemo(() => completeJobs, [completeJobs]) //render this

    const cancelledJobsRef = useRef([] as Job[]) // used to store cancelled jobs
    const memoizedCancelledJobs = useMemo(() => cancelledJobs, [cancelledJobs]) //render this

    const isShowingSearchResultsRef = useRef(false)

    //FILTERS
    const filterAppliedRef = useRef(JOB_STATUS_FILTER.INCOMPLETE)

    //COMPANY
    const selectedCompanyRef = useRef(StorageController.getCurrentCompany())

    //MEMBERS
    const membersRef = useRef([] as Member[])

    //DRIVERS
    const driversRef = useRef([] as Member[])
    const memoizedDrivers = useMemo(() => drivers, [drivers])

    //CLIENTS
    const clientsRef = useRef([] as Client[])
    const memoizedClients = useMemo(() => clients, [clients])

    //SERVICES
    const servicesRef = useRef([] as Service[])

    //HOLDINGS
    const holdingsRef = useRef([] as Holding[])

    //CASES
    const casesRef = useRef([] as Case[])

    //ROUTES
    const routesRef = useRef(routes as Route[])

    //ORDERS
    const ordersRef = useRef([] as Order[])

    //LOADING
    const loadingTextRef = useRef(loadingText)

    //EVENTS
    const eventTokensRef = React.useRef([] as any[]);


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// SET STATE FUNCTIONS                            //////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onSelectRoute = (route: Route | null) => {
        // if (route?._id == selectedRoute?._id) {
        //     setSelectedRoute(null)
        // } else {
        // }
        setSelectedRoute(route)
    }

    const listPanelTabRef = useRef(LIST_TABS.JOBS)
    const onSetListPanelTab = (tab: LIST_TABS) => {
        listPanelTabRef.current = tab
        setSelectedListPanelTab(tab)
        switch (tab) {
            case LIST_TABS.JOBS:
                break;
            case LIST_TABS.SCHEDULED:

                break;
            case LIST_TABS.DRIVERS:

                break;
            case LIST_TABS.HOLDINGS:

                break;
            case LIST_TABS.CASES:

                break;
            case LIST_TABS.ROUTES:

                break;
            case LIST_TABS.ORDERS:

                fetchOrders()
                break;
        }
        // setListPanelTab(tab)
        // setSelectedTab(tab)
    }

    /*
    //////////////////////////////////////////////////////////////////
    // #region JOBS
    //////////////////////////////////////////////////////////////////
    */

    const getAlertTime = () => {
        const scheduleSettings = StorageController.getCurrentCompany()?.settings?.scheduleSettings;
        return (scheduleSettings && scheduleSettings.alertEnabled && !isNaN(scheduleSettings.alertTime))
            ? scheduleSettings.alertTime
            : 60;
    };

    // checks if the scheduled job is within the alert time
    const isJobScheduled = (job: Job, isScheduled: boolean, alertTime: number) => {
        // if (job.is_scheduled) return isScheduled;

        let now = new Date().getTime();
        let jobStartTime = new Date(job.start_time).getTime();
        now += (alertTime * 60 * 1000);

        return isScheduled ? jobStartTime > now : jobStartTime < now;
    };

    const getListJobsFromFiltersAndScheduled = (memoizedFilteredJobs: Job[], isScheduled: boolean) => {
        if (!memoizedFilteredJobs) return [];
        const alertTime = getAlertTime();

        const jobs = memoizedFilteredJobs.filter(job => isJobScheduled(job, isScheduled, alertTime));

        if (showFilters && filteredClients.length > 0) {
            return jobs.filter(job => job.client_id != null && filteredClients.includes(job.client_id));
        }
        if (showFilters && filteredDrivers.length > 0) {
            return jobs.filter(job => job.member_id != null && filteredDrivers.includes(job.member_id));
        }

        return jobs;
    }

    const memoizedUnscheduledJobs = useMemo(() => getListJobsFromFiltersAndScheduled(memoizedFilteredJobs, false), [memoizedFilteredJobs, showFilters, filteredClients, filteredDrivers]);
    const memoizedScheduledJobs = useMemo(() => getListJobsFromFiltersAndScheduled(memoizedFilteredJobs, true), [memoizedFilteredJobs, showFilters, filteredClients, filteredDrivers]);

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region CHECK JOB INTERVAL
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    // set an interval to check the state of the jobs, if they differ from the storage controller jobs, update the state
    // const jobCheckInterval = useRef(null as NodeJS.Timeout | null)
    // useEffect(() => {
    //     if (!jobCheckInterval.current) {
    //         jobCheckInterval.current = setInterval(() => {
    //             const currentJobs = JSON.stringify(fetchedJobsRef.current)
    //             const storedJobs = JSON.stringify(StorageController.getCurrentCompany()?.jobs)
    //             if (!isEqual(currentJobs, storedJobs)) {
    //                 console.log('jobs differ')
    //                 console.log(currentJobs.length, storedJobs?.length)
    //                 // onSetFilteredJobs(storedJobs)
    //                 onSetFetchedJobs(StorageController.getCurrentCompany()?.jobs)
    //             } else {
    //                 console.log('jobs are the same')
    //             }
    //         }, 10000)
    //     }
    //     return () => {
    //         if (jobCheckInterval.current) {
    //             clearInterval(jobCheckInterval.current)
    //         }
    //     }
    // }, [selectedCompany, fetchedJobs])

    /**
 * Set fetched Jobs
 */
    const onSetFetchedJobs = (jobs: Job[]) => {
        fetchedJobsRef.current = jobs
        setFetchedJobs(jobs)
    }

    /**
     * Set the jobs to display on the list and map
     * @param jobs 
     */
    const onSetFilteredJobs = (jobs: Job[]) => {
        setFilteredJobs(jobs) //sets the memoized value
    }


    /**
     * Set the filter applied
     */
    const onSetFilterApplied = (filter: JOB_STATUS_FILTER) => {
        filterAppliedRef.current = filter
        setJobFilterApplied(filter)
    }




    /**
     * Set selected Job
     */
    const onSetSelectedJob = async (job: Job) => {
        // const j = await JobsController.getJobById(job._id) as Job
        if (job.status == JOB_STATUS.COMPLETE ||
            job.status == JOB_STATUS.CANCELLED ||
            job.status == JOB_STATUS.TRANSFERRED_OUT
        ) {
            job = await JobsController.getJobById(job._id) as Job
        }
        selectedJobRef.current = job
        StorageController.appState.selectedJob = job
        // Dispatch the selected job to global state
        setSelectedJob(job)
        dispatchEventStateChange({
            type: STATE_ACTIONS.SET_SELECTED_JOB,
            data: job
        })
        openJobDetailsModal()
    }

    /*
    //////////////////////////////////////////////////////////////////
    // #region CLIENTS
    //////////////////////////////////////////////////////////////////
    */

    /**
     * set clients
     */
    const onSetClients = (clients: Client[]) => {
        clientsRef.current = clients
        setClients(clients)
    }


    /*
    //////////////////////////////////////////////////////////////////
    // #region DRIVERS
    //////////////////////////////////////////////////////////////////
    */

    /**
     * set drivers
     */
    const onSetDrivers = (drivers: Member[]) => {
        driversRef.current = drivers
        setDrivers(drivers)
    }


    /*
    //////////////////////////////////////////////////////////////////
    // #region COMPANY
    //////////////////////////////////////////////////////////////////
    */


    /**
     * Set Selected Company
     */
    const onSetSelectedCompany = (company: Company) => {
        selectedCompanyRef.current = company
        setSelectedCompany(company)
        onCompanyChange(company)
    }


    /*
    //////////////////////////////////////////////////////////////////
    // #region OTHER
    //////////////////////////////////////////////////////////////////
    */

    /**
     * se tthe loading screen text
     * @param text 
     */
    const onSetLoadingText = (text: string) => {
        loadingTextRef.current = text
        setLoadingText(text)
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region ROUTES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const [selectedRouteStatusFilter, setSelectedRouteStatusFilter] = useState(ROUTE_STATUS.PLANNED)


    const onRoutesTabSelected = async () => {
        const activeRoutes = await RouteController.getRoutesByCompanyIdAndStatus(selectedCompanyRef.current._id, "active")
        const plannedRoutes = await RouteController.getRoutesByCompanyIdAndStatus(selectedCompanyRef.current._id, "planned")
        const routes = [...activeRoutes, ...plannedRoutes]
        routesRef.current = routes
        onSetRoutes(routes)
    }


    const onUpdateRouteStatus = (route_id: string, status: ROUTE_STATUS) => {
        // updatee routes state
        const routes = routesRef.current.map((r) => {
            if (r._id == route_id) {
                r.status = status
            }
            return r
        })
        onSetRoutes(routes)
    }

    const onRoutesEndReached = async () => {
        const offset = routesRef.current.length
        const limit = 10
        if (selectedRouteStatusFilter == ROUTE_STATUS.COMPLETED) {
            const completedRoutes = await RouteController.getRoutesByCompanyIdAndStatus(selectedCompanyRef.current._id, selectedRouteStatusFilter, limit, offset)
            onSetRoutes([...routesRef.current, ...completedRoutes])
        } else if (selectedRouteStatusFilter == ROUTE_STATUS.CANCELLED) {
            const cancelledRoutes = await RouteController.getRoutesByCompanyIdAndStatus(selectedCompanyRef.current._id, selectedRouteStatusFilter, limit, offset)
            onSetRoutes([...routesRef.current, ...cancelledRoutes])
        }
    }

    const onSetSelectedRouteStatusFilter = async (status: ROUTE_STATUS) => {
        setSelectedRouteStatusFilter(status)
        let limit = 1000
        let offset = 0
        if (status == ROUTE_STATUS.COMPLETED || status == ROUTE_STATUS.CANCELLED) {
            limit = 10
            offset = 0
        }
        // fetch routes based on the status
        const routes = await RouteController.getRoutesByCompanyIdAndStatus(selectedCompanyRef.current._id, status, limit, offset)
        onSetRoutes(routes)
    }

    const RenderMemoizedRoutesFilters = useMemo(() => {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>
                <Button
                    size='tiny'
                    appearance={selectedRouteStatusFilter == ROUTE_STATUS.PLANNED ? 'filled' : 'outline'}
                    onPress={() => onSetSelectedRouteStatusFilter(ROUTE_STATUS.PLANNED)}>
                    Planned
                </Button>
                <Button
                    size='tiny'
                    appearance={selectedRouteStatusFilter == ROUTE_STATUS.IN_PROGRESS ? 'filled' : 'outline'}
                    onPress={() => onSetSelectedRouteStatusFilter(ROUTE_STATUS.IN_PROGRESS)}>
                    In Progress
                </Button>
                <Button
                    size='tiny'
                    appearance={selectedRouteStatusFilter == ROUTE_STATUS.PENDING ? 'filled' : 'outline'}
                    onPress={() => onSetSelectedRouteStatusFilter(ROUTE_STATUS.PENDING)}>
                    Pending
                </Button>
                <Button
                    size='tiny'
                    appearance={selectedRouteStatusFilter == ROUTE_STATUS.COMPLETED ? 'filled' : 'outline'}
                    onPress={() => onSetSelectedRouteStatusFilter(ROUTE_STATUS.COMPLETED)}>
                    Completed
                </Button>
                <Button
                    size='tiny'
                    appearance={selectedRouteStatusFilter == ROUTE_STATUS.CANCELLED ? 'filled' : 'outline'}
                    onPress={() => onSetSelectedRouteStatusFilter(ROUTE_STATUS.CANCELLED)}>
                    Cancelled
                </Button>
            </View>
        )
    }, [selectedRouteStatusFilter, selectedListPanelTab])




    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region ORDERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onSetOrders = (orders: Order[]) => {
        ordersRef.current = orders
        setOrders(orders)
    }

    const [activeOrdersTab, setActiveOrdersTab] = useState(ORDERS_TABS.VENDOR_ORDERS)
    const [selectedOrderId, setSelectedOrderId] = useState<string | undefined>(undefined)
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null)
    const selectedOrderRef = useRef(selectedOrder)
    const activeOrdersTabRef = useRef(activeOrdersTab)

    useEffect(() => {
        const isVendor = selectedCompanyRef.current?.settings?.parts_network?.is_vendor
        if (isVendor) {
            onSetActiveOrdersTab(ORDERS_TABS.VENDOR_ORDERS)
        } else {
            onSetActiveOrdersTab(ORDERS_TABS.CLIENT_ORDERS)
        }
    }, [selectedCompany])

    const onSelectOrder = (order: Order) => {
        setSelectedOrder(order)
        setSelectedOrderId(order._id)
        selectedOrderRef.current = order
        setShowSelectedOrderModal(true)
    }

    const onSetActiveOrdersTab = (tab: ORDERS_TABS) => {
        activeOrdersTabRef.current = tab
        setActiveOrdersTab(tab)
        setTimeout(() => {
            fetchOrders()
        }, 0)
    }


    const handleOrderUpdatedEvent = (action: IAction) => {
        console.log("🚀============== ~ file: jobs.component.tsx:603 ~ handleOrderUpdatedEvent ~ action🚀==============", action)
        const order = action.data?.order
        if (order) {
            const orders = ordersRef.current.map((o) => o._id == order._id ? order : o)
            onSetOrders(orders)
        }
    }

    const fetchOrders = useCallback(async () => {
        console.log("🚀============== ~ file: jobs.component.tsx:594 ~ fetchOrders ~ selectedTab🚀==============", selectedTab, activeOrdersTab)
        if (listPanelTabRef.current !== LIST_TABS.ORDERS) return
        if (!selectedCompanyRef.current || !selectedCompanyRef.current._id) return;
        try {
            let searchStatuses = [
                OrderStatus.REQUEST,
                OrderStatus.PENDING,
                OrderStatus.PROCESSING,
                OrderStatus.READY,
                OrderStatus.SHIPPED,
                OrderStatus.DELIVERED,
                OrderStatus.AWAITING_PAYMENT
            ];


            let searchParams: OrderSearchParams = {
                status: searchStatuses,
                limit: 100,
                offset: 0
            };

            if (activeOrdersTabRef.current === ORDERS_TABS.VENDOR_ORDERS) {
                searchParams.vendor_company_id = selectedCompanyRef.current._id;
            } else {
                searchParams.client_company_id = selectedCompanyRef.current._id;
            }

            const fetchedOrders = await OrderController.searchOrders(searchParams);
            if (fetchedOrders) {
                onSetOrders(fetchedOrders);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }, [activeOrdersTab, ListPanelTabs])


    const RenderMemoizedOrdersFilters = useMemo(() => {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>
            </View>
        )
    }, [selectedRouteStatusFilter, selectedListPanelTab])



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region USE EFFECTS                                  //////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    // Is here because of the use of the route prop, loading 
    useEffect(() => {
        initLoad()
    }, [route])


    const selectedTabRef = useRef(selectedTab)
    const onSetSelectedTab = (tab: LIST_TABS) => {
        selectedTabRef.current = tab
        setSelectedTab(tab)
    }


    const onCompanyChange = useCallback((company: Company) => {
        // load members
        membersRef.current = company?.members || []
        // load drivers
        const drivers = company?.members?.filter((m) => m.is_driver) || []
        const clients = company?.clients || []
        onSetDrivers(drivers)
        // load clients
        onSetClients(clients)
        // load services
        servicesRef.current = company?.services || []
        // load jobs
        onSetFetchedJobs(company?.jobs || [])
        // set selected job
        setSelectedJob(null)
        // load holdings
        holdingsRef.current = company?.holdingItems || []
        // close holding item open
        setSelectedHolding(null)
        setShowCreateHolding(false)
        setHoldingDetailsOpen(false)
        updateHoldingsCount()
        // load cases
        casesRef.current = company?.cases || []
        // load vendors
        UpdateController.updateVendorProfiles().then((vendors) => {
            setVendorProfiles(vendors)
        })
        // set tab
        setSelectedTab(LIST_TABS.JOBS)
        onSetListPanelTab(LIST_TABS.JOBS)
        // clear search results
        clearJobSearchResults()
        // clear directions
        clearDirections()

        setFilteredClients([])
        setFilteredDrivers([])

        onSetRoutes([])

        // get company options
        const companySettings = company?.settings
        if (companySettings) {
            setEnableHoldings(companySettings?.job_options?.showTowingJobOptions || false)
            setEnableRoutes(companySettings?.job_options?.showTransportJobOptions || false)
            setEnableCases(companySettings?.company_roles?.showCases || false)
            setEnableOrders(companySettings?.parts_network?.is_enabled || false)
        }

        // hide or show createHolding button - this has to be enableHolding and this to be shown
        const selectedMember = StorageController.getAppState().selectedMembership
        if (selectedMember && !selectedMember.is_client) {
            setShowCreateHolding(true)
        } else {
            setShowCreateHolding(false)
        }


    }, [selectedCompany])

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region LOAD DATA FROM SERVER                         //////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const initLoad = useCallback(async () => {
        // setRenderSpinner(true)
        // const newData = await UpdateController.getAllCompanyDashboardData() //Updates jobs, members, services, clients, holdings, cases in storage
        const company = StorageController.getCurrentCompany()
        dispatchEventStateChange({ type: STATE_ACTIONS.UPDATED_SELECTED_COMPANY, data: company })
        dispatchEventStateChange({ type: STATE_ACTIONS.UPDATED_SELECTED_MEMBERSHIP, data: company.membership })
        if (!company) return
        setTimeout(() => {
            onSetSelectedCompany(company)
            // setRenderSpinner(false)
        }, 0)
    }, [route])




    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region LIST FUNCTIONS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    /**
 * Get complete jobs pagenated
 * @returns 
 */
    const getCompleteJobsPagenated = async () => {
        let company = StorageController.getCurrentCompany()
        let j = await JobsController.getJobsByCompanyAndStatus(company._id, JOB_STATUS.COMPLETE, 10, 0)
        completeJobsRef.current = j
        setCompleteJobs(j)
        onSetFilteredJobs(j)
        return j
    }

    /**
     * load additional complete jobs
     * @returns 
     */
    const loadMoreCompleteJobs = async () => {
        let company = StorageController.getCurrentCompany()
        let j = await JobsController.getJobsByCompanyAndStatus(company._id, JOB_STATUS.COMPLETE, 10, completeJobsRef.current.length)
        // console.log("LOADING MORE COMPLETE JOBS", j.length)
        completeJobsRef.current = [...completeJobsRef.current, ...j]
        setCompleteJobs(completeJobsRef.current)
        onSetFilteredJobs(completeJobsRef.current)
        return j
    }

    const transferredJobsRef = useRef([] as Job[]) // used to store transferred out jobs
    const combinedCancelledAndTransferredJobsRef = useRef([] as Job[]) // used to store transferred out jobs
    /**
     * get cancelled jobs pagenated
     * @returns 
     */
    const getCancelledJobsPagenated = async () => {
        let company = StorageController.getCurrentCompany()
        let j = await JobsController.getJobsByCompanyAndStatus(company._id, JOB_STATUS.CANCELLED, 10, 0)
        // also load transferred out jobs
        let transferredOut = await JobsController.getJobsByCompanyAndStatus(company._id, JOB_STATUS.TRANSFERRED_OUT, 10, 0)
        transferredJobsRef.current = transferredOut
        cancelledJobsRef.current = j

        const combined = [...cancelledJobsRef.current, ...transferredJobsRef.current]
        const sorted = combined.sort((a: any, b: any) => { return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() })

        combinedCancelledAndTransferredJobsRef.current = sorted
        setCancelledJobs(combinedCancelledAndTransferredJobsRef.current)
        onSetFilteredJobs(combinedCancelledAndTransferredJobsRef.current)
        // setCancelledJobs(j)
        // onSetFilteredJobs(j)
        return j
    }

    /**
     *  load more cancelled jobs
     * @returns 
     */

    const loadMoreCancelledJobs = async () => {
        let company = StorageController.getCurrentCompany()
        let j = await JobsController.getJobsByCompanyAndStatus(company._id, JOB_STATUS.CANCELLED, 10, cancelledJobsRef.current.length)
        // also load transferred out jobs
        let transferredOut = await JobsController.getJobsByCompanyAndStatus(company._id, JOB_STATUS.TRANSFERRED_OUT, 10, transferredJobsRef.current.length)
        transferredJobsRef.current = [...transferredJobsRef.current, ...transferredOut]
        cancelledJobsRef.current = [...cancelledJobsRef.current, ...j]
        const combined = [...cancelledJobsRef.current, ...transferredJobsRef.current]
        const sorted = combined.sort((a: any, b: any) => { return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime() })

        combinedCancelledAndTransferredJobsRef.current = sorted
        setCancelledJobs(combinedCancelledAndTransferredJobsRef.current)
        onSetFilteredJobs(combinedCancelledAndTransferredJobsRef.current)
        // setCancelledJobs(cancelledJobsRef.current)
        // onSetFilteredJobs(cancelledJobsRef.current)
        return j
    }


    /**
     * On end reached jobs list
     * @returns 
     */
    const onEndReached = async () => {
        if (filterAppliedRef.current == JOB_STATUS_FILTER.COMPLETE) {
            if (completeJobsRef.current.length == 0) return
            if (completeJobsRef.current.length % 10 != 0) return
            await loadMoreCompleteJobs()
        } else if (filterAppliedRef.current == JOB_STATUS_FILTER.CANCELLED) {
            if (cancelledJobsRef.current.length == 0) return
            if (cancelledJobsRef.current.length % 10 != 0) return
            await loadMoreCancelledJobs()
        } else {
            return null
        }
    }


    const vendorAcceptJob = (job_id: string, eta: number) => {
        closeJobDetailsModal()
        // return checkFilters()
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    // #region FILTERS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    /**
     * Apply filters
     */
    const applyJobFilters = () => {
        if (isShowingSearchResultsRef.current) {
            onSetFilteredJobs(jobSearchResults)
            return
        }
        switch (jobFilterApplied) {
            case JOB_STATUS_FILTER.INCOMPLETE:
                const incompleteJobs = fetchedJobsRef.current.filter((j) => j.status != "complete" && j.status != "cancelled" && j.status != "quote")
                const orderedJobs = incompleteJobs.sort((a, b) => {
                    if (a.status == JOB_STATUS.REQUEST && b.status != JOB_STATUS.REQUEST) return -1
                    if (a.status == JOB_STATUS.REQUEST && b.status == JOB_STATUS.REQUEST) return 0
                    if (a.status != JOB_STATUS.REQUEST && b.status == JOB_STATUS.REQUEST) return 1
                    if (a.status == JOB_STATUS.ASSIGNED && b.status == JOB_STATUS.UNASSIGNED) return 1
                    if (a.status == JOB_STATUS.UNASSIGNED && b.status == JOB_STATUS.ASSIGNED) return -1
                    if (a.status == JOB_STATUS.PENDING && b.status != JOB_STATUS.PENDING) return 1
                    if (a.status != JOB_STATUS.PENDING && b.status == JOB_STATUS.PENDING) return -1
                    return 0
                })
                onSetFilteredJobs(orderedJobs)
                break;
            case JOB_STATUS_FILTER.QUOTE:
                onSetFilteredJobs(fetchedJobsRef.current.filter((j) => j.status == JOB_STATUS.QUOTE))
                break;
            case JOB_STATUS_FILTER.REQUEST:
                onSetFilteredJobs(fetchedJobsRef.current.filter((j) => j.status == JOB_STATUS.REQUEST))
                break;
            case JOB_STATUS_FILTER.UNASSIGNED:
                onSetFilteredJobs(fetchedJobsRef.current.filter((j) => j.status == JOB_STATUS.UNASSIGNED))
                break;
            case JOB_STATUS_FILTER.ASSIGNED:
                onSetFilteredJobs(fetchedJobsRef.current.filter((j) => j.status == JOB_STATUS.ASSIGNED))
                break;
            case JOB_STATUS_FILTER.PENDING:
                onSetFilteredJobs(fetchedJobsRef.current.filter((j) => j.status == JOB_STATUS.PENDING))
                break;
            case JOB_STATUS_FILTER.COMPLETE:
                getCompleteJobsPagenated()
                break;
            case JOB_STATUS_FILTER.CANCELLED:
                getCancelledJobsPagenated()
                break;
            default:
                break;
        }
    }


    useMemo(() => {
        applyJobFilters()
    }, [fetchedJobs, jobFilterApplied, jobSearchResults])






    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
// #region EVENTS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedJobs: (action: IAction) => {
            handleJobUpdateEvent(action)
        },
        onUpdatedSelectedCompany: (action: IAction) => {
            handleCompanyChangeEvent(action)
        },
        onHoldingsUpdate: (action: IAction) => {
            handleHoldingUpdatedEvent(action)
        },
        onRoutesUpdate: (action: IAction) => {
            console.log("🚀============== ~ file: jobs.component.tsx:954 ~ JobsScreen ~ action🚀==============", action)
            handleRouteUpdatedEvent(action)
        },
        onUpdatedOrders: (action: IAction) => {
            handleOrderUpdatedEvent(action)
        }
    })

    /**
     * If the current company matches any of the incoming company ids fromt the job
     * @param job 
     * @param company 
     * @returns 
     */
    const jobMatchingCompany = (job: Job, company: Company) => {
        const matching_ids = [job.company_id, job.client_company_id, job.vendor_company_id]
        const currentCompany = company
        const isMatch = matching_ids.includes(currentCompany._id)
        return isMatch
    }

    const holdingMatchingCompany = (holding: Holding, company: Company) => {
        const matching_ids = [holding.company_id]
        const currentCompany = company
        const isMatch = matching_ids.includes(currentCompany._id)
        return isMatch
    }




    const handleJobUpdateEvent = useCallback((action: any) => {
        if (!action.data.job) return
        const job = new Job(action.data.job)
        let jobUpdatedInList = false
        // if current company id matches job.company_id, job.client_company_id or job.vendor_company_id update the jobs
        const isMatch = jobMatchingCompany(job, StorageController.getCurrentCompany())
        if (isMatch) {
            let _jobs = [...fetchedJobsRef.current]
            let index = _jobs.findIndex((j) => j._id == action.data.job._id)
            if (index > -1) {
                _jobs[index] = action.data.job
                fetchedJobsRef.current = _jobs
                jobUpdatedInList = true
            } else {
                try {
                    const newJob = new Job(action.data.job)
                    _jobs.unshift(newJob)
                    fetchedJobsRef.current = _jobs
                    jobUpdatedInList = true
                } catch (e) {
                }
            }
        }
        if (jobUpdatedInList) {
            onSetFetchedJobs([...fetchedJobsRef.current])
        }
    }, [fetchedJobsRef.current, filteredJobs, selectedCompany, onSetFetchedJobs]);


    const handleCompanyChangeEvent = (action: any) => {
        onSetSelectedCompany(new Company(action.data))
    };

    const handleHoldingUpdatedEvent = useCallback((action: any) => {
        const holding = new Holding(action.data)
        Toast.show({
            type: 'success',
            text1: `Holding ${holding.status} - ${holding.name}`,
            text2: 'The holding has been updated'
        });
        setTimeout(() => {

            updateHoldingsCount()
        }, 100)
    }, [selectedCompany, holdingsRef.current]);


    const handleRouteUpdatedEvent = useCallback((action: any) => {
        console.log("🚀============== ~ file: jobs.component.tsx:1033 ~ handleRouteUpdatedEvent ~ action🚀==============", action)
        const route = new Route(action.data)
        if (route.company_id == StorageController.getCurrentCompany()._id) {
            let _routes = [...routesRef.current]
            if (_routes.length == 0) return
            let index = _routes.findIndex((r) => r?._id == action?.data?.route?._id)
            if (index > -1) {
                _routes[index] = action.data.route
                routesRef.current = _routes
            }
            // filter routes by shown status
            const routes = routesRef.current.filter((r) => r.status == selectedRouteStatusFilter)
            console.log("🚀============== ~ file: jobs.component.tsx:1042 ~ handleRouteUpdatedEvent ~ routes🚀==============", routes)
            onSetRoutes(routes)
            Toast.show({
                type: 'success',
                text1: `Route ${route.status} - ${route.name}`,
                text2: 'The route has been updated'
            });
        }
    }, [selectedCompany, routesRef.current, onSetRoutes, selectedRouteStatusFilter]);


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// SEARCH
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const onSetJobSearchResults = (jobs: Job[], term: string) => {
        if (!term || term == "") {
            clearJobSearchResults()
            return
        }
        onSetFilterApplied(JOB_STATUS_FILTER.INCOMPLETE)
        isShowingSearchResultsRef.current = true
        setJobSearchResults(jobs)
    }

    const clearJobSearchResults = () => {
        isShowingSearchResultsRef.current = false
        setJobSearchResults([])
        // onSetFilterApplied(JOB_STATUS_FILTER.INCOMPLETE)
        setResetSearch(Math.random())
        // applyJobFilters()
    }



    const onSetHoldingSearchResults = (holdings: Holding[], term: string) => {
        if (!term || term == "") {
            clearHoldingSearchResults()
            return
        }
        setHoldingSearchResults(holdings)
    }

    const clearHoldingSearchResults = () => {
        setHoldingSearchResults([])
    }


    const [showNewJobModal, setShowNewJobModal] = useState(false)
    const selectedCaseIdRef = useRef(null as string | null)
    const showNewJobModalRef = useRef(false)
    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// Create Job
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const openNewJobModal = () => {
        setShowNewJobModal(true)
        showNewJobModalRef.current = true
    }

    const openNewJobModalWithCase = (case_id: string) => {
        setShowNewJobModal(true)
        showNewJobModalRef.current = true
        selectedCaseIdRef.current = case_id
    }

    const closeNewJobModal = () => {
        setShowNewJobModal(false)
        showNewJobModalRef.current = false
        selectedCaseIdRef.current = null
        // checkFilters()
    }


    //memoized createjobmodal component - Wont re-render on parnet state change
    const RenderMemoizedCreateJobModal = useMemo(() => {
        return (
            <CreateJobModal
                fn_onCreateJob={closeNewJobModal}
                fn_onClose={closeNewJobModal}
                case_id={selectedCaseIdRef.current as string}
            />
        )
    }, [showNewJobModal])


    const [showCreateRouteContainer, setShowCreateRouteContainer] = useState(false)

    const openCreateRouteContainer = () => {
        setShowCreateRouteContainer(true)
    }

    const closeCreateRouteContainer = () => {
        setShowCreateRouteContainer(false)
    }



    const RenderMemoizedCreateRouteContainer = useMemo(() => {
        return (
            <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 10 }}>
                {/* <Button onPress={closeCreateRouteContainer} status="danger" style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}>
                    <Icon name="close" fill="white" width={20} height={20} />
                </Button> */}
                <CreateRouteContainer
                    onClose={closeCreateRouteContainer}
                />
            </View>
        )
    }, [showCreateRouteContainer])



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region CREATE HOLDING
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const [showCreateHoldingContainer, setShowCreateHoldingContainer] = useState(false)
    const [selectedCaseId, setSelectedCaseId] = useState(null as string | null)
    const openCreateHoldingContainer = () => {
        setShowCreateHoldingContainer(true)
    }

    const createHoldingWithCase = (case_id: string) => {
        selectedCaseIdRef.current = case_id
        setSelectedCaseId(case_id)
        openCreateHoldingContainer()
    }

    const closeCreateHoldingContainer = () => {
        setShowCreateHoldingContainer(false)
        selectedCaseIdRef.current = null
        setSelectedCaseId(null)
        // reload holdings
        setHoldingFilterApplied(HOLDING_STATUS.HELD)
    }




    const RenderMemoizedCreateHoldingContainer = useMemo(() => {
        return (
            <DMModal
                visible={showCreateHoldingContainer}
                onClose={closeCreateHoldingContainer}
            >
                {/* <View style={{
                    position: 'absolute',
                    top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 1000,
                // borderColor: 'rgba(0,0, 0, 0.5)',
                //@ts-ignore
                border: '20px solid rgba(0,0, 0, 0.5)',
                // margin: 20
            }}> */}
                <View
                    style={{
                        width: windowWidth * 0.9,
                        height: windowHeight * 0.9,
                    }}
                >

                    <Button onPress={closeCreateHoldingContainer} appearance="ghost" status="basic" style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}>
                        <Icon name="close" fill="black" />
                    </Button>
                    <View style={{ height: 5, backgroundColor: 'green', borderTopRightRadius: 20, borderTopLeftRadius: 20 }}></View>
                    <CreateHoldingContainer
                        onClose={closeCreateHoldingContainer}
                        onCreate={(h: Holding) => { closeCreateHoldingContainer() }}
                        case_id={selectedCaseId as string}
                    />
                </View>
                {/* </View> */}

            </DMModal>
        )
    }, [showCreateHoldingContainer, selectedCaseId, windowWidth, windowHeight])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region CREATE CASE
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const [showCreateCaseContainer, setShowCreateCaseContainer] = useState(false)
    const openCreateCaseContainer = () => {
        setShowCreateCaseContainer(true)
    }





    const RenderMemoizedCreateCaseContainer = useMemo(() => {
        return (
            // <View style={{
            //     position: 'absolute',
            //     top: 0,
            //     left: 0,
            //     right: 0,
            //     bottom: 0,
            //     zIndex: 1000,
            //     // borderColor: 'rgba(0,0, 0, 0.5)',
            //     //@ts-ignore
            //     border: '20px solid rgba(0,0, 0, 0.5)',
            //     // margin: 20
            // }}>
            <DMModal
                visible={showCreateCaseContainer}
                onClose={() => setShowCreateCaseContainer(false)}
            >
                <View
                    style={{
                        width: windowWidth * 0.9,
                        height: windowHeight * 0.9,
                    }}
                >

                    <Button onPress={() => setShowCreateCaseContainer(false)} appearance="ghost" status="basic" style={{ position: 'absolute', top: 0, right: 0, zIndex: 1000 }}>
                        <Icon name="close" fill="black" />
                    </Button>
                    <View style={{ height: 5, backgroundColor: 'green', borderTopRightRadius: 20, borderTopLeftRadius: 20 }}></View>
                    <CreateCaseContainer
                        onClose={() => setShowCreateCaseContainer(false)}
                        onCreate={(c: Case) => { setShowCreateCaseContainer(false) }}
                    />
                </View>
                {/* </View> */}
            </DMModal>
        )
    }, [showCreateCaseContainer, windowWidth, windowHeight])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region CREATE ORDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const openCreateOrderModal = () => {
        setShowCreateOrderModal(true)
    }



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// JOB DETAILS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const closeJobDetailsModal = () => {
        setJobDetailsModalOpen(false)
        setSelectedJob(null)
    }

    const openJobDetailsModal = () => {
        setJobDetailsModalOpen(true)
    }


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// ASSIGN JOB
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [showAssignJobModal, setShowAssignJobModal] = useState(false)

    const assignJob = async (driver: Member) => {
        let job = StorageController.getAppState().selectedJob
        if (!driver || !job || !job.company_id) return
        job.member_id = driver._id
        // job.status = "assigned"
        let updatedJob = await JobsController.assignJob(job._id, driver._id, job.company_id)
        if (updatedJob) {
            onCloseAssign()
        }
    }

    const onOpenAssign = (job: Job) => {
        StorageController.appState.selectedJob = job
        setSelectedJob(job)
        selectedJobRef.current = job
        setShowAssignJobModal(true)
    }

    const onCloseAssign = () => {
        setShowAssignJobModal(false)
    }







    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// MAP
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const [showDriverDirections, setShowDriverDirections] = useState(false)
    const [driverToJobDirections, setDriverToJobDirections] = useState(null as any)

    const onSetDriverToJobDirections = async (driver: Member, job: Job) => {
        const d = await MembershipsController.getMemberById(driver._id) as Member
        dispatchEventStateChange({ type: STATE_ACTIONS.UPDATED_MAP_DIRECTIONS, data: { driver: driver, job: job } })
        setDriverToJobDirections({ driver: d, job: job })
        setShowDriverDirections(true)
    }

    const clearDirections = () => {
        setShowDriverDirections(false)
    }

    const onZoomToJobMarker = (job: Job) => {
        setZoomToJob(job)
    }


    const updateHoldingsCount = useCallback(() => {
        if (!selectedCompany) return
        const company = StorageController.getCurrentCompany()
        const companyHoldings = company.holdings
        if (!companyHoldings || companyHoldings?.length == 0) return
        let count = 0
        companyHoldings?.forEach((holdingItem: Holding) => {
            if (holdingItem.status == "held") count++
        })
        setHoldingsCount(count)
    }, [selectedCompany, holdingscount])

    /**
     * Render holding count badge
     * @returns 
     */
    const RenderHoldingCountBadge = useMemo(() => {
        return (
            <ErrorBoundary>
                <CounterBadge status="assigned">{holdingscount}</CounterBadge>
            </ErrorBoundary>
        )
    }, [selectedCompany, holdingscount])






    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const getDriversJobs = () => {
        // get all jobs where a driver is assigned
        const jobs = fetchedJobsRef.current
        const assignedJobs = jobs.filter((job: Job) => !!job.member_id)
        return assignedJobs
    }

    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region MAP
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    // Memoized map
    const RenderMemoizedMap = useMemo(() => {
        // if tabs are on jobs, then show jobs on map
        const jobs = selectedListPanelTab == LIST_TABS.JOBS ? memoizedFilteredJobs : []
        const scheduledJobs = selectedListPanelTab == LIST_TABS.SCHEDULED ? memoizedScheduledJobs : []
        const driverJobs = selectedListPanelTab == LIST_TABS.DRIVERS ? getDriversJobs() : []
        const allJobs = [...jobs, ...scheduledJobs, ...driverJobs]

        const ordersToShow = selectedListPanelTab == LIST_TABS.ORDERS ? orders : []

        const routesToShow = selectedListPanelTab == LIST_TABS.ROUTES ? routes : []
        console.log("🚀============== ~ file: jobs.component.tsx:1220 ~ RenderMemoizedMap ~ routesToShow🚀==============", routesToShow, routes, selectedListPanelTab)
        // console.log("allJobs", allJobs)
        return (
            <Map
                jobs={allJobs}
                // jobs={memoizedMapDisplayJobs}
                drivers={memoizedDrivers}
                orders={ordersToShow}
                onSelectOrder={onSelectOrder}
                onSelectJob={onSetSelectedJob}
                company={selectedCompany}
                onClearDirections={clearDirections}
                showDirections={showDriverDirections}
                driverToJobDirections={driverToJobDirections}
                zoomToJob={zoomToJob}
                zoomToDriver={null}
                routes={routesToShow}
                selectedRoute={selectedRoute}
                onSelectRoute={onSelectRoute}
            />
        )
    }, [memoizedFilteredJobs, memoizedDrivers, zoomToJob, showDriverDirections, routes, selectedRoute, selectedListPanelTab, orders])


    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region JOB LIST
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    // Memoized job list
    const RenderMemoizedJobList = useMemo(() => {
        const viewedBy = StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"
        return (
            <View style={{ flex: 1 }}>
                <View style={{ position: 'absolute', top: -20, left: 0, zIndex: 10 }}>
                    <Button
                        size="tiny"
                        appearance="ghost"
                        status="primary"
                        onPress={() => setShowCompactJobList(!showCompactJobList)}>
                        {showCompactJobList ? 'Show Large' : 'Show Compact'}
                    </Button>
                </View>
                <JobsList
                    // jobs={memoizedFilteredJobs}
                    showCompact={showCompactJobList}
                    selectedJobId={selectedJob && selectedJob._id}
                    jobs={memoizedUnscheduledJobs}
                    clients={selectedCompany.clients}
                    drivers={selectedCompany.members}
                    var_vendorProfiles={vendorProfiles}
                    fn_onEndReached={onEndReached}
                    fn_zoomToMarker={onZoomToJobMarker}
                    fn_selectJob={(job: Job) => { onSetSelectedJob(job) }}
                    var_viewedBy={viewedBy}
                    fn_setDirections={onSetDriverToJobDirections}
                    fn_openAssign={onOpenAssign}
                    fn_vendorAcceptJob={vendorAcceptJob}
                />
            </View>
        )
    }, [memoizedUnscheduledJobs, memoizedFilteredJobs, memoizedClients, memoizedDrivers, selectedCompany, viewMode, showCompactJobList])//selectedJob

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region SCHEDULED JOB LIST
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    // Memoized job list
    const RenderMemoizedScheduledJobList = useMemo(() => {
        const viewedBy = StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"
        return (
            <View style={{ flex: 1 }}>
                <View style={{ position: 'absolute', top: -20, left: 0, zIndex: 10 }}>
                    <Button
                        size="tiny"
                        appearance="ghost"
                        status="primary"
                        onPress={() => setShowCompactJobList(!showCompactJobList)}>
                        {showCompactJobList ? 'Show Large' : 'Show Compact'}
                    </Button>
                </View>
                <JobsList
                    // jobs={memoizedFilteredJobs}
                    showCompact={showCompactJobList}
                    selectedJobId={selectedJob && selectedJob._id}
                    jobs={memoizedScheduledJobs}
                    clients={selectedCompany.clients}
                    drivers={selectedCompany.members}
                    var_vendorProfiles={vendorProfiles}
                    fn_onEndReached={onEndReached}
                    fn_zoomToMarker={onZoomToJobMarker}
                    fn_selectJob={(job: Job) => { onSetSelectedJob(job) }}
                    var_viewedBy={viewedBy}
                    fn_setDirections={onSetDriverToJobDirections}
                    fn_openAssign={onOpenAssign}
                    fn_vendorAcceptJob={vendorAcceptJob}
                />
            </View>
        )
    }, [memoizedUnscheduledJobs, memoizedFilteredJobs, memoizedClients, memoizedDrivers, selectedCompany, viewMode, showCompactJobList])//selectedJob


    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region JOB DETAILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const RenderMemoizedJobDetails = useMemo(() => {
        if (!selectedJob) return null
        const isCompleteOrCancelledJob = selectedJob?.status === JOB_STATUS.COMPLETE ||
            selectedJob?.status === JOB_STATUS.CANCELLED ||
            selectedJob?.status === JOB_STATUS.TRANSFERRED_OUT;

        const renderSmallScreenContent = () => (
            isCompleteOrCancelledJob ? (
                <JobReportModal
                    showReportDetailsModal={jobDetailsModalOpen}
                    setShowReportDetailsModal={closeJobDetailsModal}
                    job={selectedJob}
                />
            ) : (
                <DMModal
                    visible={jobDetailsModalOpen}
                    onClose={closeJobDetailsModal}
                    style={{ width: "90%" }}
                >
                    <JobDetailContainer
                        selectedJob={selectedJob || undefined}
                        fn_closeJobDetailsModal={closeJobDetailsModal}
                    />
                </DMModal>
            )
        );

        const renderLargeScreenContent = () => (
            isCompleteOrCancelledJob ? (
                <FullJobReport
                    onClose={closeJobDetailsModal}
                    job={selectedJob}
                />
            ) : (
                <JobDetailContainer
                    selectedJob={selectedJob || undefined}
                    fn_closeJobDetailsModal={closeJobDetailsModal}
                />
            )
        );

        return IS_SMALL_SCREEN ? renderSmallScreenContent() : renderLargeScreenContent();
    }, [selectedJob, selectedCompany, jobDetailsModalOpen]);


    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region HOLDING DETAILS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const RenderMemoizedHoldingDetails = useMemo(() => {
        if (!selectedHolding) return null
        return (
            <HoldingDetailsContainer
                fn_onClose={() => { onCloseHoldingDetails(); }}
                fn_onReleaseAndCreateJobHolding={() => { setShowReleaseHoldingOptions(true); setHoldingDetailsOpen(false) }}
                holdingItem={selectedHolding as Holding}
                fn_onReleaseOnlyHolding={() => { onCloseHoldingDetails(); }}
                disabled={false}
            />
        )
    }, [selectedHolding, holdingDetailsOpen, selectedCompany])

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region CREATE JOB FROM HOLDING MODAL
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const RenderMemoizedCreateJobFromHoldingModal = useMemo(() => {
        return (
            <CreateJobFromHoldingModal
                fn_onClose={() => setShowReleaseHoldingOptions(false)}
                fn_onCreateJob={() => { setShowReleaseHoldingOptions(false) }}
                holdingItem={selectedHolding as Holding}
                holdingItemId={selectedHolding?._id as string}
                holdingJobId={selectedHolding?.holding_job_id as string}
            />
        )
    }, [selectedHolding, showReleaseHoldingOptions])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region ASSIGN JOB MODAL
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    // Assign job
    const RenderMemoizedAssignJobToDriverModal = useMemo(() => {
        return (
            <AssignJobModal
                fn_assignMember={assignJob}
                selectedJob={selectedJob}
                var_member_id={selectedJobRef.current?.member_id}
                var_assignJobModalVisible={showAssignJobModal}
                fn_dismiss={() => setShowAssignJobModal(false)}
            />
        )
    }, [showAssignJobModal, selectedJob])

    const [showCaseDetails, setShowCaseDetails] = useState(false)
    const [selectedCase, setSelectedCase] = useState(null as Case | null)

    const openCaseDetails = () => {
        setShowCaseDetails(true)
    }

    const closeCaseDetails = () => {
        setShowCaseDetails(false)
    }

    const onSetSelectedCase = (caseObj: Case) => {
        setSelectedCase(caseObj)
        openCaseDetails()
    }


    const RenderMemoizedCaseDetails = useMemo(() => {
        return (
            <View
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1000,
                    // border: '20px solid rgba(0,0, 0, 0.5)',
                }}>
                <CaseDetailsContainer
                    caseObj={selectedCase}
                    onClose={closeCaseDetails}
                />
            </View>
        )
    }, [showCaseDetails, selectedCase])




    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region FILTER PANEL
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    // memoized filter panel
    const RenderMemoizedFilterPanel = useMemo(() => {
        return (
            <FilterPanel
                clients={memoizedClients}
                filteredClients={filteredClients}
                drivers={memoizedDrivers}
                filteredDrivers={filteredDrivers}
                setFilteredClients={setFilteredClients}
                setFilteredDrivers={setFilteredDrivers}
                setShowFilters={setShowFilters}
                showFilters={showFilters}
            />
        )
    }, [showFilters, memoizedClients, filteredClients, memoizedDrivers, filteredDrivers])


    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region DRIVERS LIST
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    // drivers list
    const RenderMemoizedDriversList = useMemo(() => {
        const viewedBy = StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"
        return (
            <DriversList
                var_clients={memoizedClients}
                var_drivers={memoizedDrivers}
                fn_selectDriver={() => { }}
                fn_zoomToMarker={onZoomToJobMarker}
                fn_selectJob={onSetSelectedJob}
                fn_setDirections={onSetDriverToJobDirections}
                var_viewedBy={viewedBy}
            />
        )
    }, [memoizedDrivers])


    const onSelectHoldingItem = (holding: Holding) => {
        setSelectedHolding(holding)
        setHoldingDetailsOpen(true)
    }
    const onCloseHoldingDetails = () => {
        setSelectedHolding(null)
        setHoldingDetailsOpen(false)
    }

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region HOLDINGS LIST
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    // holding list
    const RenderMemoizedHoldingsList = useMemo(() => {
        return (
            <HoldingList
                holdingFilter={holdingFilterApplied}
                selectedCompany={selectedCompany}
                preloadedHoldingItems={holdingSearchResults}
                onSelectHolding={onSelectHoldingItem}
                allowShowHoldingDetails={false}
            />
        )
    }, [selectedCompany, holdingFilterApplied, selectedListPanelTab, holdingSearchResults])


    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region CASES LIST
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const RenderMemoizedCasesList = useMemo(() => {
        return (
            <CaseList
                var_viewedBy={StorageController.getAppState().selectedMembership?.is_client ? "local_client" : "vendor"}
                selectedCompany={selectedCompany}
                caseFilter={caseFilterApplied}
                var_clients={memoizedClients}
                var_drivers={memoizedDrivers}
                fn_selectJob={onSetSelectedJob}
                fn_openAssign={onOpenAssign}
                fn_zoomToMarker={onZoomToJobMarker}
                fn_setDirections={onSetDriverToJobDirections}
                fn_vendorAcceptJob={vendorAcceptJob}
                fn_onCreateNewCaseJob={openNewJobModalWithCase}
                fn_onCreateNewHolding={createHoldingWithCase}
                var_vendorProfiles={undefined}
                fn_openJobReport={undefined}
                fn_openCaseDetails={onSetSelectedCase}
            />
        )
    }, [selectedCompany, caseFilterApplied, selectedListPanelTab])

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region FILTER BUTTONS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    // memoized filter buttons
    const RenderMemoizedJobStatusFilterButtons = React.useMemo(() => {
        return (
            <JobStatusFilterButtons
                style={{ flex: 1 }}
                onSetFilterApplied={onSetFilterApplied}
                filterApplied={jobFilterApplied}
                setShowFilters={setShowFilters}
                fetchedJobsRef={fetchedJobsRef}
            />
        )
    }, [jobFilterApplied, fetchedJobsRef.current, showFilters])

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region CASE FILTER BUTTONS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const RenderMemoizedCaseFilterButtons = useMemo(() => {
        return (
            <CaseStatusFilterButtons
                caseFilterApplied={caseFilterApplied}
                setCaseFilterApplied={setCaseFilterApplied}
            />
        )
    }, [caseFilterApplied, selectedListPanelTab]);

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region HOLDING FILTER BUTTONS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const RenderMemoizedHoldingFilterButtons = useMemo(() => {
        return (
            <HoldingStatusFilterButtons
                holdingFilterApplied={holdingFilterApplied}
                setHoldingFilterApplied={setHoldingFilterApplied}
                onClearSearchResults={clearHoldingSearchResults}
                holdingSearchResults={holdingSearchResults}
            />
        )
    }, [holdingFilterApplied, selectedListPanelTab, holdingSearchResults]);


    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region CALENDAR VIEW
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const RenderMemoizedCalendarView = useMemo(() => {
        return (
            <CalendarContainer
                // scheduledJobs={memoizedScheduledJobs}
                jobStatusFilter={jobFilterApplied}
                jobs={memoizedFilteredJobs}
                holdings={selectedCompany.holdings}
                clients={selectedCompany.clients}
                members={selectedCompany.members}
                services={selectedCompany.services}
                onSelectJob={onSetSelectedJob}
                selectedCompany={selectedCompany}
                routes={routes}
                enableFilters={showFilters}
                filteredClientIds={filteredClients}
                filteredDriverIds={filteredDrivers}
            />
        )
    }, [memoizedScheduledJobs])




    const onSelectJobId = (job_id: string) => {
        // get the job from the list and zoom
        const job = memoizedFilteredJobs.find((j: Job) => j._id == job_id)
        if (job) {
            onZoomToJobMarker(job)
        }
    }

    const onUpdateRoute = useCallback((updatedRoute: Route) => {
        let prevRoutes = [...routesRef.current]
        onSetRoutes(prevRoutes.map((route: Route) =>
            route._id === updatedRoute._id ? updatedRoute : route
        ));
        if (selectedRoute && selectedRoute._id === updatedRoute._id) {
            onSelectRoute(updatedRoute)
        }
    }, [selectedRoute]);

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region ROUTES LIST
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    const RenderMemoizedRoutesList = useMemo(() => {
        return (
            <RoutesList
                routes={routes}
                selectedRoute={selectedRoute}
                onSelectRoute={onSelectRoute}
                onSelectJobId={onSelectJobId}
                onUpdateRouteStatus={onUpdateRouteStatus}
                onEndReached={onRoutesEndReached}
                onUpdateRoute={onUpdateRoute}
            />
        )
    }, [routes])


    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region ORDERS LIST
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
    const [showSelectedOrderModal, setShowSelectedOrderModal] = useState(false)
    const [showCreateOrderModal, setShowCreateOrderModal] = useState(false)
    const RenderMemoizedSelectedOrderModal = useMemo(() => {
        if (!selectedOrder) return null
        return (
            <DMModal
                visible={showSelectedOrderModal}
                onClose={() => { setShowSelectedOrderModal(false) }}
            >
                <View style={{ maxHeight: windowHeight * 0.9, maxWidth: windowWidth * 0.8 }}>

                    <ScrollView>
                        <OrderDetail
                            currentCompany={selectedCompany}
                            activeTab={activeOrdersTab}
                            onOrderUpdated={() => { }}
                            order={selectedOrder}
                            onBack={() => { setShowSelectedOrderModal(false) }}
                        />
                    </ScrollView>
                </View>
            </DMModal>
        )
    }, [selectedOrder, showSelectedOrderModal])

    const RenderMemoizedCreateOrderModal = useMemo(() => {
        return (
            <DMModal
                visible={showCreateOrderModal}
                onClose={() => { setShowCreateOrderModal(false) }}
            >
                <CreateOrderComponent
                    onOrderCreated={() => { setShowCreateOrderModal(false) }}
                    onClose={() => { setShowCreateOrderModal(false) }}
                />
            </DMModal>
        )
    }, [showCreateOrderModal])

    const RenderMemoizedOrdersList = useMemo(() => {
        // show the switch between client orders and vendor orders if the company is a vendor
        const isVendor = selectedCompany.settings?.parts_network?.is_vendor || false;

        return (
            <View style={{ flexDirection: 'column', maxHeight: windowHeight * 0.8 }}>
                <View style={{ flexDirection: 'row', marginTop: 3, justifyContent: 'space-between' }}>
                    {/* show the switch between client orders and vendor orders */}
                    <View style={{ flexDirection: 'row', gap: 3 }}>
                        {isVendor && (
                            <View style={{ flexDirection: 'row', gap: 10 }}>
                                <Button
                                    size="small"
                                    onPress={() => { setShowCreateOrderModal(true) }}
                                    accessoryLeft={<Icon name="plus" />}
                                    status="success"
                                >
                                    Create Order
                                </Button>

                                <Button
                                    size="small"
                                    appearance={activeOrdersTab == ORDERS_TABS.VENDOR_ORDERS ? 'filled' : 'outline'}
                                    onPress={() => { onSetActiveOrdersTab(ORDERS_TABS.VENDOR_ORDERS) }}
                                >Vendor Orders</Button>
                            </View>
                        )}
                        <Button
                            size="small"
                            appearance={activeOrdersTab == ORDERS_TABS.CLIENT_ORDERS ? 'filled' : 'outline'}
                            onPress={() => { onSetActiveOrdersTab(ORDERS_TABS.CLIENT_ORDERS) }}
                        >Client Orders</Button>
                    </View>

                </View>
                <ScrollView>
                    <OrderList
                        orders={orders}
                        isLoading={false}
                        currentCompany={selectedCompany}
                        onSelectOrder={onSelectOrder}
                        selectedOrderId={selectedOrderId}
                        activeTab={activeOrdersTab}
                    />
                </ScrollView>
            </View>
        )
    }, [orders, selectedOrder, activeOrdersTab, showSelectedOrderModal])


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //#region DRIVERS ONLINE COUNT
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const getDriversOnlineCount = () => {
        try {
            let count = 0
            if (!selectedCompany?.members) return count
            const drivers = selectedCompany.members?.filter((member: Member) => member.is_driver)
            drivers?.forEach((driver: Member) => {
                if (driver.online == true) {
                    let lastUpdated = new Date(driver.updatedAt)
                    let now = new Date()
                    let diff = now.getTime() - lastUpdated.getTime()
                    if (diff < 1000 * 60 * 60) count++
                }
            })
            return count
        } catch (error) {
            console.error("Error getting drivers online count:", error);
            return 0
        }
    }

    const ongoingCasesCount = () => {
        let count = 0
        selectedCompany.cases.forEach((c: Case) => {
            if (c.status == "ongoing") count++
        })
        return count
    }


    const RenderRefreshButton = () => {
        return (
            <Button
                size="small"
                style={{
                    padding: 5,
                    margin: 5,
                    width: 40,
                    height: 30,
                    alignSelf: 'center',
                    borderColor: theme['color-success-500'],
                }}
                status="warning"
                onPress={async () => {
                    try {
                        setRenderSpinner(true)
                        setLoadingText("Refreshing data...")
                        const result = await UpdateController.getAllCompanyDashboardData()
                        // if result is true, then refresh the page
                        if (result) {
                            initLoad()
                        }
                        setRenderSpinner(false)
                        setLoadingText("")
                    } catch (error) {
                        console.error("Error refreshing data:", error);
                        setRenderSpinner(false)
                        setLoadingText("")
                    }
                }}
                accessoryLeft={<Icon name="refresh" />}
            />
        )
    }



    return (
        <Layout style={{ flex: 1 }}>
            {/*
            //////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////////////
            //////// MODALS
            //////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////////////
            */}
            {viewMode != VIEW_MODE.LIST && // Open the jobdetails in a modal only when here?
                <>{jobDetailsModalOpen && selectedJob ?
                    <DMModal
                        visible={jobDetailsModalOpen}
                        onClose={() => { closeJobDetailsModal() }}
                        style={{ width: "90%" }}
                    >
                        {RenderMemoizedJobDetails}
                    </DMModal>
                    : null}
                    {holdingDetailsOpen && selectedHolding ?
                        <DMModal
                            visible={holdingDetailsOpen}
                            onClose={() => { setHoldingDetailsOpen(false) }}
                        >
                            {RenderMemoizedHoldingDetails}
                        </DMModal>
                        : null}
                </>
            }
            {showNewJobModal ? RenderMemoizedCreateJobModal : null}
            {enableRoutes && showCreateRouteContainer ? RenderMemoizedCreateRouteContainer : null}
            {enableHoldings && showCreateHoldingContainer ? RenderMemoizedCreateHoldingContainer : null}
            {enableCases && showCreateCaseContainer ? RenderMemoizedCreateCaseContainer : null}
            {showAssignJobModal ? RenderMemoizedAssignJobToDriverModal : null}
            {showReleaseHoldingOptions && RenderMemoizedCreateJobFromHoldingModal}
            {showCaseDetails && RenderMemoizedCaseDetails}
            {showSelectedOrderModal && RenderMemoizedSelectedOrderModal}
            {showCreateOrderModal && RenderMemoizedCreateOrderModal}
            <ErrorBoundary>
                <RenderSpinnerOverlay renderSpinner={renderSpinner} loadingText={loadingText} />
                <View style={[styles.filter_row_wrap, { marginLeft: 10 }]}>
                    <RenderRefreshButton />
                    {/* <View style={{}}> */}
                    <View style={{ flexBasis: '5%', minWidth: 100 }}>
                        <View style={{ flex: 1, justifyContent: 'center', alignItems: "center" }}>
                            <RenderCreateJobButton
                                openNewJobModal={openNewJobModal}
                            />
                        </View>
                    </View>
                    {enableRoutes &&
                        <View style={{ flexBasis: '5%', minWidth: 100 }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: "center" }}>
                                <RenderCreateRouteButton
                                    openCreateRouteContainer={openCreateRouteContainer}
                                />
                            </View>
                        </View>
                    }{enableHoldings && showCreateHolding &&
                        <View style={{ flexBasis: '5%', minWidth: 100 }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: "center" }}>
                                <RenderCreateHoldingButton
                                    openCreateHoldingContainer={openCreateHoldingContainer}
                                />
                            </View>
                        </View>
                    }
                    {enableCases &&
                        <View style={{ flexBasis: '5%', minWidth: 100 }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: "center" }}>
                                <RenderCreateCaseButton
                                    openCreateCaseContainer={openCreateCaseContainer}
                                />
                            </View>
                        </View>
                    }
                    {enableOrders &&
                        <View style={{ flexBasis: '5%', minWidth: 100 }}>
                            <View style={{ flex: 1, justifyContent: 'center', alignItems: "center" }}>
                                <RenderCreateOrderButton
                                    openCreateOrderModal={openCreateOrderModal}
                                />
                            </View>
                        </View>
                    }

                    <View style={{ flexBasis: "50%", minWidth: 200 }}>
                        <View style={{ flex: 3 }}>
                            <View style={{ flexDirection: 'row' }}>

                                {selectedListPanelTab == LIST_TABS.CASES && RenderMemoizedCaseFilterButtons}
                                {selectedListPanelTab == LIST_TABS.HOLDINGS && RenderMemoizedHoldingFilterButtons}
                                {selectedListPanelTab == LIST_TABS.JOBS && RenderMemoizedJobStatusFilterButtons}
                                {selectedListPanelTab == LIST_TABS.SCHEDULED && RenderMemoizedJobStatusFilterButtons}
                                {selectedListPanelTab == LIST_TABS.DRIVERS && RenderMemoizedJobStatusFilterButtons}
                                {selectedListPanelTab == LIST_TABS.ROUTES && RenderMemoizedRoutesFilters}
                                {selectedListPanelTab == LIST_TABS.ORDERS && RenderMemoizedOrdersFilters}
                            </View>
                        </View>
                    </View>
                    <View style={{ flexBasis: "10%", minWidth: 50 }}>
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <ViewModeButtons
                                    style={{ flex: 1 }}
                                    viewMode={viewMode}
                                    setViewMode={setViewMode}
                                />
                            </View>
                        </View>
                    </View>
                    <View style={{ flexBasis: '10%', minWidth: 250 }}>
                        <View style={{ flex: 1 }}>
                            {selectedListPanelTab == LIST_TABS.JOBS &&
                                <SearchJobsHoldingsCases
                                    onSearchResultsReturned={onSetJobSearchResults}
                                    resetSearch={resetSearch}
                                />
                            }
                            {selectedListPanelTab == LIST_TABS.HOLDINGS &&
                                <SearchJobsHoldingsCases
                                    onSearchResultsReturned={onSetHoldingSearchResults}
                                    resetSearch={resetSearch}
                                    searchType={SEARCH_TYPE.HOLDING}
                                />
                            }
                        </View>
                    </View>
                </View>
                <View style={styles.content}>
                    <View style={styles.main_area}>
                        {/*
                        //////////////////////////////////////////////////////////////////
                        //////////////////////////////////////////////////////////////////
                        //////// MAIN AREA ROW (Filters, List, Map)           ////////////
                        //////////////////////////////////////////////////////////////////
                        //////////////////////////////////////////////////////////////////
                        */}
                        {showFilters ? RenderMemoizedFilterPanel : null}
                        {windowWidth < 800 ?
                            <>
                                {viewMode == VIEW_MODE.LIST &&
                                    <View style={styles.list_section}>
                                        <ListPanelTabs
                                            listPanelIndex={selectedListPanelTab}
                                            setListPanelIndex={onSetListPanelTab}
                                            enableHoldings={enableHoldings}
                                            enableCases={enableCases}
                                            enableRoutes={enableRoutes}
                                            onRoutesTabSelected={() => { onRoutesTabSelected() }}
                                            scheduledJobsCount={memoizedScheduledJobs.length}
                                            onlineDriversCount={getDriversOnlineCount()}
                                            holdingsCount={holdingscount}
                                            ongoingCasesCount={ongoingCasesCount()}
                                        />
                                        <View style={{ flex: 1 }}>
                                            {selectedListPanelTab == LIST_TABS.JOBS && RenderMemoizedJobList}
                                            {selectedListPanelTab == LIST_TABS.SCHEDULED && RenderMemoizedScheduledJobList}
                                            {selectedListPanelTab == LIST_TABS.DRIVERS && RenderMemoizedDriversList}
                                            {selectedListPanelTab == LIST_TABS.HOLDINGS && enableHoldings && RenderMemoizedHoldingsList}
                                            {selectedListPanelTab == LIST_TABS.CASES && enableCases && RenderMemoizedCasesList}
                                            {selectedListPanelTab == LIST_TABS.ROUTES && enableRoutes && RenderMemoizedRoutesList}
                                            {selectedListPanelTab == LIST_TABS.ORDERS && enableOrders && RenderMemoizedOrdersList}
                                        </View>
                                    </View>
                                }
                                {viewMode == VIEW_MODE.MAP &&
                                    <View style={styles.map_container}>
                                        {RenderMemoizedMap}
                                    </View>
                                }
                                {viewMode == VIEW_MODE.CALENDAR &&
                                    <View style={styles.map_container}>
                                        {RenderMemoizedCalendarView}
                                    </View>
                                }
                            </>
                            :

                            <View style={styles.list_section}>
                                <ListPanelTabs
                                    listPanelIndex={selectedListPanelTab}
                                    setListPanelIndex={onSetListPanelTab}
                                    enableHoldings={enableHoldings}
                                    enableCases={enableCases}
                                    enableRoutes={enableRoutes}
                                    enableOrders={enableOrders}
                                    onRoutesTabSelected={() => { onRoutesTabSelected() }}
                                    scheduledJobsCount={memoizedScheduledJobs.length}
                                    onlineDriversCount={getDriversOnlineCount()}
                                    holdingsCount={holdingscount}
                                    ongoingCasesCount={ongoingCasesCount()}
                                />
                                <View style={{ flex: 1 }}>
                                    {selectedListPanelTab == LIST_TABS.JOBS && RenderMemoizedJobList}
                                    {selectedListPanelTab == LIST_TABS.SCHEDULED && RenderMemoizedScheduledJobList}
                                    {selectedListPanelTab == LIST_TABS.DRIVERS && RenderMemoizedDriversList}
                                    {selectedListPanelTab == LIST_TABS.HOLDINGS && enableHoldings && RenderMemoizedHoldingsList}
                                    {selectedListPanelTab == LIST_TABS.CASES && enableCases && RenderMemoizedCasesList}
                                    {selectedListPanelTab == LIST_TABS.ROUTES && enableRoutes && RenderMemoizedRoutesList}
                                    {selectedListPanelTab == LIST_TABS.ORDERS && enableOrders && RenderMemoizedOrdersList}
                                </View>
                            </View>
                        }
                        {/*
                        //////////////////////////////////////////////////////////////////
                        //////////////////////////////////////////////////////////////////
                        //////// MAP AREA (Map)                          ////////////
                        //////////////////////////////////////////////////////////////////
                        //////////////////////////////////////////////////////////////////
                        */}
                        {viewMode == VIEW_MODE.LIST && jobDetailsModalOpen && selectedJob ?
                            // if mobileView open in modal format
                            <>
                                {windowWidth < 800 ?
                                    <DMModal
                                        visible={jobDetailsModalOpen}
                                        onClose={() => closeJobDetailsModal()}
                                        style={{ width: "99%" }}
                                    >
                                        {RenderMemoizedJobDetails}
                                    </DMModal>
                                    :
                                    <View style={styles.list_detail_container}>
                                        {RenderMemoizedJobDetails}
                                    </View>
                                }
                            </>
                            : null
                        }
                        {viewMode == VIEW_MODE.LIST && holdingDetailsOpen && selectedHolding ?
                            // if mobileView open in modal format
                            <>
                                {windowWidth < 800 ?
                                    <Modal
                                        visible={holdingDetailsOpen}
                                        backdropStyle={styles.job_details_modal_backdrop}
                                        onBackdropPress={() => onCloseHoldingDetails()}
                                        style={styles.job_details_modal_container}
                                    >
                                        {RenderMemoizedHoldingDetails}
                                    </Modal>
                                    :
                                    <View style={styles.list_detail_container}>
                                        {RenderMemoizedHoldingDetails}
                                    </View>
                                }
                            </>
                            : null
                        }
                        {viewMode == VIEW_MODE.MAP && windowWidth > 800 &&
                            <View style={styles.map_container}>
                                {RenderMemoizedMap}
                            </View>
                        }
                        {viewMode == VIEW_MODE.CALENDAR && windowWidth > 800 &&
                            <View style={styles.map_container}>
                                {RenderMemoizedCalendarView}
                            </View>
                        }
                    </View>
                </View>
            </ErrorBoundary>
        </Layout >
    )




}

const styles = StyleSheet.create(
    {
        filter_row: {
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
            padding: 7,
            gap: 2
        },
        filter_row_wrap: {
            flexDirection: 'row',
            // flex: 1,
            justifyContent: 'space-between',
            // padding: 7,
            gap: 20,
            flexWrap: 'wrap'
        },
        filter_button_container: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 5,
            gap: 2,
            height: "60%",
            flexWrap: 'wrap'
        },
        filter_button_style: {
            flex: 1,
            minWidth: 70,
            // maxWidth: 80,
            // flexBasis: 80,
        },
        view_button_style: {
            flex: 1,
            minWidth: 50,
            // maxWidth: 80,
            // flexBasis: 80,
        },
        content: {
            flex: 18,
            flexDirection: "column",
        },
        main_area: {
            flexDirection: "row",
            height: "100%",
            width: "100%",
            padding: 0,
            margin: 0
        },
        list_section: {
            flex: 2,
            justifyContent: 'center',
            height: "100%",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
            zIndex: 2
        },
        map_container: {
            flex: 3,
            justifyContent: 'center',
            display: 'flex',
            height: "100%"
        },
        list_detail_container: {
            flex: 4,
            justifyContent: 'center',
            display: 'flex',
            height: "100%"
        },
        job_details_modal_backdrop: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 100
        },
        job_details_modal_container: {
            position: 'absolute',
            top: '0%',
            right: '5%',
            left: '5%',
            bottom: '0%',
            zIndex: 100000
        }
    }
);




const RenderSpinnerOverlay = ({ renderSpinner, loadingText }: any) => {
    if (!renderSpinner) return <></>
    return (
        <Layout style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1000, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <Spinner size='giant' />
            <Text>{loadingText}</Text>
        </Layout>
    )
}



const RenderCreateJobButton = ({ openNewJobModal }: { openNewJobModal: () => void }) => {
    return (
        <RenderCreateButton onPress={openNewJobModal} label1="CREATE" label2="JOB" />

    )
}



const RenderCreateCaseButton = ({ openCreateCaseContainer }: { openCreateCaseContainer: () => void }) => {
    return (
        <RenderCreateButton onPress={openCreateCaseContainer} label1="CREATE" label2="CASE" />
    )
}


const RenderCreateHoldingButton = ({ openCreateHoldingContainer }: { openCreateHoldingContainer: () => void }) => {
    return (
        <RenderCreateButton onPress={openCreateHoldingContainer} label1="CREATE" label2="HOLDING" />

    )
}


const RenderCreateRouteButton = ({ openCreateRouteContainer }: { openCreateRouteContainer: () => void }) => {
    return (
        <RenderCreateButton onPress={openCreateRouteContainer} label1="CREATE" label2="ROUTE" />
    )
}


const RenderCreateOrderButton = ({ openCreateOrderModal }: { openCreateOrderModal: () => void }) => {
    return (
        <RenderCreateButton onPress={openCreateOrderModal} label1="CREATE" label2="ORDER" />
    )
}

// const RenderCreateButtonsContainer = ({
//     enableOrders,
//     enableRoutes,
//     enableHoldings,
//     enableCases,
//     openNewJobModal,
//     openCreateOrderModal,
//     openCreateRouteContainer,
//     openCreateHoldingContainer,
//     openCreateCaseContainer
// }: {
//     enableOrders: boolean,
//     enableRoutes: boolean,
//     enableHoldings: boolean,
//     enableCases: boolean,
//     openNewJobModal: () => void,
//     openCreateOrderModal: () => void,
//     openCreateRouteContainer: () => void,
//     openCreateHoldingContainer: () => void,
//     openCreateCaseContainer: () => void
// }) => {
//     return (
//         <View style={{ flexDirection: "row", justifyContent: "space-between", gap: 20 }}>
//             <RenderCreateButton onPress={openNewJobModal} label1="CREATE" label2="JOB" />
//             {enableOrders && <RenderCreateButton onPress={openCreateOrderModal} label1="CREATE" label2="ORDER" />}
//             {enableRoutes && <RenderCreateButton onPress={openCreateRouteContainer} label1="CREATE" label2="ROUTE" />}
//             {enableHoldings && <RenderCreateButton onPress={openCreateHoldingContainer} label1="CREATE" label2="HOLDING" />}
//             {enableCases && <RenderCreateButton onPress={openCreateCaseContainer} label1="CREATE" label2="CASE" />}
//         </View>
//     )
// }

const RenderCreateButton = ({ onPress, label1, label2 }: { onPress: () => void, label1: string, label2?: string }) => {
    const theme = useTheme();
    return (
        <Button
            style={{
                width: 100,
                // backgroundColor: theme['color-success-900'],
                // borderColor: theme['color-success-900']
            }}
            onPress={onPress}
            status="success"
            appearance="outline"
            size="small"
            accessoryLeft={<Icon name="plus" fill={theme['text-basic-color']} />}
        >
            <View style={{ flex: 1, flexDirection: "column" }}>
                {/* <Text
                    style={{ textAlign: "center" }}
                    status="basic"
                    category="label">
                    {label1}
                </Text> */}
                {label2 && (
                    <Text
                        style={{ textAlign: "center" }}
                        status="basic"
                        category="label">
                        {label2}
                    </Text>
                )}
            </View>
        </Button>
    )
}

export default JobsScreen;