var config = require('../config/config.js');
const axios = require('axios');
import { Route, RouteDetails, RouteLeg } from '../models/Route.model';
import { Job, JOB_STATUS } from '../models/Job.model';
import * as StorageController from './storageController';
import * as JobController from './job.controller';
import polyline from '@mapbox/polyline';
import * as OsrmController from './osrm.controller';
import { RouteOptimizationRequest, VroomSummary } from '../types/route.types';
import Toast from 'react-native-toast-message';

var api = config.api;
var testApi = config.test_api;

async function getApi() {
    if (await StorageController.getUseTestApi()) {
        return testApi
    }
    return api
}

//==============================================================
//==============================================================
// ROUTES
//==============================================================
//==============================================================
axios.interceptors.request.use(
    (config: any) => {
        let token = getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);
const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

// #region CREATE ROUTE
export async function createRoute(routeObj: Route): Promise<Route | null> {
    try {
        // Before sending to the server, ensure all job data is up-to-date
        for (let location of routeObj.details.locations) {
            const job = await JobController.getJobById(location.job_id);
            if (job) {
                if (location.is_towing_location && job.details.options.towing_job_options && job.details.towing_details) {
                    const towingLocation = job.details.towing_details.towing_locations[location.job_location_index];
                    if (towingLocation) {
                        location.address = towingLocation.address;
                        location.location = towingLocation.location;
                    }
                } else {
                    location.address = job.details.address;
                    location.location = job.details.location;
                }
            }
        }
        routeObj.details.legs = encodePolylineLegs(routeObj)
        routeObj.details.overview_polyline = encodeOverviewPolyline(routeObj.details.overview_polyline) as string
        const response = await axios.post(await getApi() + "/route", routeObj);
        return new Route(response.data);
    } catch (error) {
        console.error(error);
        return null
    }
}

// #region UPDATE ROUTE
export async function updateRoute(routeObj: Route) {
    try {
        // Before sending to the server, ensure all job data is up-to-date
        for (let location of routeObj.details.locations) {
            const job = await JobController.getJobById(location.job_id);
            if (job) {
                if (location.is_towing_location && job.details.options.towing_job_options && job.details.towing_details) {
                    const towingLocation = job.details.towing_details.towing_locations[location.job_location_index];
                    if (towingLocation) {
                        location.address = towingLocation.address;
                        location.location = towingLocation.location;
                    }
                } else {
                    location.address = job.details.address;
                    location.location = job.details.location;
                }
            }
        }
        // ensure all route legs are encoded
        routeObj.details.legs = encodePolylineLegs(routeObj)
        routeObj.details.overview_polyline = encodeOverviewPolyline(routeObj.details.overview_polyline) as string
        const response = await axios.put(await getApi() + "/route/" + routeObj._id, routeObj);
        console.log("🚀============== ~ file: route.controller.ts:98 ~ updateRoute ~ response🚀==============", response)
        return response.data;
    } catch (error) {
        console.error(error);
    }
}





// #region ENCODE POLYLINE LEGS
function encodePolylineLegs(routeObj: Route): RouteLeg[] {
    let legs = routeObj.details.legs
    for (let leg of legs) {
        if (typeof leg.polyline === 'string') {
            return legs
        }
        if (Array.isArray(leg.polyline)) {
            if (leg.polyline.length > 0 && leg.polyline[0].hasOwnProperty('lat') && leg.polyline[0].hasOwnProperty('lng')) {
                // This is an array of {lat, lng} objects
                // Handle the Google Maps format directly
                leg.polyline = polyline.encode(leg.polyline.map((point: { lat: number; lng: number }) => [point.lng, point.lat]), 6);
            }
            else { //is likely geoJson
                leg.polyline = polyline.encode(leg.polyline, 6);
            }
        }
        else {
            console.error('route.controller.ts - encodePolylineLegs - Unexpected polyline format:', leg.polyline);
            return legs
        }
    }
    console.log("🚀============== ~ file: route.controller.ts:125 ~ encodePolylineLegs ~ legs🚀==============", legs)
    return legs
}
// #region ENCODE OVERVIEW POLYLINE
function encodeOverviewPolyline(overviewPolyline: any): string | null {
    if (overviewPolyline) {
        if (Array.isArray(overviewPolyline)) {
            if (overviewPolyline.length > 0 && overviewPolyline[0].hasOwnProperty('lat') && overviewPolyline[0].hasOwnProperty('lng')) {
                // This is an array of {lat, lng} objects
                // Handle the Google Maps format directly
                const encodedOverviewPolyline = polyline.encode(overviewPolyline.map((point: { lat: number; lng: number }) => [point.lng, point.lat]), 6);
                return encodedOverviewPolyline
            }
            else { //is likely geoJson - backwards
                const encodedOverviewPolyline = polyline.encode(overviewPolyline, 6);
                return encodedOverviewPolyline
            }
        }
        else if (typeof overviewPolyline === 'string') {
            return overviewPolyline
        }
    }
    return null;
}

// #region GET ROUTE BY ID
export async function getRouteById(routeId: string) {
    try {
        const response = await axios.get(await getApi() + "/route/" + routeId);
        let routeObj = new Route(response.data);
        return routeObj;
    } catch (error) {
        console.error(error);
    }
}

// #region DELETE ROUTE
export async function deleteRoute(routeId: string) {
    try {
        const response = await axios.delete(await getApi() + "/route/" + routeId);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// #region GET ROUTE BY JOB ID
export async function getRouteByJobId(jobId: string) {
    try {
        const response = await axios.get(await getApi() + "/route/job/" + jobId);
        return new Route(response.data);
    } catch (error) {
        console.error(error);
    }
}

// #region ADD JOB TO ROUTE
export async function addJobToRoute(routeId: string, jobId: string) {
    try {
        const response = await axios.put(await getApi() + "/route/" + routeId + "/job/" + jobId);
        return new Route(response.data);
    } catch (error) {
        console.error(error);
    }
}

// #region REMOVE JOB FROM ROUTE
export async function removeJobFromRoute(routeId: string, jobId: string) {
    try {
        const response = await axios.put(await getApi() + "/route/" + routeId + "/remove_job/" + jobId);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

// #region GET ROUTES BY COMPANY ID
export async function getRoutesByCompanyId(companyId: string) {
    try {
        const response = await axios.get(await getApi() + "/route/company/" + companyId);
        const routes = response.data?.map((routeObj: any) => {
            return new Route(routeObj);
        });
        return routes;
    } catch (error) {
        console.error(error);
    }
}

// #region GET ROUTES BY COMPANY ID AND DATE RANGE
export async function getRoutesByCompanyIdAndDateRange(companyId: string, startDate: any, endDate: any) {
    var body = {
        company_id: companyId,
        start_date: startDate,
        end_date: endDate,
    };
    try {
        const response = await axios.post(await getApi() + "/route/company/dates", body);
        const routes = response.data?.map((routeObj: any) => {
            return new Route(routeObj);
        });
        return routes;
    } catch (error) {
        console.error(error);
    }
}

// #region GET ROUTES BY COMPANY ID AND STATUS
export async function getRoutesByCompanyIdAndStatus(companyId: string, status: string, limit = 1000, offset = 0) {
    const body = {
        company_id: companyId,
        status: status,
        limit: limit,
        offset: offset
    };
    try {
        const response = await axios.post(await getApi() + "/route/company/status", body);
        const routes = response.data.map((routeObj: any) => {
            return new Route(routeObj);
        });
        return routes;
    } catch (error) {
        console.error(error);
    }
}

// #region SET ROUTE STATUS
export async function setRouteStatus(routeId: string, status: string) {
    const body = {
        route_id: routeId,
        status: status,
    };
    try {
        const response = await axios.put(await getApi() + `/route/status/${status}`, body);
        return new Route(response.data);
    } catch (error) {
        console.error(error);
    }
}
// #region ASSIGN DRIVER TO ROUTE
export async function assignDriverToRoute(routeId: string, driverId: string) {
    try {
        // use update route function
        let route = await getRouteById(routeId) as Route;
        if (!route) {
            throw new Error("Route not found");
        }
        route.member_id = driverId;
        const updatedRoute = await updateRoute(route);
        return updatedRoute;
    } catch (error) {
        console.error("Error assigning driver to route:", error);
        throw error;
    }
}

// #region ASSIGN DRIVER TO ALL JOBS IN ROUTE
export async function assignDriverToAllJobsInRoute(routeId: string, driverId: string) {
    try {
        let route = await getRouteById(routeId) as Route;
        if (!route) {
            throw new Error("Route not found");
        }
        await JobController.assignMultipleJobsToMember(route.job_ids, driverId, route.company_id as string)
    } catch (error) {
        console.error("Error assigning driver to all jobs in route:", error);
        throw error;
    }
}

// #region CALCULATE OPTIMISED ROUTE
export const calculateOptimisedRoute = async (request: RouteOptimizationRequest): Promise<{ routes: Route[], summary: VroomSummary }> => {
    try {
        const result = await OsrmController.getOptimizeRoutes(request);
        return result;
    } catch (error) {
        console.error("Error in calculateOptimisedRoute:", error);
        throw error;
    }
};

// #region CALCULATE ORDERED ROUTE
export const calculateOrderedRoute = async (route: Route): Promise<Route> => {
    try {
        const result = await OsrmController.getRouteDirections(route);
        console.log("🚀============== ~ file: route.controller.ts:309 ~ calculateOrderedRoute ~ result🚀==============", result)
        if (result.legs.length > 0) {
            const newRoute = new Route({
                ...route,
                details: new RouteDetails({
                    ...route.details,
                    legs: result.legs,
                    overview_polyline: result.routePolyline
                })
            });
            console.log("🚀============== ~ file: route.controller.ts:321 ~ calculateOrderedRoute ~ newRoute🚀==============", newRoute)
            return newRoute;
        }
        console.log("🚀============== ~ file: route.controller.ts:325 ~ calculateOrderedRoute ~ route🚀==============", route)
        return route;
    } catch (error) {
        console.error("Error getting route directions:", error);
        throw error;
    }
};

// #region GET AVAILABLE JOBS FOR ROUTE
export async function getAvailableJobsForRoute(companyId: string, routeId: string | null = null) {
    try {
        // const fetchedJobs = await JobController.getIncompleteJobsByCompanyId(companyId, 100);
        const fetchedJobs = await JobController.getJobsByCompanyAndStatus(companyId, JOB_STATUS.UNASSIGNED, 100);
        const incompleteJobs = fetchedJobs.map((job: Job) => new Job(job));
        const jobsWithoutRoute = incompleteJobs.filter((job: Job) => !job.route_id);
        if (routeId) {
            const filteredJobs = jobsWithoutRoute.filter((job: Job) => job.route_id != routeId);
            return filteredJobs;
        }
        return jobsWithoutRoute;
    } catch (error) {
        console.error("Error getting route locations:", error);
        throw error;
    }
}


// #region GET ALL JOBS BY ROUTE ID
export async function getAllJobsByRouteId(routeId: string) {
    try {
        const response = await axios.get(await getApi() + "/route/" + routeId + "/jobs");
        return response.data;
    } catch (error) {
        console.error("Error getting jobs by route id:", error);
        throw error;
    }
}


/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//#region LOCAL STORAGE FUNCTIONS
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
/**
 * Update Route in company
 * @param {*} route
 */
export async function updateRouteInCompany(route: Route) {
    try {
        let companies = StorageController.getAppState().companies || []
        let company = companies.find(c => c._id == route.company_id)
        if (company && company.routes && company.routes.length > 0) {
            let routeIndex = company.routes.findIndex(r => r._id == route._id)
            if (routeIndex == -1) {
                company.routes.unshift(route)
            } else {
                company.routes[routeIndex] = route
            }
            console.log("🚀============== ~ file: route.controller.ts:390 ~ updateRouteInCompany ~ company.routes🚀==============", company.routes)
            // Toast.show({
            //     type: 'success',
            //     text1: "Route Updated",
            //     text2: `${route.name}👋 ${route.status}`
            // });
        }
        return companies
    } catch (err) {
        console.log("🚀 ~ file: route.controller.ts:392 ~ updateRouteInCompany ~ err", err)
        return StorageController.getAppState().companies || []
    }
}
