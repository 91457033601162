import React, { useState, useEffect } from 'react';
import { Modal, Card, Input, Button, Select, SelectItem, IndexPath, Text, Icon } from '@ui-kitten/components';
import { View, StyleSheet } from 'react-native';
import { InventoryStock } from '../../models/Inventory.model';
import { InventoryCategory, InventoryItem } from '../../models/Inventory.model';
import * as InventoryController from '../../functions/Inventory.controller';

interface AddInventoryStockModalProps {
    visible: boolean;
    onClose: () => void;
    onAddStock: (stock: InventoryStock) => void;
    onEditStock: (stock: InventoryStock) => void;
    stock?: InventoryStock | null;
    inventoryItemId: string;
}

const AddInventoryStockModal: React.FC<AddInventoryStockModalProps> = ({ visible, onClose, onAddStock, onEditStock, stock, inventoryItemId }) => {
    // Replace separate states with a single stockData state
    const [stockData, setStockData] = useState<InventoryStock>(stock ? stock : new InventoryStock());

    useEffect(() => {
        if (stock) {
            setStockData(stock);
        } else {
            setStockData(new InventoryStock());
        }
    }, [stock]);

    const handleSubmit = () => {
        console.log('stockData', stockData);
        if (stockData._id) {
            onEditStock(stockData);
        } else {
            onAddStock(stockData);
        }
    };

    return (
        <Modal visible={visible} onBackdropPress={onClose} backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <Card disabled={true} style={styles.card} status='basic'
                header={
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>{stockData._id ? 'Edit Stock' : 'Add Stock'}</Text>
                        <Button
                            size='small'
                            style={{ position: 'absolute', right: 0, top: 0 }}
                            accessoryLeft={<Icon name='close-outline' />}
                            appearance='filled' status='danger'
                            onPress={onClose}>
                        </Button>
                    </View>
                }
            >
                <View style={styles.container}>
                    <Input
                        label='Location Name'
                        value={stockData.location || ''}
                        onChangeText={(value) => setStockData({ ...stockData, location: value })}
                        style={styles.input}
                    />
                    <Input
                        label='Quantity'
                        value={stockData.quantity?.toString() || ''}
                        keyboardType='number-pad'
                        onChangeText={(value) => setStockData({ ...stockData, quantity: parseInt(value, 10) })}
                        style={styles.input}
                    />
                    <View style={{flexDirection:'row',justifyContent:'space-between'}}>

                    <Button
                        appearance='filled'
                        status='success'
                        onPress={handleSubmit}>
                        {stockData._id ? 'Update Stock' : 'Add Stock'}
                    </Button>
                    <Button
                        appearance='ghost'
                        status='danger'
                        onPress={onClose}>
                        Cancel
                    </Button>
                    </View>
                </View>
            </Card>
        </Modal>
    );
};

const styles = StyleSheet.create({
    card: {
        width: '90%',
    },
    container: {
        padding: 20,
    },
    input: {
        marginBottom: 15,
    },
});

export default AddInventoryStockModal;