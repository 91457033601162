import React from 'react'
//@ts-ignore
import { View } from 'react-native'
import { Button, Card, Text } from '@ui-kitten/components'
import { Job, JobAddress, TOWING_TYPE } from '../../models/Job.model'
import * as GoogleController from '../../functions/google.controller'

export const TowingJobInfo = ({
    job,
    setShowJobInfoWindow = () => { },
    setShowTowingPolyline = () => { },
    setTowingPolylines = () => { },
    fn_selectJob = () => { }
}: {
    job: Job,
    setShowJobInfoWindow: any,
    setShowTowingPolyline: any,
    setTowingPolylines: any,
    fn_selectJob: any

}) => {
    job = new Job(job)
    const serviceNames = job.getServicesNames()
    const towing_details = job.details?.towing_details
    const towingPickupAddress = towing_details?.towing_locations[0].address
    const towingPickupAddressType = towing_details?.towing_locations[0].address_type
    const towingDropoffAddress = towing_details?.towing_locations[towing_details.towing_locations.length - 1].address
    const towingDropoffAddressType = towing_details?.towing_locations[towing_details.towing_locations.length - 1].address_type
    const towingDetails = {
        customerName: job?.details?.customer_details?.name || "N/A",
        customerPhone: job?.details?.customer_details?.phone || "N/A",
        customerEmail: job?.details?.customer_details?.email || "N/A",
        comments: job?.details?.comments || "N/A",
        pickupAddress: GoogleController.addressBuilder(towingPickupAddress as JobAddress) || "N/A",
        dropoffAddress: GoogleController.addressBuilder(towingDropoffAddress as JobAddress) || "N/A",
        pickupAddressType: towingPickupAddressType || "N/A",
        dropoffAddressType: towingDropoffAddressType || "N/A",
        distance: towing_details?.distance_kms || "N/A",
        duration: (towing_details?.duration_seconds)?.toFixed(2) || "N/A",
        towingType: towing_details?.towing_type || "N/A",
    }

    const towingTypeDescription = () => {
        if (towing_details?.towing_type?.toLowerCase() == "equipment" || towing_details?.towing_type == TOWING_TYPE.EQUIPMENT) {
            return towing_details?.equipment_details?.equipment
        } else if (towingDetails.towingType?.toLowerCase() == "vehicle" || towingDetails.towingType == TOWING_TYPE.VEHICLE) {
            return `${towing_details?.vehicle_details?.make}/${towing_details?.vehicle_details?.model}`
        } else {
            return "N/A"
        }
    }

    return (
        <Card
            disabled={true}
            status='primary'
            header={() => (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: "row", justifyContent: 'space-between' }}>
                        <Text category="s1">{serviceNames}</Text>
                    </View>
                </View>
            )}
            footer={() => (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button
                        status='primary'
                        appearance='outline'
                        onPress={() => {
                            // console.log("on click towing info")
                            setShowJobInfoWindow(false)
                            setShowTowingPolyline(false)
                            setTowingPolylines(null)
                            fn_selectJob(job)
                        }}
                    >
                        Open Job
                    </Button>
                    <Button
                        status='danger'
                        appearance='outline'
                        onPress={() => {
                            // console.log("on click roadside info")
                            setShowJobInfoWindow(false)
                            setShowTowingPolyline(false)
                            setTowingPolylines(null)
                        }}
                    >
                        Close
                    </Button>
                </View>
            )}
        >
            <View style={{ margin: -10 }}>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Customer Name:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.customerName}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Customer Phone:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.customerPhone}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Customer Email:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.customerEmail}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Comments:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.comments}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} numberOfLines={1} category="s1">Pickup Address:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.pickupAddress}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} numberOfLines={1} category="s1">Dropoff Address:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.dropoffAddress}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Pickup Address Type:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.pickupAddressType}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Dropoff Address Type:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.dropoffAddressType}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Distance:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.distance} km</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Duration:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.duration} mins</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Towing Type:</Text>
                    <Text style={{ flex: 3 }}>{towingDetails.towingType}</Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: 'space-between', borderBottomWidth: 1 }}>
                    <Text style={{ flex: 1 }} category="s1">Towing Type Description:</Text>
                    <Text style={{ flex: 3 }}>{towingTypeDescription()}</Text>
                </View>
            </View>
        </Card>
    )
}