import React, { useState, useEffect } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Layout, Card, Text, Input, useTheme } from '@ui-kitten/components';
import { Company } from '../../models/Company.model';
import * as CompanyController from '../../functions/company.controller';

export const VendorList = ({ onSelectVendor }: { onSelectVendor: (vendor: Company) => void }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const [vendors, setVendors] = useState<Company[]>([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchVendors();
    }, []);

    const fetchVendors = async () => {
        const companies = await CompanyController.getPartsNetworkCompanies();
        setVendors(companies);
    };

    const filteredVendors = vendors.filter(vendor =>
        vendor.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const renderVendorCard = (vendor: Company) => (
        <Card
            key={vendor._id}
            status='basic'
            style={styles.card}
            onPress={() => onSelectVendor(vendor)}
            header={(props) => (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                    <Text category="h6" style={styles.cardHeader}>{vendor.name}</Text>
                    <Text category="s1" style={styles.cardType}>{vendor.details}</Text>
                </View>
            )}
        >
            <Text category="s1" style={styles.cardDescription}>
                Address: {vendor.settings.location.address.formatted_address || vendor.settings.location.address.toString() || ""}
            </Text>
            {/* <Text category="s1" style={styles.cardDescription}>
                    Phone: {vendor.settings.contact_info.phone}
                </Text> */}
            <Text category="s1" style={styles.cardDescription}>
                Email: {vendor.settings.contact_info.email}
            </Text>
            <Text category="s1" style={styles.cardDescription}>
                ABN: {'N/A'}
            </Text>
            <Text category="s2" style={styles.cardType}>
                {vendor.settings.parts_network?.is_vendor ? 'Vendor' : ''}
                {vendor.settings.parts_network?.is_vendor && vendor.settings.parts_network?.is_client ? ' | ' : ''}
                {vendor.settings.parts_network?.is_client ? 'Client' : ''}
            </Text>
        </Card>
    );

    return (
        <Layout level="1" style={styles.container}>
            <Text category="h5" style={styles.title}>VENDORS</Text>
            <Input
                placeholder="SEARCH / filters"
                value={searchQuery}
                onChangeText={setSearchQuery}
                style={styles.searchInput}
            />
            <ScrollView contentContainerStyle={styles.cardContainer}>
                {filteredVendors.map(renderVendorCard)}
            </ScrollView>
        </Layout>
    );
};

const getStyles = (theme: any) => StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
    },
    title: {
        marginBottom: 16,
    },
    searchInput: {
        marginBottom: 16,
    },
    cardContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    card: {
        width: '48%',
        marginBottom: 16,
        backgroundColor: theme['color-basic-900'],
    },
    cardHeader: {
        padding: 16,
    },
    cardDescription: {
        marginBottom: 8,
    },
    cardType: {
        marginTop: 8,
        fontStyle: 'italic',
    },
});
