import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle, Layout } from '@ui-kitten/components';
import { CompanyAppOptions } from '../../models/Company.model';

interface DriverAppOptionsProps {
    appOptions: CompanyAppOptions;
    onUpdateAppOptions: (updatedOptions: CompanyAppOptions) => void;
    allowDriverCreateJob: boolean;
    onUpdateAllowDriverCreateJob: (allow: boolean) => void;
    style?: any;
}

export const DriverAppOptions: React.FC<DriverAppOptionsProps> = ({
    appOptions,
    onUpdateAppOptions,
    allowDriverCreateJob,
    onUpdateAllowDriverCreateJob,
    style = {}
}) => {
    const updateAppOption = (key: keyof CompanyAppOptions, value: boolean) => {
        const updatedOptions = new CompanyAppOptions({ ...appOptions, [key]: value });
        onUpdateAppOptions(updatedOptions);
    };

    const updateSignatures = (show: boolean) => {
        const updatedOptions = new CompanyAppOptions({
            ...appOptions,
            show_signatures: show,
            show_pre_inspection_signatures: show,
            show_post_inspection_signatures: show
        });
        onUpdateAppOptions(updatedOptions);
    };

    const updateImages = (show: boolean) => {
        const updatedOptions = new CompanyAppOptions({
            ...appOptions,
            show_images: show,
            require_pre_inspection_images: show,
            require_post_inspection_images: show
        });
        onUpdateAppOptions(updatedOptions);
    };

    const RenderToggleOption = useMemo(() => (label: string, checked: boolean, onChange: Function) => (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Toggle
                checked={checked}
                onChange={(nextChecked: boolean) => onChange(nextChecked)}
            >
                {label}
            </Toggle>
        </View>
    ), [appOptions]);

    /**
     *     show_pre_inspection = true
    show_post_inspection = true
    show_vehicle_indemnity = true
    show_services = true
    show_inventory = true
    show_payment = true
    show_images = true

    show_signatures = true // replacing with the 2 below
    show_pre_inspection_signatures = true
    show_post_inspection_signatures = true

    require_driver_comments = true
    require_pre_inspection_images = true
    require_post_inspection_images = true

    require_pre_inspection_signatures = true
    require_post_inspection_signatures = true

    allow_edit_customer_details = true
    allow_edit_vehicle_details = true
    allow_edit_services = true
    allow_edit_inventory = true
    allow_edit_payment = true
     */

    return (
        <Card
            style={[style, { flex: 1 }]}
            disabled
            header={() => <Text category='h6'>Driver App Options</Text>}
            status="basic"
        >
            <Layout style={{ flex: 1, flexDirection: 'column', gap: 3 }}>
                {RenderToggleOption('Allow Driver To Create Jobs In App', allowDriverCreateJob, (nextChecked: boolean) => onUpdateAllowDriverCreateJob(nextChecked))}
                {RenderToggleOption('Show Signatures', appOptions.show_signatures, (nextChecked: boolean) => updateSignatures(nextChecked))}

                {RenderToggleOption('Show Pre-Inspection', appOptions.show_pre_inspection, (nextChecked: boolean) => updateAppOption('show_pre_inspection', nextChecked))}
                {appOptions.show_pre_inspection && appOptions.show_signatures &&
                    <Card>
                        {RenderToggleOption('Show Pre-Inspection Signatures', appOptions.show_pre_inspection_signatures, (nextChecked: boolean) => updateAppOption('show_pre_inspection_signatures', nextChecked))}
                    </Card>
                }
                {RenderToggleOption('Show Post-Inspection', appOptions.show_post_inspection, (nextChecked: boolean) => updateAppOption('show_post_inspection', nextChecked))}
                {appOptions.show_post_inspection && appOptions.show_signatures &&
                    <Card>
                        {RenderToggleOption('Show Post-Inspection Signatures', appOptions.show_post_inspection_signatures, (nextChecked: boolean) => updateAppOption('show_post_inspection_signatures', nextChecked))}
                    </Card>
                }
                {RenderToggleOption('Show Vehicle Indemnity', appOptions.show_vehicle_indemnity, (nextChecked: boolean) => updateAppOption('show_vehicle_indemnity', nextChecked))}
                {RenderToggleOption('Show Services', appOptions.show_services, (nextChecked: boolean) => updateAppOption('show_services', nextChecked))}
                {appOptions.show_services && (
                    <Card>
                        {RenderToggleOption('Allow Edit Services', appOptions.allow_edit_services, (nextChecked: boolean) => updateAppOption('allow_edit_services', nextChecked))}
                    </Card>
                )}
                {RenderToggleOption('Show Inventory', appOptions.show_inventory, (nextChecked: boolean) => updateAppOption('show_inventory', nextChecked))}
                {appOptions.show_inventory && (
                    <Card>
                        {RenderToggleOption('Allow Edit Inventory', appOptions.allow_edit_inventory, (nextChecked: boolean) => updateAppOption('allow_edit_inventory', nextChecked))}
                    </Card>
                )}
                {RenderToggleOption('Show Payment', appOptions.show_payment, (nextChecked: boolean) => updateAppOption('show_payment', nextChecked))}
                {appOptions.show_payment && (
                    <Card>
                        {RenderToggleOption('Allow Edit Payment', appOptions.allow_edit_payment, (nextChecked: boolean) => updateAppOption('allow_edit_payment', nextChecked))}
                    </Card>
                )}
                {RenderToggleOption('Require Driver Comments', appOptions.require_driver_comments, (nextChecked: boolean) => updateAppOption('require_driver_comments', nextChecked))}
                {RenderToggleOption('Show Images', appOptions.show_images, (nextChecked: boolean) => updateImages(nextChecked))}
                {appOptions.show_images && (
                    <Card>
                        {RenderToggleOption('Require Pre Inspection Images', appOptions.require_pre_inspection_images, (nextChecked: boolean) => updateAppOption('require_pre_inspection_images', nextChecked))}
                        {RenderToggleOption('Require Post Inspection Images', appOptions.require_post_inspection_images, (nextChecked: boolean) => updateAppOption('require_post_inspection_images', nextChecked))}
                    </Card>
                )}
                {RenderToggleOption('Allow Edit Customer Details', appOptions.allow_edit_customer_details, (nextChecked: boolean) => updateAppOption('allow_edit_customer_details', nextChecked))}
                {RenderToggleOption('Allow Edit Vehicle Details', appOptions.allow_edit_vehicle_details, (nextChecked: boolean) => updateAppOption('allow_edit_vehicle_details', nextChecked))}
            </Layout>
        </Card>
    );
};
