import React, { useState } from 'react';
import { Layout, Card, Text, Toggle, Input } from '@ui-kitten/components';
import { DriverSettings } from '../../models/Company.model';

interface ServiceSettingsProps {
    driverSettings: DriverSettings;
    onUpdateDriverSettings: (updatedSettings: DriverSettings) => void;
    style?: any;
}

export const ServiceSettings: React.FC<ServiceSettingsProps> = ({
    driverSettings,
    onUpdateDriverSettings,
    style = {}
}) => {
    const updateDriverSetting = (key: keyof DriverSettings, value: any) => {
        const updatedSettings = new DriverSettings({ ...driverSettings, [key]: value });
        onUpdateDriverSettings(updatedSettings);
    };

    const [isConcurrentJobsPerDriverLimitError, setIsConcurrentJobsPerDriverLimitError] = useState<boolean>(false);

    return (
        <Card
            disabled
            header={() => <Text category='h6'>Service Settings</Text>}
            status="basic"
            style={[style, { flex: 1 }]}
        >
            <Layout style={{ flex: 1, flexDirection: 'column' }}>
                <Toggle
                    checked={driverSettings.enable_concurrent_jobs_per_driver_limit || false}
                    onChange={(nextChecked: boolean) => {
                        updateDriverSetting('enable_concurrent_jobs_per_driver_limit', nextChecked);
                    }}
                >
                    Enable Concurrent Jobs Per Driver Limit
                </Toggle>
                {driverSettings.enable_concurrent_jobs_per_driver_limit && (
                    <Layout style={{ flexDirection: 'row' }}>
                        <Input
                            label="Jobs Per Driver"
                            placeholder="Jobs Per Driver"
                            defaultValue={driverSettings.concurrent_jobs_per_driver.toString()}
                            onChangeText={(nextValue: string) => {
                                const num = parseInt(nextValue) || 1;
                                updateDriverSetting('concurrent_jobs_per_driver', num);
                                if (isNaN(nextValue as unknown as number)) {
                                    setIsConcurrentJobsPerDriverLimitError(true);
                                } else {
                                    setIsConcurrentJobsPerDriverLimitError(false);
                                }
                            }}
                            caption={isConcurrentJobsPerDriverLimitError ? 'Invalid number changes will not be saved' : ''}
                            status={isConcurrentJobsPerDriverLimitError ? 'danger' : 'basic'}
                        />
                    </Layout>
                )}
            </Layout>
        </Card>
    );
};
