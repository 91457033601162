import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    Radio,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import AssignJobList from '../assignJobList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import { ServicesContainer } from '../services/servicesContainer.component';
import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
//@ts-ignore
import DatePicker from 'react-datepicker';
//@ts-ignore
import CalendarContainer from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../ErrorBoundary.component';
import { JobDetailImages } from '../jobDetailsImages.component';
import { event } from 'react-native-reanimated';
import NotificationContext from '../../../context/notifications.context';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
// import AddressSearch from './addressSearch.component';
import { JobDetailMap } from './mapSelectLocation.component';


interface TimeDetailsCardProps {
    start_time: number | null;
    accepted_time: number | null;
    driver_on_route_time: number | null;
    arrived_time: number | null;
    pending_time: number | null;
    onSet_start_time: (time: number) => void;
    onSet_accepted_time: (time: number) => void;
    onSet_driver_on_route_time: (time: number) => void;
    onSet_arrived_time: (time: number) => void;
    onSet_pending_time: (time: number) => void;
    allowEdit: boolean;
    style?: any;
}

export const TimeDetailsCard = ({
    start_time,
    accepted_time,
    driver_on_route_time,
    arrived_time,
    pending_time,
    onSet_start_time,
    onSet_accepted_time,
    onSet_driver_on_route_time,
    onSet_arrived_time,
    onSet_pending_time,
    allowEdit,
    style
}: TimeDetailsCardProps) => {

    const [editTimes, setEditTimes] = useState(false)
    const [editCreatedTime, setEditCreatedTime] = useState(false)
    const [editAcceptedTime, setEditAcceptedTime] = useState(false)
    const [editOnRouteTime, setEditOnRouteTime] = useState(false)
    const [editArrivedTime, setEditArrivedTime] = useState(false)
    const [editPendingTime, setEditPendingTime] = useState(false)



    const RenderCreatedDateTimePicker = () => {
        return (
            <Layout style={{ flexDirection: 'column' }}>
                <Layout style={{ flexDirection: 'row' }}>
                    <Button
                        disabled={!editTimes}
                        onPress={() => setEditCreatedTime(!editCreatedTime)}
                        size='tiny'
                    >
                        {/* {createdAt && new Date(createdAt).toLocaleString( */}
                        {start_time && new Date(start_time).toLocaleString(
                            'en-AU',
                            {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            }
                        )}
                    </Button>
                    <Layout />
                </Layout>

            </Layout>
        )
    }


    const RenderAcceptedDateTimePicker = () => {
        return (
            <Layout style={{ flexDirection: 'column' }}>
                <Layout style={{ flexDirection: 'row' }}>
                    <Button
                        disabled={!editTimes}
                        onPress={() => setEditAcceptedTime(!editAcceptedTime)}
                        size='tiny'
                    >
                        {accepted_time && new Date(accepted_time).toLocaleString(
                            'en-AU',
                            {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            }
                        )}
                    </Button>
                    <Layout />
                </Layout>

            </Layout>
        )
    }

    const RenderOnRouteDateTimePicker = () => {
        return (
            <Layout style={{ flexDirection: 'column' }}>
                <Layout style={{ flexDirection: 'row' }}>
                    <Button
                        disabled={!editTimes}
                        onPress={() => setEditOnRouteTime(!editOnRouteTime)}
                        size='tiny'
                    >
                        {driver_on_route_time && new Date(driver_on_route_time).toLocaleString(
                            'en-AU',
                            {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            }
                        )}
                    </Button>
                    <Layout />
                </Layout>

            </Layout>
        )
    }

    const RenderArrivedDateTimePicker = () => {
        return (
            <Layout style={{ flexDirection: 'column' }}>
                <Layout style={{ flexDirection: 'row' }}>
                    <Button
                        disabled={!editTimes}
                        onPress={() => setEditArrivedTime(!editArrivedTime)}
                        size='tiny'
                    >
                        {arrived_time && new Date(arrived_time).toLocaleString(
                            'en-AU',
                            {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            }
                        )}
                    </Button>
                    <Layout />
                </Layout>

            </Layout>
        )

    }

    const RenderPendingDateTimePicker = () => {
        return (
            <Layout style={{ flexDirection: 'column' }}>
                <Layout style={{ flexDirection: 'row' }}>
                    <Button
                        disabled={!editTimes}
                        onPress={() => setEditPendingTime(!editPendingTime)}
                        size='tiny'
                    >
                        {pending_time && new Date(pending_time).toLocaleString(
                            'en-AU',
                            {
                                day: '2-digit',
                                month: 'short',
                                year: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            }
                        )}
                    </Button>
                    <Layout />
                </Layout>

            </Layout>
        )

    }

    return (
        <Card
            disabled
            header={() => <Text style={{ alignSelf: 'center' }}>Times</Text>}
            style={[style, {}]}
            status='primary'
        >
            <Text style={styles.header}>
                {allowEdit &&
                    <Button onPress={() => { setEditTimes(!editTimes) }} appearance="ghost" status="info" size='tiny'>Edit</Button>
                }
            </Text>
            <View style={styles.row}>
                <View style={styles.column1}>
                    <Text style={styles.label}>
                        Created At:
                    </Text>
                </View>
                <View style={styles.column}>
                    <RenderCreatedDateTimePicker />
                    {editCreatedTime &&
                        <DatePicker
                            selected={start_time ? new Date(start_time) : null}
                            onChange={(date: Date) => {
                                const timestamp = date.getTime()
                                onSet_start_time(timestamp)
                            }}
                            showTimeInput
                            inline
                            dateFormat="d MMMM, yyyy h:mm aa"
                        />
                    }
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.column1}>
                    <Text style={styles.label}>
                        Accepted At:
                    </Text>
                </View>
                <View style={styles.column}>
                    <RenderAcceptedDateTimePicker />
                    {editAcceptedTime &&
                        <DatePicker
                            selected={accepted_time ? new Date(accepted_time) : null}
                            onChange={(date: Date) => {
                                const timestamp = date.getTime()
                                onSet_accepted_time(timestamp)
                            }}
                            showTimeInput
                            inline
                            dateFormat="d MMMM, yyyy h:mm aa"
                        />
                    }
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.column1}>
                    <Text style={styles.label}>
                        On Route At:
                    </Text>
                </View>
                <View style={styles.column}>
                    <RenderOnRouteDateTimePicker />
                    {editOnRouteTime &&
                        <DatePicker
                            selected={driver_on_route_time ? new Date(driver_on_route_time) : null}
                            onChange={(date: Date) => {
                                const timestamp = date.getTime()
                                onSet_driver_on_route_time(timestamp)
                            }}
                            showTimeInput
                            inline
                            dateFormat="d MMMM, yyyy h:mm aa"
                        />
                    }
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.column1}>
                    <Text style={styles.label}>
                        Arrived At:
                    </Text>
                </View>
                <View style={styles.column}>
                    <RenderArrivedDateTimePicker />
                    {editArrivedTime &&
                        <DatePicker
                            disabled={!editTimes}
                            selected={arrived_time ? new Date(arrived_time) : null}
                            onChange={(date: Date) => {
                                const timestamp = date.getTime()
                                onSet_arrived_time(timestamp)
                            }}
                            showTimeInput
                            inline
                            dateFormat="d MMMM, yyyy h:mm aa"
                        />
                    }
                </View>
            </View>
            <View style={styles.row}>
                <View style={styles.column1}>
                    <Text style={styles.label}>
                        Driver Complete Time:
                    </Text>
                </View>
                <View style={styles.column}>
                    <RenderPendingDateTimePicker />
                    {editPendingTime &&
                        <DatePicker
                            disabled={!editTimes}
                            selected={pending_time ? new Date(pending_time) : null}
                            onChange={(date: Date) => {
                                const timestamp = date.getTime()
                                onSet_pending_time(timestamp)
                            }}
                            showTimeInput
                            inline
                            dateFormat="d MMMM, yyyy h:mm aa"
                        />
                    }
                </View>
            </View>

        </Card>
    )

}


const styles = StyleSheet.create({
    container1: {
        margin: 5,
        marginTop: 30,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        padding: 10,
    },
    header: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    row: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    column: {
        flex: 2,
        borderWidth: 1,
        borderColor: 'gray',
        padding: 5,
    },
    column1: {
        flex: 1,
        borderWidth: 1,
        borderColor: 'gray',
        padding: 5,
    },
    label: {
        fontWeight: 'bold',
    },
    // table stuff ^_
    container: {
        maxHeight: "100%",
    },
    contentContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    listContainer: {
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: 0,
        margin: 0
    },
    item: {
        marginVertical: 4,
    },
    side_section: {
        flex: 1,
        justifyContent: 'center',
        display: 'flex'
    },
    tinyLogo: {
        width: 100,
        height: 100,
    },
    image: {
        width: 200,
        height: 200,
    },
    images: {
        flex: 1,
        flexDirection: 'column',
    }
})
