import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet, Alert, Modal, TouchableWithoutFeedback, useWindowDimensions } from "react-native";
import * as StorageController from '../../functions/storageController';
import { Layout, Text, List, ListItem, Divider, Spinner, Button, Input, useTheme, Icon, CheckBox } from '@ui-kitten/components';
import * as InventoryController from '../../functions/Inventory.controller';
import { InventoryCategory, InventoryItem } from '../../models/Inventory.model';
import InventoryItemDetail from './InventoryItemDetail.component';
import { IAction, useAppStateChange } from '../../hooks/appStateChange.hook';
import Toast from 'react-native-toast-message';
import { PricingTierManager } from './PricingTierManager.component';
import { StockManager } from './StockManager.component';
import DMModal from '../common/Modal';
import { LocationManager } from './LocationManager.component';
import { BulkItemImport } from './BulkItemImport.component';

export const InventoryCategoryScreen = ({ navigation }: any) => {
    const [categories, setCategories] = useState<InventoryCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<InventoryCategory | null>(null);
    const [items, setItems] = useState<InventoryItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    // State for Add Category Modal
    const [addCategoryModalVisible, setAddCategoryModalVisable] = useState<boolean>(false);
    const [newCategory, setNewCategory] = useState<{
        name: string;
        description: string;
    }>({
        name: '',
        description: '',
    });
    const [adding, setAdding] = useState<boolean>(false);
    // State for Add Item Modal
    const [newItem, setNewItem] = useState<{
        name: string;
        price: number;
        description: string;
        sku: string | null;
    }>({
        name: '',
        price: 0,
        description: '',
        sku: null,
    });
    const [addItemModalVisible, setAddItemModalVisible] = useState<boolean>(false);
    const [editingItem, setEditingItem] = useState<InventoryItem | null>(null); // State for the item being edited
    const [confirmRemoveModalVisible, setConfirmRemoveModalVisible] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<InventoryItem | null>(null); // State for the item to remove
    const [editingCategory, setEditingCategory] = useState<InventoryCategory | null>(null); // State for the category being edited
    const [searchQuery, setSearchQuery] = useState<string>(''); // Add state for search query
    const [selectedItems, setSelectedItems] = useState<string[]>([]); // State for selected items
    const [moveModalVisible, setMoveModalVisible] = useState<boolean>(false); // State for move modal
    const [targetCategory, setTargetCategory] = useState<InventoryCategory | null>(null); // State for target category
    const [showPricingTierManager, setShowPricingTierManager] = useState(false);
    const [showStockManager, setShowStockManager] = useState(false);
    const [showLocationManager, setShowLocationManager] = useState(false);
    const { width, height } = useWindowDimensions();

    const [isItemPriceError, setIsItemPriceError] = useState<boolean>(false);
    const [isItemNameError, setIsItemNameError] = useState<boolean>(false);

    const [isCategoryNameError, setIsCategoryNameError] = useState<boolean>(false);

    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState<InventoryCategory | null>(null);

    const theme = useTheme();
    const [showBulkImport, setShowBulkImport] = useState(false);

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleCompanyChangeEvent = useCallback((action: IAction) => {
        setCategories([]);
        setItems([]);
        setSelectedCategory(null);
        setSelectedItems([]);
        fetchCategories();
    }, []);


    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })


    const fetchCategories = async () => {
        setLoading(true);
        const companyId = StorageController.getCurrentCompany()._id;
        const fetchedCategories = await InventoryController.getInventoryCategoriesByCompanyId(companyId);
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:21 ~ fetchCategories ~ fetchedCategories🚀==============", fetchedCategories)
        if (fetchedCategories) {
            setCategories(fetchedCategories.filter((category: InventoryCategory) => !category.deleted));
        }
        setLoading(false);
    }
    const fetchItems = async (categoryId: string) => {
        try {
            setLoading(true);
            const fetchedItems = await InventoryController.getInventoryItemsByCategory(categoryId);
            console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:31 ~ fetchItems ~ fetchedItems🚀==============", fetchedItems)
            if (fetchedItems) {
                setItems(fetchedItems);
            }
            setLoading(false);
        } catch (error) {
            console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:78 ~ fetchItems ~ error🚀==============", error)
            setLoading(false);
        }
    }
    const renderCategory = ({ item }: { item: InventoryCategory }) => (
        <ListItem
            title={item.name}
            description={item.description ?? ''}
            style={{ backgroundColor: item._id == selectedCategory?._id ? theme['color-success-100'] : theme['color-background-basic'] }}
            onPress={() => {
                setSelectedCategory(item);
                setItems([]);
                fetchItems(item._id!);
            }}
            accessoryLeft={<Icon name='folder-outline' fill={theme['color-primary-500']} />}
            accessoryRight={
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    <Button
                        appearance='ghost'
                        status='primary'
                        size='small'
                        accessoryLeft={<Icon name='edit-outline' />}
                        onPress={() => {
                            openEditCategoryModal(item);
                        }}
                    />
                    {/* <Button
                        appearance='ghost'
                        status='danger'
                        size='small'
                        accessoryLeft={<Icon name='trash-2-outline' />}
                        onPress={() => {
                            setCategoryToDelete(item);
                            setShowDeleteCategoryModal(true);
                        }}
                    /> */}
                </View>
            }
            onLongPress={() => openEditCategoryModal(item)}
        />
    );
    const renderItem = ({ item }: { item: InventoryItem }) => (
        <View style={{ flexDirection: 'row' }}>
            {/* <View style={{ flexDirection: 'row' }}>
                 <CheckBox
                    checked={selectedItems.includes(item._id!)}
                    onChange={() => toggleSelectItem(item._id!)}
                    // style={{ position: 'absolute', top: 10, left: 10 }}
                />
            </View> */}
            <View style={{ flex: 1 }}>
                <InventoryItemDetail
                    item={item}
                    onEdit={() => {
                        setEditingItem(item);
                        setNewItem({ name: item.name, price: item.default_price, description: item.description ?? '', sku: item.sku ?? '' });
                        setAddItemModalVisible(true); // Open modal for editing
                    }}
                    onRemove={() => {
                        setItemToRemove(item); // Set the item to remove
                        setConfirmRemoveModalVisible(true); // Show confirmation modal
                    }}
                    isSelected={selectedItems.includes(item._id!)}
                    onSelect={() => toggleSelectItem(item._id!)}
                />
            </View>
        </View>
    );

    const toggleSelectItem = (itemId: string) => {
        setSelectedItems(prev =>
            prev.includes(itemId) ? prev.filter(id => id !== itemId) : [...prev, itemId]
        );
    };

    const handleMoveItems = async () => {
        if (!targetCategory) return;
        await Promise.all(selectedItems.map(async (itemId) => {
            const item = items.find(i => i._id === itemId);
            if (item) {
                const updatedItem = new InventoryItem({ ...item, inventory_category_id: targetCategory._id });
                await InventoryController.updateInventoryItem(updatedItem);
            }
        }));
        setSelectedItems([]);
        setMoveModalVisible(false);
        await fetchItems(selectedCategory!._id!);
        Toast.show({ text1: 'Items moved successfully', type: 'success' });
    };




    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CATEGORIES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    // Function to handle adding a new category
    const handleAddCategory = async () => {
        if (newCategory.name.trim() === '') {
            return;
        }
        setAdding(true);
        const companyId = StorageController.getCurrentCompany()._id;
        const categoryToAdd: InventoryCategory = new InventoryCategory({
            name: newCategory.name,
            description: newCategory.description,
            company_id: companyId,
            details: {},
            is_public: false, // Set a default value
            pricing_tiers: [], // Initialize with an empty array
        });
        const addedCategory = await InventoryController.createInventoryCategory(categoryToAdd);
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:72 ~ handleAddCategory ~ addedCategory🚀==============", addedCategory)
        if (addedCategory) {
            setCategories([...categories, addedCategory]);
            onCloseAddCategoryModal();
            await fetchCategories();
        }
        setAdding(false);
    }
    // Function to handle editing a category
    const handleEditCategory = async () => {
        if (!editingCategory) return; // Ensure there's a category to edit
        const updatedCategory: InventoryCategory = {
            ...editingCategory,
            name: newCategory.name,
            description: newCategory.description ?? '',
        };
        const result = await InventoryController.updateInventoryCategory(updatedCategory); // Assume this function exists
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:106 ~ handleEditCategory ~ result🚀==============", result)
        if (result) {
            setCategories(categories.map(category => (category._id === updatedCategory._id ? updatedCategory : category))); // Update the category in the list
            onCloseAddCategoryModal(); // Close modal after editing
        }
    };



    // Modify the modal to set the editing category when opening
    const openEditCategoryModal = (category: InventoryCategory) => {
        setEditingCategory(category);
        setNewCategory({ name: category.name, description: category.description ?? '' });
        setAddCategoryModalVisable(true);
    }

    const onCloseAddCategoryModal = () => {
        setAddCategoryModalVisable(false);
        setNewCategory({ name: '', description: '' });
        setEditingCategory(null); // Reset editing state
    }



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// ITEMS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    // Function to handle adding a new item
    const handleAddItem = async () => {
        if (newItem.name.trim() === '' || newItem.price <= 0) {
            return; // Add validation as needed
        }
        const itemToAdd: InventoryItem = new InventoryItem({
            name: newItem.name,
            default_price: newItem.price,
            inventory_category_id: selectedCategory?._id!,
            company_id: StorageController.getCurrentCompany()._id,
            description: newItem.description,
            sku: newItem.sku ?? '',
            details: {},
            purchase_price: 0, // Set a default value
            default_sale_price: newItem.price, // Use the input price as default sale price
            is_public: false, // Set a default value
        });
        const addedItem = await InventoryController.createInventoryItem(itemToAdd);
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:106 ~ handleAddItem ~ addedItem🚀==============", addedItem)
        if (addedItem) {
            setItems([...items, addedItem]);
            setAddItemModalVisible(false);
            setNewItem({ name: '', price: 0, description: '', sku: null }); // Reset new item state
            await fetchItems(selectedCategory?._id!);
        }
    };
    const handleEditItem = async () => {
        if (!editingItem) return; // Ensure there's an item to edit
        const updatedItem: InventoryItem = new InventoryItem({
            ...editingItem,
            name: newItem.name,
            default_price: newItem.price,
            description: newItem.description,
            sku: newItem.sku ?? '',
        });
        const result = await InventoryController.updateInventoryItem(updatedItem);
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:106 ~ handleEditItem ~ result🚀==============", result)
        if (result) {
            setItems(items.map(item => (item._id === updatedItem._id ? updatedItem : item)));
            setAddItemModalVisible(false);
            resetItemForm();
            setEditingItem(null);
        }
    };
    const resetItemForm = () => {
        setNewItem({ name: '', price: 0, description: '', sku: null });
        setEditingItem(null); // Reset editing state
    };

    const handleRemoveItem = async () => {
        if (!itemToRemove || !itemToRemove._id) return; // Ensure there's an item to remove
        const result = await InventoryController.deleteInventoryItem(itemToRemove._id); // Assume this function exists
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:106 ~ handleRemoveItem ~ result🚀==============", result)
        if (result) {
            setItems(items.filter(item => item._id !== itemToRemove._id)); // Remove the item from the list
            setConfirmRemoveModalVisible(false); // Close the confirmation modal
            setItemToRemove(null); // Reset the item to remove
        }
    };

    const onCloseAddItemModal = () => {
        setAddItemModalVisible(false);
        setNewItem({ name: '', price: 0, description: '', sku: null });
    }

    // Add this function to handle saving updated category
    const handleSaveCategory = async (updatedCategory: InventoryCategory) => {
        console.log("🚀============== ~ file: inventoryCategoryScreen.component.tsx:312 ~ handleSaveCategory ~ updatedCategory🚀==============", updatedCategory)
        try {
            const result = await InventoryController.updateInventoryCategory(updatedCategory);
            if (result) {
                setCategories(categories.map(cat => cat._id === updatedCategory._id ? updatedCategory : cat));
                // if the category saved is the selected category, then update the selected category
                if (selectedCategory && selectedCategory._id === updatedCategory._id) {
                    setSelectedCategory(updatedCategory);
                }
                setShowPricingTierManager(false);
                Toast.show({
                    type: 'success',
                    text1: 'Category updated successfully',
                });
            }
        } catch (error) {
            console.error('Error updating category:', error);
            Toast.show({
                type: 'error',
                text1: 'Failed to update category',
            });
        }
    };

    // Add new function to handle category deletion
    const handleDeleteCategory = async () => {
        if (!categoryToDelete?._id) return;

        // First check if category has items
        const categoryItems = await InventoryController.getInventoryItemsByCategory(categoryToDelete._id);

        if (categoryItems && categoryItems.length > 0) {
            Toast.show({
                type: 'error',
                text1: 'Cannot delete category',
                text2: 'Remove all items from this category first'
            });
            setShowDeleteCategoryModal(false);
            return;
        }

        // If no items, proceed with deletion
        const success = await InventoryController.deleteInventoryCategory(categoryToDelete._id);

        if (success) {
            setCategories(categories.filter(cat => cat._id !== categoryToDelete._id));
            if (selectedCategory?._id === categoryToDelete._id) {
                setSelectedCategory(null);
                onCloseAddCategoryModal();
                setItems([]);
            }
            Toast.show({
                type: 'success',
                text1: 'Category deleted successfully'
            });
        } else {
            Toast.show({
                type: 'error',
                text1: 'Failed to delete category'
            });
        }

        setShowDeleteCategoryModal(false);
        setCategoryToDelete(null);
    }

    const handleBulkImport = async (items: InventoryItem[]) => {
        setLoading(true);
        try {
            const BATCH_SIZE = 5; // Process 50 items at a time
            const batches = [];

            // Split items into batches
            for (let i = 0; i < items.length; i += BATCH_SIZE) {
                batches.push(items.slice(i, i + BATCH_SIZE));
            }

            let successfulImports: InventoryItem[] = [];
            let processedCount = 0;

            // Process each batch
            for (const batch of batches) {
                const results = await Promise.all(
                    batch.map(item => InventoryController.createInventoryItem(item))
                );

                const successful = results.filter(result => result !== null);
                successfulImports = [...successfulImports, ...successful];

                processedCount += batch.length;
                // Update progress through callback
                onBulkImportProgress(processedCount, items.length);
            }

            setItems(prev => [...prev, ...successfulImports]);

            Toast.show({
                type: 'success',
                text1: 'Items imported successfully',
                text2: `${successfulImports.length}/${items.length} items imported`,
            });

            setShowBulkImport(false);
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: 'Error importing items',
                text2: error instanceof Error ? error.message : 'Unknown error',
            });
        }
        setLoading(false);
    };

    // Add progress callback function
    const onBulkImportProgress = (processed: number, total: number) => {
        Toast.show({
            type: 'info',
            text1: 'Import Progress',
            text2: `Processed ${processed}/${total} items`,
            visibilityTime: 1000,
            autoHide: true,
        });
    };

    return (
        <View style={{ flexDirection: 'row', flex: 1 }}>
            <View style={{ flex: 1, padding: 10 }}>
                <Text category='h6' style={styles.header}>Categories</Text>
                <Button style={styles.addButton} onPress={() => setAddCategoryModalVisable(true)}>
                    Add Category
                </Button>
                <List
                    data={categories}
                    renderItem={renderCategory}
                    ItemSeparatorComponent={Divider}
                    style={styles.list}
                />
            </View>
            <View style={{ flex: 4, padding: 10 }}>
                {selectedCategory && (
                    <>
                        <Text category='h6' style={styles.header}>Items in {selectedCategory.name}</Text>
                        <View style={{ marginBottom: 10, flexDirection: 'row', gap: 10 }}>
                            <Button onPress={() => setShowPricingTierManager(true)}>
                                Manage Pricing Tiers
                            </Button>
                            <Button onPress={() => setShowStockManager(true)}>
                                Manage Stock Levels
                            </Button>
                            <Button onPress={() => setShowLocationManager(true)}>
                                Manage Locations
                            </Button>
                        </View>
                        <View style={{ marginBottom: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 10 }}>
                            <Button style={styles.addButton} onPress={() => {
                                resetItemForm(); // Reset the form before opening the modal
                                setAddItemModalVisible(true);
                            }}
                                size='small'
                                appearance='filled'
                                status='success'
                                disabled={!selectedCategory}
                                accessoryLeft={<Icon name='plus-outline' />}
                            >
                                Add Item
                            </Button>
                            <Button
                                size='small'
                                appearance='outline'
                                status='primary'
                                disabled={!selectedCategory}
                                accessoryLeft={<Icon name='file-add-outline' />}
                                onPress={() => setShowBulkImport(true)}
                            >
                                Bulk Import
                            </Button>
                            <View style={{ flexDirection: 'row', flex: 1 }}>

                                <Input
                                    placeholder='Search items...'
                                    value={searchQuery}
                                    onChangeText={setSearchQuery} // Update search query
                                    style={{ flex: 1 }}
                                />
                                <Button
                                    appearance='ghost'
                                    status='basic'
                                    onPress={() => setSearchQuery('')} // Clear search query
                                >
                                    Clear
                                </Button>
                            </View>
                            <Button
                                size='small'
                                disabled={selectedItems.length === 0}
                                onPress={() => setMoveModalVisible(true)}>
                                Move to Another Category
                            </Button>
                        </View>
                        {loading ? (
                            <Spinner size='large' />
                        ) : (
                            <List
                                data={items.filter(item =>
                                    searchQuery === '' || item.name.toLowerCase().includes(searchQuery.toLowerCase()) // Show all if searchQuery is empty
                                )}
                                renderItem={renderItem}
                                ItemSeparatorComponent={Divider}
                                style={styles.list}
                            />
                        )}

                    </>
                )}
            </View>
            <DMModal
                visible={addCategoryModalVisible}
                onClose={onCloseAddCategoryModal}
            >
                <View style={styles.modalContent}>
                    <Layout style={styles.modalContainer}>
                        <Text category='h6' style={styles.modalHeader}>{editingCategory ? 'Edit Category' : 'Add New Category'}</Text>
                        <View style={{ position: 'absolute', top: 0, right: 0 }}>
                            <View style={{ flexDirection: 'row' }}>

                                {editingCategory &&
                                    <Button
                                        appearance='ghost'
                                        status='danger'
                                        size='small'
                                        accessoryLeft={<Icon name='trash-2-outline' />}
                                        onPress={() => {
                                            setCategoryToDelete(editingCategory);
                                            setShowDeleteCategoryModal(true);
                                        }}
                                    />
                                }
                                <Button
                                    appearance='filled'
                                    size='small'
                                    status='danger'
                                    accessoryLeft={<Icon name='close-outline' />}
                                    onPress={onCloseAddCategoryModal}
                                    style={styles.submitButton}
                                />
                            </View>
                        </View>
                        <Input
                            label='Category Name'
                            placeholder='Enter category name'
                            value={newCategory.name}
                            onChangeText={(text) => {
                                setNewCategory({ ...newCategory, name: text }) // Update name
                                if (text.length < 3) {
                                    setIsCategoryNameError(true)
                                } else {
                                    setIsCategoryNameError(false)
                                }
                            }} // Update name
                            style={styles.input}
                            caption={isCategoryNameError ? 'Category name must be at least 3 characters' : ''}
                            status={isCategoryNameError ? 'danger' : 'basic'}
                        />
                        <Input
                            label='Description'
                            placeholder='Enter description (optional)'
                            value={newCategory.description}
                            onChangeText={(text) => setNewCategory({ ...newCategory, description: text })} // Update description
                            style={styles.input}
                            multiline={true}
                        />
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button
                                appearance='ghost'
                                status='danger'
                                size='small'
                                onPress={() => {
                                    onCloseAddCategoryModal();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                onPress={editingCategory ? handleEditCategory : handleAddCategory}
                                disabled={adding || isCategoryNameError}
                                style={styles.submitButton}
                            >
                                {editingCategory ? 'Save Changes' : 'Add Category'}
                            </Button>
                        </View>

                    </Layout>
                </View>
            </DMModal>
            <DMModal
                visible={addItemModalVisible}
                onClose={() => onCloseAddItemModal()}
            >

                <View style={styles.modalContent}>
                    <Layout style={styles.modalContainer}>
                        <Text category='s1'>
                            {editingItem ? 'Edit Item' : 'Add New Item'}
                        </Text>
                        <View style={{ position: 'absolute', top: 0, right: 0 }}>
                            <Button
                                appearance='filled'
                                size='small'
                                status='danger'
                                accessoryLeft={<Icon name='close-outline' />}
                                onPress={onCloseAddItemModal}
                                style={styles.submitButton}
                            />
                        </View>
                        <Input
                            label='Item Name'
                            placeholder='Enter item name'
                            value={newItem.name}
                            onChangeText={(text) => {
                                setNewItem({ ...newItem, name: text }) // Update name
                                if (text.length < 3) {
                                    setIsItemNameError(true)
                                } else {
                                    setIsItemNameError(false)
                                }
                            }} // Update name
                            style={styles.input}
                            caption={isItemNameError ? 'Item name must be at least 3 characters' : ''}
                            status={isItemNameError ? 'danger' : 'basic'}
                        />
                        <Input
                            label='Price'
                            placeholder='Enter item price'
                            value={newItem.price?.toString()}
                            onChangeText={(text) => {
                                setNewItem({ ...newItem, price: text as unknown as number }) // Update price
                                if (isNaN(text as unknown as number)) {
                                    setIsItemPriceError(true)
                                } else {
                                    setIsItemPriceError(false)
                                }
                            }} // Update price
                            style={styles.input}
                            keyboardType='numeric'
                            caption={isItemPriceError ? 'Invalid price' : ''}
                            status={isItemPriceError ? 'danger' : 'basic'}
                        />
                        <Input
                            label='Description'
                            placeholder='Enter item description'
                            value={newItem.description}
                            onChangeText={(text) => setNewItem({ ...newItem, description: text })} // Update description
                            style={styles.input}
                            multiline={true}
                        />
                        <Input
                            label='SKU'
                            placeholder='Enter item SKU (optional)'
                            value={newItem.sku ?? ''}
                            onChangeText={(text) => setNewItem({ ...newItem, sku: text })} // Update SKU
                            style={styles.input}
                        />
                        <Button
                            disabled={isItemPriceError || isItemNameError}
                            onPress={editingItem ? handleEditItem : handleAddItem}
                            style={styles.submitButton}
                        >
                            {editingItem ? 'Save Changes' : 'Add Item'}
                        </Button>
                    </Layout>
                </View>

            </DMModal>
            <DMModal
                visible={confirmRemoveModalVisible}
                onClose={() => setConfirmRemoveModalVisible(false)}
            >

                <View style={styles.modalContent}>
                    <Layout style={styles.modalContainer}>
                        <Text category='h6' style={styles.modalHeader}>Confirm Removal</Text>
                        <Text>Are you sure you want to remove this item?</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                            <Button onPress={handleRemoveItem} style={styles.submitButton}>
                                Yes, Remove
                            </Button>
                            <Button appearance='ghost'
                                status='danger'
                                onPress={() => setConfirmRemoveModalVisible(false)} style={styles.submitButton}>
                                Cancel
                            </Button>
                        </View>
                    </Layout>
                </View>

            </DMModal>
            <DMModal
                visible={moveModalVisible}
                onClose={() => setMoveModalVisible(false)}
            >

                <View style={styles.modalContent}>
                    <Layout style={[styles.modalContainer, { gap: 20 }]}>
                        <Text category='h6'>Move Items to Category</Text>
                        <List
                            style={{ backgroundColor: 'transparent' }}
                            data={categories.filter(cat => cat._id !== selectedCategory?._id)}
                            renderItem={({ item }) => (
                                <ListItem
                                    style={{ backgroundColor: targetCategory?._id === item._id ? theme['color-success-300'] : 'transparent' }}
                                    title={item.name}
                                    onPress={() => setTargetCategory(item)}
                                    accessoryLeft={<Icon name='folder-outline' />}
                                />
                            )}
                        />
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Button onPress={handleMoveItems} disabled={!targetCategory}>
                                Move
                            </Button>
                            <Button
                                appearance='ghost'
                                status='danger'
                                onPress={() => {
                                    setTargetCategory(null);
                                    setMoveModalVisible(false);
                                }}>
                                Cancel
                            </Button>
                        </View>
                    </Layout>
                </View>

            </DMModal>
            <DMModal
                visible={showPricingTierManager}
                onClose={() => setShowPricingTierManager(false)}
            >

                <View style={[styles.modalContent, { width: width * 0.9, maxHeight: height * 0.9 }]}>
                    <Layout style={styles.modalContainer}>
                        <PricingTierManager
                            category={selectedCategory!}
                            items={items}
                            onSave={handleSaveCategory}
                            onClose={() => setShowPricingTierManager(false)}
                        />
                    </Layout>
                </View>
            </DMModal>
            <DMModal
                visible={showDeleteCategoryModal}
                onClose={() => setShowDeleteCategoryModal(false)}
            >
                <View style={styles.modalContent}>
                    <Layout style={styles.modalContainer}>
                        <Text category='h6' style={styles.modalHeader}>Delete Category</Text>
                        <Text>Are you sure you want to delete this category?</Text>
                        <Text category='c1' status='warning' style={{ marginTop: 10 }}>
                            Note: Category can only be deleted if it contains no items.
                        </Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                            <Button status='danger' onPress={handleDeleteCategory}>
                                Delete
                            </Button>
                            <Button appearance='ghost' onPress={() => setShowDeleteCategoryModal(false)}>
                                Cancel
                            </Button>
                        </View>
                    </Layout>
                </View>

            </DMModal>
            <DMModal
                visible={showStockManager}
                onClose={() => setShowStockManager(false)}
            >
                <View style={[styles.modalContent, { width: width * 0.9, height: height * 0.9 }]}>
                    <Layout style={{ flex: 1 }}>
                        <StockManager
                            category_id={selectedCategory?._id!}
                            items={items}
                            onClose={() => setShowStockManager(false)}
                        />
                    </Layout>
                </View>
            </DMModal>
            <DMModal
                visible={showLocationManager}
                onClose={() => setShowLocationManager(false)}
                style={{ width: width * 0.9, height: height * 0.9 }}
            >
                <Layout style={{ flex: 1 }}>
                    <LocationManager
                        onClose={() => setShowLocationManager(false)}
                    />
                </Layout>
            </DMModal>
            <DMModal
                visible={showBulkImport}
                onClose={() => setShowBulkImport(false)}
            >
                <View style={[styles.modalContent, { width: width * 0.8 }]}>
                    <Layout style={styles.modalContainer}>
                        <BulkItemImport
                            categoryId={selectedCategory?._id!}
                            companyId={StorageController.getCurrentCompany()._id}
                            onImportComplete={handleBulkImport}
                            onClose={() => setShowBulkImport(false)}
                        />
                    </Layout>
                </View>
            </DMModal>
        </View>
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
        flexDirection: 'row',
    },
    header: {
        marginVertical: 10,
    },
    list: {
        flex: 1,
    },
    addButton: {
        marginBottom: 10,
    },
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContainer: {
        // width: 300,
        padding: 20,
        borderRadius: 8,
    },
    modalHeader: {
        marginBottom: 15,
    },
    input: {
        marginBottom: 10,
    },
    submitButton: {
        marginTop: 10,
    },
    modalBackdrop: {
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        // backgroundColor: '',
        borderRadius: 8,
        padding: 20,
        // width: 300,
    },
});
