var config = require('../config/config.js')
const axios = require('axios');
import * as StorageController from './storageController'
import * as UpdateController from './update.controller'
import * as UserController from './user.controller'
import { Company } from '../models/Company.model';
import { Member } from '../models/Member.model';
import * as MqttService from '../services/mqtt.service'
var api = config.api;
var testApi = config.test_api

function getApi() {
    if (StorageController.getAppState().use_test_api) {
        return testApi
    }
    return api
}

axios.interceptors.request.use(
    async (config: any) => {
        let token = await getToken();
        let member_id = null;
        if (StorageController.getAppState().selectedMembership != null) {
            member_id = StorageController.getAppState().selectedMembership?._id;
        }
        config.headers.authorization = `Bearer ${token}`;
        config.headers['member-id'] = member_id;
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

const getToken = async () => {
    return await StorageController.getAccessToken() || null;
}

export async function switchCompany(company: Company) {
    try {
        if (!company?.membership) {
            // console.log("🚀============== ~ file: company.controller.ts:46 ~ switchCompany ~ 'no membership found for company'🚀==============", 'no membership found for company')
            return
        }
        StorageController.appState.selectedMembership = company.membership || null
        // console.log("🚀============== ~ file: company.controller.ts:46 ~ switchCompany ~ StorageController.appState🚀==============", StorageController.getAppState())
        StorageController.appState.selectedCompany = company
        StorageController.appState.clients = company.clients
        StorageController.appState.members = company.members
        StorageController.appState.vendorProfiles = company.vendorProfiles
        StorageController.appState.drivers = company.members.filter((member) => member.is_driver == true)
        StorageController.appState.services = company.services
        UpdateController.readyUpdate()
        StorageController.saveStateToDisk();
        UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.UPDATED_SELECTED_COMPANY, data: company })
        UpdateController.dispatchEventStateChange({ type: UpdateController.STATE_ACTIONS.UPDATED_SELECTED_MEMBERSHIP, data: company.membership })

        if (StorageController.getAppState().selectedMembership != null && MqttService.getConnectionStatus() != MqttService.CONNECTION_STATUS.CONNECTED) {
            MqttService.connect();
        }
        //Removed await so that the function can return immediately
        await UserController.setMembershipOnlineStatus(company.membership._id, true)

        return company
    } catch (error) {
        console.log("🚀============== ~ file: company.controller.ts:59 ~ switchCompany ~ error🚀==============", error)
    }
}


export async function getCompaniesFromMemberships(memberships: Member[]) {
    var companies = [] as Company[]
    if (memberships == null) return companies
    for (var i = 0; i < memberships.length; i++) {
        var membership = memberships[i]
        if (membership.company_id == null) continue
        var company = await getCompanyById(membership.company_id)
        if (!company) continue
        company.membership = membership
        companies.push(company)
    }
    // console.log(companies)
    if (companies.length > 0) {
        const result = companies.map((company) => { return new Company(company) })
        // console.log("🚀============== ~ file: company.controller:48 ~ getCompaniesFromMemberships ~ result🚀==============", result)
        return result
    } else {
        return []
    }
}

export async function getCompanyById(id: string) {
    try {
        const response = await axios.get(getApi() + `/company/${id}`);
        // console.log(response);
        return new Company(response.data)
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}

//update company
export async function updateCompany(company: Company) {
    try {
        const response = await axios.put(getApi() + `/company/${company._id}`, company);
        // console.log(response);
        return response
    } catch (error) {
        console.error(error);
        // throw new Error(`HTTP error! status: ${response.status}`);
    }
}


// Get companies with parts network enabled
export async function getPartsNetworkCompanies(): Promise<Company[]> {
    try {
        const response = await axios.get(getApi() + `/parts-network/companies`);
        console.log("🚀============== ~ file: company.controller.ts:121 ~ getPartsNetworkCompanies ~ response🚀==============", response)
        const companies = response.data?.map((company: any) => new Company(company));
        console.log("🚀============== ~ file: company.controller.ts:118 ~ getPartsNetworkCompanies ~ companies🚀==============", companies)
        return companies || [];
    } catch (error: any) {
        console.error(error);
        return []
    }
}
