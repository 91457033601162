import React from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle } from '@ui-kitten/components';
import { SMSSettings } from '../../models/Company.model';

interface SMSSettingsProps {
    smsSettings: SMSSettings;
    onUpdateSMSSettings: (updatedSettings: SMSSettings) => void;
    style?: any;
}

export const SMSSettingsComponent: React.FC<SMSSettingsProps> = ({
    smsSettings,
    onUpdateSMSSettings,
    style = {}
}) => {
    const updateSMSSetting = (key: keyof SMSSettings, value: boolean) => {
        const updatedSettings = new SMSSettings({
            ...smsSettings,
            [key]: value
        });
        onUpdateSMSSettings(updatedSettings);
    };

    const RenderToggle = (label: string, key: keyof SMSSettings) => (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
            <Text>{label}</Text>
            <Toggle
                checked={smsSettings[key] as boolean}
                onChange={(nextChecked) => updateSMSSetting(key, nextChecked)}
            />
        </View>
    );

    return (
        <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>SMS Settings</Text>} status="basic">
            <View style={{ flexDirection: 'column' }}>
                {RenderToggle('Send Created SMS', 'sendCreatedSMS')}
                {RenderToggle('Send Assigned SMS', 'sendAssignedSMS')}
                {RenderToggle('Send En Route SMS', 'sendEnRouteSMS')}
                {RenderToggle('Send Completed SMS', 'sendCompletedSMS')}
                {RenderToggle('Send Cancelled SMS', 'sendCancelledSMS')}
                
                <Text category='h6' style={{ marginTop: 20, marginBottom: 10 }}>General Settings</Text>
                {RenderToggle('Include Job Status Link in SMS', 'include_job_status')}
            </View>
        </Card>
    );
};
