import React from 'react';
import { View } from 'react-native';
import { Card, Text, Toggle, Input } from '@ui-kitten/components';
import { ScheduleSettings } from '../../models/Company.model';

interface ScheduleSettingsProps {
    scheduleSettings: ScheduleSettings;
    onUpdateScheduleSettings: (updatedSettings: ScheduleSettings) => void;
    style?: any;
}

export const ScheduleSettingsComponent: React.FC<ScheduleSettingsProps> = ({
    scheduleSettings,
    onUpdateScheduleSettings,
    style = {}
}) => {
    const updateScheduleSetting = (key: keyof ScheduleSettings, value: boolean | number) => {
        const updatedSettings = new ScheduleSettings({
            ...scheduleSettings,
            [key]: value
        });
        onUpdateScheduleSettings(updatedSettings);
    };

    return (
        <Card style={[style, { flex: 1 }]} disabled header={() => <Text category='h6'>Schedule Settings</Text>} status="basic">
            <View style={{ flexDirection: 'column' }}>
                <Toggle
                    checked={scheduleSettings.alertEnabled}
                    onChange={(nextChecked) => updateScheduleSetting('alertEnabled', nextChecked)}
                >
                    Schedule Alert Enabled
                </Toggle>
                <Input
                    label="Schedule Alert Time"
                    placeholder="Schedule Alert Time"
                    value={scheduleSettings.alertTime.toString()}
                    onChangeText={(nextValue) => {
                        const numValue = parseInt(nextValue);
                        if (!isNaN(numValue)) {
                            updateScheduleSetting('alertTime', numValue);
                        }
                    }}
                    keyboardType="numeric"
                    style={{ marginTop: 10 }}
                />
            </View>
        </Card>
    );
};
