import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    Button,
    Icon,
    Layout,
    Text,
    Card,
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import ErrorBoundary from '../../ErrorBoundary.component';
import { JobDetailImages } from '../jobDetailsImages.component';
import { useTheme } from "../../../context/theme.context";
import { Job } from '../../../models/Job.model';
import { Holding } from '../../../models/Holding.model';


interface ImageDetailsCardProps {
    images: any[];
    propStyle?: any;
    onSetImages: (images: any[]) => void;
    onSetLocalImages: (images: any[]) => void;
    showUploadButton?: boolean;
}

export const ImageDetailsCard = ({
    images = [],
    propStyle,
    onSetImages = (e: any) => { console.log("onSetImages - Not Set") },
    onSetLocalImages = (e: any) => { console.log("onSetLocalImages - Not Set") },
    showUploadButton = true
}: ImageDetailsCardProps) => {
    const { theme } = useTheme();
    const [image, setImage] = useState<string | null>(null);
    const [imagesState, setImagesState] = useState<any[]>(images);
    const [localImages, setLocalImages] = useState<any[]>([]);

    useEffect(() => {
        setImagesState(images);
    }, [images]);

    const pickImage = async () => {
        // No permissions request is necessary for launching the image library
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            // allowsEditing: true,
            aspect: [4, 3],
            quality: 0.5,
            exif: true
        });

        // console.log("Selected Image", result);
        if (!result.cancelled) {
            setImage(result.uri);
            addImageToJob(result);
        }
    };



    const addImageToJob = async (img: ImagePicker.ImageInfo) => {
        let updatedLocalImages = [...localImages, img];
        console.log("🚀============== ~ file: imageDetailsCard.component.tsx:78 ~ addImageToJob ~ updatedLocalImages🚀==============", updatedLocalImages)
        setLocalImages(updatedLocalImages);
        onSetLocalImages(updatedLocalImages);
        onSetImages(updatedLocalImages);
    };


    return (
        <Layout style={[propStyle, { justifyContent: "start", height: "100%", borderRightWidth: 1, boxShadow: "0 0 5px rgba(0,0,0,0.5)" }]}>
            {/* @ts-ignore */}
            <Layout style={{ flex: 10, flexDirection: 'column', overflow: "auto" }}>
                <Card
                    disabled
                    header={() => <Text style={{ alignSelf: 'center' }}>Images</Text>}
                    status='primary'
                >
                    {showUploadButton &&
                        <Layout style={{ flex: 1 }}>
                            <Button
                                style={{ margin: 5 }}
                                onPress={() => { pickImage() }}
                                appearance='outline'
                                status="info"
                                size='small'
                                accessoryLeft={() => <Icon
                                    name='camera-outline'
                                    width={24}
                                    height={24}
                                    fill={theme['text-basic-color']}
                                />}
                            >
                                Add Image
                            </Button>
                        </Layout>
                    }
                    <JobDetailImages
                        var_images={imagesState}
                        var_localImages={localImages}
                    />
                </Card >
            </Layout>
        </Layout>
    )

}
