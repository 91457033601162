import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Text, useTheme } from '@ui-kitten/components';

interface TextDetailPillProps {
    label: string;
    value: string;
    valueThemeColor?: string | null;
    labelStyle?: object | null;
    valueStyle?: object | null;
    size?: 'small' | 'medium' | 'large';
}

export const TextDetailPill: React.FC<TextDetailPillProps> = ({
    label,
    value,
    labelStyle,
    valueStyle,
    valueThemeColor,
    size = 'medium',
}) => {
    const theme = useTheme();
    const styles = getStyles(theme, size, !!valueThemeColor);

    return (
        <View style={styles.container}>
            <Text style={[styles.label, labelStyle]}>{label}</Text>
            <Text style={[
                styles.value, 
                valueStyle, 
                valueThemeColor ? { backgroundColor: valueThemeColor } : null
            ]}>
                {value}
            </Text>
        </View>
    );
};

const getStyles = (theme: any, size: 'small' | 'medium' | 'large', hasBorder: boolean) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 5,
    },
    label: {
        color: theme['text-basic-color'],
        fontSize: size === 'small' ? 12 : size === 'medium' ? 14 : 16,
    },
    value: {
        fontSize: size === 'small' ? 12 : size === 'medium' ? 14 : 16,
        borderWidth: hasBorder ? 1 : 0,
        borderRadius: 8,
        paddingHorizontal: size === 'small' ? 2 : size === 'medium' ? 3 : 4,
        paddingVertical: size === 'small' ? 1 : size === 'medium' ? 2 : 3,
        color: theme['text-basic-color'],
    },
});
