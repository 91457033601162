import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline, OverlayView } from '@react-google-maps/api';
import * as UpdateController from '../../../functions/update.controller';
import * as UserController from '../../../functions/user.controller';
import * as StorageController from "../../../functions/storageController"
import * as MessageController from "../../../functions/message.controller"
import * as GoogleController from "../../../functions/google.controller"
import * as JobsController from '../../../functions/job.controller'
import ErrorBoundary from '../../ErrorBoundary.component';
//@ts-ignore
import _ from 'lodash'
//@ts-ignore
import debounce from 'lodash.debounce';
import Toast from 'react-native-toast-message';
// import { AddressSearch } from '../searchAddress.component';
import DistanceTool from './../distanceTool.component';
import { RoadsideJobInfo } from './../RoadsideJobInfo.component';
import { TowingJobInfo } from './../TowingJobInfo.component';
import { MemberDeviceInfoCard } from '../../members/memberDeviceInfoCard.component';
import { Job, JobTowingDetails, TowingLeg } from '../../../models/Job.model';
import { Member } from '../../../models/Member.model';
import { Company } from '../../../models/Company.model'
import { darkMapStyles, lightMapStyles } from './../mapStyles';


export const RenderDirectionsDriverToJobDirectionsPanel = ({
    mapDirections = null as any,
    onClearDirections = () => { },
    setShowDirectionsPanel = (v: boolean) => { },
}) => {
    return (
        <Layout
            style={{
                // display: showDirectionsPanel ? "block" : "none",
                // position: "absolute",
                // top: "10px",
                // left: "10px",
                width: "300px",
                height: "500px",
                // zIndex: 1000,
                border: "1px solid black",
                borderRadius: "5px",
                padding: "10px",
                overflow: "auto",
            }}
        >
            <Button
                style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px"
                }}
                appearance="outline"
                status="danger"
                onPress={() => {
                    setShowDirectionsPanel(false)
                    onClearDirections()
                }}
            >X</Button>
            <Text category='h6' style={{ paddingBottom: 25 }}>Travel Info</Text>
            <Layout style={{ display: "flex", justifyContent: "space-between" }}>
                {/* display duration */}
                {mapDirections &&
                    <Layout style={{
                        border: "1px solid green",
                        borderRadius: "5px",
                        padding: "5px",
                        backgroundColor: "rgba(0, 255, 0, 0.2)",
                    }}>
                        <Text category='label'>Duration:</Text>
                        <Text>
                            {mapDirections?.routes[0].legs[0].duration.text}
                        </Text>
                    </Layout>
                }
                {/* display distance */}
                {mapDirections &&
                    <Layout style={{
                        border: "1px solid blue",
                        borderRadius: "5px",
                        padding: "5px",
                        backgroundColor: "rgba(0, 0, 255, 0.2)",
                    }}>
                        <Text category='label'>Distance:</Text>
                        <Text>
                            {mapDirections.routes[0].legs[0].distance.text}
                        </Text>
                    </Layout>
                }
                {/* display address */}
                {mapDirections &&
                    <Layout style={{
                        border: "1px solid yellow",
                        borderRadius: "5px",
                        padding: "5px",
                        backgroundColor: "rgba(255, 255, 0, 0.2)",
                    }}>

                        <Text category='label'>Address:</Text>
                        <Text>
                            {mapDirections.routes[0].legs[0].end_address}
                        </Text>
                    </Layout>
                }
            </Layout>
            <List
                style={{ maxHeight: "200px", overflow: "auto" }}
                data={mapDirections && mapDirections.routes[0].legs[0].steps}
                renderItem={({ item, index }: any) => {
                    // instructions are in html format, so we need to convert it to text
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(item.instructions, "text/html");
                    let instructions = htmlDoc.body.textContent;
                    return (
                        <ListItem
                            style={{ border: "1px solid black" }}
                            key={index}
                            title={instructions}
                            description={item.distance.text}
                        />
                    )
                }}
            >
            </List>
            <Button
                style={{
                    position: "absolute",
                    bottom: "0px",
                    right: "0px"
                }}
                appearance="outline"
                status="success"
                onPress={() => {
                    // get all directions and copy to clipboard as a string
                    let directions = mapDirections.routes[0].legs[0].steps
                    let directionsString = ""
                    directions.forEach((direction: any, index: number) => {
                        const parser = new DOMParser();
                        const htmlDoc = parser.parseFromString(direction.instructions, "text/html");
                        let instructions = htmlDoc.body.textContent;
                        directionsString += instructions + '\n'

                        // add distance if it's not the last direction
                        if (index < directions.length - 1) {
                            directionsString += " (" + direction.distance.text + ") "
                        }
                    })
                    navigator.clipboard.writeText(directionsString)
                    Toast.show({
                        text1: "Copied to clipboard",
                        type: "success",
                        // @ts-ignore
                        duration: 1000,
                    })
                }}
            >
                Copy All
            </Button>
        </Layout>
    )
}