import React, { useState, useEffect, useRef } from 'react'; // Add useState and useEffect
import { View, StyleSheet, Animated, ScrollView } from 'react-native';
import { Text, Button, Layout, useTheme, Icon, OverflowMenu, MenuItem, Modal, CheckBox } from '@ui-kitten/components';
import { InventoryItem, InventoryStock } from '../../models/Inventory.model';
import { CustomPricing } from '../../models/Inventory.model';
import { formatTimestamp } from '../../functions/utils';
import * as StorageController from '../../functions/storageController';
import * as InventoryController from '../../functions/Inventory.controller'; // Import the function
import AddCustomPricingModal from './AddCustomPricingModal.component';
import AddInventoryStockModal from './AddInventoryStockModal.component'; // Import the new modal
import Toast from 'react-native-toast-message';
import EditCustomPricingModal from './EditCustomPricingModal.component'; // Import the edit modal

interface InventoryItemDetailProps {
    item: InventoryItem;
    onEdit: () => void;
    onRemove: () => void;
    // Add props for selection
    isSelected?: boolean;
    onSelect?: () => void;
}

const InventoryItemDetail: React.FC<InventoryItemDetailProps> = ({ item, onEdit, onRemove, isSelected, onSelect }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const [expanded, setExpanded] = useState(false); // State for expansion
    const [customPricings, setCustomPricings] = useState<CustomPricing[]>([]); // State for custom pricing
    const [animation] = useState(new Animated.Value(0)); // Animation state
    const [modalVisible, setModalVisible] = useState(false); // State for modal visibility
    const [deleteModalVisible, setDeleteModalVisible] = useState(false); // State for delete modal visibility
    const [pricingToDelete, setPricingToDelete] = useState<CustomPricing | null>(null); // State to hold pricing to delete
    const [inventoryStock, setInventoryStock] = useState<InventoryStock[]>([]); // State for inventory stock
    const [stockModalVisible, setStockModalVisible] = useState(false); // State for stock modal visibility
    const [stockToEdit, setStockToEdit] = useState<InventoryStock | null>(null); // State for editing stock
    const [deleteStockModalVisible, setDeleteStockModalVisible] = useState(false); // State for delete stock modal visibility
    const [stockToDelete, setStockToDelete] = useState<InventoryStock | null>(null); // State to hold stock to delete
    const [editModalVisible, setEditModalVisible] = useState(false); // State for edit modal visibility
    const [pricingToEdit, setPricingToEdit] = useState<CustomPricing | null>(null); // State for pricing to edit

    const inventoryItemSelected = useRef<InventoryItem | null>(null);


    const toggleExpand = () => {
        setExpanded(!expanded);
        Animated.timing(animation, {
            toValue: expanded ? 0 : 1, // Toggle between 0 and 1
            duration: 100,
            useNativeDriver: false,
        }).start();
    };


    useEffect(() => {
        if (expanded) {
            const fetchCustomPricings = async () => {
                const pricings = await InventoryController.getAllCustomPricesByItem(item.company_id ?? '', item._id ?? ''); // Fetch custom pricing
                setCustomPricings(pricings || []);
            };
            fetchCustomPricings();

            const fetchInventoryStock = async () => {
                const stock = await InventoryController.getInventoryStockByInventoryItemId(item._id ?? '');
                console.log("🚀============== ~ file: InventoryItemDetail.component.tsx:50 ~ fetchInventoryStock ~ stock🚀==============", stock)
                setInventoryStock(stock || []);
            };
            fetchInventoryStock();
        }
    }, [expanded, item.company_id]);

    const animatedHeight = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 500], // Adjust the output range as needed
    });


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// CUSTOM PRICING
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const handleAddCustomPricing = async (newPricing: CustomPricing) => {
        // ensure the client is unique to this item, just check locally for now
        const clientExists = customPricings.find(pricing =>
            pricing.client_id === newPricing.client_id &&
            pricing.inventory_item_id === newPricing.inventory_item_id
        );
        if (clientExists) {
            console.log("🚀============== ~ file: InventoryItemDetail.component.tsx:79 ~ handleAddCustomPricing ~ clientExists🚀==============", clientExists)
            Toast.show({
                text1: 'Client price already exists for this item',
                type: 'error'
            });
            return;
        }

        const addedPricing = await InventoryController.createCustomPricing(newPricing);
        if (addedPricing) {
            setCustomPricings([...customPricings, addedPricing]);
            setModalVisible(false);
        }
        setModalVisible(false);
    };

    const handleDeleteCustomPricing = async () => {
        if (pricingToDelete) {
            const deleted = await InventoryController.deleteCustomPricing(pricingToDelete._id ?? '');
            if (deleted) {
                setCustomPricings(customPricings.filter(pricing => pricing._id !== pricingToDelete._id));
            }
            setDeleteModalVisible(false);
            setPricingToDelete(null);
        }
    };

    const getClientName = (client_id: string) => {
        const company = StorageController.getCurrentCompany();
        if (company) {
            const client = company.clients.find(client => client._id === client_id);
            return client?.name ?? 'N/A';
        }
        return 'N/A';
    };

    const handleOpenEditModal = (pricing: CustomPricing) => {
        setPricingToEdit(pricing);
        setEditModalVisible(true);
    };

    const handleUpdateCustomPricing = async (pricing: CustomPricing) => {
        const updatedPricing = await InventoryController.updateCustomPricing(pricing._id ?? '', pricing);
        if (updatedPricing) {
            setCustomPricings(customPricings.map(p => p._id === updatedPricing._id ? updatedPricing : p));
            setEditModalVisible(false);
            setPricingToEdit(null);
        }
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// INVENTORY STOCK
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const handleAddInventoryStock = async (stock: InventoryStock) => {
        console.log("🚀============== ~ file: InventoryItemDetail.component.tsx:102 ~ handleAddInventoryStock ~ stock🚀==============", stock)
        try {
            if (!item._id || !item.company_id) {
                return;
            }
            stock.inventory_item_id = item._id ?? '';
            stock.company_id = item.company_id ?? '';
            const addedStock = await InventoryController.createInventoryStock(stock);
            console.log("🚀============== ~ file: InventoryItemDetail.component.tsx:99 ~ handleAddInventoryStock ~ addedStock🚀==============", addedStock)
            if (addedStock) {
                setInventoryStock([...inventoryStock, addedStock]);
                setStockModalVisible(false);
            }
        } catch (error) {
            console.log("🚀============== ~ file: InventoryItemDetail.component.tsx:106 ~ handleAddInventoryStock ~ error🚀==============", error)

        }
    };

    const handleEditInventoryStock = async (stock: InventoryStock) => {
        const updatedStock = await InventoryController.updateInventoryStock(stock._id, stock);
        if (updatedStock) {
            setInventoryStock(inventoryStock.map(s => s._id === updatedStock._id ? updatedStock : s));
            setStockModalVisible(false);
            setStockToEdit(null);
        }
    };

    const handleDeleteInventoryStock = async () => {
        if (stockToDelete) {
            const deleted = await InventoryController.deleteInventoryStock(stockToDelete._id ?? '');
            if (deleted) {
                setInventoryStock(inventoryStock.filter(stock => stock._id !== stockToDelete._id));
            }
            setDeleteStockModalVisible(false);
            setStockToDelete(null);
        }
    };
    const handleAddEditInventoryStockToItem = async (stock: InventoryStock) => {
        setStockModalVisible(true);
        setStockToEdit(stock); // set the stock to edit if it exists
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// RENDER
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */


    return (
        <View style={[styles.container, { borderColor: isSelected ? theme['color-primary-500'] : theme['color-basic-400'] }]}>
            {onSelect && (
                <CheckBox
                    checked={isSelected}
                    onChange={onSelect}
                // style={{ position: 'absolute', top: 10, left: 10 }}
                />
            )}
            <View style={{ flexDirection: 'column', flex: 1 }}>


                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={styles.row}>
                        <Icon name='pricetags-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text category='s1' style={[styles.label]}>
                            Name: <Text style={[styles.detailTextPill, { backgroundColor: theme['color-info-900'] }]}> {item.name} </Text>
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Icon name='hash-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text category='s1' style={[styles.label]}>
                            SKU: <Text category='s2'> {item.sku ?? 'N/A'} </Text>
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Icon name='shopping-cart-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-500'] }]}> ${item.default_price.toFixed(2)} </Text>
                        <Button size='small' appearance='ghost' status='primary' onPress={toggleExpand}
                            accessoryLeft={<Icon name={expanded ? 'chevron-up-outline' : 'chevron-down-outline'} fill={theme['color-primary-500']} style={styles.icon} />}>
                        </Button>
                    </View>
                </View>
                <View style={styles.row}>
                    <Icon name='info-outline' fill={theme['color-primary-500']} style={styles.icon} />
                    <Text category='s1' style={[styles.label]}>
                        Description: <Text category='p1'> {item.description ?? 'N/A'} </Text>
                    </Text>
                </View>
                <Animated.View style={{ maxHeight: animatedHeight, overflow: 'hidden' }}>
                    {expanded && (
                        <ScrollView>
                            <View style={{ gap: 10, padding: 10, margin: 30, flexDirection: 'row' }}>
                                <Layout style={{ borderWidth: 1, borderColor: '#aaa', borderRadius: 5, padding: 10, margin: 10, flex: 1 }}>
                                    <Text category='s1' style={[styles.label]}>Custom Pricing</Text>
                                    <Button
                                        appearance='ghost'
                                        size='small'
                                        onPress={() => setModalVisible(true)}
                                        accessoryLeft={<Icon name='plus-outline' style={styles.icon} />} // Added icon
                                    >
                                        Add Custom Pricing
                                    </Button>

                                    {customPricings.map((pricing) => (
                                        <View key={pricing._id} style={{ width: '100%', borderWidth: 1, borderColor: '#aaa', borderRadius: 5 }}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                                                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', flex: 1 }}>
                                                    {/* <Text category='s1'>Client: </Text> */}
                                                    <Text category='s1'>{getClientName(pricing.client_id ?? '')}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', flex: 1 }}>
                                                    <Text category='s1'>Price: </Text>
                                                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-500'] }]}>${pricing.price}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center', flex: 1 }}>
                                                    <Button
                                                        appearance='ghost'
                                                        size='small'
                                                        onPress={() => handleOpenEditModal(pricing)} // Update edit handler
                                                        accessoryLeft={<Icon name='edit-outline' style={styles.icon} />}
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        appearance='ghost'
                                                        size='small'
                                                        onPress={() => { setPricingToDelete(pricing); setDeleteModalVisible(true); }}
                                                        accessoryLeft={<Icon name='trash-outline' style={styles.icon} />} // Added icon
                                                    >
                                                        Delete
                                                    </Button>
                                                </View>
                                            </View>
                                        </View>
                                    ))}
                                </Layout>
                                <Layout style={{ borderWidth: 1, borderColor: '#aaa', borderRadius: 5, padding: 10, margin: 10, flex: 1 }}>

                                    <Text category='s1' style={[styles.label]}>Inventory Stock</Text>
                                    <Button
                                        appearance='ghost'
                                        size='small'
                                        onPress={() => handleAddEditInventoryStockToItem(new InventoryStock())}
                                        accessoryLeft={<Icon name='plus-outline' style={styles.icon} />} // Added icon
                                    >
                                        Add Inventory Stock
                                    </Button>
                                    {inventoryStock.map((stock, index) => (
                                        <View key={stock._id} style={{ width: '100%', borderWidth: 1, borderColor: '#aaa', borderRadius: 5 }}>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Text category='s1'>{stock.location ?? 'N/A'}</Text>
                                                <Button
                                                    appearance='ghost'
                                                    size='small'
                                                    onPress={() => { handleAddEditInventoryStockToItem(stock) }}
                                                    accessoryLeft={<Icon name='edit-outline' style={styles.icon} />} // Added icon
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    appearance='ghost'
                                                    size='small'
                                                    onPress={() => { setStockToDelete(stock); setDeleteStockModalVisible(true); }}
                                                    accessoryLeft={<Icon name='trash-outline' style={styles.icon} />} // Added icon
                                                >
                                                    Delete
                                                </Button>
                                            </View>
                                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10, alignItems: 'center' }}>
                                                <View style={{ flexDirection: 'row', gap: 10 }}>
                                                    <Text category='s1'>Quantity: </Text>
                                                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-primary-100'] }]} category='s1'>{stock.quantity ?? 'N/A'}</Text>
                                                </View>
                                                <View style={{ flexDirection: 'row', gap: 10 }}>
                                                    <Text category='s1'>Updated At: </Text>
                                                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-basic-500'] }]} category='s1'>{formatTimestamp(stock.updatedAt ?? '')}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    ))}
                                </Layout>
                            </View>
                        </ScrollView>
                    )}
                </Animated.View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={styles.row}>
                        <Icon name='calendar-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text category='p1' style={[styles.label]}>
                            Created At: <Text category='p2' style={[styles.detailTextPill, { backgroundColor: theme['color-basic-500'] }]}> {formatTimestamp(item.createdAt ?? '')} </Text>
                        </Text>
                    </View>
                    <View style={styles.row}>
                        <Icon name='edit-outline' fill={theme['color-primary-500']} style={styles.icon} />
                        <Text category='p1' style={[styles.label]}>
                            Updated At: <Text category='p2' style={[styles.detailTextPill, { backgroundColor: theme['color-basic-500'] }]}> {formatTimestamp(item.updatedAt ?? '')} </Text>
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', gap: 10 }}>
                        <Button
                            appearance='ghost'
                            status='primary'
                            size='small'
                            accessoryLeft={<Icon name='edit-outline' style={styles.icon} />}
                            onPress={onEdit}
                        >
                            Edit
                        </Button>
                        <Button
                            appearance='ghost'
                            status='danger'
                            size='small'
                            accessoryLeft={<Icon name='trash-outline' style={styles.icon} />}
                            onPress={onRemove}
                        >
                            Remove
                        </Button>
                    </View>
                </View>

                <AddCustomPricingModal
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    onAddPricing={handleAddCustomPricing}
                    companyId={item.company_id ?? ''} // Pass company ID to fetch clients
                    inventoryItemId={item._id ?? ''}
                />

                <Modal visible={deleteModalVisible} onBackdropPress={() => setDeleteModalVisible(false)} backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <View style={{ padding: 20 }}>
                        <Text>Are you sure you want to delete this pricing?</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Button status='primary' onPress={handleDeleteCustomPricing}>Yes, Delete</Button>
                            <Button appearance='ghost' status='danger' onPress={() => setDeleteModalVisible(false)}>Cancel</Button>
                        </View>
                    </View>
                </Modal>

                <AddInventoryStockModal
                    visible={stockModalVisible}
                    onClose={() => { setStockModalVisible(false); setStockToEdit(null); }}
                    onAddStock={handleAddInventoryStock}
                    onEditStock={handleEditInventoryStock}
                    stock={stockToEdit}
                    inventoryItemId={item._id ?? ''}
                />

                <Modal visible={deleteStockModalVisible} onBackdropPress={() => setDeleteStockModalVisible(false)} backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                    <Layout style={{ padding: 20 }}>
                        <Text>Are you sure you want to delete this stock?</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                            <Button status='primary' onPress={handleDeleteInventoryStock}>Yes, Delete</Button>
                            <Button appearance='ghost' status='danger' onPress={() => setDeleteStockModalVisible(false)}>Cancel</Button>
                        </View>
                    </Layout>
                </Modal>

                <EditCustomPricingModal
                    visible={editModalVisible}
                    onClose={() => { setEditModalVisible(false); setPricingToEdit(null); }}
                    onUpdatePricing={handleUpdateCustomPricing}
                    pricing={pricingToEdit}
                    clientName={getClientName(pricingToEdit?.client_id ?? '')}
                />
            </View>
        </View>
    );
};

const getStyles = (theme: any) => StyleSheet.create({
    container: {
        padding: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        marginBottom: 10,
        borderRadius: 5,
        flexDirection: 'row',
    },
    label: {
        marginBottom: 5,
        color: theme['text-basic-color']
    },
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    icon: {
        width: 24,
        height: 24,
        marginRight: 10,
    },
    detailTextPill: {
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 8,
        paddingVertical: 2,
        // borderColor: theme['color-primary-500'], // Use theme color for border
    },
});

export default InventoryItemDetail;