import { Surcharge } from "./Company.model";
export class CompanySecret {
    _id!: string;
    company_id!: string;
    square!: {
        access_token: string,
        application_id: string,
        location_id: string,
        surcharges: Surcharge[]
    };
    stripe!: {
        secret_key: string,
        publishable_key: string,
        customer_id: string
    };
    integrations: { [key: string]: { [key: string]: string } };
    webhook: WebhookConfig;
    details!: any;
    createdAt!: string;
    updatedAt!: string;

    constructor(companySecret: any = {}) {
        this._id = companySecret?._id;
        this.company_id = companySecret?.company_id;
        this.square = companySecret?.square ?? {
            access_token: "",
            application_id: "",
            location_id: "",
            surcharges: []
        };
        this.stripe = companySecret?.stripe ?? {
            secret_key: "",
            publishable_key: "",
            customer_id: ""
        };
        this.integrations = companySecret?.integrations ?? {};
        this.webhook = companySecret?.webhook ? new WebhookConfig(companySecret.webhook) : new WebhookConfig();
        this.details = companySecret?.details ?? {};
        this.createdAt = companySecret?.createdAt;
        this.updatedAt = companySecret?.updatedAt;
    }
}


export class WebhookConfig {
    enabled: boolean = false;
    method: string = '';
    url: string = '';
    headers: any = {};
    auth_type: 'bearer' | 'basic' | 'none' = 'none';
    auth_config: any = {};
    timeout: number = 5000;
    bearer_token: string = '';
    basic_auth_username: string = '';
    basic_auth_password: string = '';



    constructor(webhookConfig: Partial<WebhookConfig> = {}) {
        this.enabled = webhookConfig.enabled ?? false;
        this.method = webhookConfig.method ?? '';
        this.url = webhookConfig.url ?? '';
        this.headers = webhookConfig.headers ?? {};
        this.auth_type = webhookConfig.auth_type ?? 'none';
        this.auth_config = webhookConfig.auth_config ?? {};
        this.timeout = webhookConfig.timeout ?? 5000;
        this.bearer_token = webhookConfig.bearer_token ?? '';
        this.basic_auth_username = webhookConfig.basic_auth_username ?? '';
        this.basic_auth_password = webhookConfig.basic_auth_password ?? '';
    }
}