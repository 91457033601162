import React, { useState, useRef, useCallback, useEffect, useReducer, useMemo } from 'react';
//@ts-ignore
import { StyleSheet, View, useWindowDimensions } from 'react-native';
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    //@ts-ignore
    IndexPath,
} from "@ui-kitten/components";
import { SafeAreaView } from 'react-native-safe-area-context';
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import { mapping, light, dark } from "@eva-design/eva";
import * as StorageController from '../../functions/storageController';
import * as GoogleController from '../../functions/google.controller';
import * as ClientController from '../../functions/client.controller';
import * as VendorProfileController from '../../functions/vendorProfile.controller';
import * as UpdateController from '../../functions/update.controller';
import * as CompanyController from '../../functions/company.controller';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline } from '@react-google-maps/api';
import "react-datepicker/dist/react-datepicker.css";
import { Job, JobTowingDetails, LineItem, TowingLocation, JobDirections, TowingLeg, JobLocation, Polyline as JobPolyLine, JobAddress } from '../../models/Job.model';
import { ScrollView } from 'react-native-gesture-handler';
import { Route, RouteDetails, ROUTE_STATUS, RouteLeg, RouteLocation, IGoogleRoute, IGoogleRouteLeg, ROUTE_START_END_OPTION } from '../../models/Route.model';
import polyline from '@mapbox/polyline';
import { polylineStrokeColourSet } from '../../utils/routeUtils';
import { CompanySavedAddress } from '../../models/Company.model';
import { RouteGroup } from '../../types/route.types';

const mapLibs = ["places", "visualization"]
const mapPanelWidth = 200
const mapHeight = 500

enum ROUTE_POLYLINE_OPTIONS {
    OVERVIEW = 'overview',
    LEGS = 'legs'
}


interface RoutePlanningMapProps {
    routes: Route[],
    selectedRoute: Route | null, // Add this line
    hoveredMarkerIndex: number | null,
    highlightedLegIndex: number | null,
    selectedMarkerIndex: number | null,
    setSelectedMarkerIndex: (index: number | null) => void,
    onMapClick: (event: any) => void,
    onDragMarker: (location: any, index: number, routeIndex: number) => void,
    setHoveredMarkerIndex: (index: number | null) => void,
    setHighlightedLegIndex: (index: number | null) => void,
    onClickMarker: (location: RouteLocation, routeIndex: number) => void,
    customStartPin: CompanySavedAddress | null;
    customEndPin: CompanySavedAddress | null;
    routeGroups: RouteGroup[];
    companySavedAddresses: CompanySavedAddress[];
    previewRouteLocations: RouteLocation[];
}


export const RoutePlanningMap = ({
    routes,
    selectedRoute,
    highlightedLegIndex,
    hoveredMarkerIndex,
    onMapClick,
    onDragMarker,
    setHoveredMarkerIndex,
    setHighlightedLegIndex,
    onClickMarker,
    customStartPin,
    customEndPin,
    routeGroups,
    companySavedAddresses,
    previewRouteLocations,
}: RoutePlanningMapProps) => {

    const [map, setMap] = useState<any>(null);
    const { width, height } = useWindowDimensions();
    const [routePolylineOptions, setRoutePolylineOptions] = useState<ROUTE_POLYLINE_OPTIONS>(ROUTE_POLYLINE_OPTIONS.OVERVIEW);

    useEffect(() => {
        if (!selectedRoute) {
            setRoutePolylineOptions(ROUTE_POLYLINE_OPTIONS.OVERVIEW)
        } else {
            setRoutePolylineOptions(ROUTE_POLYLINE_OPTIONS.LEGS)
        }
    }, [routes, selectedRoute])

    const mapContainerStyle = {
        width: "100%",
        height: `${height}px`
    };

    const mapRef = React.useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs
    });

    const companyLocation = StorageController.getCurrentCompany().settings?.location?.coords || { lat: 0, lng: 0, lon: 0 };
    const [center, setCenter] = useState(companyLocation);

    const memoizedPolylines = useMemo(() => {
        const routesToRender = selectedRoute ? [selectedRoute] : routes;

        return routesToRender.flatMap((route, routeIndex) => {
            if (selectedRoute) {
                // Render leg polylines for the selected route
                return route.details.legs.map((leg, legIndex) => {
                    const isHighlighted = highlightedLegIndex === legIndex ||
                        hoveredMarkerIndex === legIndex ||
                        hoveredMarkerIndex === legIndex + 1;

                    return {
                        path: leg.polyline,
                        options: {
                            strokeColor: polylineStrokeColourSet(routeIndex * 10 + legIndex),
                            strokeOpacity: isHighlighted ? 0.8 : 0.5,
                            strokeWeight: isHighlighted ? 6 : 4,
                        }
                    };
                });
            } else {
                // Render overview polyline for each route
                if (!route.details.overview_polyline) return [];

                let decodedPolyline;
                if (typeof route.details.overview_polyline === 'string') {
                    decodedPolyline = polyline.decode(route.details.overview_polyline, 6)
                        .map((point: any) => ({ lat: point[0], lng: point[1] }));
                } else {
                    decodedPolyline = route.details.overview_polyline;
                }

                return [{
                    path: decodedPolyline,
                    options: {
                        strokeColor: polylineStrokeColourSet(routeIndex * 10),
                        strokeOpacity: 0.8,
                        strokeWeight: 6,
                    }
                }];
            }
        });
    }, [routes, selectedRoute, hoveredMarkerIndex, highlightedLegIndex]);

    const onHoverMarker = (index: number) => {
        setHoveredMarkerIndex(index);
    }


    const RenderPolyLineButtonOptions = () => {
        return (
            <View style={{ flexDirection: 'column', gap: 3 }}>
                <Button
                    onPress={() => {
                        setRoutePolylineOptions(ROUTE_POLYLINE_OPTIONS.OVERVIEW)
                    }}
                    status='success'>
                    <Text>Overview</Text>
                </Button>
                <Button
                    onPress={() => setRoutePolylineOptions(ROUTE_POLYLINE_OPTIONS.LEGS)}
                    status='success'>
                    <Text>Steps</Text>
                </Button>
            </View>
        )
    }

    const onHoverMarkerEvent = (routeLocation: RouteLocation | null) => {
        if (!routeLocation) {
            setShowMarkerInfoWindow(false);
            setHoveredMarkerIndex(null);
            return;
        }
        // get the leg that the marker is in
        const leg = routes.flatMap(route => route.details.legs).find((leg: RouteLeg) => leg.origin?.lat === routeLocation.location.lat && leg.origin.lng === routeLocation.location.lng);
        // get next leg if its not the last marker
        const nextLeg = routes.flatMap(route => route.details.legs)[routeLocation.index + 1];
        setHoveredMarkerIndex(routeLocation.index);
        setSelectedMarkerRouteLocation(routeLocation);
        setShowMarkerInfoWindow(true);
    }

    const [showMarkerInfoWindow, setShowMarkerInfoWindow] = useState(false);
    const [selectedMarkerRouteLocation, setSelectedMarkerRouteLocation] = useState<RouteLocation | null>(null);

    const onClickMarkerEvent = (routeLocation: RouteLocation, routeIndex: number) => {
        if (!routeLocation) return;
        onClickMarker(routeLocation, routeIndex);
        setSelectedMarkerRouteLocation(routeLocation);
        setShowMarkerInfoWindow(true);
    }

    const RenderMarkerInfoWindow = ({ routeLocation }: { routeLocation: RouteLocation }) => {
        // const leg = routes.flatMap(route => route.details.legs).find((leg: RouteLeg) => leg.origin?.lat === routeLocation.location.lat && leg.origin.lng === routeLocation.location.lng);
        const leg = routes.flatMap(route => route.details.legs)[routeLocation.index - 1];
        const nextLeg = routes.flatMap(route => route.details.legs)[routeLocation.index];

        return (
            <InfoWindow
                position={routeLocation.location}
                options={{
                    pixelOffset: new google.maps.Size(0, -30),
                }}
                onCloseClick={() => setShowMarkerInfoWindow(false)}
            >
                <Layout>
                    <Text category="h6">Location {routeLocation.index + 1}: {routeLocation.name}</Text>
                    <Text category="s1">{routeLocation.address.formatted_address}</Text>
                </Layout>
            </InfoWindow>
        );
    };

    const renderGroupMarkers = useCallback(() => {
        if (selectedRoute && selectedRoute.details.legs.length > 0) {
            const markers = [];
            const firstLeg = selectedRoute.details.legs[0];
            const lastLeg = selectedRoute.details.legs[selectedRoute.details.legs.length - 1];

            // Render start marker
            if (firstLeg.origin) {
                markers.push(
                    <Marker
                        key="start-selected"
                        position={{ lat: firstLeg.origin.lat, lng: firstLeg.origin.lng }}
                        icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        title="Start"
                    />
                );
            }

            // Render end marker
            if (lastLeg.destination && firstLeg.origin && (lastLeg.destination.lat !== firstLeg.origin.lat || lastLeg.destination.lng !== firstLeg.origin.lng)) {
                markers.push(
                    <Marker
                        key="end-selected"
                        position={{ lat: lastLeg.destination.lat, lng: lastLeg.destination.lng }}
                        icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        title="End"
                    />
                );
            }

            return markers;
        }

        // If no route is selected, render markers for all groups (existing code)
        return routeGroups.flatMap((group, groupIndex) => {
            const markers = [];

            if (group.startAddress) {
                markers.push(
                    <Marker
                        key={`start-${groupIndex}`}
                        position={{ lat: group.startAddress.location.lat, lng: group.startAddress.location.lng }}
                        icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        title={`Group ${groupIndex + 1} Start: ${group.startAddress.name}`}
                    />
                );
            }

            if (group.endAddress && group.endAddress !== group.startAddress) {
                markers.push(
                    <Marker
                        key={`end-${groupIndex}`}
                        position={{ lat: group.endAddress.location.lat, lng: group.endAddress.location.lng }}
                        icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        title={`Group ${groupIndex + 1} End: ${group.endAddress.name}`}
                    />
                );
            }

            if (group.roundTripAddress) {
                markers.push(
                    <Marker
                        key={`roundtrip-${groupIndex}`}
                        position={{ lat: group.roundTripAddress.location.lat, lng: group.roundTripAddress.location.lng }}
                        icon={{
                            url: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
                            scaledSize: new window.google.maps.Size(40, 40),
                        }}
                        title={`Group ${groupIndex + 1} Round Trip: ${group.roundTripAddress.name}`}
                    />
                );
            }

            return markers;
        });
    }, [routeGroups, selectedRoute]);

    const memoizedPreviewRouteLocations = useMemo(() => {
        if (!previewRouteLocations) return null;
        console.log("🚀============== ~ file: routePlanningMap.component.tsx:328 ~ memoizedPreviewRouteLocations ~ previewRouteLocations🚀==============", previewRouteLocations)
        return previewRouteLocations.map((location, index) => (
            <Marker
                key={`marker-${index}`}
                position={location.location}
                //@ts-ignore
                label={index + 1}
                icon={{
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 3,
                    fillColor: "red",
                    fillOpacity: 0.85,
                    strokeColor: "red",
                    strokeWeight: 4,
                }}
            />
        ));
    }, [previewRouteLocations]);

    return (
        <View style={{ flex: 1 }}>
            {isLoaded && (
                <GoogleMap
                    options={{
                        disableDefaultUI: true,
                        zoomControl: true,
                        fullscreenControl: false,
                        streetViewControl: false,
                        mapTypeControl: true,
                        rotateControl: false,
                    }}
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={10}
                    onLoad={(map: any) => { mapRef.current = map; }}
                    onUnmount={() => { mapRef.current = null; }}
                    onClick={onMapClick}
                >
                    {memoizedPreviewRouteLocations}
                    {showMarkerInfoWindow && (
                        <RenderMarkerInfoWindow routeLocation={selectedMarkerRouteLocation as RouteLocation} />
                    )}
                    {(selectedRoute ? [selectedRoute] : routes).flatMap((route, routeIndex) =>
                        route.details.locations.map((routeLocation, index) => (
                            <Marker
                                key={`marker-${routeIndex}-${index}`}
                                position={routeLocation.location}
                                // draggable={true}
                                // onDragEnd={(event: any) => {
                                //     const location = event?.latLng.toJSON();
                                //     onDragMarker(location, index, routeIndex);
                                // }}
                                label={(index + 1).toString()}
                                onMouseOver={() => onHoverMarkerEvent(routeLocation)}
                                onMouseOut={() => onHoverMarkerEvent(null)}
                                onClick={() => onClickMarkerEvent(routeLocation, routeIndex)}
                            />
                        ))
                    )}
                    {customStartPin && (
                        <Marker
                            position={{ lat: customStartPin.location.lat, lng: customStartPin.location.lng }}
                            icon={{
                                url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                                scaledSize: new window.google.maps.Size(50, 50),
                            }}
                            title={`Custom Start: ${customStartPin.name}`}
                        />
                    )}
                    {customEndPin && (
                        <Marker
                            position={{ lat: customEndPin.location.lat, lng: customEndPin.location.lng }}
                            icon={{
                                url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                                scaledSize: new window.google.maps.Size(50, 50),
                            }}
                            title={`Custom End: ${customEndPin.name}`}
                        />
                    )}
                    {memoizedPolylines.map((polyline, index) => (
                        <Polyline
                            key={`polyline-${index}`}
                            path={polyline.path}
                            options={polyline.options}
                        />
                    ))}

                    {renderGroupMarkers()}
                </GoogleMap>
            )}
        </View>
    );
};
