import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, View, Image, TouchableWithoutFeedback, BackHandler, Dimensions, Animated } from "react-native";
import { Divider, Layout, Text, Card, Button, Input, Icon, useTheme, Modal, Spinner } from "@ui-kitten/components";
import { Feather } from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import { debounce } from "lodash";
import * as UserController from "../../functions/user.controller";
import * as StorageController from "../../functions/storageController";
import { User } from "../../models/User.model";
import * as RootNavigation from '../../routes';

const useInputState = (initialValue = "") => {
    const [value, setValue] = React.useState(initialValue);
    return { value, onChangeText: setValue };
};

export default function LoginScreen({ route, navigation, options }: any) {
    const [errText, setErrText] = React.useState("");
    const [secureTextEntry, setSecureTextEntry] = React.useState(true);
    const [showMFAInput, setShowMFAInput] = React.useState(false);
    const inputUsername = useInputState();
    const inputPassword = useInputState();
    const inputMfa = useInputState();
    const theme = useTheme();

    const [disableLoginButton, setDisableLoginButton] = React.useState(false);
    const [disableMfaButton, setDisableMfaButton] = React.useState(false);
    const fadeAnim = useRef(new Animated.Value(0)).current;

    // making an mfa input box for each digit
    const mfaInputs = Array.from({ length: 6 }, () => useInputState());
    const mfaInputRefs = mfaInputs.map(() => React.useRef(null as any));
    const loginResponseRef = useRef(null as UserController.iLoginResponse | null);

    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
    const [resetPasswordError, setResetPasswordError] = useState("");
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState("");
    const forgotPasswordUsername = useInputState();

    // Add refs for input fields
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);

    // Add key press handler
    const handleKeyPressArr = (int: number) => (e: any) => {
        try {
            if (e.nativeEvent.key === 'Tab') {
                e.preventDefault();
                //@ts-ignore
                passwordRef?.current?.focus();
            }
        } catch (err) {
            console.log(err, e);
        }
    };

    useEffect(() => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: true,
        }).start();
    }, []);

    const FormHeader = (props: any) => (
        <View {...props} style={styles.headerContainer}>
            {/* <Image
				source={require('../../assets/logo.png')}
				style={styles.logo}
				resizeMode="contain"
			/> */}
            <Text category="h5" style={styles.welcomeText}>Welcome Back!</Text>
            <Text category="s1" style={styles.subtitleText}>Please sign in to continue</Text>
            {errText ? (
                <Text status="danger" category="s1" style={styles.errorText}>
                    {errText}
                </Text>
            ) : null}
        </View>
    );

    const FormFooter = (props: any) => (
        <View {...props} style={styles.footerContainer}>
            <Button
                disabled={disableLoginButton}
                style={styles.loginButton}
                size="large"
                onPress={login}>
                {disableLoginButton ? 'Signing In...' : 'Sign In'}
            </Button>
            <TouchableWithoutFeedback onPress={() => setShowForgotPassword(true)}>
                <Text style={styles.forgotPassword}>Forgot Password?</Text>
            </TouchableWithoutFeedback>
        </View>
    );

    const verifyUsernameIsValidAndPasswordIsReset = async () => {
        try {
            const response = await UserController.resetLoginPassword(forgotPasswordUsername.value.trim());
            return response;
        } catch (error) {
            throw error;
        }
    }

    const FormHeaderMFA = (props: any) => (
        <View {...props} style={styles.headerContainer}>
            <Text category='h5' style={styles.welcomeText}>Two-Factor Authentication</Text>
            <Text category='s1' style={styles.subtitleText}>Enter the code sent to your device</Text>
            {errText ? (
                <Text status="danger" category="s1" style={styles.errorText}>
                    {errText}
                </Text>
            ) : null}
        </View>
    );

    const FormFooterMFA = (props: any) => (
        <View {...props} style={styles.footerContainer}>
            <Button
                style={styles.loginButton}
                size="large"
                disabled={disableMfaButton}
                onPress={verifyMFA}>
                {disableMfaButton ? 'Verifying...' : 'Verify Code'}
            </Button>
        </View>
    );

    // capture back button
    useEffect(() => {
        const backHandler = BackHandler.addEventListener(
            "hardwareBackPress",
            // do nothing
            () => true
        );

        return () => backHandler.remove();
    }, []);

    const renderIcon = () => (
        <TouchableWithoutFeedback onPress={toggleSecureEntry}>
            <Feather name={secureTextEntry ? "eye-off" : "eye"} size={16} color="white" />
        </TouchableWithoutFeedback>
    );

    const toggleSecureEntry = () => {
        setSecureTextEntry(!secureTextEntry);
    };

    const nav = async (loginResult: UserController.iLoginResponse | UserController.iMfaLoginResponse) => {
        options.fn_update()
        RootNavigation.navigate("CompanySelect");
    }

    const login = async () => {
        try {
            setDisableLoginButton(true);
            const loginResponse: UserController.iLoginResponse = await UserController.login(inputUsername.value.trim(), inputPassword.value.trim());
            console.log("🚀============== ~ file: Login.component.tsx:107 ~ login ~ loginResponse================", loginResponse)
            if (loginResponse.statusCode === 429) {
                let msg = "Too many login attempts, please try again later"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                setDisableLoginButton(false);
                return
            }
            loginResponseRef.current = loginResponse
            if (!loginResponse.success) {
                let msg = loginResponse.error || "Login Error"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                setDisableLoginButton(false);
                return
            }

            if (loginResponse.success && !loginResponse.mfa_enabled) {
                nav(loginResponse)
                Toast.show({
                    type: 'success',
                    text1: loginResponse.message,
                    text2: 'Logged in!'
                });
                setDisableLoginButton(false);
            }

            if (loginResponse.success && loginResponse.mfa_enabled) {
                setShowMFAInput(true)
                Toast.show({
                    type: 'info',
                    text1: loginResponse.message,
                    text2: '👋'
                });
                setDisableLoginButton(false);
            }
        } catch (e) {
            console.log(e);
            Toast.show({
                type: "error",
                position: "bottom",
                text1: "Error",
                text2: "An error occurred while logging in",
            });
            setDisableLoginButton(false);
        }
    };

    const verifyMFA = async () => {
        try {
            setDisableMfaButton(true);
            console.log("🚀============== ~ file: Login.component.tsx:160 ~ verifyMFA ~ loginResponseRef.current================", loginResponseRef.current)
            if (!loginResponseRef.current || !loginResponseRef.current?.mfa_access_token) {
                let msg = "MFA Error"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                setDisableMfaButton(false);
                return
            }

            // get the 6 digit mfa code
            const mfaCode = mfaInputs.map((input) => input.value).join("");
            const mfaLoginResponse: UserController.iMfaLoginResponse = await UserController.verifyMFA(loginResponseRef.current?.mfa_access_token, mfaCode);
            console.log("🚀============== ~ file: login.component.tsx:161 ~ verifyMFA ~ mfaLoginResponse🚀==============", mfaLoginResponse)
            if (mfaLoginResponse.statusCode === 429) {
                let msg = "Too many login attempts, please try again later"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                setDisableMfaButton(false);
                return
            }
            if (!mfaLoginResponse.success) {
                let msg = "MFA Error"
                setErrText(msg.toString())
                Toast.show({
                    type: 'error',
                    text1: msg,
                    text2: '👋'
                });
                setDisableMfaButton(false);
                return
            }
            if (mfaLoginResponse.success) {
                const user: User = new User(mfaLoginResponse.user)
                StorageController.appState.user = user
                Toast.show({
                    type: 'success',
                    text1: mfaLoginResponse.message,
                    text2: 'Logged in!'
                });
                setTimeout(() => {
                    setDisableMfaButton(false);
                    nav(mfaLoginResponse)
                }, 1000);
            }
        } catch (error) {
            let msg = "MFA Error"
            setErrText(msg.toString())
            Toast.show({
                type: 'error',
                text1: msg,
                text2: '👋'
            });
            setDisableMfaButton(false);
        }
    }

    const maskEmail = (email: string) => {
        try {
            const [username, domain] = email.split('@');
            const maskedUsername = username.slice(0, 3) + '*'.repeat(username.length - 3);
            const maskedDomain = '*'.repeat(domain.length - 4) + domain.slice(-4);
            return `${maskedUsername}@${maskedDomain}`;
        } catch (error) {
            console.log("🚀============== ~ maskEmail ~ error================", error)
            return email;
        }
    };


    const handleForgotPassword = async () => {
        if (!forgotPasswordUsername.value.trim()) {
            setResetPasswordError("Please enter your username");
            return;
        }

        try {
            setResetPasswordLoading(true);
            setResetPasswordError("");
            setResetPasswordSuccess("");

            const response = await verifyUsernameIsValidAndPasswordIsReset();
            console.log("🚀============== ~ handleForgotPassword ~ response================", response)

            if (response?.success && response.user) {
                if (response.user) {
                    const maskedEmail = maskEmail(response.user.email);
                    setResetPasswordSuccess(`Password reset email sent to ${maskedEmail}`);
                } else {
                    setResetPasswordError("No user found");
                }
                // Close modal after 3 seconds on success
                setTimeout(() => {
                    setShowForgotPassword(false);
                    setResetPasswordSuccess("");
                    forgotPasswordUsername.onChangeText("");
                }, 3000);
            }
            else {
                setResetPasswordError(response?.message || "Failed to reset password. Please try again.");
            }
        } catch (error) {
            console.log("🚀============== ~ handleForgotPassword ~ error================", error)
            setResetPasswordError("An error occurred. Please try again later.");
        } finally {
            setResetPasswordLoading(false);
        }
    };





    return (
        <Layout style={styles.container}>
            <ForgotPasswordModal
                showForgotPassword={showForgotPassword}
                setShowForgotPassword={setShowForgotPassword}
                resetPasswordLoading={resetPasswordLoading}
                resetPasswordError={resetPasswordError}
                resetPasswordSuccess={resetPasswordSuccess}
                forgotPasswordUsername={forgotPasswordUsername}
                setResetPasswordError={setResetPasswordError}
                setResetPasswordSuccess={setResetPasswordSuccess}
                handleForgotPassword={handleForgotPassword}
            />
            <Animated.View style={[styles.animatedContainer, { opacity: fadeAnim }]}>

                {showMFAInput ? (
                    <Card
                        status="primary"
                        disabled={true}
                        style={styles.card}
                        header={FormHeaderMFA}
                        footer={FormFooterMFA}>
                        <View style={styles.mfaInputContainer}>
                            {mfaInputs.map((input, index) => (
                                <Input
                                    key={index}
                                    ref={mfaInputRefs[index]}
                                    size="large"
                                    style={styles.mfaInput}
                                    maxLength={6}
                                    value={input.value}
                                    keyboardType="numeric"
                                    textStyle={styles.mfaInputText}
                                    onChangeText={(value) => {
                                        if (value.length > 1) {
                                            const splitValues = value.split("").slice(0, mfaInputs.length);
                                            splitValues.forEach((char, i) => {
                                                mfaInputs[i].onChangeText(char);
                                            });
                                            if (splitValues.length === mfaInputs.length) {
                                                mfaInputRefs[mfaInputs.length - 1].current?.focus();
                                            } else {
                                                mfaInputRefs[splitValues.length].current?.focus();
                                            }
                                        } else {
                                            input.onChangeText(value);
                                            if (value !== "") {
                                                if (index < mfaInputs.length - 1) {
                                                    mfaInputRefs[index + 1].current?.focus();
                                                }
                                            }
                                        }
                                    }}
                                    onKeyPress={({ nativeEvent }) => {
                                        if (nativeEvent.key === "Backspace" && index > 0) {
                                            input.onChangeText("");
                                            mfaInputRefs[index - 1].current?.focus();
                                        }
                                    }}
                                />
                            ))}
                        </View>
                    </Card>
                ) : (
                    <Card
                        status="basic"
                        style={styles.card}
                        header={FormHeader}
                        footer={FormFooter}
                        disabled>
                        <Input
                            style={styles.input}
                            size="large"
                            placeholder="Username"
                            accessoryLeft={(props) => (
                                <Icon {...props} name="person-outline" />
                            )}
                            ref={usernameRef}
                            returnKeyType="next"
                            //@ts-ignore
                            onSubmitEditing={() => passwordRef.current?.focus()}
                            onKeyPress={handleKeyPressArr(0)}
                            {...inputUsername}
                        />
                        <Input
                            style={styles.input}
                            size="large"
                            placeholder="Password"
                            accessoryLeft={(props) => (
                                <Icon {...props} name="lock-outline" />
                            )}
                            accessoryRight={(props) => (
                                <TouchableWithoutFeedback onPress={toggleSecureEntry}>
                                    <Icon {...props} name={secureTextEntry ? 'eye-off' : 'eye'} />
                                </TouchableWithoutFeedback>
                            )}
                            secureTextEntry={secureTextEntry}
                            ref={passwordRef}
                            onSubmitEditing={() => login()}
                            {...inputPassword}
                        />
                    </Card>
                )}
            </Animated.View>
        </Layout>
    );
}


interface ForgotPasswordModalProps {
    showForgotPassword: boolean;
    setShowForgotPassword: (show: boolean) => void;
    resetPasswordLoading: boolean;
    resetPasswordError: string;
    resetPasswordSuccess: string;
    forgotPasswordUsername: { value: string; onChangeText: (text: string) => void };
    setResetPasswordError: (error: string) => void;
    setResetPasswordSuccess: (success: string) => void;
    handleForgotPassword: () => void;
}


const ForgotPasswordModal = ({
    showForgotPassword,
    setShowForgotPassword,
    resetPasswordLoading,
    resetPasswordError,
    resetPasswordSuccess,
    forgotPasswordUsername,
    setResetPasswordError,
    setResetPasswordSuccess,
    handleForgotPassword
}: ForgotPasswordModalProps) => (
    <Modal
        visible={showForgotPassword}
        backdropStyle={styles.backdrop}
        onBackdropPress={() => {
            setShowForgotPassword(false);
            setResetPasswordError("");
            setResetPasswordSuccess("");
            forgotPasswordUsername.onChangeText("");
        }}>
        <Card disabled style={styles.forgotPasswordCard}>
            <Text category="h6" style={styles.forgotPasswordTitle}>Reset Password</Text>
            <Text category="s1" style={styles.forgotPasswordSubtitle}>
                Enter your username and we'll send you instructions to reset your password.
            </Text>

            <Input
                style={styles.input}
                size="large"
                placeholder="Username"
                disabled={resetPasswordLoading}
                status={resetPasswordError ? "danger" : "basic"}
                accessoryLeft={(props) => (
                    <Icon {...props} name="person-outline" />
                )}
                {...forgotPasswordUsername}
            />

            {resetPasswordError ? (
                <Text status="danger" category="s1" style={styles.messageText}>
                    {resetPasswordError}
                </Text>
            ) : null}

            {resetPasswordSuccess ? (
                <Text status="success" category="s1" style={styles.messageText}>
                    {resetPasswordSuccess}
                </Text>
            ) : null}

            <View style={styles.forgotPasswordButtons}>
                <Button
                    appearance="ghost"
                    status="basic"
                    disabled={resetPasswordLoading}
                    onPress={() => {
                        setShowForgotPassword(false);
                        setResetPasswordError("");
                        setResetPasswordSuccess("");
                        forgotPasswordUsername.onChangeText("");
                    }}>
                    Cancel
                </Button>
                <Button
                    disabled={resetPasswordLoading}
                    onPress={handleForgotPassword}>
                    {resetPasswordLoading ? (
                        <View style={styles.loadingButton}>
                            <Spinner size="small" status="basic" />
                            <Text style={styles.loadingButtonText}>Sending...</Text>
                        </View>
                    ) : (
                        'Reset Password'
                    )}
                </Button>
            </View>
        </Card>
    </Modal>
);

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
    },
    animatedContainer: {
        width: '100%',
        maxWidth: 400,
    },
    card: {
        width: '100%',
        elevation: 4,
        borderRadius: 10,
    },
    headerContainer: {
        alignItems: 'center',
        paddingVertical: 24,
    },
    logo: {
        width: 120,
        height: 120,
        marginBottom: 16,
    },
    welcomeText: {
        marginBottom: 8,
        fontWeight: 'bold',
    },
    subtitleText: {
        opacity: 0.7,
        marginBottom: 8,
    },
    errorText: {
        marginTop: 8,
        textAlign: 'center',
    },
    input: {
        marginBottom: 16,
    },
    footerContainer: {
        paddingVertical: 24,
        alignItems: 'center',
    },
    loginButton: {
        width: '100%',
        borderRadius: 30,
        marginBottom: 16,
    },
    forgotPassword: {
        opacity: 0.7,
    },
    mfaInputContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 0,
    },
    mfaInput: {
        width: 45,
        marginHorizontal: 1,
    },
    mfaInputText: {
        textAlign: 'center',
        // alignSelf: 'center',
        fontSize: 20,
        width: 40,
        maxWidth: 40,
        marginLeft: -8, //overrides the width of the input
        marginRight: -8,
    },


    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    forgotPasswordCard: {
        width: '90%',
        maxWidth: 400,
        borderRadius: 10,
        marginHorizontal: 20,
    },
    forgotPasswordTitle: {
        textAlign: 'center',
        marginBottom: 8,
    },
    forgotPasswordSubtitle: {
        textAlign: 'center',
        opacity: 0.7,
        marginBottom: 24,
    },
    forgotPasswordButtons: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 24,
        gap: 8,
    },
    messageText: {
        textAlign: 'center',
        marginTop: 8,
    },
    loadingButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
    },
    loadingButtonText: {
        color: 'white',
    },
});
