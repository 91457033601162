import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Layout, Text, Input, Button, useTheme, Icon } from '@ui-kitten/components';
import { LINE_ITEM_BILLED_TO, LineItem } from '../../../../models/Job.model';

interface LineItemComponentProps {
    item: LineItem;
    allowEdit: boolean;
    onRemove: () => void;
    onUpdate: (item: LineItem) => void;
}

export const LineItemComponent = ({ item, allowEdit, onRemove, onUpdate }: LineItemComponentProps) => {
    const theme = useTheme();
    const [isEditing, setIsEditing] = useState(false);
    const [editedItem, setEditedItem] = useState<LineItem | null | any>(null);
    const [quantityError, setQuantityError] = useState('');
    const [priceError, setPriceError] = useState('');

    useEffect(() => {
        setEditedItem(new LineItem(item));
    }, [item]);

    useEffect(() => {
        if (editedItem) {
            validateQuantity(editedItem.quantity.toString());
            validatePrice(editedItem.cost.toString());
        }
    }, [editedItem?.quantity, editedItem?.cost]);

    const validateQuantity = (value: string) => {
        if (value === '') {
            setQuantityError('');
        } else if (isNaN(Number(value))) {
            setQuantityError('Invalid quantity');
        } else {
            setQuantityError('');
        }
    };

    const validatePrice = (value: string) => {
        if (value === '') {
            setPriceError('');
        } else if (isNaN(Number(value))) {
            setPriceError('Invalid price');
        } else {
            setPriceError('');
        }
    };

    const onSetPaid = (paid: boolean) => {
        onUpdate(new LineItem({ ...editedItem, paid }));
    };

    const onSetBilling = (billed_to: LINE_ITEM_BILLED_TO) => {
        onUpdate(new LineItem({ ...editedItem, billed_to }));
    };

    const handleSave = () => {
        if (editedItem) {
            onUpdate(editedItem);
            setIsEditing(false);
        }
    };

    const getSafeCost = (cost: number) => {
        if (isNaN(cost)) {
            return 0;
        }
        try {
            return parseFloat(cost.toString());
        } catch (error) {
            console.log(error)
            return 0;
        }
    }

    const getSafeQuantity = (quantity: number) => {
        if (isNaN(quantity)) {
            return 0;
        }
        try {
            return parseInt(quantity.toString());
        } catch (error) {
            console.log(error)
            return 0;
        }
    }
    if (!editedItem) return null;

    return (
        <View style={styles.container}>
            {isEditing ? (
                <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap', flex: 1 }}>
                        <Input
                            size='small'
                            label="Name"
                            value={editedItem.name}
                            onChangeText={(text) => {
                                setEditedItem({ ...editedItem, name: text });
                            }}
                            style={{ minWidth: 200 }}
                        />
                        <Input
                            size='small'
                            label="Quantity"
                            value={editedItem.quantity.toString()}
                            onChangeText={(text) => {
                                setEditedItem({ ...editedItem, quantity: text || 0 });
                                validateQuantity(text);
                            }}
                            keyboardType="numeric"
                            status={quantityError ? 'danger' : 'basic'}
                            caption={quantityError}
                            style={{ minWidth: 200 }}
                        />
                        <Input
                            size='small'
                            label="Cost"
                            value={editedItem.cost.toString()}
                            onChangeText={(text) => {
                                setEditedItem({ ...editedItem, cost: text || 0 });
                                validatePrice(text);
                            }}
                            keyboardType="decimal-pad"
                            style={{ minWidth: 200 }}
                            status={priceError ? 'danger' : 'basic'}
                            caption={priceError}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', gap: 5 }}>
                        <Button
                            size='tiny'
                            onPress={handleSave}
                            style={{ maxHeight: 30 }}
                            disabled={!!quantityError || !!priceError}
                        >
                            Save
                        </Button>
                        <Button
                            size='tiny'
                            appearance="ghost"
                            status="basic"
                            onPress={() => setIsEditing(false)}
                            style={{ maxHeight: 30 }}
                        >
                            Cancel
                        </Button>
                    </View>
                </View>
            ) : (
                <View style={{ flexDirection: 'column' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Text category='s1' style={[]}> {editedItem.name} </Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 3, alignItems: 'flex-end', alignContent: 'flex-end' }}>
                            <Text style={[styles.detailTextPill, { backgroundColor: theme['color-info-900'] }]}> {getSafeQuantity(editedItem?.quantity)}</Text>
                            <Text style={{ alignContent: 'center' }}>x</Text>
                            <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-900'] }]}> ${getSafeCost(editedItem?.cost)} </Text>
                            {allowEdit && (
                                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                    <Button
                                        size='tiny'
                                        appearance="ghost"
                                        onPress={() => setIsEditing(true)}
                                        accessoryLeft={<Icon name='edit-outline' />}
                                    />
                                    <Button
                                        size='tiny'
                                        appearance="ghost"
                                        status="danger"
                                        onPress={onRemove}
                                        accessoryLeft={<Icon name='trash-outline' />}
                                    />
                                </View>
                            )}
                        </View>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 5 }}>

                        <View style={{ flexDirection: 'row', gap: 2, flexWrap: 'wrap', flex: 1 }}>
                            <Button
                                size='tiny'
                                appearance={editedItem.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST ? 'filled' : 'outline'}
                                status={editedItem.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST ? 'primary' : 'basic'}
                                onPress={() => onSetBilling(LINE_ITEM_BILLED_TO.CUSTOMER_COST)}
                                style={styles.toggleButton}
                            >
                                Customer Cost
                            </Button>
                            <Button
                                size='tiny'
                                appearance={editedItem.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK ? 'filled' : 'outline'}
                                status={editedItem.billed_to === LINE_ITEM_BILLED_TO.BILL_ALL_BACK ? 'primary' : 'basic'}
                                onPress={() => onSetBilling(LINE_ITEM_BILLED_TO.BILL_ALL_BACK)}
                                style={styles.toggleButton}
                            >
                                Bill All Back
                            </Button>
                        </View>
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', gap: 5 }}>

                            {editedItem.billed_to === LINE_ITEM_BILLED_TO.CUSTOMER_COST && (
                                <View style={{ flexDirection: 'row', gap: 5 }}>
                                    <Button
                                        size='tiny'
                                        appearance={editedItem.paid ? 'filled' : 'outline'}
                                        status={editedItem.paid ? 'success' : 'basic'}
                                        onPress={() => onSetPaid(true)}
                                        style={styles.toggleButton}
                                    >
                                        Paid
                                    </Button>
                                    <Button
                                        size='tiny'
                                        appearance={editedItem.paid ? 'outline' : 'filled'}
                                        status={editedItem.paid ? 'basic' : 'warning'}
                                        onPress={() => onSetPaid(false)}
                                        style={styles.toggleButton}
                                    >
                                        Unpaid
                                    </Button>
                                </View>
                            )}

                        </View>
                    </View>
                </View>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 10,
        borderBottomWidth: 1,
        borderColor: '#ccc',
    },
    input: {
        marginVertical: 5,
    },
    label: {
        marginHorizontal: 5,
    },
    buttonGroup: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 10,
    },
    toggleButton: {
        flex: 1,
        marginHorizontal: 5,
    },
    buttonRow: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 5,
    },
    button: {
        marginLeft: 5,
    },
    detailTextPill: {
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 3,
        paddingVertical: 1,
        // borderColor: theme['color-primary-500'], // Use theme color for border
    },
});