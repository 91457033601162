import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { Button, Icon, Text, Card, Input } from '@ui-kitten/components';
import { ExtendedInventoryItem, PricingTier } from '../../../../models/Inventory.model';
import { LINE_ITEM_BILLED_TO } from '../../../../models/LineItem.model';
import { LineItem } from '../../../../models/LineItem.model';
import { Client } from '../../../../models/Client.model';
import * as StorageController from '../../../../functions/storageController';
import Toast from 'react-native-toast-message';
import DMModal from '../../../common/Modal';
import { StyleSheet } from 'react-native';

interface RenderInventoryItemProps {
    item: ExtendedInventoryItem;
    theme: any;
    onSetSelectedItems: (items: LineItem[]) => void;
    selectedItems: LineItem[];
    selectedClient: Client | null;
    companyInventoryList: any[];
    getCategoryNameFromId: (categoryId: string) => string;
    pricingTier: PricingTier | null;
    itemStockLevel: number;
}

export const RenderInventoryItem = ({
    item,
    theme,
    onSetSelectedItems,
    selectedItems,
    selectedClient,
    companyInventoryList,
    getCategoryNameFromId,
    pricingTier,
    itemStockLevel,
}: RenderInventoryItemProps) => {
    if (!item) return null;

    const [isQuantityModalVisible, setIsQuantityModalVisible] = useState(false);
    const [customQuantity, setCustomQuantity] = useState('');
    const [quantityError, setQuantityError] = useState('');
    const defaultBilledTo = LINE_ITEM_BILLED_TO.BILL_ALL_BACK;

    // Get current quantity from selected items
    const getCurrentQuantity = () => {
        const existingItem = selectedItems.find(selectedItem => selectedItem.inventoryItemId === item._id);
        return existingItem ? existingItem.quantity.toString() : '1';
    };

    useEffect(() => {
        // Set initial custom quantity when modal opens
        if (isQuantityModalVisible) {
            setCustomQuantity(getCurrentQuantity());
        }
    }, [isQuantityModalVisible]);

    useEffect(() => {
        if (customQuantity === '' || isNaN(Number(customQuantity))) {
            setQuantityError('Invalid quantity');
        } else {
            setQuantityError('');
        }
    }, [customQuantity]);

    const calculateItemTierPrice = (item: ExtendedInventoryItem): number | null => {
        if (!selectedClient) return null;
        if (pricingTier) {
            if (pricingTier.item_prices?.[item._id as string]) {
                return pricingTier.item_prices[item._id as string];
            } else if (pricingTier.multiplier) {
                return item.default_price * pricingTier.multiplier;
            } else if (pricingTier.fixed_price) {
                return pricingTier.fixed_price;
            }
        }
        return null;
    };

    const itemHasGST = (item: ExtendedInventoryItem): boolean => {
        return pricingTier ? pricingTier.is_gst_inclusive : StorageController.getCurrentCompany()?.settings?.business_info?.gst_registered || true;
    };

    const tierPrice = pricingTier ? calculateItemTierPrice(item) : null;
    const isGSTRegistered = StorageController.getCurrentCompany()?.settings?.business_info?.gst_registered ?? false

    const handleCustomQuantityAdd = (quantity?: number) => {
        const quantityToUse = quantity ?? parseInt(customQuantity);
        if (quantityToUse > 0) {
            const category = companyInventoryList.find(c => c._id === item.inventory_category_id);
            const clientTier = category?.pricing_tiers?.find((tier: PricingTier) =>
                tier.client_ids?.includes(selectedClient?._id as string)
            );
            const includesGST = () => {
                if (!isGSTRegistered) return true;
                if (clientTier) {
                    return clientTier.is_gst_inclusive;
                }
                return true;
            }
            console.log("🚀============== ~ file: RenderInventoryItem.component.tsx:78 ~ handleCustomQuantityAdd ~ includesGST🚀==============", includesGST())

            const cost = tierPrice !== null ? tierPrice : item.default_price
            console.log("🚀============== ~ file: RenderInventoryItem.component.tsx:79 ~ handleCustomQuantityAdd ~ cost🚀==============", cost)
            const adjustedCost = includesGST() ? cost : (cost * 1.1)
            console.log("🚀============== ~ file: RenderInventoryItem.component.tsx:81 ~ handleCustomQuantityAdd ~ adjustedCost🚀==============", adjustedCost)
            const lineItem = new LineItem({
                name: item.name,
                description: item.description || "",
                quantity: quantityToUse,
                cost: adjustedCost,
                billed_to: defaultBilledTo,
                inventoryItemId: item._id,
                // is_gst_inclusive: includesGST,
                is_gst_inclusive: true,
            });
            console.log("🚀============== ~ file: RenderInventoryItem.component.tsx:86 ~ handleCustomQuantityAdd ~ lineItem🚀==============", lineItem)
            onSetSelectedItems([...selectedItems, lineItem]);
            Toast.show({
                type: 'success',
                position: 'bottom',
                text1: 'Items Added',
                text2: `${quantityToUse}x ${item.name} added to selected items`,
                visibilityTime: 1000,
                autoHide: true,
            });
        }
        setIsQuantityModalVisible(false);
        setCustomQuantity('');
    };

    const getStockThemeColour = () => {
        if (itemStockLevel > 0 && itemStockLevel < 5) {
            return theme['color-warning-700'];
        } else if (itemStockLevel <= 0) {
            return theme['color-danger-transparent'];
        }
        return theme['color-basic-400'];
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerRow}>
                <Text category='s1' style={styles.detailTextPill}>{item.name}</Text>
                <View style={styles.priceContainer}>
                    <Icon name='shopping-cart-outline' fill={theme['color-primary-500']} style={styles.icon} />
                    {tierPrice !== null ? (
                        <Text style={[styles.detailTextPill, { backgroundColor: theme['color-info-700'] }]}>
                            Price: ${tierPrice.toFixed(2)} {itemHasGST(item) ? '(GST Inclusive)' : '(GST Exclusive)'}
                        </Text>
                    ) :
                        <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-800'] }]}>
                            <>
                                ${item.default_price.toFixed(2)}
                                {isGSTRegistered && (itemHasGST(item) ? '(GST Inclusive)' : '(GST Exclusive)')}
                            </>
                        </Text>
                    }
                </View>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
                <Text style={[styles.stockLevel, { backgroundColor: getStockThemeColour() }]}>
                    Stock: {itemStockLevel}
                </Text>
            </View>
            <View style={styles.detailsRow}>
                <Text>{getCategoryNameFromId(item.inventory_category_id as string)}</Text>
                <View style={styles.skuContainer}>
                    <Text category='s1' style={styles.skuLabel}>SKU: </Text>
                    <Text style={styles.skuValue}>{item.sku ?? 'N/A'}</Text>
                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        size='tiny'
                        status='success'
                        onPress={() => setIsQuantityModalVisible(true)}>
                        ++
                    </Button>
                    <Button
                        size='tiny'
                        status='success'
                        // onPress={() => onAddItemToSelectedItems(item)}>
                        onPress={() => {
                            handleCustomQuantityAdd(1);
                        }}>
                        1x
                    </Button>
                </View>
            </View>

            <RenderCustomQuantityModal
                isQuantityModalVisible={isQuantityModalVisible}
                setIsQuantityModalVisible={setIsQuantityModalVisible}
                item={item}
                theme={theme}
                customQuantity={customQuantity}
                setCustomQuantity={setCustomQuantity}
                quantityError={quantityError}
                handleCustomQuantityAdd={handleCustomQuantityAdd}
                itemStockLevel={itemStockLevel}
            />
        </View >
    );
};

interface RenderCustomQuantityModalProps {
    isQuantityModalVisible: boolean;
    setIsQuantityModalVisible: (visible: boolean) => void;
    item: ExtendedInventoryItem;
    theme: any;
    customQuantity: string;
    setCustomQuantity: (quantity: string) => void;
    quantityError: string;
    handleCustomQuantityAdd: () => void;
    itemStockLevel: number;
}

const RenderCustomQuantityModal = ({
    isQuantityModalVisible,
    setIsQuantityModalVisible,
    item,
    theme,
    customQuantity,
    setCustomQuantity,
    quantityError,
    handleCustomQuantityAdd,
    itemStockLevel,
}: RenderCustomQuantityModalProps) => {
    return (
        <DMModal
            visible={isQuantityModalVisible}
            onClose={() => setIsQuantityModalVisible(false)}
            style={styles.modal}>
            <Card disabled>
                <Text category='s1'>Add Custom Quantity</Text>
                <View style={styles.detailTextPill}>
                    <Text>{item.name}</Text>
                    <Text style={{ backgroundColor: theme['color-success-800'] }}>
                        ${item.default_price.toFixed(2)}
                    </Text>
                </View>
                <Text category='c1' style={{ marginBottom: 8 }}>
                    Available Stock: {itemStockLevel}
                </Text>
                <Input
                    label="Quantity"
                    placeholder="Enter quantity"
                    value={customQuantity}
                    onChangeText={setCustomQuantity}
                    keyboardType="numeric"
                    caption={quantityError}
                    status={quantityError ? 'danger' : 'basic'}
                    style={styles.quantityInput}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter' && !quantityError && customQuantity !== '') {
                            handleCustomQuantityAdd();
                        }
                    }}
                    onLayout={(event: any) => {
                        event?.nativeEvent?.target?.focus();
                    }}
                />
                <View style={styles.modalButtonContainer}>
                    <Button
                        size='small'
                        status='basic'
                        onPress={() => setIsQuantityModalVisible(false)}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!!quantityError || Number(customQuantity) > itemStockLevel}
                        size='small'
                        onPress={handleCustomQuantityAdd}>
                        Add
                    </Button>
                </View>
            </Card>
        </DMModal>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        borderWidth: 1,
        borderColor: '#e0e0e0',
        padding: 3,
        borderRadius: 8
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    detailTextPill: {
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 3,
        paddingVertical: 1,
    },
    priceContainer: {
        flexDirection: 'row',
        gap: 3,
        alignItems: 'center'
    },
    detailsRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    skuContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5
    },
    skuLabel: {
        fontSize: 8
    },
    skuValue: {
        fontSize: 8
    },
    buttonContainer: {
        flexDirection: 'row',
        gap: 5
    },
    icon: {
        width: 15,
        height: 15,
    },
    modal: {
        width: '80%',
        maxWidth: 500,
    },
    quantityInput: {
        marginVertical: 10
    },
    modalButtonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 10
    },
    nameAndStockContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    stockLevel: {
        fontSize: 12,
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 4,
        overflow: 'hidden',
    },
}); 