import React, { useState, useEffect, useCallback } from 'react';
import { View, StyleSheet } from 'react-native';
import { Input, Button, Text, Modal, Select, IndexPath, SelectItem, Layout } from '@ui-kitten/components';
import { Client } from '../../models/Client.model';
import { getClientsByCompanyId } from '../../functions/client.controller';
import * as StorageController from '../../functions/storageController';
import { CustomPricing } from '../../models/Inventory.model';



interface AddCustomPricingModalProps {
    visible: boolean;
    onClose: () => void;
    onAddPricing: (pricing: CustomPricing) => void;
    companyId: string;
    inventoryItemId: string;
}


const AddCustomPricingModal = ({ visible, onClose, onAddPricing, companyId, inventoryItemId }: AddCustomPricingModalProps) => {
    const [clients, setClients] = useState<Client[]>([]);
    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const [price, setPrice] = useState<number | null>(null);
    const [selectedClientIndex, setSelectedClientIndex] = useState<IndexPath | IndexPath[] | undefined>(undefined);

    useEffect(() => {
        const fetchClients = async () => {
            const company = StorageController.getCurrentCompany();
            const clients = company.clients;
            setClients(clients);
        };
        fetchClients();
    }, [companyId]);

    const handleAddPricing = useCallback(() => {
        if (selectedClient && price) {
            onAddPricing({
                client_id: selectedClient._id,
                price: price,
                _id: null,
                company_id: companyId,
                inventory_item_id: inventoryItemId,
                details: undefined,
                createdAt: '',
                updatedAt: ''
            });
            onClose();
        }
    }, [selectedClient, price, companyId, inventoryItemId, onAddPricing, onClose]);

    const handleClientChange = (index: IndexPath | IndexPath[]) => {
        if (Array.isArray(index)) {
            setSelectedClientIndex(index);
            setSelectedClient(clients[index[0].row]);
        } else {
            setSelectedClientIndex(index);
            setSelectedClient(clients[index.row]);
        }
    };

    return (
        <Modal visible={visible} onBackdropPress={onClose} backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <Layout style={styles.container}>
                <Text>Select Client:</Text>
                <Select
                    placeholder="Select Client"
                    selectedIndex={selectedClientIndex}
                    onSelect={handleClientChange}
                    value={selectedClient?.name}
                >
                    {clients.map((client: Client) => (
                        <SelectItem key={client._id} title={client.name} />
                    ))}
                </Select>
                <Input placeholder="Price" keyboardType="numeric" onChangeText={(text) => setPrice(Number(text))} />
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>

                    <Button
                        status='success'
                        appearance='filled'
                        onPress={handleAddPricing}
                    >
                        Add Pricing
                    </Button>
                    <Button
                    status='danger'
                        appearance='ghost'
                        onPress={onClose}
                    >
                        Cancel
                    </Button>
                </View>
            </Layout>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        padding: 20,
        gap: 10
    },
});

export default AddCustomPricingModal;