import React, { useState } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    Layout,
    Text,
    Toggle,
    Button,
    Icon,
    Card,
} from '@ui-kitten/components';

export const RenderToggles = ({
    showTrafficFlow = false,
    showDarkMap = false,
    showServiceAreaPolygons = false,
    onSetServiceAreasShown = (e: boolean) => { },
    setShowDarkMap = (e: boolean) => { },
    setShowTrafficFlow = (e: boolean) => { },
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const RenderToggleShowTrafficFlow = () => (
        <Layout style={{ paddingLeft: 5, paddingVertical: 5 }}>
            <Toggle
                checked={showTrafficFlow}
                onChange={(e: any) => setShowTrafficFlow(e)}
            >
                Show Traffic Flow
            </Toggle>
        </Layout>
    );

    const RenderToggleShowDarkMap = () => (
        <Layout style={{ paddingLeft: 5, paddingVertical: 5 }}>
            <Toggle
                checked={showDarkMap}
                onChange={(e: any) => setShowDarkMap(e)}
            >
                Show Dark Map
            </Toggle>
        </Layout>
    );

    const RenderToggleShowServiceAreas = () => (
        <Layout style={{ paddingLeft: 5, paddingVertical: 5 }}>
            <Toggle
                checked={showServiceAreaPolygons}
                onChange={(e: any) => onSetServiceAreasShown(e)}
            >
                Show Service Areas
            </Toggle>
        </Layout>
    );

    return (
        <View
            style={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 1000,
                borderRadius: 4,
                overflow: 'hidden',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
            }}
        >
            <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>

                    <Button
                        appearance="filled"
                        status={isExpanded ? 'danger' : 'warning'}
                        accessoryLeft={(props) => <Icon {...props} name='settings-2-outline' />}
                        onPress={() => setIsExpanded(!isExpanded)}
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: 20,
                            // backgroundColor: 'white'
                        }}
                    />
                </View>
                {isExpanded && (
                    <Card
                        status='basic'
                        disabled={true}
                        header={() => <Text>Map Settings</Text>}
                    >
                        <View style={{ flexDirection: 'row' }}>
                            <Toggle
                                checked={showTrafficFlow}
                                onChange={(e: any) => setShowTrafficFlow(e)}
                            >
                                Show Traffic Flow
                            </Toggle>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Toggle
                                checked={showDarkMap}
                                onChange={(e: any) => setShowDarkMap(e)}
                            >
                                Show Dark Map
                            </Toggle>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Toggle
                                checked={showServiceAreaPolygons}
                                onChange={(e: any) => onSetServiceAreasShown(e)}
                            >
                                Show Service Areas
                            </Toggle>
                        </View>
                    </Card>
                )}
            </View>
        </View>
    );
};
