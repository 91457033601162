import React, { useState, useEffect, useRef } from 'react';
import { View, ScrollView, TouchableOpacity, Animated, StyleSheet } from 'react-native';
//@ts-ignore
import { Layout, Text, Icon, useTheme, Button, Modal, Card, Input } from "@ui-kitten/components";
import { Route, ROUTE_STATUS } from '../../../models/Route.model';
import { Job, JOB_STATUS } from '../../../models/Job.model';
// ... other imports remain the same


export const Badge = ({ status, style, animated = false }: { status: JOB_STATUS, style?: any, animated?: boolean }) => {

    const pulseValue = useRef(new Animated.Value(1)).current;
    useEffect(() => {
        // Start the pulse animation when the component mounts
        if (animated) {
            Animated.loop(
                Animated.sequence([
                    Animated.timing(pulseValue, {
                        toValue: 1.5,
                        duration: 500,
                        useNativeDriver: true,
                    }),
                    Animated.timing(pulseValue, {
                        toValue: 1,
                        duration: 500,
                        useNativeDriver: true,
                    }),
                ])
            ).start();
        }
        return () => {
            pulseValue.stopAnimation();
        };
    }, [animated]);


    const getStatusColor = (status: JOB_STATUS) => {
        switch (status) {
            case JOB_STATUS.QUOTE:
                return '#808080'; // gray
            case JOB_STATUS.REQUEST:
                return '#ff34fb'; // pink
            case JOB_STATUS.UNASSIGNED:
                return '#ff0000'; // red
            case JOB_STATUS.ASSIGNED:
                return '#ffaa00'; // orange
            case JOB_STATUS.PENDING:
                return '#3366ff'; // blue
            case JOB_STATUS.COMPLETE:
                return '#00e096'; // green
            case JOB_STATUS.CANCELLED:
                return 'purple'; // purple
            case JOB_STATUS.TRANSFERRED_OUT:
                return '#00cbff'; // purple
            default:
                return '#808080'; // Default to gray
        }
    };

    const getStatusText = (status: JOB_STATUS) => {
        switch (status) {
            case JOB_STATUS.QUOTE:
                return 'Quote';
            case JOB_STATUS.REQUEST:
                return 'Request';
            case JOB_STATUS.UNASSIGNED:
                return 'Unassigned';
            case JOB_STATUS.ASSIGNED:
                return 'Assigned';
            case JOB_STATUS.PENDING:
                return 'Pending';
            case JOB_STATUS.COMPLETE:
                return 'Complete';
            case JOB_STATUS.CANCELLED:
                return 'Cancelled';
            case JOB_STATUS.TRANSFERRED_OUT:
                return 'Transferred Out';
            default:
                return '';
        }
    };

    return (
        <Animated.View style={[style, { transform: [{ scale: pulseValue }] }]}>
            <Text
                category='s1'
                style={{
                    backgroundColor: getStatusColor(status),
                    padding: 5,
                    borderRadius: 5,
                    fontSize: 12,
                    marginLeft: 8,
                    textAlign: 'center',
                    textShadowColor: 'rgba(0, 0, 0, 0.75)', // Black shadow with 75% opacity
                    // textShadowOffset: { width: 1, height: 1 }, // Shadow offset
                    textShadowRadius: 5, // Shadow blur radius
                }}>
                {status && getStatusText(status) || ""}
            </Text>
        </Animated.View>
    );
};


export const RouteBadge = ({ status, style, animated = false }: { status: ROUTE_STATUS, style?: any, animated?: boolean }) => {
    const getStatusColor = (status: ROUTE_STATUS) => {
        switch (status) {
            case ROUTE_STATUS.PLANNED:
                return '#808080'; // gray
            case ROUTE_STATUS.IN_PROGRESS:
                return '#ff34fb'; // pink
            case ROUTE_STATUS.COMPLETED:
                return '#ffaa00'; // orange
            case ROUTE_STATUS.PENDING:
                return '#3366ff'; // blue
            case ROUTE_STATUS.CANCELLED:
                return 'purple'; // purple
            default:
                return '#808080'; // Default to gray
        }
    };

    const getStatusText = (status: ROUTE_STATUS) => {
        switch (status) {
            case ROUTE_STATUS.PLANNED:
                return 'Planned';
            case ROUTE_STATUS.IN_PROGRESS:
                return 'In Progress';
            case ROUTE_STATUS.COMPLETED:
                return 'Completed';
            case ROUTE_STATUS.CANCELLED:
                return 'Cancelled';
            case ROUTE_STATUS.PENDING:
                return 'Pending';
            default:
                return '';
        }
    };
    return <Text
        category='s1'
        style={{
            backgroundColor: getStatusColor(status),
            padding: 5,
            borderRadius: 5,
            fontSize: 12,
            marginLeft: 8,
            textAlign: 'center',
            textShadowColor: 'rgba(0, 0, 0, 0.75)', // Black shadow with 75% opacity
            // textShadowOffset: { width: 1, height: 1 }, // Shadow offset
            textShadowRadius: 5, // Shadow blur radius
        }}>
        {status && getStatusText(status) || ""}
    </Text>
}