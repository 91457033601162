import React, { useState } from 'react';
import { View } from "react-native";
import {
    Button,
    Card,
    Icon,
    Input,
    Layout,
    Text,
    useTheme,
    Spinner
} from "@ui-kitten/components";
import { Job, JOB_TYPE, JobVehicleDetails, JobEquipmentDetails, TOWING_TYPE, JobAddress } from '../../../models/Job.model';
import { getOpenAiProcessJobText } from '../../../functions/job.controller';

interface FieldMappingProps {
    style?: any;
    JobState: Job;
    selectedJobType: JOB_TYPE;
    enum_JOB_TYPE_OPTION: any;
    onSetCustomerFieldsDetails: (field: string, value: string) => void;
    onSetVehicleFieldDetails: (field: string, value: string) => void;
    onSetTowingDetails: (field: string, value: any) => void;
    onSetJob: (job: any, caller: string) => void;
    onSetAddress: (address: JobAddress) => void;
    jobStateRef: React.MutableRefObject<any>;
    dispatchCommentsValueRef: React.MutableRefObject<string>;
    addressDisplayValue: string;
    setAddressDisplayValue: (value: string) => void;
    towingPickupAddressDisplayValue: string;
    setTowingPickupAddressDisplayValue: (value: string) => void;
    towingDropoffAddressDisplayValue: string;
    setTowingDropoffAddressDisplayValue: (value: string) => void;
    forceUpdate: () => void;
}

const RenderCardHeader = ({ value }: { value: string }) => (
    <Text style={{ fontSize: 14, fontWeight: 'bold' }}>{value}</Text>
);

export const FieldMapping = ({
    style = { flex: 1 },
    JobState,
    selectedJobType,
    enum_JOB_TYPE_OPTION,
    onSetCustomerFieldsDetails,
    onSetVehicleFieldDetails,
    onSetTowingDetails,
    onSetJob,
    onSetAddress,
    jobStateRef,
    dispatchCommentsValueRef,
    addressDisplayValue,
    setAddressDisplayValue,
    towingPickupAddressDisplayValue,
    setTowingPickupAddressDisplayValue,
    towingDropoffAddressDisplayValue,
    setTowingDropoffAddressDisplayValue,
    forceUpdate,
}: FieldMappingProps) => {
    const [parsedPastedInputString, setParsedPastedInputString] = useState("");
    const [parsedData, setParsedData] = useState([] as any[]);
    const theme = useTheme();
    const [isProcessing, setIsProcessing] = useState(false);

    const processText = (pastedText: string) => {
        const delimiters = [',', ':', '\r\n', '\r', '\n', '\t'];
        const regex = new RegExp(delimiters.join('|'), 'g');
        const parts = pastedText.split(regex).map(s => s.trim());
        setParsedData(parts);
    };

    const mapField = (field: string, value: string) => {
        let towing_vehicle_details: JobVehicleDetails = JobState.details.towing_details?.vehicle_details || new JobVehicleDetails({})
        let towing_equipment_details: JobEquipmentDetails = JobState.details.towing_details?.equipment_details || new JobEquipmentDetails({})

        switch (field) {
            // ... rest of the switch statement stays the same
            case 'name':
                onSetCustomerFieldsDetails("name", value)
                break;
            case 'phone':
                onSetCustomerFieldsDetails("phone", value)
                break;
            case 'email':
                onSetCustomerFieldsDetails("email", value)
                break;
            case 'make':
                onSetVehicleFieldDetails("make", value)
                break;
            case 'model':
                onSetVehicleFieldDetails("model", value)
                break;
            case 'year':
                onSetVehicleFieldDetails("year", value)
                break;
            case 'colour':
                onSetVehicleFieldDetails("colour", value)
                break;
            case 'rego':
                onSetVehicleFieldDetails("rego", value)
                break;
            case 'notes':
                let notes = jobStateRef.current.details.comments || ""
                notes += ` ${value}`
                dispatchCommentsValueRef.current = notes
                onSetJob({ ...jobStateRef.current, details: { ...jobStateRef.current.details, comments: notes } }, "mapField")
                break;
            case 'client_reference_id':
                onSetJob({ ...jobStateRef.current, client_reference_id: value }, "mapField")
                break;
            case 'towing_pickup':
                setTowingPickupAddressDisplayValue(towingPickupAddressDisplayValue + " " + value)
                break;
            case 'towing_dropoff':
                setTowingDropoffAddressDisplayValue(towingDropoffAddressDisplayValue + " " + value)
                break;
            case 'towing_rego':
                towing_vehicle_details.rego = value
                onSetTowingDetails("vehicle_details", towing_vehicle_details)
                break;
            case 'towing_make':
                towing_vehicle_details.make = value
                onSetTowingDetails("vehicle_details", towing_vehicle_details)
                break;
            case 'towing_model':
                towing_vehicle_details.model = value
                onSetTowingDetails("vehicle_details", towing_vehicle_details)
                break;
            case 'towing_year':
                towing_vehicle_details.year = value
                onSetTowingDetails("vehicle_details", towing_vehicle_details)
                break;
            case 'towing_colour':
                towing_vehicle_details.colour = value
                onSetTowingDetails("vehicle_details", towing_vehicle_details)
                break;
            case 'towing_equipment_equipment':
                towing_equipment_details.equipment = value
                onSetTowingDetails("equipment_details", towing_equipment_details)
                break;
            case 'towing_equipment_type':
                towing_equipment_details.type = value
                onSetTowingDetails("equipment_details", towing_equipment_details)
                break;
            case 'towing_equipment_weight':
                towing_equipment_details.weight = value
                onSetTowingDetails("equipment_details", towing_equipment_details)
                break;
            case 'towing_equipment_size':
                towing_equipment_details.size = value
                onSetTowingDetails("equipment_details", towing_equipment_details)
                break;
            case 'towing_equipment_serial':
                towing_equipment_details.serial = value
                onSetTowingDetails("equipment_details", towing_equipment_details)
                break;
            case 'address':
                setAddressDisplayValue(addressDisplayValue + " " + value)
                break;
            default:
                break;
        }

        // Remove the mapped value from parsedData array
        setParsedData(parsedData.filter((item: any) => item !== value));
    };

    const processTextWithOpenAi = async (pastedText: string) => {
        setIsProcessing(true);
        try {
            const response = await getOpenAiProcessJobText({ jobText: pastedText, jobType: selectedJobType })
            console.log(response)
            if (response.success) {
                onSetJob({ ...JobState, ...response.job }, "processTextWithOpenAi")

                setAddressDisplayValue(response.job?.details.address.formatted_address || "")
                setTowingPickupAddressDisplayValue(response.job?.details.towing_details?.pickup_address.formatted_address || "")
                setTowingDropoffAddressDisplayValue(response.job?.details.towing_details?.dropoff_address.formatted_address || "")
                setTimeout(() => {
                    onSetAddress(response.job?.details.address || new JobAddress({}))
                }, 100)
                setTimeout(() => {
                    forceUpdate()
                }, 200)
            } else {
                console.error(response.error)
            }
        } catch (error) {
            console.error(error)
        } finally {
            setIsProcessing(false)
        }
    }

    return (
        <Card
            status="info"
            header={() => <RenderCardHeader value="Field Mapping" />}
            disabled={true}
            style={style}>
            {/* Rest of the component JSX stays the same */}
            <Text category='h5'>Paste csv or plain text:</Text>
            <Input
                multiline={true}
                textStyle={{ minHeight: 64 }}
                defaultValue={parsedPastedInputString}
                onChangeText={(nextValue: string) => {
                    setParsedPastedInputString(nextValue);
                }}
            />
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flex: 9 }}></View>
                <Button
                    appearance='outline'
                    status="success"
                    size="small"
                    onPress={() => processText(parsedPastedInputString)}
                    style={{ margin: 5, flex: 1 }}
                    accessoryLeft={<Icon
                        name='file-text-outline'
                        width={24}
                        height={24}
                        fill={theme['text-basic-color']}
                    />}
                >
                    Manual Process
                </Button>
                <Button
                    appearance='outline'
                    status="primary"
                    size="small"
                    onPress={() => processTextWithOpenAi(parsedPastedInputString)}
                    style={{ margin: 5, flex: 1 }}
                    accessoryLeft={<Icon
                        name='bulb-outline'
                        width={24}
                        height={24}
                        fill={theme['text-basic-color']}
                    />}
                >
                    Smart Process
                </Button>
            </View>

            {parsedData.length > 0 && (
                <Layout>
                    <Text category='s1'>Map the following fields:</Text>
                    {parsedData.map((item: any, index: number) => (
                        <View key={index} style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 3 }}>
                            <Text style={{ flex: 2 }}>{item}</Text>
                            <View style={{ flex: 10, flexDirection: "row" }}>
                                {!JobState.details.customer_details?.name && (
                                    <Button appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('name', item)}>Name</Button>
                                )}
                                {!JobState.details.customer_details?.phone && (
                                    <Button appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('phone', item)}>Phone</Button>
                                )}
                                {!JobState.details.customer_details?.email && (
                                    <Button appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('email', item)}>Email</Button>
                                )}

                                <Button status='info' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('notes', item)}>+ Notes</Button>

                                {!JobState.client_reference_id && (
                                    <Button status='purple' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('client_reference_id', item)}>Client Ref ID</Button>
                                )}

                                {selectedJobType == JOB_TYPE.ROADSIDE &&
                                    <>
                                        {!JobState.details.vehicle_details?.make && (
                                            <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('make', item)}>Make</Button>
                                        )}
                                        {!JobState.details.vehicle_details?.model && (
                                            <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('model', item)}>Model</Button>
                                        )}
                                        {!JobState.details.vehicle_details?.year && (
                                            <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('year', item)}>Year</Button>
                                        )}
                                        {!JobState.details.vehicle_details?.colour && (
                                            <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('colour', item)}>Colour</Button>
                                        )}
                                        {!JobState.details.vehicle_details?.rego && (
                                            <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('rego', item)}>Rego</Button>
                                        )}
                                        <Button status="success" appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('address', item)}>+ Address</Button>
                                    </>
                                }

                                {selectedJobType == JOB_TYPE.TOWING &&
                                    <>
                                        {JobState.details.towing_details?.towing_type == TOWING_TYPE.VEHICLE &&
                                            <>
                                                {!JobState.details.towing_details?.vehicle_details?.rego &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_rego', item)}>Rego</Button>
                                                }
                                                {!JobState.details.towing_details?.vehicle_details?.make &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_make', item)}>Make</Button>
                                                }
                                                {!JobState.details.towing_details?.vehicle_details?.model &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_model', item)}>Model</Button>
                                                }
                                                {!JobState.details.towing_details?.vehicle_details?.year &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_year', item)}>Year</Button>
                                                }
                                                {!JobState.details.towing_details?.vehicle_details?.colour &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_colour', item)}>Colour</Button>
                                                }
                                            </>
                                        }
                                        {JobState.details.towing_details?.towing_type == TOWING_TYPE.EQUIPMENT &&
                                            <>
                                                {!JobState.details.towing_details?.equipment_details?.equipment &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_equipment', item)}>Equipment</Button>
                                                }
                                                {!JobState.details.towing_details?.equipment_details?.type &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_type', item)}>Type</Button>
                                                }
                                                {!JobState.details.towing_details?.equipment_details?.weight &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_weight', item)}>Weight</Button>
                                                }
                                                {!JobState.details.towing_details?.equipment_details?.size &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_size', item)}>Size</Button>
                                                }
                                                {!JobState.details.towing_details?.equipment_details?.serial &&
                                                    <Button status='warning' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_equipment_serial', item)}>Serial</Button>
                                                }
                                            </>
                                        }
                                        <Button status='success' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_pickup', item)}>+ Pickup Address</Button>
                                        <Button status='success' appearance='outline' size="small" style={{ margin: 5 }} onPress={() => mapField('towing_dropoff', item)}>+ Dropoff Address</Button>
                                    </>
                                }
                            </View>
                        </View>
                    ))}
                </Layout>
            )}

            {isProcessing && (
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 1000,
                    }}>
                    <Spinner size='giant' status='basic' />
                    <Text style={{ color: 'white', marginTop: 10 }}>Processing text...</Text>
                </View>
            )}
        </Card>
    );
}; 