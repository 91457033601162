import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity, FlatList, Alert, ScrollView } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, List, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Toggle, OverflowMenu, MenuItem, ListItem } from '@ui-kitten/components';
import ErrorBoundary from '../ErrorBoundary.component';
import Stripe from 'stripe';
import * as JobsController from '../../functions/job.controller'
import * as MembershipsController from '../../functions/membership.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ImageController from '../../functions/image.controller'
import * as ClientController from '../../functions/client.controller'
import * as StorageController from '../../functions/storageController'
import * as CompanyController from '../../functions/company.controller'
import * as GoogleController from '../../functions/google.controller'
import { Job } from '../../models/Job.model'
import { ServiceAreaEdit } from './serviceAreaEdit.component'
//@ts-ignore
import { SketchPicker, CirclePicker, SwatchesPicker, SliderPicker, ChromePicker } from 'react-color'
//@ts-ignore
import debounce from 'lodash.debounce';
import { useNotification } from '../../context/notifications.context';
import { Company, CompanySavedAddress, CompanySettings, JobOptions, ScheduleSettings, SMSSettings, EmailSettings, Surcharge, ServiceArea, CompanyWebhookSettings, CompanyTheme, IServiceAreaCoord, CompanyAlerts } from '../../models/Company.model';
import { SavedAddresses } from './address/savedAddresses.component';
import Tooltip from '../modals/Tooltip';
import { CompanySubscription } from '../../models/CompanySubscription.model';
import * as CompanySubscriptionController from '../../functions/CompanySubscription.controller'
interface IStripeSubscriptionSettingsProps {
    company: Company
    onSetCompany: (company: Company) => void
    style?: any 
}


export const StripeSubscriptionSettings = ({
    company,
    onSetCompany: setCompany,
    style = {}
}: IStripeSubscriptionSettingsProps) => {

    const notification = useNotification();
    const [companySubscriptions, setCompanySubscriptions] = useState<CompanySubscription[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)
    const [stripe, setStripe] = useState<Stripe | null>(null)

    useEffect(() => {
        load()
    }, [company])


    const load = async () => {
        setLoading(true)
        try {
            const subscription = await CompanySubscriptionController.getCompanySubscriptionsByCompany(company._id)
            console.log("🚀============== ~ file: stripeSubscriptionSettings.component.tsx:55 ~ load ~ subscription🚀==============", subscription)
            if (!subscription) {
                setCompanySubscriptions([])
            } else {
                setCompanySubscriptions(subscription)
            }
        } catch (error) {
            console.log("🚀============== ~ file: stripeSubscriptionSettings.component.tsx:57 ~ load ~ error🚀==============", error)
        }
        setLoading(false)
    }


    if (!companySubscriptions || companySubscriptions.length === 0) {
        return <Text>No subscription found for this company</Text>
    }

    return (
        <Layout style={{ flex: 1, padding: 20, ...style }}>
            {companySubscriptions?.map((companySubscription, index) => (
                <SubscriptionDetails key={index} companySubscription={companySubscription} />
            ))}
        </Layout>
    )
}


const SubscriptionDetails = ({ companySubscription }: { companySubscription: CompanySubscription }) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(companySubscription.status !== 'active')
    return (
        <Card
            disabled
            header={() => (
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Text category="h6">{companySubscription.name}</Text>
                    <Text category="s1">{companySubscription.status.toLocaleUpperCase()}</Text>
                    <Button
                        appearance="ghost"
                        status="basic"
                        onPress={() => setIsCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? 'Show Details' : 'Hide Details'}
                    </Button>
                </View>
            )}
            status={companySubscription.status === 'active' ? 'success' : 'danger'}

        >
            {isCollapsed ? null : (
                <View style={{ flexDirection: 'column', gap: 10 }}>
                    {companySubscription.stripe_subscription &&
                        <Card
                            disabled
                            style={{ marginTop: 10 }}>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <View style={{ flexDirection: 'column' }}>
                                    <Text category="s1">Currency:</Text>
                                    <Text>{companySubscription.currency.toUpperCase()}</Text>
                                </View>
                                <View style={{ flexDirection: 'column' }}>
                                    <Text category="s1">Stripe Subscription ID:</Text>
                                    <Text>{companySubscription.stripe_subscription.id}</Text>
                                </View>
                                <View style={{ flexDirection: 'column' }}>
                                    <Text category="s1">Status:</Text>
                                    <Text>{companySubscription.status.toLocaleUpperCase()}</Text>
                                </View>
                                <View style={{ flexDirection: 'column' }}>
                                    <Text category="s1">Current Billing Period Start:</Text>
                                    <Text>{new Date(companySubscription.stripe_subscription.current_period_start * 1000).toLocaleDateString()}</Text>
                                </View>
                                <View style={{ flexDirection: 'column' }}>
                                    <Text category="s1">Current Billing Period End:</Text>
                                    <Text>{new Date(companySubscription.stripe_subscription.current_period_end * 1000).toLocaleDateString()}</Text>
                                </View>
                            </View>
                        </Card>
                    }
                    <View style={{ flexDirection: 'row', gap: 5 }}>
                        {companySubscription.stripe_subscription &&
                            <Card
                                disabled
                                header={() => (
                                    <Text category="h6">Plan</Text>
                                )}
                            >
                                {companySubscription.stripe_subscription.items?.data.map((item: any, index: number) => (
                                    <Layout key={index} style={{ marginTop: 10 }}>
                                        <Text category="label">Plan: {item.plan.nickname || ''}</Text>
                                        <Text>
                                            Amount: ${(item.price.unit_amount / 100).toFixed(2)} {companySubscription.currency.toUpperCase()}
                                        </Text>
                                        <Text>Billing Interval: {item.plan.interval_count} {item.plan.interval}</Text>
                                    </Layout>
                                ))}
                            </Card>
                        }
                        <Card
                            disabled
                            header={() => (
                                <Text category="h6">Features</Text>
                            )}
                        >
                            {/* Features */}
                            <View style={{ flexDirection: 'column' }}>
                                {Object.keys(companySubscription.features).map((feature, index) => {
                                    const capitaliseAndRemoveUnderscore = (str: string) => {
                                        return str.replace("_", " ").replace(/\w\S*/g, (txt) => {
                                            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                                        });
                                    }

                                    return (
                                        <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <Text category="s1">{capitaliseAndRemoveUnderscore(feature)}:</Text>
                                            {/*@ts-ignore */}
                                            <Text status={companySubscription.features[feature] ? 'success' : 'danger'} >{companySubscription.features[feature] ? 'Yes' : 'No'}</Text>
                                        </View>
                                    )
                                })}
                            </View>
                        </Card>
                    </View>
                    {companySubscription.stripe_subscription &&
                        <Button
                            style={{ marginTop: 10 }}
                            onPress={async () => {
                                const response = await CompanySubscriptionController.createStripePortalSession(companySubscription)
                                console.log("🚀============== ~ file: stripeSubscriptionSettings.component.tsx:94 ~ onPress={ ~ response🚀==============", response)
                                window.open(response.url, '_blank')
                            }}
                        >
                            Manage Subscription in Stripe
                        </Button>
                    }
                </View>
            )}
        </Card>
    )
}
