import React, { useState, useEffect, useMemo } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Layout, List, ListItem, Text, Button, Icon, Autocomplete, AutocompleteItem, Input, useTheme } from '@ui-kitten/components';
import * as InventoryController from '../../functions/Inventory.controller';
import { Company } from '../../models/Company.model';
import { InventoryCategory, InventoryItem } from '../../models/Inventory.model';
import PartsNetworkItemDetail from './PartsNetworkItemDetail';
import { CartItem } from './PartsNetworkContainerScreen';
import * as ClientController from '../../functions/client.controller';
import { Client } from '../../models/Client.model';

interface VendorStoreProps {
    vendor: Company;
    onBack: () => void;
    addToCart: (item: InventoryItem, quantity: number, isGSTInclusive: boolean) => void;
    cart: CartItem[];
    clientCompany: Company;
}

export const VendorStore: React.FC<VendorStoreProps> = ({
    vendor,
    onBack,
    addToCart,
    cart,
    clientCompany
}) => {
    const [categories, setCategories] = useState<InventoryCategory[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<InventoryCategory | null>(null);
    const [items, setItems] = useState<InventoryItem[]>([]);
    const [filteredItems, setFilteredItems] = useState<InventoryItem[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [ownClientProfile, setOwnClientProfile] = useState<Client | null>(null);
    const theme = useTheme();

    useEffect(() => {
        console.log("🚀============== ~ file: VendorStore.tsx:36 ~ useEffect ~ vendor🚀==============", vendor)
        fetchCategories();
        fetchClientProfile();
    }, [vendor, clientCompany]);

    useEffect(() => {
        setFilteredItems(items);
    }, [items]);


    const fetchCategories = async () => {
        const vendorCategories = await InventoryController.getInventoryCategoriesByCompanyId(vendor._id);

        console.log("🚀============== ~ file: VendorStore.tsx:46 ~ fetchCategories ~ vendorCategories🚀==============", vendorCategories)
        if (vendorCategories) {
            //categories that arent deleted
            const filteredCategories = vendorCategories.filter(category => !category.deleted);
            setCategories(filteredCategories);
        }
    };

    const fetchClientProfile = async () => {
        console.log("🚀============== ~ file: VendorStore.tsx:55 ~ fetchClientProfile ~ vendor._id, clientCompany._id🚀==============", vendor._id, clientCompany._id)
        const clientProfile = await ClientController.getClientByCompanyAndClientLinkingId(vendor._id, clientCompany._id);
        console.log("🚀============== ~ file: VendorStore.tsx:56 ~ fetchClientProfile ~ clientProfile🚀==============", clientProfile)
        if (clientProfile) {
            console.log("🚀============== ~ file: VendorStore.tsx:54 ~ fetchClientProfile ~ clientProfile🚀==============", clientProfile)
            setOwnClientProfile(clientProfile);
        }
    };

    const fetchItems = async (category: InventoryCategory) => {
        if (category._id) {
            const categoryItems = await InventoryController.getInventoryItemsByCategory(category._id);
            if (categoryItems) {
                setItems(categoryItems);
                setFilteredItems(categoryItems);
            }
        }
        setSelectedCategory(category);
        setSearchQuery('');
    };

    const cartQuantity = (itemId: string | null | undefined): number => {
        if (!itemId) return 0;
        const item = cart.find(cartItem => cartItem._id === itemId);
        return item ? item.quantity : 0;
    };

    const handleAddToCart = (item: InventoryItem, quantity: number, price: number, isGSTInclusive: boolean) => {
        const updatedItem = new InventoryItem({ ...item, default_price: price });
        console.log("🚀============== ~ file: VendorStore.tsx:87 ~ handleAddToCart ~ updatedItem🚀==============", updatedItem, isGSTInclusive)
        addToCart(updatedItem, quantity, isGSTInclusive);
    };

    const renderItemListItem = ({ item }: { item: InventoryItem }) => (
        <PartsNetworkItemDetail
            key={item._id}
            item={item}
            onAddToCart={handleAddToCart}
            cartQuantity={cartQuantity(item._id)}
            currentCompany={clientCompany}
            vendor={vendor}
            ownClientProfile={ownClientProfile}
            cart={cart}
            //@ts-ignore
            category={selectedCategory}
        />
    );

    const filteredItemsList = items.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Layout style={styles.container}>
            <View style={styles.header}>
                <Button
                    appearance="ghost"
                    status="basic"
                    accessoryLeft={(props) => <Icon {...props} name="arrow-back" />}
                    onPress={onBack}
                >
                    Back to Vendors
                </Button>
                <Text category="h6" style={styles.title}>SELECTED VENDOR: {vendor.name}</Text>
            </View>
            <View style={styles.contentContainer}>
                <View style={styles.categoriesContainer}>
                    <Text category="h6">Vendor Categories</Text>
                    <List
                        ItemSeparatorComponent={() => <View style={{ height: 1, backgroundColor: '#ccc' }} />}
                        data={categories}
                        renderItem={({ item }) => (
                            <ListItem
                                style={{
                                    backgroundColor: selectedCategory?._id === item._id ? theme['color-success-900'] : 'transparent'
                                }}
                                title={item.name}
                                description={item.description || 'No description'}
                                onPress={() => fetchItems(item)}
                            />
                        )}
                    />
                </View>
                <View style={styles.itemsContainer}>
                    <Text category="h6">
                        {selectedCategory ? `SELECTED CATEGORY: ${selectedCategory.name}` : 'Select a category'}
                    </Text>
                    {selectedCategory && (
                        <>
                            <View style={styles.searchContainer}>
                                <Input
                                    size='small'
                                    placeholder='Search items'
                                    value={searchQuery}
                                    onChangeText={setSearchQuery}
                                    style={styles.searchInput}
                                    accessoryLeft={(props) => (
                                        <Icon
                                            {...props}
                                            name={'search-outline'}
                                        />
                                    )}
                                />
                                <Button
                                    appearance="ghost"
                                    status={searchQuery && searchQuery.length > 0 ? 'danger' : 'basic'}
                                    accessoryLeft={(props) => <Icon {...props} name="close" />}
                                    onPress={() => setSearchQuery('')}
                                />
                            </View>
                            <List
                                data={filteredItemsList}
                                renderItem={renderItemListItem}
                            />
                        </>
                    )}
                </View>
            </View>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 16,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16,
    },
    title: {
        flex: 1,
        textAlign: 'center',
    },
    contentContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    categoriesContainer: {
        flex: 1,
        marginRight: 16,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 8,
    },
    itemsContainer: {
        flex: 3,
        gap: 8
    },
    searchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    searchInput: {
        flex: 1,
    },
});
