import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
import {
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    Image,
    StyleSheet,
    TouchableOpacity,
    View,
    Text as RNText,
    Platform,
    useWindowDimensions,
    ScrollView,
    Dimensions,
    Alert
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import AssignJobList from '../assignJobList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import { ServicesContainer } from '../services/servicesContainer.component';
import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../ErrorBoundary.component';
import { JobDetailImages } from '../jobDetailsImages.component';
import { event } from 'react-native-reanimated';
import NotificationContext from '../../../context/notifications.context';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import AddressSearch from './addressSearch.component';
import { JobDetailMap } from './mapSelectLocation.component';
import { Job, Service } from '../../../models/Job.model';

interface ServiceDetailsCardProps {
    allowEdit: boolean;
    updateJobServices: (services: Service[]) => void;
    selectedServices: Service[];
    canSetTimes: boolean;
    style?: any;
}


export const ServiceDetailsCard = ({
    allowEdit,
    updateJobServices,
    selectedServices,
    style = { flex: 1 }
}: ServiceDetailsCardProps) => {

    return (

        <Card
            disabled
            header={() => <Text style={{ alignSelf: 'center' }}>Services</Text>}
            style={style}
            status='warning'
        >
            {/* <RenderHeading heading="Services" /> */}
            <ServicesContainer
                fn_updateJobServices={updateJobServices}
                var_selectedServices={selectedServices}
                allowEdit={allowEdit}
                var_randUpdate={undefined}
                style={undefined}
            />
        </Card>
    )

}
