import React, { useCallback, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import { Card, Text } from '@ui-kitten/components';
import { TowingAddressDetailsCard } from './towingAddressDetailsCard.component';
import { TowingLocation, JobLocation, JOB_LOCATION_TYPE, JOB_TOWING_START_END_OPTION, JobAddress, JobTowingDetails } from '../../../models/Job.model';
import { CompanySavedAddress } from '../../../models/Company.model';
import * as GoogleController from '../../../functions/google.controller';

interface StartEndLocationsProps {
    jobTowingDetails: JobTowingDetails;
    // startEndOption: JOB_TOWING_START_END_OPTION;
    allowEdit: boolean;
    onStartTowingLocationChange: (towingLocation: TowingLocation) => void;
    onEndTowingLocationChange: (towingLocation: TowingLocation) => void;
    onRemoveStartLocation: () => void;
    onRemoveEndLocation: () => void;
}

export const StartEndLocations = ({
    jobTowingDetails,
    allowEdit,
    onStartTowingLocationChange,
    onEndTowingLocationChange,
    onRemoveStartLocation,
    onRemoveEndLocation
}: StartEndLocationsProps) => {

    const [startEndOption, setStartEndOption] = useState(jobTowingDetails.start_end_option);
    const [showStartLocation, setShowStartLocation] = useState(false);
    const [showEndLocation, setShowEndLocation] = useState(false);

    useEffect(() => {
        const newStartEndOption = jobTowingDetails.start_end_option;
        setStartEndOption(newStartEndOption);

        const showStartLocation = newStartEndOption === JOB_TOWING_START_END_OPTION.ROUND_TRIP ||
            newStartEndOption === JOB_TOWING_START_END_OPTION.START_ONLY ||
            newStartEndOption === JOB_TOWING_START_END_OPTION.START_END;

        const showEndLocation = newStartEndOption === JOB_TOWING_START_END_OPTION.END_ONLY ||
            newStartEndOption === JOB_TOWING_START_END_OPTION.START_END;

        setShowStartLocation(showStartLocation);
        setShowEndLocation(showEndLocation);
    }, [jobTowingDetails])


    const onSetStartTowingLocation = (towingLocation: TowingLocation) => {
        console.log("========on set start towingLocation", towingLocation)
        onStartTowingLocationChange(towingLocation)
    }

    const onSetEndTowingLocation = (towingLocation: TowingLocation) => {
        console.log("========on set end towingLocation", towingLocation)
        onEndTowingLocationChange(towingLocation)
    }


    const handleSetStartAddress = useCallback((address: JobAddress) => {
        const newTowingLocation = new TowingLocation({
            name: "Start",
            location: jobTowingDetails.start_location?.location ?? new JobLocation({}),
            location_type: JOB_LOCATION_TYPE.ADDRESS,
            address: address
        })
        onSetStartTowingLocation(newTowingLocation)
    }, [jobTowingDetails])

    const handleSetEndAddress = useCallback((address: JobAddress) => {
        const newTowingLocation = new TowingLocation({
            name: "End",
            location: jobTowingDetails.end_location?.location ?? new JobLocation({}),
            location_type: JOB_LOCATION_TYPE.ADDRESS,
            address: address
        })
        onSetEndTowingLocation(newTowingLocation)
    }, [jobTowingDetails])

    const handleSetStartLocation = useCallback((location: JobLocation) => {
        const newTowingLocation = new TowingLocation({
            name: "Start",
            location: location,
            location_type: JOB_LOCATION_TYPE.ADDRESS,
            address: jobTowingDetails.start_location?.address ?? new JobAddress({})
        })
        onSetStartTowingLocation(newTowingLocation)
    }, [jobTowingDetails])

    const handleSetEndLocation = useCallback((location: JobLocation) => {
        const newTowingLocation = new TowingLocation({
            name: "End",
            location: location,
            location_type: JOB_LOCATION_TYPE.ADDRESS,
            address: jobTowingDetails.end_location?.address ?? new JobAddress({})
        })
        onSetEndTowingLocation(newTowingLocation)
    }, [jobTowingDetails])

    const handleSetStartSavedAddress = useCallback((savedAddress: CompanySavedAddress) => {
        const newTowingLocation = new TowingLocation({
            name: "Start",
            location: new JobLocation(savedAddress.location),
            location_type: savedAddress.type,
            address: new JobAddress(savedAddress.address)
        })
        onSetStartTowingLocation(newTowingLocation)
    }, [jobTowingDetails])

    const handleSetEndSavedAddress = useCallback((savedAddress: CompanySavedAddress) => {
        const newTowingLocation = new TowingLocation({
            name: "End",
            location: new JobLocation(savedAddress.location),
            location_type: savedAddress.type,
            address: new JobAddress(savedAddress.address)
        })
        onSetEndTowingLocation(newTowingLocation)
    }, [jobTowingDetails])

    const handleSetStartAddressType = useCallback((addressType: JOB_LOCATION_TYPE) => {
        const newTowingLocation = new TowingLocation({
            ...jobTowingDetails.start_location,
            address_type: addressType
        })
        onSetStartTowingLocation(newTowingLocation)
    }, [jobTowingDetails])

    const handleSetEndAddressType = useCallback((addressType: JOB_LOCATION_TYPE) => {
        const newTowingLocation = new TowingLocation({
            ...jobTowingDetails.end_location,
            address_type: addressType
        })
        onSetEndTowingLocation(newTowingLocation)
    }, [jobTowingDetails])


    const handleSetStartAddressLocation = useCallback((address: JobAddress, location: JobLocation) => {
        console.log("🚀============== ~ file: StartEndLocations.component.tsx:138 ~ handleSetStartAddressLocation ~ address🚀==============", address)
        onSetStartTowingLocation(new TowingLocation({
            ...jobTowingDetails.start_location,
            address: address,
            location: location
        }))
    }, [jobTowingDetails])

    const handleSetEndAddressLocation = useCallback((address: JobAddress, location: JobLocation) => {
        console.log("🚀============== ~ file: StartEndLocations.component.tsx:147 ~ handleSetEndAddressLocation ~ address🚀==============", address)
        onSetEndTowingLocation(new TowingLocation({
            ...jobTowingDetails.end_location,
            address: address,
            location: location
        }))
    }, [jobTowingDetails])

    return (
        <>
            {showStartLocation && (
                <Card
                    status="warning"
                    header={() => <Text style={{ alignSelf: 'center' }}>{"Start Location"}</Text>}
                    disabled={true}
                >
                    <TowingAddressDetailsCard
                        defaultAddressDisplayValue={jobTowingDetails.start_location ? GoogleController.addressBuilder(jobTowingDetails.start_location.address) : ""}
                        index={-1}
                        isDropoff={false}
                        collapsed={false}
                        towingLocationObject={jobTowingDetails.start_location || new TowingLocation({
                            name: "Start",
                            location: new JobLocation({}),
                            location_type: JOB_LOCATION_TYPE.ADDRESS
                        })}
                        cardName="Start Location"
                        allowEdit={allowEdit}
                        style={{}}
                        onRemoveTowingLocation={onRemoveStartLocation}
                        onToggleCollapse={() => { }}
                        onSetTowingLocationObject={onSetStartTowingLocation}
                        onSetAddress={handleSetStartAddress}
                        onSetLocation={handleSetStartLocation}
                        onSetAddressType={handleSetStartAddressType}
                        onSetSavedAddress={handleSetStartSavedAddress}
                        onSetAddressLocation={handleSetStartAddressLocation}
                        onRemoveAndSaveAsOriginalDestination={() => { }}
                    />
                </Card>
            )}

            {showEndLocation && (
                <Card
                    status="basic"
                    header={() => <Text style={{ alignSelf: 'center' }}>{"End Location"}</Text>}
                    disabled={true}
                >
                    <TowingAddressDetailsCard
                        defaultAddressDisplayValue={jobTowingDetails.end_location ? GoogleController.addressBuilder(jobTowingDetails.end_location.address) : ""}
                        index={-2}
                        isDropoff={false}
                        collapsed={false}
                        towingLocationObject={jobTowingDetails.end_location || new TowingLocation({
                            name: "End",
                            location: new JobLocation({}),
                            location_type: JOB_LOCATION_TYPE.ADDRESS
                        })}
                        cardName="End Location"
                        allowEdit={allowEdit}
                        style={{}}
                        onRemoveTowingLocation={onRemoveEndLocation}
                        onToggleCollapse={() => { }}
                        onSetTowingLocationObject={onSetEndTowingLocation}
                        onSetAddress={handleSetEndAddress}
                        onSetLocation={handleSetEndLocation}
                        onSetAddressType={handleSetEndAddressType}
                        onSetSavedAddress={handleSetEndSavedAddress}
                        onSetAddressLocation={handleSetEndAddressLocation}
                        onRemoveAndSaveAsOriginalDestination={() => { }}
                    />
                </Card>
            )}
        </>
    );
}; 