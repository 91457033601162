import { JobAddress, JobLocation, JobDirections, LineItem, Job } from './Job.model';
import * as RouteController from '../functions/route.controller';
import * as JobController from '../functions/job.controller';

export class Route {
    _id!: string | null;
    name!: string;
    company_id!: string | null;
    member_id!: string | null;
    job_ids!: string[];
    details!: RouteDetails;
    status!: ROUTE_STATUS;
    planned_start_time!: number | null;
    actual_start_time!: number | null;
    completed_time!: number | null;
    inventory_items!: LineItem[];
    createdAt!: string | null;
    updatedAt!: string | null;

    constructor(route: Partial<Route> = {}) {
        this._id = route._id || null;
        this.name = route.name || `New Route ${new Date().toLocaleTimeString('en-AU', { month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}`;
        this.company_id = route.company_id || null;
        this.member_id = route.member_id || null;
        this.job_ids = route.job_ids || [];
        this.details = route.details ? new RouteDetails(route.details) : new RouteDetails({});
        this.status = route.status || ROUTE_STATUS.PLANNED;
        this.planned_start_time = route.planned_start_time ? new Date(route.planned_start_time).getTime() : new Date().getTime();
        this.actual_start_time = route.actual_start_time ? route.actual_start_time : null;
        this.completed_time = route.completed_time ? route.completed_time : null;
        this.inventory_items = route.inventory_items || [];
        this.createdAt = route.createdAt || null;
        this.updatedAt = route.updatedAt || null;

        this.syncItemsWithLocations();
    }

    create() {
        return RouteController.createRoute(this)
    }

    save() {
        return RouteController.updateRoute(this)
    }

    /**
     * Update the locations in the route, Matching items, addresses and job locations
     */
    async updateLocations(jobs: Job[] | null) {
        try {
            if (!this._id) {
                throw new Error("Route ID is required")
            }
            if (!jobs) {
                jobs = await RouteController.getAllJobsByRouteId(this._id) as Job[];
            }
            if (!jobs || jobs.length === 0) {
                console.warn(`No jobs found for route ${this._id}`);
                return this;
            }

            // Create a map of existing items with their picked status
            const existingItems = new Map(
                this.details.itemsToHandle.map(item => [item.lineItemId, item.isPicked])
            );

            // Update locations
            this.details.locations = this.details.locations.filter(location => {
                let job = jobs?.find(job => job._id === location.job_id);
                if (!job) {
                    console.log(`Job ${location.job_id} no longer exists in the route. Removing location.`);
                    return false;
                }
                job = new Job(job);
                const updatedItems = job.getLineItems().map(item => ({
                    ...item,
                    isPicked: existingItems.get(item._id) || false
                }));

                Object.assign(location, {
                    address: job.details.address,
                    location: job.details.location,
                    inventory_items: updatedItems
                });

                return true;
            });

            this.syncItemsWithLocations();
            return this;
        } catch (error) {
            console.error(`Error updating locations for route ${this._id}:`, error);
            throw error;
        }
    }

    async assignDriver(member_id: string) {
        if (!member_id) {
            throw new Error("Member ID is required")
        }
        if (!this._id) {
            throw new Error("Route ID is required - Route has not been created yet")
        }
        this.member_id = member_id
        await this.save()
        await RouteController.assignDriverToAllJobsInRoute(this._id, member_id)
    }

    /*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// ITEMS TO HANDLE
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/

    syncItemsWithLocations() {
        this.details.syncItemsWithLocations();
    }

    updateItemStatus(itemId: string, isPicked: boolean) {
        this.details.updateItemStatus(itemId, isPicked);
    }

    getUnpickedItems() {
        return this.details.getUnpickedItems();
    }

    isReadyToStart() {
        return this.getUnpickedItems().length === 0;
    }
}

// status constants
export enum ROUTE_STATUS {
    PLANNED = 'planned',
    IN_PROGRESS = 'in_progress',
    PENDING = 'pending',
    COMPLETED = 'completed',
    CANCELLED = 'cancelled',
};

export enum ROUTE_START_END_OPTION {
    DEFAULT = 'default',
    ROUND_TRIP = 'round_trip',
    START_FIRST_END_LAST = 'start_first_end_last',
    CUSTOM = 'custom',
}

export class RouteDetails {
    locations: RouteLocation[];
    legs: RouteLeg[];
    distance_kms: number | null;
    duration_seconds: number | null;
    cost_per_km: number | null;
    covered_distance_kms: number | null;
    total_cost: number | null;
    overview_polyline: string | { lat: number, lng: number }[];
    all_steps_polyline: string[] | { lat: number, lng: number }[];
    startEndOption: ROUTE_START_END_OPTION;
    startLocationIndex: number | null;
    endLocationIndex: number | null;
    itemsToHandle: RouteItem[];

    constructor(details: any = {}) {
        this.locations = details?.locations ? details.locations.map((loc: any) => new RouteLocation(loc)) : [];
        this.legs = details?.legs ? details.legs.map((leg: any) => new RouteLeg(leg)) : [];
        this.distance_kms = details?.distance_kms ?? null;
        this.duration_seconds = details?.duration_seconds ?? null;
        this.cost_per_km = details?.cost_per_km ?? null;
        this.covered_distance_kms = details?.covered_distance_kms ?? null;
        this.total_cost = details?.total_cost ?? null;
        this.overview_polyline = details?.overview_polyline ?? null;
        this.all_steps_polyline = details?.all_steps_polyline ?? null;
        this.startEndOption = details?.startEndOption ?? ROUTE_START_END_OPTION.DEFAULT;
        this.startLocationIndex = details?.startLocationIndex ?? null;
        this.endLocationIndex = details?.endLocationIndex ?? null;
        this.itemsToHandle = details?.itemsToHandle ? details.itemsToHandle.map((item: any) => new RouteItem(item)) : [];
    }

    addNewLocationAtIndex(index: number, location: RouteLocation = new RouteLocation({})) {
        this.locations.splice(index, 0, location)
    }

    addNewLegAtIndex(index: number, leg: RouteLeg = new RouteLeg({})) {
        this.legs.splice(index, 0, leg)
    }

    reindexLegs() {
        this.legs.forEach((leg, index) => {
            leg.index = index;
        })
        return this.legs;
    }

    updateItemStatus(itemId: string, isPicked: boolean) {
        const item = this.itemsToHandle.find(item => item.id === itemId);
        if (item) {
            item.isPicked = isPicked;
        }
    }

    updateItemPickedTime(itemId: string, pickedTime: number) {
        const item = this.itemsToHandle.find(item => item.id === itemId);
        if (item) {
            item.pickedTime = pickedTime;
        }
    }

    getUnpickedItems() {
        return this.itemsToHandle.filter(item => !item.isPicked);
    }

    syncItemsWithLocations() {
        const existingItems = new Map(this.itemsToHandle.map(item => [item.lineItemId, item]));
        this.itemsToHandle = [];
        this.locations.forEach((location, index) => {
            location.inventory_items.forEach(item => {
                const existingItem = existingItems.get(item._id);
                this.itemsToHandle.push(new RouteItem({
                    id: item._id,
                    lineItemId: item._id,
                    isPicked: existingItem ? existingItem.isPicked : false,
                    locationType: location.route_location_type === ROUTE_LOCATION_TYPE.PICKUP ? 'pickup' : 'delivery',
                    locationIndex: index,
                    pickedTime: existingItem ? existingItem.pickedTime : null
                }));
            });
        });
    }

}


export class RouteLeg {
    index: number;
    origin_route_location_index: number;
    destination_route_location_index: number;
    origin: JobLocation | null;
    destination: JobLocation | null;
    directions: JobDirections;
    cost_per_km: number;
    covered_distance_kms: number;
    total_cost: number;
    actual_distance_kms: number;
    actual_duration_seconds: number;
    polyline: any;
    constructor(details: any = {}) {
        this.index = details?.index ?? 0;
        this.origin_route_location_index = details?.origin_route_location_index ?? 0;
        this.destination_route_location_index = details?.destination_route_location_index ?? 0;
        this.origin = details?.origin ? new JobLocation(details?.origin) : null;
        this.destination = details?.destination ? new JobLocation(details?.destination) : null;
        this.directions = details?.directions ? new JobDirections(details?.directions) : new JobDirections({});
        this.cost_per_km = details?.cost_per_km ?? 0;
        this.covered_distance_kms = details?.covered_distance_kms ?? 0;
        this.total_cost = details?.total_cost ?? 0;
        this.actual_distance_kms = details?.actual_distance_kms ?? 0;
        this.actual_duration_seconds = details?.actual_duration_seconds ?? 0;
        this.polyline = details?.polyline ?? "";
    }
}

export enum ROUTE_LOCATION_TYPE {
    PICKUP = 'pickup',
    DELIVERY = 'delivery',
    INTERMEDIATE = 'intermediate',
    RETURN = 'return',
    JOB = 'job',
}

export class RouteLocation {
    index: number;
    job_id: string;
    job_location_index: number; // New field to track which location from the job this represents
    location_type: string;
    address_type: string;
    address: JobAddress;
    location: JobLocation;
    name: string;
    note: string;
    inventory_items: LineItem[];
    route_location_type: ROUTE_LOCATION_TYPE;

    // Job is only used in the UI, it will be null when sending to the server
    job: Job | null;
    is_towing_location: boolean; // New field to indicate if this is part of a towing job
    towing_location_type: string | null; // e.g., "pickup", "dropoff", "intermediate"

    constructor(details: any = {}) {
        this.index = details?.index ?? 0;
        this.job_id = details?.job_id ?? "";
        this.location_type = details?.location_type ?? "Address";
        this.address_type = details?.address_type ?? "Home";
        this.address = details?.address ? new JobAddress(details?.address) : new JobAddress({});
        this.location = details?.location ? new JobLocation(details?.location) : new JobLocation({});
        this.name = details?.name ?? "Location";
        this.note = details?.note ?? "";
        this.inventory_items = details?.inventory_items ?? [];
        this.job = details?.job ? new Job(details.job) : null;
        this.route_location_type = details?.route_location_type ?? ROUTE_LOCATION_TYPE.DELIVERY;
        this.job_location_index = details?.job_location_index ?? 0;
        this.is_towing_location = details?.is_towing_location ?? false;
        this.towing_location_type = details?.towing_location_type ?? null;
    }
}


export class RouteItem {
    id: string;
    lineItemId: string;
    isPicked: boolean;
    locationType: 'pickup' | 'delivery';
    locationIndex: number;
    pickedTime: number | null;

    constructor(item: any = {}) {
        this.id = item.id || this.generateId();
        this.lineItemId = item.lineItemId || '';
        this.isPicked = item.isPicked || false;
        this.locationType = item.locationType || 'pickup';
        this.locationIndex = item.locationIndex || 0;
        this.pickedTime = item.pickedTime || null;
    }

    generateId() {
        return Math.random().toString(36).substring(2, 15);
    }
}


export interface IGoogleRoute {
    distanceMeters: number
    duration: number
    legs: IGoogleRouteLeg[]
    localizedValues: any
    polyline: {
        encodedPolyline: string
    }
    routeLabels: any
    routeToken: string
    staticDuration: number
    travelAdvisory: any
    viewport: {
        high: {
            latitude: number
            longitude: number
        }
        low: {
            latitude: number
            longitude: number
        }
    }
    warnings: any
}

export interface IGoogleRouteLeg {
    distanceMeters: number
    duration: string // "12345s"
    endLocation: {
        latLng: {
            latitude: number
            longitude: number
        }
    }
    startLocation: {
        latLng: {
            latitude: number
            longitude: number
        }
    }
    polyline: {
        encodedPolyline: string
    }
    localizedValues: any

}

/*
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
//////// GOOGLE OPTIMIZE ROUTE
//////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
*/
export interface IGoogleOptimizeLocation {
    latitude: number;
    longitude: number;
}

export interface IGoogleOptimizeArrivalLocation {
    arrivalLocation: IGoogleOptimizeLocation;
}

export interface IGoogleOptimizeShipment {
    pickups: IGoogleOptimizeArrivalLocation[];
    deliveries: IGoogleOptimizeArrivalLocation[];
}

export interface IGoogleOptimizeVehicle {
    startLocation: IGoogleOptimizeLocation;
    endLocation: IGoogleOptimizeLocation;
    costPerKilometer?: number;
}

export interface IGoogleOptimizeRouteModel {
    shipments: IGoogleOptimizeShipment[];
    vehicles: IGoogleOptimizeVehicle[];
    globalStartTime: string;
    globalEndTime: string;
}

export interface IGoogleOptimizeRouteRequest {
    model: IGoogleOptimizeRouteModel;
    considerRoadTraffic: boolean;
    populateTransitionPolylines: boolean;
    searchMode: 'RETURN_BEST' | 'RETURN_FAST';
}


const test: IGoogleOptimizeRouteRequest = {
    model: {
        shipments: [
            {
                pickups: [
                    {
                        arrivalLocation: {
                            latitude: 37.73881799999999,
                            longitude: -122.4161
                        }
                    }
                ],
                deliveries: [
                    {
                        arrivalLocation: {
                            latitude: 37.79581,
                            longitude: -122.4218856
                        }
                    }
                ]
            }
        ],
        vehicles: [
            {
                startLocation: {
                    latitude: 37.73881799999999,
                    longitude: -122.4161
                },
                endLocation: {
                    latitude: 37.73881799999999,
                    longitude: -122.4161
                },
                costPerKilometer: 1.0
            }
        ],
        globalStartTime: "2024-02-13T00:00:00.000Z",
        globalEndTime: "2024-02-14T06:00:00.000Z"
    },
    considerRoadTraffic: true,
    populateTransitionPolylines: true,
    searchMode: 'RETURN_BEST'
}