import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline, OverlayView } from '@react-google-maps/api';
import * as UpdateController from '../../../functions/update.controller';
import * as UserController from '../../../functions/user.controller';
import * as StorageController from "../../../functions/storageController"
import * as MessageController from "../../../functions/message.controller"
import * as GoogleController from "../../../functions/google.controller"
import * as JobsController from '../../../functions/job.controller'
import ErrorBoundary from '../../ErrorBoundary.component';
//@ts-ignore
import _ from 'lodash'
//@ts-ignore
import debounce from 'lodash.debounce';
import Toast from 'react-native-toast-message';
// import { AddressSearch } from '../searchAddress.component';
import DistanceTool from './../distanceTool.component';
import { RoadsideJobInfo } from './../RoadsideJobInfo.component';
import { TowingJobInfo } from './../TowingJobInfo.component';
import { MemberDeviceInfoCard } from '../../members/memberDeviceInfoCard.component';
import { Job, JobTowingDetails, TowingLeg, JOB_STATUS } from '../../../models/Job.model';
import { Member } from '../../../models/Member.model';
import { Company } from '../../../models/Company.model'
import { darkMapStyles, lightMapStyles } from './../mapStyles';
import { RenderTowingIcon } from './JobIcon.component';


var getGoogleClusterInlineSvg = function (color: string) {
    var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-100 -100 200 200"><defs><g id="a" transform="rotate(45)"><path d="M0 47A47 47 0 0 0 47 0L62 0A62 62 0 0 1 0 62Z" fill-opacity="0.7"/><path d="M0 67A67 67 0 0 0 67 0L81 0A81 81 0 0 1 0 81Z" fill-opacity="0.5"/><path d="M0 86A86 86 0 0 0 86 0L100 0A100 100 0 0 1 0 100Z" fill-opacity="0.3"/></g></defs><g fill="' + color + '"><circle r="42"/><use xlink:href="#a"/><g transform="rotate(120)"><use xlink:href="#a"/></g><g transform="rotate(240)"><use xlink:href="#a"/></g></g></svg>');

    return ('data:image/svg+xml;base64,' + encoded);
};

interface JobClusterProps {
    jobs: Job[],
    status: JOB_STATUS | null,
    onClick: (job: Job) => void,
    onClickTowingMapMarker: (towingDetails: JobTowingDetails) => void,
    renderJobIcon: (status: string) => any
}

export const JobClusterComponent = ({ jobs, status, onClick, onClickTowingMapMarker, renderJobIcon }: JobClusterProps) => {


    const statusColour = (status: string): string => {
        switch (status) {
            case JOB_STATUS.ASSIGNED:
                return "orange"
            case JOB_STATUS.PENDING:
                return "blue"
            case JOB_STATUS.COMPLETE:
                return "green"
            case JOB_STATUS.CANCELLED:
                return "purple"
            case JOB_STATUS.UNASSIGNED:
                return "red"
            case JOB_STATUS.REQUEST:
                return "pink"
            case JOB_STATUS.TRANSFERRED_OUT:
                return "cyan"
            default:
                return "white"
        }
    }

    const clustererOptions = {
        // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
        gridSize: 35,
        maxZoom: 15,
        zoomOnClick: true,
        averageCenter: true,
        minimumClusterSize: 3,
        styles: [
            {
                textColor: 'white',
                url: getGoogleClusterInlineSvg(statusColour(status ?? JOB_STATUS.UNASSIGNED)),
                height: 53,
                width: 53
            },
            {
                textColor: 'white',
                url: getGoogleClusterInlineSvg(statusColour(status ?? JOB_STATUS.UNASSIGNED)),
                height: 56,
                width: 56
            },
            {
                textColor: 'white',
                url: getGoogleClusterInlineSvg(statusColour(status ?? JOB_STATUS.UNASSIGNED)),
                height: 66,
                width: 66
            },
            {
                textColor: 'white',
                url: getGoogleClusterInlineSvg(statusColour(status ?? JOB_STATUS.UNASSIGNED)),
                height: 78,
                width: 78
            },
            {
                textColor: 'white',
                url: getGoogleClusterInlineSvg(statusColour(status ?? JOB_STATUS.UNASSIGNED)),
                height: 90,
                width: 90
            }
        ],
    }

    const memoizedFilteredJobs = useMemo(() => {
        if (!status) return jobs
        const result = jobs.filter((job: Job) => job.status == status)
        return result
    }, [jobs, status])


    return (
        <MarkerClusterer options={clustererOptions}>
            {/* @ts-ignore */}
            {(clusterer: any) => (
                memoizedFilteredJobs?.map((job: Job) => {
                    if (job.status != status) {
                        return null
                    }
                    if (job?.details?.options?.towing_job_options) {
                        return (
                            <RenderTowingMarkers
                                key={job._id}
                                job={job}
                                onClick={onClick}
                                clusterer={clusterer}
                                onClickTowingMapMarker={onClickTowingMapMarker}
                                renderJobIcon={renderJobIcon}
                            />
                        )
                    }
                    else {
                        return (
                            <RenderRoadsideMarker
                                key={job._id}
                                job={job}
                                onClick={onClick}
                                clusterer={clusterer}
                                renderJobIcon={renderJobIcon}
                            />
                        )
                    }
                })
            )}
        </MarkerClusterer>
    )
};

export const RenderRoadsideMarker = ({ job, onClick, clusterer, renderJobIcon }: any) => {
    let servicesInitials = " "
    try {
        if (job?.details?.selected_services) {
            servicesInitials = job.getServicesNames()
        }
        // if theres no services
        if (servicesInitials.length == 0) {
            servicesInitials = " "
        }
    } catch (e) {
        console.log("Error getting services initials", e)
    }
    try {

        return (
            <Marker
                clusterer={clusterer}
                position={{ lat: job.details?.location?.lat, lng: job.details?.location?.lon }}
                icon={renderJobIcon(job.status)}
                // label={{
                //     text: servicesInitials || '',
                //     color: "white",
                //     fontSize: "8px",
                //     fontWeight: "bold",
                //     fontFamily: "Arial",
                //     //@ts-ignore
                //     textShadowOffset: { width: -1, height: 1 },
                //     textShadowRadius: 1
                // }}
                onClick={() => {
                    onClick(job)
                }}
            />
        )
    } catch (e) {
        console.log("🚀============== ~ file: JobCluster.component.tsx:199 ~ RenderRoadsideMarker ~ e🚀==============", e)
        return <>   </>
    }
}

export const RenderTowingMarkers = ({ job, onClick, clusterer, onClickTowingMapMarker, renderJobIcon }: any) => {
    const towingPickupLocation = job?.details?.towing_details?.pickup_towing_location?.location.latLng() as any
    const towingDropoffLocation = job?.details?.towing_details?.dropoff_towing_location?.location.latLng() as any
    const towingPickupIcon = RenderTowingIcon(job.status, "pickup")
    const towingDropoffIcon = RenderTowingIcon(job.status, "dropoff")
    return (
        <>
            <React.Fragment key={job._id}>
                {job.details?.towing_details?.pickup_towing_location?.location &&
                    <Marker
                        clusterer={clusterer}
                        position={towingPickupLocation}
                        icon={towingPickupIcon}
                        onClick={() => {
                            onClickTowingMapMarker(job?.details?.towing_details as JobTowingDetails)
                            onClick(job)
                        }}
                    // label={{
                    //     // text: "P",
                    //     color: "white",
                    //     fontSize: "8px",
                    //     fontWeight: "bold",
                    //     fontFamily: "Arial",
                    //     // @ts-ignore
                    //     textShadowOffset: { width: -1, height: 1 },
                    //     textShadowRadius: 1
                    // }}
                    >
                    </Marker>
                }
                {job.details?.towing_details?.dropoff_towing_location?.location &&
                    <Marker
                        clusterer={clusterer}
                        position={towingDropoffLocation}
                        icon={towingDropoffIcon}
                        onClick={() => {
                            onClickTowingMapMarker(job?.details?.towing_details as JobTowingDetails)
                            onClick(job)
                        }}
                    // label={{
                    //     // text: "D",
                    //     color: "white",
                    //     fontSize: "8px",
                    //     fontWeight: "bold",
                    //     fontFamily: "Arial",
                    //     // @ts-ignore
                    //     textShadowOffset: { width: -1, height: 1 },
                    //     textShadowRadius: 1
                    // }}
                    >
                    </Marker>
                }
            </React.Fragment>
        </>
    )
}