import React, { useState, useEffect, useMemo } from 'react';
import { View, StyleSheet } from 'react-native';
import {
    Button,
    Icon,
    Input,
    Select,
    SelectItem,
    Text,
    Card,
    IndexPath
} from "@ui-kitten/components";
import { AddressDetailsCard } from '../details/addressDetailsCard.component';
import { TowingLocation, JobAddress } from '../../../models/Job.model';
import * as GoogleController from '../../../functions/google.controller';
import { CompanySavedAddress } from '../../../models/Company.model';
import { JOB_LOCATION_TYPE } from '../../../models/Job.model';

interface TowingAddressDetailsCardProps {
    index: number;
    style: any;
    towingLocationObject: TowingLocation;
    onSetTowingLocationObject: (e: any) => void;
    onRemoveTowingLocation: () => void;
    onRemoveAndSaveAsOriginalDestination: () => void;
    onSetAddress: (e: any) => void;
    onSetLocation: (e: any) => void;
    collapsed: boolean;
    onToggleCollapse: (e: any) => void;
    cardName: string;
    allowEdit: boolean;
    isDropoff: boolean;
    defaultAddressDisplayValue: string;
    onSetAddressType?: (type: JOB_LOCATION_TYPE) => void;
    onSetSavedAddress?: (address: CompanySavedAddress) => void;
}


export const TowingAddressDetailsCard = ({
    index = 0,
    style = {},
    towingLocationObject = new TowingLocation({}),
    onSetTowingLocationObject = (e: any) => { },
    onRemoveTowingLocation = () => { },
    onRemoveAndSaveAsOriginalDestination = () => { },
    onSetAddress = (e: any) => { },
    onSetLocation = (e: any) => { },
    collapsed = true,
    onToggleCollapse = (e: any) => { },
    cardName = "PICKUP",
    allowEdit = true,
    isDropoff = false,
    defaultAddressDisplayValue = "",
    onSetAddressType = (type: JOB_LOCATION_TYPE) => { },
    onSetSavedAddress = (address: CompanySavedAddress) => { }
}: TowingAddressDetailsCardProps) => {
    const [towingLocationState, setTowingLocationState] = useState(new TowingLocation(towingLocationObject));

    useEffect(() => {
        setTowingLocationState(new TowingLocation(towingLocationObject));
    }, [towingLocationObject]);

    const onUpdateAddress = (address: JobAddress) => {
        onSetAddress(address);
    };

    const onUpdateLocation = (location: any) => {
        onSetLocation(location);
    };

    const handleSetTowingLocationSavedAddress = (savedAddress: CompanySavedAddress) => {
        if (!savedAddress.type) {
            savedAddress.type = JOB_LOCATION_TYPE.ADDRESS
        }
        onSetSavedAddress(savedAddress);
    }

    const locationTypes = ["Address", "Holding"];
    const holdingReasons = ["Holding", "Impound", "Repair", "Other"];
    const addressTypes = ["Home", "Business", "Carpark", "Roadside", "Mechanic", "Dealership", "Tow to safe location", "Other"];

    const [locationTypeValue, setLocationTypeValue] = useState(towingLocationObject?.location_type || locationTypes[0]);
    const [addressTypeIndex, setAddressTypeIndex] = useState(new IndexPath(addressTypes.indexOf(towingLocationObject?.address_type) || 0));
    const [holdingReasonIndex, setHoldingReasonIndex] = useState(new IndexPath(holdingReasons.indexOf(towingLocationObject?.holding_reason) || 0));
    const [addressTypeValue, setAddressTypeValue] = useState(towingLocationObject?.address_type || addressTypes[0]);
    const [holdingReasonValue, setHoldingReasonValue] = useState(towingLocationObject?.holding_reason || holdingReasons[0]);

    const onSetTowingLocationState = (_towingLocationState: TowingLocation) => {
        setTowingLocationState(_towingLocationState);
        onSetTowingLocationObject(_towingLocationState);
    };

    const onSetLocationType = (type: JOB_LOCATION_TYPE) => {
        setLocationTypeValue(type);
        onSetTowingLocationState(new TowingLocation({
            ...towingLocationState,
            location_type: type
        }));
    };

    const onSetHoldingReasonValue = (indexPath: any) => {
        setHoldingReasonIndex(indexPath);
        setHoldingReasonValue(holdingReasons[indexPath.row]);
        onSetTowingLocationState(new TowingLocation({
            ...towingLocationState,
            holding_reason: holdingReasons[indexPath.row]
        }));
    };

    const onSetAddressTypeValue = (indexPath: any) => {
        setAddressTypeIndex(indexPath);
        setAddressTypeValue(addressTypes[indexPath.row]);
        onSetTowingLocationState(new TowingLocation({
            ...towingLocationState,
            address_type: addressTypes[indexPath.row]
        }));
    };

    const DraggableHandle = () => (
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 30 }}>
            <Icon name='menu-outline' width={24} height={24} fill="white" />
        </View>
    );

    const RenderAddressHeader = ({ cardName }: any) => {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ flexDirection: 'row' }}>
                    <DraggableHandle />
                    {index != 0 &&
                        <Button
                            style={{ margin: 5 }}
                            size='small'
                            status='warning'
                            appearance='outline'
                            onPress={() => {
                                onRemoveAndSaveAsOriginalDestination();
                            }}
                            accessoryLeft={() => <Icon name='arrow-circle-right-outline' width={15} height={15} fill="white" />}
                        >
                            Remove And Save As Original Destination
                        </Button>
                    }
                </View>
                <Text style={{ alignSelf: 'center' }}>{cardName}</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: 30 }}>
                    <Button
                        style={{ margin: 5 }}
                        size='small'
                        status='danger'
                        appearance='filled'
                        onPress={() => {
                            onRemoveTowingLocation();
                        }}
                    >
                        <Icon name='trash-2-outline' width={15} height={15} fill="white" />
                    </Button>
                </View>
            </View>
        );
    };

    const autoColourStyles = (index: number) => {
        const colours = ["#009362a1", "#6f2910a1", "#353535", "#2a6978", "#2d4376", "#9b552d", "#651464", "#252e56"];
        if (isDropoff) return {
            backgroundColor: colours[colours.length - 1],
            borderColor: colours[colours.length - 1],
        };
        return {
            backgroundColor: colours[index % colours.length],
            borderColor: colours[index % colours.length],
        };
    };

    const RenderLocationTypeButtons = useMemo(() => {
        return (
            <View style={{ flexDirection: 'row', marginVertical: 10 }}>
                <Button
                    style={{ marginHorizontal: 5 }}
                    size='small'
                    status='primary'
                    appearance={towingLocationState.location_type === JOB_LOCATION_TYPE.ADDRESS ? 'filled' : 'outline'}
                    onPress={() => onSetLocationType(JOB_LOCATION_TYPE.ADDRESS)}
                    disabled={!allowEdit}
                >
                    Address
                </Button>
                <Button
                    style={{ marginHorizontal: 5 }}
                    size='small'
                    status='primary'
                    appearance={towingLocationState.location_type === JOB_LOCATION_TYPE.HOLDING ? 'filled' : 'outline'}
                    onPress={() => onSetLocationType(JOB_LOCATION_TYPE.HOLDING)}
                    disabled={!allowEdit}
                >
                    Holding
                </Button>
            </View>
        )
    }, [towingLocationState, allowEdit]);

    return (
        <View style={style}>
            <Card
                status="info"
                header={<RenderAddressHeader cardName={cardName} />}
                disabled={true}
                style={autoColourStyles(index)}
            >
                <View style={{ flexDirection: 'column', justifyContent: "space-between", margin: -15, flexWrap: 'wrap' }}>
                    <View style={{ flex: 1, minWidth: 350 }}>
                        <AddressDetailsCard
                            // onSetAddressType={onSetAddressType}
                            onSetSavedAddress={handleSetTowingLocationSavedAddress}
                            style={styles.groupContainer}
                            onSetAddress={onUpdateAddress}
                            onSetLocation={onUpdateLocation}
                            address={towingLocationState.address}
                            collapsed={collapsed}
                            addressName={cardName}
                            addressDisplayValue={
                                GoogleController.addressBuilder(towingLocationState.address).length > 0 ?
                                    GoogleController.addressBuilder(towingLocationState.address) :
                                    defaultAddressDisplayValue
                            }
                            onToggleCollapse={(state: any) => {
                                onToggleCollapse(state);
                            }}
                        />
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                            {RenderLocationTypeButtons}

                            {
                                towingLocationState.location_type === JOB_LOCATION_TYPE.ADDRESS &&
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <Text style={{ alignSelf: 'center' }}>Address Type:</Text>
                                    <Select
                                        style={{ margin: 5 }}
                                        disabled={!allowEdit}
                                        // label='Address Type'
                                        size='small'
                                        placeholder='Address Type'
                                        value={addressTypeValue}
                                        selectedIndex={addressTypeIndex}
                                        onSelect={onSetAddressTypeValue}
                                    >
                                        {
                                            addressTypes.map((type, index) => (
                                                <SelectItem key={index} title={type} />
                                            ))
                                        }
                                    </Select>
                                </View>
                            }
                            {
                                towingLocationState.location_type === JOB_LOCATION_TYPE.HOLDING &&
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Text style={{ alignSelf: 'center' }}>Holding Reason:</Text>
                                    <Select
                                        style={{ margin: 5 }}
                                        disabled={!allowEdit}
                                        // label='Holding Reason'
                                        size='small'
                                        placeholder='Holding Reason'
                                        value={holdingReasonValue}
                                        selectedIndex={holdingReasonIndex}
                                        onSelect={onSetHoldingReasonValue}
                                    >
                                        {
                                            holdingReasons.map((reason, index) => (
                                                <SelectItem key={index} title={reason} />
                                            ))
                                        }
                                    </Select>
                                </View>
                            }
                        </View>
                    </View>
                </View>
            </Card>
        </View>
    );
};

const styles = StyleSheet.create({
    groupContainer: {
        margin: 0,
        flex: 1,
        minWidth: 350,
    },
});
