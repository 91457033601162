import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Input, Text, Select, SelectItem, Layout } from '@ui-kitten/components';
import { InventoryStock, InventoryLocation } from '../../models/Inventory.model';
import * as StorageController from '../../functions/storageController';
import * as InventoryController from '../../functions/Inventory.controller';
import Toast from 'react-native-toast-message';
import ErrorBoundary from '../ErrorBoundary.component';

interface AddInventoryStockModalProps {
    visible: boolean;
    onClose: () => void;
    onAddStock: (stock: InventoryStock) => void;
    onEditStock: (stock: InventoryStock) => void;
    stock?: InventoryStock | null;
    inventoryItemId: string;
    existingLocations?: string[];
}

const AddInventoryStockModal: React.FC<AddInventoryStockModalProps> = ({
    visible,
    onClose,
    onAddStock,
    onEditStock,
    stock,
    inventoryItemId,
    existingLocations = []
}) => {
    const [locations, setLocations] = useState<InventoryLocation[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<InventoryLocation | null>(null);
    const [quantity, setQuantity] = useState('0');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (visible) {
            fetchLocations();
            if (stock) {
                setQuantity(stock.quantity?.toString() || '0');
                setSelectedLocation(stock.location || null);
            } else {
                resetForm();
            }
        }
    }, [visible, stock]);

    const fetchLocations = async () => {
        setLoading(true);
        const company = StorageController.getCurrentCompany();
        const fetchedLocations = await InventoryController.getAllInventoryLocationsByCompanyId(company._id);
        if (fetchedLocations) {
            const filteredLocations = stock
                ? fetchedLocations
                : fetchedLocations.filter(loc => !existingLocations.includes(loc._id!));
            setLocations(filteredLocations);
        }
        setLoading(false);
    };

    const handleSubmit = () => {
        const locationToUse = stock ? stock.location : selectedLocation;

        if (!locationToUse) {
            Toast.show({
                type: 'error',
                text1: 'Please select a location'
            });
            return;
        }

        const parsedQuantity = parseInt(quantity);
        if (isNaN(parsedQuantity) || parsedQuantity < 0) {
            Toast.show({
                type: 'error',
                text1: 'Please enter a valid quantity'
            });
            return;
        }

        if (stock) {
            const updatedStock = new InventoryStock({
                ...stock,
                quantity: parsedQuantity,
            });
            onEditStock(updatedStock);
        } else {
            const newStock = new InventoryStock({
                inventory_item_id: inventoryItemId,
                location_id: locationToUse._id,
                location: locationToUse,
                quantity: parsedQuantity,
            });
            onAddStock(newStock);
        }
        resetForm();
    };

    const resetForm = () => {
        setSelectedLocation(null);
        setQuantity('0');
    };

    const handleLocationSelect = (index: any) => {
        setSelectedLocation(locations[index.row]);
    };

    const displayValue = selectedLocation ? selectedLocation.name : 'Select Location';

    return (
        <Layout style={styles.container}>
            <ErrorBoundary>
                <Text category="h6" style={styles.header}>
                    {stock ? 'Edit Stock' : 'Add Stock'}
                </Text>

                {stock ? (
                    <View style={styles.input}>
                        <Text category="label">Location</Text>
                        <Text category="s1" style={styles.locationName}>
                            {stock.location?.name || 'Unknown Location'}
                        </Text>
                    </View>
                ) : (
                    <Select
                        label="Location"
                        placeholder="Select a location"
                        value={displayValue}
                        onSelect={handleLocationSelect}
                        disabled={loading}
                        style={styles.input}
                    >
                        {locations.map(location => (
                            <SelectItem
                                key={location._id}
                                title={location.name}
                            />
                        ))}
                    </Select>
                )}

                <Input
                    label="Quantity"
                    placeholder="Enter quantity"
                    value={quantity}
                    onChangeText={setQuantity}
                    keyboardType="numeric"
                    style={styles.input}
                />

                <View style={styles.buttonContainer}>
                    <Button
                        status="primary"
                        onPress={handleSubmit}
                        disabled={(!stock && !selectedLocation) || loading}
                    >
                        {stock ? 'Save Changes' : 'Add Stock'}
                    </Button>
                    <Button
                        appearance="ghost"
                        status="danger"
                        onPress={() => {
                            resetForm();
                            onClose();
                        }}
                    >
                        Cancel
                    </Button>
                </View>
            </ErrorBoundary>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 20,
        minWidth: 300,
        borderRadius: 4,
    },
    header: {
        marginBottom: 20,
    },
    input: {
        marginBottom: 15,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    locationName: {
        marginTop: 4,
        padding: 8,
        borderRadius: 4,
    },
});

export default AddInventoryStockModal;