import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { View, ScrollView, TouchableOpacity, Animated, StyleSheet, Modal, TouchableWithoutFeedback } from 'react-native';
import { Layout, Text, Icon, Button, Card, Input, OverflowMenu, MenuItem, Toggle, useTheme, IndexPath, ListItem, List } from "@ui-kitten/components";
import { Route, ROUTE_LOCATION_TYPE, ROUTE_STATUS, RouteDetails, RouteLocation } from '../../models/Route.model';
import { Member } from '../../models/Member.model';
import { JobReportModal } from '../dashboardComponents/jobReportModal.component';
import * as JobController from '../../functions/job.controller';
import * as RouteController from '../../functions/route.controller';
//@ts-ignore
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as MembershipController from '../../functions/membership.controller';
import * as StorageController from "../../functions/storageController";
import AssignJobModal from '../job/modals/assignJobModal.component';
import { Job, JOB_STATUS } from '../../models/Job.model';
import { Badge } from '../job/components/Badge.component';
import Toast from 'react-native-toast-message';
import { RouteLocationDetailsCard } from './routeLocationDetailsCard.component';
import { AvailableJobs } from './AvailableJobs';
import { RouteGroup, RouteOptimizationRequest } from '../../types/route.types';
import { EditableLocations } from './EditableLocations';
import { ItemPicker } from './ItemPicker';
import { CompanySavedAddress } from '../../models/Company.model';

interface RouteSummaryProps {
    route: Route;
    routeReadyForCreation: boolean;
    onCreateRoute: (route: Route) => void;
    onAssignDriver: (driverId: string) => void;
    // isRouteCreated: boolean;
    onUpdatePlannedStartTime: (time: Date) => void;
    onSelectRoute: (route: Route) => void;
    onSelectJobId: (job_id: string) => void;
    onUpdateRouteStatus: (routeId: string, newStatus: ROUTE_STATUS) => void;
    onUpdateRoute: (updatedRoute: Route) => void;
    selectedRouteIndex?: number | null;
}

export const RouteSummary = ({
    route,
    routeReadyForCreation,
    onCreateRoute,
    onAssignDriver,
    // isRouteCreated,
    onUpdatePlannedStartTime,
    onSelectRoute,
    onSelectJobId,
    onUpdateRouteStatus,
    onUpdateRoute,
    selectedRouteIndex,
}: RouteSummaryProps) => {
    const theme = useTheme();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [routeCanBeCreated, setRouteCanBeCreated] = useState(false);
    const [routeCanBeAssigned, setRouteCanBeAssigned] = useState(false);
    const [showCreateRouteModal, setShowCreateRouteModal] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [animation] = useState(new Animated.Value(0));
    const [selectedJob, setSelectedJob] = useState<Job | null>(null);
    const [showJobReportModal, setShowJobReportModal] = useState(false);
    const [drivers, setDrivers] = useState<Member[]>([]);
    const [selectedDriver, setSelectedDriver] = useState<string | null>(null);
    const [plannedStartTime, setPlannedStartTime] = useState(new Date());
    const [showAssignJobModal, setShowAssignJobModal] = useState(false);
    const [statusMenuVisible, setStatusMenuVisible] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedRoute, setEditedRoute] = useState<Route>(route);
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [selectedLocationIndex, setSelectedLocationIndex] = useState<number | null>(null);
    const [recalculationMode, setRecalculationMode] = useState<'optimize' | 'directions'>('optimize');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [showAvailableJobsModal, setShowAvailableJobsModal] = useState(false);
    const [expandedJobs, setExpandedJobs] = useState<any[]>([]);
    const [showItemPicker, setShowItemPicker] = useState(false);

    // const totalDistance = route.details.legs.reduce((sum, leg) => sum + leg.actual_distance_kms, 0);
    // const totalDistance = route.details.distance_kms;
    // const totalDuration = route.details.legs.reduce((sum, leg) => sum + leg.actual_duration_seconds, 0);
    // const totalDuration = (route.details.duration_seconds / 60).toFixed(2);
    const isRouteCreated = route._id !== undefined;

    const getTotalDistance = (route: Route): string => {
        if (!route.details.legs) return 'N/A';
        return `${route.details.distance_kms?.toFixed(2)}`;
        // return `${route.details.legs.reduce((sum, leg) => sum + leg.actual_distance_kms, 0).toFixed(2)} km`;
    };

    const getTotalDuration = (route: Route): string => {
        if (!route.details.duration_seconds) return 'N/A';
        return `${(route.details.duration_seconds / 60).toFixed(2)}`;
        // return `${Math.round(route.details.legs.reduce((sum, leg) => sum + leg.actual_duration_seconds, 0) / 60)} min`;
    };

    const polylineStrokeColourSet = (index: number) => {
        const colours = [
            "#0000FF", "#4B0082", "#9400D3", "#FF7F00", "#FF0000",
            "#00FF00", "#00FFFF", "#FF00FF", "#FFD700", "#32CD32",
            "#8A2BE2", "#FF1493", "#1E90FF", "#FF4500", "#00CED1",
        ];
        return colours[index % colours.length];
    };

    const editedRouteRef = useRef<Route>(route);

    const onSetEditedRoute = (updatedRoute: Route, caller: string = "n/a") => {
        console.log("🚀============== ~ file: RouteSummary.tsx:106 ~ onSetEditedRoute ~ updatedRoute🚀==============", updatedRoute, caller)
        editedRouteRef.current = updatedRoute;
        setEditedRoute(updatedRoute);
        setHasUnsavedChanges(true);
    };

    const handleOpenAssignModal = () => {
        setShowAssignJobModal(true);
    };

    const handleCreateRoute = () => {
        setShowCreateRouteModal(true);
    };

    const confirmCreateRoute = () => {
        onCreateRoute(route);
        setShowCreateRouteModal(false);
    };

    useEffect(() => {
        if (route.status === ROUTE_STATUS.PLANNED) {
            setRouteCanBeAssigned(true);
        }
    }, [route.status]);

    useEffect(() => {
        if (routeReadyForCreation) {
            setRouteCanBeCreated(true);
        }
    }, [routeReadyForCreation]);

    const onSetSelectedJob = async (job_id: string) => {
        const job = await JobController.getJobById(job_id);
        if (job) {
            setSelectedJob(job);
            setShowJobReportModal(true);
        }
    };

    useEffect(() => {
        if (isExpanded) {
            // async function loadJobs() {
            //     if (route._id) {
            //         const jobs = await RouteController.getAllJobsByRouteId(route._id);
            //         setExpandedJobs(jobs);
            //     } else {
            //         const jobs = StorageController.getCurrentCompany().jobs;
            //         const filteredJobs = jobs.filter((job: Job) => {
            //             return route.details.locations.some((location: RouteLocation) => location.job_id === job._id);
            //         });
            //         setExpandedJobs(filteredJobs);
            //     }
            // }
            loadJobs();
        }
    }, [route, isExpanded])

    const loadJobs = async () => {
        if (route._id) {
            const jobs = await RouteController.getAllJobsByRouteId(route._id);
            setExpandedJobs(jobs);
        } else {
            const jobs = StorageController.getCurrentCompany().jobs;
            const filteredJobs = jobs.filter((job: Job) => {
                return route.details.locations.some((location: RouteLocation) => location.job_id === job._id);
            });
            setExpandedJobs(filteredJobs);
        }
    }

    const toggleExpand = useCallback(async () => {
        const toValue = isExpanded ? 0 : 1;
        Animated.timing(animation, {
            toValue,
            duration: 300,
            useNativeDriver: false,
        }).start();

        // if (!isExpanded) {
        //     try {
        //         if (route._id) {
        //             const jobs = await RouteController.getAllJobsByRouteId(route._id);
        //             setExpandedJobs(jobs);
        //         } else {
        //             const jobs = StorageController.getCurrentCompany().jobs;
        //             const filteredJobs = jobs.filter((job: Job) => {
        //                 return editedRouteRef.current.details.locations.some((location: RouteLocation) => location.job_id === job._id);
        //             });
        //             setExpandedJobs(filteredJobs);
        //         }
        //     } catch (error) {
        //         console.error("Error fetching jobs for route:", error);
        //         Toast.show({
        //             type: 'error',
        //             text1: 'Error',
        //             text2: 'Failed to load jobs for this route',
        //         });
        //     }
        // }

        setIsExpanded(!isExpanded);
    }, [isExpanded, route._id, route]);

    const getMatchedJob = useCallback((location: RouteLocation) => {
        try {
            if (!expandedJobs) return null;
            return expandedJobs?.find(job => job._id === location.job_id);
        } catch (error) {
            console.error("Error getting matched job:", error);
            return null;
        }
    }, [expandedJobs]);

    const maxHeight = animation.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 500],
    });

    const assignedDriver = drivers.find(driver => driver._id === route.member_id);

    useEffect(() => {
        loadDrivers();
    }, []);

    const loadDrivers = async () => {
        const company = StorageController.getCurrentCompany();
        const fetchedDrivers = await MembershipController.getDriversByCompanyId(company._id);
        setDrivers(fetchedDrivers);
    };

    const handlePlannedStartTimeChange = (newTime: Date) => {
        try {
            setPlannedStartTime(newTime);
            onUpdatePlannedStartTime(newTime);
        } catch (error) {
        }
    };

    const handleAssignDriver = async (selectedDriver: Member) => {
        if (selectedDriver) {
            await route.assignDriver(selectedDriver._id);
            const company = StorageController.getCurrentCompany()
            const jobIds = route.details.locations.map((location: RouteLocation) => { return location.job_id })
            await JobController.assignMultipleJobsToMember(jobIds, selectedDriver._id, company._id)
            onAssignDriver(selectedDriver._id);
            setShowAssignJobModal(false);
        }
    };

    const handleDismissAssignJobModal = () => {
        setShowAssignJobModal(false);
    };

    const renderStatusToggle = () => (
        <Button
            size="tiny"
            appearance="ghost"
            onPress={() => setStatusMenuVisible(true)}
            accessoryLeft={<Icon name="more-vertical" fill={theme['color-basic-600']} style={{ width: 24, height: 24 }} />}
        />
    );

    const onStatusSelect = (index: IndexPath) => {
        setStatusMenuVisible(false);
        const newStatus = Object.values(ROUTE_STATUS)[index.row];
        if (!route._id) return
        onUpdateRouteStatus(route._id, newStatus);
    };

    const toggleEditMode = () => {
        setIsEditMode(!isEditMode);
        if (!isEditMode) {
            onSetEditedRoute(new Route(route), "toggleEditMode");
            onSelectRoute(route);
        }
    };

    const handleLocationEdit = (index: number) => {
        setSelectedLocationIndex(index);
        setShowLocationModal(true);
    };

    const handleLocationUpdate = (updatedLocation: RouteLocation) => {
        if (!route._id) return
        const updatedLocations = [...editedRouteRef.current.details.locations];
        updatedLocations[selectedLocationIndex!] = updatedLocation;
        onSetEditedRoute(new Route({ ...editedRouteRef.current, details: new RouteDetails({ ...editedRouteRef.current.details, locations: updatedLocations }) }), "handleLocationUpdate");
        setShowLocationModal(false);
    };

    const jobIdsToRemoveRef = useRef<string[]>([]);
    const jobIdsToAddRef = useRef<string[]>([]);

    const handleLocationRemove = (index: number) => {
        console.log("🚀============== ~ file: RouteSummary.tsx:297 ~ handleLocationRemove ~ index🚀==============", index)
        const locationToRemove = editedRouteRef.current.details.locations[index];
        console.log("🚀============== ~ file: RouteSummary.tsx:298 ~ handleLocationRemove ~ locationToRemove🚀==============", locationToRemove)
        let updatedLocations = [...editedRouteRef.current.details.locations];

        // Remove only the specific location
        updatedLocations.splice(index, 1);

        // Update indices
        updatedLocations.forEach((location, idx) => {
            location.index = idx;
        });

        jobIdsToRemoveRef.current.push(locationToRemove.job_id);
        const updatedRoute = new Route({ ...editedRouteRef.current, details: new RouteDetails({ ...editedRouteRef.current.details, locations: updatedLocations }) });
        onSetEditedRoute(updatedRoute, "handleLocationRemove");
    };

    const handleLocationMove = async (index: number, direction: 'up' | 'down') => {
        const newLocations = [...editedRouteRef.current.details.locations];
        const newIndex = direction === 'up' ? index - 1 : index + 1;

        if (newIndex >= 0 && newIndex < newLocations.length) {
            // Move the location individually
            [newLocations[index], newLocations[newIndex]] = [newLocations[newIndex], newLocations[index]];

            // Update indices
            newLocations.forEach((location, idx) => {
                location.index = idx;
            });

            const updatedRoute = new Route({
                ...editedRouteRef.current,
                details: new RouteDetails({ ...editedRouteRef.current.details, locations: newLocations })
            });

            onSetEditedRoute(updatedRoute, "handleLocationMove");
            setHasUnsavedChanges(true);

            // Automatically recalculate the route after moving a location
            await handleRecalculateRoute();
        }
    };

    const [availableJobs, setAvailableJobs] = useState<Job[]>([]);

    const handleAddLocation = async () => {
        if (!route.company_id) return
        const availableJobs = await RouteController.getAvailableJobsForRoute(route.company_id, route._id);
        setAvailableJobs(availableJobs);
        setShowAvailableJobsModal(true);
    };

    const handleDismissAvailableJobsModal = () => {
        setShowAvailableJobsModal(false);
    };

    const handleAddJobToRoute = async (jobs: Job | Job[]) => {
        const jobsArray = Array.isArray(jobs) ? jobs : [jobs];
        setShowAvailableJobsModal(false);
        const newLocations: RouteLocation[] = [];
        jobsArray.forEach(job => {
            jobIdsToAddRef.current.push(job._id);
            if (job.details.options.towing_job_options && job.details.towing_details) {
                // For towing jobs, add a location for each towing location
                job.details.towing_details.towing_locations.forEach((towingLocation: any, index: number) => {
                    const towingLocations = job.details.towing_details?.towing_locations || [];
                    newLocations.push(new RouteLocation({
                        index: editedRouteRef.current.details.locations.length + newLocations.length,
                        job_id: job._id,
                        job_location_index: index,
                        name: `${job.details.customer_details.name || `Job ${job._id}`} - ${towingLocation.name}`,
                        address: towingLocation.address,
                        location: towingLocation.location,
                        is_towing_location: true,
                        route_location_type: index === 0 ? ROUTE_LOCATION_TYPE.PICKUP : index === towingLocations.length - 1 ? ROUTE_LOCATION_TYPE.DELIVERY : ROUTE_LOCATION_TYPE.INTERMEDIATE
                    }));
                });
            } else {
                // For non-towing jobs, add a single location
                newLocations.push(new RouteLocation({
                    index: editedRouteRef.current.details.locations.length,
                    job_id: job._id,
                    job_location_index: 0,
                    name: job.details.customer_details.name || `Job ${job._id}`,
                    address: job.details.address,
                    location: job.details.location,
                    is_towing_location: false
                }));
            }
        });
        // add the location to the route
        const updatedLocations = [...editedRouteRef.current.details.locations, ...newLocations];
        onSetEditedRoute(new Route({ ...editedRouteRef.current, details: new RouteDetails({ ...editedRouteRef.current.details, locations: updatedLocations }) }), "handleAddJobToRoute");
    };

    const handleRemoveJobFromRoute = async (job_id: string) => {
        jobIdsToRemoveRef.current.push(job_id);
        // remove the location from the route
        const updatedLocations = editedRouteRef.current.details.locations.filter(location => location.job_id !== job_id);
        onSetEditedRoute(new Route({ ...editedRouteRef.current, details: new RouteDetails({ ...editedRouteRef.current.details, locations: updatedLocations }) }), "handleRemoveJobFromRoute");
    };

    const handleSaveChanges = async () => {
        try {
            // remove the jobs from the route
            if (jobIdsToRemoveRef.current.length > 0) {
                jobIdsToRemoveRef.current.forEach(async (job_id) => {
                    const job = await JobController.getJobById(job_id);
                    if (job && job.route_id === editedRouteRef.current._id) {
                        job.route_id = null;
                        await JobController.updateJob(job);
                    }
                    if (!editedRouteRef.current._id) return
                    await RouteController.removeJobFromRoute(editedRouteRef.current._id, job_id);
                    console.log('job removed from route', job_id);
                });
            }
            // add the jobs to the route
            if (jobIdsToAddRef.current.length > 0) {
                jobIdsToAddRef.current.forEach(async (job_id) => {
                    const job = await JobController.getJobById(job_id);
                    if (job && job.route_id !== editedRouteRef.current._id) {
                        job.route_id = editedRouteRef.current._id;
                        await JobController.updateJob(job);
                    }
                    if (!editedRouteRef.current._id) return
                    await RouteController.addJobToRoute(editedRouteRef.current._id, job_id);
                    console.log('job added to route', job_id);
                });
            }
            if (!editedRouteRef.current._id) return
            let updatedRoute = await RouteController.updateRoute(editedRouteRef.current);
            updatedRoute = new Route(updatedRoute);
            updatedRoute = await updatedRoute.updateLocations(null);

            editedRouteRef.current = updatedRoute;
            onUpdateRoute(updatedRoute);
            setIsEditMode(false);
            setHasUnsavedChanges(false);
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: 'Route updated successfully',
            });
        } catch (error) {
            console.error("Error updating route:", error);
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: 'Failed to update route',
            });
        }
    };

    const handleRecalculateRoute = async () => {
        try {
            let recalculatedRoute: Route;
            if (recalculationMode === 'optimize') {
                const startAddress = new CompanySavedAddress({
                    _id: null,
                    name: editedRouteRef.current.details.locations[0].name,
                    address: editedRouteRef.current.details.locations[0].address,
                    location: editedRouteRef.current.details.locations[0].location,
                    phone: '',
                    email: '',
                    category: ''
                });
                const endAddress = new CompanySavedAddress({
                    _id: null,
                    location: editedRouteRef.current.details.locations[editedRouteRef.current.details.locations.length - 1].location,
                    name: editedRouteRef.current.details.locations[editedRouteRef.current.details.locations.length - 1].name,
                    formatted_address: editedRouteRef.current.details.locations[editedRouteRef.current.details.locations.length - 1].address.addressToString(),
                    phone: '',
                    email: '',
                    category: ''
                });
                const roundTripAddress = startAddress;
                const routeGroup: RouteGroup = {
                    numberOfVehicles: 1,
                    startEndOption: editedRouteRef.current.details.startEndOption,
                    // locations: editedRouteRef.current.details.locations,
                    startAddress,
                    endAddress,
                    roundTripAddress
                }
                const optimizationRequest: RouteOptimizationRequest = {
                    locations: editedRouteRef.current.details.locations,
                    numberOfRoutes: 1,
                    routeGroups: [routeGroup]
                };
                const result = await RouteController.calculateOptimisedRoute(optimizationRequest);
                recalculatedRoute = result.routes[0];
            } else {
                recalculatedRoute = await RouteController.calculateOrderedRoute(editedRouteRef.current);
            }
            recalculatedRoute._id = editedRouteRef.current._id;
            recalculatedRoute.name = editedRouteRef.current.name;
            recalculatedRoute.company_id = editedRouteRef.current.company_id;
            recalculatedRoute.member_id = editedRouteRef.current.member_id;
            recalculatedRoute.planned_start_time = editedRouteRef.current.planned_start_time;
            recalculatedRoute.status = editedRouteRef.current.status;
            recalculatedRoute.completed_time = editedRouteRef.current.completed_time;
            recalculatedRoute.details.startEndOption = editedRouteRef.current.details.startEndOption;
            // recalculatedRoute.details.locations = editedRouteRef.current.details.locations;
            onSetEditedRoute(recalculatedRoute, "handleRecalculateRoute");
            onUpdateRoute(recalculatedRoute);
            setHasUnsavedChanges(true);
            Toast.show({
                type: 'success',
                text1: 'Success',
                text2: `Route ${recalculationMode === 'optimize' ? 'optimized' : 'recalculated'} successfully`,
            });
        } catch (error) {
            console.error("Error recalculating route:", error);
            Toast.show({
                type: 'error',
                text1: 'Error',
                text2: `Failed to ${recalculationMode === 'optimize' ? 'optimize' : 'recalculate'} route`,
            });
        }
    };

    const handleCancelEdit = () => {
        onSetEditedRoute(route, "handleCancelEdit");
        setIsEditMode(false);
        setHasUnsavedChanges(false);
    };

    const handleEditedRouteChange = useCallback((newRoute: Route) => {
        onSetEditedRoute(newRoute, "handleEditedRouteChange");
        setHasUnsavedChanges(true);
    }, []);

    useEffect(() => {
        if (isEditMode) {
            const isEqual = JSON.stringify(route) === JSON.stringify(editedRouteRef.current);
            if (!isEqual) {
                setHasUnsavedChanges(true);
            }
        }
    }, [isEditMode, route, editedRouteRef.current]);

    const RenderLocations = useMemo(() => {
        return (
            <ScrollView>
                {route.details.locations.map((location, index) => {
                    const matchedJob = getMatchedJob(location);
                    return (
                        <React.Fragment key={index}>
                            <View style={{ marginBottom: 4, flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                                <TouchableOpacity onPress={() => {
                                    if (matchedJob) {
                                        onSelectJobId(matchedJob._id);
                                    }
                                }}>
                                    <Icon
                                        name={index === 0 ? 'pin-outline' : (index === route.details.locations.length - 1 ? 'flag' : 'flag-outline')}
                                        fill={theme['color-primary-500']}
                                        style={{ width: 24, height: 24, marginRight: 4 }}
                                    />
                                </TouchableOpacity>
                                <View style={{ flex: 1 }}>
                                    <Text category="s2">{index + 1}. {location.name}</Text>
                                    <Text category="c1">{location.address.addressToString()}</Text>
                                </View>
                                {matchedJob && (
                                    <Badge status={matchedJob.status} />
                                )}
                                {matchedJob && (
                                    <Button
                                        size="tiny"
                                        status="info"
                                        onPress={() => {
                                            onSetSelectedJob(location.job_id);
                                        }}
                                    >
                                        View Job
                                    </Button>
                                )}
                            </View>
                            {index < route.details.legs.length && (
                                <View style={{ marginLeft: 32, marginBottom: 4, flexDirection: 'row', alignItems: 'center' }}>
                                    <Icon
                                        name="arrow-forward-outline"
                                        fill={theme['color-info-500']}
                                        style={{ width: 24, height: 24, marginRight: 4 }}
                                    />
                                    <View style={styles.legInfoContainer}>
                                        <View style={[styles.legInfoValue, { backgroundColor: theme['color-success-200'] }]}>
                                            <Text category="s2">{route.details.legs[index].actual_distance_kms?.toFixed(2)} km</Text>
                                        </View>
                                    </View>
                                    <View style={styles.legInfoContainer}>
                                        <View style={[styles.legInfoValue, { backgroundColor: theme['color-warning-200'] }]}>
                                            <Text category="s2">{Math.round(route.details.legs[index].actual_duration_seconds / 60)} min</Text>
                                        </View>
                                    </View>
                                </View>
                            )}
                        </React.Fragment>
                    );
                })}
            </ScrollView>
        );
    }, [expandedJobs, route]);

    const handleLocationsUpdate = (updatedLocations: RouteLocation[]) => {
        const updatedRoute = new Route({
            ...editedRouteRef.current,
            details: new RouteDetails({
                ...editedRouteRef.current.details,
                locations: updatedLocations
            })
        });
        onSetEditedRoute(updatedRoute, "handleLocationsUpdate");
    };

    const renderEditableLocations = useMemo(() => {
        return (
            <EditableLocations
                route={editedRoute}
                onLocationMove={handleLocationMove}
                onLocationEdit={handleLocationEdit}
                onLocationRemove={handleLocationRemove}
                onLocationsUpdate={handleLocationsUpdate}
                styles={styles}
            />
        );
    }, [editedRoute]);

    const handleRouteUpdate = (updatedRoute: Route) => {
        onUpdateRoute(updatedRoute);
    };

    const renderEditModeButtons = () => (
        <View style={styles.editModeButtonContainer}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                    size="small"
                    status="success"
                    accessoryLeft={(props) => <Icon {...props} name='plus-outline' />}
                    onPress={handleAddLocation}>
                    Add Location
                </Button>
                <Button
                    size="small"
                    status="info"
                    accessoryLeft={(props) => <Icon {...props} name='shopping-bag-outline' />}
                    onPress={() => setShowItemPicker(true)}>
                    Manage Items
                </Button>
            </View>
            <View style={styles.recalculationButtonGroup}>
                <Button
                    size="small"
                    status={recalculationMode === 'optimize' ? 'primary' : 'basic'}
                    onPress={() => setRecalculationMode('optimize')}
                    style={styles.recalculationButton}
                >
                    Optimize
                </Button>
                <Button
                    size="small"
                    status={recalculationMode === 'directions' ? 'primary' : 'basic'}
                    onPress={() => setRecalculationMode('directions')}
                    style={styles.recalculationButton}
                >
                    Keep Order
                </Button>
            </View>
            <Button
                status="info"
                accessoryLeft={(props) => <Icon {...props} name='refresh-outline' />}
                onPress={handleRecalculateRoute}
                style={styles.actionButton}
            >
                {recalculationMode === 'optimize' ? 'Optimize Route' : 'Recalculate Route'}
            </Button>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Button
                    status="success"
                    accessoryLeft={(props) => <Icon {...props} name='save-outline' />}
                    onPress={handleSaveChanges}
                    style={styles.actionButton}
                    disabled={!hasUnsavedChanges}
                >
                    Save Changes
                </Button>
                <Button
                    status="danger"
                    appearance='ghost'
                    accessoryLeft={(props) => <Icon {...props} name='close-outline' />}
                    onPress={handleCancelEdit}
                    style={styles.actionButton}
                >
                    Cancel
                </Button>
            </View>
        </View>
    );

    return (
        <Layout style={{ padding: 16, borderRadius: 8, marginBottom: 4, width: '100%', borderWidth: 1, borderColor: theme['color-basic-500'] }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 4 }}>
                <TouchableOpacity onPress={toggleExpand} style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text category="s1">{route.name}</Text>
                    <Icon
                        name={isExpanded ? 'chevron-up-outline' : 'chevron-down-outline'}
                        fill={theme['color-primary-500']}
                        style={{ width: 24, height: 24, marginLeft: 8 }}
                    />
                </TouchableOpacity>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 40 }}>
                    {isRouteCreated &&
                        <View style={{ flexDirection: 'row' }}>

                            <Button
                                size="small"
                                appearance={isEditMode ? "filled" : "ghost"}
                                onPress={toggleEditMode}
                                status={isEditMode ? "danger" : "basic"}
                                accessoryLeft={() => {
                                    if (isEditMode) {
                                        return <Icon name="close-outline" fill={theme['color-basic-100']} style={{ width: 15, height: 15 }} />
                                    } else {
                                        return <Icon name="edit-outline" fill={theme['color-basic-100']} style={{ width: 15, height: 15 }} />
                                    }
                                }}
                            >
                            </Button>
                            <View style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
                                <Button
                                    size="tiny"
                                    status="warning"
                                    appearance="ghost"
                                    onPress={() => onSelectRoute(route)}
                                    accessoryLeft={<Icon name="map-outline" fill={theme['color-basic-100']} style={{ width: 24, height: 24 }} />}
                                />
                            </View>
                        </View>
                    }
                    <OverflowMenu
                        anchor={renderStatusToggle}
                        visible={statusMenuVisible}
                        onBackdropPress={() => setStatusMenuVisible(false)}
                        onSelect={onStatusSelect}
                    >
                        {Object.values(ROUTE_STATUS).map((status, index) => {
                            const statusName = status.replace('_', ' ').toUpperCase();
                            return (<MenuItem key={index} title={statusName} />)
                        })}
                    </OverflowMenu>
                </View>
            </View>

            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 4 }}>
                <View>
                    <Text category="s1">Total Distance</Text>
                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-success-500'], borderColor: theme['color-success-500'] }]} category="s2">{getTotalDistance(route) || "N/A"} km</Text>
                </View>
                <View>
                    <Text category="s1">Total Duration</Text>
                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-warning-400'], borderColor: theme['color-warning-400'] }]} category="s2">{getTotalDuration(route) || "N/A"} min</Text>
                </View>
                <View>
                    <Text category="s1">Locations</Text>
                    <Text style={[styles.detailTextPill, { backgroundColor: theme['color-info-800'], borderColor: theme['color-info-800'] }]} category="s2">{route.details.locations.length}</Text>
                </View>
                {!isRouteCreated && (
                    <Button onPress={handleCreateRoute} disabled={!routeCanBeCreated}>
                        Create Route
                    </Button>
                )}
            </View>
            {!isEditMode &&
                <Animated.View style={{ maxHeight, overflow: 'hidden' }}>
                    {RenderLocations}
                </Animated.View>
            }
            <ScrollView horizontal showsHorizontalScrollIndicator={true}>
                {route.details.locations.map((location, index) => (
                    <React.Fragment key={index}>
                        <View style={{ alignItems: 'center', marginRight: 1 }}>
                            <Icon
                                name={index === 0 ? 'pin-outline' : (index === route.details.locations.length - 1 ? 'flag' : 'flag-outline')}
                                fill={theme['color-primary-500']}
                                style={{ width: 24, height: 24 }}
                            />
                            <Text category="c1">{location.name}</Text>
                            <Text category="c1">{location.route_location_type}</Text>
                        </View>
                        {index < route.details.locations.length - 1 && (
                            <View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 8 }}>
                                <View style={{
                                    height: 2,
                                    width: 40,
                                    backgroundColor: polylineStrokeColourSet(index),
                                    marginHorizontal: 4
                                }} />
                                <Icon
                                    name="arrow-forward-outline"
                                    fill={theme['color-basic-600']}
                                    style={{ width: 16, height: 16 }}
                                />
                            </View>
                        )}
                    </React.Fragment>
                ))}
            </ScrollView>
            <View style={{ flexDirection: 'row', gap: 20 }}>
                <View style={styles.timePickerContainer}>
                    <Text category="s1">Planned Start Time</Text>
                    <TimePicker value={plannedStartTime} onChange={handlePlannedStartTimeChange} />
                </View>
                {isRouteCreated && (
                    <View style={{ marginTop: 4, flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                        <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                            <Text category="s1">Assigned Driver:</Text>
                            {assignedDriver ?
                                <View style={[styles.detailTextPill, { backgroundColor: theme['color-success-900'], borderColor: theme['color-primary-500'] }]}>
                                    <Text category="s2">{assignedDriver?.name || 'No driver assigned'}</Text>
                                </View>
                                :
                                <Text category="s2">No driver assigned</Text>
                            }
                            <View style={{}}>

                                {assignedDriver ? (
                                    <View style={{ marginBottom: 4 }}>
                                        <Button
                                            size="tiny"
                                            appearance="outline" onPress={handleOpenAssignModal} style={{ marginTop: 8 }}>
                                            Reassign Driver
                                        </Button>
                                    </View>
                                ) : (
                                    <View>
                                        <Button
                                            size="tiny"
                                            onPress={handleOpenAssignModal} disabled={!routeCanBeAssigned} style={{ marginTop: 8 }}>
                                            Assign Driver
                                        </Button>
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                )}
            </View>

            {isEditMode && (
                <View style={styles.editModeContainer}>
                    {hasUnsavedChanges && (
                        <Text status="warning" category="s1" style={styles.unsavedChangesText}>
                            You have unsaved changes
                        </Text>
                    )}
                    {renderEditableLocations}
                    {renderEditModeButtons()}
                </View>
            )}

            <Modal
                visible={showCreateRouteModal}
                transparent={true}
                animationType="slide"
                onRequestClose={() => setShowCreateRouteModal(false)}
            >
                <Card disabled>
                    <Text category="h6" style={{ marginBottom: 16 }}>Confirm Route Creation</Text>
                    <Text>
                        Are you sure you want to create a route with the following locations?
                    </Text>
                    <ScrollView style={{ maxHeight: 200, marginVertical: 16 }}>
                        {route.details.locations.map((location, index) => (
                            <Text key={index}>{location.name}</Text>
                        ))}
                    </ScrollView>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Button appearance="ghost" onPress={() => setShowCreateRouteModal(false)} style={{ marginRight: 8 }}>
                            Cancel
                        </Button>
                        <Button onPress={confirmCreateRoute}>
                            Confirm
                        </Button>
                    </View>
                </Card>
            </Modal>

            <JobReportModal
                showReportDetailsModal={showJobReportModal}
                setShowReportDetailsModal={setShowJobReportModal}
                job={selectedJob as Job}
                showImages={true}
                showLogs={true}
            />

            <AssignJobModal
                var_member_id={route.member_id}
                var_assignJobModalVisible={showAssignJobModal}
                selectedJob={null}
                fn_assignMember={handleAssignDriver}
                fn_dismiss={handleDismissAssignJobModal}
            />
            <Modal
                visible={showAvailableJobsModal}
                transparent={true}
                animationType="slide"
                onRequestClose={() => setShowAvailableJobsModal(false)}
            >
                <View style={{ flex: 1, backgroundColor: 'rgba(0, 0, 0, 0.5)', alignItems: 'center', justifyContent: 'center' }}>
                    <Layout style={{ maxHeight: "80%", maxWidth: "80%", alignItems: 'center' }}>
                        <AvailableJobs
                            availableJobs={availableJobs}
                            onSelectJob={handleAddJobToRoute}
                            isJobSelected={() => { return false; }}
                            getJobOrderBadge={() => { return null; }} onCreateJob={function (): void {
                                // throw new Error('Function not implemented.');
                            }} />
                        <Button onPress={() => setShowAvailableJobsModal(false)}>Close</Button>
                    </Layout>
                </View>
            </Modal>

            <Modal
                visible={showLocationModal}
                transparent={true}
                animationType="slide"
                onRequestClose={() => setShowLocationModal(false)}
            >
                <Card disabled>
                    <RouteLocationDetailsCard
                        location={editedRouteRef.current.details.locations[selectedLocationIndex!]}
                        onChangeRouteLocation={handleLocationUpdate}
                        comapnyInventoryItems={[]} // Pass the actual company inventory items here
                        onCloseDetailsPanel={() => setShowLocationModal(false)}
                        openJobReportModal={() => { }} // Implement this function if needed
                        onSaveLocation={handleLocationUpdate}
                    />
                </Card>
            </Modal>

            <Modal
                visible={showItemPicker}
                onRequestClose={() => setShowItemPicker(false)}
                transparent={true}
                animationType="slide"
            >
                <TouchableWithoutFeedback onPress={() => setShowItemPicker(false)}>
                    <View style={styles.modalOverlay}>
                        <TouchableWithoutFeedback>
                            <Layout style={styles.modalContent}>
                                <Text category="h5" style={{ marginBottom: 16 }}>Manage Route Items</Text>
                                <ItemPicker route={route} onRouteUpdate={handleRouteUpdate} />
                                <Button
                                    size="small"
                                    status="basic"
                                    onPress={() => setShowItemPicker(false)}
                                    style={{ marginTop: 16 }}
                                >
                                    Close
                                </Button>
                            </Layout>
                        </TouchableWithoutFeedback>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>
        </Layout >
    );
};

interface TimePickerProps {
    value: Date;
    onChange: (date: Date) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({ value, onChange }) => {
    const theme = useTheme();
    const [showPicker, setShowPicker] = useState(false);

    return (
        <View>
            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                <View style={[styles.detailTextPill, { backgroundColor: theme['color-basic-900'], borderColor: theme['color-basic-500'] }]}>
                    <Text style={{ color: theme['color-basic-100'] }}>{value.toLocaleTimeString([], { day: '2-digit', month: 'short', year: '2-digit', hour: '2-digit', minute: '2-digit' })}</Text>
                </View>

                <Button onPress={() => setShowPicker(true)} size="small">
                    Select
                </Button>
            </View>
            {showPicker && (
                <Layout style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                    <DatePicker
                        selected={value}
                        onChange={onChange}
                        showTimeSelect
                        timeIntervals={15}
                        shouldCloseOnSelect={false}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        minDate={new Date()}
                        inline
                    />
                    <Button onPress={() => setShowPicker(false)}>Close</Button>
                </Layout>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    timePickerContainer: {
        marginTop: 16,
    },
    legInfoContainer: {
        flexDirection: 'row',
        marginRight: 16,
        gap: 8,
    },
    legInfoLabel: {
        marginBottom: 4,
    },
    legInfoValue: {
        borderRadius: 8,
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
    detailTextPill: {
        borderWidth: 1,
        borderRadius: 8,
        paddingHorizontal: 8,
        paddingVertical: 4,
    },
    container: {
        padding: 16,
        borderRadius: 8,
        marginBottom: 4,
        width: '100%',
        borderWidth: 1,
        borderColor: 'rgba(143, 155, 179, 0.16)',
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    },
    locationItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 8,
        borderWidth: 1,
        borderColor: 'rgba(143, 155, 179, 0.16)',
        borderRadius: 4,
        marginBottom: 8,
    },
    editModeContainer: {
        marginTop: 16,
        borderTopWidth: 1,
        borderTopColor: 'rgba(143, 155, 179, 0.16)',
        paddingTop: 16,
        gap: 8,
    },
    unsavedChangesText: {
        marginBottom: 8,
        fontStyle: 'italic',
    },
    editModeButtonContainer: {
        gap: 8,
    },
    recalculationButtonGroup: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    recalculationButton: {
        flex: 1,
        marginHorizontal: 4,
    },
    actionButton: {
        marginTop: 4,
    },
    modalOverlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        padding: 20,
        borderRadius: 10,
        width: '40%',
        maxHeight: '90%',
    },
});