import React, { useState, useEffect, useCallback } from 'react';
import { View, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { Text, List, useTheme, Layout, Divider, Autocomplete, AutocompleteItem, Button, Icon } from "@ui-kitten/components";
import { Job } from '../../models/Job.model';
import { Badge } from '../job/components/Badge.component';
import * as StorageController from '../../functions/storageController';

interface AvailableJobsProps {
    availableJobs: Job[];
    onSelectJob: (job: Job | Job[]) => void;
    isJobSelected: (job: Job) => boolean;
    getJobOrderBadge: (job: Job) => string | null;
    onCreateJob: () => void; // Add this prop
}

export const AvailableJobs = ({ availableJobs, onSelectJob, isJobSelected, getJobOrderBadge, onCreateJob }: AvailableJobsProps) => {
    const [clients, setClients] = useState<any[]>([]);
    const [services, setServices] = useState<string[]>([]);
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [selectedService, setSelectedService] = useState<string | null>(null);
    const [filteredJobs, setFilteredJobs] = useState<Job[]>(availableJobs);

    const theme = useTheme();

    useEffect(() => {
        const company = StorageController.getCurrentCompany();
        if (company) {
            setClients(company.clients || []);
        }

        const allServices = Array.from(new Set(availableJobs.flatMap(job => job.getServicesNames())));
        setServices(allServices);
    }, [availableJobs]);

    useEffect(() => {
        filterJobs();
    }, [selectedClient, selectedService, availableJobs]);

    const filterJobs = useCallback(() => {
        let filtered = availableJobs;
        if (selectedClient) {
            filtered = filtered.filter(job => getClientName(job) === selectedClient);
        }
        if (selectedService) {
            filtered = filtered.filter(job => job.getServicesNames().includes(selectedService));
        }
        setFilteredJobs(filtered);
    }, [selectedClient, selectedService, availableJobs]);

    const getClientName = (job: Job) => {
        const client_id = job.client_id;
        const clients = StorageController.getCurrentCompany()?.clients;
        const client = clients?.find((c) => c._id == client_id);
        return client?.name || '--';
    }

    const renderOption = (item: string) => (
        <AutocompleteItem key={item} title={item} />
    );

    const clearFilters = () => {
        setSelectedClient(null);
        setSelectedService(null);
    };

    const toggleSelectAll = () => {
        const allSelected = filteredJobs.every(job => isJobSelected(job));
        if (allSelected) {
            onSelectJob(filteredJobs.filter(job => isJobSelected(job)));
        } else {
            onSelectJob(filteredJobs.filter(job => !isJobSelected(job)));
        }
    };

    const RenderListItem = ({ item }: { item: Job }) => {
        const selected = isJobSelected(item);
        const clientName = getClientName(item);
        const serviceNames = item.getServicesNames()
        const orderBadge = getJobOrderBadge(item);
        const address = item.details.address.addressToString()
        const suburb = item.details.address.suburb || item.details.address.city
        return (
            <TouchableOpacity
                onPress={() => onSelectJob(item)}
                style={[
                    styles.jobItem,
                    { backgroundColor: selected ? theme['color-success-100'] : 'transparent' }
                ]}
            >
                <View style={styles.jobHeader}>
                    {orderBadge && (
                        <View style={[styles.orderBadge, { backgroundColor: theme['color-info-500'] }]}>
                            <Text category="c1" style={styles.orderBadgeText}>{orderBadge}</Text>
                        </View>
                    )}
                    <View style={styles.jobMainInfo}>
                        <Text category="s1" style={styles.jobTitle}>{clientName}</Text>
                    </View>
                    <Badge status={item.status} />
                </View>
                <View style={styles.jobDetails}>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={[styles.detailItem, { flex: 1 }]}>
                            <Icon name="car-outline" fill={theme['color-success-500']} style={styles.icon} />
                            <Text category="c1" style={styles.detailText}>{serviceNames}</Text>
                        </View>
                        <View style={[styles.detailItem, { flex: 1 }]}>
                            <Icon name="person-outline" fill={theme['color-primary-500']} style={styles.icon} />
                            <Text category="c1" style={styles.detailText}>{item.details.customer_details.name}</Text>
                        </View>
                    </View>
                    <View style={styles.detailItem}>
                        <Icon name="pin-outline" fill={theme['color-warning-500']} style={styles.icon} />
                        <Text category="c1" style={[styles.detailText, { fontWeight: 'bold' }]} numberOfLines={1} ellipsizeMode="tail">
                            {suburb} <Text category="c1" style={styles.detailText}>{address}</Text>
                        </Text>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    const filterData = (query: string, data: string[]) => {
        const lowercaseQuery = query.toLowerCase();
        return data.filter(item => item.toLowerCase().includes(lowercaseQuery));
    };

    return (
        <Layout style={styles.container}>
            <View style={styles.header}>
                <Text category="s1" style={styles.title}>Available Jobs</Text>
                <View style={styles.headerButtons}>
                    {/* <Button
                        size='tiny'
                        status='primary'
                        appearance='ghost'
                        accessoryLeft={(props) => <Icon {...props} name='plus-outline' />}
                        onPress={onCreateJob}
                        style={styles.createJobButton}
                    >
                        Create Job
                    </Button> */}
                    <Button
                        size='tiny'
                        onPress={toggleSelectAll}
                        status='basic'
                        style={styles.selectAllButton}
                    >
                        {filteredJobs.every(job => isJobSelected(job)) ? 'Clear' : 'Select All'}
                    </Button>
                </View>
            </View>
            <View style={styles.filterContainer}>
                <Autocomplete
                    size="small"
                    placeholder='Filter by Client'
                    textStyle={{ maxWidth: 100 }}
                    value={selectedClient || ''}
                    onSelect={index => setSelectedClient(filterData(selectedClient || '', clients.map(c => c.name))[index])}
                    onChangeText={setSelectedClient}
                    style={styles.filterInput}
                >
                    {filterData(selectedClient || '', clients.map(c => c.name)).map(renderOption)}
                </Autocomplete>
                <Autocomplete
                    size="small"
                    textStyle={{ maxWidth: 100 }}
                    placeholder='Filter by Service'
                    value={selectedService || ''}
                    onSelect={index => setSelectedService(filterData(selectedService || '', services)[index])}
                    onChangeText={setSelectedService}
                    style={styles.filterInput}
                >
                    {filterData(selectedService || '', services).map(renderOption)}
                </Autocomplete>
                <Button
                    size='small'
                    appearance='ghost'
                    onPress={clearFilters}
                    accessoryLeft={props => <Icon {...props} name='close-outline' />}
                    style={styles.clearButton}
                />
            </View>
            <ScrollView style={styles.jobList}>
                {filteredJobs.map((item, index) => (
                    <React.Fragment key={item._id}>
                        <RenderListItem item={item} />
                        {index < filteredJobs.length - 1 && <Divider />}
                    </React.Fragment>
                ))}
            </ScrollView>
        </Layout>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 8,
    },
    title: {
        marginBottom: 1,
    },
    filterContainer: {
        flexDirection: 'row',
        marginBottom: 8,
        alignItems: 'center',
    },
    filterInput: {
        flex: 1,
        marginRight: 8,
        height: 20,
    },
    clearButton: {
        width: 40,
    },
    selectAllButton: {
        height: 20,
    },
    jobList: {
        flex: 1,
    },
    jobItem: {
        padding: 4,
        borderRadius: 8,
        marginBottom: 1,
    },
    jobHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
    },
    jobMainInfo: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    jobTitle: {
        marginRight: 8,
        fontWeight: 'bold',
    },
    orderBadge: {
        paddingHorizontal: 8,
        paddingVertical: 4,
        borderRadius: 12,
    },
    orderBadgeText: {
        color: 'white',
    },
    jobDetails: {
        marginTop: 4,
    },
    detailItem: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 8,
    },
    detailText: {
        flex: 1,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 2,
        borderBottomWidth: 1,
        borderBottomColor: 'lightgray',
    },
    headerButtons: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    createJobButton: {
        marginRight: 8,
        paddingHorizontal: 0,
    },
});