module.exports = {
    test_api: "https://test.api.dispatchme.net.au", //dev
    // api: "https://test.api.dispatchme.net.au", //dev
    api: "https://api.dispatchme.net.au", //prod
    auth_api: "https://auth.dispatchme.net.au",
    mqtt: "wss://mqtt.dispatchme.net.au/mqtt",
    // auth_api: "https://10.0.5.10:5000",
    // api: "http://10.0.5.45:3000", //dev
    // mqtt : "wss://mqtt2.roadmanager.net/mqtt",
    image_api: "https://roadmanager-images.s3.ap-southeast-2.amazonaws.com",
    image_api2: "https://api2.roadmanager.net/imageurl",
    pdf_api: "https://dispatchme-pdfs.s3.ap-southeast-2.amazonaws.com",
}


