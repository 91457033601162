import React, { useState } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { Layout, Text, Button, List, ListItem, Icon, Divider, Modal } from '@ui-kitten/components';
import { CartItem } from './PartsNetworkContainerScreen';
import { CheckoutComponent } from './CheckoutComponent';
import { Company } from '../../models/Company.model';
import { Order } from '../../models/Order.model';
import DMModal from '../common/Modal';

interface CartProps {
  items: CartItem[];
  updateQuantity: (itemId: string, newQuantity: number) => void;
  removeItem: (itemId: string) => void;
  onClose: () => void;
  currentCompany: Company;
  onCheckoutComplete: (order: Order) => void;
}

export const Cart: React.FC<CartProps> = ({ items, updateQuantity, removeItem,
  onClose, currentCompany, onCheckoutComplete }) => {
  const [checkoutVisible, setCheckoutVisible] = useState(false);

  const groupedItems = items.reduce((acc, item) => {
    if (!acc[item.vendorId]) {
      acc[item.vendorId] = {
        vendorName: item.vendorName,
        items: []
      };
    }
    acc[item.vendorId].items.push(item);
    return acc;
  }, {} as Record<string, { vendorName: string; items: CartItem[] }>);

  const handleQuantityChange = (itemId: string, newQuantity: number) => {
    if (newQuantity <= 0) {
      removeItem(itemId);
    } else {
      updateQuantity(itemId, newQuantity);
    }
  };

  const renderCartItem = ({ item }: { item: CartItem }) => (
    <ListItem
      title={`${item.name} (${item.quantity})`}
      description={`$${(item.default_price * item.quantity).toFixed(2)}`}
      accessoryRight={() => (
        <View style={styles.itemActions}>
          <Button
            size='tiny'
            onPress={() => item._id && handleQuantityChange(item._id, item.quantity - 1)}
          >
            -
          </Button>
          <Button
            size='tiny'
            onPress={() => item._id && handleQuantityChange(item._id, item.quantity + 1)}
          >
            +
          </Button>
          <Button
            size='tiny'
            status='danger'
            onPress={() => item._id && removeItem(item._id)}
            accessoryLeft={(props) => <Icon {...props} name='trash-2-outline' />}
          />
        </View>
      )}
    />
  );

  const renderVendorGroup = ({ vendorName, items }: { vendorName: string; items: CartItem[] }) => (
    <View style={styles.vendorGroup}>
      <Text category='h6'>{vendorName}</Text>
      <List
        data={items}
        renderItem={renderCartItem}
        ItemSeparatorComponent={Divider}
      />
      <Text category='s1' style={styles.vendorTotal}>
        Vendor Total: ${items.reduce((sum, item) => sum + item.default_price * item.quantity, 0).toFixed(2)}
      </Text>
    </View>
  );

  const totalPrice = items.reduce((sum, item) => sum + item.default_price * item.quantity, 0);

  const handleCheckoutComplete = (order: Order) => {
    setCheckoutVisible(false);
    onCheckoutComplete(order);
    onClose();
    // You might want to clear the cart or refresh the order list here
  };

  return (
    <Layout style={styles.container}>
      <View style={styles.header}>
        <Text category='h5'>Cart</Text>
        <Button appearance='ghost' status='basic' accessoryLeft={(props) => <Icon {...props} name='close' />} onPress={onClose} />
      </View>
      <ScrollView>
        {Object.values(groupedItems).map((group, index) => (
          <React.Fragment key={index}>
            {renderVendorGroup(group)}
            {index < Object.values(groupedItems).length - 1 && <Divider style={styles.vendorDivider} />}
          </React.Fragment>
        ))}
      </ScrollView>
      <View style={styles.footer}>
        <Divider style={styles.totalDivider} />
        <Text category='h6' style={styles.totalPrice}>Total: ${totalPrice.toFixed(2)}</Text>
        <Button onPress={() => setCheckoutVisible(true)}>Checkout</Button>
      </View>
      <DMModal
        visible={checkoutVisible}
        onClose={() => setCheckoutVisible(false)}
      >
        <CheckoutComponent
          cartItems={items}
          currentCompany={currentCompany}
          onCheckoutComplete={handleCheckoutComplete}
          onClose={() => setCheckoutVisible(false)}
        />
      </DMModal>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  itemActions: {
    flexDirection: 'row',
    gap: 4
  },
  vendorGroup: {
    marginBottom: 16,
  },
  vendorTotal: {
    textAlign: 'right',
    marginTop: 8,
  },
  vendorDivider: {
    marginVertical: 16,
  },
  totalDivider: {
    marginVertical: 8,
  },
  totalPrice: {
    textAlign: 'right',
    marginBottom: 16,
  },
  footer: {
    marginTop: 16,
  },
});
