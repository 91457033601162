import React, { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    //@ts-ignore
} from "react-native";
import {
    Card,
    Text
} from "@ui-kitten/components";
import ErrorBoundary from '../../ErrorBoundary.component';
// import AddressSearch from './addressSearch.component';
import { JobDetailMap } from './mapSelectLocation.component';
import { AddressDetailsCard } from '../../common/address/AddressComponents';
import { JobAddress, JobLocation } from '../../../models/Job.model';
import { CompanySavedAddress } from '../../../models/Company.model';


interface LocationDetailsCardProps {
    style?: any;
    allowEdit?: boolean;
    onSetAddress: (address: JobAddress) => void;
    onSetLocation: (location: JobLocation) => void;
    onSetAddressLocation: (address: JobAddress, location: JobLocation) => void;
    address: JobAddress;
    location: JobLocation;
    searchDisplayValue: string;
    cardTitle?: string;
}


export const LocationDetailsCard = ({
    style = {},
    allowEdit = true,
    onSetAddress,
    onSetLocation,
    onSetAddressLocation,
    address = new JobAddress({}),
    location = new JobLocation({}),
    searchDisplayValue = "",
    cardTitle = "Location Details"
}: LocationDetailsCardProps) => {

    const [addressDisplayValue, setAddressDisplayValue] = useState(searchDisplayValue)

    useEffect(() => {
        setAddressDisplayValue(searchDisplayValue)
    }, [searchDisplayValue])

    useEffect(() => {
        if (typeof address == "string") {
            setAddressDisplayValue(address)
        }
    }, [address])



    const onSetAddressDisplayValue = (value: string) => {
        setAddressDisplayValue(value)
    }

    const onSetSavedAddress = (savedAddress: CompanySavedAddress) => {
        onSetAddress(savedAddress.address)
        onSetLocation(new JobLocation(savedAddress.location))
    }
    return (

        <Card
            status="info"
            header={() => <Text style={{ alignSelf: 'center' }}>{cardTitle || "Location Details"}</Text>}
            disabled={true}
            style={style}
        >
            <ErrorBoundary>
                <View style={{ flexDirection: 'row', justifyContent: "space-between", margin: -15, flexWrap: 'wrap' }}>
                    <View style={{ flex: 1, minWidth: 350 }}>

                        <AddressDetailsCard
                            allowEdit={allowEdit}
                            style={styles.groupContainer}
                            onSetAddress={onSetAddress}
                            onSetLocation={onSetLocation}
                            onSetAddressLocation={onSetAddressLocation}
                            address={address}
                            addressDisplayValue={addressDisplayValue}
                            onSetSavedAddress={onSetSavedAddress}
                        />
                    </View>
                    <View style={{ flex: 1, minWidth: 350 }}>
                        <Card
                            style={styles.groupContainer}
                            status="warning"
                            disabled={true}
                        >
                            <JobDetailMap
                                lat_lng={location}
                                allowEdit={allowEdit}
                                onSetAddress={onSetAddress}
                                onSetLocation={(location) => {
                                    onSetLocation(location)
                                }}
                                onGetFormattedAddress={(formatted_address) => {
                                    onSetAddressDisplayValue(formatted_address)
                                }}
                            />
                            {allowEdit &&
                                <>
                                    <Text>Location of searched address</Text>
                                    <Text>Tip: Click map or drag marker to update location</Text>
                                </>
                            }
                        </Card>
                    </View>
                </View>
            </ErrorBoundary>
        </Card>

    );
}



const styles = StyleSheet.create({
    groupContainer: {
        margin: 10,
        flex: 1,
        // padding: 16,
        // borderRadius: 8,
        // backgroundColor: '#252e56',
    },
});