import React from 'react';
import { View } from "react-native";
import { Button, Text, Icon, Layout } from "@ui-kitten/components";
import { SearchJobsHoldingsCases, SEARCH_TYPE } from '../../job/SearchJobsHoldingsCases';
import { ViewModeButtons } from '../../job/ViewModeButtons';
import { Job } from '../../../models/Job.model';
import { Case } from '../../../models/Case.model';
import { Holding, HOLDING_STATUS } from '../../../models/Holding.model';
import { FILTER_TYPE, JOB_STATUS_FILTER, VIEW_MODE } from '../overviewRender.component';
import { JobStatusFilterButtons } from '../../job/components/JobStatusFilterButtons';
import { CASE_STATUS_FILTER, CaseStatusFilterButtons } from '../../job/CaseStatusFilterButtons';
import { HoldingStatusFilterButtons } from '../../job/HoldingStatusFilterButtons';
import { OverviewFilters } from '../filters/overviewFilters.component';

/*
//////////////////////////////////////////////////////////////////
//#region SEARCH
//////////////////////////////////////////////////////////////////
*/

interface OverviewSearchJobsHoldingsCasesProps {
    filterType: FILTER_TYPE,
    resetSearch: () => void,
    onSearchResultsReturned: (results: Job[] | Holding[] | Case[]) => void
}

const OverviewSearchJobsHoldingsCases = ({
    filterType,
    resetSearch,
    onSearchResultsReturned
}: OverviewSearchJobsHoldingsCasesProps) => {
    return (
        <SearchJobsHoldingsCases
            onSearchResultsReturned={onSearchResultsReturned}
            resetSearch={resetSearch}
            searchType={filterType === 'job' ? SEARCH_TYPE.JOB : filterType === 'holding' ? SEARCH_TYPE.HOLDING : SEARCH_TYPE.CASE}
        />
    )
}

/*
//////////////////////////////////////////////////////////////////
//#region CREATE BUTTONS
//////////////////////////////////////////////////////////////////
*/

interface RenderCreateJobButtonProps {
    openNewJobModal: () => void
}

interface CreateButtonProps {
    onPress: () => void,
    iconName: string,
    text: string
}

const CreateButton = ({ onPress, iconName, text }: CreateButtonProps) => {
    return (
        <Button
            onPress={onPress}
            status="success"
            size="small"
            accessoryLeft={(props) => <Icon {...props} name={iconName} />}
            style={{ margin: 5, borderRadius: 8 }}
        >
            {text}
        </Button>
    )
}

export const RenderCreateJobButton = ({ openNewJobModal }: RenderCreateJobButtonProps) => {
    return <CreateButton onPress={openNewJobModal} iconName="briefcase-outline" text="Create Job" />
}

interface RenderCreateCaseButtonProps {
    openCreateCaseContainer: () => void
}

export const RenderCreateCaseButton = ({ openCreateCaseContainer }: RenderCreateCaseButtonProps) => {
    return <CreateButton onPress={openCreateCaseContainer} iconName="file-text-outline" text="Create Case" />
}

interface RenderCreateHoldingButtonProps {
    openCreateHoldingContainer: () => void
}

export const RenderCreateHoldingButton = ({ openCreateHoldingContainer }: RenderCreateHoldingButtonProps) => {
    return <CreateButton onPress={openCreateHoldingContainer} iconName="archive-outline" text="Create Holding" />
}

interface RenderCreateRouteButtonProps {
    openCreateRouteContainer: () => void
}

export const RenderCreateRouteButton = ({ openCreateRouteContainer }: RenderCreateRouteButtonProps) => {
    return <CreateButton onPress={openCreateRouteContainer} iconName="map-outline" text="Create Route" />
}

/*
//////////////////////////////////////////////////////////////////
//#region ACTION BAR
//////////////////////////////////////////////////////////////////
*/

interface OverviewActionBarProps {
    filterType: FILTER_TYPE,
    viewMode: VIEW_MODE,
    enableRoutes: boolean,
    enableHoldings: boolean,
    enableCases: boolean,
    setViewMode: (viewMode: VIEW_MODE) => void,
    resetSearch: () => void,
    onSearchResultsReturned: (results: Job[] | Holding[] | Case[]) => void,
    openCreateRouteContainer: () => void,
    openCreateHoldingContainer: () => void,
    openCreateCaseContainer: () => void,
    openNewJobModal: () => void,
    onSetFilterApplied: (filter: JOB_STATUS_FILTER) => void,
    filterApplied: JOB_STATUS_FILTER,
    setShowFilters: (show: boolean) => void,
    fetchedJobsRef: React.MutableRefObject<Job[]>,
    holdingFilterApplied: HOLDING_STATUS,
    setHoldingFilterApplied: (filter: HOLDING_STATUS) => void,
    onClearSearchResults: () => void,
    holdingSearchResults: Holding[],
    caseFilterApplied: CASE_STATUS_FILTER,
    setCaseFilterApplied: (filter: CASE_STATUS_FILTER) => void
}

export const OverviewActionBar = ({
    filterType,
    viewMode,
    enableRoutes,
    enableHoldings,
    enableCases,
    setViewMode,
    resetSearch,
    onSearchResultsReturned,
    openCreateRouteContainer,
    openCreateHoldingContainer,
    openCreateCaseContainer,
    openNewJobModal,
    onSetFilterApplied,
    filterApplied,
    setShowFilters,
    fetchedJobsRef,
    holdingFilterApplied,
    setHoldingFilterApplied,
    onClearSearchResults,
    holdingSearchResults,
    caseFilterApplied,
    setCaseFilterApplied
}: OverviewActionBarProps) => {
    return (
        <View style={{ flexDirection: 'column', flex: 1 }}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', }}>
                    <RenderCreateJobButton openNewJobModal={openNewJobModal} />
                    {enableRoutes && <RenderCreateRouteButton openCreateRouteContainer={openCreateRouteContainer} />}
                    {enableHoldings && <RenderCreateHoldingButton openCreateHoldingContainer={openCreateHoldingContainer} />}
                    {enableCases && <RenderCreateCaseButton openCreateCaseContainer={openCreateCaseContainer} />}
                </View>
                <View style={{ flexDirection: 'row', }}>
                    <ViewModeButtons viewMode={viewMode} setViewMode={setViewMode} style={{ flex: 1 }} />
                </View>
                <View style={{ flexDirection: 'row', }}>
                    <OverviewSearchJobsHoldingsCases filterType={filterType} resetSearch={resetSearch} onSearchResultsReturned={onSearchResultsReturned} />
                </View>
            </View>
            <OverviewFilters
                filterType={filterType}
                onSetFilterApplied={onSetFilterApplied}
                filterApplied={filterApplied}
                setShowFilters={setShowFilters}
                fetchedJobsRef={fetchedJobsRef}
                holdingFilterApplied={holdingFilterApplied}
                setHoldingFilterApplied={setHoldingFilterApplied}
                onClearSearchResults={onClearSearchResults}
                holdingSearchResults={holdingSearchResults}
                caseFilterApplied={caseFilterApplied}
                setCaseFilterApplied={setCaseFilterApplied}
            />
        </View>
    )
}