import React, { useState, useEffect, useRef, useReducer, useCallback, useMemo, createContext, useContext } from 'react';
//@ts-ignore
import { useWindowDimensions, StyleSheet, View, TouchableOpacity } from "react-native";
import { SafeAreaView } from 'react-native-safe-area-context';
//@ts-ignore
import { Divider, Icon, Layout, Text, TopNavigation, TopNavigationAction, Card, Button, Input, ButtonGroup, CheckBox, Modal, RangeDatepicker, Datepicker, useTheme, IndexPath, Select, SelectItem, Spinner, Toggle } from '@ui-kitten/components';
import 'react-data-grid/lib/styles.css';
import moment from 'moment';
//@ts-ignore
import DataGrid, { Row, RowRendererProps, SelectColumn, textEditor, useFocusRef, Too, Column, DataGridProps } from 'react-data-grid';
import * as StorageController from '../../functions/storageController'
import * as JobController from '../../functions/job.controller'
import * as InvoiceController from '../../functions/invoice.controller'
import * as ClientController from '../../functions/client.controller'
import * as UpdateController from '../../functions/update.controller'
import * as ServiceController from '../../functions/service.controller'
import * as GoogleController from '../../functions/google.controller'
import * as VendorProfileController from '../../functions/vendorProfile.controller'
import ErrorBoundary from '../ErrorBoundary.component';
import { ScrollView } from 'react-native-gesture-handler';
//@ts-ignore
import { CSVLink } from "react-csv";
import { ReportDetailsCard } from '../job/details/reportDetailsCard.component';
import { LogDetailsCard } from '../job/details/logDetailsCard.component';
import { Job, JobAddress, JobCustomerDetails, JobDetails, JobLocation, JobReport, JobTowingDetails, JobVehicleDetails, LineItem, Service } from '../../models/Job.model'
import { Case } from '../../models/Case.model';
import { Holding } from '../../models/Holding.model';
import { Invoice, InvoiceItem } from '../../models/Invoice.model';
import * as Utils from './invoiceUtils';
import * as Formatters from './invoiceFormatters';
import Tooltip from '../modals/Tooltip';
import { Member } from '../../models/Member.model';
import { Client } from '../../models/Client.model';
import { Company } from '../../models/Company.model';
import { FullJobReport } from '../dashboardComponents/jobReportModal.component';
import { JobInvoiceRow, HoldingInvoiceRow } from './invoicingTypes';
import { ExportInvoiceModal } from './ExportModal';
import { Filters } from './Filters';
import { JobsColumnsRenderer, createTowingColumns, createVehicleColumns, itemSubrowColumns, HoldingColumnsRenderer } from './InvoiceColumnsConfigJobs';
import { HoldingDetailsContainer } from '../job/holding/holdingDetailsContainer.component';
import { useAppStateChange, IAction } from '../../hooks/appStateChange.hook';
import {
    addAllFieldsToJobRows,
    addAllFieldsToHoldingRows,
    transformJobDataToCSV,
    transformHoldingDataToCSV,
    transformJobDataToQuickBooksCSV,
    transformHoldingDataToQuickBooksCSV,
    DescriptionOptions
} from './rowCreationUtils';

import { DateRangePicker } from './DateRangePicker';
import { ExportButton } from './ExportButton';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { InvoiceDataGrid } from './InvoiceDataGrid';
import { JobDetailsPanel } from './JobDetailsPanel';
import { VendorProfile } from '../../models/VendorProfile.model';


// Context is needed to read filter values otherwise columns are
// re-created when filters are changed and filter loses focus
const FilterContext = createContext(undefined);


export const InvoiceScreen = ({ navigation }: any) => {
    const [fetchedJobs, setFetchedJobs] = useState([] as Job[]);
    const [fetchedHoldings, setFetchedHoldings] = useState([] as Holding[]);
    const [x, forceUpdate] = useReducer(x => x + 1, 0);
    const pivot = useRef(null);

    const [windowHeight, setWindowHeight] = React.useState(useWindowDimensions().height * 0.9)
    const [windowWidth, setWindowWidth] = React.useState(useWindowDimensions().width * 0.9);

    const [holdingsOrJobs, setHoldingsOrJobs] = useState("jobs");

    const [selectedRows, setSelectedRows] = useState(() => new Set());
    const [dataRows, setRows] = useState([] as JobInvoiceRow[] | HoldingInvoiceRow[]);
    const memoizedDataRows = useMemo(() => {
        return (dataRows)
    }, [dataRows]);
    const [holdingsRows, setHoldingsRows] = useState([] as HoldingInvoiceRow[]);
    const memoizedHoldingsRows = useMemo(() => {
        return (holdingsRows)
    }, [holdingsRows]);
    // const [fetchedJobs, setFetchedJobs] = useState([]);

    //@ts-ignore
    const theme = useTheme();

    const [sortColumn, setSortColumn] = useState('id');
    const [sortDirection, setSortDirection] = useState('ASC');

    const clientsRef = useRef([] as Client[]);
    // memebers list
    const driversRef = useRef([] as Member[]);

    // services
    const servicesRef = useRef([] as Service[]);

    // vendor profiles
    const vendorProfilesRef = useRef([] as VendorProfile[]);


    const [csvExportModalVisible, setExportModalVisible] = useState(false);
    const invoiceDueDateRef = useRef(new Date());
    const invoiceTaxTypeRef = useRef("GST on Income");
    const [dueDate, setDueDate] = useState(new Date());
    const [taxType, setTaxType] = useState("GST on Income");
    const memoizedInvoiceDueDate = useMemo(() => dueDate, [dueDate]);
    const memoizedInvoiceTaxType = useMemo(() => taxType, [taxType]);
    const [exportServices, setExportServices] = useState(false);
    const [exportLineItems, setExportLineItems] = useState(true);
    const [exportBillBackItems, setExportBillBackItems] = useState(true);
    const [exportCustomerCostItems, setExportCustomerCostItems] = useState(true);
    const exportServicesRef = useRef(false);
    const exportLineItemsRef = useRef(true);
    const exportBillBackItemsRef = useRef(true);
    const exportCustomerCostItemsRef = useRef(true);



    const [showJobDetailsPanel, setShowJobDetailsPanel] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null as Job | null);
    const [jobLogs, setJobLogs] = useState([]);
    const [columns, setColumns] = useState([] as Column<JobInvoiceRow>[]); // What will actually be rendered in the DataGrid
    const memoizedColumns = useMemo(() => columns, [columns]);

    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)


    const [showReleaseHoldingOptions, setShowReleaseHoldingOptions] = useState(false);
    const [holdingDetailsOpen, setHoldingDetailsOpen] = useState(false);
    const [selectedHolding, setSelectedHolding] = useState(null as Holding | null);


    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [dateRange, setDateRange] = React.useState({ startDate: new Date(), endDate: new Date() });
    const startDateRef = useRef(startDate);
    const endDateRef = useRef(endDate);
    const dateRangeRef = useRef(dateRange);


    const [descriptionOptions, setDescriptionOptions] = useState<DescriptionOptions>({
        includeServiceNames: true,
        includeVehicleDetails: true,
        includeCustomerDetails: true,
        includeDriverName: true,
        includeComments: true,
        includeClientRate: true,
        includeAddressDetails: true,
        includeTowingDetails: true,
        includeEquipmentDetails: true,
    });


    const onSetStartDate = (date: Date) => {
        setStartDate(date);
        startDateRef.current = date;
    }

    const onSetEndDate = (date: Date) => {
        setEndDate(date);
        endDateRef.current = date;
    }

    const onSetDateRange = (range: any) => {
        setDateRange(range);
        dateRangeRef.current = range;
    }



    const onCloseHoldingDetails = () => {
        setHoldingDetailsOpen(false);
        setSelectedHolding(null);
    }

    const getDueDate = () => {
        return memoizedInvoiceDueDate;
    }

    const getTaxType = () => {
        return memoizedInvoiceTaxType;
    }


    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            setSelectedCompany(new Company(StorageController.getCurrentCompany()))
            load();
        });
        // Return the function to unsubscribe from the event so it gets removed on unmount
        return unsubscribe;
    }, [navigation]);


    const handleCompanyChangeEvent = useCallback((action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.UPDATED_SELECTED_COMPANY) {
            setSelectedCompany(new Company(action.data))
            setFetchedJobs([]);
            setSelectedRows(new Set());
            setSelectedJob(null);
            // setJobLogs([]);
            setShowJobDetailsPanel(false);
            setColumns([]);
            setRows([]);
            clientsRef.current = [];
            driversRef.current = [];
            servicesRef.current = [];

            setTimeout(async () => {
                load()
            }, 100);
        }
    }, [selectedCompany]);

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleCompanyChangeEvent
    })

    useEffect(() => {
        searchJobsByDateRange(dateRange.startDate, dateRange.endDate);
        // console.log("🚀============== ~ file: invoices.component.tsx:192 ~ useEffect ~ startDateRef.current🚀==============", dateRange)
    }, [holdingsOrJobs]);



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// SEARCH BY DATE RANGE
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const searchJobsByDateRange = useCallback(async (start = startDate, end = endDate) => {
        setShowLoading(true);
        start.setHours(0, 0, 0, 0);
        if (!end) { // for a single date
            end = new Date(start);
        }
        end.setHours(23, 59, 59, 999);
        let dat = [] as Holding[] | Job[] | any
        if (holdingsOrJobs === "holdings") {
            dat = await InvoiceController.getHoldingsByDateRange(start, end) as Holding[];
        } else {
            dat = await InvoiceController.getJobsByDateRange(start, end) as Job[];
        }
        if (!dat) {
            dat = []
        }
        // console.log("🚀============== ~ file: invoices.component.tsx:191 ~ searchJobsByDateRange ~ dat🚀==============", dat, start, end)
        clearFilters();
        // console.log(_jobs)
        let updatedJobs = []
        if (holdingsOrJobs === "jobs") {
            // updatedJobs = addAllFieldsToJobRows(dat as Job[]);
            const { rows: updatedJobFields, columns: updatedJobColumns } = addAllFieldsToJobRows(
                {
                    rows: dat as Job[],
                    servicesCheckedRef: servicesCheckedRef,
                    onItemsExpanded: onItemsExpanded,
                    onOpenJobDetailsPanel: onOpenJobDetailsPanel,
                    getClientName: getClientName,
                    getClientAccountCode: getClientAccountCode,
                    getMemberName: getMemberName,
                }
            );
            setFetchedJobs(dat as Job[]);
            setColumns(updatedJobColumns);
            updatedJobs = updatedJobFields
        } else {
            dat = dat?.filter((row: Holding) => row.status == "released")
            // updatedJobs = addAllFieldsToHoldingRows(dat as Holding[]);
            const { rows: updatedHoldings, columns: updatedHoldingColumns } = addAllFieldsToHoldingRows(
                {
                    rows: dat as Holding[],
                    servicesCheckedRef: servicesCheckedRef,
                    onItemsExpanded: onItemsExpanded,
                    onOpenHoldingDetailsPanel: onOpenHoldingDetailsPanel,
                    getClientName: getClientName,
                    getClientAccountCode: getClientAccountCode,
                    getMemberName: getMemberName,
                }
            );
            setFetchedHoldings(dat as Holding[]);
            setColumns(updatedHoldingColumns);
            updatedJobs = updatedHoldings
        }
        setRows(updatedJobs);
        console.log("🚀============== ~ file: invoices.component.tsx:235 ~ searchJobsByDateRange ~ updatedJobs🚀==============", updatedJobs)
        itemsExpanded ? toggleItems(true) : null;
        forceUpdate();
        setShowLoading(false);
        return dat;
    }, [startDate, endDate, holdingsOrJobs, dateRange]);




    const load = async () => {
        await loadClients();
        initDateRange();
        handlePreviousWeek();
        loadDrivers();
        loadServices();
        loadVendorProfiles();
        setShowJobDetailsPanel(false);
        setSelectedJob(null);
        // setJobLogs([]);
        await toggleItems(true)

    }

    const initDateRange = async () => {
        const week = new Date();
        week.setDate(week.getDate() - 7);
        const _startDate = new Date(week);
        const _endDate = new Date(week);
        _startDate.setDate(_startDate.getDate() - week.getDay() + 1);
        _endDate.setDate(_endDate.getDate() - week.getDay() + 7);
        onSetStartDate(_startDate);
        onSetEndDate(_endDate);
    }

    const loadVendorProfiles = async () => {
        const result = await VendorProfileController.getVendorProfilesByCompanyId(StorageController.getCurrentCompany()._id)
        vendorProfilesRef.current = result;
    }

    // get services
    const loadServices = async () => {
        const s = StorageController.getAppState().services || StorageController.getAppState().selectedCompany?.services || [];
        servicesRef.current = s;
    }

    // get members
    const loadDrivers = async () => {
        const result = StorageController.getCurrentCompany().members.filter((member: Member) => member.is_driver === true);
        driversRef.current = result;
    }

    // get clients
    const loadClients = async () => {
        const result = await ClientController.getClientsByCompanyId(StorageController.getCurrentCompany()._id)
        if (StorageController.getAppState().selectedMembership?.is_client) {
            const client = result.find((c: Client) => c._id === StorageController.getAppState().selectedMembership?.client_id);
            clientsRef.current = [client];
        } else {
            clientsRef.current = result;
        }
    }

    function handleFill({ columnKey, sourceRow, targetRow }: {
        columnKey: string;
        sourceRow: any;
        targetRow: any;
    }) {
        return { ...targetRow, [columnKey]: sourceRow[columnKey] };
    }

    function handlePaste({
        sourceColumnKey,
        sourceRow,
        targetColumnKey,
        targetRow
    }: {
        sourceColumnKey: string;
        sourceRow: any;
        targetColumnKey: string;
        targetRow: any;
    }) {
        const incompatibleColumns = ['email', 'zipCode', 'date'];
        if (
            sourceColumnKey === 'avatar' ||
            ['id', 'avatar'].includes(targetColumnKey) ||
            ((incompatibleColumns.includes(targetColumnKey) ||
                incompatibleColumns.includes(sourceColumnKey)) &&
                sourceColumnKey !== targetColumnKey)
        ) {
            return targetRow;
        }

        return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }

    function handleCopy({ sourceRow, sourceColumnKey }: {
        sourceRow: any;
        sourceColumnKey: string;
    }) {
        if (window.isSecureContext) {
            navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
        }
    }
    function rowKeyGetter(row: JobInvoiceRow) {
        try {
            return row.id;
        }
        catch (err) {
            console.log(err);
        }
    }

    const getClientName = (clientId: string) => {
        const client = clientsRef.current.find(client => client._id === clientId);
        if (client) {
            return client.name;
        }
        return clientId;
    }

    // get client account_code
    const getClientAccountCode = (clientId: string) => {
        const client = clientsRef.current.find(client => client._id === clientId);
        if (client) {
            return client.account_code;
        }
        return clientId;
    }

    const getMemberName = (memberId: string) => {

        const member = driversRef.current.find(member => member._id === memberId);
        if (member) {
            return member.name;
        }
        return memberId;
    }


    const onOpenJobDetailsPanel = async (job: JobInvoiceRow) => {
        const selectedJob = new Job(job);
        setSelectedJob(selectedJob);
        setShowJobDetailsPanel(true);
        // const logs = await JobController.getJobLogsByJobId(job._id)
        // setJobLogs(logs);
    }

    const onOpenHoldingDetailsPanel = async (holding: HoldingInvoiceRow) => {
        const selectedHolding = new Holding(holding);
        setSelectedHolding(selectedHolding);
        setHoldingDetailsOpen(true);
    }

    const handleGridSort = (sortColumn: any, sortDirection: any) => {
        setSortColumn(sortColumn);
        setSortDirection(sortDirection);
    };

    const memoJobsColumnsRenderer = useMemo(() => {
        return (
            JobsColumnsRenderer({
                onItemsExpanded: onItemsExpanded,
                itemsExpanded: itemsExpanded,
                handleGridSort: handleGridSort,
                onOpenJobDetailsPanel: onOpenJobDetailsPanel,
                getMemberName: getMemberName,
                getClientName: getClientName,
                getClientAccountCode: getClientAccountCode,
            })
        )

    }, [fetchedJobs]);

    const memoHoldingsColumnsRenderer = useMemo(() => {
        return (
            HoldingColumnsRenderer({
                onItemsExpanded: onItemsExpanded,
                itemsExpanded: itemsExpanded,
                handleGridSort: handleGridSort,
                onOpenHoldingDetailsPanel: onOpenHoldingDetailsPanel,
                getMemberName: getMemberName,
                getClientName: getClientName,
                getClientAccountCode: getClientAccountCode,
            })
        )

    }, [fetchedHoldings]);










    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // ITEM ROWS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const [itemsExpanded, setItemsExpanded] = useState(true);

    const onItemsExpanded = () => {
        setItemsExpanded(!itemsExpanded);
        toggleItems(!itemsExpanded)
    };

    const toggleItems = useCallback(async (expanded: boolean) => {
        setColumns(prevColumns => {
            const updatedColumns = [...prevColumns];
            const itemsColumnIndex = updatedColumns.findIndex(c => c.key === 'items') + 1;

            if (expanded) {
                updatedColumns.splice(itemsColumnIndex, 0, ...itemSubrowColumns as any);
            } else {
                updatedColumns.splice(itemsColumnIndex, itemSubrowColumns.length);
            }

            return updatedColumns;
        });
        if (holdingsOrJobs === "jobs") {
            setRows(prevRows => {
                let newRows: JobInvoiceRow[] = [...prevRows as JobInvoiceRow[]];

                if (expanded) {
                    newRows = newRows.reduce((expandedRows: JobInvoiceRow[], row) => {
                        const jobLineItems = row.details?.report.items;
                        if (!jobLineItems || jobLineItems?.length === 0) {
                            expandedRows.push(row);
                            return expandedRows;
                        }

                        //@ts-ignore
                        const subRows: JobInvoiceRow[] = jobLineItems.map((child, index) => ({
                            ...child,
                            _id: row._id,
                            friendly_id: row.friendly_id,
                            createdAt: row.createdAt,
                            invoice_date: row.createdAt,
                            job_count: row.job_count,
                            pending_time: row.pending_time,
                            isSubRow: true,
                            isItemRow: true,
                            parentId: row.id,
                            id: `${row.id}-${index + 1}`,
                            total: (child.cost * child.quantity).toFixed(2),
                        }));

                        const total = subRows.reduce((acc, cur) => acc + parseFloat(cur.total), 0).toFixed(2);
                        subRows.push({
                            _id: row._id,
                            friendly_id: row.friendly_id,
                            createdAt: row.createdAt,
                            job_count: row.job_count,
                            invoice_date: row.createdAt,
                            pending_time: row.pending_time,
                            isTotalRow: true,
                            isSubRow: true,
                            total: `$${total}`,
                            id: `${row.id}-total`,
                        } as unknown as JobInvoiceRow);

                        expandedRows.push(row, ...subRows);
                        return expandedRows;
                    }, []);
                } else {
                    newRows = newRows.filter(row => !row.isSubRow);
                }

                return newRows;
            });
        } else {
            setRows(prevRows => {
                let newRows: HoldingInvoiceRow[] = [...prevRows as HoldingInvoiceRow[]];

                if (expanded) {
                    newRows = newRows.reduce((expandedRows: HoldingInvoiceRow[], row) => {
                        const holdingLineItems = row.line_items;
                        if (!holdingLineItems || holdingLineItems?.length === 0) {
                            expandedRows.push(row);
                            return expandedRows;
                        }

                        //@ts-ignore
                        const subRows: HoldingInvoiceRow[] = holdingLineItems.map((child, index) => ({
                            ...child,
                            _id: row._id,
                            createdAt: row.createdAt,
                            invoice_date: row.createdAt,
                            isSubRow: true,
                            isItemRow: true,
                            parentId: row.id,
                            id: `${row.id}-${index + 1}`,
                            total: (child.cost * child.quantity).toFixed(2),
                        }));

                        const total = subRows.reduce((acc, cur) => acc + parseFloat(cur.total), 0).toFixed(2);
                        subRows.push({
                            _id: row._id,
                            createdAt: row.createdAt,
                            invoice_date: row.createdAt,
                            isTotalRow: true,
                            isSubRow: true,
                            total: `$${total}`,
                            id: `${row.id}-total`,
                        } as unknown as HoldingInvoiceRow);

                        expandedRows.push(row, ...subRows);
                        return expandedRows;
                    }, []);
                } else {
                    newRows = newRows.filter(row => !row.isSubRow);
                }

                return newRows;
            });
        }
    }, [holdingsOrJobs]);




    const sortedData = useMemo(() => {
        const result = memoizedDataRows?.sort((a: any, b: any) => {
            const sortValueA: any = a[sortColumn];
            const sortValueB: any = b[sortColumn];
            let result = 0;

            if (typeof sortValueA === 'number' && typeof sortValueB === 'number') {

                return sortDirection === 'ASC' ? sortValueA - sortValueB : sortValueB - sortValueA;
            } else if (typeof sortValueA === 'string' && typeof sortValueB === 'string') {

                return sortDirection === 'ASC' ? sortValueA.localeCompare(sortValueB) : sortValueB.localeCompare(sortValueA);
            } else if (sortValueA instanceof Date && sortValueB instanceof Date) {

                //@ts-ignore
                return sortDirection === 'ASC' ? sortValueA - sortValueB : sortValueB - sortValueA;
            } else {
                return 0;
            }
        });
        return result;
    }, [memoizedDataRows, sortColumn, sortDirection]);




    const topSummaryRowJobs = useMemo(() => {
        return [{
            id: 'summary',
            totalCount: sortedData?.filter(row => !row.isSubRow)?.length,
            clientRateTotal: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getTotalClientRate(cur).toString()), 0).toFixed(2),
            itemBillAllBackTotal: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getTotalItemBillAllBack(cur).toString()), 0).toFixed(2),
            itemCustomerCostTotal: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getTotalItemCustomerCost(cur).toString()), 0).toFixed(2),
            itemTotal: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getTotalCostItems(cur).toString()), 0).toFixed(2),
            cardAmount: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getCustomerPaidCardAmount(cur as JobInvoiceRow).toString()), 0).toFixed(2),
            cashAmount: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getCustomerPaidCashAmount(cur as JobInvoiceRow).toString()), 0).toFixed(2),
            totalPaid: sortedData?.reduce((acc, cur) => acc + parseFloat(Utils.getCustomerPaidCardAmount(cur as JobInvoiceRow).toString()) + parseFloat(Utils.getCustomerPaidCashAmount(cur as JobInvoiceRow).toString()), 0).toFixed(2),
            total: 0,
        }];
    }, [sortedData]);


    const topSummaryRowHoldings = useMemo(() => {
        return [{
            id: 'summary',
            totalCount: sortedData?.length,
            total: 0,
        }];
    }, [sortedData]);





    const handleCellContextMenu = (args: any) => {
        // console.log("handleCellContextMenu", args)
        // if (args.row && !args.row.isSubRow) {
        //     setSelectedJob(args.row)
        //     setShowJobDetailsPanel(true);
        // }
    }




    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    //#region FILTERS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const clientsCheckedRef = useRef([] as Client[]);
    const driversCheckedRef = useRef([] as Member[]);
    const servicesCheckedRef = useRef([] as Service[]);
    const statusesCheckedRef = useRef([] as any[]);
    const vendorProfilesCheckedRef = useRef([] as VendorProfile[]);
    const [statuses, setStatuses] = useState([]); // statuses checked in filter
    const [statusesChecked, setStatusesChecked] = useState([] as any[]); // statuses checked in filter
    const [clientsChecked, setClientsChecked] = useState([] as any[]); // clients checked in filter
    const [driversChecked, setDriversChecked] = useState([] as any[]); // drivers checked in filter
    const [servicesChecked, setServicesChecked] = useState([] as any[]); // services checked in filter
    const [vendorProfilesChecked, setVendorProfilesChecked] = useState([] as any[]); // vendor profiles checked in filter

    // clear filters
    const clearFilters = () => {
        clientsCheckedRef.current = [];
        driversCheckedRef.current = [];
        servicesCheckedRef.current = [];
        statusesCheckedRef.current = [];
        vendorProfilesCheckedRef.current = [];
        setStatusesChecked([]);
        setClientsChecked([]);
        setDriversChecked([]);
        setServicesChecked([]);
        setStatuses([]);
        setVendorProfilesChecked([]);
        forceUpdate()
    }

    const RenderFilterCards = () => {
        return (

            <Filters
                filterType={holdingsOrJobs as any}
                clients={clientsRef}
                drivers={driversRef}
                services={servicesRef}
                statuses={statuses}
                statusesChecked={statusesChecked}
                clientsChecked={clientsChecked}
                driversChecked={driversChecked}
                servicesChecked={servicesChecked}
                vendorProfilesChecked={vendorProfilesChecked}
                setClientsChecked={setClientsChecked}
                setDriversChecked={setDriversChecked}
                setServicesChecked={setServicesChecked}
                setStatusesChecked={setStatusesChecked}
                setVendorProfilesChecked={setVendorProfilesChecked}
                clientsCheckedRef={clientsCheckedRef}
                driversCheckedRef={driversCheckedRef}
                servicesCheckedRef={servicesCheckedRef}
                statusesCheckedRef={statusesCheckedRef}
                vendorProfilesCheckedRef={vendorProfilesCheckedRef}
                jobs={fetchedJobs}
                filteredJobs={sortedData as Job[]}
                holdings={fetchedHoldings}
                filteredHoldings={sortedData as Holding[]}
                forceUpdate={forceUpdate}
                filterJobsByCriteria={filterJobsByCriteria}
                filterHoldingsByCriteria={filterHoldingsByCriteria}
                vendorProfiles={vendorProfilesRef}
            />
        )
    }


    //////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    ///// FILTER JOBS BY CRITERIA
    ////////////////////////////////////////////////////
    /////////////////////////////////////////////////// 
    const filterJobsByCriteria = () => {
        try {

            //convert to the JobInvoiceRow type
            const nextJobs = fetchedJobs
            const nextJobsFiltered = nextJobs.filter((job) => {
                if (
                    clientsCheckedRef.current?.length > 0 &&
                    !clientsCheckedRef.current.find((c) => c._id === job.client_id)
                ) {
                    return false;
                }
                if (
                    vendorProfilesCheckedRef.current?.length > 0 &&
                    !vendorProfilesCheckedRef.current.find((vp) => {
                        if (job.vendor_company_id && !!vp.linkedVendorAccountId) {
                            return vp.linkedVendorAccountId === job.vendor_company_id
                        }
                        if (job.vendor_profile_id) {
                            return vp._id === job.vendor_profile_id
                        }
                        return false
                    })
                ) {
                    return false;
                }
                if (
                    driversCheckedRef.current?.length > 0 &&
                    !driversCheckedRef.current.find((d) => d._id === job.member_id)
                ) {
                    return false;
                }
                if (servicesCheckedRef.current?.length > 0) {

                    const hasSelectedService = job.details.selected_services.some(
                        (service) =>
                            servicesCheckedRef.current.some(
                                (selectedService) => selectedService._id === service._id
                            )
                    );

                    if (!hasSelectedService) {
                        return false;
                    }
                }
                if (
                    statusesCheckedRef.current.length > 0 &&
                    !statusesCheckedRef.current.includes(job.status)
                ) {
                    return false;
                }
                return true;
            });
            // const updatedJobs = addAllFieldsToJobRows(nextJobsFiltered);
            const { rows: updatedJobs, columns: updatedJobsColumns } = addAllFieldsToJobRows({
                rows: nextJobsFiltered,
                servicesCheckedRef: servicesCheckedRef,
                getClientName: getClientName,
                getMemberName: getMemberName,
                onItemsExpanded: onItemsExpanded as any,
                getClientAccountCode: getClientAccountCode,
                onOpenJobDetailsPanel: onOpenJobDetailsPanel,
            });
            setRows(updatedJobs);
            setColumns(updatedJobsColumns);
            itemsExpanded ? toggleItems(true) : null;
            // setRows(nextJobsFiltered);
            forceUpdate();
        } catch (e) {
            console.log('Error filterJobsByCriteria: ', e);
        }
    };

    const filterHoldingsByCriteria = () => {
        try {
            //convert to the HoldingInvoiceRow type
            const nextHoldings = fetchedHoldings
            const nextHoldingsFiltered = nextHoldings.filter((holding) => {
                if (
                    clientsCheckedRef.current?.length > 0 &&
                    !clientsCheckedRef.current.find((c) => c._id === holding.client_id)
                ) {
                    return false;
                }
                if (servicesCheckedRef.current?.length > 0) {
                    const hasSelectedService = holding.details.selected_services.some(
                        (service) =>
                            servicesCheckedRef.current.some(
                                (selectedService) => selectedService._id === service._id
                            )
                    );
                    if (!hasSelectedService) {
                        return false;
                    }
                }
                return true;
            });
            // const updatedHoldings = addAllFieldsToHoldingRows(nextHoldingsFiltered as any);
            const { rows: updatedHoldings, columns: updatedHoldingsColumns } = addAllFieldsToHoldingRows(
                {
                    rows: nextHoldingsFiltered,
                    servicesCheckedRef: servicesCheckedRef,
                    getClientName: getClientName,
                    getMemberName: getMemberName,
                    onItemsExpanded: onItemsExpanded as any,
                    getClientAccountCode: getClientAccountCode,
                    onOpenHoldingDetailsPanel: onOpenHoldingDetailsPanel,
                }
            );
            setRows(updatedHoldings);
            setColumns(updatedHoldingsColumns);
            itemsExpanded ? toggleItems(true) : null;
            // setRows(nextJobsFiltered);
            forceUpdate();
        } catch (e) {
            console.log('Error filterJobsByCriteria: ', e);
        }
    }








    //////////////////////////////////////////////////////
    /////////////////////////////////////////////////////
    ///// FILTER JOBS BY DATE
    ////////////////////////////////////////////////////
    /////////////////////////////////////////////////// 



    interface DateRange {
        startDate: Date;
        endDate: Date;
    }

    const onDateRangeChange = (nextRange: DateRange) => {
        if (validateDateRange(nextRange)) {
            onSetDateRange(nextRange);
            searchJobsByDateRange(nextRange.startDate, nextRange.endDate);
        }
    };

    const validateStartDate = (date: Date) => {
        date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 5, 5, 5);
        // convert isodate to timestamp
        var timestamp = date.getTime()
        // console.log(timestamp)
        onSetStartDate(date)
    }
    const validateEndDate = (date: Date) => {
        // console.log(date)
        onSetEndDate(date)
    }
    const validateRange = (range: DateRange) => {
        // console.log(range)
        validateStartDate(range.startDate)
        validateEndDate(range.endDate)
        onSetDateRange(range)
    }

    const [showLoading, setShowLoading] = useState(false);
    // render full page loading spinner
    const RenderLoading = () => (
        <Layout style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
            backgroundColor: "rgba(0,0,0,0.5)"
        }}>
            <Spinner size="giant" />
        </Layout>
    )









    // validate start date is before end date
    const validateDateRange = (nextRange: any) => {
        const { startDate, endDate } = nextRange; // are Date objects
        return startDate <= endDate;
    };

    const CalendarIcon = (props: any) => (
        <Icon {...props} name="calendar" />
    );
    const [currentWeek, setCurrentWeek] = useState(new Date()); // Initial week
    // render this week button
    const RenderNextWeekButton = () => (
        <Button
            style={{}}
            appearance="outline"
            status="basic"
            onPress={() => {
                // start date is last sunday aned date is this saturday
                handleNextWeek();
            }}
        >
            <Icon name="arrow-ios-forward-outline" width={30} height={30} />
        </Button>
    );

    const RenderLastWeekButton = () => (
        <Button
            style={{}}
            appearance="outline"
            status="basic"
            onPress={() => {
                // start date is last sunday aned date is this saturday
                handlePreviousWeek();
            }}
        >
            <Icon name="arrow-ios-back-outline" width={30} height={30} />
        </Button>
    );


    const handlePreviousWeek = () => {
        const previousWeek = new Date(currentWeek);
        previousWeek.setDate(previousWeek.getDate() - 7);
        setCurrentWeek(previousWeek);
        updateDateRange(previousWeek);
    };

    const handleNextWeek = () => {
        const nextWeek = new Date(currentWeek);
        nextWeek.setDate(nextWeek.getDate() + 7);
        setCurrentWeek(nextWeek);
        updateDateRange(nextWeek);
    };

    const updateDateRange = (week: Date) => {
        const startDate = new Date(week);
        const endDate = new Date(week);
        startDate.setDate(startDate.getDate() - week.getDay() + 1);
        endDate.setDate(endDate.getDate() - week.getDay() + 7);
        const nextRange = { startDate, endDate };
        onDateRangeChange(nextRange);
    };


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    //#region TRANSFORM DATA
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const transformHoldingDataToCSVMemoized = useMemo(() => {
        return transformHoldingDataToCSV(
            sortedData as HoldingInvoiceRow[],
            getClientName,
            getClientAccountCode,
            getDueDate,
            getTaxType,
            exportLineItemsRef,
            exportBillBackItemsRef,
            exportCustomerCostItemsRef,
            exportServicesRef);

    }, [sortedData, dueDate, taxType, exportServices, exportLineItems, exportBillBackItems, exportCustomerCostItems, descriptionOptions]);


    const transformJobDataToCSVMemoized = useMemo(() => {
        return transformJobDataToCSV(
            sortedData as JobInvoiceRow[],
            getClientName,
            getClientAccountCode,
            getMemberName,
            getDueDate,
            getTaxType,
            exportLineItemsRef,
            exportBillBackItemsRef,
            exportCustomerCostItemsRef,
            exportServicesRef,
            descriptionOptions
        )
    }, [sortedData, dueDate, taxType, exportServices, exportLineItems, exportBillBackItems, exportCustomerCostItems, descriptionOptions]);

    const transformJobDataToQuickBooksCSVMemoized = useMemo(() => {
        return transformJobDataToQuickBooksCSV(
            sortedData as JobInvoiceRow[],
            getClientName,
            getClientAccountCode,
            getMemberName,
            getDueDate,
            getTaxType,
            exportLineItemsRef,
            exportBillBackItemsRef,
            exportCustomerCostItemsRef,
            exportServicesRef,
            descriptionOptions
        )
    }, [sortedData, dueDate, taxType, exportServices, exportLineItems, exportBillBackItems, exportCustomerCostItems, descriptionOptions]);

    const transformHoldingDataToQuickBooksCSVMemoized = useMemo(() => {
        return transformHoldingDataToQuickBooksCSV(
            sortedData as HoldingInvoiceRow[],
            getClientName,
            getClientAccountCode,
            getDueDate,
            getTaxType,
            exportLineItemsRef,
            exportBillBackItemsRef,
            exportCustomerCostItemsRef,
            exportServicesRef
        )
    }, [sortedData, dueDate, taxType, exportServices, exportLineItems, exportBillBackItems, exportCustomerCostItems, descriptionOptions]);







    // checkbox to confirm set invoices as submitted
    const [confirmSetInvoiceSubmit, setConfirmSetInvoiceSubmit] = useState(false);
    const confirmSetInvoiceSubmitRef = useRef(false);
    const handleConfirmSubmitCheckbox = (value: any) => {
        setConfirmSetInvoiceSubmit(value);
        confirmSetInvoiceSubmitRef.current = value;
    }




    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // RENDER
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const RenderMemoizedHoldingDetails = useMemo(() => {
        if (!selectedHolding) return null
        return (
            <HoldingDetailsContainer
                fn_onClose={() => { onCloseHoldingDetails(); }}
                fn_onReleaseAndCreateJobHolding={() => { setShowReleaseHoldingOptions(true); setHoldingDetailsOpen(false) }}
                holdingItem={selectedHolding as Holding}
                fn_onReleaseOnlyHolding={() => { onCloseHoldingDetails(); }}
                disabled={false}
            />
        )
    }, [selectedHolding, holdingDetailsOpen, selectedCompany])


    return (
        <ErrorBoundary>
            {showLoading && <LoadingSpinner />}
            <ExportInvoiceModal
                csvExportModalVisible={csvExportModalVisible}
                setExportModalVisible={setExportModalVisible}
                handleConfirmSubmitCheckbox={handleConfirmSubmitCheckbox}
                setDueDate={setDueDate}
                setTaxType={setTaxType}
                setExportServices={setExportServices}
                setExportLineItems={setExportLineItems}
                setExportBillBackItems={setExportBillBackItems}
                setExportCustomerCostItems={setExportCustomerCostItems}
                generateCsvData={Utils.generateCsvData}
                transformData={holdingsOrJobs == "jobs" ? transformJobDataToCSVMemoized : transformHoldingDataToCSVMemoized}
                transformDataQuickBooks={holdingsOrJobs == "jobs" ? transformJobDataToQuickBooksCSVMemoized : transformHoldingDataToQuickBooksCSVMemoized}
                jobs={fetchedJobs}
                sortedData={sortedData}
                memoizedInvoiceDueDate={memoizedInvoiceDueDate}
                memoizedInvoiceTaxType={memoizedInvoiceTaxType}
                exportLineItems={exportLineItems}
                exportBillBackItems={exportBillBackItems}
                exportCustomerCostItems={exportCustomerCostItems}
                exportServices={exportServices}
                confirmSetInvoiceSubmit={confirmSetInvoiceSubmit}
                invoiceTaxTypeRef={invoiceTaxTypeRef}
                invoiceDueDateRef={invoiceDueDateRef}
                exportServicesRef={exportServicesRef}
                exportLineItemsRef={exportLineItemsRef}
                exportBillBackItemsRef={exportBillBackItemsRef}
                exportCustomerCostItemsRef={exportCustomerCostItemsRef}
                confirmSetInvoiceSubmitRef={confirmSetInvoiceSubmitRef}
                descriptionOptions={descriptionOptions}
                setDescriptionOptions={setDescriptionOptions}
            />

            <View style={{ flex: 1, flexDirection: 'row' }}>
                <View style={{ flex: 2, flexDirection: 'row' }}>
                    {/*
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    //////// FILTERS AND SEARCH
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    */}
                    <Layout style={{ flex: 15 }}>
                        <ScrollView contentContainerStyle={{ gap: 5 }}>
                            {selectedCompany?.settings.job_options.showTowingJobOptions &&
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Button
                                        style={{ flex: 1 }}
                                        appearance={holdingsOrJobs === "jobs" ? 'filled' : 'outline'}
                                        status='info'
                                        onPress={() => {
                                            setHoldingsOrJobs("jobs")
                                        }}>
                                        Jobs
                                    </Button>
                                    <Button
                                        style={{ flex: 1 }}
                                        appearance={holdingsOrJobs === "holdings" ? 'filled' : 'outline'}
                                        status='info'
                                        onPress={() => {
                                            setHoldingsOrJobs("holdings")
                                        }}>
                                        Holdings
                                    </Button>
                                </View>
                            }
                            <View>
                                <ExportButton onPress={() => { setExportModalVisible(true) }} />
                            </View>
                            <Button style={{ flex: 1 }} size="small" onPress={() => { searchJobsByDateRange() }}>
                                Search
                            </Button>
                            <Layout style={{}}>
                                <DateRangePicker
                                    currentWeek={currentWeek}
                                    dateRange={dateRange}
                                    onDateRangeChange={(nextRange: DateRange) => validateRange(nextRange)}
                                    handlePreviousWeek={handlePreviousWeek}
                                    handleNextWeek={handleNextWeek}
                                />
                            </Layout>
                            <View style={{ flexDirection: 'column' }}>
                                <RenderFilterCards />

                            </View>
                        </ScrollView>
                    </Layout>
                    {/*
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    //////// DATA GRID
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    */}
                    <View style={{ flex: 85, fontFamily: 'Helvetica' }}>
                        <InvoiceDataGrid
                            columns={memoizedColumns as any}
                            rows={sortedData}
                            onRowsChange={setRows}
                            onFill={handleFill}
                            onCopy={handleCopy}
                            onPaste={handlePaste}
                            //@ts-ignore
                            sortDirection={sortDirection}
                            //@ts-ignore
                            onSort={handleGridSort}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            onCellContextMenu={handleCellContextMenu}
                            topSummaryRows={holdingsOrJobs == "jobs" ? topSummaryRowJobs : topSummaryRowHoldings}
                            onCellClick={(args, event) => {
                                console.log("=====Cell click=====", args, event)
                            }}
                        />
                    </View>
                </View>
                {/*
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                //////// JOB DETAILS PANEL
                //////////////////////////////////////////////////////////////////
                //////////////////////////////////////////////////////////////////
                */}

                {((showJobDetailsPanel || holdingDetailsOpen) && (selectedJob || selectedHolding)) &&
                    <JobDetailsPanel
                        selectedItem={selectedJob || selectedHolding}
                        holdingsOrJobs={holdingsOrJobs as 'jobs' | 'holdings'}
                        onClose={() => {
                            setShowJobDetailsPanel(false)
                            setHoldingDetailsOpen(false)
                            setSelectedJob(null)
                            setSelectedHolding(null)
                        }}
                    />
                }
            </View>
        </ErrorBoundary >
    );

};



// export default invoiceScreen

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },

});
