import React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import { Layout, Text, Button, Icon, useTheme } from '@ui-kitten/components';
import { VroomSummary } from '../../types/route.types';

interface VroomSummaryDisplayProps {
    summary: VroomSummary;
    onClose: () => void;
}

export const VroomSummaryDisplay: React.FC<VroomSummaryDisplayProps> = ({ summary, onClose }) => {
    const theme = useTheme();
    const styles = StyleSheet.create({
        vroomSummary: {
            position: 'absolute',
            bottom: 10,
            right: 10,
            width: '25%',
            padding: 10,
            borderRadius: 8,
            zIndex: 1000,
            maxHeight: '50%',
        },
        vroomSummaryHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
        },
        vroomSummaryContent: {
            marginBottom: 10,
        },
        summaryItem: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 5,
        },
        pill: {
            borderRadius: 12,
            paddingHorizontal: 8,
            paddingVertical: 2,
            marginLeft: 5,
        },
        icon: {
            width: 20,
            height: 20,
            marginRight: 5,
            tintColor: theme['text-basic-color'], // Add this line
        },
        subheader: {
            marginTop: 10,
            marginBottom: 5,
        },
    });

    const calculateDistance = (distance: number) => {
        return (distance / 1000).toFixed(2) + ' km';
    }

    const calculateDuration = (duration: number) => {
        return (duration / 60).toFixed(2) + ' minutes';
    }

    const calculateCost = (cost: number) => {
        return `$${cost.toFixed(2)}`;
    }


    return (
        <Layout style={styles.vroomSummary}>
            <View style={styles.vroomSummaryHeader}>
                <Text category="h6">Route Summary</Text>
                <Button appearance="ghost" status="basic" accessoryLeft={(props) => <Icon {...props} name='close-outline' fill={theme['text-basic-color']} />} onPress={onClose} />
            </View>
            <ScrollView style={styles.vroomSummaryContent}>
                {/* <View style={styles.summaryItem}>
                    <Icon name='pricetags-outline' style={styles.icon} fill={theme['text-basic-color']} />
                    <Text>Total Cost: </Text>
                    <Text style={[styles.pill, { backgroundColor: theme['color-success-default'] }]}>{calculateCost(summary.cost)}</Text>
                </View> */}
                <View style={styles.summaryItem}>
                    <Icon name='map-outline' style={styles.icon} fill={theme['text-basic-color']} />
                    <Text>Routes: </Text>
                    <Text style={[styles.pill, { backgroundColor: theme['color-info-default'] }]}>{summary.routes}</Text>
                </View>
                <View style={styles.summaryItem}>
                    <Icon name='alert-triangle-outline' style={styles.icon} fill={theme['text-basic-color']} />
                    <Text>Unassigned: </Text>
                    <Text style={[styles.pill, { backgroundColor: theme['color-danger-500'] }]}>{summary.unassigned}</Text>
                </View>
                <View style={styles.summaryItem}>
                    <Icon name='clock-outline' style={styles.icon} fill={theme['text-basic-color']} />
                    <Text>Total Duration: </Text>
                    <Text style={[styles.pill, { backgroundColor: theme['color-warning-100'] }]}>{calculateDuration(summary.duration)}</Text>
                </View>
                <View style={styles.summaryItem}>
                    <Icon name='navigation-2-outline' style={styles.icon} fill={theme['text-basic-color']} />
                    <Text>Total Distance: </Text>
                    <Text style={[styles.pill, { backgroundColor: theme['color-success-100'] }]}>{calculateDistance(summary.distance)}</Text>
                </View>
                <Text category="h6" style={styles.subheader}>Computing Times</Text>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                    <View style={styles.summaryItem}>
                        <Icon name='loader-outline' style={styles.icon} fill={theme['text-basic-color']} />
                        <Text>Loading: </Text>
                        <Text style={[styles.pill]}>{summary.computing_times.loading} ms</Text>
                    </View>
                    <View style={styles.summaryItem}>
                        <Icon name='flash-outline' style={styles.icon} fill={theme['text-basic-color']} />
                        <Text>Solving: </Text>
                        <Text style={[styles.pill]}>{summary.computing_times.solving} ms</Text>
                    </View>
                    <View style={styles.summaryItem}>
                        <Icon name='compass-outline' style={styles.icon} fill={theme['text-basic-color']} />
                        <Text>Routing: </Text>
                        <Text style={[styles.pill]}>{summary.computing_times.routing} ms</Text>
                    </View>
                </View>
            </ScrollView>
        </Layout>
    );
};

