import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { Modal, Card, Text, Button, Spinner } from '@ui-kitten/components';
import * as CompanyController from '../../functions/company.controller';
import { Company } from '../../models/Company.model';

interface StripeConnectCompleteProps {
    visible: boolean;
    onClose: () => void;
    companyId: string;
}

export const StripeConnectComplete: React.FC<StripeConnectCompleteProps> = ({ visible, onClose, companyId }) => {
    const [isChecking, setIsChecking] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        const checkStripeConnectStatus = async () => {
            try {
                const company = await CompanyController.getCompanyById(companyId);
                const stripeConnectAccountId = company?.settings.payment_settings.stripe.stripe_connect_account_id;

                if (stripeConnectAccountId && stripeConnectAccountId !== "" && stripeConnectAccountId !== null) {
                    setIsConnected(true);
                    setIsChecking(false);
                    clearInterval(intervalId);
                }
            } catch (err) {
                setError('Failed to check Stripe Connect status');
                setIsChecking(false);
                clearInterval(intervalId);
            }
        };

        if (visible) {
            checkStripeConnectStatus();
            intervalId = setInterval(checkStripeConnectStatus, 5000); // Check every 5 seconds
        }

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [visible, companyId]);

    const renderModalContent = () => {
        if (isChecking) {
            return (
                <>
                    <Spinner size='medium' />
                    <Text>Checking Stripe Connect status...</Text>
                </>
            );
        }

        if (error) {
            return (
                <>
                    <Text status="danger">{error}</Text>
                    <Button onPress={onClose}>Close</Button>
                </>
            );
        }

        if (isConnected) {
            return (
                <>
                    <Text>Stripe Connect account successfully set up!</Text>
                    <Button onPress={onClose}>Close</Button>
                </>
            );
        }

        return (
            <>
                <Text>Stripe Connect account setup is still in progress. Please try again later.</Text>
                <Button onPress={onClose}>Close</Button>
            </>
        );
    };

    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={onClose}
        >
            <Card disabled={true}>
                {renderModalContent()}
            </Card>
        </Modal>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
});
