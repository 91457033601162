import React, { useState, useEffect, useRef, useReducer } from 'react';
//@ts-ignore
import { StyleSheet, View, Image, ScrollView } from 'react-native';
import _ from 'lodash';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import * as UpdateController from '../../functions/update.controller';
import * as UserController from '../../functions/user.controller';
import * as StorageController from "../../functions/storageController"
import * as MessageController from "../../functions/message.controller"
import { Layout, Text, Button, Divider, List, Card, Input, Icon } from '@ui-kitten/components';
import { GiftedChat, IChatMessage } from 'react-native-gifted-chat';
import { renderInputToolbar, renderActions, RenderComposer, renderSend } from './messageInputToolbar.component';
import ErrorBoundary from '../ErrorBoundary.component';
import {
    renderAvatar,
    renderBubble,
    renderSystemMessage,
    renderMessage,
    renderMessageText,
    renderCustomView,
} from './messageComponents.component';
import { Member } from '../../models/Member.model';
import { Message } from '../../models/Message.model';
import { useAppStateChange, IAction, STATE_ACTIONS } from '../../hooks/appStateChange.hook';

interface MessagesScreenProps {
    var_selectedMember?: Member | null;
    var_showChatSection?: boolean;
    fn_onSendMessage?: (message: string) => void;
    style?: any;
}

export const MessagesScreen = ({
    var_selectedMember = null,
    var_showChatSection = false,
    fn_onSendMessage = () => { },
    style = {},
}: MessagesScreenProps) => {

    const [eventsRegistered, setEventsRegistered] = React.useState(false)




    const eventTokensRef = React.useRef([] as any);
    const mqttChatEventsTokensRef = React.useRef([] as any);
    const chatRef = React.useRef(null as GiftedChat | null);
    const [messages, setMessages] = useState([] as Message[]);
    const messagesRef = React.useRef(messages); //for events to keep state
    const memoizedMessages = React.useMemo(() => messages, [messages]);

  




    const onSetMessages = (newMessages = [] as Message[]) => {
        if (!Array.isArray(newMessages)) {
            console.log('newMessages is not an array:', newMessages);
            return;
        }
        // only update if the messages are different
        if (newMessages.length != 0) {
            if (JSON.stringify(newMessages) == JSON.stringify(messages)) {
                return
            }
        }
        const validMessages = newMessages.filter((message: Message) => message._id != null);
        const uniqueMessages = _.uniqBy(validMessages, '_id');
        messagesRef.current = uniqueMessages;
        setMessages(uniqueMessages)
    }

    const onSend = async (newMessages: Message[] = []) => {
        try {
            if (newMessages.length == 0 || newMessages[0].text == undefined || newMessages[0].text == "") {
                return
            }
            // Create the local version of message
            const selectedMembership = StorageController.getAppState().selectedMembership
            newMessages[0].user = {
                _id: selectedMembership?._id,
                name: selectedMembership?.name,
            }
            onSetMessages([...messagesRef.current, newMessages[0]]);
            const selectedChatRecipient = StorageController.getAppState().selectedChatRecipient as Member
            const newMessageText = newMessages[0].text
            const _message = await MessageController.sendMessage(selectedChatRecipient._id, newMessageText)
            await StorageController.updateMessages(_message)
            fn_onSendMessage(newMessageText)
        } catch (e) {
            console.log(e)
        }
    };

    const sortedMessages = (messages: Message[]) => {
        if (!messages) return []
        let _messages = messages.sort((a, b) => {
            // convert iso date to timestamp
            const at = new Date(a.createdAt).getTime()
            const bt = new Date(b.createdAt).getTime()
            return (at - bt)
        })
        return _messages
    }

    const setLastMessageRead = async () => {
        let updatedMessages = [...messagesRef.current]
        let lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage) {
            lastMessage.read = true;  // Set read status to true
        }
        onSetMessages(updatedMessages);
        // onSetMessages(prevMessages => {
        //     // Deep copy the messages array and the last message object
        //     let updatedMessages = JSON.parse(JSON.stringify(prevMessages));
        //     // Assuming messages are sorted newest-first
        //     let lastMessage = updatedMessages[updatedMessages.length - 1];

        //     if (lastMessage) {
        //         lastMessage.read = true;  // Set read status to true
        //     }
        //     return updatedMessages;
        // });
        // forceUpdate();
    };


    const handleMqttChatReadEvent = async (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.MQTT_ON_MEMBER_CHAT_READ) {
            try {
                setLastMessageRead();
            } catch (e) {
                console.log(e);
            }
        }
    };

    const handleMqttMemberChatUpdateEvent = async (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.MEMBER_CHAT_UPDATE) {
            // console.log("MQTT_ON_MEMBER_CHAT_UPDATE", action);
        }
    };

    const handleMqttMemberIsTypingEvent = async (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.MEMBER_CHAT_IS_TYPING) {
            // console.log("MEMBER_CHAT_IS_TYPING", action);
        }
    };


    // Callback function for the selected chat messages event
    const handleSelectedChatMessagesEvent = (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.SET_SELECTED_CHAT) {
            try {
                console.log("🚀 ~ file: messagingScreen.component.js:174 ~ handleSelectedChatMessagesEvent ~ SET_SELECTED_CHAT:", action)
                const m = sortedMessages(action.data.messages)
                // GiftedChat.prepend([], m)
                onSetMessages(m);
                // onSetMessages((prevMessages) => GiftedChat.prepend([], m));
                MessageController.setAllMessagesToReadForSelectedMembership(action.data.messages);
                setTimeout(() => {
                    if (chatRef.current) {
                        chatRef.current.scrollToBottom();
                    }
                }, 500); // Adjust delay as needed
            } catch (e) {
                console.log(e);
            }
        }
    };// Function to register the company switched event



    // Callback function for the company switched event
    const handleCompanySwitchedEvent = (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.UPDATED_SELECTED_COMPANY) {
            // console.log("COMPANY_SWITCHED", action);
            onSetMessages([]);
        }
    };





    // Callback function for the message update event
    const handleMessageUpdateEvent = async (action: UpdateController.IAction) => {
        if (action.type === UpdateController.STATE_ACTIONS.MESSAGE_UPDATE) {
            console.log("🚀============== ~ file: messagingScreen.component.tsx:190 ~ handleMessageUpdateEvent ~ action.type🚀==============", action)
            // console.log("MESSAGE_UPDATE", action);
            const selectedMembership = StorageController.getAppState().selectedMembership as Member
            const selectedChatRecipient = StorageController.getAppState().selectedChatRecipient as Member
            if (
                action.data.data.recipient === selectedMembership._id &&
                action.data.data.sender === selectedChatRecipient._id
            ) {
                // set message as read straight away if it's open
                if (var_showChatSection) {
                    MessageController.setAllMessagesToReadForSelectedMembership(action.data.data);
                }
                await StorageController.updateMessages(action.data.data);
                onSetMessages([...messagesRef.current, action.data.data])
                // GiftedChat.prepend(...messages, action.data.data);
                // onSetMessages((prevMessages) => GiftedChat.prepend(prevMessages, action.data.data));
                chatRef.current?.scrollToBottom();
            }
            else {
                console.log("🚀============== ~ file: messagingScreen.component.js:252 ~ handleMessageUpdateEvent ~ getUnreadMessages🚀==============")
                MessageController.getUnreadMessages();
            }
        }
    };


    const { dispatchEventStateChange } = useAppStateChange({
        onMqttOnMemberChatRead: handleMqttChatReadEvent,
        onMemberChatUpdate: handleMqttMemberChatUpdateEvent,
        onMemberChatIsTyping: handleMqttMemberIsTypingEvent,
        onSetSelectedChat: handleSelectedChatMessagesEvent,
        onUpdatedSelectedCompany: handleCompanySwitchedEvent,
        onMessageUpdate: handleMessageUpdateEvent,
    });




    return (
        <Layout style={[style, { backgroundColor: "#141A29" }]}>
            <ErrorBoundary>
                <SafeAreaProvider>
                    <GiftedChat
                        ref={chatRef}
                        //@ts-ignore - Using own Message class not IChatMessage
                        messages={memoizedMessages}
                        // text={text}
                        // onInputTextChanged={setText}
                        //@ts-ignore - Using own message class no IChatMessage
                        onSend={onSend}
                        user={{
                            _id: StorageController.getAppState().selectedMembership?._id || "" as string,
                            name: StorageController.getAppState().selectedMembership?.name,
                            // avatar: 'https://placeimg.com/150/150/any',
                        }}
                        // alignTop
                        alwaysShowSend
                        scrollToBottom
                        showUserAvatar
                        renderAvatarOnTop
                        renderUsernameOnMessage
                        bottomOffset={25}
                        onPressAvatar={console.log}
                        renderInputToolbar={renderInputToolbar}
                        // renderActions={renderActions}
                        renderComposer={RenderComposer}
                        // renderComposer={() =>
                        //     <RenderComposer
                        //         fn_onSend={customSend}
                        //     />
                        // }
                        renderSend={renderSend}
                        // renderAvatar={renderAvatar}
                        renderBubble={renderBubble}
                        // renderSystemMessage={renderSystemMessage}
                        renderMessage={renderMessage}
                        renderMessageText={renderMessageText}
                        // renderCustomView={renderCustomView}
                        // renderMessageImage
                        isCustomViewBottom
                        inverted={false}
                        // messagesContainerStyle={{ backgroundColor: 'indigo' }}
                        parsePatterns={(linkStyle: any) => [
                            {
                                pattern: /#(\w+)/,
                                style: linkStyle,
                                onPress: (tag: any) => console.log(`Pressed on hashtag: ${tag}`),
                            },
                        ]}
                    />
                </SafeAreaProvider>
            </ErrorBoundary>
        </Layout>
    );


}