import React, { useMemo } from 'react';
//@ts-ignore
import { StyleSheet, View } from 'react-native';
import {
    Card,
    Text,
} from "@ui-kitten/components";
import { useTowingLocationDetails } from './hooks/useTowingLocationDetails';
import { RouteTypeSelector } from './RouteTypeSelector.component';
import { StartEndLocations } from './StartEndLocations.component';
import { TowingLocationsList } from './TowingLocationsList.component';
import { TowingMap } from './TowingMap.component';
import { CostCalculator } from './CostCalculator.component';
import { JobTowingDetails, JobLocation, TowingLocation } from '../../../models/Job.model';
import { useJsApiLoader } from '@react-google-maps/api';

// Constants
const mapLibs = ["places", "visualization"];
const mapPanelWidth = 200;
const mapHeight = 500;
const mapContainerStyle = {
    width: "100%",
    height: `${mapHeight}px`
};

interface TowingLocationDetailsCardProps {
    style?: any;
    allowEdit?: boolean;
    onChangeTowingLocations?: (t: any) => void;
    onAddCostToItems?: (c: any) => void;
    defaultTowingLocationObj?: JobTowingDetails;
    validateOnLoad?: boolean;
    pickupAddressDisplayValue?: string | null;
    dropoffAddressDisplayValue?: string | null;
}

export const TowingLocationDetailsCard = ({
    style = {},
    allowEdit = true,
    onChangeTowingLocations = (t: any) => { },
    onAddCostToItems = (c: any) => { },
    defaultTowingLocationObj = new JobTowingDetails({}),
    validateOnLoad = false,
    pickupAddressDisplayValue = null as string | null,
    dropoffAddressDisplayValue = null as string | null,
}: TowingLocationDetailsCardProps) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs  // Required for heatmap
    });

    const {
        jobTowingDetails,
        costPerKm,
        coveredDistance,
        directionsPolylines,
        directionsPolyline,
        addCoveredCostToBillBack,
        showLocationSelectInfoWindow,
        InfoWindowListSelectedIndex,
        locationSearchResults,
        startEndOption,
        center,
        setCostPerKm,
        setCoveredDistance,
        setAddCoveredCostToBillBack,
        setInfoWindowListSelectedIndex,
        setShowLocationSelectInfoWindow,
        onMapClick,
        onDragMarker,
        onAddTowingCostToItems,
        addNewLocation,
        removeOriginalDropoffLocation,
        handleRouteTypeChange,
        validateTowingLegs,
        onRemoveAndSaveAsOriginalDestination,
        onLocationObjectChange,
        onAddressChange,
        onLocationChange,
        onAddressLocationChange,
        onSavedAddressSelect,
        onRemoveLocation,
        onLocationSelect,
    } = useTowingLocationDetails({
        defaultTowingLocationObj,
        validateOnLoad,
        onChangeTowingLocations,
        onAddCostToItems,
    });

    const memoizedStartEndLocations = useMemo(() => {
        return <StartEndLocations
            jobTowingDetails={jobTowingDetails}
            allowEdit={allowEdit}
            onStartTowingLocationChange={(location) => {
                let tDetails = new JobTowingDetails(jobTowingDetails);
                tDetails.start_location = location;
                onChangeTowingLocations(tDetails);
                setTimeout(() => {
                    validateTowingLegs();
                }, 100);
            }}
            onEndTowingLocationChange={(location) => {
                let tDetails = new JobTowingDetails(jobTowingDetails);
                tDetails.end_location = location;
                onChangeTowingLocations(tDetails);
                setTimeout(() => {
                    validateTowingLegs();
                }, 100);
            }}
            onRemoveStartLocation={() => {
                let tDetails = new JobTowingDetails(jobTowingDetails);
                tDetails.start_location = null;
                onChangeTowingLocations(tDetails);
                setTimeout(() => {
                    validateTowingLegs();
                }, 100);
            }}
            onRemoveEndLocation={() => {
                let tDetails = new JobTowingDetails(jobTowingDetails);
                tDetails.end_location = null;
                onChangeTowingLocations(tDetails);
                setTimeout(() => {
                    validateTowingLegs();
                }, 100);
            }}
        />;
    }, [jobTowingDetails, onChangeTowingLocations, allowEdit, validateTowingLegs]);

    return (
        <Card
            status="info"
            header={() => <Text style={{ alignSelf: 'center' }}>{"Towing Locations"}</Text>}
            disabled={true}
            style={style}
        >
            <View style={{ flexDirection: 'row', justifyContent: "space-between", margin: -15, flexWrap: 'wrap' }}>
                <View style={styles.addressContainer}>
                    <RouteTypeSelector
                        startEndOption={startEndOption}
                        onRouteTypeChange={handleRouteTypeChange}
                    />

                    {memoizedStartEndLocations}

                    <TowingLocationsList
                        towingLocations={jobTowingDetails.towing_locations}
                        towingLegs={jobTowingDetails.towing_legs}
                        originalDropoffLocation={jobTowingDetails.original_dropoff_towing_location}
                        allowEdit={allowEdit}
                        pickupAddressDisplayValue={pickupAddressDisplayValue}
                        dropoffAddressDisplayValue={dropoffAddressDisplayValue}
                        onAddLocation={addNewLocation}
                        onRemoveLocation={onRemoveLocation}
                        onRemoveAndSaveAsOriginalDestination={onRemoveAndSaveAsOriginalDestination}
                        onRemoveOriginalDropoffLocation={removeOriginalDropoffLocation}
                        onLocationObjectChange={onLocationObjectChange}
                        onAddressChange={onAddressChange}
                        onLocationChange={onLocationChange}
                        onAddressLocationChange={onAddressLocationChange}
                        onSavedAddressSelect={onSavedAddressSelect}
                    />
                </View>
                <View style={styles.mapContainer}>
                    <View>
                        <TowingMap
                            jobTowingDetails={jobTowingDetails}
                            isLoaded={isLoaded}
                            center={center}
                            directionsPolylines={directionsPolylines}
                            directionsPolyline={directionsPolyline}
                            onMapClick={onMapClick}
                            onDragMarker={onDragMarker}
                            validateTowingLegs={validateTowingLegs}
                            showLocationSelectInfoWindow={showLocationSelectInfoWindow}
                            locationSearchResults={locationSearchResults}
                            InfoWindowListSelectedIndex={InfoWindowListSelectedIndex}
                            setInfoWindowListSelectedIndex={setInfoWindowListSelectedIndex}
                            setShowLocationSelectInfoWindow={setShowLocationSelectInfoWindow}
                            onLocationSelect={onLocationSelect}
                        />
                        <CostCalculator
                            jobTowingDetails={jobTowingDetails}
                            costPerKm={costPerKm}
                            coveredDistance={coveredDistance}
                            addCoveredCostToBillBack={addCoveredCostToBillBack}
                            allowEdit={allowEdit}
                            onCostPerKmChange={setCostPerKm}
                            onCoveredDistanceChange={setCoveredDistance}
                            onAddCoveredCostToBillBackChange={setAddCoveredCostToBillBack}
                            onAddTowingCostToItems={onAddTowingCostToItems}
                        />
                    </View>
                </View>
            </View>
        </Card>
    );
};

const styles = StyleSheet.create({
    addressContainer: {
        flexDirection: 'column',
        flex: 1,
        margin: 5,
        gap: 10,
        minWidth: 350
    },
    mapContainer: {
        flex: 1,
        minWidth: 350,
    },
    groupContainer: {
        margin: 0,
        flex: 1,
        minWidth: 350,
    },
    greenContainer: {
        margin: 10,
        minWidth: 350,
        backgroundColor: '#009362a1',
    },
    orangeContainer: {
        margin: 10,
        minWidth: 350,
        backgroundColor: '#6f2910a1',
    },
});