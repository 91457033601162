import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Modal, Card, Text, Button } from '@ui-kitten/components';

interface PartsNetworkSetupModalProps {
    visible: boolean;
    onClose: () => void;
    onSetupClick: () => void;
}

export const PartsNetworkSetupModal = ({ visible, onClose, onSetupClick }: PartsNetworkSetupModalProps) => {
    return (
        <Modal
            visible={visible}
            backdropStyle={styles.backdrop}
            onBackdropPress={onClose}
        >
            <Card disabled style={styles.card}>
                <Text category="h6" style={styles.title}>Setup Parts Network Account</Text>

                <Text style={styles.description}>
                    To enable Parts Network, you'll need to complete these steps:
                </Text>

                <View style={styles.steps}>
                    <Text>1. Set up your Stripe Connect account</Text>
                    <Text>2. Verify your business information</Text>
                    <Text>3. Configure your payment settings</Text>
                </View>

                <View style={styles.buttonContainer}>
                    <Button status="basic" appearance="outline" onPress={onClose} style={styles.button}>
                        Cancel
                    </Button>
                    <Button status="primary" onPress={onSetupClick} style={styles.button}>
                        Start Setup
                    </Button>
                </View>
            </Card>
        </Modal>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    card: {
        width: 400,
        maxWidth: '90%',
    },
    title: {
        marginBottom: 16,
    },
    description: {
        marginBottom: 16,
    },
    steps: {
        marginBottom: 24,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 8,
    },
    button: {
        minWidth: 120,
    },
}); 