import React from 'react';
import { View } from 'react-native';
import { Button, Icon, RangeDatepicker } from '@ui-kitten/components';

interface DateRangePickerProps {
  currentWeek: Date;
  dateRange: { startDate: Date; endDate: Date };
  onDateRangeChange: (range: { startDate: Date; endDate: Date }) => void;
  handlePreviousWeek: () => void;
  handleNextWeek: () => void;
}

export const DateRangePicker = ({
  currentWeek,
  dateRange,
  onDateRangeChange,
  handlePreviousWeek,
  handleNextWeek,
}: DateRangePickerProps) => {
  const CalendarIcon = (props: any) => (
    <Icon {...props} name="calendar" />
  );

  return (
    <View>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Button
          style={{ flex: 1, margin: 5 }}
          appearance="outline"
          status="basic"
          onPress={handlePreviousWeek}
        >
          <Icon name="arrow-ios-back-outline" width={30} height={30} />
        </Button>
        <Button
          style={{ flex: 1, margin: 5 }}
          appearance="outline"
          status="basic"
          onPress={handleNextWeek}
        >
          <Icon name="arrow-ios-forward-outline" width={30} height={30} />
        </Button>
      </View>
      <RangeDatepicker
        range={dateRange}
        min={new Date(1900, 0, 0)}
        max={new Date(2099, 0, 0)}
        accessoryRight={CalendarIcon}
        //@ts-ignore
        onSelect={onDateRangeChange}
      />
    </View>
  );
};