import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Card, Text, Button, Icon, Input, Select, SelectItem, IndexPath } from '@ui-kitten/components';
import { PropertyPathBuilder } from './propertyPathBuilder.component';
import { IntegrationEndpoint } from '../../models/Company.model';
import DMModal from '../common/Modal'; // Add this import

type PropertyType = 'property_path' | 'string' | 'timestamp';

interface PropertyRow {
    id: string;
    name: string;
    type: PropertyType;
    value: string;
}

interface EndpointPropertyBuilderProps {
    endpoint: IntegrationEndpoint;
    eventType: string;
    onChange: (template: string) => void;
}

export const EndpointPropertyBuilder = ({ endpoint, eventType, onChange }: EndpointPropertyBuilderProps) => {
    const [rows, setRows] = useState<PropertyRow[]>([]);
    const [showPropertySelector, setShowPropertySelector] = useState<string | null>(null); // stores row id

    const typeOptions = [
        { title: 'Property Path', value: 'property_path' as PropertyType },
        { title: 'String', value: 'string' as PropertyType },
        { title: 'Timestamp', value: 'timestamp' as PropertyType }
    ];

    // Initialize with default rows
    useEffect(() => {
        const defaultRows: PropertyRow[] = [
            { id: 'event', name: 'event', type: 'string', value: eventType },
            { id: 'job', name: 'job', type: 'property_path', value: 'job' },
            { id: 'timestamp', name: 'timestamp', type: 'timestamp', value: new Date().toISOString() }
        ];
        // load it from endpoint if it exists
        if (endpoint.body_template) {
            // get the properties from the template
            const properties = getPropertiesFromTemplate(endpoint.body_template);
            setRows(properties);
        } else {
            setRows(defaultRows);
        }
    }, [eventType]);

    const getPropertiesFromTemplate = (template: string): PropertyRow[] => {
        try {
            const parsed = JSON.parse(template);
            return Object.entries(parsed).map(([key, value]) => {
                // Check if value matches ${...} pattern
                if (typeof value === 'string') {
                    const match = value.match(/\${(.*?)}/);
                    if (match) {
                        // It's a property path
                        return {
                            id: `row_${key}`,
                            name: key,
                            type: 'property_path',
                            value: match[1] // Get the content inside ${}
                        };
                    } else if (key === 'timestamp') {
                        // It's a timestamp
                        return {
                            id: `row_${key}`,
                            name: key,
                            type: 'timestamp',
                            value: new Date().toISOString()
                        };
                    } else {
                        // It's a regular string
                        return {
                            id: `row_${key}`,
                            name: key,
                            type: 'string',
                            value: value
                        };
                    }
                }
                // Default case
                return {
                    id: `row_${key}`,
                    name: key,
                    type: 'string',
                    value: String(value)
                };
            });
        } catch (e) {
            console.log("Failed to parse template:", e);
            return [
                { id: 'event', name: 'event', type: 'string', value: eventType },
                { id: 'job', name: 'job', type: 'property_path', value: 'job' },
                { id: 'timestamp', name: 'timestamp', type: 'timestamp', value: new Date().toISOString() }
            ];
        }
    };

    // Generate template whenever rows change
    useEffect(() => {
        const template: { [key: string]: any } = {};
        rows.forEach(row => {
            switch (row.type) {
                case 'property_path':
                    template[row.name] = `\${${row.value}}`;
                    break;
                case 'string':
                    template[row.name] = row.value;
                    break;
                case 'timestamp':
                    template[row.name] = new Date().toISOString();
                    break;
            }
        });
        onChange(JSON.stringify(template, null, 2));
    }, [rows]);

    const updateRow = (id: string, updates: Partial<PropertyRow>) => {
        setRows(currentRows => 
            currentRows.map(row => 
                row.id === id ? { ...row, ...updates } : row
            )
        );
    };

    const addRow = () => {
        const newId = `row_${Date.now()}`;
        setRows(current => [...current, {
            id: newId,
            name: `property_${current.length}`,
            type: 'string',
            value: ''
        }]);
    };

    const removeRow = (id: string) => {
        setRows(current => current.filter(row => row.id !== id));
    };

    const renderPropertyValue = (row: PropertyRow) => {
        switch (row.type) {
            case 'property_path':
                return (
                    <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', gap: 5 }}>
                        <Text style={{ flex: 1 }} numberOfLines={1}>{row.value}</Text>
                        <Button
                            size='small'
                            status='info'
                            appearance='ghost'
                            onPress={() => setShowPropertySelector(row.id)}
                            accessoryLeft={props => <Icon {...props} name='search-outline'/>}
                        />
                    </View>
                );
            case 'string':
                return (
                    <Input
                        size='small'
                        style={{ flex: 1 }}
                        value={row.value}
                        onChangeText={text => updateRow(row.id, { value: text })}
                        placeholder="Enter value"
                    />
                );
            case 'timestamp':
                return (
                    <Input
                        size='small'
                        style={{ flex: 1 }}
                        value={new Date().toISOString()}
                        disabled
                    />
                );
        }
    };

    return (
        <View style={{ flexDirection: 'row', gap: 10 }}>
            <Card style={{ flex: 1 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15 }}>
                    <Text category='h6'>Properties</Text>
                    <Button
                        size='small'
                        onPress={addRow}
                        accessoryLeft={props => <Icon {...props} name='plus-outline'/>}
                    >
                        Add Property
                    </Button>
                </View>

                {rows.map(row => (
                    <View key={row.id} style={{ flexDirection: 'row', alignItems: 'center', gap: 10, marginBottom: 5 }}>
                        <Input
                            size='small'
                            style={{ flex: 1 }}
                            value={row.name}
                            onChangeText={text => updateRow(row.id, { name: text })}
                            placeholder="Property name"
                        />
                        <Text>:</Text>
                        <Select
                            size='small'
                            style={{ width: 150 }}
                            selectedIndex={new IndexPath(typeOptions.findIndex(t => t.value === row.type))}
                            value={typeOptions.find(t => t.value === row.type)?.title}
                            onSelect={index => {
                                //@ts-ignore
                                const type = typeOptions[index.row].value;
                                updateRow(row.id, { 
                                    type,
                                    value: type === 'timestamp' ? new Date().toISOString() : ''
                                });
                            }}
                        >
                            {typeOptions.map(option => 
                                <SelectItem key={option.value} title={option.title} />
                            )}
                        </Select>
                        {renderPropertyValue(row)}
                        <Button
                            size='small'
                            status='danger'
                            appearance='ghost'
                            onPress={() => removeRow(row.id)}
                            accessoryLeft={props => <Icon {...props} name='close-outline'/>}
                        />
                    </View>
                ))}
            </Card>

            <Card style={{ flex: 1 }} disabled={true}>
                <Text category='h6'>Preview</Text>
                <Text appearance='hint' selectable style={{ fontFamily: 'monospace' }}>
                    {rows.length > 0 ? JSON.stringify(
                        rows.reduce((acc, row) => ({
                            ...acc,
                            [row.name]: row.type === 'property_path' ? 
                                `\${${row.value}}` : 
                                row.type === 'timestamp' ? 
                                    new Date().toISOString() : 
                                    row.value
                        }), {}),
                        null,
                        2
                    ) : '{}'}
                </Text>
            </Card>

            <DMModal
                visible={!!showPropertySelector}
                onClose={() => setShowPropertySelector(null)}
            >
                <Card style={{ width: 400 }}>
                    <PropertyPathBuilder
                        onSelectPath={path => {
                            if (showPropertySelector) {
                                updateRow(showPropertySelector, { value: path });
                                setShowPropertySelector(null);
                            }
                        }}
                        onClose={() => setShowPropertySelector(null)}
                    />
                </Card>
            </DMModal>
        </View>
    );
};
