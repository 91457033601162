import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { Button, Card, Icon, Input, Text, Toggle } from '@ui-kitten/components';
import { JobTowingDetails, JOB_TOWING_START_END_OPTION } from '../../../models/Job.model';
import { LineItem } from '../../../models/LineItem.model';

interface CostCalculatorProps {
    jobTowingDetails: JobTowingDetails;
    costPerKm: string;
    coveredDistance: string;
    addCoveredCostToBillBack: boolean;
    allowEdit: boolean;
    onCostPerKmChange: (value: string) => void;
    onCoveredDistanceChange: (value: string) => void;
    onAddCoveredCostToBillBackChange: (value: boolean) => void;
    onAddTowingCostToItems: (items: LineItem[]) => void;
}

export const CostCalculator = ({
    jobTowingDetails,
    costPerKm,
    coveredDistance,
    addCoveredCostToBillBack,
    allowEdit,
    onCostPerKmChange,
    onCoveredDistanceChange,
    onAddCoveredCostToBillBackChange,
    onAddTowingCostToItems
}: CostCalculatorProps) => {
    // State for calculated values
    const [distanceText, setDistanceText] = useState("");
    const [durationText, setDurationText] = useState("");
    const [uncoveredCost, setUncoveredCost] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [calculationError, setCalculationError] = useState("");

    // Calculate costs and distances
    useEffect(() => {
        try {
            let totalDistance = jobTowingDetails.getTotalDistance() / 1000;
            let totalDuration = jobTowingDetails.getTotalDuration() / 60;

            const costPerKmNum = Number(costPerKm);
            const coveredDistanceNum = Number(coveredDistance);

            if (isNaN(costPerKmNum) || isNaN(coveredDistanceNum)) {
                throw new Error("Invalid cost or distance values");
            }

            const offsetDistance = Math.max(totalDistance - coveredDistanceNum, 0);
            const calculatedUncoveredCost = offsetDistance * costPerKmNum;
            const calculatedTotalCost = totalDistance * costPerKmNum;

            setDistanceText(`${totalDistance.toFixed(2)} kms`);
            setDurationText(`${totalDuration.toFixed(2)} mins`);
            setUncoveredCost(calculatedUncoveredCost);
            setTotalCost(calculatedTotalCost);
            setCalculationError("");
        } catch (error: any) {
            setCalculationError(error.message);
        }
    }, [jobTowingDetails, costPerKm, coveredDistance]);

    const handleAddTowingCostToItems = () => {
        const totalDistance = jobTowingDetails.getTotalDistance() / 1000;
        const coveredDistanceValidated = Math.min(Number(coveredDistance), totalDistance);
        const uncoveredDistance = Math.max(totalDistance - coveredDistanceValidated, 0);

        const billBackName = `Towing COVERED - ${coveredDistanceValidated.toFixed(2)}km of ${totalDistance.toFixed(2)}km @ $${Number(costPerKm).toFixed(2)}/km`;
        const customerCostName = `Towing Customer Cost - ${uncoveredDistance.toFixed(2)}km of ${totalDistance.toFixed(2)}km @ $${Number(costPerKm).toFixed(2)}/km`;

        const result = [];

        if (addCoveredCostToBillBack && coveredDistanceValidated > 0) {
            result.push(new LineItem({
                name: billBackName,
                description: billBackName,
                quantity: 1,
                cost: Number((coveredDistanceValidated * Number(costPerKm)).toFixed(2)),
                billAllBack: true,
                billing: "Bill All Back",
            }));
        }

        if (uncoveredDistance > 0) {
            result.push(new LineItem({
                name: customerCostName,
                description: customerCostName,
                quantity: 1,
                cost: Number(uncoveredCost.toFixed(2)),
                billAllBack: false,
                customerCost: true,
                billing: "Customer Cost",
            }));
        }

        onAddTowingCostToItems(result);
    };

    return (
        <Card
            status="success"
            header={() => <Text style={{ alignSelf: 'center' }}>Calculated Result</Text>}
            disabled={true}
            style={{ margin: 0, marginTop: 0 }}
        >
            <View style={{ flexDirection: 'column', justifyContent: "space-between" }}>
                <View style={{ marginBottom: 10 }}>
                    {jobTowingDetails.start_leg && (
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Icon name='flag-outline' width={20} height={20} fill='green' style={{ marginRight: 5 }} />
                                <Text category='s1'>Start</Text>
                            </View>
                            <Text category='s1'>{`${(jobTowingDetails.start_leg?.directions?.distance_value / 1000).toFixed(2)} km / ${(jobTowingDetails.start_leg?.directions?.duration_value / 60).toFixed(2)} min`}</Text>
                        </View>
                    )}
                    {jobTowingDetails.towing_legs.map((leg, index) => (
                        <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Icon name='arrow-forward-outline' width={20} height={20} fill='black' style={{ marginRight: 5 }} />
                                <Text category='s1'>Leg {index + 1}</Text>
                            </View>
                            <Text category='s1'>{`${(leg.directions?.distance_value / 1000).toFixed(2)} km / ${(leg.directions?.duration_value / 60).toFixed(2)} min`}</Text>
                        </View>
                    ))}
                    {jobTowingDetails.end_leg && (
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <Icon name='flag-outline' width={20} height={20} fill='red' style={{ marginRight: 5 }} />
                                <Text category='s1'>End</Text>
                            </View>
                            <Text category='s1'>{`${(jobTowingDetails.end_leg?.directions?.distance_value / 1000).toFixed(2)} km / ${(jobTowingDetails.end_leg?.directions?.duration_value / 60).toFixed(2)} min`}</Text>
                        </View>
                    )}
                </View>

                <View style={{ flexDirection: 'row', justifyContent: "space-between" }}>
                    <View style={{ flexDirection: 'column', borderBottomColor: 'black', borderBottomWidth: 1 }}>
                        <Text style={{ margin: 5 }} category='label'>Distance:</Text>
                        <Text style={{ margin: 5 }}>{distanceText}</Text>
                    </View>
                    <View style={{ flexDirection: 'column', borderBottomColor: 'black', borderBottomWidth: 1 }}>
                        <Text style={{ margin: 5 }} category='label'>Duration:</Text>
                        <Text style={{ margin: 5 }}>{durationText}</Text>
                    </View>
                </View>

                <View style={{ flexDirection: 'row', gap: 20, justifyContent: 'space-between' }}>
                    <Input
                        textStyle={{ maxWidth: 130 }}
                        disabled={!allowEdit}
                        style={{ maxWidth: 130 }}
                        label='Cost per km ($)'
                        size='small'
                        placeholder='Cost per km'
                        value={costPerKm}
                        onChangeText={onCostPerKmChange}
                    />
                    <Input
                        textStyle={{ maxWidth: 150 }}
                        disabled={!allowEdit}
                        style={{ maxWidth: 150 }}
                        label='Covered distance (km)'
                        size='small'
                        placeholder='Covered distance'
                        value={coveredDistance}
                        onChangeText={onCoveredDistanceChange}
                    />
                </View>

                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                        <Text style={{ margin: 5 }} category='label'>Cost:</Text>
                        <Text style={{ margin: 5 }} category='p1'>
                            Excess: ${uncoveredCost.toFixed(2)}
                        </Text>
                        <Text style={{ margin: 5 }} category='p1'>
                            Total: ${totalCost.toFixed(2)}
                        </Text>
                        {calculationError && (
                            <Text style={{ margin: 5, color: 'red' }}>{calculationError}</Text>
                        )}
                    </View>
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                        <Toggle
                            style={{ margin: 5 }}
                            checked={addCoveredCostToBillBack}
                            onChange={onAddCoveredCostToBillBackChange}
                        >
                            Add covered cost to bill back
                        </Toggle>
                        <Text style={{ margin: 5 }}>Add to Items</Text>
                        <Button
                            style={{ margin: 5 }}
                            size='small'
                            appearance='outline'
                            status='success'
                            disabled={calculationError.length > 0 || !allowEdit}
                            onPress={handleAddTowingCostToItems}
                        >
                            <Icon name='plus-outline' width={20} height={20} fill='white' />
                        </Button>
                    </View>
                </View>
            </View>
        </Card>
    );
}; 