import React, { useState } from 'react';
import { View } from 'react-native';
import { Layout, Text, Input, Button, Card } from "@ui-kitten/components";
import { SERVICE_FIELD_TYPES } from '../../../models/Job.model';
import DMModal from '../../common/Modal';

interface NewFieldModalProps {
    onClose: any,
    showNewFieldModal: boolean,
    addField: Function
}

export const NewFieldModal = ({
    onClose,
    showNewFieldModal,
    addField
}: NewFieldModalProps) => {
    const [newField, setNewField] = useState("");
    const [newFieldType, setNewFieldType] = useState(SERVICE_FIELD_TYPES.input);

    const handleNewFieldSave = () => {
        addField(newField);
        onClose();
    }

    return (
        <DMModal
            visible={showNewFieldModal}
            onClose={onClose}
        >
            <Card
                disabled
                status='basic'
                header={() => <Text category="h6">New Field</Text>}
                footer={() => <View style={{ flexDirection: "row", justifyContent: "space-between", gap: 10 }}>
                    <Button appearance="ghost" status="danger" size='small' onPress={onClose}> Cancel </Button>
                    <Button appearance='filled' size='small' status='success' onPress={handleNewFieldSave}> Save </Button>
                </View>
                }
            >
                <View style={{ flex: 1 }}>
                    <Input
                        placeholder="Field Name"
                        value={newField}
                        onChangeText={setNewField}
                    />
                </View>
            </Card>
        </DMModal>
    )
} 