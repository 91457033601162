import React, { useState, useRef, useCallback, useEffect, useReducer } from 'react';
import {
    Image,
    AccessibilityRole,
    ImageProps,
    ImageStyle,
    StyleSheet,
    TouchableOpacity,
    View,
    ScrollView,
    FlatList,
    Alert,
    Clipboard,
    Text as RNText,
    useWindowDimensions,
    //@ts-ignore
} from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    // Modal,
} from "@ui-kitten/components";
import * as StorageController from '../../../functions/storageController';
import * as GoogleController from '../../../functions/google.controller';
import axios from 'axios';
import ErrorBoundary from '../../ErrorBoundary.component';
import DistanceQuoteTool from '../jobDistanceQuoteTool.component';
import AddressSearch from './addressSearch.component';
import { JobDetailMap } from './mapSelectLocation.component';
import { JobAddress } from '../../../models/Job.model';
import { Company, CompanySavedAddress } from '../../../models/Company.model';
import { JOB_LOCATION_TYPE } from '../../../models/Job.model';
import DMModal from '../../common/Modal';


const useInputState = (initialValue = '') => {
    const [value, setValue] = useState(initialValue);
    return { value, onChangeText: setValue };
};


interface AddressDetailsCardProps {
    style?: any;
    onSetAddress: (address: JobAddress) => void;
    onSetLocation: (location: any) => void;
    onSetAddressType?: (type: JOB_LOCATION_TYPE) => void;
    onSetSavedAddress?: (savedAddress: CompanySavedAddress) => void;
    address: JobAddress;
    addressDisplayValue: string;
    allowEdit?: boolean;
    collapsed?: boolean;
    addressName?: string;
    onToggleCollapse?: (collapsed: boolean) => void;
}

export const AddressDetailsCard = ({
    style = {},
    onSetAddress = (j) => { },
    onSetLocation = (j) => { },
    onSetAddressType = () => { },
    onSetSavedAddress = () => { },
    address = new JobAddress(),
    addressDisplayValue = "",
    allowEdit = true,
    collapsed = false,
    addressName = "Address",
    onToggleCollapse = () => { }
}: AddressDetailsCardProps) => {

    const isLocalClient = StorageController.getAppState().selectedMembership?.is_client

    const [isCollapsed, setIsCollapsed] = useState(collapsed);
    const [editAddress, setEditAddress] = useState(false);

    const unitNumberInputState = useInputState(address.unit_number);
    const streetNumberInputState = useInputState(address.street_number);
    const streetNameInputState = useInputState(address.street_name);
    const suburbInputState = useInputState(address.suburb);
    const cityInputState = useInputState(address.city);
    const postCodeInputState = useInputState(address.post_code);
    const stateInputState = useInputState(address.state);
    const countryInputState = useInputState(address.country);
    const descriptionInputState = useInputState(address.description);

    const [companyAddressBook, setCompanyAddressBook] = useState([] as CompanySavedAddress[]);
    const [showCompanyAddressBook, setShowCompanyAddressBook] = useState(false);

    useEffect(() => {
        const company = StorageController.getCurrentCompany();
        if (company) {
            setCompanyAddressBook(company.settings.address_book || []);
        }
    }, [])


    useEffect(() => {
        unitNumberInputState.onChangeText(address.unit_number);
        streetNumberInputState.onChangeText(address.street_number);
        streetNameInputState.onChangeText(address.street_name);
        suburbInputState.onChangeText(address.suburb);
        cityInputState.onChangeText(address.city);
        postCodeInputState.onChangeText(address.post_code);
        stateInputState.onChangeText(address.state);
        countryInputState.onChangeText(address.country);
        descriptionInputState.onChangeText(address.description);
    }, [address])

    useEffect(() => {
        setIsCollapsed(collapsed);
    }, [collapsed])


    const onBlur = () => {
        const _address = new JobAddress({
            unit_number: unitNumberInputState.value,
            street_number: streetNumberInputState.value,
            street_name: streetNameInputState.value,
            suburb: suburbInputState.value,
            city: cityInputState.value,
            post_code: postCodeInputState.value,
            state: stateInputState.value,
            country: countryInputState.value,
            description: descriptionInputState.value
        });
        onSetAddress(_address);
    }

    const styles = StyleSheet.create({
        addressDetailComponent: {
            flexBasis: '50%',
            borderBottomWidth: 1,
        },
        collapsedAddressDetailComponent: {
            flex: 1,
            borderBottomWidth: 1
        },
        collapsibleHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        }
    })


    const toggleCollapse = () => {
        const newCollapsed = !isCollapsed;
        onToggleCollapse(newCollapsed);
        setIsCollapsed(newCollapsed);
    };

    const Header = () => (
        <View style={styles.collapsibleHeader}>
            <Text style={{ alignSelf: 'center' }}>{addressName}</Text>
            {/* Open address book */}
            {!isLocalClient &&
                <Button
                    appearance='outline'
                    size='tiny'
                    status='info'
                    onPress={() => setShowCompanyAddressBook(true)}
                >
                    <>
                        <Icon name='book-open-outline' width={20} height={20} fill="white" />
                        <Text category='p1' style={{ fontSize: 12 }}>Open Address Book</Text>
                    </>
                </Button>
            }
            {/* Toggle collapse */}
            <Button
                onPress={toggleCollapse}
                appearance='outline'
                size='tiny'
                status='basic'
            >
                {isCollapsed ?
                    <Icon name='arrow-ios-downward-outline' width={20} height={20} fill="white" />
                    :
                    <Icon name='arrow-ios-upward-outline' width={20} height={20} fill="white" />
                }
            </Button>
        </View>
    );

    return (
        <Card
            status="success"
            header={Header}
            disabled={true}
            style={style}
        >
            <RenderAddressBookModal
                showCompanyAddressBook={showCompanyAddressBook}
                setShowCompanyAddressBook={setShowCompanyAddressBook}
                companyAddressBook={companyAddressBook}
                // onSetAddress={onSetAddress}
                // onSetLocation={onSetLocation}
                // onSetAddressType={onSetAddressType}
                onSetSavedAddress={onSetSavedAddress}
            />
            <View style={{ margin: -15 }}>

                {(!isCollapsed && allowEdit) &&
                    <AddressSearch
                        displayValue={addressDisplayValue}
                        onSetAddress={(value) => {
                            onSetAddress(value)
                        }}
                        allowEdit={true}
                        onSetLocation={(location) => {
                            onSetLocation(location)
                        }}
                    />
                }

                {isCollapsed &&
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        <View style={{ flexDirection: 'column', flex: 10 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.collapsedAddressDetailComponent}>
                                    <Text category='label'>Address:</Text>
                                    <Text style={{ fontSize: 12 }} numberOfLines={1} adjustsFontSizeToFit>
                                        <Icon name="pin-outline" width={15} height={15} fill='white' />
                                        {GoogleController.addressBuilder(address)}
                                    </Text>
                                    <Text style={{ fontSize: 12 }} numberOfLines={1} adjustsFontSizeToFit>
                                        <Icon name="smiling-face-outline" width={15} height={15} fill='white' />
                                        {address.description}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                }
                {(!isCollapsed && editAddress) &&
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 3 }}>
                        <Button
                            style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                            onPress={() => setEditAddress(false)}
                            appearance='ghost'
                            size='tiny'
                            status='warning'
                            accessoryLeft={(props: any) => <Icon {...props} name="edit-outline" />}
                        >
                            {/* <Icon name='edit-outline' width={10} height={10} /> */}
                        </Button>
                        <Input
                            size='small'
                            placeholder='Unit Number'
                            label='Unit Number'
                            // value={address.unit_number}
                            onBlur={onBlur}
                            {...unitNumberInputState}

                        />
                        <Input
                            size='small'
                            placeholder='Street Number'
                            label='Street Number'
                            // value={address.street_number}
                            onBlur={onBlur}
                            {...streetNumberInputState}

                        />
                        <Input
                            size='small'
                            placeholder='Street Name'
                            label='Street Name'
                            // value={address.street_name}
                            onBlur={onBlur}
                            {...streetNameInputState}

                        />
                        <Input
                            size='small'
                            placeholder='Suburb'
                            label='Suburb'
                            // value={address.suburb}
                            onBlur={onBlur}
                            {...suburbInputState}

                        />
                        <Input
                            size='small'
                            placeholder='City'
                            label='City'
                            // value={address.city}
                            onBlur={onBlur}
                            {...cityInputState}

                        />
                        <Input
                            size='small'
                            placeholder='Postal / Zip code'
                            label='Postal / Zip code'

                            // value={address.post_code}
                            onBlur={onBlur}
                            {...postCodeInputState}
                        />
                        <Input
                            size='small'
                            placeholder='State'
                            label='State'
                            // value={address.state}
                            onBlur={onBlur}
                            {...stateInputState}
                        />
                        <Input
                            size='small'
                            placeholder='Country'
                            label='Country'
                            // value={address.country}
                            onBlur={onBlur}
                            {...countryInputState}
                            style={{ paddingLeft: 24, paddingRight: 24 }}
                        />
                        {/* Description */}
                        <Input
                            size='small'
                            placeholder='Description'
                            label='Description'
                            // value={address.description}
                            onBlur={onBlur}
                            {...descriptionInputState}
                            style={{ paddingLeft: 24, paddingRight: 24 }}
                        />
                    </View>
                }
                {(!isCollapsed && !editAddress) &&
                    <View style={{ flexDirection: 'row', alignItems: 'flex-start' }}>
                        <Button
                            style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                            onPress={() => setEditAddress(true)}
                            appearance='ghost'
                            size='tiny'
                            status='warning'
                            disabled={!allowEdit}
                            accessoryLeft={(props: any) => <Icon {...props} name="edit-outline" />}
                        >
                            {/* <Icon name='edit-outline' width={10} height={10} /> */}
                        </Button>
                        <View style={{ flexDirection: 'column', flex: 1 }}>

                            <View style={styles.addressDetailComponent}>
                                <Text category='label'>Address:</Text>
                                <Text>{address.unit_number && address.unit_number + "/"}{address.street_number + " "}{address.street_name}</Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.addressDetailComponent}>
                                    <Text category='label'>Suburb: </Text>
                                    <Text>{address.suburb}</Text>
                                </View>
                                <View style={styles.addressDetailComponent}>
                                    <Text category='label'>City: </Text>
                                    <Text>{address.city}</Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                                <View style={styles.addressDetailComponent}>
                                    <Text category='label'>Post Code: </Text>
                                    <Text>{address.post_code}</Text>
                                </View>
                                <View style={styles.addressDetailComponent}>
                                    <Text category='label'>State: </Text>
                                    <Text>{address.state}</Text>
                                </View>
                            </View>
                            <View style={styles.addressDetailComponent}>
                                <Text category='label'>Description: </Text>
                                <Text>{address.description}</Text>
                            </View>
                        </View>
                    </View>
                }
            </View>
        </Card >
    );

}

interface RenderAddressBookModalProps {
    showCompanyAddressBook: boolean;
    setShowCompanyAddressBook: (value: boolean) => void;
    companyAddressBook: CompanySavedAddress[];
    onSetAddress?: (value: JobAddress) => void;
    onSetLocation?: (value: any) => void;
    onSetAddressType?: (value: JOB_LOCATION_TYPE) => void;
    onSetSavedAddress?: (value: CompanySavedAddress) => void;
}

const RenderAddressBookModal = ({
    showCompanyAddressBook,
    setShowCompanyAddressBook,
    companyAddressBook,
    onSetAddress,
    onSetLocation,
    onSetAddressType,
    onSetSavedAddress
}: RenderAddressBookModalProps) => {
    const { width, height } = useWindowDimensions();
    const [filteredAddresses, setFilteredAddresses] = useState(companyAddressBook as CompanySavedAddress[]);
    useEffect(() => {
        setFilteredAddresses(companyAddressBook);
    }, [companyAddressBook])
    const fuzzySearchAddress = (search: string) => {
        if (search.length == 0) {
            setFilteredAddresses(companyAddressBook);
            return;
        }
        const filtered = companyAddressBook.filter((item) => {
            const addrObj = new JobAddress(item.address);
            const addrString = addrObj.addressToString() + " " + addrObj.description + " " + item.name;
            return addrString.toLowerCase().includes(search.toLowerCase());
        });
        setFilteredAddresses(filtered);
    }

    const handleUseAddress = (address: CompanySavedAddress) => {
        if (!address.address.formatted_address || address.address.formatted_address == "") {
            let addrObj = new JobAddress(address.address);
            address.address.formatted_address = addrObj.addressToString();
        }
        onSetAddress && onSetAddress(address.address);
        onSetLocation && onSetLocation(address.location);
        onSetSavedAddress && onSetSavedAddress(address);
        setShowCompanyAddressBook(false);
    }

    return (
        <DMModal
            visible={showCompanyAddressBook}
            onClose={() => setShowCompanyAddressBook(false)}
        >
            <Card
                disabled
                status='info'
                header={() =>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Text>Company Address Book</Text>
                        <Button
                            style={{ position: 'absolute', right: 0, top: 0, zIndex: 10 }}
                            onPress={() => setShowCompanyAddressBook(false)}
                            appearance='outline'
                            size='tiny'
                            status='danger'
                        >
                            <Icon name='close-outline' fill="white" width={10} height={10} />
                        </Button>
                    </View>
                }
                style={{ minWidth: width * 0.5, maxWidth: width * 0.9, height: height * 0.7 }}
            >
                <View
                    style={{ minWidth: width * 0.5, maxWidth: width * 0.9, height: height * 0.7 }}
                >

                    <ScrollView style={{ flex: 1 }}>
                        <View style={{ flexDirection: 'column', gap: 5 }}>
                            <View style={{ flexDirection: 'column', gap: 3 }}>
                                <Input
                                    placeholder='Search Address Book'
                                    onChangeText={(value: string) => fuzzySearchAddress(value)}
                                />
                                {companyAddressBook.length == 0 &&
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text style={{ alignSelf: 'center', color: 'grey' }}>No addresses found</Text>
                                        <Text style={{ alignSelf: 'center', color: 'grey' }}>Add addresses in company settings</Text>
                                    </View>
                                }
                                <List
                                    data={filteredAddresses}
                                    ItemSeparatorComponent={() => <View style={{ height: 1, backgroundColor: 'grey' }}></View>}
                                    renderItem={({ item, index }: { item: CompanySavedAddress, index: number }) => {
                                        const addrObj = new JobAddress(item.address);
                                        const title = `${item.name} - ${item?.type?.toUpperCase() || "ADDRESS"}`;
                                        return (
                                            <ListItem
                                                onPress={() => { console.log(item) }}
                                                title={title}
                                                description={addrObj.addressToString() + " " + item.address.description}
                                                accessoryLeft={(props: any) => <Icon {...props} name='pin-outline' />}
                                                accessoryRight={(props: any) => <Button appearance='outline' size='tiny' status='info' onPress={() => {
                                                    // onSetAddress && onSetAddress(item.address);
                                                    // onSetLocation && onSetLocation(item.location);
                                                    // onSetAddressType && onSetAddressType(item.type);
                                                    // onSetSavedAddress && onSetSavedAddress(item);
                                                    // setShowCompanyAddressBook(false);
                                                    handleUseAddress(item);
                                                }}>Use</Button>}
                                            />
                                        )
                                    }}
                                />
                            </View>
                        </View>
                    </ScrollView>
                </View>
            </Card>
        </DMModal >
    )
}