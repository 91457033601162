import React from 'react';
import { View } from 'react-native';
import { Button, Text } from "@ui-kitten/components";
import { CounterBadge } from './components/CounterBadge';
import { Member } from '../../models/Member.model';
import { Job } from '../../models/Job.model';
import { Case } from '../../models/Case.model';
import { Company } from '../../models/Company.model';



export enum LIST_TABS {
    JOBS = "JOBS",
    SCHEDULED = "SCHEDULED",
    DRIVERS = "DRIVERS",
    HOLDINGS = "HOLDING",
    CASES = "CASES",
    ROUTES = "ROUTES",
    ORDERS = "ORDERS"
}


interface ListPanelTabsProps {
    listPanelIndex: LIST_TABS;
    setListPanelIndex: (tab: LIST_TABS) => void;
    enableHoldings?: boolean;
    enableCases?: boolean;
    enableRoutes?: boolean;
    enableOrders?: boolean;
    onRoutesTabSelected: () => void;
    scheduledJobsCount: number;
    onlineDriversCount: number;
    holdingsCount: number;
    ongoingCasesCount: number;
}

export const ListPanelTabs: React.FC<ListPanelTabsProps> = ({
    listPanelIndex,
    setListPanelIndex,
    enableHoldings = false,
    enableCases = false,
    enableRoutes = false,
    enableOrders = false,
    onRoutesTabSelected,
    scheduledJobsCount,
    onlineDriversCount,
    holdingsCount,
    ongoingCasesCount
}) => {
    return (
        <View style={{ flexDirection: 'row', gap: 4, marginBottom: -3 }}>
            <TabButton
                tab={LIST_TABS.JOBS}
                currentTab={listPanelIndex}
                onPress={() => setListPanelIndex(LIST_TABS.JOBS)}
                label="JOBS"
            />
            <TabButton
                tab={LIST_TABS.SCHEDULED}
                currentTab={listPanelIndex}
                onPress={() => setListPanelIndex(LIST_TABS.SCHEDULED)}
                label="SCHEDULED"
                badgeCount={scheduledJobsCount}
            />
            <TabButton
                tab={LIST_TABS.DRIVERS}
                currentTab={listPanelIndex}
                onPress={() => setListPanelIndex(LIST_TABS.DRIVERS)}
                label="DRIVERS"
                badgeCount={onlineDriversCount}
            />
            {enableHoldings && (
                <TabButton
                    tab={LIST_TABS.HOLDINGS}
                    currentTab={listPanelIndex}
                    onPress={() => setListPanelIndex(LIST_TABS.HOLDINGS)}
                    label="HOLDINGS"
                    badgeCount={holdingsCount}
                />
            )}
            {enableCases && (
                <TabButton
                    tab={LIST_TABS.CASES}
                    currentTab={listPanelIndex}
                    onPress={() => setListPanelIndex(LIST_TABS.CASES)}
                    label="CASES"
                    badgeCount={ongoingCasesCount}
                />
            )}
            {enableRoutes && (
                <TabButton
                    tab={LIST_TABS.ROUTES}
                    currentTab={listPanelIndex}
                    onPress={() => {
                        setListPanelIndex(LIST_TABS.ROUTES);
                        onRoutesTabSelected();
                    }}
                    label="ROUTES"
                />
            )}
            {enableOrders && (
                <TabButton
                    tab={LIST_TABS.ORDERS}
                    currentTab={listPanelIndex}
                    onPress={() => setListPanelIndex(LIST_TABS.ORDERS)}
                    label="ORDERS"
                />
            )}
        </View>
    );
};

interface TabButtonProps {
    tab: LIST_TABS;
    currentTab: LIST_TABS;
    onPress: () => void;
    label: string;
    badgeCount?: number;
}

const TabButton: React.FC<TabButtonProps> = ({ tab, currentTab, onPress, label, badgeCount }) => (
    <Button
        style={{ flex: 1 }}
        onPress={onPress}
        appearance={currentTab === tab ? "filled" : "outline"}
        status={currentTab === tab ? "success" : "basic"}
    >
        {/* @ts-ignore */}
        <Text>
            {label}
            {badgeCount !== undefined && badgeCount > 0 && (
                <View style={{ position: 'absolute', top: 0, right: 0 }}>
                    <CounterBadge status="assigned">{badgeCount}</CounterBadge>
                </View>
            )}
        </Text>
    </Button>
);