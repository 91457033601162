import { Job } from "../../models/Job.model";



export function calculatePercentage(partialValue: number, totalValue: number) {
    if (totalValue === 0) return "0%";
    return ((partialValue / totalValue) * 100).toFixed(2) + '%';
};

export function formatTime(milliseconds: number) {
    let seconds: any = Math.floor(milliseconds / 1000);
    let hours: any = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    let minutes: any = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    // Pad the minutes and seconds with leading zeros, if required
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    // if any are NaN, return 00:00:00
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        return "00:00:00";
    }

    // Format the time as hh:mm:ss
    let timeFormat = hours + ":" + minutes + ":" + seconds;

    return timeFormat;
};


export function formatDateTime(timestamp: number) {
    if (!timestamp) return "-- --"
    try {
        const time = Intl.DateTimeFormat(
            'en-AU',
            {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
            }
        ).format(new Date(timestamp))
        return time
    } catch (e) {
        return "-- --"
    }
}

export function convertToTime(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let mins = Math.floor(minutes % 60);
    return `${hours}:${mins < 10 ? '0' : ''}${mins}`;
}


// Helper function to calculate total callout fees
export function calculateTotalCalloutFees(jobs: Job[]) {
    return jobs.reduce((total, job) => {
        let rate = job.details?.client_rate?.cost || 0;
        if (isNaN(Number(rate))) return total;
        return total + Number(rate);
    }, 0);
}


/**
 * Calculate amount of jobs completed within Time in mins
 * @param {*} jobs 
 * @returns number of jobs completed within the specified time
 */
export function countJobsArrivedWithinTime(jobs: Job[], time = 60) {
    if (jobs.length === 0) return 0;
    let count = 0;
    jobs.forEach(job => {
        if (!job.arrived_time || !job.start_time) return;
        if (job.start_time == 0 || job.arrived_time == 0) return;
        if (job.arrived_time - job.start_time <= time * 60 * 1000) {
            count++;
        }
    });
    return count;
}

export function countNumberOfValidArrvalTimeJobs(jobs: Job[]) {
    if (jobs.length === 0) return 0;
    let count = 0;
    jobs.forEach(job => {
        if (!job.arrived_time || !job.start_time) return;
        if (job.start_time == 0 || job.arrived_time == 0) return;
        count++;
    });
    return count;
}


export function calculateAverageCreatedToPendingTime(jobs: Job[]) {
    if (!jobs || jobs?.length === 0) return 0;
    const totalJobTime = jobs.reduce((sum, job) =>
        sum + (job.pending_time as number - (job.start_time || job.pending_time as number)), 0);
    return totalJobTime / jobs.length;
}

export function calculateAverageArrivalToCompleteTime(jobs: Job[]) {
    if (!jobs || jobs?.length === 0) return 0;
    let countedJobs = 0;
    const totalJobTime = jobs.reduce((sum, job) => {
        const arrivedTime = job.arrived_time || 0;
        const pendingTime = job.pending_time || 0;
        if (!arrivedTime) {
            return sum
        }
        countedJobs++;
        return sum + (pendingTime - arrivedTime);
        // sum + (job.pending_time - job.arrived_time), 0);
    }, 0)
    // return totalJobTime / jobs.length;
    return totalJobTime / countedJobs;
}


export function calculateTotalBillBackItemsCost(jobs: Job[]) {
    if (!jobs || jobs?.length === 0) return 0;
    const totalBillBackItemsCost = jobs.reduce((sum, job) => {
        const _job = new Job(job)
        const billbackItemsCost = _job.getTotalBillBackItems().map(item => (item.cost * item.quantity)).reduce((a, b) => a + b, 0);
        return sum + billbackItemsCost;
    }, 0);

    return totalBillBackItemsCost;
}

export function calculateTotalCustomerItemsCost(jobs: Job[]) {
    if (!jobs || jobs?.length === 0) return 0;
    const totalCustomerPaidItemsCost = jobs.reduce((sum, job) => {
        const _job = new Job(job)
        const customerPaidItemsCost = _job.getTotalCustomerCostItems().map(item => (item.cost * item.quantity)).reduce((a, b) => a + b, 0);
        return sum + customerPaidItemsCost;
    }, 0);

    return totalCustomerPaidItemsCost.toFixed(2);
}



export function getLast10Weeks() {
    const today = new Date();
    const last10Weeks = [];
    for (let i = 0; i < 10; i++) {
        const startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() + 7 * i - 1));
        const endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (today.getDay() + 7 * i - 7));
        let s = new Date(startDate);
        let e = new Date(endDate);
        s.setHours(0, 0, 0, 0);
        e.setHours(23, 59, 59, 999);
        last10Weeks.push({ start: s, end: e });
    }

    return last10Weeks;
}

// Helper function to calculate average job time in minutes
export function calculateAverageJobTime(jobs: Job[]) {
    if (!jobs || jobs?.length === 0) return 0;
    const totalJobTime = jobs.reduce((sum, job) =>
        sum + (job.pending_time as number - job.start_time), 0);
    return totalJobTime / jobs.length;
}

export function calculateAverageCreatedToArrivalTime(jobs: Job[]) {
    if (!jobs || jobs?.length === 0) return 0;
    let countedJobs = 0;
    const totalJobTime = jobs.reduce((sum, job) => {
        const arrivedTime = job.arrived_time || 0;
        const acceptedTime = job.start_time || job.accepted_time || 0;
        if (!arrivedTime) {
            return sum
        }
        countedJobs++;
        return sum + (arrivedTime - acceptedTime);
    }, 0);
    // sum + ((job.arrived_time || job.pending_time) - (job.start_time || job.accepted_time)), 0);
    // return totalJobTime / jobs.length;
    return totalJobTime / countedJobs;
}


export function getDaysBetweenDates(startDate: Date, endDate: Date) {

    const days = [];
    const currentDate = new Date(startDate);
    if (!endDate) { return [currentDate] }
    while (currentDate <= endDate) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return days;
}


export function namedColor(index: number) {

    const CHART_COLORS = {
        red: 'rgb(255, 99, 132)',
        orange: 'rgb(255, 159, 64)',
        yellow: 'rgb(255, 205, 86)',
        green: 'rgb(75, 192, 192)',
        blue: 'rgb(54, 162, 235)',
        purple: 'rgb(153, 102, 255)',
        grey: 'rgb(201, 203, 207)'
    };
    const NAMED_COLORS = [
        CHART_COLORS.red,
        CHART_COLORS.orange,
        CHART_COLORS.yellow,
        CHART_COLORS.green,
        CHART_COLORS.blue,
        CHART_COLORS.purple,
        CHART_COLORS.grey,
    ];
    return NAMED_COLORS[index % NAMED_COLORS.length];
}
