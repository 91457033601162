import { GoogleMap, useJsApiLoader, Marker, InfoWindow, TransitLayer, DirectionsService, DirectionsRenderer, TrafficLayer, MarkerClusterer, Polygon, Polyline, OverlayView, HeatmapLayer, HeatmapLayerProps } from '@react-google-maps/api';
import React, { useState, useEffect, useRef, useReducer, useMemo, useCallback } from 'react';
//@ts-ignore
import { StyleSheet, View, ScrollView } from 'react-native';
// @ts-ignore
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Divider,
    Input,
    Select,
    SelectItem,
    useTheme,
    Layout,
    Text,
    List,
    ListItem,
    OverflowMenu,
    MenuItem,
    Card,
    Toggle,
    Radio,
    RadioGroup,
    Modal
} from '@ui-kitten/components';
import { Job, JobTowingDetails, TowingLeg } from '../../models/Job.model';
import { Member } from '../../models/Member.model';
import { Company } from '../../models/Company.model';
import * as StorageController from '../../functions/storageController';
import { darkMapStyles, lightMapStyles } from '../map/mapStyles';


// https://pyjun01.github.io/react-google-map-wrapper/docs/api/heatmap-layer/
/**
 * Displays a heatmap of all the jobs in the jobs prop
 */

// const { isLoaded } = useJsApiLoader({
//     id: 'google-map-script',
//     googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
//     libraries: ["places", "visualization"]  // Required for heatmap
// })
const mapLibs = ["places", "visualization"]

export const DashboardMap = ({
    jobs = [] as Job[],
    members = [] as Member[],
    company = {} as Company,
    selectedJob = {} as Job,

}) => {

    const [map, setMap] = useState(null);
    const [selected, setSelected] = useState(null);

    const containerStyle = {
        width: '100%',
        height: '100%',
    };

    const [center, setCenter] = useState({
        lat: StorageController.getCurrentCompany().settings.location?.coords?.lat || 0,
        lng: StorageController.getCurrentCompany().settings.location?.coords?.lng || 0
    });
    const [gradient, setGradient] = useState<string[] | null>(null);
    const [radius, setRadius] = useState<number | null>(15);
    const [opacity, setOpacity] = useState<number | null>(0.6);
    const [maxIntensity, setMaxIntensity] = useState<number>(3);
    const [mapStyle, setMapStyle] = useState(darkMapStyles);
    const [isDarkMap, setIsDarkMap] = useState(true);

    useEffect(() => {
        if (company.settings.location?.coords) {
            setCenter({
                lat: company.settings.location.coords.lat,
                lng: company.settings.location.coords.lng
            })
        }
    }, [company])


    const toggleMapStyle = () => {
        if (isDarkMap) {
            setMapStyle(lightMapStyles);
            setOpacity(0.9);
            setIsDarkMap(false);
        } else {
            setMapStyle(darkMapStyles);
            setOpacity(0.6);
            setIsDarkMap(true);
        }
    }

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDySIh3VZu-dtIFF9lzZC2RLJa_bLKtsvM",
        //@ts-ignore
        libraries: mapLibs  // Required for heatmap
    })

    const changeGradient = () => {
        setGradient((prev) => (prev ? null : customGradient));
    };

    const customGradient = [
        'rgba(0, 255, 255, 0)',
        'rgba(0, 255, 255, 1)',
        'rgba(191, 0, 31, 1)',
        'rgba(255, 0, 0, 1)',
    ];

    const options = {
        disableDefaultUI: true,
        zoomControl: true,
        styles: mapStyle
    }

    const onMapLoad = useCallback((map: any) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null);
    }, []);




    const RenderHeatmapLayer = ({ jobs = [] as Job[], gradient = [] as any, opacity = 0.2 as number, radius = 20 as number }) => {
        if (!jobs || jobs.length == 0) return null;
        return (
            <HeatmapLayer
                options={{
                    maxIntensity: maxIntensity,
                    // dissipating: true,
                    gradient,
                    radius,
                    opacity
                }}
                // @ts-ignore
                data={jobs.map((job: Job) => {
                    if (!job.details.location) return null;
                    if (!job.details.location.lat || !job.details.location.lng) return null;
                    return {
                        location: new google.maps.LatLng(job.details.location.lat, job.details.location.lng),
                        weight: 1
                    }
                })}
            />
        )
    }

    const [showControls, setShowControls] = useState(false);



    const renderMap = () => {
        return (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                onLoad={onMapLoad}
                onUnmount={onUnmount}
                options={options}
            >
                <RenderMapControls
                    showControls={showControls}
                    setShowControls={setShowControls}
                    setRadius={setRadius}
                    setMaxIntensity={setMaxIntensity}
                    isDarkMap={isDarkMap}
                    toggleMapStyle={toggleMapStyle}
                />
                <RenderHeatmapLayer
                    jobs={jobs}
                    gradient={gradient}
                    radius={radius || 10}
                    opacity={opacity || 0.5}
                />
            </GoogleMap>
        )
    }


    const RenderMapControls = ({
        showControls = false,
        setShowControls = (showControls: boolean) => { },
        setRadius = (radius: number) => { },
        setMaxIntensity = (maxIntensity: number) => { },
        isDarkMap = false,
        toggleMapStyle = () => { }
    }) => {
        const theme = useTheme();

        return (
            <Layout style={{ position: 'absolute', top: 10, right: 10, zIndex: 1 }}>
                <Button
                    appearance="ghost"
                    status="basic"
                    size="small"
                    style={{ marginBottom: showControls ? 10 : 0 }}
                    accessoryLeft={(props) => (
                        <Icon
                            {...props}
                            name={showControls ? 'close-outline' : 'settings-2-outline'}
                        />
                    )}
                    onPress={() => setShowControls(!showControls)}
                />

                {showControls && (
                    <Layout style={{
                        padding: 10,
                        borderRadius: 8,
                        backgroundColor: theme['background-basic-color-1'],
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        elevation: 5,
                    }}>
                        <Text category="label">Heat Size</Text>
                        <input
                            type="range"
                            min="1"
                            max="40"
                            value={radius || 15}
                            onChange={(e) => setRadius(parseInt(e.target.value))}
                        />
                        <Text category="label">Heat Intensity</Text>
                        <input
                            type="range"
                            min="1"
                            max="9"
                            step="1"
                            value={10 - maxIntensity || 10}
                            onChange={(e) => setMaxIntensity(10 - parseInt(e.target.value))}
                        />
                        <Toggle
                            checked={isDarkMap}
                            onChange={toggleMapStyle}
                        >
                            Dark Map
                        </Toggle>
                        <Toggle
                            style={{ marginLeft: -10 }}
                            checked={gradient ? true : false}
                            onChange={changeGradient}
                        >
                            Colours
                        </Toggle>
                    </Layout>
                )}
            </Layout>
        );
    };

    return isLoaded ? renderMap() : null;

}


