import React, { useState } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { Button, Input, Text, Icon, Layout, Divider, List } from '@ui-kitten/components';
import { InventoryItem } from '../../models/Inventory.model';
import Toast from 'react-native-toast-message';


interface BulkItemImportProps {
    categoryId: string;
    companyId: string;
    onImportComplete: (items: InventoryItem[]) => void;
    onClose: () => void;
}

interface ItemRow {
    name: string;
    description?: string;
    default_price: number;
    sku?: string;
    purchase_price?: number;
    default_sale_price?: number;
}


export const BulkItemImport = ({ categoryId, companyId, onImportComplete, onClose }: BulkItemImportProps) => {
    const [items, setItems] = useState<ItemRow[]>([]);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [csvText, setCsvText] = useState('');
    const [isImporting, setIsImporting] = useState(false);
    const [importProgress, setImportProgress] = useState({ processed: 0, total: 0 });

    const parseCsvText = (text: string) => {
        try {
            // Split by newlines and filter out empty lines
            const lines = text.split('\n').filter(line => line.trim());

            // Get headers from first line
            const headers = lines[0].split(',').map(h => h.trim().toLowerCase());

            // Parse each data line
            const parsedItems = lines.slice(1).map(line => {
                const values = line.split(',').map(v => v.trim());
                const item: any = {};

                headers.forEach((header, index) => {
                    if (header === 'default_price' || header === 'purchase_price' || header === 'default_sale_price') {
                        item[header] = parseFloat(values[index]) || 0;
                    } else {
                        item[header] = values[index] || '';
                    }
                });

                return {
                    name: item.name || '',
                    description: item.description || '',
                    default_price: item.default_price || 0,
                    sku: item.sku || generateUUID(),
                    purchase_price: item.purchase_price || 0,
                    default_sale_price: item.default_sale_price || item.default_price || 0,
                };
            });

            setItems(parsedItems);
            Toast.show({
                type: 'success',
                text1: `Parsed ${parsedItems.length} items`,
            });
        } catch (error) {
            Toast.show({
                type: 'error',
                text1: 'Error parsing CSV text',
                text2: 'Please check the format and try again',
            });
        }
    };

    const addEmptyRow = () => {
        setItems([...items, {
            name: '',
            description: '',
            default_price: 0,
            sku: '',
            purchase_price: 0,
            default_sale_price: 0,
        }]);
    };

    const updateItem = (index: number, field: keyof ItemRow, value: string | number) => {
        const newItems = [...items];
        newItems[index] = {
            ...newItems[index],
            [field]: value,
        };
        setItems(newItems);
    };

    const removeItem = (index: number) => {
        setItems(items.filter((_, i) => i !== index));
    };

    const validateItems = (): boolean => {
        const newErrors: { [key: string]: string } = {};
        let isValid = true;

        items.forEach((item, index) => {
            if (!item.name) {
                newErrors[`${index}-name`] = 'Name is required';
                isValid = false;
            }
            if (item.default_price < 0) {
                newErrors[`${index}-price`] = 'Price must be 0 or greater';
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleImport = () => {
        if (!validateItems()) return;
        setIsImporting(true);
        setImportProgress({ processed: 0, total: items.length });

        const inventoryItems = items.map(item => new InventoryItem({
            company_id: companyId,
            inventory_category_id: categoryId,
            name: item.name,
            description: item.description,
            default_price: item.default_price,
            sku: item.sku,
            purchase_price: item.purchase_price,
            default_sale_price: item.default_sale_price || item.default_price,
        }));

        onImportComplete(inventoryItems);
    };

    const renderItem = ({ item, index }: { item: ItemRow; index: number }) => (
        <Layout style={styles.itemRow} level='2'>
            <Input
                style={styles.input}
                placeholder="Name"
                value={item.name}
                onChangeText={(value) => updateItem(index, 'name', value)}
                status={errors[`${index}-name`] ? 'danger' : 'basic'}
                caption={errors[`${index}-name`]}
            />
            <Input
                style={styles.input}
                placeholder="Description"
                value={item.description}
                onChangeText={(value) => updateItem(index, 'description', value)}
            />
            <Input
                style={styles.input}
                placeholder="Price"
                value={item.default_price.toString()}
                onChangeText={(value) => updateItem(index, 'default_price', parseFloat(value) || 0)}
                keyboardType="numeric"
                status={errors[`${index}-price`] ? 'danger' : 'basic'}
                caption={errors[`${index}-price`]}
            />
            <Input
                style={styles.input}
                placeholder="SKU"
                value={item.sku}
                onChangeText={(value) => updateItem(index, 'sku', value)}
            />
            <Button
                size='small'
                status='danger'
                appearance='ghost'
                accessoryLeft={<Icon name='trash-2-outline' />}
                onPress={() => removeItem(index)}
            />
        </Layout>
    );

    return (
        <View style={styles.container}>
            <Text category='h6'>Bulk Import Items</Text>
            <View style={styles.csvContainer}>
                <Input
                    multiline
                    textStyle={{ minHeight: 100 }}
                    placeholder="Paste CSV data here (include headers)&#10;name,description,default_price,sku,purchase_price,default_sale_price"
                    value={csvText}
                    onChangeText={setCsvText}
                />
                <Button
                    onPress={() => parseCsvText(csvText)}
                    style={styles.parseButton}
                >
                    Parse CSV
                </Button>
            </View>
            <Text category='label' appearance='hint' style={styles.hint}>
                CSV format: name, description, default_price, sku, purchase_price, default_sale_price
            </Text>
            <Button onPress={addEmptyRow} accessoryLeft={<Icon name='plus-outline' />}>
                Add Row
            </Button>
            <List
                data={items}
                renderItem={renderItem}
                ItemSeparatorComponent={Divider}
                style={styles.list}
            />
            <View style={styles.buttonContainer}>
                <Button
                    appearance='ghost'
                    status='basic'
                    onPress={onClose}
                    disabled={isImporting}
                >
                    Cancel
                </Button>
                {isImporting ? (
                    <View style={styles.progressContainer}>
                        <ActivityIndicator size="small" />
                        <Text category='label' style={styles.progressText}>
                            Processing: {importProgress.total} items
                        </Text>
                    </View>
                ) : (
                    <Button
                        onPress={handleImport}
                        disabled={items.length === 0}
                    >
                        Import Items
                    </Button>
                )}
            </View>
        </View>
    );
};

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


const styles = StyleSheet.create({
    container: {
        padding: 16,
        gap: 16,
    },
    csvContainer: {
        gap: 8,
    },
    parseButton: {
        alignSelf: 'flex-end',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 8,
    },
    hint: {
        fontSize: 12,
        marginTop: 4,
    },
    list: {
        maxHeight: 400,
    },
    itemRow: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 8,
        gap: 8,
    },
    input: {
        flex: 1,
    },
    progressContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
    },
    progressText: {
        marginLeft: 8,
    },
}); 