import React, { useState, useEffect, useRef, useReducer, useCallback, Profiler, useMemo, memo, useContext } from 'react';
//@ts-ignore
import { StyleSheet, TouchableOpacity, View, Platform, ScrollView, Dimensions, useWindowDimensions } from "react-native";
import {
    ApplicationProvider,
    Button,
    Icon,
    IconRegistry,
    Layout,
    Text,
    List,
    ListItem,
    Card,
    Input,
    useTheme,
    Select,
    SelectItem,
    Toggle,
    OverflowMenu,
    MenuItem,
    Modal,
    //@ts-ignore
    Spinner,
    Radio,
    RadioGroup,
    Divider
} from "@ui-kitten/components";
import * as ImagePicker from 'expo-image-picker';
import { SafeAreaView } from 'react-native-safe-area-context';
import AssignJobList from '../assignJobList.component';
import AssignJobModal from '../modals/assignJobModal.component';
import { ServicesContainer } from '../services/servicesContainer.component';
import * as JobsController from '../../../functions/job.controller'
import * as MemberController from '../../../functions/membership.controller'
import * as ClientController from '../../../functions/client.controller'
import * as UpdateController from '../../../functions/update.controller'
import * as CompanyController from '../../../functions/company.controller'
import * as StorageController from '../../../functions/storageController'
import * as GoogleController from '../../../functions/google.controller'
import * as HoldingController from '../../../functions/holding.controller'
//@ts-ignore
import debounce from 'lodash.debounce';
import "react-datepicker/dist/react-datepicker.css";
import ErrorBoundary from '../../ErrorBoundary.component';
import { JobDetailImages } from '../jobDetailsImages.component';
import { event, set } from 'react-native-reanimated';
import NotificationContext from '../../../context/notifications.context';
import { Toast } from 'react-native-toast-message/lib/src/Toast';
import AddressSearch from './addressSearch.component';
import { JobDetailMap } from './mapSelectLocation.component';

// import { AddressDetailsCard } from './addressDetailsCard.component';
import { ClientDetailsCard } from './ClientDetailsCard.component';
import { CustomerDetailsCard } from './customerDetailsCard.component';
// import { ItemsDetailsCard } from './itemsDetailsCard.component';
import { ImageDetailsCard } from './imageDetailsCard.component';
import { OptionsDetailsCard } from './optionsDetailsCard.component';
import { OtherDetailsCard } from './otherDetailsCard.component';
import { PaymentDetailsCard } from './paymentDetailsCard.component';
import { ReportDetailsCard } from './reportDetailsCard.component';
import { ServiceDetailsCard } from './serviceDetailsCard.component';
import { TimeDetailsCard } from './timeDetailsCard.component';
import { VehicleDetailsCard } from './vehicleDetailsCard.component';
import { LogDetailsCard } from './logDetailsCard.component';
import { VendorDetailsCard } from './vendorDetailsCard.component';
import { InvoicedTotalCard } from './jobInvoicedTotalDetailsCard.component';
import { TransferJobComponent } from '../transfer/transferJob.component';

import { LocationDetailsCard } from './locationDetailsCard.component';
import { TowingDetailsCard } from '../newJobComponents/towingDetailsCard.component';
import { TowingLocationDetailsCard } from '../newJobComponents/towingLocationDetailsCard.component';
import { InventoryDetailsCard } from './inventory/inventoryDetailsCard.component';
import { Job, JobCustomerDetails, JobTowingDetails, Service, TowingLocation, JobVehicleDetails, JobLocation, JobAddress, JOB_STATUS, JobNote } from '../../../models/Job.model';
import { LineItem } from '../../../models/LineItem.model';
import { Member } from '../../../models/Member.model';
import { Client } from '../../../models/Client.model';
import { JobNotesList } from './jobNotes.component';
import { JobDocuments } from './jobDocuments.component';
import { CopyJobComponent } from '../copyJob/copyJob.component';
import { JobOptionsMenu } from './jobOptionsMenu.component';
import { useAppStateChange, IAction } from '../../../hooks/appStateChange.hook';
import SendJobEmails from '../email/sendJobEmails.component';
import DMModal from '../../common/Modal';
import { LoadingSpinner } from '../../common/LoadingSpinner';
import { ConvertJobComponent } from '../convert/convertJob.component';





// const { windowWidth, height } = Dimensions.get('window');

const windowCollapseWidth = 1200;

interface JobDetailContainerProps {
    jobViewedBy?: "vendor" | "client" | "local_client";
    fn_setJobPending?: (j: Job) => void;
    fn_setJobComplete?: (j: Job) => void;
    fn_setJobAssigned?: (j: Job) => void;
    fn_setJobUnassigned?: (j: Job) => void;
    fn_cancelJob?: (j: Job) => void;
    fn_closeJobDetailsModal?: () => void;
    fn_onEditJob?: (j: Job) => void;
    fn_updateJobs?: (j?: Job) => void;
    selectedJob?: Job;
}

export const JobDetailContainer = ({
    jobViewedBy = "vendor", // "client" company, "vendor" or "local_client"
    fn_setJobPending = (j: Job) => { },
    fn_setJobComplete = (j: Job) => { },
    fn_setJobAssigned = (j: Job) => { },
    fn_setJobUnassigned = (j: Job) => { },
    fn_cancelJob = (j: Job) => { },
    fn_closeJobDetailsModal = () => { },
    fn_onEditJob = (j: Job) => { },
    fn_updateJobs = (j: Job = null as any) => { },
    selectedJob = {} as Job,
}: JobDetailContainerProps) => {

    // const { showNotification } = useContext(NotificationContext);
    //@ts-ignore
    const theme = useTheme() as any;
    // const innerViewRef = useRef(null);
    const [_width, setWidth] = useState(Dimensions.get('window').width);
    const [_height, setHeight] = useState(Dimensions.get('window').height);
    const [loaded, setLoaded] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false)

    // JOB STATES
    const [JobState, setJob] = useState(new Job(selectedJob) as Job);
    const jobStateRef = useRef(JobState as Job);
    // const memoizedJob = useMemo(() => new Job(JobState), [JobState]);
    const [viewedBy, setViewedBy] = useState(jobViewedBy || "vendor" as any);
    const [jobLogs, setJobLogs] = useState([] as any);
    // const jobUpdatesRef = useRef({}); // use this to update specific fields in the job object, otherwide it overrides the entire job object on the server
    // const [location, setLocation] = useState(null as any);

    const [images, setImages] = useState([] as any); // loading job images
    const [localImages, setLocalImages] = useState([] as any); // local images to upload

    // Drivers
    const [drivers, setDrivers] = useState([] as Member[]);

    // CLIENTS
    const [clients, setClients] = useState([] as Client[]);

    // EVENTS 
    const eventsRegistered = useRef(false as boolean);

    // Services
    // const [services, setServices] = useState([] as Service[]); // loading company services

    //options
    const [showRoadsideOptions, setShowRoadsideOptions] = useState(false)
    const [showTowingOptions, setShowTowingOptions] = useState(false)
    const [showTransportOptions, setShowTransportOptions] = useState(false)

    // Get screenDimensions
    const windowWidth = useWindowDimensions().width
    const windowHeight = useWindowDimensions().height

    const [showImagesModal, setShowImagesModal] = useState(false)
    const [showLogsModal, setShowLogsModal] = useState(false)

    // Copy Job
    // const [showCopyJobModal, setShowCopyJobModal] = useState(false)

    // Send Job Emails
    const [showSendJobEmails, setShowSendJobEmails] = useState(false);


    const [showJobOptionsMemnu, setShowJobOptionsMenu] = useState(false)

    const [isJobOwner, setIsJobOwner] = useState(true)

    useEffect(() => {
        const company_id = StorageController.getCurrentCompany()._id
        const job_company_id = selectedJob.company_id
        setIsJobOwner(company_id == job_company_id)
    }, [selectedJob])


    useEffect(() => {
        //  setLoaded(false)
        load("Use effect 146")
        if (!eventsRegistered.current) {
            eventsRegistered.current = true;
        }
        return () => {
            if (eventsRegistered.current) {
                eventsRegistered.current = false;
            }
            setLoaded(false)
        }
    }, [selectedJob]);

    //===============================================
    //===============================================
    //===============================================
    //INIT LOAD
    //===============================================
    //===============================================
    //===============================================
    const load = async (caller = null as any, reload = false) => {
        try {
            let _job = selectedJob as Job
            console.log("🚀============== ~ file: jobDetailContainer.component.tsx:208 ~ load ~ job🚀==============", _job)
            if (reload) {
                const job = await JobsController.getJobById(selectedJob._id) as Job
                _job = job
            }
            if (!_job) {
                return
            }
            StorageController.appState.selectedJob = _job;
            loadOptions(_job)
            const _drivers = await MemberController.getDriversByCompanyId(_job.company_id || "") as Member[]
            const logs = await JobsController.getJobLogsByJobId(_job._id)
            if (viewedBy == "vendor" || viewedBy == "local_client") {
                loadClientsList()
            }
            if (viewedBy == "client") {
                // load vendor list
            }
            if (_drivers && _drivers.length > 0) {
                setDrivers(_drivers)
            }
            if (_job.client_id) {
                const selectedClient = StorageController.getCurrentCompany().clients?.find((client) => client._id == _job.client_id)
                if (selectedClient) {
                    onSetSelectedClient(selectedClient)
                }
            }
            loadImages(_job)
            // loadLocation(_job)
            setJobLogs(logs)
            // loadServices()
            // setJob(job)
            onSetJob(_job, "load()")
            setLoaded(true)
        } catch (err) {
            console.log(err)
        }
    }



    const loadClientsList = async () => {
        try {
            let _clients = StorageController.getAppState().clients
            if (viewedBy == "vendor") {
                setClients(_clients)
            } else {
                // local client
                let selectedMembership = StorageController.getAppState().selectedMembership
                if (!selectedMembership) return
                _clients = _clients.filter((client) => client._id == selectedMembership?.client_id)
                setClients(_clients)
            }
        } catch (err) {
            console.log(err)
        }
    }

    // const loadServices = async () => {
    //     let _services = StorageController.getCurrentCompany().services
    //     setServices(_services)
    // }

    // const loadLocation = async (_job: Job) => {
    //     try {

    //         const loc = {
    //             lat: _job.details?.location?.lat,
    //             lng: _job.details?.location?.lng || _job.details.location?.lon
    //         }
    //         setLocation(loc)
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    const loadImages = async (_job: Job) => {
        try {
            let _images = await JobsController.getImagesByJobId(_job._id)
            setImages(_images)
        } catch (e) {
            console.log(e)
        }
    }

    /**
     * Load options from job object
     * Allows for roadside vehicle options to display for older jobs
     */
    const loadOptions = async (_job: Job) => {
        try {
            // get options object
            if (!_job.details?.options) {
                setShowRoadsideOptions(true)
                return
            } else {
                const options = _job.details?.options
                setShowRoadsideOptions(!!options.roadside_job_options)
                setShowTowingOptions(!!options.towing_job_options)
                setShowTransportOptions(!!options.transport_job_options)
            }
            // log the options set
        } catch (e) {
            console.log(e)
        }
    }






    //===============================================
    //===============================================
    //===============================================
    //UPDATE STATES
    //===============================================
    //===============================================
    //===============================================


    const onSetJob = async (job: Job, caller = null as any) => {
        // ensure job is type of Job
        if (!job) return
        let _Job = new Job(job)
        // if (typeof job === "object" && job.constructor.name !== "Job") {
        //     job = new Job(job)
        // }
        // job.details.comments = commentsInputRef.current
        jobStateRef.current = _Job
        setJob(job)
    }

    const onSetVendorEta = async (eta: number) => {
        // console.log("onSetVendorEta", eta)
        let job = JobState
        job.vendor_eta = eta
        onSetJob(job, "onSetVendorEta")
    }

    const [selectedClient, setSelectedClient] = useState<Client | null>(null);
    const selectedClientRef = useRef<Client | null>(null);
    const onSetSelectedClient = (client: Client) => {
        setSelectedClient(new Client(client))
        selectedClientRef.current = new Client(client)
    }


    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // CUSTOMER
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////


    const onSetCustomerDetails = (updatedDetails: JobCustomerDetails) => {
        let _customer_details: JobCustomerDetails = jobStateRef.current.details.customer_details
        _customer_details = updatedDetails
        let _Job = jobStateRef.current
        _Job.details.customer_details = _customer_details
        onSetJob(_Job, "onSetCustomerDetails")
    };




    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // VEHICLE
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////

    const onSetVehicleDetails = (updatedDetails: JobVehicleDetails) => {
        let _vehicle_details: JobVehicleDetails = jobStateRef.current.details.vehicle_details
        _vehicle_details = updatedDetails
        let _Job = jobStateRef.current
        _Job.details.vehicle_details = _vehicle_details
        onSetJob(_Job, "onSetVehicleDetails")
    }

    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    // TOWING OPTIONS
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////
    const towing_details_ref = useRef(new JobTowingDetails(JobState?.details?.towing_details || {}));

    const onSetTowingDetails = (field: keyof JobTowingDetails, value: any) => {
        let _towing_details = new JobTowingDetails(JobState?.details?.towing_details)
        //@ts-ignore TODO: why?
        _towing_details[field] = value
        JobState.details.towing_details = _towing_details
        towing_details_ref.current = _towing_details
        onSetJob(JobState, "onSetTowingDetails")
    }

    /**
     * 
     * @param {JobTowingDetails} locationDetailsObj 
     */
    const onSetTowingLocationDetails = (locationDetailsObj: JobTowingDetails) => {
        // Merge location details with towing details
        let _towing_details = new JobTowingDetails(jobStateRef.current?.details?.towing_details) // get existing details
        _towing_details.towing_locations = locationDetailsObj.towing_locations
        _towing_details.towing_legs = locationDetailsObj.towing_legs
        _towing_details.distance_kms = locationDetailsObj.distance_kms
        _towing_details.duration_seconds = locationDetailsObj.duration_seconds
        _towing_details.cost_per_km = locationDetailsObj.cost_per_km
        _towing_details.covered_distance_kms = locationDetailsObj.covered_distance_kms
        _towing_details.total_cost = locationDetailsObj.total_cost
        _towing_details.original_dropoff_towing_location = locationDetailsObj.original_dropoff_towing_location
        _towing_details.direction_polylines = locationDetailsObj.direction_polylines
        _towing_details.directions_polyline = locationDetailsObj.directions_polyline

        jobStateRef.current.details.location = _towing_details.towing_locations[0]?.location
        jobStateRef.current.details.address = _towing_details.towing_locations[0]?.address
        jobStateRef.current.details.towing_details = _towing_details
        towing_details_ref.current = _towing_details
        onSetJob(jobStateRef.current, "onSetTowingLocationDetails")
    }

    const addTowingDistanceItems = (towingItems: LineItem[]) => {
        try {

            let _Job = JobState
            towingItems.forEach(towingItem => {
                JobState.addLineItem(towingItem)
            })
            onSetJob(_Job, "addTowingDistanceItem")
            Toast.show({
                text1: 'Towing Distance Added',
                text2: `Towing distance items have been added to the job`,
                type: 'success',
                position: 'bottom'
            });
        } catch (err) {
            console.log(err)
        }
    }



    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// COMMENTS
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */
    const debounceComments = useCallback(debounce((comments: string) => onSetComments(comments), 500), []);
    const onSetComments = (comments: string) => {
        let _Job = jobStateRef.current
        _Job.details.comments = comments
        onSetJob(_Job)
    }

    //===============================================
    //===============================================
    //===============================================
    //EVENT REGISTRATION
    //===============================================
    //===============================================
    //===============================================



    //debounce update job load() from handleJobUpdateEvent. takes the latest update and ensures it is only called once every 500ms
    const debouncedLoad = useCallback(debounce((caller: any, reload: boolean = false) => load(caller || "", reload), 500), []);

    const handleJobUpdatedEvent = useCallback(
        (action: any) => {
            // console.log("=== JOB DETAILS === JOB UPDATED EVENT", action);
            if (!action.data) return;
            let job_id = "";
            if (action.data.hasOwnProperty('job_id')) {
                job_id = action.data.job_id;
            } else if (action.data.hasOwnProperty('job')) {
                job_id = action.data.job._id;
                // console.log("=== JOB DETAILS === JOB UPDATED EVENT", action.data.job._id, Job._id);
            }
            if (job_id === JobState._id) {
                // console.log("=== JOB DETAILS === JOB UPDATED EVENT - MATCHED JOB");
                debouncedLoad("Handle Job Updated", true)
                // JobsController.getJobById(job_id).then((job) => {
                //     // console.log('Updating job details:', job)
                //     StorageController.getAppState().selectedJob = job;
                //     // load("Handle Job Updated")
                // });
            }
        },
        [JobState]
    );

    const handleSwitchCompany = (action: any) => {
        fn_closeJobDetailsModal()
    }

    const { dispatchEventStateChange } = useAppStateChange({
        onUpdatedSelectedCompany: handleSwitchCompany,
        onUpdatedJobs: handleJobUpdatedEvent
    })




    //===============================================
    //===============================================
    //===============================================
    //SAVE AND CLOSE
    //===============================================
    //===============================================
    //===============================================
    const [showSavedModal, setShowSavedModal] = useState(false)

    const saveJob = async (close = false, setComplete = false) => {
        // let _job = await JobsController.updateJob(Job._id, jobUpdatesRef.current)
        // jobStateRef.current.details.comments = commentsInputRef.current
        let _job = await JobsController.updateJob(jobStateRef.current)
        if (_job) {
            await uploadImages()
            onSetJob(_job)
            fn_onEditJob(_job)
            fn_updateJobs(_job)
            updateJobInLocalStorage(_job)
        }
        if (setComplete) {
            await setJobComplete()
        }
        if (close) {
            closeDetails()
        }
    }

    const closeDetails = () => {
        fn_closeJobDetailsModal()
    }

    const RenderSavedModal = () => {
        return (
            <DMModal
                visible={showSavedModal}
                onClose={() => setShowSavedModal(false)}>
                <Card disabled={true}>
                    <Text>Job Saved</Text>
                    <Button onPress={() => {
                        setShowSavedModal(false)
                    }}>OK</Button>
                </Card>
            </DMModal>
        )
    }

    //===============================================
    //===============================================
    //===============================================
    //IMAGE Handling
    //===============================================
    //===============================================
    //===============================================

    const uploadImages = async () => {

        if (localImages.length === 0) {
            return []
        }
        let _images = await JobsController.uploadImages(localImages)
        return
    }

    //===============================================
    //===============================================
    //===============================================
    //CANCEL JOB
    //===============================================
    //===============================================
    //===============================================

    const [showCancelJobModal, setShowCancelJobModal] = useState(false)
    const CancelJobModal = () => {
        return (
            <DMModal
                visible={showCancelJobModal}
                onClose={() => setShowCancelJobModal(false)}>
                <Card disabled={true} style={{ flex: 1 }}>
                    <Text>Are you sure you want to cancel this job?</Text>
                    <Button
                        status='danger'
                        onPress={() => { cancelJob(); setShowCancelJobModal(false) }}>
                        CANCEL JOB
                    </Button>
                    <Layout></Layout>
                    <Button
                        status='primary'
                        onPress={() => setShowCancelJobModal(false)}>
                        DISMISS
                    </Button>
                </Card>
            </DMModal>
        )
    }






    //===============================================
    //===============================================
    //===============================================
    //UTILS
    //===============================================
    //===============================================
    //===============================================

    const getJobCreatedBy = () => {
        if (JobState.is_client_created && JobState.company_id == StorageController.getCurrentCompany()._id) {
            return "local_client"
        }
        else if (JobState.is_client_created && JobState.company_id != StorageController.getCurrentCompany()._id) {
            return "client"
        }
        else {
            return "vendor"
        }
    }



    const capitaliseAndRemoveSpecialChars = (str: string) => {
        // remove _ and replace with space
        str = str.replace(/_/g, " ")
        // capitalise first letter of each word
        str = str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
        return str
    }

    const generateFriendlyJobNumber = (job_id: string, createdAt: string, jobCount: number) => {
        //`${year}_${month}_${jobNoDecimal}`;
        const date = new Date(createdAt);
        let jobnumber = ""
        let jobNo = ""
        let jobNoDecimal = 0
        let jobNumber = ""
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        if (jobCount) {
            jobCount = jobCount % 100000;
            jobnumber += jobCount.toString().padStart(5, "0");
            jobNumber = `${year}-${month}-${jobnumber}`;
        } else {
            jobnumber = ""
            jobNo = job_id?.substr(-4)
            jobNoDecimal = parseInt(jobNo, 16);
            jobNumber = `${year}_${month}_${jobNoDecimal}`;
        }
        return jobNumber;
    }

    const renderNested = (object: any, index: number) => {
        const ignoredKeys = ["destinationTypes"]; // Add the keys you want to ignore here
        if (object === null || object === undefined) return null;
        return (
            <View key={index}>
                {Array.isArray(object) ? (
                    object.map((item, subIndex) => renderNested(item, subIndex))
                ) : typeof object === 'string' ? (
                    <Text selectable={true}>{object}</Text>
                ) : (
                    Object.keys(object)
                        .filter(key => !ignoredKeys.includes(key))
                        .map((key, subIndex) => (
                            <View key={subIndex} style={styles.column}>
                                {/* @ts-ignore */}
                                <Text selectable={true}>
                                    {capitaliseAndRemoveSpecialChars(key)}:
                                    {typeof object[key] === 'object' && !Array.isArray(object[key]) && typeof object[key] !== 'string'
                                        ? renderNested(object[key], subIndex)
                                        : ` ${object[key]}`}
                                </Text>
                            </View>
                        ))
                )}
            </View>
        );
    };


    //===============================================
    //===============================================
    //===============================================
    //ASSIGN JOB
    //===============================================
    //===============================================
    //===============================================
    const [AssignJobVisible, setAssignJobVisible] = useState(false)


    const assignJob = async (driver: Member) => {
        // console.log("assigning job", driver, Job)
        setDisableSaveButton(true)
        try {
            if (!JobState._id || !driver._id || !JobState.company_id) {
                setDisableSaveButton(false)
                return
            }
            const jobResult = await JobsController.assignJob(JobState._id, driver._id, JobState.company_id)
            if (!jobResult) {
                setDisableSaveButton(false)
                return
            }
            StorageController.appState.selectedJob = jobResult
            //update Job member_id to item._id
            onSetJob(jobResult, "assignJob")
            setDisableSaveButton(false)
            fn_updateJobs()
            setAssignJobVisible(false)
        } catch (err) {
            console.log(err)
            setDisableSaveButton(false)
            fn_closeJobDetailsModal()
        }

        // JobsController.assignJob(JobState._id, driver._id, JobState.company_id).then((res: any) => {
        //     // console.log("===ASSIGNING JOB===", res)
        //     if (!res) return
        //     StorageController.appState.selectedJob = res
        //     //update Job member_id to item._id
        //     jobStateRef.current.member_id = driver._id
        //     onSetJob(jobStateRef.current, "assignJob")
        //     fn_updateJobs()
        // }).catch(err => {
        //     console.log(err)
        // })
        // setAssignJobVisible(false)
    }


    const RenderAssignJobModal = () => {
        return (
            <DMModal
                visible={AssignJobVisible}
                onClose={() => setAssignJobVisible(false)}>
                <Layout style={{ height: "100%", borderRadius: 20 }}>
                    <View style={{ flex: 1, margin: 50 }}>

                        <Layout style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <Text category='h2' status='success'>
                                Assign Driver
                            </Text>
                        </Layout>
                        <Layout style={{ flex: 8, borderRadius: 10 }}>
                            <AssignJobList
                                selectedJob={JobState}
                                fn_assignMember={assignJob}
                                member_id={JobState.member_id}
                            />
                        </Layout>
                        <Layout style={{ flex: 1 }}>
                            <Button onPress={() => setAssignJobVisible(false)}>
                                DISMISS
                            </Button>
                        </Layout>
                    </View>
                </Layout>
            </DMModal>
        )
    }

    //===============================================
    //===============================================
    //===============================================
    //Set Job Status
    //===============================================
    //===============================================
    //===============================================

    const updateJobInLocalStorage = (job: Job) => {
        // console.log("======updateJobInLocalStorage======", job)
        if (!StorageController.getAppState().jobs) {
            return
        }
        StorageController.getAppState().jobs = StorageController.getAppState().jobs.map(j => {
            // console.log("======updateJobInLocalStorage======", j)
            if (j._id == job._id) {
                return job
            }
            else {
                return j
            }
        })
    }

    const setJobComplete = async () => {
        let _job = await JobsController.setJobComplete(JobState)
        if (_job) {
            onSetJob(_job, "setJobComplete")
            fn_setJobComplete(_job)
            updateJobInLocalStorage(_job)
        }
        return _job
    }

    const setJobPending = async () => {
        let _job = await JobsController.setJobPending(JobState)
        if (_job) {
            onSetJob(_job, "setJobPending")
            fn_setJobPending(_job)
            updateJobInLocalStorage(_job)
        }
    }

    const setJobReassignedBack = async () => {
        let _job = await JobsController.setJobReassignedBack(JobState)
        if (_job) {
            onSetJob(_job, "setJobReassignedBack")
            fn_setJobAssigned(_job)
            updateJobInLocalStorage(_job)
        }
    }

    const setJobUnassigned = async () => {
        let _job = await JobsController.setJobUnassigned(JobState)
        if (_job) {
            onSetJob(_job, "setJobUnassigned")
            fn_setJobUnassigned(_job)
            updateJobInLocalStorage(_job)
        }
    }

    const cancelJob = async () => {
        try {
            let _job = await JobsController.cancelJob(JobState._id)
            if (_job) {
                await checkIfJobBackToHoldingOnCancel()
                onSetJob(_job, "cancelJob")
                fn_cancelJob(_job)
                updateJobInLocalStorage(_job)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const checkIfJobBackToHoldingOnCancel = async () => {
        try {
            if (showTowingOptions) {
                if (JobState.details?.towing_details?.holding_item_id) {
                    const _holdingItem = await HoldingController.findByPk(JobState.details?.towing_details?.holding_item_id)
                    if (_holdingItem) {
                        if (_holdingItem.status == "released") {
                            await HoldingController.unReleaseHolding(_holdingItem._id as string)
                        }
                    }
                }
            }
        } catch (err) {
            console.log(err)
        }
    }



    const acceptJobRequest = async () => {
        try {
            let eta = JobState.vendor_eta
            if (!JobState.vendor_eta) eta = 60
            const _j = await JobsController.vendorAcceptJob(JobState._id, eta)
            // console.log("acceptJobRequest", _j)
            updateJobInLocalStorage(JobState)
            setShowRequestAccept(false)
        } catch (e) {
            console.log(e)
        }
    }

    const declineJobRequest = async () => {
        const _j = await JobsController.vendorDeclineJob(JobState._id)
        // console.log("rejectJobRequest", _j)
        updateJobInLocalStorage(JobState)
        setShowRequestAccept(false)
    }

    const promoteQuoteToJob = async () => {
        // if member is client, set job to request
        if (StorageController.getAppState().selectedMembership?.is_client) {
            jobStateRef.current.status = JOB_STATUS.REQUEST
            onSetJob(jobStateRef.current, "promoteQuoteToJob")
            const _j = await JobsController.updateJob(jobStateRef.current)
            updateJobInLocalStorage(JobState)
            fn_onEditJob(_j as Job)
            return
        }
        else {
            // if member is vendor, set job to unassigned
            setJobUnassigned()
            return
        }
    }

    const deleteQuote = async () => {
        cancelJob()
    }




    //===============================================
    //===============================================
    //===============================================
    //PERMISSIONS
    //===============================================
    //===============================================
    //===============================================
    const isAdmin = () => StorageController.getAppState().selectedMembership?.is_admin
    const isOperator = () => StorageController.getAppState().selectedMembership?.is_operator
    const isLocalClient = () => StorageController.getAppState().selectedMembership?.is_client

    const canEditUnassigned = () => isAdmin() || isOperator() || isLocalClient();

    const canEditAssigned = () => isJobOwner && (isAdmin() || isOperator());

    const canEditPending = () => isJobOwner && (isAdmin() || isOperator());

    const canEditComplete = () => isJobOwner && isAdmin();

    const canEditQuote = () => isJobOwner && (isAdmin() || isOperator() || isLocalClient());

    const canEditRequested = () => isJobOwner && (isAdmin() || isOperator() || isLocalClient());

    const canEditTransferredOut = () => isJobOwner && isAdmin();



    const allowEdit = () => {
        if (JobState.status == JOB_STATUS.QUOTE) {
            return canEditQuote()
        }
        if (JobState.status == JOB_STATUS.REQUEST) {
            return canEditRequested()
        }
        if (JobState.status == JOB_STATUS.UNASSIGNED) {
            return canEditUnassigned()
        }
        else if (JobState.status == JOB_STATUS.ASSIGNED) {
            return canEditAssigned()
        }
        else if (JobState.status == JOB_STATUS.PENDING) {
            return canEditPending()
        }
        else if (JobState.status == JOB_STATUS.COMPLETE) {
            return canEditComplete()
        }
        else if (JobState.status == JOB_STATUS.TRANSFERRED_OUT) {
            return canEditTransferredOut()
        }
        else {
            return false
        }
    }


    const MemoizedOptionsDetailsCard = useMemo(() => {
        return (
            <OptionsDetailsCard
                job={JobState}
                style={styles.groupContainer}
                allowEdit={allowEdit()}
                onSetDispatchable={(e: boolean) => {
                    jobStateRef.current.dispatchable = e
                    onSetJob(jobStateRef.current, "dispatchable")
                }}
                onSetIsScheduled={(e) => {
                    jobStateRef.current.is_scheduled = e
                    onSetJob(jobStateRef.current, "isScheduled")
                }}
                onSetVendorEta={(e: any) => onSetVendorEta(e)}
                onSet_start_time={(e) => {
                    jobStateRef.current.start_time = e || new Date().getTime()
                    onSetJob(jobStateRef.current, "start_time")
                }}
                onSetPriority={(e) => {
                    jobStateRef.current.details.priority = e
                    onSetJob(jobStateRef.current, "priority")
                }
                }
            />
        )
    }, [JobState])

    //===============================================
    //===============================================
    //===============================================
    //Status Renderer
    //===============================================
    //===============================================
    //===============================================
    const [showConfirmDeleteRequest, setShowConfirmDeleteRequest] = useState(false)
    const [showRequestAccept, setShowRequestAccept] = useState(false)

    const buttonStyle = { flex: 1, borderRadius: 5 };
    const iconStyle = { flex: 1, width: 30, height: 20, backgroundColor: theme['background-basic-color-2'] };
    const layoutStyle = { flex: 4, borderRadius: 5, flexDirection: "row" };


    const statusStyles = {
        quote: { backgroundColor: "grey", text: "Quote", },
        request: { backgroundColor: "#ff34fb", text: "Job Requested", },
        pending: { backgroundColor: "blue", text: "Pending", },
        complete: { backgroundColor: "green", text: "Complete", },
        unassigned: { backgroundColor: "red", text: "Unassigned", },
        assigned: { backgroundColor: "orange", text: "Assigned", },
        cancelled: { backgroundColor: "purple", text: "Cancelled", },
        transferred_out: { backgroundColor: "cyan", text: "Transferred Out", },
    } as any;

    const JobStatus = ({ status }: any) => {
        const { backgroundColor, text } = statusStyles[status] || { text: "Unknown" };
        return (
            <Layout style={{
                backgroundColor,
                borderRadius: 5,
                border: "1px solid black",
            }}>
                <Text style={{}}>{text}</Text>
            </Layout>
        );
    }

    const JobStatusButtons = ({ status }: any) => {
        const { backgroundColor, text } = statusStyles[status] || { text: "Unknown" };

        if (status == "quote" && !showConfirmDeleteRequest) {
            return (
                <Layout style={layoutStyle as any}>
                    <Button
                        style={{ flex: 2, borderRadius: 5 }}
                        status="success"
                        appearance="outline"
                        disabled={!allowEdit()}
                        onPress={() => { allowEdit() ? promoteQuoteToJob() : null }}
                    >
                        Promote To Job
                    </Button>
                    <Icon name="arrow-forward-outline" style={iconStyle} />
                    <Button
                        style={{ flex: 1, borderRadius: 5 }}
                        status="danger"
                        disabled={!allowEdit()}
                        appearance='outline'
                        onPress={() => { allowEdit() ? setShowConfirmDeleteRequest(true) : null }}
                    >
                        Delete
                    </Button>

                </Layout>
            );
        }

        if (status == "request" && !showRequestAccept && !showConfirmDeleteRequest && !isLocalClient() && viewedBy == "vendor") {
            return (
                <Card
                    style={{ height: 120, width: "100%" }}
                    header={() => <Text category='h6'>Accept Job Request</Text>}
                    status="warning"
                >
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button style={{ flex: 2, borderRadius: 5 }}
                            status="success"
                            appearance="filled"
                            disabled={!allowEdit()}
                            onPress={() => { allowEdit() ? setShowRequestAccept(true) : null }}
                        >
                            Accept
                        </Button>
                        <Button
                            style={{ flex: 1, borderRadius: 5 }}
                            status="danger"
                            disabled={!allowEdit()}
                            appearance='ghost'
                            onPress={() => { allowEdit() ? setShowConfirmDeleteRequest(true) : null }}
                        >
                            Reject
                        </Button>
                    </View>
                </Card >
            )
        }

        if (status != "request" && status != "quote") {
            return (
                <Layout style={{ flex: 4, borderRadius: 5, flexDirection: "row" }}>
                    <Button
                        style={{ flex: 1, borderRadius: 5 }}
                        status="danger"
                        appearance="outline"
                        disabled={status == "assigned" ? false : true}
                        onPress={() => { allowEdit() ? setJobUnassigned() : null }}>
                        Unassign
                    </Button>
                    <Icon name="arrow-forward-outline" style={{ flex: 1, width: 30, height: 20, backgroundColor: theme['background-basic-color-2'] }} />
                    <Button
                        style={{ flex: 1, borderRadius: 5 }}
                        status="warning"
                        disabled={
                            (status == "pending") || (status == "complete") || (!JobState.dispatchable) ? true : false
                        }
                        appearance='outline'
                        onPress={() => { (allowEdit() && viewedBy == "vendor") ? setAssignJobVisible(true) : null }}
                    >
                        {status == "assigned" ? "Reassign" : "Assign"}
                    </Button>
                    <Icon name="arrow-forward-outline" style={{ flex: 1, width: 30, height: 20, backgroundColor: theme['background-basic-color-2'] }} />
                    <Button
                        style={{ flex: 1, borderRadius: 5 }}
                        status="info"
                        disabled={
                            (status == "complete") || (status == "unassigned") || (status == "pending") ? true : false
                        }
                        appearance='outline'
                        onPress={() => { allowEdit() ? setJobPending() : null }}
                    >
                        Pending
                    </Button>
                    <Icon name="arrow-forward-outline" style={{ flex: 1, width: 30, height: 20, backgroundColor: theme['background-basic-color-2'] }} />
                    <Button
                        style={{ flex: 1, borderRadius: 5 }}
                        status="success"
                        disabled={
                            (status == "complete") || (status == "unassigned") || (status == "assigned") ? true : false
                            // (status != "pending") ? true : false
                        }
                        appearance='outline'
                        onPress={() => { allowEdit() ? setJobComplete() : null }}
                    >
                        Complete
                    </Button>
                    <Icon name="arrow-forward-outline" style={{ flex: 1, width: 30, height: 20, backgroundColor: theme['background-basic-color-2'] }} />
                    <Button
                        style={{ flex: 1, borderRadius: 5 }}
                        status="control"
                        appearance='outline'
                        disabled={(status == "pending") || (status == "complete" && isAdmin()) ? false : true}
                        onPress={() => { allowEdit() ? setJobReassignedBack() : null }}
                    >
                        Reassign Back
                    </Button>
                </Layout>
            )
        }

        if (showRequestAccept) {
            return (
                <Layout style={layoutStyle as any}>
                    {/* ETA */}
                    <View style={{ flex: 5, justifyContent: 'center', alignItems: 'flex-end', padding: 5 }}>
                        <Text category="h6">
                            ETA (Minutes):
                        </Text>
                    </View>
                    <Input
                        style={{ flex: 1, borderRadius: 5, padding: 5 }}
                        // label='ETA'
                        placeholder='ETA'
                        onChangeText={(e: any) => onSetVendorEta(e)}
                        value={JobState.vendor_eta?.toString() || ""}
                    />
                    <Button
                        style={{ flex: 1, borderRadius: 5 }}
                        status="success"
                        appearance="outline"
                        onPress={() => { acceptJobRequest() }}
                    >
                        Accept
                    </Button>
                    <View style={{ flex: 5 }}></View>
                </Layout>
            )
        }
        return null
    };


    /*
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////// NOTES
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    */

    const onAddNote = async (note: JobNote) => {
        try {
            jobStateRef.current.details.addNote(note)
            onSetJob(jobStateRef.current, "onAddNote")
        } catch (err) {
        }
    }

    const onUpdateNote = async (note: JobNote) => {
        try {
            jobStateRef.current.details.updateNote(note)
            onSetJob(jobStateRef.current, "onUpdateNote")
        } catch (err) {
        }
    }

    const onDeleteNote = async (note: JobNote) => {
        try {
            jobStateRef.current.details.deleteNote(note)
            onSetJob(jobStateRef.current, "onDeleteNote")
        } catch (err) {
        }
    }



    //===============================================
    //===============================================
    //===============================================
    //RENDER
    //===============================================
    //===============================================
    //===============================================

    const RenderMobileHeader = () => {
        return (
            <View style={{ flexDirection: 'column', justifyContent: 'space-between' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <JobStatus status={JobState.status} />
                    <View style={{ flexDirection: 'row' }}>
                        <Text category='label' style={{ textAlign: "center" }}>
                            Job ID:
                        </Text>
                        <Text category='p2' style={{ textAlign: "center" }}>
                            {JobState.friendly_id ? `  ${JobState.friendly_id}` : generateFriendlyJobNumber(JobState._id, JobState.createdAt || "", JobState.job_count)}
                        </Text>
                    </View>
                    <Button
                        style={{ flex: 1 }}
                        status="danger"
                        size='tiny'
                        onPress={() => { fn_closeJobDetailsModal() }}
                        accessoryLeft={() => (<Icon name="close-outline" fill={theme['text-basic-color']} width={24} height={24} />)}
                    >
                    </Button>
                </View>
                <Button
                    status="info"
                    appearance="outline"
                    size='tiny'
                    onPress={() => { setShowJobOptionsMenu(true) }}
                    accessoryLeft={() => (<Icon name="more-vertical-outline" fill={theme['text-basic-color']} width={20} height={20} />)}
                >
                    Options
                </Button>
            </View>
        )
    }

    const RenderDesktopHeader = () => {
        return (
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Layout style={{ flex: 1, flexDirection: "row", justifyContent: "space-evenly", alignItems: "flex-start", backgroundColor: theme['background-basic-color-2'] }}>
                    <Layout style={{ flex: 1, justifyContent: "center", borderRadius: 10, padding: 5 }}>
                        <JobStatus status={JobState.status} />
                    </Layout>
                    <Layout style={{ flex: 5, flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', gap: 25, flexWrap: "wrap" }}>
                        <TransferJobComponent
                            job={JobState}
                            onTransferJob={(r: any) => {
                            }}
                            canEdit={allowEdit()}
                        />
                        <View style={{ flexDirection: 'row' }}>
                            <Text category='label' style={{ textAlign: "center" }}>
                                Job ID:
                            </Text>
                            <Text category='p2' style={{ textAlign: "center" }}>
                                {JobState.friendly_id ? `  ${JobState.friendly_id}` : generateFriendlyJobNumber(JobState._id, JobState.createdAt || "", JobState.job_count)}
                            </Text>
                        </View>
                        <Button
                            status="danger"
                            appearance="outline"
                            size='tiny'
                            onPress={() => { setShowCancelJobModal(true) }}
                            accessoryLeft={() => (<Icon name="alert-triangle-outline" fill={theme['text-basic-color']} width={20} height={20} />)}
                        >
                            Cancel Job
                        </Button>
                        <CopyJobComponent
                            onClose={() => { }}
                            job={JobState}
                            onCopy={() => { }}
                            canEdit={allowEdit()}
                        />
                        <ConvertJobComponent
                            job={JobState}
                            onConvert={(updatedJob: Job) => {
                                onSetJob(updatedJob, "convertJobType");
                            }}
                            canEdit={allowEdit()}
                        />
                    </Layout>
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                        <Button
                            style={{ flex: 1 }}
                            status="info"
                            size='tiny'
                            onPress={() => saveJob(false, false)}
                            accessoryLeft={() => (<Icon name="save-outline" fill={theme['text-basic-color']} width={24} height={24} />)}
                        >
                            Save
                        </Button>
                        <Button
                            style={{ flex: 1 }}
                            status="danger"
                            size='tiny'
                            onPress={() => { fn_closeJobDetailsModal() }}
                            accessoryLeft={() => (<Icon name="close-outline" fill={theme['text-basic-color']} width={24} height={24} />)}
                        >
                        </Button>
                    </View>
                </Layout>
            </View>
        )
    }



    const imagesWidth = "25%"
    const mainWidth = "60%"
    const logsWidth = "15%"
    return (
        // @ts-ignore
        <Layout style={{
            flex: 1,
            gap: 10,
            borderColor: '#1f1f2f',
            borderWidth: 5,
            borderRadius: 10,
        }}>
            {!loaded &&
                <LoadingSpinner />
            }
            <RenderSavedModal />
            <CancelJobModal />
            <SendJobEmails
                job={JobState}
                visible={showSendJobEmails}
                onClose={() => setShowSendJobEmails(false)}
            />
            {/* <RenderAssignJobModal /> */}
            {AssignJobVisible &&
                <AssignJobModal
                    fn_assignMember={assignJob}
                    selectedJob={JobState}
                    var_member_id={JobState.member_id}
                    var_assignJobModalVisible={AssignJobVisible}
                    fn_dismiss={() => setAssignJobVisible(false)}
                />
            }
            <JobOptionsMenu
                JobState={JobState}
                TransferJobComponent={TransferJobComponent}
                setShowJobOptionsMenu={setShowJobOptionsMenu}
                showJobOptionsMemnu={showJobOptionsMemnu}
                allowEdit={allowEdit}
                setShowCancelJobModal={setShowCancelJobModal}
            />
            {/*
        //////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////
        //////// Header
        //////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////
        */}

            {windowWidth < windowCollapseWidth ?
                <RenderMobileHeader />
                :
                <RenderDesktopHeader />
            }


            {/*
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    //////// STATUS BUTTONS
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
            */}
            <View
                style={{
                    width: '100%',
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Layout style={{ flex: 1, zIndex: 1, justifyContent: "space-between", flexDirection: "row", height: "6%", backgroundColor: theme['background-basic-color-2'] }}>
                    <JobStatusButtons status={JobState.status} />
                </Layout>
            </View>
            <ErrorBoundary>
                <View style={{ flexDirection: 'row', flex: 1 }}>

                    {/*
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    //////// IMAGES
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    */}
                    {
                        // if mobile, show images first
                        windowWidth > windowCollapseWidth ?
                            <View
                                style={{
                                    height: "100%",
                                    // flex: 2,
                                    width: imagesWidth,
                                }}
                            >
                                <View style={{ flex: 1 }}>

                                    <ImageDetailsCard
                                        propStyle={{ flex: 1 }}
                                        images={images}
                                        onSetLocalImages={setLocalImages}
                                        onSetImages={() => { }}
                                    />
                                    <JobDocuments
                                        job={JobState}
                                        onSelectDocument={(doc: any) => { }}
                                    />
                                </View>
                            </View>
                            :
                            <DMModal
                                visible={showImagesModal}
                                onClose={() => setShowImagesModal(false)}
                            >

                                <Layout style={{ flex: 1 }}>
                                    {/* Close Button */}
                                    <Button
                                        style={{ position: 'absolute', top: 0, right: 0, zIndex: 1, margin: 5, borderRadius: 5 }}
                                        status="danger"
                                        size='tiny'
                                        onPress={() => setShowImagesModal(false)}
                                    >
                                        <Icon name="close-outline" fill={theme['text-basic-color']} width={24} height={24} />
                                    </Button>

                                    <ImageDetailsCard
                                        propStyle={{ flex: 1 }}
                                        images={images}
                                        onSetLocalImages={setLocalImages}
                                        onSetImages={() => { }}
                                    />
                                </Layout>
                            </DMModal>
                    }

                    {/*
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    //////// MAIN AREA
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    */}
                    <ScrollView
                        style={{
                            height: "100%",
                            width: mainWidth,
                        }}
                    >
                        {windowWidth < windowCollapseWidth ?
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 3 }}>
                                <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 5 }}>
                                    <Button
                                        style={{ flex: 1, borderRadius: 5 }}
                                        size='small'
                                        status='info'
                                        appearance='outline'
                                        onPress={() => setShowImagesModal(true)}
                                        accessoryLeft={() => (<Icon name="image-outline" fill={theme['text-basic-color']} width={24} height={24} />)}
                                    >
                                        <Text>View Images {images.length > 0 ? `(${images.length})` : ""}</Text>
                                    </Button>
                                </Layout>
                                {/* View logs */}
                                <Layout style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Button
                                        style={{ flex: 1, borderRadius: 5 }}
                                        size='small'
                                        status='info'
                                        appearance='outline'
                                        onPress={() => setShowLogsModal(true)}
                                        accessoryLeft={() => (<Icon name="file-text-outline" fill={theme['text-basic-color']} width={24} height={24} />)}
                                    >
                                        View Logs
                                    </Button>
                                </Layout>
                            </View>
                            : <></>
                        }
                        {/*
                        //////////////////////////////////////////////////////////////////
                        //////////////////////////////////////////////////////////////////
                        //////// Driver and email buttons
                        //////////////////////////////////////////////////////////////////
                        //////////////////////////////////////////////////////////////////
                        */}
                        <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center", minHeight: 80 }}>
                            {/* Driver Assignment */}
                            {(!JobState.dispatchable && JobState.status != 'complete') ?
                                //@ts-ignore
                                <Button
                                    style={{ flex: 1, margin: 5 }}
                                    onPress={() => { setAssignJobVisible(true) }}
                                    appearance='outline'
                                    status="warning"
                                    size='large'>
                                    <Icon
                                        name='car-outline'
                                        width={24}
                                        height={24}
                                        fill={theme['text-basic-color']}
                                    />
                                    <Text>Assign Dispatched Driver</Text>
                                </Button>
                                : <></>}

                            {/* Driver assigned name */}
                            {JobState.status != "unassigned" ? (
                                <Layout style={{ flex: 1, margin: 5 }}>
                                    {JobState.member_id &&
                                        <ErrorBoundary>
                                            <Text category="s1">
                                                Driver: {drivers.find(driver => driver._id == JobState.member_id)?.name || ""}
                                            </Text>
                                        </ErrorBoundary>
                                    }
                                </Layout>
                            ) : (
                                <Layout style={{ flex: 1, margin: 5 }}>
                                    <Text category="h6">
                                        Driver: Unassigned
                                    </Text>
                                </Layout>
                            )}
                            <View style={{ flex: 1 }}></View>
                            <Button
                                onPress={() => { setShowSendJobEmails(true) }}
                                appearance='filled'
                                status="info"
                                size='small'
                                accessoryLeft={() => (<Icon name="email-outline" fill={theme['text-basic-color']} width={24} height={24} />)}
                            >
                                Email Options
                            </Button>
                        </View>

                        {/*
                        //////////////////////////////////////////////////////////////////
                        //////////////////////////////////////////////////////////////////
                        //////// DETAILS
                        //////////////////////////////////////////////////////////////////
                        //////////////////////////////////////////////////////////////////
                        */}

                        <View style={{ flexDirection: 'column', gap: 5 }}>
                            <View style={{ flexDirection: 'row', gap: 5, flexWrap: 'wrap' }}>
                                <CustomerDetailsCard
                                    style={styles.groupContainer}
                                    allowEdit={allowEdit()}
                                    customerDetails={JobState.details.customer_details}
                                    onChangeCustomerDetails={onSetCustomerDetails}
                                />
                                {showRoadsideOptions ?
                                    <VehicleDetailsCard
                                        style={styles.groupContainer}
                                        allowEdit={allowEdit()}
                                        vehicleDetails={JobState.details.vehicle_details}
                                        onChangeVehicleDetails={onSetVehicleDetails}
                                    />
                                    : <></>}
                                {showTowingOptions ?
                                    <TowingDetailsCard
                                        onChangeTowingDetails={onSetTowingDetails}
                                        defaultTowingObj={JobState.details.towing_details ? JobState.details.towing_details : null}
                                        style={styles.groupContainer}
                                    />
                                    : <></>}
                            </View>

                            <View style={{ flexDirection: "row", gap: 5, flexWrap: 'wrap' }}>
                                {(viewedBy == "vendor" || viewedBy == "local_client") ?
                                    <ClientDetailsCard
                                        job={JobState}
                                        style={styles.groupContainer}
                                        clientReferenceId={JobState.client_reference_id || ""}
                                        jobCreatedBy={getJobCreatedBy()}
                                        fn_onSetClientRate={(e: any) => {
                                            jobStateRef.current.details.client_rate = e;
                                            onSetJob(jobStateRef.current, "clientRateSelected");
                                        }}
                                        fn_onSetClient={(e) => {
                                            jobStateRef.current.client_id = e._id;
                                            jobStateRef.current.client_company_id = e.linkedClientAccountId ?? null
                                            onSetJob(jobStateRef.current, "clientSelected");
                                            onSetSelectedClient(e);
                                        }}
                                        fn_onSetClientRefrerence={(value) => {
                                            jobStateRef.current.client_reference_id = value;
                                            onSetJob(jobStateRef.current, "clientReferenceId");
                                        }}
                                        allowEdit={allowEdit() || false}
                                        isNewJob={false}
                                        jobCreateSource={getJobCreatedBy()}
                                        setRefs={function (e: any): void {

                                        }} onInputFocus={function (e: any): void {

                                        }} />
                                    : <></>}
                                {viewedBy == "client" ?
                                    <VendorDetailsCard
                                        style={styles.groupContainer}
                                        allowEdit={allowEdit()}
                                        clientReferenceId={JobState.client_reference_id || ""}
                                        client_id={JobState.client_id || null}
                                        client_company_id={JobState.client_company_id || null}
                                        client_rate={JobState.details.client_rate || null}
                                        is_client_created={JobState.is_client_created || false}
                                        is_vendor_created={JobState.is_vendor_created || false}
                                        jobCreatedBy={getJobCreatedBy()}
                                        vendor_company_id={JobState.vendor_company_id || null}
                                        vendor_profile_id={JobState.vendor_profile_id || null}
                                        onClientRateCostSelected={(e) => {
                                            jobStateRef.current.details.client_rate.cost = e
                                            onSetJob(jobStateRef.current, "clientRateCostSelected")
                                        }}
                                        onSetClientRateName={(e) => {
                                            jobStateRef.current.details.client_rate.name = e
                                            onSetJob(jobStateRef.current, "clientRateName")
                                        }}
                                        onSetBilling={(e) => {
                                            jobStateRef.current.details.client_rate.billed_to = e
                                            onSetJob(jobStateRef.current, "billing")
                                        }}
                                        onClientRateSelected={(e) => {
                                            jobStateRef.current.details.client_rate = e
                                            onSetJob(jobStateRef.current, "clientRateSelected")
                                        }}
                                        onSetClientRefrerenceId={(e) => {
                                            jobStateRef.current.client_reference_id = e
                                            onSetJob(jobStateRef.current, "clientReferenceId")
                                        }}
                                        onSetCustomerPaid={(e) => {
                                            jobStateRef.current.details.client_rate.customer_paid = e
                                            onSetJob(jobStateRef.current, "customerPaid")
                                        }}
                                    />
                                    : <></>}


                                {/* Options details */}
                                {MemoizedOptionsDetailsCard}

                            </View>

                            <ServiceDetailsCard
                                style={styles.groupContainerLarge}
                                updateJobServices={(services: Service[]) => {
                                    jobStateRef.current.details.selected_services = services
                                    onSetJob(jobStateRef.current, "selectedServices")
                                }}
                                selectedServices={JobState.details?.selected_services}
                                canSetTimes={true}
                                allowEdit={allowEdit() as boolean}
                            />

                            {
                                (showRoadsideOptions || showTransportOptions) ?
                                    <>
                                        {/* Location details */}
                                        <LocationDetailsCard
                                            style={styles.groupContainerLarge}
                                            allowEdit={allowEdit()}
                                            address={JobState.details.address || null}
                                            location={JobState.details.location || null}
                                            onSetAddress={(e: JobAddress) => {
                                                jobStateRef.current.details.address = e
                                                onSetJob(jobStateRef.current, "address")
                                            }}
                                            onSetLocation={(e: JobLocation) => {
                                                jobStateRef.current.details.location = e
                                                onSetJob(jobStateRef.current, "location")
                                            }}
                                            searchDisplayValue={GoogleController.addressBuilder(JobState.details.address)}
                                        />
                                    </>
                                    : <></>}

                            {
                                showTowingOptions ?
                                    <>
                                        <TowingLocationDetailsCard
                                            style={styles.groupContainerLarge}
                                            onChangeTowingLocations={onSetTowingLocationDetails}
                                            onAddCostToItems={addTowingDistanceItems}
                                            defaultTowingLocationObj={JobState.details.towing_details ?? undefined}
                                            allowEdit={allowEdit()}
                                        />
                                    </>
                                    : <></>}
                            <OtherDetailsCard
                                allowEdit={allowEdit() as boolean}
                                // allowEdit={false}
                                dispatchComments={JobState.details.comments || ""}
                                onSetDispatchComments={(e: any) => debounceComments(e)}
                                style={styles.groupContainer}
                            // onSetDispatchComments={(e) => onSetJob({ ...JobState, details: { ...JobState.details, comments: e } }, "comments")}
                            />
                            <View style={{ flexDirection: 'column' }}>

                                <JobNotesList
                                    notes={JobState.details.notes}
                                    onAddNote={onAddNote}
                                    onDeleteNote={onDeleteNote}
                                    onUpdateNote={onUpdateNote}
                                />
                            </View>



                            <InventoryDetailsCard
                                allowEdit={allowEdit()}
                                jobSource={viewedBy}
                                style={styles.groupContainerLarge}
                                selectedItems={JobState.details?.report?.items || []}
                                onChangeSelectedItems={(e: any) => {
                                    jobStateRef.current.details.report.items = e
                                    onSetJob(jobStateRef.current, "selectedItems")
                                }}
                                vendorProfile={null}
                                selectedClient={selectedClient}
                            />
                            <ErrorBoundary>
                                <InvoicedTotalCard
                                    job={JobState || null}
                                    style={styles.groupContainerLarge}
                                />
                            </ErrorBoundary>
                            <PaymentDetailsCard
                                jobState={JobState}
                                allowEdit={allowEdit() as boolean}
                                customerPaidCard={JobState.details?.report?.customerPaidCard || false}
                                customerPaidCardAmount={JobState.details?.report?.customerPaidCardAmount || 0}
                                customerPaidCash={JobState.details?.report?.customerPaidCash || false}
                                customerPaidCashAmount={JobState.details?.report?.customerPaidCashAmount || 0}
                                onSetCustomerPaidCard={(e: any) => {
                                    jobStateRef.current.details.report.customerPaidCard = e
                                    onSetJob(jobStateRef.current, "customerPaidCard")
                                }}
                                onSetCustomerPaidCardAmount={(e: any) => {
                                    jobStateRef.current.details.report.customerPaidCardAmount = e
                                    onSetJob(jobStateRef.current, "customerPaidCardAmount")
                                }}
                                onSetCustomerPaidCash={(e: any) => {
                                    jobStateRef.current.details.report.customerPaidCash = e
                                    onSetJob(jobStateRef.current, "customerPaidCash")

                                }}
                                onSetCustomerPaidCashAmount={(e: any) => {
                                    jobStateRef.current.details.report.customerPaidCashAmount = e
                                    onSetJob(jobStateRef.current, "customerPaidCashAmount")
                                }}
                                style={styles.groupContainerLarge} />

                            <TimeDetailsCard
                                allowEdit={allowEdit() as boolean}
                                start_time={JobState.start_time}
                                accepted_time={JobState.accepted_time}
                                arrived_time={JobState.arrived_time}
                                pending_time={JobState.pending_time}
                                driver_on_route_time={JobState.driver_on_route_time}
                                onSet_accepted_time={(e: any) => {
                                    jobStateRef.current.accepted_time = e
                                    onSetJob(jobStateRef.current, "accepted_time")
                                }
                                }
                                onSet_arrived_time={(e: any) => {
                                    jobStateRef.current.arrived_time = e
                                    onSetJob(jobStateRef.current, "arrived_time")
                                }
                                }
                                onSet_driver_on_route_time={(e: any) => {
                                    jobStateRef.current.driver_on_route_time = e
                                    onSetJob(jobStateRef.current, "driver_on_route_time")
                                }
                                }
                                onSet_pending_time={(e: any) => {
                                    jobStateRef.current.pending_time = e
                                    onSetJob(jobStateRef.current, "pending_time")
                                }
                                }
                                onSet_start_time={(e: any) => {
                                    jobStateRef.current.start_time = e
                                    onSetJob(jobStateRef.current, "start_time")
                                }
                                }
                                style={styles.groupContainerLarge}
                            />

                            <RenderDriverCommentsReport
                                jobState={JobState}
                                allowEdit={allowEdit() as boolean}
                                onSetPreInspectionComments={(e: string) => {
                                    JobState.details.report.preInspectionComments = e
                                    onSetJob(JobState, "preInspectionComments")
                                }}
                                onSetPostServiceComments={(e: string) => {
                                    JobState.details.report.comments = e
                                    onSetJob(JobState, "comments")
                                }}
                            />

                            <ReportDetailsCard
                                job={JobState}
                                style={styles.groupContainerLarge}
                            />
                        </View>
                    </ScrollView>
                    {/*
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    //////// LOGS
                    //////////////////////////////////////////////////////////////////
                    //////////////////////////////////////////////////////////////////
                    */}
                    {
                        windowWidth > windowCollapseWidth ?
                            <View
                                style={{
                                    height: "100%",
                                    width: logsWidth,
                                }}
                            >
                                <LogDetailsCard
                                    logs={jobLogs}
                                />
                            </View>
                            :
                            <DMModal
                                visible={showLogsModal}
                                onClose={() => setShowLogsModal(false)}
                            >
                                <Layout style={{ flex: 1 }}>
                                    {/* Close Button */}
                                    <Button
                                        style={{ position: 'absolute', top: 0, right: 0, zIndex: 1, margin: 5, borderRadius: 5 }}
                                        status="danger"
                                        // appearance="outline"
                                        size='tiny'
                                        onPress={() => setShowLogsModal(false)}
                                    >
                                        <Icon name="close-outline" fill={theme['text-basic-color']} width={24} height={24} />
                                    </Button>

                                    <LogDetailsCard
                                        logs={jobLogs}
                                    />
                                </Layout>
                            </DMModal>
                    }
                </View>
            </ErrorBoundary>

            {/*
            //////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////////////
            //////// FOOTER
            //////////////////////////////////////////////////////////////////
            //////////////////////////////////////////////////////////////////
            */}

            <Layout style={[{
                flexDirection: 'row',
                gap: 10,
                maxHeight: 50,
                justifyContent: 'space-between',
                marginBottom: 3
            }]}>
                <Button
                    style={{ flex: 1, maxWidth: 200 }}
                    status="danger"
                    appearance="outline"
                    size='tiny'
                    onPress={() => { fn_closeJobDetailsModal() }}
                    accessoryLeft={() => (<Icon name="close-outline" fill={theme['text-basic-color']} width={24} height={24} />)}
                >
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ alignSelf: 'center' }}>Close</Text>
                    </View>
                </Button>
                {/* <View style={{ flex: 1 }}></View> */}
                <Button
                    style={{ flex: 5 }}
                    onPress={() => saveJob(true, false)}
                    // appearance='outline'
                    status='success'
                    disabled={!allowEdit() || disableSaveButton}
                    accessoryLeft={() => (
                        <Icon
                            name='save-outline'
                            width={24}
                            height={24}
                            fill={theme['text-basic-color']}
                        />
                    )}
                >
                    Save Job (Close Window)
                </Button>
                {JobState.status === 'pending' ?
                    <Button
                        style={{ flex: 2 }}
                        onPress={() => saveJob(true, true)}
                        // appearance='outline'
                        status='success'
                        disabled={!allowEdit()}
                        accessoryLeft={() => (
                            <Icon
                                name='save-outline'
                                width={24}
                                height={24}
                                fill={theme['text-basic-color']}
                            />
                        )}
                    >
                        Save & Complete
                    </Button>
                    :
                    <View style={{ flex: 1 }}></View>
                }
                {/* <View style={{ flex: 1 }}></View> */}
            </Layout>

        </Layout>
    )
}

const styles = StyleSheet.create({
    groupContainer: {
        margin: 0,
        flex: 1,
        // backgroundColor: '#252e56',
        minWidth: 250,
    },
    groupContainerLarge: {
        margin: 0,
        // flex: 1,
        // backgroundColor: '#252e56',
        minWidth: 350,
    },
    container1: {
        margin: 5,
        marginTop: 30,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 5,
        padding: 10,
    },
    header: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    row: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    column: {
        flex: 2,
        borderWidth: 1,
        borderColor: 'gray',
        padding: 5,
    },
    column1: {
        flex: 1,
        borderWidth: 1,
        borderColor: 'gray',
        padding: 5,
    },
    label: {
        fontWeight: 'bold',
    },
    // table stuff ^_
    container: {
        maxHeight: "100%",
    },
    contentContainer: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    listContainer: {
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: 0,
        margin: 0
    },
    item: {
        marginVertical: 4,
    },
    side_section: {
        flex: 1,
        justifyContent: 'center',
        display: 'flex'
    },
    tinyLogo: {
        width: 100,
        height: 100,
    },
    image: {
        width: 200,
        height: 200,
    },
    images: {
        flex: 1,
        flexDirection: 'column',
    },
    modal: {

    }
}
);

interface RenderDriverCommentsReportProps {
    jobState: Job
    allowEdit: boolean
    onSetPreInspectionComments: (e: string) => void
    onSetPostServiceComments: (e: string) => void
}


const RenderDriverCommentsReport = ({
    jobState,
    allowEdit,
    onSetPreInspectionComments,
    onSetPostServiceComments
}: RenderDriverCommentsReportProps) => {
    const theme = useTheme()
    const [allowEditDriverComments, setAllowEditDriverComments] = useState(false)

    return (
        <Card
            disabled={!allowEditDriverComments}
            status='basic'
            header={() =>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

                    <Text category='h6'>Driver Comments</Text>
                    {allowEditDriverComments ?
                        <Button
                            status="success"
                            size='small'
                            appearance="ghost"
                            onPress={() => { setAllowEditDriverComments(false) }}
                            accessoryLeft={(props) => (<Icon name="save-outline" {...props} />)}
                        />
                        :
                        <Button
                            status="primary"
                            size='small'
                            appearance="ghost"
                            onPress={() => { setAllowEditDriverComments(true) }}
                            accessoryLeft={(props) => (<Icon name="edit-2-outline" {...props} />)}
                        />
                    }
                </View>
            }
        >
            <View style={{ flexDirection: 'column' }}>
                {allowEditDriverComments ?
                    <View style={{ flexDirection: 'column', gap: 5 }}>
                        <Input
                            defaultValue={jobState.details?.report?.preInspectionComments}
                            onChangeText={(e: any) => {
                                jobState.details.report.preInspectionComments = e
                                onSetPreInspectionComments(e)
                            }}
                            label='Pre-Inspection Comments'
                        />
                        <Input
                            defaultValue={jobState.details?.report?.comments}
                            onChangeText={(e: any) => {
                                jobState.details.report.comments = e
                                onSetPostServiceComments(e)
                            }}
                            label='Post Service Comments'
                        />
                    </View>
                    :
                    <View style={{ flexDirection: 'column', gap: 10 }}>
                        <View style={{ flexDirection: 'column', gap: 2 }}>
                            <Text category='s1' style={{ borderBottomWidth: 1, borderBottomColor: theme['border-basic-color-3'] }}>Pre-Inspection Comments</Text>
                            <Text category='p1' selectable>{jobState.details?.report?.preInspectionComments || "--No comments --"}</Text>
                        </View>
                        <View style={{ flexDirection: 'column', gap: 2 }}>
                            <Text category='s1' style={{ borderBottomWidth: 1, borderBottomColor: theme['border-basic-color-3'] }}>Post Service Comments</Text>
                            <Text category='p1' selectable>{jobState.details?.report?.comments || "--No comments --"}</Text>
                        </View>
                    </View>
                }
            </View>
        </Card>
    )
}
